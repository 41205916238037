import { AttachmentsRendererComponent } from './attachments-renderer';
import { ChatComponent } from './chat/chat.component';
import { ChatBlockFormComponent } from './chat-block-form';
import { ChatParticipantsComponent } from './chat-participants';
import { ChatListComponent } from './chat-list';
import { ChatListPopupComponent } from './chat-list-popup';
import { ChatMessageDateStubComponent } from './chat-message-date-stub';
import { ChatMessageEditComponent } from './chat-message-edit';
import { ChatMessageListComponent } from './chat-message-list';
import { ChatMessageUnreadStubComponent } from './chat-message-unread-stub';
import { ChatMessageViewComponent } from './chat-message-view';
import { ChatMessageViewNestedComponent } from './chat-message-view-nested';
import { ChatWrapperComponent } from './chat-wrapper';
import { ChatPageComponent } from './chat-page';
import { ChatWarningFormComponent } from './chat-warning-form';
import { ForwardMessagesPreviewComponent } from './forward-messages-preview';
import { GiphyPopupComponent } from './giphy-popup';

export const MODULE_COMPONENTS = [
  AttachmentsRendererComponent,
  ChatComponent,
  ChatBlockFormComponent,
  ChatParticipantsComponent,
  ChatListComponent,
  ChatListPopupComponent,
  ChatMessageDateStubComponent,
  ChatMessageEditComponent,
  ChatMessageListComponent,
  ChatMessageUnreadStubComponent,
  ChatMessageViewComponent,
  ChatMessageViewNestedComponent,
  ChatWrapperComponent,
  ChatWarningFormComponent,
  ForwardMessagesPreviewComponent,
  GiphyPopupComponent,
  ChatPageComponent
];

export const MODULE_EXPORT_COMPONENTS = [...MODULE_COMPONENTS];

export {
  AttachmentsRendererComponent,
  ChatComponent,
  ChatBlockFormComponent,
  ChatParticipantsComponent,
  ChatListComponent,
  ChatListPopupComponent,
  ChatMessageDateStubComponent,
  ChatMessageEditComponent,
  ChatMessageListComponent,
  ChatMessageUnreadStubComponent,
  ChatMessageViewComponent,
  ChatMessageViewNestedComponent,
  ChatWrapperComponent,
  ChatWarningFormComponent,
  ForwardMessagesPreviewComponent,
  GiphyPopupComponent,
  ChatPageComponent
};
