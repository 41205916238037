import * as NotificationActions from './notification.actions';
import * as NotificationState from './notification.state';

export function NotificationReducers(
  state = NotificationState.initialState,
  action: NotificationActions.Actions
): NotificationState.State {
  switch (action.type) {
    default:
      return state;
  }
}
