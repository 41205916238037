import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { Message } from '@breez/modules/chat';
import { isTruthy } from '@breez/shared/utilities/is-truthy';

@Pipe({
  name: 'messageIsForwarded'
})
@Injectable({
  providedIn: 'root'
})
export class MessageIsForwardedPipe implements PipeTransform {
  transform(message: Partial<Message>): boolean {
    return isTruthy(message.meta?.forward);
  }
}
