import { MonoTypeOperatorFunction, Observable, pipe } from 'rxjs';
import { mapTo, switchMap } from 'rxjs/operators';

/**
 * Switches to returned from the project-function observable each emitted value of which is mapped to input data
 * @param project
 */
export function waitFor<T, O extends Observable<any>>(project: (value: T) => O): MonoTypeOperatorFunction<T> {
  return pipe(
    switchMap(input => {
      return project(input).pipe(mapTo(input));
    })
  );
}
