import { Action, createReducer, on } from '@ngrx/store';
import * as UserState from './user.state';
import * as UserActions from './user.actions';

const reducer = createReducer(
  UserState.initialState,
  on(UserActions.loadUsersSuccess, (state, action) => {
    return UserState.adapter.upsertMany(action.users, state);
  })
);

export function userReducer(state: UserState.State | undefined, action: Action): UserState.State {
  return reducer(state, action);
}
