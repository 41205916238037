import { Overlay, OverlayContainer } from '@angular/cdk/overlay';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, inject, InjectionToken, NgModule } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MAT_RIPPLE_GLOBAL_OPTIONS,
  MatNativeDateModule,
  RippleGlobalOptions
} from '@angular/material/core';
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MAT_DIALOG_SCROLL_STRATEGY,
  MatDialogConfig,
  MatDialogModule
} from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipDefaultOptions, MatTooltipModule, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HAMMER_LOADER, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppRoutingModule } from '@breez/app-routing.module';
import { environment } from '@breez/environment';
import { AppConfigModel } from '@breez/models/app-config.model';
import { CallModule } from '@breez/modules/call/call.module';
import { NotificationModule } from '@breez/modules/notification';
import { OverlayModule } from '@breez/modules/overlay/overlay.module';
import { AvatarSmartModule } from '@breez/modules/user/modules/avatar-smart/avatar-smart.module';
import { WebsocketModule } from '@breez/modules/websocket/websocket.module';
import { StorageModule } from '@breez/shared/modules/storage/storage.module';
import {
  TRANSLATION_PREFIX,
  TRANSLATION_SUFFIX
} from '@breez/shared/modules/translation/interfaces/translation.interface';
import { TranslationModule } from '@breez/shared/modules/translation/translation.module';
import { TablePaginatorLocaleService } from '@breez/shared/services/table-paginator-locale.service';
import { SharedModule } from '@breez/shared/shared.module';
import { generateOrigin } from '@breez/shared/utilities/generate-origin';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxdModule } from '@ngxd/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { GestureConfig } from 'gesture-config';
// tslint:disable-next-line:no-import-side-effect
import 'moment/locale/ru';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { FileSaverModule } from 'ngx-filesaver';
import { ImageCropperModule } from 'ngx-image-cropper';
import { LoggerModule } from 'ngx-logger';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { ToastrModule } from 'ngx-toastr';
import { icons } from 'styles/icons';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './components/home/home.component';
import { LanguageSelectionComponent } from './components/language-selection';
import { MenuComponent } from './components/menu/menu.component';
import { ReconnectRequestComponent } from './components/reconnect-request/reconnect-request.component';
import { ShortcutsDialogComponent } from './components/shortcuts-dialog/shortcuts-dialog.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { NotSupportPageComponent } from './pages/not-support-page/not-support-page.component';
import { OopsPageComponent } from './pages/oops-page/oops-page.component';
import { CoreModule } from '@breez/modules/core/core.module';
// @ts-ignore
import { KeycloakAngularModule } from 'keycloak-angular';
import { RestApiService } from '@breez/rest-api.service';
import { KeycloakAuthService } from '@breez/modules/auth/services/keycloak-auth.service';
import { ErrorHandlerModule } from '../error-handler/error-handler.module';
import { ErrorHandlerService } from '../error-handler/error-handler.service';
import { LeavingComponent } from './components/leaving/leaving.component';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import {
  ROOT_STORE_DEVMETAREDUCERS,
  ROOT_STORE_EFFECTS,
  ROOT_STORE_PRODMETAREDUCERS,
  ROOT_STORE_REDUCERS
} from '@breez/+state';
import { UsersModule } from '@breez/modules/users/users.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ServiceWorkerApiService } from '@breez/shared/push-service/service-worker-api.service';
import { PushNotificationsService } from '@breez/shared/push-service/push-notifications.service';
import { PwaInstallComponent } from '@breez/components/pwa-install/pwa-install.component';
import { WEBRTC_MEDIA_EFFECTS } from '@breez/modules/webrtc/call-media-effects-service.provider';
import { MediaEffectsService } from '@breez/modules/webrtc/services/media-effects.service';
import { filter, switchMap } from 'rxjs/operators';
import { firstValueFrom } from 'rxjs';
import { InvitingGuestsModule } from '@breez/modules/inviting/inviting-guests';

const DATE_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY'
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD.MM.YYYY',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

function appInit(keycloakAuthService: KeycloakAuthService, appInfoService: RestApiService): () => Promise<boolean> {
  return () => {
    appInfoService.init();
    return firstValueFrom(
      appInfoService.wrongApiStructure$.pipe(
        filter(wrongApiStructure => {
          return wrongApiStructure === false;
        }),
        switchMap(() => {
          return keycloakAuthService.init();
        })
      )
    );
  };
}

export function translateHttpFactory(httpClient: HttpClient, prefix: string, suffix: string): TranslateHttpLoader {
  return new TranslateHttpLoader(httpClient, `${prefix}/`, suffix);
}

export const NAVIGATOR = new InjectionToken('Navigator object');

const APP_COMPONENTS = [AppComponent, HeaderComponent, FooterComponent];

@NgModule({
  declarations: [
    APP_COMPONENTS,
    NotFoundPageComponent,
    HomePageComponent,
    ReconnectRequestComponent,
    MenuComponent,
    LanguageSelectionComponent,
    ShortcutsDialogComponent,
    NotSupportPageComponent,
    HomeComponent,
    OopsPageComponent,
    LeavingComponent,
    PwaInstallComponent
  ],
  imports: [
    StoreModule.forRoot(ROOT_STORE_REDUCERS, {
      metaReducers: ['production', 'electron'].includes(environment.type)
        ? ROOT_STORE_PRODMETAREDUCERS
        : ROOT_STORE_DEVMETAREDUCERS
    }),
    EffectsModule.forRoot(ROOT_STORE_EFFECTS),
    ErrorHandlerModule.forRoot(),
    KeycloakAngularModule,
    SharedModule,
    NgxPermissionsModule.forRoot(),
    NgxdModule,
    FileSaverModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatDividerModule,
    TranslateModule,
    HttpClientModule,
    FontAwesomeModule,
    MatToolbarModule,
    MatIconModule,
    MatBadgeModule,
    AngularSvgIconModule.forRoot(),
    ToastrModule.forRoot({
      maxOpened: 5
    }),
    DragDropModule,
    LoggerModule.forRoot({
      level: environment.logLevel,
      enableSourceMaps: true,
      disableConsoleLogging: false,
      serverLogLevel: environment.serverLogLevel,
      serverLoggingUrl: `${environment.origin || ''}/${environment.api}/log?source=${environment.type === 'electron' ? environment.type : 'web'}`
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpFactory,
        deps: [HttpClient, TRANSLATION_PREFIX, TRANSLATION_SUFFIX]
      }
    }),
    TranslationModule.forRoot(),
    WebsocketModule.forRoot(),
    AppRoutingModule,
    MatButtonModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatMenuModule,
    MatDividerModule,
    MatSnackBarModule,
    ImageCropperModule,
    OverlayModule,
    CallModule,
    NotificationModule,
    HammerModule,
    StorageModule.forRoot(),
    ShareIconsModule,
    AvatarSmartModule,

    CoreModule,
    UsersModule,
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: ['production', 'electron'].includes(environment.type) }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled:
        environment.type === 'production' || environment.type === 'staging' || environment.type === 'development',
      // Register the ServiceWorker as soon as the application is stable
      // or after 3 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:3000'
    }),
    InvitingGuestsModule
  ],
  providers: [
    ServiceWorkerApiService,
    PushNotificationsService,
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      multi: true,
      deps: [KeycloakAuthService, RestApiService]
    },
    {
      provide: 'ORIGIN',
      useFactory: generateOrigin
    },
    {
      provide: MatPaginatorIntl,
      useClass: TablePaginatorLocaleService
    },
    MatNativeDateModule,
    {
      provide: 'AUTH_TOKEN_KEY',
      useValue: environment.tokenKey
    },
    {
      provide: 'FILE_TOKEN_KEY',
      useValue: environment.fileTokenKey
    },
    {
      provide: 'APP_CONFIG',
      useValue: <AppConfigModel>{
        webrtcClient: {
          reconnectTimeout: 20000
        },
        notifications: {
          timeout: 5000
        },
        defaultLayoutId: 0,
        defaultConferenceStartDelayInMinutes: 15,
        messageNotSentNotificationTime: 5000
      }
    },

    {
      provide: MAT_RIPPLE_GLOBAL_OPTIONS,
      useValue: <RippleGlobalOptions>{ disabled: true }
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: DATE_FORMATS
    },
    {
      provide: HAMMER_LOADER,
      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
      useValue: () => {
        return new Promise(() => {});
      }
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: GestureConfig
    },
    {
      provide: LocationStrategy,
      useClass: environment.type === 'electron' ? HashLocationStrategy : PathLocationStrategy
    },
    {
      provide: NAVIGATOR,
      useValue: window.navigator
    },
    { provide: OverlayContainer, useClass: OverlayContainer },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: <MatDialogConfig>{
        width: '400px',
        disableClose: false,
        closeOnNavigation: true,
        hasBackdrop: true,
        restoreFocus: false,
        autoFocus: true
      }
    },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: <MatTooltipDefaultOptions>{
        disableTooltipInteractivity: true // при смещении курсора на любой из нижних пунктов контекстного меню, гиперссылка не пропадает, и мешает нажать на другой пункт меню
      }
    },
    {
      provide: MAT_DIALOG_SCROLL_STRATEGY,
      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
      useFactory: () => {
        return inject(Overlay).scrollStrategies.reposition;
      }
    },
    { provide: WEBRTC_MEDIA_EFFECTS, useClass: MediaEffectsService }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: []
})
export class AppModule {
  //todo избавиться от FontAwesome
  constructor(library: FaIconLibrary) {
    library.addIcons(...icons);
  }
}
