import { Expose } from 'class-transformer';

export class ConferenceParticipantConnection {
  @Expose({ name: 'canchange' })
  canChange: boolean;

  @Expose({ name: 'multicast' })
  isMulticast: boolean;

  @Expose({ name: 'multicastavailable' })
  isMutlicastAvailable: boolean;
}
