import { User } from '@breez/models/user.model';
import { Expose, Type } from 'class-transformer';
import { LayoutFrame } from './layout-frame.model';

export class Layout {
  @Expose({ name: 'id' }) id: number;

  @Expose() name: string;
  @Expose() description: string;
  @Expose() creator: User;
  @Expose() created: Date;

  @Expose()
  @Type(() => {
    return LayoutFrame;
  })
  frames: LayoutFrame[];

  resolution?: {
    height: number;
    width: number;
  };

  greatestCommonDivisorForResolution?: {
    height: number;
    width?: number;
  };

  constructor() {
    this.frames = [];
  }
}
