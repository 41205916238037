import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FilterItem } from '@breez/models/filters/filter-item';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FiltersService } from '@breez/shared/services/filters/filters.service';
import moment from 'moment';
import { Params } from '@angular/router';
import { MatMenuTrigger } from '@angular/material/menu';

export interface DateRangeFilterValues {
  startDate: string;
  endDate: string;
  optionValue: string;
}

@Component({
  selector: 'vks-date-range-filter',
  templateUrl: './date-range-filter.component.html',
  styleUrls: ['./date-range-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateRangeFilterComponent implements OnInit, OnChanges {
  @Input()
  item: FilterItem;

  @Input()
  valueFromParams: Params;

  @ViewChild('menu')
  menu: MatMenuTrigger;

  displayedFilterValue: string;
  formGroup: UntypedFormGroup;

  private readonly defaultEmptySymbol = '—';

  private static getDateFromUrl(param: string): Date {
    return param ? moment(param, 'DD-MM-YYYY').toDate() : null;
  }

  static convertToParam(date: Date): string {
    if (date) {
      return moment(date).format('DD-MM-YYYY');
    }
    return null;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private filtersService: FiltersService
  ) {}

  ngOnInit(): void {
    const paramsValues: DateRangeFilterValues = this.getValueFromParams();
    this.formGroup = this.fb.group({
      startDate: [DateRangeFilterComponent.getDateFromUrl(paramsValues.startDate)],
      endDate: [DateRangeFilterComponent.getDateFromUrl(paramsValues.endDate)]
    });
    this.setDisplayedValue(paramsValues);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.valueFromParams && changes.valueFromParams.currentValue) {
      this.setDisplayedValue(this.getValueFromParams());
    }
  }

  selectFilterValue(option: string): void {
    let filterValue = null;
    this.formGroup.reset();
    if (option !== 'ALL' && option !== 'ALL_CONFERENCES') {
      filterValue = option;
    }
    this.displayedFilterValue = option;
    this.filtersService.setFilters(this.createFilterParams(null, null, filterValue));
  }

  setCustomPeriod(): void {
    const startDate = DateRangeFilterComponent.convertToParam(this.formGroup.controls.startDate.value);
    const endDate = DateRangeFilterComponent.convertToParam(this.formGroup.controls.endDate.value);
    this.displayedFilterValue = this.formatValuesToString(startDate, endDate);
    this.filtersService.setFilters(this.createFilterParams(startDate, endDate, null));
  }

  private getValueFromParams(): DateRangeFilterValues {
    const period = {
      start: this.valueFromParams ? this.valueFromParams[this.item.paramName + '_start'] : undefined,
      end: this.valueFromParams ? this.valueFromParams[this.item.paramName + '_end'] : undefined
    };
    const optionValue: string = this.valueFromParams
      ? this.valueFromParams[this.item.paramName.toLowerCase()]
      : undefined;

    return {
      startDate: period.start,
      endDate: period.end,
      optionValue: optionValue
    };
  }

  private setDisplayedValue(paramsValues: DateRangeFilterValues): void {
    if (paramsValues.startDate || paramsValues.endDate) {
      this.displayedFilterValue = this.formatValuesToString(paramsValues.startDate, paramsValues.endDate);
    } else if (paramsValues.optionValue) {
      this.displayedFilterValue = paramsValues.optionValue;
    } else {
      this.displayedFilterValue = this.item.defaultValue.key;
    }
  }

  private createFilterParams(startDate: string, endDate: string, option: string): Params {
    return this.filtersService.createDateRangeParams(this.item.paramName, startDate, endDate, option);
  }

  private formatValuesToString(start: string, end: string): string {
    if (!start && !end) {
      return this.item.defaultValue.key;
    }
    start = start || this.defaultEmptySymbol;
    end = end || this.defaultEmptySymbol;
    return `${start} — ${end}`;
  }
}
