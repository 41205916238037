import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'simplifyNumber'
})
export class SimplifyNumberPipe implements PipeTransform {
  transform(value: any, upTo: number = 99): string {
    if (!value) {
      return '';
    }

    if (typeof value !== 'number') {
      return value;
    }

    if (value > upTo) {
      return upTo + '+';
    }

    return value + '';
  }
}
