import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';

import { catchError, exhaustMap, of } from 'rxjs';
import { filter, map, mergeMap, switchMap, take, withLatestFrom } from 'rxjs/operators';

import { ChatService } from '../../services';
import * as MessageActions from './message.actions';

import * as MessageState from './message.state';
import * as MessageSelectors from './message.selectors';
import { concatMessages, Message, MessageStatusEventEnum } from '@breez/modules/chat';
import { EventTypeEnum } from '@breez/models';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import * as ChatSelectors from '@breez/modules/chat/+state/chat/chat.selectors';
import { waitFor } from '@breez/shared/rxjs-operators/wait-for';
import * as ChatUnreadMessageActions from '@breez/modules/chat/+state/chatUnreadMessage/chatUnreadMessage.actions';
import * as ChatActions from '@breez/modules/chat/+state/chat/chat.actions';
import { AuthService } from '@breez/modules/auth/services/auth.service';
import { ChatAvatarEntityPipe } from '@breez/modules/chat/pipes/chat-avatar-entity.pipe';

@Injectable()
export class MessageEffects {
  // noinspection JSUnusedGlobalSymbols
  loadMessages$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessageActions.loadMessages),

      concatLatestFrom(action => {
        return this.store.select(MessageSelectors.getMessages(action.chatId));
      }),
      // withLatestFrom((action) => this.store.select(MessageSelectors.getMessages(action.chatId))),
      switchMap(([action, messageState]) => {
        if (typeof action.chatId === 'string') {
          return of(
            MessageActions.loadMessagesFailure({
              chatId: action.chatId,
              errorMessage: "action.chatId === 'string'"
            })
          );
        }

        const byIds = action?.ids?.length > 0;
        if (!action?.forcedLoad && !byIds && messageState.length >= action.from + action.count) {
          return of(
            MessageActions.loadMessagesSuccess({
              chatId: action.chatId,
              messages: []
            })
          );
        }
        return (
          byIds
            ? this.chatService.getMessagesById(action.chatId, action?.ids)
            : this.chatService.getMessagesByChatId(action.chatId, action.depth || 1, action.from, action.count)
        ).pipe(
          map(messages => {
            return MessageActions.loadMessagesSuccess({
              chatId: action.chatId,
              messages
            });
          }),
          catchError(errorMessage => {
            return of(
              MessageActions.loadMessagesFailure({
                chatId: action.chatId,
                errorMessage
              })
            );
          })
        );
      })
    );
  });

  // noinspection JSUnusedGlobalSymbols
  sendMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessageActions.sendMessage),
      mergeMap(action => {
        return (
          action.conferenceId
            ? this.chatService.sendMessage(action.message, action.conferenceId)
            : this.chatService.sendMessage(action.message)
        ).pipe(
          map(id => {
            let { message } = action;
            message = { ...message, id } as Message;
            return MessageActions.sendMessageSuccess({ ...action, message });
          }),
          catchError(error => {
            console.error(error.message);
            return of(
              MessageActions.sendMessageFailure({
                ...action,
                errorMessage: error.message
              })
            );
          })
        );
      })
    );
  });

  deleteMessage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessageActions.deleteMessage),
      exhaustMap(action => {
        return this.chatService.deleteMessage(action.messageId, action.messageBody).pipe(
          map(() => {
            return MessageActions.deleteMessageSuccess({ ...action });
          }),
          catchError(error => {
            return of(
              MessageActions.deleteMessageFailure({
                ...action,
                errorMessage: error.message
              })
            );
          })
        );
      })
    );
  });

  // noinspection JSUnusedGlobalSymbols
  deleteMessageSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessageActions.deleteMessageSuccess),
      concatLatestFrom(({ messageId }) => {
        return this.store.select(MessageSelectors.getMessage(messageId)).pipe(take(1));
      }),
      exhaustMap(([{ messageId }, message]) => {
        return of(MessageActions.removeMessageFromState({ message, messageId }));
      })
    );
  });

  // noinspection JSUnusedGlobalSymbols
  unreadMessagesOnRemoveMessageFromState$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessageActions.removeMessageFromState),
      concatLatestFrom(({ messageId }) => {
        return this.store.select(ChatSelectors.chatEntityByLastMessageId(messageId));
      }),
      withLatestFrom(this.authService.currentUser$),
      exhaustMap(([[{ message }, chat], currentUser]) => {
        const actions: Action[] = [];
        if (
          isTruthy(message.id) &&
          message.deliveredUserIds?.includes(currentUser.id) &&
          !message.readUserIds?.includes(currentUser.id)
        ) {
          actions.push(
            ChatUnreadMessageActions.updateUnreadMessagesCount({ chatId: message.chatId, readIds: [message.id] })
          );
        } else {
          actions.push(ChatUnreadMessageActions.loadUnreadMessages({}));
        }
        if (isTruthy(chat)) {
          actions.push(ChatActions.loadChats({ chatsId: [chat.id], forceIfExist: [chat.id] }));
        }
        return of(actions);
      }),
      mergeMap((actions: Action[]) => {
        return actions;
      })
    );
  });

  // noinspection JSUnusedGlobalSymbols
  removeMessageFromState$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessageActions.removeMessageFromState),
      filter(({ message }) => {
        return isTruthy(message) && isTruthy(message?.id);
      }),
      // concatLatestFrom((action) => this.store.select(MessageSelectors.getMessagesRange(action.message?.chatId,null,2))),
      concatLatestFrom(action => {
        return this.store.select(MessageSelectors.getLastMessage(action.message?.chatId)).pipe(take(1));
      }),
      map(([, lastMessages]) => {
        return lastMessages?.chatId
          ? ChatActions.updateLastActionDate({
              chatId: lastMessages.chatId,
              lastAction: lastMessages.modifiedOn ?? lastMessages.sentDate ?? null
            })
          : null;
      }),
      filter(isTruthy)
    );
  });

  // noinspection JSUnusedGlobalSymbols
  updateMessageInStateWaitExist$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessageActions.updateMessageInStateWaitExist),
      // ofType(MessageActions.updateMessageInState),
      waitFor(({ messageId }) => {
        return this.store.select(MessageSelectors.getMessage(messageId)).pipe(
          filter(message => {
            return isTruthy(message.id) && message.id === messageId;
          }),
          take(1)
        );
      }),
      mergeMap(({ messageId, partialMessage }) => {
        return of(MessageActions.updateMessageInState({ messageId, partialMessage }));
      })
    );
  });

  // noinspection JSUnusedGlobalSymbols
  onMessage$ = createEffect(() => {
    return this.chatService.messageEventsEffect$.pipe(
      concatLatestFrom(({ message }) => {
        return this.store.select(MessageSelectors.getMessage(message.id)).pipe(take(1));
      }),
      map(([{ message, eventType }, storeMessage]) => {
        switch (eventType) {
          case EventTypeEnum.INSERT:
            return MessageActions.addMessageToState({ message, messageId: message.id });
          case EventTypeEnum.UPDATE:
            return MessageActions.updateMessageInStateWaitExist({ partialMessage: message, messageId: message.id });
          case EventTypeEnum.DELETE:
            return MessageActions.removeMessageFromState({ message: storeMessage, messageId: message.id });
        }
      })
    );
  });

  // noinspection JSUnusedGlobalSymbols
  onIncomingNotify$ = createEffect(
    () => {
      return this.chatService.messageIncomingEffect$.pipe(
        filter(message => {
          return isTruthy(message?.chat?.id);
        }),
        concatLatestFrom(message => {
          return this.chatAvatarEntityPipe.transform(message.chat);
        }),
        map(([message, chatAvatarEntity]) => {
          return this.chatService.onMessageIncoming(message, chatAvatarEntity?.name);
        }),
        map(() => {
          return null;
        })
      );
    },
    { dispatch: false }
  );

  onAddMessageToState$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessageActions.addMessageToState),
      filter(action => {
        return isTruthy(action?.message?.chat?.id);
      }),
      map(action => {
        return ChatActions.updateLastActionDate({
          chatId: action?.message?.chat?.id,
          lastAction: action.message.modifiedOn ?? action.message.sentDate
        });
      })
    );
  });

  onUpdateMessageInState$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MessageActions.updateMessageInState),
      concatLatestFrom(({ messageId }) => {
        return this.store.select(MessageSelectors.getMessage(messageId)).pipe(take(1));
      }),
      map(([action, storeMessage]) => {
        const newMessage = concatMessages(storeMessage, action.partialMessage);
        return ChatActions.updateLastActionDate({
          chatId: storeMessage.chatId,
          lastAction: newMessage.modifiedOn ?? storeMessage.sentDate
        });
      })
    );
  });

  // noinspection JSUnusedGlobalSymbols
  onMessageStatusUpdate$ = createEffect(() => {
    return this.chatService.messagesStatus$.pipe(
      withLatestFrom(this.authService.currentUser$),
      map(([messageStatusEvent, currentUser]) => {
        if (!isTruthy(messageStatusEvent.messageIds)) return null;
        const { chatId, messageIds, userId } = messageStatusEvent;
        const userIds = [userId];
        let actions = [];
        switch (messageStatusEvent?.type) {
          case MessageStatusEventEnum.DELIVERY:
            actions = messageIds.map(messageId => {
              return MessageActions.updateMessageInStateWaitExist({
                messageId,
                partialMessage: {
                  deliveredUserIds: userIds
                }
              });
            });
            return userId === currentUser.id
              ? [...actions, ChatUnreadMessageActions.updateUnreadMessagesCount({ chatId, unreadIds: messageIds })]
              : actions;

          case MessageStatusEventEnum.READ:
            actions = messageIds.map(messageId => {
              return MessageActions.updateMessageInStateWaitExist({
                messageId,
                partialMessage: {
                  readUserIds: userIds
                }
              });
            });
            return userId === currentUser.id
              ? [...actions, ChatUnreadMessageActions.updateUnreadMessagesCount({ chatId, readIds: messageIds })]
              : actions;
          default:
            return null;
        }
      }),
      filter(isTruthy),
      mergeMap((actions: Action[]) => {
        return actions;
      })
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store<MessageState.State>,
    private chatService: ChatService,
    private authService: AuthService,
    private chatAvatarEntityPipe: ChatAvatarEntityPipe
  ) {}
}
