import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from '@breez/models';

@Component({
  selector: 'vks-user-list-modal',
  templateUrl: './user-list-modal.component.html',
  styleUrls: ['./user-list-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserListModalComponent implements OnInit {
  headerTitle: string;
  title: string;
  subtitle: string;
  users: User[] = [];

  constructor(
    private dialogRef: MatDialogRef<UserListModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public dialogInputData: { headerTitle: string; title: string; subtitle: string; users: User[] }
  ) {}

  ngOnInit(): void {
    const { headerTitle, title, subtitle, users } = this.dialogInputData;
    this.headerTitle = headerTitle;
    this.title = title;
    this.subtitle = subtitle;
    this.users = users;
  }

  close(): void {
    this.dialogRef.close();
  }
}
