import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IAuthResponse, User } from '@breez/models';
import { EntityReference } from '@breez/models/shared/entity-reference.model';
import { AuthParticipant, Participant } from '@breez/models/shared/participant/participant.model';
import { UserRole } from '@breez/modules/auth/model/user-role.model';
import { IWsMessage, WebsocketEvents, WebsocketService } from '@breez/modules/websocket';
import { LocalStorage } from '@breez/shared/modules/storage/interfaces/local-storage.interface';
import { replayWhileSubs, toClass } from '@breez/shared/rxjs-operators';
import { waitFor } from '@breez/shared/rxjs-operators/wait-for';
import { EmailNotificationsService } from '@breez/shared/services/email-notifications.service';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { ElectronService } from '@breez/modules/core/services';
import { BehaviorSubject, EMPTY, Observable, of, OperatorFunction, ReplaySubject, throwError, timer } from 'rxjs';
import {
  catchError,
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  shareReplay,
  skip,
  startWith,
  switchMap,
  take,
  tap,
  timeout
} from 'rxjs/operators';
import { StateService } from '@breez/shared/services/state.service';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakLoginOptions } from 'keycloak-js';
import { APP_KEYCLOAK_REFRESH, APP_KEYCLOAK_TOKEN } from '@breez/rest-api.service';
import { KeycloakAuthService } from './keycloak-auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user$ = new ReplaySubject<User>(1);
  loadingInProgress$: BehaviorSubject<boolean> = new BehaviorSubject(false); // true - если пытаемся авторизаваться
  currentUser$: Observable<User> = this.user$.pipe(shareReplay(1));
  userLogout$: Observable<boolean> = this.currentUser$.pipe(
    filter(user => {
      return !isTruthy(user);
    }),
    mapTo(true),
    shareReplay()
  );

  roles$: Observable<string[]> = this.currentUser$.pipe(
    switchMap(user => {
      return !!user ? this.getRoles() : [];
    }),
    startWith([]),
    replayWhileSubs()
  );

  redirectUrl: string;

  isElectronApp: boolean = this.electronService.isElectron;
  logoutInProcessSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  logoutInProcess$ = this.logoutInProcessSubject$.pipe(distinctUntilChanged(), shareReplay());
  separateAuthToken$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  wsStatus$ = this.wsService.status$;

  offline$ = this.wsStatus$.pipe(
    filter(status => {
      return status === false;
    })
  );

  logoutTrigger$ = this.keycloakAuthService.logoutTrigger$;

  constructor(
    private router: Router,
    @Inject('AUTH_TOKEN_KEY') private authTokenKey: string,
    private keycloakService: KeycloakService,
    private wsService: WebsocketService,
    private emailNotificationsService: EmailNotificationsService,
    private electronService: ElectronService,
    private localStorage: LocalStorage,
    private stateService: StateService,
    private keycloakAuthService: KeycloakAuthService
  ) {
    this.keycloakAuthService.isKeycloakLoggedIn$
      .pipe(
        filter(isLogged => {
          return !isLogged;
        }),
        tap(() => {
          return this.user$.next(null);
        })
      )
      .subscribe();

    this.logoutTrigger$.pipe(take(1)).subscribe(() => {
      return this.keycloakLogout();
    });

    this.keycloakAuthService.authToken$
      .pipe(
        debounceTime(100),
        distinctUntilChanged(),
        switchMap(() => {
          return this.wsStatus$;
        }),
        filter(isSocketInstance => {
          return isSocketInstance === true;
        })
      )
      .subscribe(() => {
        this.authByToken();
      });

    this.userLogout$
      .pipe(
        filter(logout => {
          return isTruthy(logout);
        })
      )
      .subscribe(() => {
        return this.wsLogout(500);
      });
  }

  authLogin(options?: KeycloakLoginOptions): void {
    if (this.isElectronApp) {
      this.loadingInProgress$.next(true);
    }
    this.keycloakAuthService
      .loginToKeycloak(options)
      .pipe(
        take(1),
        filter(isLogin => {
          return this.isElectronApp && isLogin;
        }),
        tap(() => {
          return this.reInitWebSocket();
        }),
        delay(1000),
        switchMap(() => {
          return this.router.navigate(['/']);
        })
      )
      .subscribe(() => {
        if (this.isElectronApp) {
          this.loadingInProgress$.next(false);
        }
      });
  }

  getRoles(): Observable<string[]> {
    return this.currentUser$.pipe(
      switchMap(user => {
        return !!user ? this.wsService.send(WebsocketEvents.RECEIVE.ACCOUNT.GET_ROLES) : [];
      }),
      map((userRoles: UserRole[]) => {
        return userRoles.map(role => {
          return role.name;
        });
      })
    );
  }

  wsLogout(_ = 1200): void {
    this.logoutInProcessSubject$.next(false);
  }

  logout(): void {
    if (this.stateService.isPwaApplication()) {
      (navigator as any).setAppBadge(0);
    }
    this.logoutInProcessSubject$.next(true);
    this.router.navigate(['auth', 'logout']);

    timer(0, 5000)
      .pipe(
        waitFor(() => {
          return this.wsStatus$.pipe(
            filter(status => {
              return status === true;
            })
          );
        }),
        switchMap(() => {
          return this.wsService.query(WebsocketEvents.SEND.AUTHORIZE.LOGOUT, {}, { sendImmediately: true }).pipe(
            timeout(2500),
            catchError(() => {
              return of(false);
            })
          );
        }),
        filter(result => {
          return result;
        }),
        take(1)
      )
      .pipe(
        timeout(30_000),
        catchError(() => {
          return of(false);
        }),
        take(1)
      )
      .subscribe(() => {
        this.logoutInProcessSubject$.next(false);
      });
  }

  keycloakLogout(clearKeycloakService = false): void {
    this.user$.next(null);
    this.stateService.setHeader('');
    this.stateService.notificationsCount$.next(0);
    this.keycloakService.clearToken();
    this.setToken(null);
    this.stateService.removeFromLocal(APP_KEYCLOAK_REFRESH, this.isElectronApp);
    this.stateService.removeFromLocal(APP_KEYCLOAK_TOKEN, this.isElectronApp);
    this.logoutInProcessSubject$.next(false);
    if (clearKeycloakService) {
      this.keycloakService.logout().then();
    } else {
      window.location.reload();
    }
  }

  sendConfirmationEmail(email: string, contextConferenceId?: number): Observable<boolean> {
    return this.wsService
      .send<any>(WebsocketEvents.SEND.AUTHORIZE.SEND_CONFIRMATION_EMAIL, { data: { email, contextConferenceId } })
      .pipe(take(1));
  }

  updateCurrentUser(fields: { [key: string]: any }): Observable<boolean> {
    return this.currentUser$.pipe(
      switchMap(user => {
        return this.wsService.send<boolean>(WebsocketEvents.SEND.USER.UPDATE, { id: user.id, data: fields });
      }),
      take(1),
      waitFor(() => {
        return this.currentUser$.pipe(
          take(1),
          tap(user => {
            return this.user$.next(Object.assign(new User(user), fields));
          })
        );
      }),
      mapTo(true),
      catchError(() => {
        return of(false);
      })
    );
  }

  verifyCode(email: string, code: string, conferenceId: number): Observable<User> {
    return this.wsService
      .send<IAuthResponse>(WebsocketEvents.SEND.CONFERENCE.VERIFY_CODE, { data: { email, code, conferenceId } })
      .pipe(
        take(1),
        switchMap(data => {
          if (data && data.token) {
            return of(data).pipe(
              tap(data_ => {
                return this.setToken(data_.token);
              }),
              this.checkTokenResponse(true),
              waitFor(user => {
                return this.emailNotificationsService.sendEmailTemplate({
                  group: 'conference-webinar',
                  name: 'registration',
                  data: { conferenceId },
                  calendar: { conferenceId },
                  address: user.email
                });
              })
            );
          } else {
            return of(null);
          }
        })
      );
  }

  authenticateByToken(conferenceId: number, token: string): Observable<Participant> {
    return this.wsService
      .send<AuthParticipant>(WebsocketEvents.SEND.CONFERENCE.AUTH_BY_TOKEN, {
        data: {
          token,
          conferenceId
        }
      })
      .pipe(
        take(1),
        catchError(() => {
          return this.router.navigate(['/', 'conference', conferenceId, 'enter']);
        }),
        map(plain => {
          return Array.isArray(plain) ? plain[0] : plain;
        }),
        toClass(AuthParticipant),
        switchMap(participant => {
          // TODO костыль: про людей пришедших без регистрации в имени теперь прилетает guestXXX; оставить пока не появится
          //  событие в сокетах про апдейт имени юзера
          if (!!participant && !!participant.authToken) {
            this.connectByToken(participant.authToken);
            participant.authToken = null;

            return this.currentUser$.pipe(
              // дожидаемся следующего юзера (который чуть позже залогинится по токену), так как послали перед этим connectByToken
              skip(1),
              take(1),
              map(user => {
                if (!user) {
                  return participant;
                }

                participant.participantReference = new EntityReference({
                  id: user.id,
                  name: user.name,
                  email: user.email
                });
                return participant;
              })
            );
          }

          // если authToken в AuthParticipant не падал, значит юзер уже логинился и в observable висит то что нужно
          return this.currentUser$.pipe(
            map(user => {
              if (!user) {
                return participant;
              }

              participant.participantReference.name = user.name;
              return participant;
            })
          );
          // </костыль>
        })
      );
  }

  private authByToken(): void {
    this.keycloakAuthService.accessToken$
      .pipe(
        take(1),
        switchMap(token => {
          return isTruthy(token)
            ? this.wsService.query<IAuthResponse>(
                WebsocketEvents.SEND.AUTHORIZE.LOGIN,
                { data: { token } },
                { sendImmediately: true }
              )
            : of(
                throwError(() => {
                  return new Error('Something went wrong while authenticating');
                })
              );
        }),

        this.checkAuthResponse(false)
      )
      .subscribe();
  }

  private connectByToken(authToken?: string, lostconnection = false): void {
    const token = authToken ?? this.separateAuthToken$.value ?? this.localStorage.getItem(this.authTokenKey);
    if (!token) {
      this.user$.next(null);
      return;
    }

    this.wsService
      .send<IAuthResponse>(WebsocketEvents.SEND.AUTHORIZE.RECONNECT, { data: { token, lostconnection } })
      .pipe(this.checkTokenResponse())
      .subscribe();
  }

  private checkAuthResponse(skipError: boolean = false): OperatorFunction<IAuthResponse, User> {
    return input$ => {
      return input$.pipe(
        catchError(({ data, error }) => {
          this.loadingInProgress$.next(false);
          if (data.code === 401) {
            const refreshToken = this.stateService.getFromLocal<string>(APP_KEYCLOAK_REFRESH, true);
            if (this.isElectronApp && isTruthy(refreshToken)) {
              window.location.reload();
            } else {
              this.keycloakLogout(true);
            }
          }

          return skipError ? EMPTY : throwError(error);
        }),
        filter(data => {
          return isTruthy(data.userid);
        }),
        take(1),
        toClass(User),
        tap(user => {
          this.user$.next(user);
          this.wsService.connectionWasLostSubject$.next(false);
        })
      );
    };
  }

  private checkTokenResponse(skipError: boolean = false): OperatorFunction<IAuthResponse, User> {
    return input$ => {
      return input$.pipe(
        catchError((error: IWsMessage) => {
          this.loadingInProgress$.next(false);

          if (error.code === 401) {
            this.keycloakLogout();
            this.user$.next(null);
          }

          return skipError ? EMPTY : throwError(error);
        }),
        filter(data => {
          return isTruthy(data.token);
        }),
        tap(data => {
          return this.setToken(data.token);
        }),
        switchMap(() => {
          return this.wsService.send<User>(WebsocketEvents.SEND.USER.CURRENT);
        }),
        take(1),
        toClass(User),
        tap(user => {
          this.user$.next(user);
          this.wsService.connectionWasLostSubject$.next(false);
        })
      );
    };
  }

  checkRoles$(roles: string[]): Observable<boolean> {
    return this.roles$.pipe(
      switchMap(userRoles => {
        return userRoles.length > 0 ? of(userRoles) : of([]);
      }),
      map(userRoles => {
        return roles.every(role => {
          return userRoles.includes(role);
        });
      })
    );
  }

  setToken(token: string): void {
    this.keycloakAuthService.setToken(token).subscribe();
  }

  reInitWebSocket(): void {
    this.wsService.authInstanceTrigger$.next();
  }
}
