import { Action, createReducer, on } from '@ngrx/store';
import * as ChatActions from './chat.actions';
import * as ChatState from './chat.state';
import { EMPTY_CHAT_ENTITY } from '@breez/modules/chat';
import { isTruthy } from '@breez/shared/utilities/is-truthy';

// @ts-ignore
const reducer = createReducer(
  ChatState.initialState,
  on(ChatActions.loadChatsSuccess, (state, { chats }) => {
    return ChatState.adapter.upsertMany(chats, state);
  }),

  on(ChatActions.createChat, (state, action) => {
    return ChatState.adapter.addOne({ ...EMPTY_CHAT_ENTITY, ...action.chat, id: action.tempId }, state);
  }),

  on(
    ChatActions.createChatSuccess, // add to store
    (state, action) => {
      let newState;
      if (isTruthy(action.tempId) && action.tempId !== action.chat.id) {
        // @ts-ignore
        newState = ChatState.adapter.updateOne(
          { id: action.tempId as any, changes: { id: action.chat.id } },
          { ...state, selectedId: action.chat.id }
        );
      }
      // @ts-ignore
      return ChatState.adapter.upsertOne(action.chat, newState ?? state);
    }
  ),

  on(ChatActions.changeChatStateSuccess, (state, { chatId, remove }) => {
    if (remove) {
      // @ts-ignore
      return ChatState.adapter.removeOne(chatId, state); //TODO ADD
    }
    return state;
  }),

  on(ChatActions.changeChatSuccess, (state, { chat }) => {
    // @ts-ignore
    return ChatState.adapter.updateOne({ id: chat.id, changes: chat }, state);
  }),

  on(ChatActions.changePinSuccess, (state, { chatId, pinnedOrder }) => {
    // @ts-ignore
    return ChatState.adapter.updateOne({ id: chatId, changes: { pinnedOrder } }, state);
  }),

  on(ChatActions.updateLastActionDate, (state, { chatId, lastAction }) => {
    // @ts-ignore
    return ChatState.adapter.updateOne({ id: chatId, changes: { lastAction } }, state);
  }),

  // TODO

  on(ChatActions.selectChat, (state, { chatId }) => {
    //делаем сравнение без типов
    return state.selectedId == chatId ? state : { ...state, selectedId: chatId };
  }),
  on(ChatActions.deselectChat, state => {
    return { ...state, selectedId: null };
  }),
  on(ChatActions.markAsLoaded, state => {
    return { ...state, isLoad: true };
  }),
  on(ChatActions.markAsNotLoaded, state => {
    return { ...state, isLoad: false };
  }),
  on(ChatActions.markAsConference, (state, { conferenceId }) => {
    return { ...state, isConference: conferenceId };
  }),
  on(ChatActions.markAsNotConference, state => {
    return { ...state, isConference: null, isLoad: false };
  })
);

export function chatReducer(state: ChatState.State | undefined, action: Action): ChatState.State {
  return reducer(state, action);
}
