import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AccountActions from './account.actions';
import { map, mergeMap, skipWhile } from 'rxjs';
import { WebsocketEvents, WebsocketService } from '@breez/modules/websocket';
import { Account } from '@breez/shared/models/account.model';

@Injectable()
export class AccountEffects {
  accountInit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AccountActions.loadAccount),
      mergeMap(() => {
        return this.wsService.listen({ path: WebsocketEvents.RECEIVE.ACCOUNT.INIT }).pipe(
          skipWhile(event => {
            return !event;
          }),
          map((account: Account) => {
            return AccountActions.loadAccountSuccess({ account });
          })
        );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private wsService: WebsocketService
  ) {}
}
