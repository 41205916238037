import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

@Injectable()
export class PipeTranslationDependency implements OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  protected savedValue;

  constructor(
    protected locale: string,
    translateService: TranslateService
  ) {
    translateService.onLangChange
      .pipe(
        map(event => {
          return event.lang;
        }),
        startWith(translateService.currentLang),
        takeUntil(this.destroy$)
      )
      .subscribe(language => {
        this.locale = language;
        this.savedValue = null;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
