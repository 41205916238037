<div class="enter-header" [class.no-shadow]="isElectronApp">
  <ng-container *ngIf="isElectronApp | not">
    <div></div>
    <div [routerLink]="['/']" class="logo">
      <div [class]="'logo-content ' + (languageLogoClass$ | async)"></div>
    </div>
  </ng-container>
</div>
<vks-layout>
  <div class="content">
    <div class="content_message">
      <div class="content_message_header">
        {{ 'GOTO_HEADER' | translate }}
      </div>
      <div class="content_message_info">
        {{ 'GOTO_INFO' | translate }}
      </div>
      <div class="content_message_problem">
        {{ 'GOTO_PROBLEM' | translate }}<a [routerLink]="['/']">{{ 'GOTO_PROBLEM_LINK' | translate | lowercase }}</a>
      </div>
      <div></div>
      <div></div>
      <vks-control-language-selector-menu></vks-control-language-selector-menu>
    </div>
  </div>
</vks-layout>
