import { Expose } from 'class-transformer';

export class Room {
  @Expose({ name: 'roomid' })
  id: number;

  @Expose({ name: 'room_name' })
  name: string;

  @Expose({ name: 'room_descr' })
  description: string;

  constructor(base: Partial<Room>) {
    return Object.assign(this, base);
  }
}
