import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as breezValidators from '@breez/shared/validators';
import { WebsocketService } from '@breez/modules/websocket';
import { APP_ELECTRON_ORIGIN_KEY, IS_SERVER_NOT_FILLED_BY_USER, RestApiService } from '@breez/rest-api.service';
import { ConfirmPopupData } from '@breez/shared/models/confrim-popup-data.model';
import { distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { ConfirmPopupService } from '@breez/shared/services/confirm-popup/confirm-popup.service';
import { of } from 'rxjs';
import { StateService } from '@breez/shared/services/state.service';
import { ElectronService } from '@breez/modules/core/services';
import { replayWhileSubs } from '@breez/shared/rxjs-operators';
import { AuthService } from '@breez/modules/auth/services/auth.service';

@Component({
  selector: 'vks-select-server',
  templateUrl: './select-server.component.html',
  styleUrls: ['./select-server.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectServerComponent implements OnInit {
  readonly isElectronApp: boolean = this.electronService.isElectron;
  serverForm: FormGroup;

  serverError$ = this.restApiService.wrongApiStructure$.pipe(distinctUntilChanged(), replayWhileSubs());

  constructor(
    private electronService: ElectronService,
    private websocketService: WebsocketService,
    private restApiService: RestApiService,
    private confirmPopupService: ConfirmPopupService,
    private stateService: StateService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    const isServerNotFilled = this.restApiService.getFromLocal<string>(IS_SERVER_NOT_FILLED_BY_USER);
    this.restApiService.url$.pipe(filter(isTruthy), take(1)).subscribe(initialUrl => {
      this.serverForm = new FormGroup({
        serverUrl: new FormControl(isServerNotFilled ? null : initialUrl, [
          Validators.required,
          breezValidators.serverUrlValidator()
        ])
      });
    });
  }

  updateServerUrl(newUrl: string): void {
    this.restApiService.removeFromLocal(IS_SERVER_NOT_FILLED_BY_USER);
    const confirmData: ConfirmPopupData | string = { question: 'NEW_SERVER_URL_QUESTION', action: 'CONTINUE' };
    this.restApiService.url$
      .pipe(
        filter(() => {
          return this.isElectronApp;
        }),
        take(1),
        map(storedUrl => {
          return this.restApiService.clearUrl(storedUrl).toLowerCase().trim() !== newUrl.toLowerCase().trim();
        }),
        switchMap(needUpdate => {
          return needUpdate
            ? this.confirmPopupService.confirm(confirmData).pipe(
                map(confirmation => {
                  if (confirmation) {
                    this.stateService.saveToLocal(APP_ELECTRON_ORIGIN_KEY, newUrl);
                    this.websocketService.updateWebsocketUrl(newUrl);
                    this.electronService.electronApi.send('app-need-restart', true);
                  }
                  return null;
                })
              )
            : of(true);
        }),
        filter(isTruthy)
      )
      .subscribe(() => {
        this.authService.authLogin();
      });
  }

  update(): void {
    if (this.serverForm.invalid) {
      this.serverForm.markAllAsTouched();
      return;
    }

    const serverUrl = this.serverForm.controls.serverUrl.value;
    this.updateServerUrl(serverUrl);
  }
}
