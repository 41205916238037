import { CHAT_ID } from '../types';

export interface BlockChatRequest {
  reason: string;
  userId: number;
  chatId: CHAT_ID;
  conferenceId: number;
  duration: Date;
  expires: Date;
  viewExpires: Date;
  banned: boolean;
}
