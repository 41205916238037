import { Inject, Injectable, Type } from '@angular/core';
import { NgxdResolver } from '@ngxd/core';

import {
  CALLSTATUS_PROVIDER,
  CallStatusComponentProvider
} from '@breez/modules/call/call-statuses/call-status-component.provider';

@Injectable()
export class CallStatusComponentResolver extends NgxdResolver<string, Type<any>> {
  constructor(@Inject(CALLSTATUS_PROVIDER) private providers: CallStatusComponentProvider[]) {
    super(providers);
  }
}
