<h1>Welcome home!</h1>
<ul>
  <li>
    <a routerLink="/conference/add">Planner</a>
  </li>
  <li>
    <a routerLink="/conference">Conferences list</a>
  </li>
  <li>
    <a routerLink="/conference/new-list">New conferences list</a>
  </li>
  <li>
    <a routerLink="/auth">Auth</a>
  </li>
</ul>
<p>
  <button (click)="logout()" mat-stroked-button>Logout</button>
  <button (click)="setOperatorRights()" mat-stroked-button>Set operator rights</button>
</p>

<!--<div class="user-selector">
    <vks-user-select></vks-user-select>
</div>-->
