import { Inject, Injectable } from '@angular/core';
import { IUserStatusSource } from '@breez/modules/user/modules/models/user-status-source.model';
import { UserStatus } from '@breez/modules/user/modules/models/user-status.enum';
import { filter, finalize, map, shareReplay, switchMap } from 'rxjs/operators';
import { arrToClass, toClass } from '@breez/shared/rxjs-operators';
import { WebsocketEvents, WebsocketService } from '@breez/modules/websocket';
import { Observable } from 'rxjs';
import { UserStatusEvent, UserStatusSubscribeEvent } from '@breez/modules/user/modules/models/user-status-event.model';
import { USER_GROUP } from '@breez/modules/social/user-group-context.provider';
import { UserGroup } from '@breez/modules/social/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { convertObjectToArray } from '@breez/shared/utilities/convert-to-array';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { UserStatusStorageService } from '@breez/modules/user/modules/avatar-smart/services/user-status-storage.service';
import { ObjectType } from '@breez/shared/enums/object-type.enum';

/* TODO я расплодил три почти одинаковых сервиса и мне очень стыдно.
UserStatusChatContextSourceService
UserStatusConferenceContextSourceService
UserStatusUserGroupContextSourceService
 */

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class UserStatusUserGroupContextSourceService implements IUserStatusSource {
  userStatusesObserver$ = this.userGroup$.pipe(
    filter(isTruthy),
    switchMap(userGroup => {
      return this.observeUserGroup(userGroup);
    }),
    untilDestroyed(this),
    shareReplay(1)
  );

  constructor(
    @Inject(USER_GROUP) private readonly userGroup$: Observable<UserGroup>,
    private readonly wsService: WebsocketService,
    private readonly userStatusStorageService: UserStatusStorageService
  ) {
    this.userStatusesObserver$.pipe(untilDestroyed(this)).subscribe(events => {
      return events.forEach(({ userId, status }) => {
        return this.userStatusStorageService.setByUserId(userId, status);
      });
    });
  }

  observeUserStatusById(userId: number): Observable<UserStatus> {
    return this.userStatusesObserver$.pipe(
      map(events => {
        return events.find(event => {
          return event.userId === userId;
        });
      }),
      filter(isTruthy),
      map(({ status }) => {
        return status;
      })
    );
  }

  private observeUserGroup(userGroup: UserGroup): Observable<UserStatusEvent[]> {
    const userGroupIdentifier = userGroup.id || userGroup.type;

    return this.wsService
      .observe(
        WebsocketEvents.USER.STATUS.OBSERVE,
        {
          parent: { resource: ObjectType.USER, id: userGroupIdentifier },
          data: { entitytype: ObjectType.USER_GROUP }
        },
        { responseFrom: { path: WebsocketEvents.USER.STATUS.EVENT, parent: null } }
      )
      .pipe(
        toClass(UserStatusSubscribeEvent),
        map(({ data }) => {
          return data;
        }),
        map(dict => {
          return convertObjectToArray<UserStatus>(dict);
        }),
        arrToClass(UserStatusEvent),
        finalize(() => {
          return this.wsService.query(
            WebsocketEvents.USER.STATUS.LEAVE,
            {
              parent: { resource: ObjectType.USER, id: userGroupIdentifier },
              data: { entitytype: ObjectType.USER_GROUP }
            },
            { sendImmediately: true }
          );
        })
      );
  }
}
