import { Expose, Type } from 'class-transformer';
import { MessageEvent } from './message-event.model';
import { Chat } from './chat';
import { User } from '@breez/models';
import { Event } from '@breez/shared/models/event.model';

export class IncomingMessagesEvent {
  @Expose({ name: 'messages' })
  @Type(() => {
    return MessageEvent;
  })
  messageEvents: MessageEvent[];

  @Expose()
  @Type(() => {
    return Chat;
  })
  chats: Chat[];

  @Expose()
  @Type(() => {
    return User;
  })
  users: User[];
}

export class IncomingMessagesSubscribeEvent extends Event<IncomingMessagesEvent> {
  @Expose()
  @Type(() => {
    return IncomingMessagesEvent;
  })
  data: IncomingMessagesEvent;
}
