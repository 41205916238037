import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'vks-pop-up-header',
  templateUrl: './pop-up-header.component.html',
  styleUrls: ['./pop-up-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopUpHeaderComponent {
  @Input()
  text: string;
}
