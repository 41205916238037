import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Base64SavingFile } from '@breez/models/shared/saving-file.model';
import { AppService } from '@breez/app.service';
import { base64ToArrayBuffer } from '@breez/shared/utilities/base64ToBytes';

@Component({
  selector: 'vks-base64-file-saver',
  templateUrl: './base64-file-saver.component.html',
  styleUrls: ['./base64-file-saver.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Base64FileSaverComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private appService: AppService
  ) {}

  ngOnInit(): void {
    const snapshot = this.route.snapshot;
    const file: Base64SavingFile = snapshot.data.file;
    const byteArray: Uint8Array = base64ToArrayBuffer(file.blob);
    this.appService.saveFileByByteArray(byteArray, file.name);
  }
}
