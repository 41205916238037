import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OVERLAY_PROVIDERS } from './overlay-provider';
import { IMAGE_PREVIEW_COMPONENTS } from './components';
import { SharedModule } from '@breez/shared/shared.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [IMAGE_PREVIEW_COMPONENTS],
  imports: [CommonModule, SharedModule, MatTooltipModule, TranslateModule],
  providers: [OVERLAY_PROVIDERS]
})
export class ImagePreviewModule {}
