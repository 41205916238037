import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { OverlayContainer } from '@breez/modules/overlay/models';
import { INotificationModel } from '@breez/models/notification';
import { Observable } from 'rxjs';
import { Link } from '@breez/models/link.model';
import { map } from 'rxjs/operators';
import { LinksService } from '@breez/shared/services/links.service';

@Component({
  selector: 'vks-notification-message',
  templateUrl: './type.component.html',
  styleUrls: ['./type.component.scss', './type.component.media-max800.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationMessageComponent {
  @Input() notification: INotificationModel;
  @Output() changeOverlayContainer = new EventEmitter<OverlayContainer>();

  helpLink$: Observable<Link> = this.linksService.getLinks().pipe(
    map(links => {
      return links.find(link => {
        return link.name === 'Справка и поддержка';
      });
    })
  );

  constructor(private readonly linksService: LinksService) {}

  onClose(): void {
    this.changeOverlayContainer.emit(null);
  }

  openHelpLink(url: string): void {
    window.open(url);
  }
}
