import { createEntityAdapter } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { User } from '@breez/models';
import * as ModuleState from '../module.state';
import { USER_FEATURE_KEY } from '@breez/modules/users/+state/user-feature-key.const';
import { CHAT_ID } from '@breez/modules/chat';
import { USER_ID } from '@breez/modules/users/types/user-id.type';
import { getParticipantsEntity } from '@breez/modules/chat/+state/participants/participants.selectors';

export const getFeatureState = createFeatureSelector<ModuleState.State>(USER_FEATURE_KEY);
export const getState = createSelector(getFeatureState, state => {
  return state?.user;
});

const { selectAll } = createEntityAdapter<User>().getSelectors(getState);

export const users = selectAll;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const userById = (id: USER_ID) => {
  return createSelector(getState, state => {
    return state.entities[id];
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const usersByIds = (ids: USER_ID[]) => {
  return createSelector(getState, state => {
    return ids.map(id => {
      return state.entities[id];
    });
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getUsers = () => {
  return createSelector(getState, state => {
    return Object.values(state.entities) as User[];
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const usersByChatId = (chatId: CHAT_ID) => {
  return createSelector(getState, getParticipantsEntity(chatId), (state, ids) => {
    return ids.map(id => {
      return state.entities[id];
    });
  });
};
