import { isTruthy } from '@breez/shared/utilities/is-truthy';

function playAudio(audio: HTMLAudioElement, ringtoneName: string, loop: boolean = false): HTMLAudioElement {
  if (!!audio) {
    audio.pause();
  } else {
    audio = new Audio();
  }

  audio.src = `assets/sounds/${ringtoneName}.ogg`;
  audio.loop = loop;

  const play$ = audio.play();
  if (isTruthy(play$)) {
    play$.then();
    return audio;
  }

  return null;
}

export const audio = {
  play: playAudio
};
