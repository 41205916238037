import { Injectable } from '@angular/core';
import { toDataURL } from 'qrcode';
import { Observable, from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QrCodeService {
  getDataUrl(data: string, size = 256): Observable<string> {
    return from(toDataURL(data, { width: size }));
  }
}
