import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { MessageRange } from '@breez/modules/chat/models/+state/messageRange';

interface State extends EntityState<MessageRange> {}
const adapter: EntityAdapter<MessageRange> = createEntityAdapter<MessageRange>({
  // @ts-ignore
  selectId: (range: MessageRange) => {
    return range.chatId;
  },
  sortComparer: (_, __) => {
    return 1;
  }
});

const initialState: State = adapter.getInitialState({});

export { State, adapter, initialState };
