import { ObjectListResponse } from '@breez/models/shared/paging';
import { Expose, Type } from 'class-transformer';
import { Contact } from './contact.model';

export class ContactListResponse extends ObjectListResponse<Contact> {
  @Expose()
  @Type(() => {
    return Contact;
  })
  data: Contact[];

  liveContacts: Contact[];
}
