<form (ngSubmit)="update()" [formGroup]="serverForm">
  <div class="control-row">
    <div class="va-middle toggle" vks-control-group>
      <label for="server">{{ 'SERVER' | translate }}</label>
      <input formControlName="serverUrl" id="server" type="url" vks-cinput />
      <span
        *ngIf="(serverError$ | async) || (serverForm.touched && serverForm.get('serverUrl').errors?.invalidserverurl)"
        class="error"
        >{{ 'WRONG_SERVER_URL' | translate }}</span
      >
      <span *ngIf="serverForm.touched && serverForm.get('serverUrl').errors?.required" class="error">{{
        'BREEZ_REGISTRATION_NOT_EMPTY_ERROR' | translate
      }}</span>
    </div>
    <div class="va-middle" vks-control-group>
      <button [label]="'CONTINUE' | translate | uppercase" buttonType="submit" class="btn-primary" vks-button></button>
    </div>
  </div>
</form>
