import { Inject, Injectable } from '@angular/core';
import { INotificationModel, NotificationProvider, NotificationType } from '@breez/models';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { NOTIFICATION_PROVIDER } from './notification.provider';
import { PushNotificationService } from './push-browser.service';
import { AppConfigModel } from '@breez/models/app-config.model';
import { TranslateService } from '@ngx-translate/core';
import { map, take, tap } from 'rxjs/operators';
import { NotificationData } from '@breez/modules/notification/models/notification-data.model';
import {
  AppNotification,
  AppNotificationAction,
  AppNotificationData
} from '@breez/modules/notification/models/app-notification.model';
import { PushNotification } from '@breez/modules/notification/models/push-notification.model';
import { DocumentNotification } from '@breez/modules/notification/models/document-notification.model';
import { EMPTY, merge, Observable, Subject } from 'rxjs';
import { OverlayPositionType } from '@breez/modules/overlay/models/overlay-position-type.enum';
import { OverlayEntryType } from '@breez/modules/overlay/models/overlay-entry-type.enum';
import { OverlayService } from '@breez/modules/overlay/services/overlay.service';
import { OverlayEntry } from '@breez/modules/overlay/models';
import { PlacementType } from '@breez/models/notification/placement-type.enum';
import { ElectronService } from '@breez/modules/core/services';
import { StateService } from '@breez/shared/services/state.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(
    private electronService: ElectronService,
    private toastrService: ToastrService,
    private pushNotificationService: PushNotificationService,
    private translateService: TranslateService,
    private overlayService: OverlayService,
    private stateService: StateService,
    @Inject(NOTIFICATION_PROVIDER) private configs: NotificationProvider[],
    @Inject('APP_CONFIG') private appConfig: AppConfigModel
  ) {}

  /**
   * @deprecated Should be used __notify__
   */
  show(notification: INotificationModel): void {
    const individualConfig = this.getConfig(notification.config, notification.type);

    if (!individualConfig) {
      return;
    }

    const title = individualConfig.title || '';

    this.translateService
      .get(title)
      .pipe(
        tap(translatedTitle => {
          if (!this.stateService.isAppVisible()) {
            return;
          }
          let toast: ActiveToast<any> = null;
          switch (notification.type) {
            case NotificationType.Success:
              toast = this.toastrService.success(notification.message, translatedTitle, individualConfig);
              break;
            case NotificationType.Information:
              toast = this.toastrService.info(notification.message, translatedTitle, individualConfig);
              break;
            case NotificationType.Warning:
              toast = this.toastrService.warning(notification.message, translatedTitle, individualConfig);
              break;
            case NotificationType.Error:
              toast = this.toastrService.error(notification.message, translatedTitle, individualConfig);
              break;
          }
          return toast;
        })
      )
      .subscribe(toast => {
        if (notification.afterClick && toast) {
          notification.afterClick.call(this);
        }
      });
  }

  /**
   * @deprecated Should be used __notify__
   */
  showWithTranslate(notification: INotificationModel): void {
    this.translateService
      .get(notification.message)
      .pipe(take(1))
      .subscribe(translate => {
        notification.message = translate;
        this.show(notification);
      });
  }

  notify(data: AppNotificationData): AppNotification {
    let documentNotification: DocumentNotification;
    let pushNotification: PushNotification;

    const clickTriggers: Observable<AppNotificationAction>[] = [];
    const closeTriggers: Observable<AppNotificationAction>[] = [];

    switch (data.placement) {
      case PlacementType.BOTH: {
        if (!document.hasFocus() || data.forcePushNotification) {
          pushNotification = this.createPushNotification(data);
        }
        if (document.hasFocus() || data.forceDocumentNotification) {
          documentNotification = this.createDocumentNotification(data);
        }
        break;
      }
      case PlacementType.DOCUMENT: {
        if (data.forceDocumentNotification || document.hasFocus()) {
          documentNotification = this.createDocumentNotification(data);
        }
        break;
      }
      case PlacementType.PUSH: {
        if (data.forcePushNotification || !document.hasFocus()) {
          pushNotification = this.createPushNotification(data);
        }
        break;
      }
    }

    if (pushNotification) {
      clickTriggers.push(
        pushNotification.onClick$.pipe(
          map(event => {
            return <AppNotificationAction>{ event, isPushTarget: false };
          })
        )
      );
      closeTriggers.push(
        pushNotification.onClick$.pipe(
          map(event => {
            return <AppNotificationAction>{ event, isPushTarget: false };
          })
        )
      );
    }

    if (documentNotification) {
      clickTriggers.push(
        documentNotification.onClick$.pipe(
          map(event => {
            return <AppNotificationAction>{ event, isPushTarget: false };
          })
        )
      );
      closeTriggers.push(
        documentNotification.onClick$.pipe(
          map(event => {
            return <AppNotificationAction>{ event, isPushTarget: false };
          })
        )
      );
      this.overlayService.addContainer(documentNotification.overlayEntry);
    }

    return <AppNotification>{
      data,
      onClick$: merge(...clickTriggers).pipe(
        tap(() => {
          this.electronService.windowShow(true);
        })
      ),
      onDismiss$: merge(...closeTriggers),
      close() {
        if (documentNotification) {
          documentNotification.close();
        }
        if (pushNotification) {
          pushNotification.close();
        }
      }
    };
  }

  private createDocumentNotification(data: AppNotificationData): DocumentNotification {
    const position = data.position || OverlayPositionType.SIDE;

    const type = data.type || NotificationType.Information;

    const clickEvent$ = new Subject<Event>();

    const id = data.containerId ? data.containerId : {};

    const entry: OverlayEntry = {
      type: OverlayEntryType.NOTIFICATION,
      id,
      position,
      data: <INotificationModel>{
        type,
        timeout: data.timeout,
        message: data.message,
        title: data.title,
        subtitle: data.subtitle,
        iconUrl: data.iconUrl,
        imageUrl: data.imageUrl,
        avatar: data.avatar,
        afterClick: () => {
          return clickEvent$.next(null);
        }
      }
    };

    return <DocumentNotification>{
      data,
      onClick$: clickEvent$,
      onDismiss$: EMPTY,
      overlayEntry: entry,
      close: () => {
        return this.overlayService.removeContainer(entry);
      }
    };
  }

  private createPushNotification(data: NotificationData): PushNotification {
    return this.pushNotificationService.create(data);
  }

  destroy(): void {}

  private getConfig(actionConfig: NotificationProvider, actionType: NotificationType): NotificationProvider {
    if (actionConfig) {
      return actionConfig;
    }

    actionConfig = this.configs.find(provider => {
      return provider.type === actionType && provider.isDefault;
    });

    if (!actionConfig.timeOut) {
      actionConfig.timeOut = this.appConfig.notifications.timeout;
    }

    return actionConfig;
  }
}
