import { Injectable } from '@angular/core';
import { SwPush, SwUpdate } from '@angular/service-worker';
import { EMPTY, from, Observable, skipWhile, Subject } from 'rxjs';
import { catchError, delay, switchMap, take } from 'rxjs/operators';
import { ServiceWorkerApiService } from '@breez/shared/push-service/service-worker-api.service';
import {
  Credentials,
  NotificationSettingsItem,
  NotificationsPermissionEnum
} from '@breez/shared/push-service/interface/push-service.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@breez/modules/auth/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

export const IS_USER_HIDE_PWA_INSTALLER_COMPONENT: string = 'is-user-hide-pwa-installer-component';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class PushNotificationsService {
  isPwaApp: boolean = this.isPwaApplication();
  private readonly vapidPublicKey$: Observable<string> = this.swApiService.getVapidPublicKey();
  private vapidPublicKey: string;
  private notificationsAllowedData: NotificationSettingsItem = { active: null };
  private readonly showPwaInstallComponentSubject: Subject<void> = new Subject();
  readonly showPwaInstallComponent$: Observable<void> = this.showPwaInstallComponentSubject.asObservable();
  private notificationsPermission: string;

  constructor(
    private update: SwUpdate,
    private swPush: SwPush,
    private swApiService: ServiceWorkerApiService,
    private translateService: TranslateService,
    private authService: AuthService,
    private snackBar: MatSnackBar
  ) {
    this.vapidPublicKey$.pipe(take(1)).subscribe(vapidPublicKey => {
      this.vapidPublicKey = vapidPublicKey;
      if (this.isPwaApplication()) {
        this.fullSubscribe();
      } else if (this.isWindows()) {
        window.addEventListener(
          'appinstalled',
          () => {
            setTimeout(() => {
              return this.fullSubscribe();
            }, 500);
          },
          { once: true }
        );
      }
    });
  }

  /**
   * проверка, можем ли мы отобразить окно компонента установки PWA
   */
  showInstallPwaComponentByCondition(): void {
    const isUserHidePwaInstallerComponent: boolean =
      localStorage.getItem(IS_USER_HIDE_PWA_INSTALLER_COMPONENT) === 'true';
    if (isUserHidePwaInstallerComponent || this.isPwaApplication() || this.notAllowedBrowser()) {
      return;
    }

    this.authService.user$
      .pipe(
        skipWhile(user => {
          return !user || (window.location.href.includes('/conference/') && !window.location.href.includes('calendar'));
        }),
        take(1),
        delay(5000)
      )
      .subscribe(() => {
        return this.showInstallPwaComponent();
      });
  }

  private subscribeToPushInSafari(): void {
    window.addEventListener(
      'click',
      () => {
        this.subscribeToPush();
      },
      { once: true }
    );
  }

  /**
   * Непосредственно подписка
   */
  private subscribeToPush(): void {
    this.authService.user$
      .pipe(
        skipWhile(user => {
          return !user;
        }),
        switchMap(() => {
          return this.swPush.subscription;
        }),
        take(1),
        switchMap(subscription => {
          if (subscription) {
            return EMPTY;
          }

          return from(this.requestSubscription());
        }),
        switchMap(credentials => {
          if (!credentials) {
            return EMPTY;
          }

          const creds = JSON.parse(credentials);
          if (!creds.hasOwnProperty('endpoint')) {
            return EMPTY;
          }

          return this.swApiService.sendCredentials({ ...creds } as Credentials);
        }),
        catchError(err => {
          this.resetNotificationsObject();
          this.showErrorMessage(err);
          throw { data: false };
        })
      )
      .subscribe(response => {
        if (response === true) {
          return;
        }

        this.resetNotificationsObject();
        this.showErrorMessage(response);
      });
  }

  private showErrorMessage(errorMessage: any): void {
    alert(
      errorMessage
        ? `${this.translateService.instant('HAS_SERVER_PROBLEM')}: ${JSON.stringify(errorMessage)}`
        : this.translateService.instant('HAS_PROBLEM')
    );
  }

  private fullSubscribe(): void {
    this.notificationsPermission = window.Notification.permission;
    this.subscribeOnUpdateNotificationStatus();
    this.unsubscribeAfterLogout();
    this.subscribeOnUpdateNotificationsPermissions();
    this.subscribeToUpdateApp();

    if (window.Notification.permission === NotificationsPermissionEnum.DENIED) {
      this.showSnackbar();
    } else if (this.isSafariBrowser()) {
      this.subscribeToPushInSafari();
    } else {
      this.subscribeToPush();
    }
  }

  private isPwaApplication(): boolean {
    return (
      window.matchMedia('(display-mode: standalone)').matches === true || (<any>window.navigator).standalone === true
    );
  }

  private subscribeOnUpdateNotificationsPermissions(): void {
    if ('permissions' in navigator) {
      navigator.permissions.query({ name: 'notifications' }).then(notificationPerm => {
        notificationPerm.onchange = (): void => {
          if (notificationPerm.state !== NotificationsPermissionEnum.GRANTED) {
            this.resetNotificationsObject();
          }

          if (
            notificationPerm.state === NotificationsPermissionEnum.GRANTED &&
            this.notificationsPermission === NotificationsPermissionEnum.DENIED
          ) {
            this.subscribeToPush();
          }

          this.notificationsPermission = notificationPerm.state;
        };
      });
    }
  }

  private unsubscribeAfterLogout(): void {
    this.authService.logoutInProcess$.pipe(untilDestroyed(this)).subscribe(logout => {
      return logout ? this.unsubscribeFromPushNotifications() : null;
    });
  }

  private unsubscribeFromPushNotifications(): void {
    if (!this.notificationsAllowedData.active) {
      return;
    }

    this.swPush.unsubscribe().then(() => {
      this.resetNotificationsObject();
    });
  }

  /**
   * вызов окна компонента установки PWA
   */
  private showInstallPwaComponent(): void {
    this.showPwaInstallComponentSubject.next();
  }

  private notAllowedBrowser(): boolean {
    return window.navigator.userAgent.includes('YaBrowser');
  }

  private isWindows(): boolean {
    return window.navigator.userAgent.includes('Windows');
  }

  private isSafariBrowser(): boolean {
    return (
      !!window.navigator.userAgent.match(/iPhone|iPad|iPod|Macintosh/) &&
      !window.navigator.userAgent.includes('Chrome') &&
      !window.navigator.userAgent.includes('YaBrowser') &&
      !window.navigator.userAgent.includes('Firefox')
    );
  }

  private showSnackbar(): void {
    this.snackBar.open(
      this.translateService.instant('NOTIFICATIONS_IS_BLOCKED'),
      this.translateService.instant('CLOSE'),
      {
        duration: 30000,
        verticalPosition: 'top',
        panelClass: 'service-info'
      }
    );
  }

  private subscribeToUpdateApp(): void {
    this.update.versionUpdates.pipe(untilDestroyed(this)).subscribe(res => {
      if (res.type !== 'VERSION_DETECTED') {
        return;
      }
      this.update.activateUpdate().then(() => {
        if (confirm(this.translateService.instant('HAS_UPDATE'))) {
          window.location.reload();
        }
      });
    });
  }

  private async requestSubscription(): Promise<string> {
    try {
      const sub = await this.swPush.requestSubscription({
        serverPublicKey: this.vapidPublicKey
      });
      return JSON.stringify(sub);
    } catch (err) {
      console.error('Could not subscribe to notifications', err);
      return JSON.stringify(err);
    }
  }

  private subscribeOnUpdateNotificationStatus(): void {
    this.swPush.subscription.pipe(untilDestroyed(this)).subscribe(subscription => {
      if (!subscription) {
        this.resetNotificationsObject();
        return;
      }
      const subs = JSON.parse(JSON.stringify(subscription));
      this.notificationsAllowedData.active = true;
      this.notificationsAllowedData.endpoint = subs.endpoint;
      this.notificationsAllowedData.keys = subs.keys;
    });
  }

  private resetNotificationsObject(): void {
    this.notificationsAllowedData.active = false;
    this.notificationsAllowedData.endpoint = '';
    this.notificationsAllowedData.keys = null;
  }
}
