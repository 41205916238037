import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Call } from '@breez/modules/call/call';
import { CallModel, CallState, CallStatus } from '@breez/modules/call/models';
import { OverlayContainer } from '@breez/modules/overlay/models';
import { OverlayPositionType } from '@breez/modules/overlay/models/overlay-position-type.enum';
import { combineLatest, Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

@Component({
  selector: 'vks-call-status-ring-outgoing',
  templateUrl: './type.component.html',
  styleUrls: ['./type.component.scss', './type.component.media.max1024.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CallStatusRingOutgoingComponent implements OnInit, OnDestroy {
  @Input() call: Call;
  @Input() callModel: CallModel;
  @Input() anotherActiveCallId: number;
  @Input() overlayContainer: OverlayContainer;

  @Output() changeOverlayContainer = new EventEmitter<OverlayContainer>();

  @HostBinding('class.smart')
  isSmartOverlayContainer: boolean;

  reducedLocalMediaStream$: Observable<MediaStream>;

  callStatus = CallStatus;
  canRestore = false;
  canCollapse = false;

  ngOnInit(): void {
    this.canRestore = !this.overlayContainer.position.includes(OverlayPositionType.CENTER);
    this.canCollapse = this.overlayContainer.position.includes(OverlayPositionType.CENTER);
    this.isSmartOverlayContainer = this.canRestore;

    this.reducedLocalMediaStream$ = this.call.localStream$.pipe(
      map(stream => {
        return new MediaStream(
          stream.getTracks().filter(track => {
            return track.kind !== 'audio';
          })
        );
      })
    );
  }

  onChangeCall(status: CallStatus): void {
    this.call.changeCall(status).subscribe();
  }

  onCollapse(): void {
    this.resize(OverlayPositionType.SIDE, false);
  }

  onRestore(): void {
    this.resize(OverlayPositionType.CENTER, true);
  }

  resize(position: OverlayPositionType, modal: boolean): void {
    this.changeOverlayContainer.emit({
      position,
      modal
    });
  }

  ngOnDestroy(): void {
    combineLatest([this.call.state$, this.call.localStream$])
      .pipe(
        take(1),
        tap(([state, stream]) => {
          if (state !== CallState.PENDING) {
            stream.getTracks().forEach(track => {
              track.stop();
              stream.removeTrack(track);
            });
          }
        })
      )
      .subscribe();
  }
}
