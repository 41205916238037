<h3>{{ callModel?.answerer?.name }}</h3>
<vks-user-avatar [entity]="callModel.answerer" size="64"></vks-user-avatar>
<video [srcObject]="reducedLocalMediaStream$ | async" playsinline autoplay class="local-video" muted></video>
<div class="info">{{ 'CALL.THERE_IS_A_CALL' | translate }}</div>
<div class="actions">
  <vks-icon
    [hasHover]="true"
    [matTooltip]="'CALL.CANCEL' | translate"
    (click)="onChangeCall(callStatus.CANCEL)"
    class="square48"
    color="white"
    hoverColor="white"
    icon="call-cancel"
  ></vks-icon>
</div>
<vks-icon
  *ngIf="canRestore"
  [matTooltip]="'RESTORE' | translate"
  (click)="onRestore()"
  class="resize restore square24"
  color="white"
  hoverColor="white"
  icon="arrow-down"
></vks-icon>
<vks-icon
  *ngIf="canCollapse"
  [matTooltip]="'COLLAPSE' | translate"
  (click)="onCollapse()"
  class="resize collapse square24"
  color="white"
  hoverColor="white"
  icon="arrow-down"
></vks-icon>
