import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'vks-text-reducer',
  templateUrl: './text-reducer.component.html',
  styleUrls: ['./text-reducer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextReducerComponent implements OnChanges {
  @Input() text: string;
  reducedText: string;

  @Input() maxLength = 60;

  collapsed = true;
  needCollapse = true;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.text) {
      this.handleText();
    }
    if (changes.maxLength) {
      this.handleText();
    }
  }

  handleText(): void {
    if (!this.text) {
      this.collapsed = false;
      this.needCollapse = false;
      return;
    }

    if (this.text.length <= this.maxLength) {
      this.collapsed = false;
      this.needCollapse = false;
      return;
    }

    this.collapsed = true;
    this.needCollapse = true;
    this.reducedText = this.text.slice(0, this.maxLength);
  }
}
