import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ShortcutsService } from '@breez/components/shortcuts-dialog/shortcuts.service';
import { Observable } from 'rxjs';
import { IShortcut } from '@breez/components/shortcuts-dialog/model/shortcut.model';

@Component({
  selector: 'vks-shortcuts-dialog',
  templateUrl: './shortcuts-dialog.component.html',
  styleUrls: ['./shortcuts-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShortcutsDialogComponent {
  shortcutsList$: Observable<IShortcut[]> = this.shortcutsService.getShortcutsList();

  constructor(private readonly shortcutsService: ShortcutsService) {}

  getKeyboardKeyCode(key: string): string {
    return this.shortcutsService.getKeyIconByTitle(key);
  }
}
