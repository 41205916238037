import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { OverlayContainer } from '@breez/modules/overlay/models';
import { INotificationModel } from '@breez/models/notification';
import { Observable } from 'rxjs';
import { StateService } from '@breez/shared/services/state.service';

@Component({
  selector: 'vks-notification-information',
  templateUrl: './type.component.html',
  styleUrls: ['./type.component.scss', './type.component.media-max800.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationInformationComponent {
  @Input() notification: INotificationModel;
  @Output() changeOverlayContainer = new EventEmitter<OverlayContainer>();

  isMobile$: Observable<boolean> = this.stateService.isMobileDevice$;

  constructor(private stateService: StateService) {}

  onClose(): void {
    this.changeOverlayContainer.emit(null);
  }
}
