import { formatDate } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AppService } from '@breez/app.service';
import { User } from '@breez/models';
import { AppConfigModel } from '@breez/models/app-config.model';
import { Conference } from '@breez/models/conference/conference.model';
import { ParticipantDTO } from '@breez/models/conference/participant.dto.model';
import { SettingsItemModel } from '@breez/models/settings';
import { AuthService } from '@breez/modules/auth/services/auth.service';
import { DateControlPatch } from '@breez/modules/conference/models/date-control-patch.model';
import { UserParticipationInfo } from '@breez/modules/conference/models/user-participatrion-info.model';
import { makeDTOFromUser } from '@breez/modules/conference/modules/planner/services/conference-planner-makeDTOFromParticipant/conference-planner-makeDTOFromParticipant';
import { CreateConferenceDialogService } from '@breez/modules/conference/modules/planner/services/create-conference-dialog/create-conference-dialog.service';
import { PlannerSettingsItem } from '@breez/modules/conference/modules/planner/services/planner-settings-item.enum';
import { getAvatarEntity, ChatParticipant } from '@breez/modules/chat';
import { SettingsSectionsKeys } from '@breez/modules/settings/enums/settings-section-keys';
import { SettingsService } from '@breez/modules/settings/services/settings.service';
import { RoomsService } from '@breez/modules/social/services/rooms.service';
import { WebsocketEvents, WebsocketService } from '@breez/modules/websocket';
import { distinctUntilChangedByJsonCompare, replayWhileSubs } from '@breez/shared/rxjs-operators';
import { ConferenceLayoutService } from '@breez/shared/services/conference-layout/conference-layout.service';
import { ConferencesService } from '@breez/shared/services/conferences.service';
import { StateService } from '@breez/shared/services/state.service';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { ElectronService } from '@breez/modules/core/services';
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import {
  auditTime,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import * as uuid from 'uuid';
import { ObjectType } from '@breez/shared/enums/object-type.enum';
import { IGroupControl, IStepControl } from '@breez/models/template/template.model';
import {
  CheckScheduleByPeriod,
  ConferenceSettingService
} from '@breez/modules/conference/services/conference-setting.service';
import { ChatEntity } from '@breez/modules/chat/models/+state/chatEntity';

@Injectable({
  providedIn: 'root'
})
export class ConferencePlannerService {
  roomUsersSubject$: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);
  roomUsers$: Observable<User[]> = this.roomUsersSubject$.asObservable().pipe(distinctUntilChangedByJsonCompare());
  busyUsersSubject$: BehaviorSubject<UserParticipationInfo[]> = new BehaviorSubject<UserParticipationInfo[]>([]);
  overflowMulticastingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  busyUsers$: Observable<UserParticipationInfo[]> = this.busyUsersSubject$
    .asObservable()
    .pipe(distinctUntilChangedByJsonCompare());

  createEnabled$: Observable<boolean> = this.appService.conferenceSettings$.pipe(
    map(conferenceSettiings => {
      return !conferenceSettiings.create_disabled;
    }),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  isCreateEnabled(): Observable<boolean> {
    return this.createEnabled$.pipe(debounceTime(200), take(1));
  }

  plannerSettings$: BehaviorSubject<SettingsItemModel[]> = new BehaviorSubject<SettingsItemModel[]>([]);
  private plannerSettingsObservable$: Observable<SettingsItemModel[]> = this.plannerSettings$.asObservable().pipe(
    filter(settings => {
      return settings.length > 0;
    }),
    distinctUntilChangedByJsonCompare(),
    replayWhileSubs()
  );

  conferenceStartDay$: ReplaySubject<Date> = new ReplaySubject<Date>(1);
  isConferenceStartDayTime$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isElectronApp: boolean = this.electronService.isElectron;

  moratoriumHoursAmount$: Observable<string> = this.plannerSettingsObservable$.pipe(
    map(settings => {
      return settings.find(setting => {
        return setting.key === PlannerSettingsItem.MORATORIUM;
      }).value;
    }),
    distinctUntilChanged()
  );

  timeBeforeConferenceStarts$: Observable<string> = this.plannerSettingsObservable$.pipe(
    map(settings => {
      return settings.find(setting => {
        return setting.key === PlannerSettingsItem.TIME_BEFORE_START;
      }).value;
    }),
    distinctUntilChanged()
  );

  conferenceStartEndDatePatch$: Observable<DateControlPatch> = combineLatest([
    this.moratoriumHoursAmount$.pipe(map(parseInt)),
    this.timeBeforeConferenceStarts$.pipe(map(parseInt))
  ]).pipe(
    map(([moratoriumHours, minutesBeforeStart]) => {
      return this.getDateControlPatchByMoratoriumAndStartDelay(moratoriumHours, minutesBeforeStart);
    })
  );

  constructor(
    @Inject('APP_CONFIG') private readonly appConfig: AppConfigModel,
    @Inject(LOCALE_ID) private readonly locale,
    private readonly wss: WebsocketService,
    private readonly appService: AppService,
    private readonly electronService: ElectronService,
    private readonly stateService: StateService,
    private readonly conferencesService: ConferencesService,
    private readonly roomsService: RoomsService,
    private readonly authService: AuthService,
    private readonly settingsService: SettingsService,
    private readonly conferenceLayoutService: ConferenceLayoutService,
    private readonly createConferenceDialogService: CreateConferenceDialogService,
    private readonly conferenceSettingService: ConferenceSettingService
  ) {}

  checkScheduleByPeriod(startDate: Date, endDate: Date): Observable<CheckScheduleByPeriod> {
    if (!(startDate && endDate && startDate instanceof Date && endDate instanceof Date)) {
      return of(null);
    }
    const diff = endDate?.getTime() - startDate?.getTime();
    return diff < 0 ? of(null) : this.conferenceSettingService.checkScheduleByPeriod({ startDate, endDate });
  }

  initPlanerSettings(): void {
    this.settingsService.getValuesBySectionName(SettingsSectionsKeys.PLANNER).subscribe(settings => {
      return this.plannerSettings$.next(settings);
    });
  }

  resetConferenceStartDay(): void {
    this.conferenceStartDay$.next(null);
    this.isConferenceStartDayTime$.next(false);
  }

  openCreateConferencePopup(): void {
    this.createConferenceDialogService
      .openCreateConferencePopup()
      .pipe(
        switchMap(conferenceId => {
          if (!conferenceId) {
            return of(null);
          }
          this.stateService.goToUrl(`${this.isElectronApp ? '#' : location.origin}/conference/${conferenceId}`);
          return this.conferencesService.startConference(conferenceId);
        })
      )
      .subscribe();
  }

  createConferenceFromGroupChat(
    chat: ChatEntity,
    chatParticipants: ChatParticipant[],
    currentUser: User
  ): Observable<number> {
    return combineLatest([
      this.conferencesService.conferenceResolutionByRole$,
      this.conferenceLayoutService.conferenceLayouts$
    ]).pipe(
      switchMap(([conferenceResolutionByRole, _]) => {
        const size =
          conferenceResolutionByRole.reduce((prev, current) => {
            return prev.size.height > current.size.height ? prev : current;
          })?.value || 9;

        const title = this.getConferenceTitleFromChat(chat, chatParticipants, currentUser);
        const chatParticipantDTOs: ParticipantDTO[] = chatParticipants
          .map(chatParticipant => {
            return chatParticipant.user;
          })
          .map(participant => {
            return makeDTOFromUser(true, true, true)(participant);
          });

        const currentDate = new Date();
        return this.conferencesService.createConference(<Partial<Conference>>{
          title,
          autolayout: true,
          layoutId: 0,
          size,
          creator: currentUser,
          participants: chatParticipantDTOs,
          token: uuid.v4(),
          plannedstartedon: currentDate,
          plannedendedon: currentDate,
          autostart: true,
          enableChat: true,
          isMulticasting: false,
          enablesurvey: false,
          recording: false,
          enableqts: false,
          isPublic: false,
          enablerecord: false,
          description: null,
          displaydescription: null,
          fromchatid: chat.id
        });
      })
    );
  }

  processCurrentUserInRoomParticipants(roomParticipants: User[], currentUser: User): User[] {
    const isCurrentUserRoomParticipant =
      roomParticipants.findIndex(roomParticipant => {
        return roomParticipant.id === currentUser.id;
      }) !== -1;

    if (!isCurrentUserRoomParticipant) {
      roomParticipants.concat(currentUser);
      return roomParticipants;
    }

    return roomParticipants;
  }

  fromSelectedRoomToUsers(roomId: number, currentUser: User): Observable<User[]> {
    if (!isTruthy(roomId)) {
      return of(currentUser.canInviteToConference ? [currentUser] : []);
    }

    return this.roomsService.getSelectedRoomUsers(roomId).pipe(
      map(users => {
        return users.map(user => {
          return new User({
            id: user.id,
            name: user.name,
            canNotBeRemovedFromPlannerList: true,
            ...user
          });
        });
      }),
      map(roomParticipantsAsUsers => {
        return this.processCurrentUserInRoomParticipants(roomParticipantsAsUsers, currentUser);
      }),
      tap(users => {
        return this.roomUsersSubject$.next(users);
      })
    );
  }

  checkUsersBusiness(
    userIds?: number[],
    conferenceStartDate?: Date,
    conferenceEndDate?: Date
  ): Observable<UserParticipationInfo[]> {
    return this.wss.query(WebsocketEvents.SEND.OBJECT.PARTICIPATION_INFO, {
      data: {
        startdate: conferenceStartDate,
        enddate: conferenceEndDate,
        participantids: userIds,
        objecttype: ObjectType.CONFERENCE,
        statusids: [1, 2] // Only Planned or Active conferences
      }
    });
  }

  checkMulicastOverflow(conferenceStartDate?: Date, conferenceEndDate?: Date, conferenceid?: number): Observable<any> {
    return this.wss.query(WebsocketEvents.SEND.CONFERENCE.MULTICAST.COUNT_CHECK, {
      data: {
        startdate: conferenceStartDate,
        enddate: conferenceEndDate,
        conferenceid
      }
    });
  }

  private getConferenceTitleFromChat(chat: ChatEntity, chatParticipants: ChatParticipant[], currentUser: User): string {
    return `${getAvatarEntity(chat, chatParticipants, currentUser).name}, ${formatDate(new Date(), 'dd.M.yy, HH:mm', this.locale)}`;
  }

  observeOverflowMulticasting(
    startDateSource$: Observable<Date>,
    endDateSource$: Observable<Date>,
    isMultycasting$: Observable<boolean>,
    conferenceIdSource$: Observable<number>
  ): Observable<boolean> {
    return combineLatest([startDateSource$, endDateSource$, isMultycasting$, conferenceIdSource$]).pipe(
      distinctUntilChanged(),
      auditTime(300),
      switchMap(([startingAt, endingAt, isMultycasting, conferenceId]) => {
        if (startingAt && endingAt && startingAt?.getTime() < endingAt?.getTime() && isMultycasting) {
          return this.checkMulicastOverflow(startingAt, endingAt, conferenceId).pipe(
            map(value => {
              return !value;
            })
          );
        } else {
          return of(false);
        }
      }),
      distinctUntilChangedByJsonCompare(),
      replayWhileSubs()
    );
  }

  observeBusyParticipants(
    startDateSource: Observable<Date>,
    endDateSource: Observable<Date>,
    participantsSource: Observable<User[]>,
    conferenceIdSource: Observable<number>
  ): Observable<UserParticipationInfo[]> {
    return combineLatest([startDateSource, endDateSource, participantsSource, this.authService.currentUser$]).pipe(
      auditTime(300),
      switchMap(([startingAt, endingAt, participants, currentUser]) => {
        if ((participants ?? []).length === 0 || !isTruthy(currentUser)) {
          return of([]);
        }

        const onlyNumbers = new RegExp('^[0-9]+$');
        const usersId: number[] = participants
          .map(participant => {
            return participant.id;
          })
          .filter(id => {
            return typeof id === 'string' ? onlyNumbers.test(id) : isTruthy(id);
          });

        if (startingAt && endingAt && startingAt?.getTime() < endingAt?.getTime() && usersId && usersId.length) {
          return this.checkUsersBusiness(usersId, startingAt, endingAt)
            .pipe(withLatestFrom(conferenceIdSource))
            .pipe(
              map(([data, conferenceId]) => {
                return data
                  .map(userInfo => {
                    return {
                      ...userInfo,
                      objects: userInfo.objects.filter(objectInfo => {
                        return (
                          objectInfo.object.type === ObjectType.CONFERENCE && objectInfo.object.id !== conferenceId
                        );
                      })
                    } as UserParticipationInfo;
                  })
                  .filter(info => {
                    return info.objects.length > 0;
                  });
              })
            );
        } else {
          return of([] as UserParticipationInfo[]);
        }
      }),
      distinctUntilChangedByJsonCompare(),
      replayWhileSubs()
    );
  }

  private getDateControlPatchByMoratoriumAndStartDelay(moratorium: number, startDelay: number): DateControlPatch {
    const defaultStartDelayInMinutes = this.appConfig.defaultConferenceStartDelayInMinutes;
    const minutesFactor = moratorium === 0 && startDelay === 0 ? defaultStartDelayInMinutes : startDelay;

    const hoursToAddAsMilliseconds = (moratorium || 0) * 60 * 60 * 1_000;
    const minutesToAddAsMilliseconds = minutesFactor * 60 * 1_000;

    const startDate = new Date(new Date()?.getTime() + hoursToAddAsMilliseconds + minutesToAddAsMilliseconds);
    const endDate = new Date(startDate?.getTime() + 60 * 60 * 1_000);
    return { startDate, endDate };
  }

  getControlByKey(controls: Array<IStepControl | IGroupControl>, key: any): any {
    for (const control of controls) {
      if (control.key === key) {
        return control;
      } else if (isTruthy(control.controls)) {
        const findRes = this.getControlByKey(control.controls, key);
        if (findRes) {
          return findRes;
        }
      }
    }
  }

  patchEndDateByStartDateAndDuration(duration: Date, startDate: Date, endDayFormControl: UntypedFormControl): void {
    const endDatePatch = new Date(startDate?.getTime() + duration?.getTime());

    endDayFormControl.patchValue(endDatePatch, { emitEvent: false });
  }
}
