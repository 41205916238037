import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { LanguageService } from '@breez/shared/services/language.service';
import { Observable } from 'rxjs';
import { LanguageModel } from '@breez/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, switchMap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'vks-control-language-selector-menu',
  templateUrl: './vks-control-language-selector-menu.component.html',
  styleUrls: ['./vks-control-language-selector-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ControlLanguageSelectorMenuComponent implements OnInit {
  languageId$: Observable<string> = this.languageService.interfaceLanguage$;
  language$: Observable<LanguageModel> = this.languageId$.pipe(
    switchMap(languageId => {
      return this.languageService.getLanguageInfo(languageId);
    })
  );

  languageName$: Observable<string> = this.language$.pipe(
    map(language => {
      return language.name;
    })
  );

  languageFlagIcon$: Observable<string> = this.language$.pipe(
    map(language => {
      return language.icon;
    })
  );

  languages$: Observable<LanguageModel[]> = this.languageService.getLanguagesInfo();
  selected: string;

  constructor(private languageService: LanguageService) {}

  ngOnInit(): void {
    this.languageId$.pipe(untilDestroyed(this)).subscribe(id => {
      this.selected = id;
    });
  }

  switchLanguage(id: string): void {
    this.languageService.switchInterfaceLanguage(id);
  }
}
