import { Expose, Transform } from 'class-transformer';
import { Participant } from '@breez/models/shared/participant/participant.model';

export class ConferenceEvent {
  @Expose({ name: 'conference_schedule_id' })
  id: number;

  @Expose()
  description: string;

  @Expose({ name: 'starting_at' })
  @Transform(({ value }) => {
    return new Date(value.replace(/ /g, 'T') + 'Z');
  })
  startingAt: Date;

  @Expose({ name: 'ending_at' })
  @Transform(({ value }) => {
    return new Date(value.replace(/ /g, 'T') + 'Z');
  })
  endingAt: Date;

  @Expose({ name: 'conference_id' })
  conferenceId: number;

  @Expose({ name: 'user_id' })
  userId: number;

  eventLeader: Participant;
}
