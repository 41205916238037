import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { EmitOnChange } from '@breez/shared/utilities/decorators/emit-on-change.decorator';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

interface IGridItem {
  id: number | string;
}

@Component({
  selector: 'vks-grid-list',
  templateUrl: './grid-list.component.html',
  styleUrls: ['./grid-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridListComponent implements OnChanges {
  @Input() itemTemplate: TemplateRef<any>;
  @Input() items: IGridItem[] = [];
  @Input() paging: PageEvent;
  @Input() pageSizeOptions: number[];
  @Input() scrollable = true;
  @Output() itemClick: EventEmitter<IGridItem> = new EventEmitter<IGridItem>();
  @Output() scrolled: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output() pageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();

  @EmitOnChange('paging', { onlyTruthy: true })
  paging$: BehaviorSubject<PageEvent> = new BehaviorSubject<PageEvent>({
    length: 0,
    pageSize: 0,
    pageIndex: 0
  } as PageEvent);

  @EmitOnChange('items', { onlyTruthy: true })
  items$: ReplaySubject<IGridItem[]> = new ReplaySubject<IGridItem[]>();

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnChanges(_: SimpleChanges): void {}

  trackBy(_, item): number | string {
    return item.id;
  }

  onScrolled(down: boolean): void {
    this.scrolled.emit({
      pageIndex: this.paging.pageIndex + 1 * (down ? 1 : -1),
      pageSize: this.paging.pageSize
    } as PageEvent);
  }
}
