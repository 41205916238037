import { User } from '@breez/models';
import { CallStatus } from '@breez/modules/call/models/call-status.enum';
import { CallDestinationType } from '@breez/modules/call/models/call-destination.enum';
import { Expose } from 'class-transformer';
import { isTruthy } from '@breez/shared/utilities/is-truthy';

export class CallModel {
  @Expose({ name: 'callid' })
  id: number;

  @Expose({ name: 'callerid' })
  callerPeerId: string;

  @Expose({ name: 'answererid' })
  answererId: number;

  @Expose({ name: 'answererapplicationid' })
  answererPeerId?: string;

  @Expose({ name: 'createdon' })
  createdOn: Date;

  @Expose({ name: 'startedon' })
  startedOn?: Date;

  @Expose({ name: 'endedon' })
  endedOn?: Date;

  @Expose({ name: 'originalcallid' })
  originalCallId?: number;

  @Expose({ name: 'whotransfercall' })
  transferer?: string;

  @Expose({ name: 'callstatusid' })
  status: CallStatus;

  @Expose({ name: 'isconference' })
  isConference: boolean;

  @Expose({ name: 'conferenceid' })
  conferenceId?: number;

  answerer: User;
  caller: User;
  destinationType: CallDestinationType;

  constructor(data: { answererId?: number; answererPeerId?: string }) {
    if (data && data.answererId) {
      this.answererId = data.answererId;
    }

    if (data && data.answererPeerId) {
      this.answererPeerId = data.answererPeerId;
    }

    return this;
  }

  get isConferenceCall(): boolean {
    return isTruthy(this.conferenceId) || this.isConference;
  }
}
