<div [class.column]="isTablet" class="grid-line-data">
  <div
    [class.additional-right-margin]="addMargin"
    [style.maxWidth]="isTablet ? 'fit-content' : formattedLabelWidth"
    [style.minWidth]="formattedLabelWidth"
    class="label"
  >
    <ng-container [ngTemplateOutlet]="labelTemplate"></ng-container>
    <span>{{ label }}</span>
  </div>
  <div #gridLineText [class.expand]="isMobile" [class.expanded]="isExpanded" class="text">
    <ng-container *ngTemplateOutlet="contentTemplate; context: templateContext"></ng-container>
    {{ text }}
  </div>
  <vks-icon
    *ngIf="expandable && !isExpanded"
    [matTooltip]="'EXPAND' | translate"
    (click)="toggleExpand()"
    icon="expand-more"
  ></vks-icon>
  <vks-icon
    *ngIf="expandable && isExpanded"
    [matTooltip]="'COLLAPSE' | translate"
    (click)="toggleExpand()"
    icon="expand-less"
  ></vks-icon>
</div>
<ng-content></ng-content>
