import { Injectable } from '@angular/core';

// import Canvg from 'canvg';

@Injectable({
  providedIn: 'root'
})
export class UserAvatarService {
  // getUserAvatarSrc(user: User): string {
  //   const reducedName = this.reduceName(user.name);
  //   const svgSrc = this.getDefaultAvatarSvg(reducedName);
  //   const canvas = document.createElement('canvas');
  //   const canvasContext = canvas.getContext('2d');
  //   Canvg.fromString(canvasContext, svgSrc).start();
  //   return canvas.toDataURL();
  // }

  reduceName(name: string): string {
    if (!name) {
      return '';
    }

    return name
      .replace(/[()\[\]{}]+/g, '')
      .split(' ')
      .splice(0, 2)
      .map(word => {
        return word[0];
      })
      .join('')
      .toUpperCase();
  }

  // getDefaultAvatarSvg(label: string): string {
  //   return `
  //   <svg width="96" height="96" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  //       <ellipse cx="24" cy="24" rx="24" ry="24" fill="#9C9C9C"/>
  //       <text fill="black"
  //             font-family="Onest"
  //             font-size="18"
  //             letter-spacing="0em"
  //             text-anchor="middle"
  //       >
  //           <tspan x="24" y="31">${label}</tspan>
  //       </text>
  //   </svg>
  //   `;
  // }
}
