export const CLIENT_ERS_URL = 'client_ers_url';
export const CLIENT_ERS_FLAVOR = 'client_ers_flavor';
export const CLIENT_ERS_CHANNEL = 'client_ers_channel';
export const CLIENT_ERS_ENABLE_UPDATE = 'client_ers_enable_update';

export interface ElectronSettings {
  [CLIENT_ERS_URL]?: string;
  [CLIENT_ERS_FLAVOR]?: string;
  [CLIENT_ERS_CHANNEL]?: string;
  [CLIENT_ERS_ENABLE_UPDATE]?: string;
}
