import { Injectable } from '@angular/core';
import { GiphyFetch } from '@giphy/js-fetch-api';
import { renderGrid } from '@giphy/js-components';
import IGif from '@giphy/js-types/dist/gif';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { BehaviorSubject, combineLatest, from, Observable, of } from 'rxjs';
import { GiphyContentTypeEnum } from '@breez/modules/chat/enums/giphy-content-type.enum';
import { GifsResult } from '@giphy/js-fetch-api/src/result-types';
import { IGiphyContent } from '@breez/modules/chat/components/giphy-popup/model/giphy-content';
import { StateService } from '@breez/shared/services/state.service';
import { IGiphyContentTypeItem } from '@breez/modules/chat/components/giphy-popup/model/giphy-content-type';
import { catchError, distinctUntilChanged, map, switchMap, take } from 'rxjs/operators';
import { RestApiService } from '@breez/rest-api.service';
import { GiphyPopupComponent } from '@breez/modules/chat/components/giphy-popup/giphy-popup.component';
import { IGiphyDialogResult } from '@breez/modules/chat/components/giphy-popup/model/giphy-dialog-result';
import { ProcessingFileModel } from '@breez/models/shared/files/processing-file.model';
import uuid from 'uuid';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

export interface GiphyDialogResult {
  file: ProcessingFileModel;
  contentType: GiphyContentTypeEnum;
}

@Injectable({
  providedIn: 'root'
})
export class GiphyService {
  private readonly LARGE_GRID_WIDTH = 500;
  private readonly SMALL_GRID_WIDTH = 230;
  private readonly FETCH_OFFSET = 10;

  private readonly giphyContentTypes: IGiphyContentTypeItem[] = [
    {
      value: GiphyContentTypeEnum.EMOJI,
      icon: 'smile'
    },
    {
      value: GiphyContentTypeEnum.STICKERS,
      icon: GiphyContentTypeEnum.STICKERS
    },
    {
      value: GiphyContentTypeEnum.GIFS,
      icon: 'gif'
    },
    {
      value: GiphyContentTypeEnum.TEXT,
      icon: GiphyContentTypeEnum.TEXT
    }
  ];

  private giphyFetch: GiphyFetch = null;
  private disableGiphy$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private clearGridRef;

  isMobileDevice$: Observable<boolean> = this.stateService.isMobileDevice$;

  giphyApiKey$: Observable<string> = this.restApiService.info$.pipe(
    map(info => {
      return info.giphyApiKey;
    }),
    distinctUntilChanged()
  );

  giphyFetch$: Observable<GiphyFetch> = this.giphyApiKey$.pipe(
    switchMap(giphyApiKey => {
      if (!giphyApiKey) {
        return of(null);
      }

      const giphyFetch = new GiphyFetch(giphyApiKey);
      return from(giphyFetch.trending({ limit: 10, offset: 0, type: GiphyContentTypeEnum.GIFS })).pipe(
        take(1),
        map(testSearch => {
          if (testSearch.data.length === 0) {
            return null;
          }
          return giphyFetch;
        }),
        catchError(() => {
          return of(null);
        })
      );
    })
  );

  isGiphyEnabled$: Observable<boolean> = combineLatest([this.giphyFetch$, this.disableGiphy$]).pipe(
    map(([giphyFetch, disableGiphy]) => {
      return isTruthy(giphyFetch) && disableGiphy !== true;
    })
  );

  selectionDialog$: Observable<GiphyDialogResult> = this.isMobileDevice$.pipe(
    take(1),
    map(isMobileDevice => {
      return {
        width: isMobileDevice ? '250px' : '520px',
        height: isMobileDevice ? '400px' : '600px',
        id: 'gifs',
        panelClass: 'no-padding'
      } as MatDialogConfig;
    }),
    switchMap(config => {
      return this.dialog.open(GiphyPopupComponent, config).afterClosed();
    }),
    map((result: IGiphyDialogResult) => {
      if (!isTruthy(result)) {
        return {} as GiphyDialogResult;
      }

      return {
        contentType: result.contentType,
        file: new ProcessingFileModel({
          name: '',
          contentUrl: result.content.images.preview_gif.url,
          previewContentUrl: result.content.images.original.url,
          size: '0',
          fileId: uuid.v4(),
          type: 'image/gif'
        })
      } as GiphyDialogResult;
    })
  );

  constructor(
    private readonly stateService: StateService,
    private restApiService: RestApiService,
    private dialog: MatDialog
  ) {
    this.giphyFetch$.subscribe(giphyFetch => {
      this.giphyFetch = giphyFetch;
    });
  }

  renderGrid(
    htmlEl: HTMLElement,
    gifClickProcessor: (gif: IGif, event: MouseEvent) => void,
    content: IGiphyContent
  ): void {
    this.isMobileDevice$.subscribe(isMobileDevice => {
      if (isTruthy(this.clearGridRef)) {
        this.clearGridRef();
      }

      this.clearGridRef = renderGrid(
        {
          width: isMobileDevice ? this.SMALL_GRID_WIDTH : this.LARGE_GRID_WIDTH,
          columns: isMobileDevice ? 2 : 3,
          fetchGifs: this.getFetchFunctionFromContentType(content),
          gutter: 6,
          onGifClick: gifClickProcessor,
          hideAttribution: true
        },
        htmlEl
      );
    });
  }

  getFetchFunctionFromContentType(content: IGiphyContent): (offset: number) => Promise<GifsResult> {
    if (!this.giphyFetch) {
      throw Error('GIPHY отключен!');
    }
    const contentType = content.contentType;
    const query = content.query;

    switch (contentType) {
      case GiphyContentTypeEnum.EMOJI:
        return (offset: number = this.FETCH_OFFSET) => {
          return this.giphyFetch.emoji({ limit: 10, offset });
        };
      case GiphyContentTypeEnum.GIFS:
      case GiphyContentTypeEnum.STICKERS:
      case GiphyContentTypeEnum.TEXT:
        if (query !== '' && query !== undefined && query !== null) {
          return (offset: number = this.FETCH_OFFSET) => {
            return this.giphyFetch.search(query, { limit: 10, offset, type: contentType });
          };
        } else {
          return (offset: number = this.FETCH_OFFSET) => {
            return this.giphyFetch.trending({ limit: 10, offset, type: contentType });
          };
        }
      default:
        throw Error('нет совпадений');
    }
  }

  getGiphyContentTypeItems(): Observable<IGiphyContentTypeItem[]> {
    return of(this.giphyContentTypes);
  }

  openDialog$(): Observable<GiphyDialogResult> {
    return this.isGiphyEnabled$.pipe(
      switchMap(isGiphyEnabled => {
        return !!isGiphyEnabled ? this.selectionDialog$ : of(null);
      })
    );
  }
}
