import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { FilterItem } from '@breez/models/filters/filter-item';
import { Params } from '@angular/router';
import { FilterTypes } from '@breez/shared/enums/filter-types.enum';

@Component({
  selector: 'vks-filters-factory',
  templateUrl: './filters-factory.component.html',
  styleUrls: ['./filters-factory.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FiltersFactoryComponent {
  filterTypes = FilterTypes;

  @Input()
  filters: FilterItem[];

  @Input()
  filterValues: Params;
}
