import { Overlay } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { FormControlUser, User } from '@breez/models';
import {
  UserSelectDialogExtraTab,
  UserSelectDialogRequestModel
} from '@breez/models/user-select/user-select-dialog-request.model';
import { UserSelectComponent } from '@breez/modules/user/user-select';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { filter, map, share, shareReplay, switchMap, take } from 'rxjs/operators';
import { PARTICIPANT_ID } from '@breez/modules/chat/types/participant-id.type';

@UntilDestroy()
@Component({
  selector: 'vks-control-user-select',
  templateUrl: './control-user-select.component.html',
  styleUrls: ['./control-user-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => {
        return ControlUserSelectComponent;
      }),
      multi: true
    }
  ]
})
export class ControlUserSelectComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() multi = false;
  @Input() disableBusyUsers = false;
  @Input() emailAddresses = false;
  @Input() users: User[];
  @Input() forChat = false;
  @Input() excludedUsersIds: PARTICIPANT_ID[] = [];
  @Input() disabledUserIds: PARTICIPANT_ID[] = [];
  @Input() extraTabs: UserSelectDialogExtraTab[] = [];
  @Input() onlyInviteInTheConference = false;

  @ViewChild('userTreeExtraContent', { read: TemplateRef }) userTreeExtraContentTemplate: TemplateRef<any>;

  changedTrigger: (value: any) => void;
  touchedTrigger: () => void;

  requestUserSelection$ = new Subject<void>();
  requestUserSelectionResult$: Observable<{ selectedUsers: User[]; duplicatedEmailUsers: User[] }> =
    this.requestUserSelection$.pipe(
      switchMap(() => {
        return this.selectedUsers$.pipe(take(1));
      }),
      switchMap(selectedUsers => {
        return this.userSelectionDialog$(this.forChat ? [] : selectedUsers);
      }),
      filter(isTruthy),
      share()
    );

  formSelectedUsers$ = this.requestUserSelectionResult$.pipe(
    map(result => {
      return result.selectedUsers;
    })
  );

  duplicatesRemoved$ = this.requestUserSelectionResult$.pipe(
    map(result => {
      return result.duplicatedEmailUsers;
    }),
    shareReplay(1)
  );

  usersFromAbove$ = new BehaviorSubject<User[]>([]);

  selectedUsers$: Observable<User[]> = merge(this.usersFromAbove$, this.formSelectedUsers$).pipe(shareReplay(1));

  constructor(
    private dialog: MatDialog,
    private overlay: Overlay
  ) {}

  ngOnInit(): void {
    this.formSelectedUsers$.pipe(untilDestroyed(this)).subscribe(users => {
      if (this.changedTrigger && typeof this.changedTrigger === 'function') {
        this.changedTrigger(users);
      }
    });
  }

  userSelectionDialog$(
    selectedUsers: User[] = []
  ): Observable<{ selectedUsers: FormControlUser[]; duplicatedEmailUsers: FormControlUser[] }> {
    const userSelectDialog = this.dialog.open(UserSelectComponent, {
      data: <UserSelectDialogRequestModel>{
        disableBusyUsers: this.disableBusyUsers,
        users: this.users,
        selectedUsers: this.multi ? selectedUsers : [],
        multiSelect: this.multi,
        displayEmailSection: this.emailAddresses,
        forChat: this.forChat,
        onlyInviteInTheConference: this.onlyInviteInTheConference,
        disabledUserIds: this.disabledUserIds,
        excludedUsersIds: this.excludedUsersIds,
        extraTabs: this.extraTabs,
        userTreeExtraContent: this.userTreeExtraContentTemplate
      },
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      maxHeight: '90vh',
      minHeight: '90vh',
      width: '90vw',
      maxWidth: '600px',
      restoreFocus: false,
      autoFocus: false
    });

    return userSelectDialog.afterClosed();
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy(): void {}

  registerOnChange(fn: any): void {
    if (fn && typeof fn === 'function') {
      this.changedTrigger = fn;
    }
  }

  registerOnTouched(fn: any): void {
    if (fn && typeof fn === 'function') {
      this.touchedTrigger = fn;
    }
  }

  writeValue(users: User[]): void {
    if (users && Array.isArray(users)) {
      this.usersFromAbove$.next(users);
    }
  }

  showDialog(): void {
    this.requestUserSelection$.next();
  }
}
