import { Inject, Injectable } from '@angular/core';
import { IConferenceTemplateInfo } from '@breez/models/template/conference-template-info.model';
import { ITemplate, ITextInputControl } from '@breez/models/template/template.model';
import { TemplatesInfo } from '@breez/modules/conference/modules/planner/services/conference-templates-info/templates-info.service';
import { GetTemplateService } from '@breez/modules/conference/modules/planner/services/get-template/get-template.service';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

export const SIMPLE_TEMPLATE: ITemplate = {
  title: 'START_A_CONFERENCE',
  icon: null,
  description: null,
  controls: [
    <ITextInputControl>{
      key: 'title',
      keyOnDTO: 'title',
      maxLength: 200,
      required: false,
      title: 'CONFERENCE_THEME',
      type: 'text'
    },
    <ITextInputControl>{
      key: 'displaydescription',
      keyOnDTO: 'displaydescription',
      maxLength: 4000,
      required: false,
      title: 'CONFERENCE_DESCRIPTION',
      type: 'rich-text'
    },
    {
      key: 'size',
      keyOnDTO: 'size',
      required: false,
      title: 'CONFERENCE_RESOLUTION',
      type: 'select',
      controls: null
    },
    {
      defaultValue: false,
      key: 'isPublic',
      keyOnDTO: 'isPublic',
      title: 'PUBLIC_CONFERENCE',
      type: 'toggle',
      controls: null
    },
    {
      defaultValue: false,
      key: 'enablerecord',
      keyOnDTO: 'enablerecord',
      title: 'ENABLE_RECORD',
      type: 'toggle',
      controls: null
    }
  ],
  defaultValues: [
    {
      defaultValue: 1,
      key: 'layoutId',
      controls: null
    },
    {
      defaultValue: false,
      key: 'isMulticasting',
      controls: null
    },
    {
      defaultValue: 'participant',
      key: 'autoinvitetoframe',
      controls: null
    }
  ]
};

@Injectable({
  providedIn: 'root'
})
export class SimpleConferenceTemplateService {
  constructor(
    @Inject(TemplatesInfo) private readonly templatesInfo$: Observable<IConferenceTemplateInfo[]>,
    private readonly getTemplateService: GetTemplateService
  ) {}

  simpleConferenceTemplate$(): Observable<ITemplate> {
    return this.templatesInfo$.pipe(
      map(templatesInfo => {
        return templatesInfo.find(templateInfo => {
          return templateInfo.title === null;
        });
      }),
      switchMap(simpleTemplateId => {
        return simpleTemplateId
          ? this.getTemplateService.getTemplateById('' + simpleTemplateId.conferencetemplateid)
          : of(SIMPLE_TEMPLATE);
      })
    );
  }
}
