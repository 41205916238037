import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { PipeTranslationDependency } from '@breez/shared/pipes/base/pipe-translation-dependecy.class';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'emptyWrapper',
  pure: false
})
export class EmptyWrapperPipe extends PipeTranslationDependency implements PipeTransform {
  protected savedValue;

  constructor(@Inject(LOCALE_ID) locale: string, translateService: TranslateService) {
    super(locale, translateService);
  }

  private readonly defaultValue = '—';

  transform(value: any, label?: string): any {
    if (this.savedValue) {
      return this.savedValue;
    }
    this.savedValue = this.getTransformedValue(value, label);
    return this.savedValue;
  }

  getTransformedValue(value: any, label?: string): string | any {
    if (!value || (label && value.toString().trim().length === 0)) {
      return label || this.defaultValue;
    } else {
      return value;
    }
  }
}
