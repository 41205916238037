<ng-container *ngIf="modal; else shareButton">
  <vks-icon (click)="shareLink()" class="square24" icon="share"></vks-icon>
</ng-container>

<ng-template #shareButton>
  <div [class.share-buttons-modal]="modal" class="share-buttons">
    <div class="sb-group">
      <share-buttons [include]="links" [url]="url" (closed)="closeShareButtons.emit($event)"> </share-buttons>
      <div (click)="showQrCode()" class="sb-button sb-modern-dark">
        <button class="sb-wrapper sb-show-icon qr-code">
          <div class="sb-content">
            <div class="sb-icon">
              <vks-icon class="square18" icon="qr-code"></vks-icon>
            </div>
          </div>
        </button>
      </div>
      <div (click)="saveIcs()" class="sb-button sb-modern-dark control-calendar">
        <button class="sb-wrapper sb-show-icon sb-whatsapp">
          <div class="sb-content">
            <div class="sb-icon">
              <vks-icon class="square20" color="white" icon="control-calendar"></vks-icon>
            </div>
          </div>
        </button>
      </div>
    </div>
  </div>
</ng-template>
