import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment as buildEnvironment, environment } from '@breez/environment';
import {
  catchError,
  delay,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap,
  take,
  tap,
  timeout
} from 'rxjs/operators';
import { Observable, of, ReplaySubject, throwError, timer } from 'rxjs';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { AppInfoModel } from '@breez/models/app-info.model';
import { LocalStorage } from '@breez/shared/modules/storage/interfaces/local-storage.interface';
import { replayWhileSubs, toClass } from '@breez/shared/rxjs-operators';
import { Conference } from '@breez/models/conference/conference.model';
import { SessionStorage } from '@breez/shared/modules/storage/interfaces/session-storage.interface';
import { WEBSOCKET_URL_STORAGE_KEY } from '@breez/modules/websocket';
import { ElectronService } from '@breez/modules/core/services';
import { Router } from '@angular/router';
import {
  ClientIdentification,
  EnvironmentAbstractionData,
  EnvironmentData
} from '@breez/shared/models/environment-data.model';
import { Store } from '@ngrx/store';
import * as ModuleState from '@breez/modules/chat/+state/module.state';
import * as ExecutionAction from '@breez/+state/execution/execution.actions';

export const APP_ELECTRON_ORIGIN_KEY = 'app-electron-origin';
export const IS_SERVER_NOT_FILLED_BY_USER = 'is-server-not-filled-by-user';
export const APP_KEYCLOAK_REFRESH = 'app-keycloak-refresh';
export const APP_KEYCLOAK_TOKEN = 'app-keycloak-token';

export const collectEnvironmentData = (): EnvironmentData => {
  const matchItem = (string, data): EnvironmentAbstractionData => {
    let i,
      j = 0,
      regex,
      regexV,
      match,
      matches;

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const parse = (parseString, value: any, version: any) => {
      regex = new RegExp(value, 'i');
      match = regex.test(parseString);
      if (match) {
        regexV = new RegExp(version + '[- /:;]([\\d._]+)', 'i');
        matches = string.match(regexV);
        version = '';
        if (matches) {
          if (matches[1]) {
            matches = matches[1];
          }
        }
        if (matches) {
          matches = matches.split(/[._]+/);
          for (j = 0; j < matches.length; j += 1) {
            if (j === 0) {
              version += matches[j] + '.';
            } else {
              version += matches[j];
            }
          }
        }
        return new EnvironmentAbstractionData({
          name: data[i].name,
          version: version ? parseFloat(version) : 0
        });
      }
    };

    for (i = 0; i < data.length; i += 1) {
      if (Array.isArray(data[i].value)) {
        for (let k = 0; k < data[i].value.length; k++) {
          const parsedData = parse(string, data[i].value[k], data[i].version[k]);
          if (parsedData) {
            return parsedData;
          }
        }
      } else {
        const parsedData = parse(string, data[i].value, data[i].version);
        if (parsedData) {
          return parsedData;
        }
      }
    }
    return new EnvironmentAbstractionData({
      name: 'unknown',
      version: 0
    });
  };

  const agent = [
      navigator.platform,
      navigator.userAgent,
      navigator.appVersion,
      navigator.vendor,
      (<any>window).opera
    ].join(' '),
    dataOS = [
      {
        name: 'Windows Phone',
        value: 'Windows Phone',
        version: 'OS'
      },
      {
        name: 'Windows',
        value: 'Win',
        version: 'NT'
      },
      {
        name: 'iPhone',
        value: ['iPhone', 'iPhone'],
        version: ['Version', 'OS']
      },
      {
        name: 'iPad',
        value: ['iPad', 'iPad'],
        version: ['Version', 'OS']
      },
      {
        name: 'Kindle',
        value: 'Silk',
        version: 'Silk'
      },
      {
        name: 'Android',
        value: 'Android',
        version: 'Android'
      },
      {
        name: 'PlayBook',
        value: 'PlayBook',
        version: 'OS'
      },
      {
        name: 'BlackBerry',
        value: 'BlackBerry',
        version: '/'
      },
      {
        name: 'Macintosh',
        value: 'Mac',
        version: 'OS X'
      },
      {
        name: 'Linux',
        value: 'Linux',
        version: 'rv'
      },
      {
        name: 'Palm',
        value: 'Palm',
        version: 'PalmOS'
      }
    ],
    dataBrowser = [
      {
        name: 'Yandex Browser',
        value: 'YaBrowser',
        version: 'YaBrowser'
      },
      {
        name: 'Microsoft Edge',
        value: ['EdgiOS', 'EdgA', 'Edge', 'Edg'],
        version: ['EdgiOS', 'EdgA', 'Edge', 'Edg']
      },
      {
        name: 'Firefox',
        value: ['FxiOS', 'Firefox'],
        version: ['FxiOS', 'Firefox']
      },
      {
        name: 'Opera',
        value: ['Opera Mini', 'Opera', 'OPR'],
        version: ['Opera Mini', 'Opera', 'OPR']
      },
      {
        name: 'Chrome',
        value: ['Chrome', 'CriOS'],
        version: ['Chrome', 'CriOS']
      },
      {
        name: 'Chromium',
        value: 'Chromium',
        version: 'Chromium'
      },
      {
        name: 'Safari',
        value: 'Safari',
        version: 'Version'
      },
      {
        name: 'Internet Explorer',
        value: 'MSIE',
        version: 'MSIE'
      },
      {
        name: 'BlackBerry',
        value: 'CLDC',
        version: 'CLDC'
      },
      {
        name: 'Mozilla',
        value: 'Mozilla',
        version: 'Mozilla'
      }
    ];

  const browser = matchItem(agent, dataBrowser);
  const regExpAuroraFix = /\(Mobile;\s+rv:\d+\.\d*\)/;
  let os = matchItem(agent, dataOS);
  os =
    os ??
    (regExpAuroraFix.test(agent)
      ? new EnvironmentAbstractionData({
          name: 'Aurora',
          version: 0
        })
      : os);

  return new EnvironmentData({
    buildType: buildEnvironment.buildType,
    os,
    browser
  });
};

interface RestSources {
  GET: {
    INFO: string;
    CONFERENCE_INFO: string;
    CONFERENCE_REGISTER: string;
    CONFERENCE_AUTH_BY_EMAIL: string;
    CONFERENCE_AUTH_VERIFY_CODE: string;
    CHECK: string;
  };
  POST: {
    CONFERENCE_REGISTER: string;
    CONFERENCE_INFO: string;
    REGISTRATION_CHECK_TOKEN: string;
    REGISTRATION_SEND_VERIFICATION: string;
    REGISTRATION_VERIFY_EMAIL: string;
    REGISTRATION_REGISTER: string;
  };
}

const REST_API_SOURCES: RestSources = {
  GET: {
    INFO: 'info',
    CONFERENCE_INFO: 'public/conference/info',
    CONFERENCE_REGISTER: 'public/conference/register',
    CONFERENCE_AUTH_BY_EMAIL: 'auth/by-email',
    CONFERENCE_AUTH_VERIFY_CODE: 'auth/verify-code',
    CHECK: 'auth/check'
  },
  POST: {
    CONFERENCE_REGISTER: 'public/conference/register',
    CONFERENCE_INFO: 'public/conference/info',
    REGISTRATION_CHECK_TOKEN: 'public/user/check-token',
    REGISTRATION_SEND_VERIFICATION: 'public/user/send-verification-email',
    REGISTRATION_VERIFY_EMAIL: 'public/user/verify-email',
    REGISTRATION_REGISTER: 'public/user/register'
  }
};

@Injectable({
  providedIn: 'root'
})
export class RestApiService {
  restApiUrls: RestSources = { ...{ ...REST_API_SOURCES } };

  private infoSubject$: ReplaySubject<AppInfoModel> = new ReplaySubject<AppInfoModel>(1);
  info$: Observable<AppInfoModel> = this.infoSubject$.pipe(filter(isTruthy), shareReplay());
  url$: ReplaySubject<string> = new ReplaySubject<string>(1);
  readyTrigger$: Observable<void> = this.info$.pipe(
    switchMap(() => {
      return of(void 0);
    })
  );

  wrongApiStructureSubject$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  wrongApiStructure$: Observable<boolean> = this.wrongApiStructureSubject$.pipe(
    distinctUntilChanged(),
    replayWhileSubs()
  );

  environment = collectEnvironmentData();
  readonly isElectronApp: boolean = this.electronService.isElectron;
  ident$: ReplaySubject<ClientIdentification> = new ReplaySubject<ClientIdentification>(1);

  restSources$: Observable<RestSources> = this.url$.pipe(
    map(url => {
      const addUrl = (obj, prefix): any => {
        if (typeof obj === 'object') {
          for (const keys in obj) {
            if (typeof obj[keys] === 'object') {
              addUrl(obj[keys], prefix);
            } else {
              obj[keys] = `${prefix}${obj[keys]}`;
            }
          }
        }
        return obj;
      };
      return addUrl(this.restApiUrls, `${url ? `https://${url}/` : ``}${environment.api}/`);
    }),
    shareReplay()
  );

  constructor(
    private httpClient: HttpClient,
    private localStorage: LocalStorage,
    private sessionStorage: SessionStorage,
    private electronService: ElectronService,
    private router: Router,
    private store: Store<ModuleState.State>
  ) {
    let localstorageOrigin: string = this.getFromLocal<string>(APP_ELECTRON_ORIGIN_KEY);

    if (this.isElectronApp && !localstorageOrigin) {
      this.saveToLocal(IS_SERVER_NOT_FILLED_BY_USER, true);
    }

    const wsUrl = this.isElectronApp ? localStorage.getItem(WEBSOCKET_URL_STORAGE_KEY) : null;
    const originFromWebSocket: string = wsUrl ? wsUrl.replace('wss://', '') : null;
    if (!isTruthy(localstorageOrigin) || localstorageOrigin !== originFromWebSocket) {
      const environmentOrigin = originFromWebSocket ?? this.clearUrl(environment.origin || '');
      this.saveToLocal(APP_ELECTRON_ORIGIN_KEY, environmentOrigin);

      localstorageOrigin = environmentOrigin;
    }
    if (this.isElectronApp) {
      this.electronService.setBaseUrl(localstorageOrigin);
    }
    this.url$.next(localstorageOrigin);

    if (this.isElectronApp) {
      this.wrongApiStructure$
        .pipe(
          take(1),
          filter(wrongApi => {
            return wrongApi === true;
          }),
          switchMap(() => {
            return this.router.navigate(['auth', 'login']);
          })
        )
        .subscribe();
    }
  }

  set info(value: AppInfoModel) {
    this.infoSubject$.next(value);
  }

  saveToLocal(key: string, value: any, localStorage = true): void {
    const storage = localStorage ? this.localStorage : this.sessionStorage;
    storage.setItem(key, JSON.stringify(value));
  }

  getFromLocal<T>(key: string, localStorage = true): T {
    const storage = localStorage ? this.localStorage : this.sessionStorage;
    try {
      return JSON.parse(storage.getItem(key)) as T;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  removeFromLocal(key: string, localStorage = true): void {
    const storage = localStorage ? this.localStorage : this.sessionStorage;
    storage.removeItem(key);
  }

  clearUrl = (url: string): string => {
    return url.replace(/(^\w+:|^)\/\//, '');
  };

  createIdent(): ClientIdentification {
    const ident = new ClientIdentification({ environment: this.environment });
    return ident;
  }

  init(needSoftRestart = false): void {
    const identification: ClientIdentification = this.createIdent();

    this.getAppInfo(identification.clientId)
      .pipe()
      .subscribe(info => {
        if (!isTruthy(info)) {
          this.wrongApiStructureSubject$.next(true);
          of(null)
            .pipe(delay(10000), take(1))
            .subscribe(() => {
              return this.init(true);
            });
        } else {
          if (needSoftRestart) {
            location.reload();
          } else {
            this.wrongApiStructureSubject$.next(false);
            this.info = info;
            this.ident$.next(identification);
          }
        }
      });
  }

  imageUrlToBase64(url?: string): Observable<string> {
    return isTruthy(url)
      ? this.httpClient
          .get(url, {
            observe: 'body',
            responseType: 'arraybuffer'
          })
          .pipe(
            take(1),
            map(arrayBuffer => {
              return btoa(
                Array.from(new Uint8Array(arrayBuffer))
                  .map(b => {
                    return String.fromCharCode(b);
                  })
                  .join('')
              );
            })
          )
      : of(null);
  }

  makeRequest<T>(
    method: 'GET' | 'POST',
    action,
    paramsValues: { [key: string]: string | number } = {},
    body = null,
    observe: 'body' | 'response' = 'body',
    headersValues: {
      [key: string]: string;
    } = {},
    includeStatus = true
  ): Observable<T> {
    if (
      !['GET', 'POST'].find(value => {
        return value === method;
      })
    ) {
      return of(null);
    }
    let headers = new HttpHeaders(); //'Authorization': `Bearer ${value.value}`
    Object.keys(headersValues).forEach(header => {
      headers = headers.append(header, headersValues[header]);
    });
    let params = new HttpParams();
    Object.keys(paramsValues).forEach(param => {
      params = params.append(param, paramsValues[param]);
    });

    return this.restSources$.pipe(
      filter(restSources => {
        return isTruthy(restSources[method][action]);
      }),
      switchMap(restSources => {
        const options = body ? { headers, params, body, observe } : { headers, params, observe };
        this.store.dispatch(ExecutionAction.customProcessingStart({ objectId: `${method}_${action}` }));
        // @ts-ignore
        return this.httpClient.request<T>(method, restSources[method][action], options).pipe(
          take(1),
          map(response => {
            this.store.dispatch(ExecutionAction.customProcessingStop({ objectId: `${method}_${action}` }));
            if (includeStatus && observe === 'response') {
              const result = (<any>response).body ?? {};
              return { ...result, status: (<any>response).status };
            } else {
              return response;
            }
          }),
          catchError(err => {
            this.store.dispatch(ExecutionAction.customProcessingStop({ objectId: `${method}_${action}` }));
            return throwError(err);
          })
        );
      })
    );
  }

  getAppInfo(appId): Observable<AppInfoModel> {
    let params = { appId };
    if (!this.isElectronApp) {
      params = { ...params, ...{ redirectUri: `${window.location.href}` } };
    } else {
      params = { ...params, ...{ source: `app` } };
    }
    return this.makeRequest<AppInfoModel>('GET', 'INFO', params).pipe(
      /*	map(data => {
				if (environment.type === 'electron') {
					data.auth.redirect_uri = data.auth.redirect_uri.replace('localhost', 'breez-vm-1.k2t.app');
				}
				return data;
			}),*/
      catchError(() => {
        return of(null);
      })
    );
  }

  getConferenceInfo(id: string | number = 1): Observable<{ conference: any; users: any }> {
    return this.makeRequest<{ conference: any; users: any }>('GET', 'CONFERENCE_INFO', { id });
  }

  apiCheckAuth(): Observable<{ access_token: string; refresh_token: string }> {
    return this.ident$.pipe(
      take(1),
      map(ident => {
        return ident.clientId;
      }),
      switchMap(appId => {
        return timer(0, 2500).pipe(
          map(() => {
            return appId;
          })
        );
      }),
      switchMap(appId => {
        return this.makeRequest<{
          access_token: string;
          refresh_token: string;
        }>('GET', 'CHECK', { appId, v: (new Date().getTime() / 1000) | 0 });
      }),
      filter(result => {
        return isTruthy(result);
      }),
      take(1),
      timeout(30 * 60_000),

      catchError(err => {
        console.error(err);
        window.location.reload();
        return of(null);
      })
    );
  }

  getConference = (id: string | number, mockData = {}, mockConference = {}): Observable<Conference> => {
    return this.getConferenceInfo(id)
      .pipe(
        take(1),
        filter(conferenceInfo => {
          return isTruthy(conferenceInfo) && isTruthy(conferenceInfo?.conference) && isTruthy(conferenceInfo?.users);
        }),
        map(conferenceInfo => {
          return conferenceInfo.conference;
        }),
        catchError(() => {
          return of(mockConference);
        })
      )
      .pipe(
        map(conference => {
          return {
            ...conference,
            ...{
              startingAt: conference.startingat,
              endingAt: conference.endingat,
              conferenceId: conference.conferenceid,
              title: conference.name,
              statusId: conference.statusid,
              registrationStatus: conference.registrationstatus,
              isPublic: conference.ispublic,
              isMulticasting: conference.mcasting,
              isVoiceActivate: conference.voiceactivate,
              layoutId: conference.layoutid,
              enableChat: conference.enablechat,
              participantsCount: conference.participantscount,
              maxUsersCount: conference.maxuserscount
            },
            ...mockData
          };
        }),
        toClass(Conference)
      );
  };

  registerAsGuestToConference(token, name): Observable<{ access_token: string; refresh_token: string }> {
    return this.makeRequest<{ access_token: string; refresh_token: string }>('GET', 'CONFERENCE_REGISTER', {
      token,
      name
    }).pipe(
      tap(result => {
        this.saveToLocal(APP_KEYCLOAK_REFRESH, result.refresh_token, false);
        this.saveToLocal(APP_KEYCLOAK_TOKEN, result.access_token, false);
      })
    );
  }

  registrationCheckToken(token: string): Observable<{ status: number; email: string }> {
    return this.makeRequest<{ status: number; email: string }>(
      'POST',
      'REGISTRATION_CHECK_TOKEN',
      {},
      {
        token
      },
      'response'
    );
  }

  registrationSendVerification(token: string, email: string, locale?: string): Observable<{ status: number }> {
    return this.makeRequest<{ status: number }>(
      'POST',
      'REGISTRATION_SEND_VERIFICATION',
      {},
      isTruthy(locale) ? { token, email, locale } : { token, email },
      'response'
    );
  }

  registrationVerifyEmail(token: string, code: number): Observable<{ status: number }> {
    return this.makeRequest<{ status: number }>(
      'POST',
      'REGISTRATION_VERIFY_EMAIL',
      {},
      {
        token,
        code
      },
      'response'
    );
  }

  registrationRegister(
    token: string,
    data: {
      password: string;
      login: string;
      locale: string;
      email: string;
      lastname: string;
      firstname: string;
      gmt?: number;
      phone?: string;
      jobtitle?: string;
    }
  ): Observable<{ status: number; access_token: string; refresh_token: string }> {
    let request = { token, ...data };
    return this.makeRequest<{ status: number; access_token: string; refresh_token: string }>(
      'POST',
      'REGISTRATION_REGISTER',
      {},
      request,
      'response'
    ).pipe(
      tap(result => {
        this.saveToLocal(APP_KEYCLOAK_REFRESH, result.refresh_token, false);
        this.saveToLocal(APP_KEYCLOAK_TOKEN, result.access_token, false);
      })
    );
  }

  registerByEmailToConference(email, id): Observable<any> {
    return this.makeRequest<any>('GET', 'CONFERENCE_AUTH_BY_EMAIL', { email, id, target: 'conference' });
  }

  authByEmailCode(email, code): Observable<{ access_token: string; refresh_token: string }> {
    return this.makeRequest<{ access_token: string; refresh_token: string }>('GET', 'CONFERENCE_AUTH_VERIFY_CODE', {
      email,
      code
    }).pipe(
      tap(result => {
        this.saveToLocal(APP_KEYCLOAK_REFRESH, result.refresh_token, false);
        this.saveToLocal(APP_KEYCLOAK_TOKEN, result.access_token, false);
      })
    );
  }
}
