import { SelectionModel } from '@angular/cdk/collections';
import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { User } from '@breez/models';
import { ConferenceChatTitle } from '@breez/models/chat/chat-title.enum';
import { Division } from '@breez/models/division.model';
import { Participant } from '@breez/models/shared/participant/participant.model';
import { Contact, UserGroupType } from '@breez/modules/social/models';
import { SocialService, UserGroupService } from '@breez/modules/social/services';
import { UsersService } from '@breez/modules/users/services/users.service';
import { replayWhileSubs, toClass } from '@breez/shared/rxjs-operators';
import { fromControl } from '@breez/shared/rxjs-operators/from-control';
import { EmitOnChange } from '@breez/shared/utilities/decorators/emit-on-change.decorator';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { BehaviorSubject, combineLatest, interval, Observable, of, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap, take } from 'rxjs/operators';
import { ObjectListRequest } from '@breez/models/shared/paging';
import { uniqueArrayOfObjects } from '@breez/shared/utilities/uniqueArrayOfObjects';
import { CHAT_ID } from '../../types';
import { Chat, ChatParticipant, FoundChat } from '../../models';
import { ChatService } from '../../services';
import { Store } from '@ngrx/store';
import * as ModuleState from '../../+state/module.state';
import * as ChatActions from '../../+state/chat/chat.actions';
import * as UserActions from '@breez/modules/users/+state/users/user.actions';
import { ChatEntity } from '@breez/modules/chat/models/+state/chatEntity';
import { getAvatarEntity, isChatMute, isChatPin } from '@breez/modules/chat';
import { guid } from '@breez/helpers/guid';
import { ObjectType } from '@breez/shared/enums/object-type.enum';
import { TranslateService } from '@ngx-translate/core';

const IS_CHAT_BETWEEN_TWO_USERS = 2;

@Component({
  selector: 'vks-chat-list',
  templateUrl: './chat-list.component.html',
  styleUrls: ['./chat-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatListComponent implements OnChanges, OnInit {
  clicked = false;

  @HostBinding('style.--component-width')
  componentWidth: string;

  @HostListener('window:resize', ['$event'])
  bindWidth(): void {
    this.componentWidth = `${this.elementRef.nativeElement.offsetWidth - 7}px`;
  }

  @Input() currentUser: User;
  @Input() chats: ChatEntity[] | Chat[];
  @Input() selectedChat: ChatEntity | Chat;

  showArchiveChats = false;
  updateArchive = {};

  @EmitOnChange('chats')
  private readonly chatsSubject: ReplaySubject<ChatEntity[] | Chat[]> = new ReplaySubject<ChatEntity[] | Chat[]>(1);

  readonly chats$: Observable<ChatEntity[] | Chat[]> = this.chatsSubject.asObservable().pipe(distinctUntilChanged());

  @EmitOnChange('selectedChat')
  private selectedChat$: ReplaySubject<ChatEntity | Chat> = new ReplaySubject<ChatEntity | Chat>(1);

  @Input() conferenceParticipants: Participant[];

  @Input() isConferenceChatList = false;
  @EmitOnChange('isConferenceChatList')
  private readonly isConferenceChatListSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  readonly isConferenceChatList$: Observable<boolean> = this.isConferenceChatListSubject.pipe(distinctUntilChanged());

  @Input() showSearchForm = true;

  @Output() selectChat: EventEmitter<CHAT_ID> = new EventEmitter<CHAT_ID>();
  keywords = new UntypedFormControl('');

  @Output() createConferenceChat: EventEmitter<User[]> = new EventEmitter<User[]>();

  @ViewChildren(MatMenuTrigger) menuTriggers: QueryList<MatMenuTrigger>;
  @ViewChildren('chatDivs') chatDivs: QueryList<ElementRef>;

  @EmitOnChange<Participant[], Participant[]>('conferenceParticipants', {
    onlyTruthy: true,
    emitter: (value, subject) => {
      if (value.length) {
        subject.next(value);
      }
    }
  })
  conferenceParticipants$: BehaviorSubject<Participant[]> = new BehaviorSubject<Participant[]>([]);

  userTreeSelectionModel = new SelectionModel<User>(true, []);
  selectedUsers: User[] = [];

  contextMenuPosition = { x: '', y: '' };

  keywords$: Observable<string> = fromControl(this.keywords, 300).pipe(
    startWith(''),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  private readonly showUserTreeSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly showUserTree$: Observable<boolean> = this.showUserTreeSubject.asObservable().pipe(distinctUntilChanged());

  searchState$ = combineLatest([this.keywords$, this.showUserTree$]).pipe(
    map(([keywords, showUserTree]) => {
      return !!keywords || showUserTree;
    }),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  contacts$: Observable<User[]> = this.userGroupService.getUserGroups().pipe(
    switchMap(groups => {
      const group = groups.find(item => {
        return item.type === UserGroupType.ALLUSERS;
      });
      return this.socialService.getContacts({ filter: { group } } as ObjectListRequest);
    }),
    map(({ data }) => {
      return data;
    }),
    replayWhileSubs()
  );

  conferenceParticipantsAsUsers$: Observable<User[]> = this.conferenceParticipants$.pipe(
    map(participants => {
      return participants.map(conferenceParticipant => {
        return new User({ ...conferenceParticipant.participantReference });
      });
    })
  );

  usersSource$: Observable<User[]> = this.isConferenceChatList$.pipe(
    switchMap(isConferenceChatList => {
      return isConferenceChatList ? this.conferenceParticipantsAsUsers$ : this.contacts$;
    })
  );

  filteredContacts$: Observable<(Chat | Contact)[]> = combineLatest([
    this.usersSource$,
    this.keywords$,
    this.chats$,
    this.showUserTree$
  ]).pipe(
    filter(() => {
      return !!this.currentUser;
    }),
    map(([users, keywords, chats, showUserTree]) => {
      const entities = this.prepareChatListEntities(users as Contact[], chats as Chat[], showUserTree);
      const prepareString = (str: string): string[] => {
        return str ? str.toLocaleLowerCase().split(/[\s.,?!]+/) : [];
      };

      return entities.filter(entity => {
        const contactWords = prepareString(entity.name);

        return prepareString(keywords).every(keyword => {
          return contactWords.some(word => {
            return word.includes(keyword);
          });
        });
      });
    })
  );

  chatList$: Observable<(Chat | Contact | ChatEntity)[]> = this.keywords$.pipe(
    switchMap(keywords => {
      return keywords ? this.filteredContacts$ : this.chats$;
    })
  );

  private prepareChatListEntities(contacts: Contact[], chats: Chat[], showUserTree: boolean): (Chat | Contact)[] {
    const currentUserId = this.currentUser.id;
    const interlocutors = contacts.filter(contact => {
      return contact.id !== currentUserId;
    });

    if (showUserTree) {
      return interlocutors.map(interlocutor => {
        return { ...interlocutor } as Contact;
      });
    }

    const stayedOffContactsInChatListById: Set<number> = new Set();
    chats.forEach(chat => {
      if (chat.participantsCount === IS_CHAT_BETWEEN_TWO_USERS) {
        const existsUserId = chat.participantUserIds.find(userId => {
          return userId !== currentUserId;
        });
        if (typeof existsUserId === 'number') stayedOffContactsInChatListById.add(existsUserId);
      }
    });

    stayedOffContactsInChatListById.delete(currentUserId);

    const stayedOffContactsInChatList = interlocutors.filter(user => {
      return !stayedOffContactsInChatListById.has(user.id);
    });
    const potentialParticipants = contacts.map(user => {
      return { userId: user.id, user };
    });

    const entities = chats.map(chat => {
      if (chat.participantsCount === IS_CHAT_BETWEEN_TWO_USERS) {
        const chatParticipantId = chat.participantUserIds.find(userId => {
          return userId !== currentUserId;
        });
        const participant = potentialParticipants.find(user => {
          return user.userId === chatParticipantId;
        });
        if (participant) {
          // пользователя может не быть
          return { ...chat, name: participant.user.name };
        }

        return;
      }

      const participants = contacts.filter(user => {
        if (!chat.participantUserIds) {
          return;
        }

        return chat.participantUserIds.includes(user.id);
      });
      const participantsAsChatParticipants = participants.map(p => {
        return { userId: p.id, user: p };
      });

      return {
        ...chat,
        ...getAvatarEntity(chat, participantsAsChatParticipants as unknown as ChatParticipant[], this.currentUser)
      } as ChatEntity;
    }) as Chat[];

    entities.push(...(stayedOffContactsInChatList as unknown as Chat[]));

    return entities.filter(entity => {
      return entity;
    });
  }

  useVirtualScroll$: Observable<boolean> = this.isConferenceChatList$.pipe(
    map(isConferenceChatList => {
      return !isConferenceChatList;
    }),
    distinctUntilChanged()
  );

  divisionsList$: Observable<Division[]> = combineLatest([
    this.isConferenceChatList$,
    this.showUserTree$,
    this.translateService.onLangChange.pipe(startWith(null))
  ]).pipe(
    switchMap(([isConferenceChatList, showUserTree]) => {
      if (isConferenceChatList) {
        return of([]);
      }
      if (!showUserTree) {
        return of([]);
      }

      return this.usersService.getDivisions();
    })
  );

  selectedUsers$: ReplaySubject<User[]> = new ReplaySubject<User[]>(1);

  trackByChatId = (_, chat: Chat): CHAT_ID => {
    return chat.id;
  };

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private readonly userGroupService: UserGroupService,
    private readonly socialService: SocialService,
    private translateService: TranslateService,
    private readonly chatService: ChatService,
    private readonly router: Router,
    private readonly usersService: UsersService,
    private store: Store<ModuleState.State>,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.store.dispatch(
      ChatActions.createChatSuccess({
        tempId: null,
        chat: {
          id: 'favorites',
          name: 'Избранное',
          pinnedOrder: 1,
          lastMessage: null,
          createdOn: new Date(),
          conferenceId: null,
          callId: null
        }
      })
    );
    this.store.dispatch(
      ChatActions.createChatSuccess({
        tempId: null,
        chat: {
          id: 'archive',
          name: 'Архив',
          pinnedOrder: 1,
          lastMessage: null,
          createdOn: new Date(),
          conferenceId: null,
          callId: null
        }
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.chats && changes.chats.currentValue) {
      this.chats = uniqueArrayOfObjects(changes.chats.currentValue, 'id');
    }
  }

  openMenu(event: MouseEvent, index: number): void {
    event.preventDefault();
    const menuTrigger = this.menuTriggers.toArray()[index];
    this.contextMenuPosition.x = `${event.clientX}px`;
    this.contextMenuPosition.y = `${event.clientY + 20}px`;
    menuTrigger.openMenu();
  }

  startContextMenuTimer($event: any, index: number): void {
    $event.preventDefault();
    this.document.body.style.webkitUserSelect = 'none';
    this.contextMenuPosition.x = $event.center.x - 65 + 'px';
    this.contextMenuPosition.y = $event.center.y - 65 + 'px';
    const menuTrigger = this.menuTriggers.toArray()[index];
    interval(400)
      .pipe(take(1))
      .subscribe(() => {
        menuTrigger.openMenu();
        this.document.body.style.webkitUserSelect = 'text';
      });
  }

  isLinkActive(targetId: number): Observable<boolean> {
    return this.selectedChat$.pipe(
      map(chat => {
        return chat?.id;
      }),
      map(id => {
        return String(id) === String(targetId);
      })
    );
  }

  markChatAsRead(chatId: CHAT_ID): void {
    this.chatService.markChatAsRead(chatId).pipe(take(1)).subscribe();
  }

  selectConversationWithUser(user: User): void {
    if (this.showUserTreeSubject.value) {
      this.selectedUsers.push(user);
      this.selectedUsers$.next(this.selectedUsers);
      return;
    }

    if (this.isConferenceChatList && !this.showUserTreeSubject.value) {
      this.createConferenceChat.emit([user]);
      this.showUserTreeSubject.next(false);
      this.keywords.patchValue('');
      return;
    }

    if (this.showUserTreeSubject.value) {
      this.showUserTreeSubject.next(false);
    }

    this.keywords.patchValue(null);

    const currentUserId = this.currentUser.id;
    const interlocutorId = user.id;
    this.chatService
      .getChatByParticipantsAndConferenceIds([interlocutorId])
      .pipe(
        take(1),
        toClass(FoundChat),
        map(response => {
          const foundChat = response.chat;

          if (foundChat) {
            return foundChat.id;
          } else {
            const tempId = guid();
            const participantIds = [currentUserId, interlocutorId];
            this.store.dispatch(ChatActions.createChat({ participantIds, tempId }));
            return tempId;
          }
        })
      )
      .subscribe(chatId => {
        return this.chatService.openChat(chatId);
      });
  }

  showUserTree(): void {
    const currentState = this.showUserTreeSubject.value;
    this.userTreeSelectionModel = new SelectionModel<User>(true, null);

    if (!currentState && this.keywords.value) {
      this.keywords.patchValue('');
      return;
    }

    if (currentState === true) {
      this.showUserTreeSubject.next(!currentState);
      this.selectedUsers$.next(null);
      this.selectedUsers = [];
      this.keywords.patchValue('');
      return;
    }

    this.showUserTreeSubject.next(!currentState);
  }

  createGroupChat(): void {
    this.selectedUsers$
      .pipe(
        take(1),
        switchMap((selectedUsers: User[]) => {
          this.store.dispatch(UserActions.loadUsersSuccess({ users: selectedUsers }));
          const interlocutorsIds = selectedUsers.map(user => {
            return user.id;
          });

          if (this.isConferenceChatList) {
            this.createConferenceChat.emit(selectedUsers);
            return of(null);
          }

          return this.chatService.getChatByParticipantsAndConferenceIds(interlocutorsIds).pipe(
            take(1),
            toClass(FoundChat),
            map(response => {
              if (response.chat && response.users?.length === IS_CHAT_BETWEEN_TWO_USERS) {
                return response.chat.id;
              } else {
                const tempId = guid();
                const participantIds = interlocutorsIds;
                this.store.dispatch(ChatActions.createChat({ participantIds, tempId }));
                return tempId;
              }
            })
          );
        })
      )
      .subscribe(chatId => {
        if (isTruthy(chatId)) {
          if (this.showUserTreeSubject.value) {
            this.showUserTreeSubject.next(false);
          }

          this.keywords.patchValue(null);
          this.selectedUsers = [];
          this.selectedUsers$.next(null);
          this.userTreeSelectionModel = new SelectionModel<User>(true, null);
          this.chatService.recountLoadedChats();
          this.router.navigate(['/', 'chat'], { queryParams: { id: chatId } });
          this.onSelectChat(chatId);
        }
      });
  }

  clearSelectedUsers($event: User[]): void {
    this.selectedUsers = this.removeDeselectedUsers(this.selectedUsers, $event);
    this.selectedUsers$.next(this.selectedUsers.length > 0 ? this.selectedUsers : null);
  }

  private onSelectChat(chatId: CHAT_ID): void {
    if (chatId === 'archive') {
      this.toggleShowArchiveChats();
      return;
    }

    if (this.isConferenceChatList) {
      this.selectChat.emit(chatId);
    }

    this.store.dispatch(ChatActions.selectChat({ chatId }));
  }

  toSelectedChat(entity: Chat | User | Contact): void {
    if ((entity instanceof Contact || entity instanceof User) && entity.type === ObjectType.USER) {
      this.selectedUsers$.next([entity] as User[]);
      this.createGroupChat();
      return;
    }

    this.keywords.patchValue(null);
    this.clicked = false;
    this.onSelectChat(entity.id);
  }

  selectDivisionUsers($event: User[]): void {
    const isArrayAndNotEmpty = Array.isArray($event) && $event.length > 0;

    if (this.showUserTreeSubject.value && isArrayAndNotEmpty) {
      const filteredUsers = $event.filter(user => {
        return (
          this.selectedUsers.findIndex(selectedUser => {
            return selectedUser.id === user.id;
          }) === -1
        );
      });

      this.selectedUsers.push(...filteredUsers);
      this.selectedUsers$.next(this.selectedUsers);
    }
  }

  removeDeselectedUsers(selectedUsers: User[], usersToRemove: User[]): User[] {
    return selectedUsers.filter(user => {
      return (
        usersToRemove.findIndex(userToRemove => {
          return userToRemove.id === user.id;
        }) === -1
      );
    });
  }

  notMainChat(chat: Chat): boolean {
    return chat.name !== ConferenceChatTitle.MAIN_CHAT; // TODO -_-
  }

  isChatPin(chat: ChatEntity | Chat): boolean {
    return isChatPin(chat);
  }

  showChatPinnedBadge(chat: Chat): boolean {
    return this.isChatPin(chat);
  }

  changeNotification(chatId: CHAT_ID, disable: boolean): void {
    this.store.dispatch(ChatActions.changeNotification({ chatId, disable }));
  }

  addChatToArchive(chatId, value = true): void {
    const chatsStorage = localStorage.getItem('chats-info');

    const chats = chatsStorage ? JSON.parse(chatsStorage) : [];

    let ind = chats?.findIndex(x => {
      return x?.chatId === chatId;
    });

    let chat =
      chats?.find(x => {
        return x?.chatId === chatId;
      }) ?? {};
    chat = { ...chat, archive: value, chatId: chatId };

    if (ind !== -1) {
      chats[ind] = chat;
    } else {
      chats.push(chat);
    }
    this.updateArchive = { ...this.updateArchive };
    localStorage.setItem('chats-info', JSON.stringify(chats));
  }

  toggleShowArchiveChats(): void {
    this.showArchiveChats = !this.showArchiveChats;
    this.updateArchive = { ...this.updateArchive };
  }

  isArchived(chatId): boolean {
    const chatsStorage = localStorage.getItem('chats-info');
    if (!chatsStorage) {
      return false;
    }

    return !!JSON.parse(chatsStorage).find(x => {
      return x.chatId === chatId && x.archive === true;
    });
  }

  changePinChat(chatId: CHAT_ID, pin: boolean): void {
    this.store.dispatch(ChatActions.changePin({ chatId, pin }));
  }

  isChatMute(chat: ChatEntity | Chat): boolean {
    return isChatMute(chat);
  }
}
