<div [class.notification--mobile]="isMobile$ | async" (click)="notification.afterClick()" class="notification">
  <div class="notification-container">
    <div *ngIf="!(isMobile$ | async) && (notification.iconUrl || notification.avatar)" class="notification-image">
      <img
        *ngIf="notification.iconUrl"
        [alt]="notification.title"
        [src]="notification.iconUrl | safe: 'url'"
        class="avatar"
      />
      <vks-user-avatar *ngIf="notification.avatar" [entity]="notification.avatar"></vks-user-avatar>
    </div>
    <div [class.notification-message--mobile]="isMobile$ | async" class="notification-message">
      <div class="notification-header">
        <span class="notification-header_text">
          {{ notification.title || 'NOTIFICATION.' + notification.type | translate }}
        </span>
        <vks-icon (click)="onClose()" class="square18" icon="close"></vks-icon>
      </div>
      <div [class.notification-body--mobile]="isMobile$ | async" class="notification-body">
        <div class="notification-body_text">
          <div class="subtitle">
            {{ notification.subtitle }}
          </div>
          <div class="body">
            {{ notification.message }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
