<vks-modal-header class="bg-transparent" [title]="headerText | translate" [icon]="'warning'" (close)="close()">
  <!--	<mat-card-title>{{ '' | translate }}</mat-card-title>-->
</vks-modal-header>
<div class="content">
  <p>
    {{ contentText | translate }}
  </p>
</div>
<div class="action">
  <button
    vks-button
    *ngIf="helpLink$ | async as helpLink"
    [label]="'HELP_BUTTON' | translate"
    (buttonClick)="openHelp(helpLink.url)"
    class="btn-anchor btn-unfocused"
  ></button>

  <button
    vks-button
    [label]="actionText | translate"
    (buttonClick)="reconnect()"
    class="btn-basic action-button"
  ></button>
</div>
