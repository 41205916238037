import { Inject, inject, Injectable, InjectionToken } from '@angular/core';
import { IConferenceTemplateInfo } from '@breez/models/template/conference-template-info.model';
import { WebsocketEvents, WebsocketService } from '@breez/modules/websocket';
import { replayWhileSubs } from '@breez/shared/rxjs-operators';
import { Observable } from 'rxjs';

export const TEMPLATES_INFO = new InjectionToken<Observable<IConferenceTemplateInfo[]>>(
  'array of available conference templates',
  {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    factory: () => {
      return inject(WebsocketService).query(WebsocketEvents.RECEIVE.TEMPLATES.GET);
    }
  }
);

@Injectable({
  providedIn: 'root'
})
export class TemplatesInfo extends Observable<IConferenceTemplateInfo[]> {
  constructor(@Inject(TEMPLATES_INFO) templatesInfo$: Observable<IConferenceTemplateInfo[]>) {
    super();

    return templatesInfo$.pipe(replayWhileSubs());
  }
}
