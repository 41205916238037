<vks-modal-header [noMargin]="true" [title]="'DEVICES' | translate" (close)="close()"></vks-modal-header>

<mat-dialog-content>
  <vks-media-sources-selector
    [currentMedia]="currentMedia"
    [isSettingInConference]="true"
    (resultSources)="saveMediaSources($event)"
    (effectsSdkSettingEmit)="saveEffectsSdkSettings($event)"
    [effectsSdkSetting]="{ blur }"
  ></vks-media-sources-selector>
  <button
    vks-button
    [disabled]="!(mediaSources?.length > 0) || (timer$ | async | not)"
    [label]="'APPLY' | translate"
    (buttonClick)="applyAndClose()"
    class="btn-primary apply-button"
  ></button>
</mat-dialog-content>
