import moment from 'moment';

export function getStartOfDay(date?: Date): Date {
  return moment(date || new Date())
    .startOf('day')
    .toDate();
}

export function getEndOfDay(date?: Date): Date {
  return moment(date || new Date())
    .endOf('day')
    .toDate();
}

export function getNDayAfterNow(amountDays: number): Date {
  return moment().add(amountDays, 'days').endOf('day').toDate();
}

export function getNDayBeforeNow(amountDays: number): Date {
  return moment().add(-amountDays, 'days').startOf('day').toDate();
}

export function getStartOfMonth(date?: Date): Date {
  return moment(date || new Date())
    .startOf('month')
    .startOf('day')
    .toDate();
}

export function getEndOfMonth(date?: Date): Date {
  return moment(date || new Date())
    .endOf('month')
    .endOf('day')
    .toDate();
}

export function getEndOfYear(): Date {
  return moment().endOf('year').endOf('day').toDate();
}

export function getStartOfYear(): Date {
  return moment().startOf('year').startOf('day').toDate();
}

export function getMinutesDuration(startDate: Date, endDate: Date): number {
  const duration = moment.duration(moment(startDate).diff(endDate));
  return Math.abs(duration.asMinutes());
}

export function getDuration(startDate: Date, endDate: Date): number {
  if (startDate && endDate) {
    return Math.abs(moment.duration(moment(startDate).diff(endDate)).days());
  }
  return null;
}

export function getFirstMondayBeforeDate(date: Date): Date {
  let currentDay = date.getDay();
  while (currentDay !== 1) {
    date = moment(date).subtract(1, 'day').toDate();
    currentDay = date.getDay();
  }
  return date;
}

export function getFirstMondayAfterDate(date: Date): Date {
  let currentDay = date.getDay();
  while (currentDay !== 1) {
    date = moment(date).add(1, 'day').toDate();
    currentDay = date.getDay();
  }
  return date;
}

export function getEndOfWeek(date: Date): Date {
  let currentDay = date.getDay();
  while (currentDay !== 0) {
    date = moment(date).add(1, 'day').toDate();
    currentDay = date.getDay();
  }
  return date;
}

export function getDateWithoutTime(date: Date): Date {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export function getTimeOfDate(date: Date): string {
  return date.toLocaleTimeString();
}

export function getTimezonefromNumber(zone: number): string {
  if (!zone) {
    return '';
  }
  const abs = Math.abs(zone);
  return `${zone >= 0 ? '+' : '-'}${abs < 10 ? '0' : ''}${abs}:00`;
}

export function compareDate(_: Date, __: Date): boolean {
  return _?.getTime() === __?.getTime();
}
