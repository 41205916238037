import { Paging } from './paging.model';
import { Order } from './order.model';

export interface ObjectListRequest {
  paging?: Paging;
  filter?: Record<string, unknown>;
  target?: string;
  order?: Order;
  withinvitedusers?: boolean;
}
