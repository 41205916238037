import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, InjectionToken, Optional, PLATFORM_ID } from '@angular/core';

import { CookieService, CookieServiceSetOptions } from '../interfaces/cookie-service.interface';
export const REQUEST = new InjectionToken<string>('BaseCookieServiceREQUEST');
export const RESPONSE = new InjectionToken<string>('BaseCookieServiceRESPONSE');

@Injectable()
export class BaseCookieService implements CookieService {
  private readonly documentIsAccessible: boolean;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(REQUEST) @Optional() private request: Request,
    @Inject(RESPONSE) @Optional() private response: Response
  ) {
    this.documentIsAccessible = isPlatformBrowser(this.platformId);
  }

  getCookieString(): string {
    return this.documentIsAccessible ? this.document.cookie : null;
  }

  check(name: string): boolean {
    name = encodeURIComponent(name);
    const regExp: RegExp = this.getCookieRegExp(name);

    return regExp.test(this.getCookieString());
  }

  get(name: string): string {
    if (this.check(name)) {
      name = encodeURIComponent(name);

      const regExp: RegExp = this.getCookieRegExp(name);
      const result: RegExpExecArray = regExp.exec(this.getCookieString());

      return decodeURIComponent(result[1]);
    }

    return null;
  }

  getAll(): { [key: string]: string } {
    const cookies: { [key: string]: string } = {};

    if (this.getCookieString() && this.getCookieString() !== '') {
      const splits = this.getCookieString().split(';');
      for (const split of splits) {
        const currentCookie = split.split('=');

        currentCookie[0] = currentCookie[0].replace(/^ /, '');
        cookies[decodeURIComponent(currentCookie[0])] = decodeURIComponent(currentCookie[1]);
      }
    }

    return cookies;
  }

  put(
    name: string,
    value: string,
    options: Partial<CookieServiceSetOptions> = { sameSite: 'none', secure: true }
  ): void {
    let cookieString = encodeURIComponent(name) + '=' + encodeURIComponent(value) + ';';

    if (options.expires) {
      cookieString += 'expires=' + options.expires.toUTCString() + ';';
    }

    if (options.path) {
      cookieString += 'path=' + options.path + ';';
    }

    if (options.domain) {
      cookieString += 'domain=' + options.domain + ';';
    }

    if (options.secure) {
      cookieString += 'secure;';
    }

    if (options.sameSite) {
      cookieString += 'sameSite=' + options.sameSite + ';';
    }

    if (this.documentIsAccessible) {
      this.document.cookie = cookieString;
    }
  }

  /**
   * @param name Cookie name
   */
  protected getCookieRegExp(name: string): RegExp {
    const escapedName: string = name.replace(/([\[\]\{\}\(\)\|\=\;\+\?\,\.\*\^\$])/gi, '\\$1');

    return new RegExp('(?:^' + escapedName + '|;\\s*' + escapedName + ')=(.*?)(?:;|$)', 'g');
  }
}
