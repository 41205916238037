import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MessageService } from '../../services';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { DeclensionService } from '@breez/shared/services/declension.service';
import { distinctUntilChanged, filter, map, startWith, switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { replayWhileSubs } from '@breez/shared/rxjs-operators';
import { EmitOnChange } from '@breez/shared/utilities/decorators/emit-on-change.decorator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { LangChangeEvent } from '@ngx-translate/core/lib/translate.service';
import { Message } from '../../models';

@UntilDestroy()
@Component({
  selector: 'vks-forward-messages-preview',
  templateUrl: './forward-messages-preview.component.html',
  styleUrls: ['./forward-messages-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForwardMessagesPreviewComponent implements OnInit, AfterViewInit, OnChanges {
  selectedLang$: Observable<string> = this.translateService.onLangChange.pipe(
    map((event: LangChangeEvent) => {
      return event.lang;
    }),
    startWith(this.translateService.currentLang),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  @Input() messageList: Message[];
  @Input() forMessageEdit: boolean;
  @Input() isOut: boolean;
  @ViewChild('messageBody') messageBody: ElementRef;

  @EmitOnChange('messageList')
  messageList$: Observable<Message[]> = new ReplaySubject<Message[]>(1);

  @Output() cancelForward = new EventEmitter<void>();

  senderPreview$: ReplaySubject<string> = new ReplaySubject<string>(1);

  constructor(
    private readonly declensionService: DeclensionService,
    private readonly translateService: TranslateService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.getSenderContent(this.messageList[0]).subscribe(this.senderPreview$);
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnChanges(_: SimpleChanges): void {}

  processMessagePreview(message: Message): Observable<string> {
    return this.selectedLang$.pipe(
      untilDestroyed(this),
      map(() => {
        return message.body;
      }),
      switchMap(content => {
        return combineLatest([
          this.messageService.parseMessage(
            content,
            this.messageService.messageIsSystem(message) || this.messageService.messageForwardBySystem(message)
          ),
          of(content)
        ]);
      }),

      map(([{ messageItems }, content]) => {
        if (messageItems.length && this.messageBody) {
          const messageBodyNativeElement = this.messageBody.nativeElement as HTMLParagraphElement;
          this.messageService.fillNative(messageItems, messageBodyNativeElement);
        }

        return content;
      })
    );
  }

  getMessagesCount(messages: Message[]): Observable<string> {
    const messagesCount = messages.length;
    return this.declensionService.getDeclension('MESSAGES', messagesCount).pipe(
      map(translated => {
        return `${messagesCount} ${translated}`;
      })
    );
  }

  getSenderContent(message: Message): Observable<string> {
    if (!message) {
      return of('');
    }
    if (this.forMessageEdit) {
      return of(message?.sender?.user?.name ?? '');
    }

    if (!message.originalSender) {
      return of('');
    }

    const senderName = message.originalSender.user.name;

    return this.translateService.stream('FORWARDED_FROM').pipe(
      map(translated => {
        return `${translated}: ${senderName}`;
      })
    );
  }

  ngAfterViewInit(): void {
    this.messageList$
      .pipe(
        filter(isTruthy),
        switchMap(_ => {
          return this.messageList.length === 1 && isTruthy(this.messageList[0])
            ? this.processMessagePreview(this.messageList[0])
            : of(null);
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
