import { AvatarDisplayable } from '@breez/modules/user/modules/models/avatar-displayable.model';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { Expose, Transform } from 'class-transformer';
import { ObjectType } from '@breez/shared/enums/object-type.enum';

export interface IEntity {
  id: number;
  type: ObjectType;
  email?: string;

  compare(anotherReference: IEntity): boolean;
}

export class EntityReference implements AvatarDisplayable, IEntity {
  @Expose()
  id: number;

  @Expose()
  @Transform(({ value }) => {
    return value ? ObjectType[value.toUpperCase()] : undefined;
  })
  type: ObjectType;

  @Expose()
  name: string;

  @Expose()
  email: string;

  avatarUrl: string;
  departmenttype?: string;

  constructor(base: Partial<EntityReference>) {
    return Object.assign(this, base);
  }

  compare(anotherReference: IEntity): boolean {
    if (!isTruthy(anotherReference)) {
      return false;
    }

    if (this.type !== anotherReference.type) {
      return false;
    }

    if (isTruthy(this.id) && isTruthy(anotherReference.id)) {
      return this.id === anotherReference.id;
    }

    if (isTruthy(this.email) && isTruthy(anotherReference.email)) {
      return this.email === anotherReference.email;
    }

    return false;
  }
}
