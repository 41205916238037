import { Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import * as ModuleState from '@breez/modules/chat/+state/module.state';
import * as ExecutionSelectors from '@breez/+state/execution/execution.selectors';
import * as ExecutionAction from '@breez/+state/execution/execution.actions';
import { ReplaySubject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { isTruthy } from '@breez/shared/utilities/is-truthy';

@Directive({
  selector: '[vks-loading-condition]'
})
export class LoadingConditionDirective implements OnChanges, OnInit, OnDestroy {
  @Input()
  storeKey: string;

  @Input()
  loadingClass: string = 'execution';

  private storeKey$: ReplaySubject<string> = new ReplaySubject<string>(1);

  private subscription: Subscription;

  private readonly executionProcessing$ = this.storeKey$.pipe(
    filter(isTruthy),
    distinctUntilChanged(),
    switchMap(storeKey => {
      return this.store.select(ExecutionSelectors.executionProcessing(ExecutionAction.customProcessingStart, storeKey));
    }),
    distinctUntilChanged()
  );

  constructor(
    private store: Store<ModuleState.State>,
    private element: ElementRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.storeKey) {
      this.storeKey$.next(changes.storeKey.currentValue);
    }
  }

  ngOnInit(): void {
    this.subscription = this.executionProcessing$.subscribe(processing => {
      if (processing) {
        this.element.nativeElement.classList.add(this.loadingClass);
      } else {
        this.element.nativeElement.classList.remove(this.loadingClass);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
