import { Action } from '@ngrx/store';
import { AppNotificationData } from '@breez/modules/notification/models/app-notification.model';

export enum ActionTypes {
  Confirm = '[Notification] confirm',
  Add = '[Notification] add',
  AddAll = '[Notification] add all',
  Clear = '[Notification] clear'
}

export class Add implements Action {
  readonly type = ActionTypes.Add;

  constructor(public payload: AppNotificationData) {}
}

export type Actions = Add;
