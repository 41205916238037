import { Injectable } from '@angular/core';
import { StateService } from '@breez/shared/services/state.service';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { ElectronService } from '@breez/modules/core/services';
import { UserSettingSectionId } from '@breez/modules/settings/enums/user-setting-section-id.enum';
import { UserSettingsItem } from '@breez/modules/settings/models/user-settings-item';
import { fromEvent, Observable } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { untilDestroyed } from 'ngx-take-until-destroy';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {
  readonly isElectronApp: boolean = this.electronService.isElectron;

  get sections(): UserSettingsItem[] {
    return [
      {
        id: UserSettingSectionId.GENERAL_SETTINGS,
        label: 'GENERAL_SETTINGS',
        icon: 'settings',
        canActivate: this.isElectronApp,
        active: true,
        scheme: {
          icon: null,
          type: 'simple',
          title: 'GENERAL_SETTINGS',

          controls: [
            {
              key: 'enable-auto-launch',
              type: 'toggle',
              title: 'ENABLE_AUTO_LAUNCH',
              keyOnDTO: 'enableAutoLaunch',

              controls: undefined
            }
          ],
          description: []
        }
      }
    ];
  }

  actions({ key, value }): void {
    switch (key) {
      case `${UserSettingSectionId.GENERAL_SETTINGS}-enable-auto-launch`:
        if (this.isElectronApp) {
          this.electronService.electronApi.send(key, value);
        }
        break;
      default:
        break;
    }
  }

  get enabledSections(): UserSettingsItem[] {
    return this.sections.filter(section => {
      return section.canActivate;
    });
  }

  get observedKeys(): string[] {
    return this.enabledSections
      .map(section => {
        return section.scheme.controls.map(control => {
          return `${section.id}-${control.key}`;
        });
      })
      .reduce((acc, currentValue) => {
        return [...acc, ...currentValue];
      }, []);
  }

  constructor(
    private stateService: StateService,
    private electronService: ElectronService
  ) {
    this.observeSettings();
  }

  getPropertyValue<T>(key: string, defaultValue?: T): T {
    const storageValue = this.stateService.getFromLocal<T>(key);
    if (!storageValue && defaultValue) {
      this.stateService.saveToLocal(key, defaultValue);
    }
    return storageValue ?? defaultValue ?? null;
  }

  updatePropertyValue(key: string, value?: any): void {
    if (isTruthy(value)) {
      this.stateService.saveToLocal(key, value);
    } else {
      this.stateService.removeFromLocal(key);
    }
  }

  observeSettings(): void {
    const autoLaunchKey = `${UserSettingSectionId.GENERAL_SETTINGS}-enable-auto-launch`;
    const enableAutoLaunch = this.getPropertyValue(autoLaunchKey);
    if (!isTruthy(enableAutoLaunch)) {
      this.updatePropertyValue(autoLaunchKey, true);
      this.actions({ key: autoLaunchKey, value: true });
    }
    const observedSetting: Observable<any> = fromEvent<StorageEvent>(window, 'storage').pipe(
      filter(event => {
        return this.observedKeys.includes(event.key);
      }),
      map(event => {
        return { key: event.key, value: this.getPropertyValue(event.key) };
      }),
      untilDestroyed(this),
      catchError(e => {
        console.error(e);
        return e;
      })
    );
    observedSetting.subscribe(({ key, value }) => {
      return this.actions({ key, value });
    });
  }
}
