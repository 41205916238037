import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nestedFields'
})
export class NestedFieldsPipe implements PipeTransform {
  transform(value: any, fieldsArray: string[]): any {
    return fieldsArray.reduce((acc, val) => {
      return acc ? acc[val] : acc;
    }, value);
  }
}
