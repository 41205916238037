import { Action, createReducer, on } from '@ngrx/store';
import * as ChatDraftMessageState from './chatDraftMessage.state';
import * as ChatDraftMessageActions from './chatDraftMessage.actions';
import { isTruthy } from '@breez/shared/utilities/is-truthy';

// @ts-ignore
const reducer = createReducer(
  ChatDraftMessageState.initialState,
  on(ChatDraftMessageActions.upsertDraft, (state, { chatId, draft }) => {
    return isTruthy(draft) ? ChatDraftMessageState.adapter.upsertOne({ chatId, draft: draft ?? '' }, state) : state;
  })
);

export function chatDraftMessageReducer(
  state: ChatDraftMessageState.State | undefined,
  action: Action
): ChatDraftMessageState.State {
  return reducer(state, action);
}
