import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'vks-reconnect-request',
  templateUrl: './reconnect-request.component.html',
  styleUrls: ['./reconnect-request.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReconnectRequestComponent implements OnInit {
  message: string;

  constructor(
    private dialogRef: MatDialogRef<ReconnectRequestComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogInputData: { message: string }
  ) {}

  ngOnInit(): void {
    this.message = this.dialogInputData.message;
  }

  resolve(): void {
    this.dialogRef.close(true);
  }
}
