import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Renderer2,
  Self,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { UserMediaSource } from '@breez/models/webrtc/media-source.model';
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { FocusMonitor } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { AppService } from '@breez/app.service';
import {
  mediaSourceKindHelper,
  MediaSourceKindHelperType
} from '@breez/modules/webrtc/helpers/media-source-kind.helper';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MediaSourceKind } from '@breez/models/webrtc/media-source-kind.enum';

@UntilDestroy()
@Component({
  selector: 'vks-media-source-selector',
  templateUrl: './media-source-selector.control.html',
  styleUrls: ['./media-source-selector.control.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: MediaSourceSelectorControlComponent }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaSourceSelectorControlComponent
  implements ControlValueAccessor, MatFormFieldControl<UserMediaSource>, OnInit, OnChanges, OnDestroy
{
  @Input()
  showToggle = false;

  @Input()
  enabled = false;

  @Input()
  sources: UserMediaSource[] = [];

  @Input()
  sourceKind: MediaSourceKind;

  @Input()
  hasPermission = false;

  @Input()
  warning = false;

  icon: string = null;
  toggleTooltip: string = null;
  isDefaultEnabled = this.appService.isMobile || this.appService.isFireFox || this.appService.isSafari;
  permissionWarningMessage = 'GET_PERMISSION_WARNING';
  noSourceTitleMessage = 'DEFAULT_DEVICE';
  noSourcesMessage = 'NO_DEVICES';
  hasSourcesWarning = false;
  private _placeholder: string;
  private _disabled = false;
  private _required = false;

  @ViewChild('sourceSelector') sourceSelector: HTMLElement;

  form: FormGroup<{
    enabled: FormControl<boolean>;
    sourceId: FormControl<string | null>;
  }>;

  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  controlType = 'media-source-selector';
  id = `${this.controlType}-${MediaSourceSelectorControlComponent.nextId++}`;
  static nextId = 0;
  onChange = (_: any): void => {};
  onTouched = (): void => {};
  writeValue = (mediaSource: UserMediaSource | null): any => {
    return this.updateValue({
      sourceId: mediaSource?.id,
      enabled: mediaSource?.enabled
    });
  };

  registerOnChange = (fn: any): any => {
    return (this.onChange = fn);
  };

  registerOnTouched = (fn: any): any => {
    return (this.onTouched = fn);
  };

  setDisabledState = (isDisabled: boolean): any => {
    return (this.disabled = isDisabled);
  };

  trackSourceById = (_: number, source: UserMediaSource): any => {
    return source.id;
  };

  get empty(): boolean {
    const {
      value: { sourceId }
    } = this.form;

    return !sourceId;
  }

  get shouldLabelFloat(): any {
    return this.focused || !this.empty;
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    if (this._disabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }

    this.stateChanges.next();
  }

  @Input()
  get value(): UserMediaSource | null {
    if (this.form.valid) {
      const {
        value: { sourceId }
      } = this.form;
      return this.sources.find(item => {
        return item.id === sourceId;
      });
    }
    return null;
  }

  get errorState(): boolean {
    return this.form.invalid && this.touched;
  }

  get mediaSourceKindHelper(): MediaSourceKindHelperType {
    return mediaSourceKindHelper(this.sourceKind);
  }

  constructor(
    private appService: AppService,
    private formBuilder: FormBuilder,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private _renderer: Renderer2,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.form = this.formBuilder.group({
      sourceId: [null],
      enabled: [false]
    });
  }

  ngOnInit(): void {
    if (this.shouldLabelFloat) {
      this._renderer.addClass(this._elementRef.nativeElement, 'media-source-selector-floating');
    }
    this._renderer.setAttribute(this._elementRef.nativeElement, 'id', this.id);

    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(({ sourceId, enabled }) => {
      if (!sourceId) {
        sourceId = this.form.getRawValue().sourceId;
      }

      this.onChange({
        ...this.sources.find(source => {
          return source.id === sourceId;
        }),
        enabled
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.sourceKind) {
      this.permissionWarningMessage = this.mediaSourceKindHelper.permissionWarningMessage;

      if (this.sourceKind === MediaSourceKind.AUDIO_OUTPUT) {
        this.noSourceTitleMessage = 'DEVICE_SOUND';
        this.noSourcesMessage = 'DEVICE_SOUND';
      } else {
        this.noSourceTitleMessage = this.isDefaultEnabled ? 'DEFAULT_DEVICE' : 'NO_DEVICES';
        this.noSourcesMessage = this.isDefaultEnabled ? 'DEFAULT_DEVICE' : 'NO_DEVICES';
      }
    }

    if (!!changes.enabled) {
      this.icon = this.mediaSourceKindHelper.icon(this.enabled);
      this.toggleTooltip = this.mediaSourceKindHelper.toggleTooltip(!this.enabled);

      this.updateValue({
        enabled: this.enabled
      });
    }

    if (!!changes.sources) {
      this.hasSourcesWarning = this.sources.some(source => {
        return !!source.warning;
      });
      if (changes.sources.currentValue.length === 1) {
        this.updateValue({ sourceId: changes.sources.currentValue[0].id });
      }

      const newState = changes.sources.currentValue.length > 1;
      if (this.form.controls.sourceId.enabled !== newState) {
        if (newState) {
          this.form.controls.sourceId.enable();
        } else {
          this.form.controls.sourceId.disable();
        }
      }
    }

    if (!!changes.hasPermission) {
      if (this.hasPermission) {
        this.form.enable();
      } else {
        this.form.disable();
      }
    }
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onFocusIn(_: FocusEvent): void {
    if (this.focused) {
      return;
    }

    this.focused = true;
    this.stateChanges.next();
  }

  onFocusOut(event: FocusEvent): void {
    if (this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      return;
    }

    this.touched = true;
    this.focused = false;
    this.onTouched();
    this.stateChanges.next();
  }

  setDescribedByIds(ids: string[]): void {
    const controlElement = this._elementRef.nativeElement.querySelector('.media-source-selector-container')!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(): void {
    if (this.form.controls.sourceId.valid) {
      this._focusMonitor.focusVia(this.sourceSelector, 'program');
    }
  }

  updateValue({ sourceId, enabled }: { sourceId?: string; enabled?: boolean }): void {
    const value = this.form.getRawValue();
    this.form.setValue({
      sourceId: sourceId ?? value.sourceId,
      enabled: enabled ?? value.enabled
    });
    this.stateChanges.next();
  }
}
