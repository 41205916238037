import { Action, createReducer, on } from '@ngrx/store';
import * as MessageActions from './message.actions';
import * as MessageState from './message.state';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { concatMessages } from '@breez/modules/chat';

const reducer = createReducer(
  MessageState.initialState,

  on(MessageActions.loadMessagesSuccess, (state, action) => {
    let { messages } = action;
    messages = messages.map(message => {
      return message.deliveredUserIds?.length === 0 && isTruthy(message.sender.userId)
        ? { ...message, deliveredUserIds: [message.sender.userId] }
        : message;
    });
    return MessageState.adapter.upsertMany(messages, state);
  }),

  on(MessageActions.sendMessage, (state, action) => {
    return MessageState.adapter.upsertOne(action.message, state);
  }),
  on(MessageActions.sendMessageSuccess, (state, action) => {
    let newState;
    if (isTruthy(action.tempId) && action.tempId !== action.message.id) {
      newState = MessageState.adapter.updateOne({ id: action.tempId, changes: { id: action.message.id } }, state);
    }
    return MessageState.adapter.upsertOne(action.message, newState ?? state);
  }),

  on(MessageActions.addMessageToState, (state, { messageId, message }) => {
    const oldMessage = state.entities[messageId];
    if (isTruthy(oldMessage)) {
      const newMessage = concatMessages(oldMessage, message);
      return MessageState.adapter.upsertOne(newMessage, MessageState.adapter.removeOne(messageId, state));
    } else {
      return MessageState.adapter.upsertOne(message, MessageState.adapter.removeOne(messageId, state));
    }
  }),

  on(MessageActions.removeMessageFromState, (state, { message }) => {
    const messageId = message.id;
    return isTruthy(messageId) ? MessageState.adapter.removeOne(messageId, state) : state;
  }),

  on(MessageActions.updateMessageInState, (state, { messageId, partialMessage }) => {
    const oldMessage = state.entities[messageId];
    if (isTruthy(oldMessage)) {
      const message = concatMessages(oldMessage, partialMessage);
      return MessageState.adapter.upsertOne(message, MessageState.adapter.removeOne(messageId, state));
    }
    return state;
  })
);

export function messageReducer(state: MessageState.State | undefined, action: Action): MessageState.State {
  return reducer(state, action);
}
