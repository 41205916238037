import { TypedAction } from '@ngrx/store/src/models';
import { EMPTY_ID } from '@breez/models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ExecutionResultHelper } from '@breez/helpers';
import { ExecutionModel } from '@breez/models/+state';

export const getFeatureState = createFeatureSelector<ExecutionModel>('execution');
export const getState = createSelector(getFeatureState, state => {
  return state;
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const execution = (action: TypedAction<string>, objectId: string = EMPTY_ID) => {
  return createSelector(getState, state => {
    return ExecutionResultHelper.executionSelector(objectId, action)(state);
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const executionProcessing = (action: TypedAction<string>, objectId: string = EMPTY_ID) => {
  return createSelector(execution(action, objectId), ExecutionResultHelper.executionSelectorProcessing);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const executionResult = (action: TypedAction<string>, objectId: string = EMPTY_ID) => {
  return createSelector(execution(action, objectId), ExecutionResultHelper.executionSelectorResult);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const executionError = (action: TypedAction<string>, objectId: string = EMPTY_ID) => {
  return createSelector(execution(action, objectId), ExecutionResultHelper.executionSelectorError);
};
