import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as RangeState from '@breez/modules/chat/+state/viewRange/viewRange.state';
import * as RangeActions from '@breez/modules/chat/+state/viewRange/viewRange.actions';
import { filter, map } from 'rxjs';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import * as MessageSelectors from '@breez/modules/chat/+state/message/message.selectors';

@Injectable()
export class RangeEffects {
  // @ts-ignore
  updateRange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RangeActions.updateRange),
      filter(action => {
        return isTruthy(action.range.anchorIndex);
      }),
      concatLatestFrom(action => {
        return this.store.select(MessageSelectors.getMessages(action.range.chatId));
      }),
      map(([action, messages]) => {
        const anchor = messages.slice(action.range.anchorIndex - 1, action.range.anchorIndex)[0];
        return RangeActions.updateRange({
          range: {
            chatId: action.range.chatId,
            anchor,
            count: action.range.count
          }
        });
      })
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store<RangeState.State>
  ) {}
}
