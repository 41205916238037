<ng-container *ngIf="message$ | async as message">
  <base target="_blank" />
  <h4 class="sender-name">{{ (message | messageAvatarEntity)?.name }}</h4>
  <div
    [style.touch-action]="'inherit'"
    [style.user-select]="'text'"
    (contextmenu)="openContextMenu($event)"
    (press)="startContextMenuTimer($event)"
    (pressup)="clearContextMenuTimer()"
    [class.system-view]="message | messageIsSystem"
    class="container"
    [ngStyle]="{ 'margin-bottom': message?.meta?.reaction ? '6px' : '0' }"
  >
    <ng-template #chatAvatar let-avatar="avatar">
      <vks-avatar-smart *ngIf="!isOut && avatar && avatar.name !== ''" [entity]="avatar" [cardByHover]="true" size="32">
      </vks-avatar-smart>
    </ng-template>
    <div *ngIf="!isOut" class="sender-avatar">
      <ng-container *ngTemplateOutlet="chatAvatar; context: { avatar: message | messageAvatarEntity }"></ng-container>
    </div>

    <div
      [class.highlight]="highlightContainer$ | async"
      class="message-wrapper"
      style="position: relative"
      (dblclick)="replyMessageByDblclick.emit()"
    >
      <ng-container *ngIf="message?.meta?.reaction">
        <span
          (contextmenu)="openContextMenu($event)"
          [matTooltip]="getReactionTooltip(reaction) | async"
          [style.left.px]="index * 15"
          style="cursor: pointer; position: absolute; bottom: -10px;z-index: 1;"
          *ngFor="let reaction of message?.meta?.reaction; let index = index"
          >{{ reaction?.reaction }}</span
        >
      </ng-container>

      <div class="content" (dblclick)="selectText($event)">
        <vks-chat-message-view-nested
          *ngIf="message.replyMessageId"
          [class.out]="isOut"
          [message]="message | replyMessage | async"
          (click)="scrollToRepliedMessage()"
        ></vks-chat-message-view-nested>
        <p
          *ngIf="message.body !== null && message.body !== undefined && !message.meta?.forward"
          #messageBody
          [class.disable-select]="menuOpened"
          [title]="message.sentDate | humanizeDate: 'time-and-date' | async"
          class="message"
        ></p>
        <vks-forward-messages-preview
          *ngIf="message.meta?.forward"
          [forMessageEdit]="false"
          [isOut]="isOut"
          [messageList]="[message]"
        ></vks-forward-messages-preview>
        <vks-file-list
          *ngIf="message.attachments?.length > 0 && !message.meta"
          [files]="message.attachments"
          [forChat]="!conferenceId"
          [isOut]="isOut"
        ></vks-file-list>
      </div>
      <div class="meta-info">
        <span>{{ message.sentDate | humanizeDate: 'time-only' | async }}</span>
        <vks-icon *ngIf="!!message.modifiedOn" [color]="isOut ? '#FFFFFF' : '#00000080'" icon="edit"></vks-icon>
        <span *ngIf="isOut && !(conferenceId || isDemoChat)">
          <ng-container *ngIf="executionProcessing$ | async">
            <vks-icon [color]="'#FFFFFF90'" [icon]="'control-time'"></vks-icon>
          </ng-container>
          <ng-container *ngIf="executionProcessing$ | async | not">
            <ng-container *ngIf="executionError$ | async; else executionCompleted">
              <vks-icon class="square16" [color]="'#EB4E35'" [icon]="'info-outlined'"></vks-icon>
            </ng-container>
            <ng-template #executionCompleted>
              <vks-icon
                [color]="isRead ? '#FFFFFF' : '#FFFFFF90'"
                [icon]="isDelivered ? 'message-check-delivered' : 'message-check-sent'"
              ></vks-icon>
            </ng-template>
          </ng-container>
        </span>
      </div>
    </div>

    <!-- TODO сделать из этого множество подкомпонентов (простое сообщение, ответ в опросе, вопрос спикеру и т.д.) -->
    <div *ngIf="allowForward" class="star-control">
      <button
        vks-button
        [matTooltip]="'FORWARD_TO_SPEAKERS_CHAT' | translate"
        (buttonClick)="forwardToSpeakersChat()"
        icon="reply-12"
        class="btn-basic btn-compressed"
      ></button>
    </div>
    <div *ngIf="isOut && (executionError$ | async)">
      <button
        vks-button
        [class.hide-text]="isMobileDevice$ | async"
        [label]="'RETRY_SEND' | translate"
        vks-debounce-click
        (debounceClick)="retrySend()"
        class="btn-anchor-warning retry-send-button"
        icon="arrow-round"
        iconClass="square18"
      ></button>
    </div>
    <div
      *ngIf="(!!conferenceId && isActiveConference) || !conferenceId"
      [matMenuTriggerFor]="menu"
      [style.left]="contextMenuPosition.x"
      [style.top]="contextMenuPosition.y"
      style="position: fixed; visibility: hidden"
    ></div>
    <mat-menu #menu="matMenu" (closed)="menuOpened = false" class="context-menu">
      <ng-container *ngIf="message?.participant?.isBlockable">
        <button
          *ngxPermissionsOnly="['OPERATOR::' + conferenceId, 'BLOCKING_CHAT::' + conferenceId]"
          (click)="issueWarning.emit()"
          mat-menu-item
        >
          <span>{{ 'ISSUE_A_WARNING' | translate }}</span>
        </button>
        <button
          *ngxPermissionsOnly="['OPERATOR::' + conferenceId, 'BLOCKING_CHAT::' + conferenceId]"
          (click)="blockChat.emit()"
          mat-menu-item
        >
          <span>{{ 'BLOCK_THIS_CHAT' | translate }}</span>
        </button>
        <button
          *ngxPermissionsOnly="['OPERATOR::' + conferenceId, 'BLOCKING_CHAT::' + conferenceId]"
          (click)="unblockChat.emit()"
          mat-menu-item
        >
          <span>{{ 'UNBLOCK_THIS_CHAT' | translate }}</span>
        </button>
        <mat-divider></mat-divider>
      </ng-container>
      <button *ngIf="message.body" (click)="copyMessageBodyToClipboard(message.body)" mat-menu-item>
        <vks-icon icon="copy" class="square20"></vks-icon> <span>{{ 'COPY_TEXT' | translate }}</span>
      </button>
      <button *ngIf="!isFavoriteChat" (click)="toggleEmojiPanel('edit')" mat-menu-item>
        <vks-icon icon="emoji" class="square20"></vks-icon>
        <span>{{ 'ADD_REACTION' | translate }}</span>
      </button>
      <button
        (click)="deleteReaction(message.id)"
        mat-menu-item
        *ngIf="message?.meta?.reaction && !isFavoriteChat && hasCurrentUserReaction(message) | async"
      >
        <vks-icon icon="emoji" class="square20"></vks-icon>
        <span>{{ 'REMOVE_REACTION' | translate }}</span>
      </button>
      <ng-container *ngIf="links$ | async as links">
        <ng-container *ngIf="links?.length > 1; else singleLInk">
          <button [matMenuTriggerFor]="linkMenu" mat-menu-item>
            <vks-icon icon="copy-link" class="square20"></vks-icon> <span>{{ 'COPY_LINKS' | translate }}</span>
          </button>
          <mat-menu #linkMenu="matMenu" [overlapTrigger]="false">
            <ng-container *ngFor="let link of links; let i = index">
              <button *ngIf="i < 5" [matTooltip]="link" (click)="copyMessageBodyToClipboard(link)" mat-menu-item>
                <vks-icon icon="copy-link" class="square20"></vks-icon>
                <span>{{ link | removeText: 'https://' : 'http://' : 'ftp://' : 'www.' | slice: 0 : 50 }}</span>
              </button>
            </ng-container>
          </mat-menu>
        </ng-container>
        <ng-template #singleLInk>
          <ng-container *ngIf="links$ | async as links">
            <button
              *ngIf="!!links[0]"
              [matTooltip]="links[0]"
              [matTooltipHideDelay]="0"
              (click)="copyMessageBodyToClipboard(links[0])"
              mat-menu-item
            >
              <vks-icon icon="copy-link" class="square20"></vks-icon> <span>{{ 'COPY_LINK' | translate }}</span>
            </button>
          </ng-container>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="message | messageIsSystem | not">
        <button *ngIf="!isFavoriteChat" (click)="pinMessage.emit(message)" mat-menu-item>
          <vks-icon [icon]="isPinned(message) ? 'disable-pin' : 'pin'" style="color: white" class="square20"></vks-icon>
          <span>{{ (isPinned(message) ? 'UNPIN_CHAT' : 'PIN_CHAT') | translate }}</span>
        </button>
        <button (click)="addToFavorites.emit(message)" mat-menu-item>
          <span>{{
            (isFavorite(message) ? 'REMOVE_MESSAGE_FROM_FAVORITES' : 'ADD_MESSAGE_TO_FAVORITES') | translate
          }}</span>
        </button>

        <button (click)="replyMessage.emit(message)" mat-menu-item>
          <vks-icon icon="reply" class="square20"></vks-icon>
          <span>{{ 'REPLY_MESSAGE' | translate }}</span>
        </button>
      </ng-container>
      <button (click)="openChatList(message)" mat-menu-item>
        <vks-icon icon="forward" class="square20"></vks-icon>
        <span>{{ 'FORWARD_MESSAGE' | translate }}</span>
      </button>
      <ng-container *ngIf="isOut && (message | messageIsSystem | not)">
        <mat-divider></mat-divider>
        <button
          *ngIf="(message | messageIsForwarded | not) && (message | messageIsSomeDelivered)"
          (click)="editMessage.emit()"
          mat-menu-item
        >
          <vks-icon icon="edit" class="square20"></vks-icon>
          <span>{{ 'EDIT_MESSAGE' | translate }}</span>
        </button>
        <button (click)="deleteMessage.emit()" mat-menu-item>
          <vks-icon icon="delete" class="square20"></vks-icon>
          <span>{{ 'DELETE' | translate }}</span>
        </button>
      </ng-container>
    </mat-menu>
  </div>
</ng-container>

<ng-container *ngIf="messageEmojiConfig && emojiMartTranslate$ | async as emojiTranslate">
  <emoji-mart
    outsideClick
    [backgroundImageFn]="backgroundImageFn"
    (outsideClick)="messageEmojiConfig = null"
    [showPreview]="false"
    [style]="{
      height: '356px',
      maxWidth: '602px',
      position: 'fixed',
      bottom: '200px',
      right: '20px',
      zIndex: '1'
    }"
    [set]="'apple'"
    (emojiClick)="addEmoji($event)"
    [i18n]="emojiTranslate"
    emoji="''"
  >
  </emoji-mart>
</ng-container>

