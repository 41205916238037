<ng-container *ngIf="formGroup">
  <ng-container *ngIf="isModal; else fastConferenceEditTemplate">
    <div class="header" mat-dialog-title>
      <span class="title no-user-select">{{ data.title | translate }}</span>
      <vks-icon
        [matTooltip]="'CLOSE' | translate"
        class="square14"
        color="#9daec3"
        (click)="closeModal()"
        icon="x-8"
      ></vks-icon>
    </div>
    <mat-dialog-content class="dialog-content">
      <div class="controls-container">
        <vks-form-renderer [form]="formGroup" [schema]="patchedData$ | async"></vks-form-renderer>
      </div>
    </mat-dialog-content>
    <div [class.mobile]="isMobileDevice$ | async" class="action-buttons" mat-dialog-actions>
      <button
        vks-button
        [disabled]="formGroup.invalid || conferenceCreating"
        [label]="'START_CONFERENCE' | translate"
        (buttonClick)="createConference()"
        class="btn-primary"
      ></button>
      <button vks-button [label]="'CALL.CANCEL' | translate" (buttonClick)="closeModal()" class="btn-anchor"></button>
    </div>
  </ng-container>
</ng-container>

<ng-template #fastConferenceEditTemplate>
  <div class="container">
    <div class="controls-container">
      <vks-form-renderer [form]="formGroup" [schema]="patchedData$ | async"></vks-form-renderer>
      <mat-divider style="margin: 20px 0" class="section-divider"></mat-divider>
      <vks-form-renderer
        [form]="formGroup"
        [schema]="participantsScheme"
        (action)="onAction($event)"
      ></vks-form-renderer>
    </div>
    <mat-divider class="section-divider"></mat-divider>
    <div [class.mobile]="isMobileDevice$ | async" [class.right]="!isModal" class="action-buttons right">
      <button
        vks-button
        [disabled]="formGroup.invalid"
        [label]="'SAVE' | translate | uppercase"
        (buttonClick)="updateConference()"
        class="btn-primary"
      ></button>
    </div>
  </div>
</ng-template>
