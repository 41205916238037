import { Injectable } from '@angular/core';
import { ITemplate } from '@breez/models/template/template.model';
import { WebsocketEvents, WebsocketService } from '@breez/modules/websocket';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GetTemplateService {
  constructor(private readonly websocketService: WebsocketService) {}

  getTemplateById(templateId: string): Observable<ITemplate> {
    return this.websocketService.send(WebsocketEvents.RECEIVE.TEMPLATE.GET, {
      data: {
        conferencetemplateid: templateId
      }
    });
  }

  getTemplateByConferenceId(conferenceId: number): Observable<ITemplate> {
    return this.websocketService.send(WebsocketEvents.RECEIVE.TEMPLATE.GET, {
      data: {
        conferenceid: conferenceId
      }
    });
  }
}
