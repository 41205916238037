import { Expose, Type } from 'class-transformer';
import { Conference } from '@breez/models/conference/conference.model';
import { ConferenceStatusEventType } from './enums/conference-status-event-type.enum';

export class ConferenceStatusEvent {
  @Expose({ name: 'event' })
  type: ConferenceStatusEventType;

  @Expose({ name: 'data' })
  @Type(() => {
    return Conference;
  })
  data: Conference;
}
