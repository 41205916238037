import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { AuthService } from '@breez/modules/auth/services/auth.service';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { StateService } from '@breez/shared/services/state.service';
import { environment } from '@breez/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  isElectron = environment.type === 'electron';

  constructor(
    private router: Router,
    private authService: AuthService,
    private stateService: StateService
  ) {}

  canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UrlTree | boolean> {
    return this.checkAccess(state).pipe(
      tap(() => {
        return this.stateService.setExpandedGUI(true);
      })
    );
  }

  canActivateChild(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UrlTree | boolean> {
    return this.checkAccess(state);
  }

  checkAccess(state: RouterStateSnapshot): Observable<UrlTree | boolean> {
    this.authService.redirectUrl = state.url;

    return this.authService.currentUser$.pipe(
      catchError(() => {
        return of(null);
      }),
      map(user => {
        if (user) {
          return true;
          // return this.router.createUrlTree(urlTree);;
        }
        const urlTree = this.isElectron ? ['/', 'auth'] : ['/'];
        return this.router.createUrlTree(urlTree);
      })
    );
  }
}
