import { ObjectListResponse } from '@breez/models/shared/paging';
import { Expose, Type } from 'class-transformer';
import { ParticipationIntervalsInfo } from './participation-intervals-info.model';
import { EntityReference } from '@breez/models/shared/entity-reference.model';

export class ParticipationIntervalsInfoResponse extends ObjectListResponse<ParticipationIntervalsInfo> {
  @Expose()
  @Type(() => {
    return ParticipationIntervalsInfo;
  })
  data: ParticipationIntervalsInfo[];

  @Expose()
  @Type(() => {
    return EntityReference;
  })
  users: EntityReference[];

  duration: number;
}
