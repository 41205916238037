import { Injectable } from '@angular/core';
import { Credentials } from '@breez/shared/push-service/interface/push-service.interface';
import { Observable, skipWhile } from 'rxjs';
import { WebsocketEvents, WebsocketService } from '@breez/modules/websocket';
import { filter, map } from 'rxjs/operators';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { Account } from '@breez/shared/models/account.model';
import * as ModuleState from '@breez/+state/account/account.state';
import * as AccountSelectors from '@breez/+state/account/account.selectors';
import { Store } from '@ngrx/store';

@Injectable()
export class ServiceWorkerApiService {
  constructor(
    private wsService: WebsocketService,
    private store: Store<ModuleState.State>
  ) {}

  sendCredentials(credentials: Credentials): Observable<boolean | { error: string; code: number }> {
    return this.wsService.send(WebsocketEvents.PWA.SET_PUSH_SUBSCRIPTION, {
      data: {
        subscription: credentials
      }
    });
  }

  getVapidPublicKey(): Observable<string> {
    return this.store.select(AccountSelectors.getAccount()).pipe(
      skipWhile(event => {
        return !event;
      }),
      map((account: Account) => {
        return account.data.push.publicKey;
      }),
      filter(isTruthy)
    );
  }
}
