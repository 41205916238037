import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { UnreadMessagesCount } from '@breez/modules/chat';

interface State extends EntityState<UnreadMessagesCount> {}
const adapter: EntityAdapter<UnreadMessagesCount> = createEntityAdapter<UnreadMessagesCount>({
  // @ts-ignore
  selectId: (unreadMessages: UnreadMessagesCount) => {
    return unreadMessages.chatId;
  },
  sortComparer: (_, __) => {
    return 1;
  }
});

const initialState: State = adapter.getInitialState({});

export { State, adapter, initialState };
