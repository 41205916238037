import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatColumnDef, MatTable } from '@angular/material/table';
import { AuthService } from '@breez/modules/auth/services/auth.service';
import { ColumnType } from '@breez/shared/enums/column-type.enum';
import { TableCellClickEvent, TableColumn } from '@breez/shared/modules/ui-controls/models/table-column.interface';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConferencesService } from '@breez/shared/services/conferences.service';
import { Conference } from '@breez/models/conference/conference.model';
import { ConferenceTypeEnum } from '@breez/modules/conference/models/conference-types.enum';

@UntilDestroy()
@Component({
  selector: 'vks-table-columns',
  templateUrl: './table-columns.component.html',
  styleUrls: ['./table-columns.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableColumnsComponent<T> implements OnInit, OnDestroy, OnChanges {
  conferenceTypeEnum = ConferenceTypeEnum;

  allColumnTypes = ColumnType;
  @Input()
  column: TableColumn;

  @Input()
  isAdvancedView: boolean;

  @Input()
  showPinIcon: boolean;

  @Output()
  cellClick = new EventEmitter<TableCellClickEvent<T>>();

  @ViewChild(MatColumnDef, { static: true }) columnDef: MatColumnDef;

  pinIcon: string;
  isSticky: boolean;
  valuePath: string[];
  private readonly defaultMinWidth: string = '100px';
  private readonly activePinIcon = 'active-pin';
  private readonly inactivePinIcon = 'inactive-pin';
  currentUserId$: Observable<number> = this.authService.currentUser$.pipe(
    map(user => {
      return user ? user.id : null;
    })
  );

  disabledSorting = false;

  constructor(
    @Optional() public table: MatTable<T>,
    private ngZone: NgZone,
    public readonly conferencesService: ConferencesService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    if (this.table) {
      this.setData();

      this.ngZone.onMicrotaskEmpty
        .pipe(
          take(1),
          map(_ => {
            return this.table.updateStickyColumnStyles();
          }),
          untilDestroyed(this)
        )
        .subscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && this.showPinIcon && this.column.attributes) {
      this.isSticky =
        (this.column.attributes ? this.column.attributes.includes('pinned') : false) && this.isAdvancedView;
      this.pinIcon = this.isSticky ? this.activePinIcon : this.inactivePinIcon;
    }
    if (changes.column) {
      this.disabledSorting = this.column.attributes ? this.column.attributes.includes('disabledSorting') : false;
    }
  }

  ngOnDestroy(): void {
    if (this.table) {
      this.table.removeColumnDef(this.columnDef);
    }
  }

  clickOnCell(value: T): void {
    this.cellClick.emit({ data: value, columnName: this.column.columnName });
  }

  private setData(): void {
    this.table.addColumnDef(this.columnDef);
    this.columnDef.name = this.column.columnName;
    this.valuePath = this.column.valuePath ? this.column.valuePath.split(',') : [this.column.columnName];
  }

  getConferenceState(conference: Conference): number {
    if (conference.conferenceType === ConferenceTypeEnum.CONSTANT) {
      return Number.MAX_SAFE_INTEGER;
    }

    return conference.state;
  }
}
