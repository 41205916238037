import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { AppService } from '@breez/app.service';
import { WebrtcMessages, WebrtcService } from '@breez/modules/webrtc/webrtc.service';
import { StateService } from '@breez/shared/services/state.service';
import { EmitOnChange } from '@breez/shared/utilities/decorators/emit-on-change.decorator';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  from,
  fromEvent,
  interval,
  merge,
  Observable,
  of,
  ReplaySubject,
  Subject,
  throwError,
  timer,
  zip
} from 'rxjs';
import {
  bufferCount,
  catchError,
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  mergeMap,
  pluck,
  retry,
  sample,
  share,
  shareReplay,
  skip,
  startWith,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { UserMediaSource } from '@breez/models/webrtc/media-source.model';
import { MediaSourceKind } from '@breez/models/webrtc/media-source-kind.enum';
import { WebrtcDialogService } from '@breez/modules/webrtc/webrtc-dialog.service';
import { DeviceSourceType } from '@breez/models/webrtc/device-source-type.enum';

import { config, DEFAULT_WEBRTC_CONSTRAINTS } from '../../webrtc.config';
import { WebrtcConfig } from '@breez/models/user-select/webrtc-config.model';
import { MediaDevicesService } from '@breez/modules/webrtc/services/media-devices.service';
import { SettingsService } from '@breez/modules/settings/services/settings.service';
import { waitFor } from '@breez/shared/rxjs-operators/wait-for';
import { replayWhileSubs } from '@breez/shared/rxjs-operators';
import { serialExpand } from '@breez/shared/rxjs-operators/serialExpand';
import { WebrtcStatsEntry } from '@breez/modules/webrtc/models/webrtc-stats-entry.model';
import { LocalStorage } from '@breez/shared/modules/storage/interfaces/local-storage.interface';
import { LoggerService } from '@breez/shared/services/logger.service';
import { isNull } from 'util';
import { DOCUMENT } from '@angular/common';
import { ConferenceParticipant } from '@breez/models';
import { MediaEffectsService } from '@breez/modules/webrtc/services/media-effects.service';
import { WEBRTC_MEDIA_EFFECTS } from '@breez/modules/webrtc/call-media-effects-service.provider';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const AVERAGE_BITRATE_TIME_WINDOW = localStorage => {
  return parseInt(localStorage.getItem('vks-average-bitrate-time-window')) || 5;
};
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const DOWN_BITRATE_SIGN = localStorage => {
  return parseFloat(localStorage.getItem('vks-up-bitrate-sign')) || 0.1;
};
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const MAX_HARDWARE_BITRATE = localStorage => {
  return parseInt(localStorage.getItem('vks-max-hardware-bitrate')) || 25;
};
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const MAX_SCREENCAST_BITRATE = localStorage => {
  return parseInt(localStorage.getItem('vks-max-screencast-bitrate')) || 25;
};
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const MAX_ICE_NEGOTIATION_ERROR = localStorage => {
  return parseInt(localStorage.getItem('vks-max-ice-negotiation-error')) || 0;
};
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const MAX_RTP_CRITICAL_ERROR = localStorage => {
  return parseInt(localStorage.getItem('vks-max_rtp_critical_error')) || 1;
};
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const MAX_RTP_CRITICAL_ERROR_TIMER = localStorage => {
  return parseInt(localStorage.getItem('vks-max_rtp_critical_error_timer')) || 20;
};

export const DEFAULT_CLIENT_RESOLUTIONS = [
  { width: 160, height: 90 },
  { width: 285, height: 120 },
  { width: 320, height: 180 },
  { width: 384, height: 216 },
  { width: 427, height: 240 },
  { width: 480, height: 270 },
  { width: 640, height: 360 },
  { width: 720, height: 405 },
  { width: 854, height: 480 },
  { width: 960, height: 540 },
  { width: 1280, height: 720 },
  { width: 1920, height: 1080 }
];

export const DEFAULT_CONFERENCE_RESOLUTIONS = [
  { kbit: 512, height: 360 },
  { kbit: 720, height: 480 },
  { kbit: 1024, height: 540 },
  { kbit: 1536, height: 720 },
  { kbit: 2280, height: 1080 }
];

@UntilDestroy()
@Component({
  selector: 'vks-webrtc-client',
  templateUrl: './webrtc-client.component.html',
  styleUrls: ['./webrtc-client.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [WebrtcService, WebrtcDialogService]
})
export class WebrtcClientComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @ViewChild('videoStream', { static: true }) videoStream: ElementRef<HTMLVideoElement>;

  @Input() localConstraints: { video: boolean; audio: boolean; screen: boolean } = {
    video: false,
    audio: false,
    screen: false
  };

  @Input() callId: number;
  @Input() isConference: boolean;
  @Input() isOfferCreator: boolean;
  @Input() videoSize: { width: number; height: number };
  @Input() frameProportion: { widthProportion: number; heightProportion: number };
  @Input() readyToEnter: boolean;
  @Input() conferenceParticipant: ConferenceParticipant;
  @Input() isDemo: boolean;
  @Input() isConferenceWaiting: boolean;
  @Input() disableBitrateTuning: boolean;
  @Input() startingDate: Date;

  @EmitOnChange('disableBitrateTuning')
  disableBitrateTuning$ = new BehaviorSubject<boolean>(false);

  @EmitOnChange('startingDate')
  startingDate$ = new ReplaySubject<Date>(1);

  @EmitOnChange('isConferenceWaiting')
  isConferenceWaiting$ = new BehaviorSubject<boolean>(true);

  @EmitOnChange('readyToEnter')
  readyToEnter$ = new ReplaySubject<boolean>(1);

  @EmitOnChange('conferenceParticipant')
  conferenceParticipant$ = new ReplaySubject<ConferenceParticipant>(1);

  @EmitOnChange('frameProportion')
  frameProportion$ = new ReplaySubject<{ widthProportion: number; heightProportion: number }>(1);

  @EmitOnChange('videoSize')
  videoSizeSubject$ = new ReplaySubject<{ width: number; height: number }>(1);

  @EmitOnChange('isOfferCreator')
  isOfferCreator$ = new ReplaySubject<boolean>(1);

  @EmitOnChange('isConference')
  isConference$ = new ReplaySubject<boolean>(1);

  @EmitOnChange('callId', { onlyTruthy: true })
  callId$ = new ReplaySubject<number>(1);

  @EmitOnChange('localConstraints')
  localConstraints$ = new ReplaySubject<{ video: boolean; audio: boolean; screen: boolean }>();

  videoSize$ = this.videoSizeSubject$.pipe(filter(isTruthy));
  readyToEnterTrigger$: Observable<void> = this.readyToEnter$.pipe(
    distinctUntilChanged(),
    filter(ready => {
      return ready;
    }),

    mapTo(undefined)
  );

  @Output() disableVideo = new EventEmitter<void>();
  @Output() reconnectToConference = new EventEmitter<void>();
  @Output() isPlaying = new EventEmitter<boolean>();
  @Output() videoResolved = new EventEmitter<boolean>();
  @Output() pipEnabled = new EventEmitter<boolean>();
  @Output() updateConstraints = new EventEmitter<{ video: boolean; audio: boolean; screen: boolean }>();
  @Output() updateConstraintsScreen = new EventEmitter<{ video: boolean; audio: boolean; screen: boolean }>();
  @Output() updateConferenceParticipant = new EventEmitter<{ video: boolean; audio: boolean; screen: boolean }>();

  @Output() readyToOverlay = new EventEmitter<boolean>();
  @Output() componentReload = new EventEmitter<boolean>();
  @Output() clickBox = new EventEmitter<number>();
  videoBitrateLimit = parseInt(this.localStorage.getItem('video-bitrate-limit')) || 2400;
  videoBitrate = parseInt(this.localStorage.getItem('video-bitrate')) || null;
  audioBitrateLimit = parseInt(this.localStorage.getItem('audio-bitrate-limit')) || 128;
  rtcPeerConnection$ = new ReplaySubject<RTCPeerConnection>(1);
  webrtcConnectionError$ = new BehaviorSubject<{
    ice_negotiation_error: number;
    rtp_critical_error: number;
  }>({
    ice_negotiation_error: 0,
    rtp_critical_error: 0
  });

  iceNegotiationErrorCounter$: Observable<number> = this.webrtcConnectionError$.pipe(
    pluck(WebrtcMessages.ICE_NEGOTIATION_ERROR),
    distinctUntilChanged()
  );

  iceNegotiationError$: Observable<boolean> = this.iceNegotiationErrorCounter$.pipe(
    map(count => {
      return count > MAX_ICE_NEGOTIATION_ERROR(this.localStorage);
    }),
    startWith(false),
    distinctUntilChanged()
  );

  rtpCriticalError$Counter$: Observable<number> = this.webrtcConnectionError$.pipe(
    pluck(WebrtcMessages.RTP_CRITICAL_ERROR),
    distinctUntilChanged()
  );

  rtpCriticalErrorAlert$: Observable<boolean> = this.rtpCriticalError$Counter$.pipe(
    switchMap(count => {
      if (count === MAX_RTP_CRITICAL_ERROR(this.localStorage)) {
        return timer(MAX_RTP_CRITICAL_ERROR_TIMER(this.localStorage) * 1_100).pipe(
          take(1),
          mapTo(false),
          tap(() => {
            this.rtpCriticalError$Counter$.pipe(take(1)).subscribe(rtpCriticalErrorCounter => {
              if (count === rtpCriticalErrorCounter) {
                this.skipRtpCriticalError();
              }
            });
          }),
          startWith(true)
        );
      } else {
        return of(false);
      }
    }),
    startWith(false),
    distinctUntilChanged()
  );

  rtpCriticalError$: Observable<boolean> = this.rtpCriticalError$Counter$.pipe(
    map(count => {
      return count > MAX_RTP_CRITICAL_ERROR(this.localStorage);
    }),
    startWith(false),
    tap(isError => {
      if (isError) {
        this.disableVideo.emit();
      }
    }),
    distinctUntilChanged()
  );

  displayStats = this.localStorage.getItem('display-stats') === 'true';

  wasOffline$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isOnline$: Observable<boolean> = this.webrtcService.isOnline$;
  isOffline$: Observable<boolean> = this.isOnline$.pipe(
    map(online => {
      const offline = !online;
      if (offline) {
        this.wasOffline$.next(true);
        of(null)
          .pipe(
            take(1),
            waitFor(() => {
              return this.isOnline$.pipe(
                filter(isOnline => {
                  return !!isOnline;
                }),
                delay(10000)
              );
            })
          )
          .subscribe(() => {
            this.wasOffline$.next(false);
          });
      }
      return offline;
    }),
    shareReplay()
  );

  connectedTrigger$: Observable<void> = this.isOnline$.pipe(
    startWith(true),
    distinctUntilChanged(),
    filter(online => {
      return online;
    }),
    mapTo(undefined)
  );

  rtcConnectionStats$ = this.rtcPeerConnection$.pipe(
    filter(connection => {
      return typeof connection.getStats === 'function';
    }),
    switchMap(connection => {
      return interval(1000).pipe(
        switchMap(() => {
          return from(connection.getStats(null));
        })
      );
    }),
    map(stats => {
      const statsObj = { inVideo: 0, inAudio: 0, outVideo: 0, outAudio: 0 };
      stats.forEach(report => {
        if (report.type === 'inbound-rtp' && report.mediaType === 'video') {
          statsObj.inVideo = report.bytesReceived;
        }
        if (report.type === 'outbound-rtp' && report.mediaType === 'video') {
          statsObj.outVideo = report.bytesSent;
        }
        if (report.type === 'inbound-rtp' && report.mediaType === 'audio') {
          statsObj.inAudio = report.bytesReceived;
        }
        if (report.type === 'outbound-rtp' && report.mediaType === 'audio') {
          statsObj.outAudio = report.bytesSent;
        }
      });
      return statsObj;
    })
  );

  remoteVideoStats$: Observable<any> = this.callId$.pipe(
    switchMap(callId => {
      return this.webrtcService.remoteWebrtcStats(callId);
    }),
    replayWhileSubs()
  );

  localVideoStatsSender$: Observable<void> = this.rtcPeerConnection$.pipe(
    filter(connection => {
      return typeof connection.getStats === 'function';
    }),
    switchMap(connection => {
      return interval(3000).pipe(
        switchMap(() => {
          return from(connection.getStats(null));
        }),
        sample(this.remoteVideoStats$)
      );
    }),
    map(stats => {
      const reports = Array.from<any>((<any>stats).values());
      const inboundVideoReport = reports.find(report => {
        return report.kind === 'video' && report.type === 'inbound-rtp';
      });
      const inboundVideoRtpReport = reports.find(report => {
        return report.kind === 'video' && report.type === 'remote-inbound-rtp';
      });

      if (!(inboundVideoReport && inboundVideoRtpReport)) {
        return null;
      }

      return new WebrtcStatsEntry({
        packetsReceived: inboundVideoReport.packetsReceived,
        packetsLost: inboundVideoReport.packetsLost,
        jitter: inboundVideoRtpReport.jitter
      });
    }),
    filter(isTruthy),
    withLatestFrom(this.callId$),
    switchMap(([entry, callId]) => {
      return this.webrtcService.sendWebrtcStats(entry, callId, true);
    })
  );

  averageSentBitrate$: Observable<number> = this.rtcPeerConnection$.pipe(
    filter(connection => {
      return typeof connection.getStats === 'function';
    }),
    switchMap(connection => {
      return interval(1000).pipe(
        switchMap(() => {
          return from(connection.getStats(null));
        })
      );
    }),
    map(stats => {
      let sumTotalBitrate = 0;
      stats.forEach(report => {
        if (report.type === 'inbound-rtp' && ['video', undefined].includes(report.mediaType)) {
          sumTotalBitrate += report?.bytesReceived * 8;
        }
      });
      return sumTotalBitrate;
    }),
    map(bitrate => {
      return { timestamp: new Date()?.getTime(), bitrate };
    }),
    bufferCount(2, 1),
    map(([previous, current]) => {
      const timeDiff = (current.timestamp - previous.timestamp) / 1000;
      return (current.bitrate - previous.bitrate) / timeDiff;
    }),
    bufferCount(AVERAGE_BITRATE_TIME_WINDOW(this.localStorage), 1),
    map(sample_ => {
      return sample_.length > 0
        ? sample_.reduce((acc, entry) => {
            return acc + entry;
          }, 0) / sample_.length
        : 0;
    }),
    map(bitrate => {
      return Math.round(bitrate);
    })
  );

  averageRecievedBitrate$: Observable<number> = this.rtcPeerConnection$.pipe(
    filter(connection => {
      return typeof connection.getStats === 'function';
    }),
    switchMap(connection => {
      return interval(1000).pipe(
        switchMap(() => {
          return from(connection.getStats(null));
        })
      );
    }),
    map(stats => {
      let sumTotalBitrate = 0;
      stats.forEach(report => {
        if (report.type === 'inbound-rtp' && ['video', undefined].includes(report.mediaType)) {
          sumTotalBitrate += report.bytesReceived * 8;
        }
      });
      return sumTotalBitrate;
    }),
    map(bitrate => {
      return { timestamp: new Date()?.getTime(), bitrate };
    }),
    bufferCount(2, 1),
    map(([previous, current]) => {
      const timeDiff = (current.timestamp - previous.timestamp) / 1000;
      return (current.bitrate - previous.bitrate) / timeDiff;
    }),
    bufferCount(AVERAGE_BITRATE_TIME_WINDOW(this.localStorage), 1),
    map(sample_ => {
      return sample_.length > 0
        ? sample_.reduce((acc, entry) => {
            return acc + entry;
          }, 0) / sample_.length
        : 0;
    }),
    map(bitrate => {
      return Math.round(bitrate);
    })
  );

  averageRemoteReceivedBitrate$: Observable<number> = EMPTY.pipe(
    switchMap(callId => {
      return this.webrtcService.remoteWebrtcStats(callId);
    }),
    map(stats => {
      return stats.bitrate;
    }),
    bufferCount(AVERAGE_BITRATE_TIME_WINDOW(this.localStorage), 1),
    map(sample_ => {
      return sample_.length > 0
        ? sample_.reduce((acc, entry) => {
            return acc + entry;
          }, 0) / sample_.length
        : 0;
    }),
    map(bitrate => {
      return Math.round(bitrate);
    })
  );

  remoteBitrateLostSign$: Observable<boolean> = this.averageRemoteReceivedBitrate$.pipe(
    withLatestFrom(this.averageSentBitrate$),
    map(([received, sent]) => {
      return (sent - received) / received;
    }),
    map(percentage => {
      return percentage > DOWN_BITRATE_SIGN(this.localStorage);
    }),
    distinctUntilChanged()
  );

  iceServers$: Observable<RTCIceServer[]> = this.webrtcService.fetchIceServers();
  remoteSessionDescriptionInit$: Observable<RTCSessionDescriptionInit> = this.callId$.pipe(
    switchMap(callId => {
      return this.webrtcService.remoteSessionDescription(callId);
    }),
    pluck('sdp'),
    share()
  );

  remoteSessionDescriptionSetter$: Observable<RTCSessionDescriptionInit> = this.remoteSessionDescriptionInit$.pipe(
    mergeMap(description => {
      return this.rtcPeerConnection$.pipe(
        take(1),
        switchMap(connection => {
          return this.setRemoteSessionDescription$(description, connection);
        })
      );
    }),
    share()
  );

  remoteIceCandidate$: Observable<RTCIceCandidate> = this.callId$.pipe(
    switchMap(callId => {
      return this.webrtcService.remoteIceCandidates(callId);
    }),
    share()
  );

  remoteWebrtcMessage$: Observable<string> = this.callId$.pipe(
    switchMap(callId => {
      return this.webrtcService.remoteWebrtcMessage(callId);
    }),
    tap(errorMessage => {
      const errorCounters = this.webrtcConnectionError$.value;
      let errorCounter = errorCounters[errorMessage];
      errorCounter = isTruthy(errorCounter) ? ++errorCounter : null;
      if (!isNull(errorCounter)) {
        errorCounters[errorMessage] = errorCounter;
      }
      this.webrtcConnectionError$.next(errorCounters);
    }),
    share()
  );

  remoteIceCandidateSetter$: Observable<void> = this.remoteIceCandidate$.pipe(
    mergeMap(candidate => {
      return this.rtcPeerConnection$.pipe(
        take(1),
        switchMap(connection => {
          return this.addRemoteIceCandidate$(candidate, connection);
        })
      );
    })
  );

  dynamicConstraints$ = new Subject<{ video: boolean; audio: boolean; screen: boolean }>();
  constraints$: Observable<{ video: boolean; audio: boolean; screen: boolean }> = merge(
    this.localConstraints$.pipe(
      distinctUntilChanged((prev, curr) => {
        return prev.video === curr.video && prev.audio === curr.audio && prev.screen === curr.screen;
      })
    ),
    this.dynamicConstraints$
  ).pipe(replayWhileSubs());

  mediaSources$: Observable<UserMediaSource[]> = this.readyToEnterTrigger$.pipe(
    switchMap(() => {
      return this.constraints$;
    }),
    map(constraints => {
      if (this.appService.isSafari) {
        return { video: true, audio: true, screen: false };
      }
      return constraints;
    }),
    switchMap(({ video, audio, screen }) => {
      return this.makeUserSourcesFromConstraints$(video, audio, screen);
    }),
    share()
  );

  isBlurEnable$: Observable<boolean> = this.mediaDevicesService.isBlurGlobalEnabled$.pipe(
    distinctUntilChanged(),
    replayWhileSubs()
  );

  mediaStream$: Observable<MediaStream> = this.conferenceParticipant$.pipe(
    switchMap(conferenceParticipant => {
      return serialExpand(
        this.mediaSources$,
        ({ mediaStream }, mediaSources) => {
          return this.mediaDevicesService
            .makeMediaStream(
              mediaStream,
              (isTruthy(conferenceParticipant) && conferenceParticipant.isSpeaker()) || this.isDemo ? mediaSources : [],
              true
            )
            .pipe(
              map(stream => {
                return { mediaStream: stream, sources: mediaSources };
              })
            );
        },
        { mediaStream: new MediaStream(), sources: [] as UserMediaSource[] }
      );
    }),
    skip(1),
    switchMap(({ mediaStream, sources }) => {
      return this.isBlurEnable$.pipe(
        debounceTime(250),
        switchMap(isBlurEnable => {
          return !mediaStream.active || this.isActiveScreenSource(mediaStream, sources) || !isBlurEnable
            ? of(mediaStream)
            : this.mediaEffectsService.getStream$(mediaStream, true);
        })
      );
    }),
    share(),
    untilDestroyed(this)
  );

  resolvedConstraints$: Observable<{ video: boolean; audio: boolean; screen: boolean }> = this.mediaStream$.pipe(
    withLatestFrom(this.mediaSources$),
    switchMap(([mediaStream, mediaSources]) => {
      let resolvedTracks = mediaStream.getTracks().filter(track => {
        return !(<any>track).isDummy && track.readyState === 'live';
      });
      return merge(
        ...resolvedTracks.map(track => {
          return fromEvent(track, 'ended');
        })
      ).pipe(
        startWith(<Event>null),
        withLatestFrom(this.constraints$, this.wasOffline$.asObservable().pipe(shareReplay())),
        map(([, localConstraints, wasOffline]) => {
          resolvedTracks = mediaStream.getTracks().filter(track => {
            return !(<any>track).isDummy && track.readyState === 'live';
          });
          const resolvedSources = resolvedTracks
            .map(track => {
              return this.webrtcService.trackToSource(track);
            })
            .concat(
              mediaSources.filter(source => {
                return source.resolveNull;
              })
            );

          const mediaToggles = {
            video:
              localConstraints.video &&
              resolvedSources.some(source => {
                return source.kind === MediaSourceKind.VIDEO_INPUT && source.sourceType === DeviceSourceType.HARDWARE;
              }),
            screen:
              localConstraints.screen &&
              resolvedSources.some(source => {
                return source.kind === MediaSourceKind.VIDEO_INPUT && source.sourceType === DeviceSourceType.SCREEN;
              }),
            audio:
              localConstraints.audio &&
              resolvedSources.some(source => {
                return source.kind === MediaSourceKind.AUDIO_INPUT;
              })
          };

          const successfulResolve =
            localConstraints.video === mediaToggles.video && localConstraints.audio === mediaToggles.audio;

          if (!successfulResolve || wasOffline) {
          }

          if (localConstraints.screen !== mediaToggles.screen) {
            this.updateConstraintsScreen.emit(mediaToggles);
          }

          return mediaToggles;
        })
      );
    }),
    replayWhileSubs()
  );

  localStream$: Observable<MediaStream> = this.mediaStream$.pipe(
    map((mediaStream: MediaStream) => {
      const tracks: MediaStreamTrack[] = mediaStream.getTracks();
      if (
        !tracks.find(track => {
          return track.kind === 'video';
        })
      ) {
        const dummyVideoTrack = this.webrtcService.getDummyVideoTrack();
        mediaStream.addTrack(dummyVideoTrack);
      }

      if (
        !tracks.find(track => {
          return track.kind === 'audio';
        })
      ) {
        const dummyAudioTrack = this.webrtcService.getDummyAudioTrack();
        mediaStream.addTrack(dummyAudioTrack);
      }

      return mediaStream;
    }),
    replayWhileSubs()
  );

  remoteStream$: Observable<MediaStream> = this.rtcPeerConnection$.pipe(
    switchMap(connection => {
      return this.onRemoteMediaStreamEvent$(connection);
    }),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  localStreamSetter$: Observable<void> = combineLatest([this.rtcPeerConnection$, this.localStream$]).pipe(
    switchMap(([connection, stream]) => {
      return this.setLocalStreamToPeerConnection$(stream, connection);
    }),
    shareReplay(1)
  );

  localIceCandidate$: Observable<RTCIceCandidate> = this.rtcPeerConnection$.pipe(
    switchMap(connection => {
      return this.onLocalIceCandidateEvent$(connection);
    })
  );

  localIceCandidateSender$: Observable<void> = combineLatest([
    this.localIceCandidate$,
    zip(this.callId$, this.isConference$)
  ]).pipe(
    switchMap(([candidate, [callId, isConference]]) => {
      return this.webrtcService.sendIceCandidate(candidate, callId, isConference);
    })
  );

  answerCreator$: Observable<RTCSessionDescription> = this.remoteSessionDescriptionSetter$.pipe(
    filter(description => {
      return description.type === 'offer';
    }),
    waitFor(() => {
      return this.localStreamSetter$.pipe(take(1));
    }),
    withLatestFrom(this.rtcPeerConnection$),
    switchMap(([, connection]) => {
      return this.createAnswer$(connection);
    })
  );

  offerCreator$: Observable<RTCSessionDescription> = this.rtcPeerConnection$.pipe(
    waitFor(() => {
      return this.localStreamSetter$.pipe(take(1));
    }),
    switchMap(connection => {
      return this.createOffer$(connection);
    })
  );

  localSessionDescription$: Observable<RTCSessionDescription> = this.isOfferCreator$.pipe(
    switchMap(isOfferCreator => {
      return isOfferCreator ? this.offerCreator$ : this.answerCreator$;
    })
  );

  localSessionDescriptionSender$: Observable<void> = combineLatest([
    this.localSessionDescription$,
    zip(this.callId$, this.isConference$)
  ]).pipe(
    switchMap(([description, [callId, isConference]]) => {
      return this.webrtcService.sendSessionDescription(description, [], callId, isConference);
    })
  );

  isFullScreen$: Observable<boolean> = this.stateService.isFullscreen$;
  displayControl$: Observable<boolean> = this.isPlaying.pipe(
    map(playing => {
      return !playing;
    }),
    startWith(false),
    shareReplay(1)
  );

  isConnected$: Observable<boolean> = this.remoteStream$.pipe(map(isTruthy), startWith(false));
  displayRipples$: Observable<boolean> = combineLatest([
    this.isConnected$,
    this.displayControl$,
    this.iceNegotiationError$,
    this.isConferenceWaiting$,
    this.wasOffline$,
    this.averageRecievedBitrate$
  ]).pipe(
    map(
      ([
        isConnected,
        displayControls,
        iceNegotiationError,
        isConferenceWaiting,
        wasOffline,
        averageRecievedBitrate
      ]) => {
        // console.log({isConnected, displayControls, iceNegotiationError, isConferenceWaiting, wasOffline});
        return (
          (isConnected &&
            !displayControls &&
            !iceNegotiationError &&
            !isConferenceWaiting &&
            Math.abs(averageRecievedBitrate) < 5000) ||
          wasOffline
        );
      }
    ),
    startWith(false),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  waitingForConnection$: Observable<boolean> = combineLatest([
    this.isConnected$,
    this.isOffline$,
    this.isConferenceWaiting$.pipe(filter(isTruthy))
  ]).pipe(
    map(([isConnected, isOffline, isConferenceWaiting]) => {
      return !isConnected && !isOffline && !isConferenceWaiting;
    }),
    startWith(false)
  );

  activeTimeCount$: Observable<boolean> = this.startingDate$.pipe(
    switchMap(until => {
      const difference = until?.getTime() - new Date()?.getTime();
      if (difference <= 0) {
        return of(false);
      }

      return interval(difference).pipe(take(1), mapTo(false), startWith(true));
    })
  );

  defaultMediaOutputDevice$: Observable<UserMediaSource> = this.mediaDevicesService.availableSources$.pipe(
    map(sources => {
      return (
        sources.find(source => {
          return source.kind === MediaSourceKind.AUDIO_OUTPUT && source.isDefault;
        }) || null
      );
    }),
    take(1)
  );

  dynamicMediaOutputDevice$ = new Subject<UserMediaSource>();
  mediaOutputDevice$: Observable<UserMediaSource> = merge(
    this.defaultMediaOutputDevice$,
    this.dynamicMediaOutputDevice$
  ).pipe(replayWhileSubs());

  mediaOutputDeviceSetter$: Observable<void> = this.mediaOutputDevice$.pipe(
    switchMap(source => {
      return this.setMediaOutputDevice$(source);
    }),
    filter(isTruthy),
    catchError(error => {
      return of(this.logger.error(error));
    })
  );

  recalcResolutionTrigger$: ReplaySubject<void> = new ReplaySubject<void>();
  senderParamsSetter$: Observable<void> = combineLatest([
    this.rtcPeerConnection$,
    this.recalcResolutionTrigger$,
    this.settingsService.getValue('web', 'videoBitrate').pipe(
      pluck('value'),
      map(videoBitrateConfig => {
        if (!videoBitrateConfig) {
          return undefined;
        }
        try {
          return JSON.parse(videoBitrateConfig);
        } catch {
          return undefined;
        }
      })
    ),
    this.settingsService.getValue('web', 'videoBitrateLimit').pipe(
      pluck('value'),
      map(videoBitrateLimitConfig => {
        if (!videoBitrateLimitConfig) {
          return undefined;
        }
        try {
          return Number(videoBitrateLimitConfig);
        } catch {
          return undefined;
        }
      })
    ),
    this.resolvedConstraints$
  ]).pipe(
    waitFor(() => {
      return this.localStreamSetter$.pipe(take(1));
    }),
    debounceTime(3000),
    switchMap(([connection, _, conferenceResolutions, videoBitrateLimit]) => {
      return combineLatest([this.frameProportion$.pipe(debounceTime(800))]).pipe(
        switchMap(([frameProportion]) => {
          if (connection.connectionState === 'closed') {
            return of(undefined);
          }

          const videoSender = connection.getSenders().find(sender => {
            return sender.track && sender.track.kind === 'video';
          });
          const videoSource = this.mediaDevicesService.trackToSource(videoSender.track);
          let downscaleFactor = 1;
          let heightFactor = 1;
          let widthFactor = 1;
          let adaptiveResolution;

          const localVideoResolution = videoSender.track.getSettings();

          if (!(localVideoResolution.width && localVideoResolution.height)) {
            this.recalcResolutionTrigger$.next();
            return Promise.resolve();
          }
          const conferenceVideoResolution = this.videoSize;

          const availibaleResolution: { width: number; height: number }[] = DEFAULT_CLIENT_RESOLUTIONS.filter(
            resolution => {
              return (
                resolution.width <= conferenceVideoResolution.width &&
                resolution.height <= conferenceVideoResolution.height
              );
            }
          );

          const defaultWidth = conferenceVideoResolution.width * frameProportion.widthProportion;
          const defaultHeight = conferenceVideoResolution.height * frameProportion.heightProportion;
          const adaptiveResolutions =
            frameProportion.heightProportion >= frameProportion.widthProportion
              ? availibaleResolution.filter(resolution => {
                  return resolution.height <= defaultHeight;
                })
              : availibaleResolution.filter(resolution => {
                  return resolution.width <= defaultWidth;
                });

          adaptiveResolution =
            adaptiveResolutions.length > 0
              ? adaptiveResolutions[adaptiveResolutions.length - 1]
              : availibaleResolution[0];
          if (adaptiveResolution.height > localVideoResolution.height) {
            adaptiveResolution = localVideoResolution;
          }
          widthFactor = localVideoResolution.width / adaptiveResolution.width;
          heightFactor = localVideoResolution.height / adaptiveResolution.height;
          downscaleFactor = Math.max(heightFactor, widthFactor);
          downscaleFactor = downscaleFactor < 1 ? 1 : downscaleFactor;

          const adaptiveResolutionHeight = adaptiveResolution?.height;
          const bitrate =
            this.videoBitrate ??
            (conferenceResolutions ?? DEFAULT_CONFERENCE_RESOLUTIONS).find(resolution => {
              return resolution.height === conferenceVideoResolution.height;
            })?.kbit ??
            1024;
          const newVideoBitrate =
            Math.max(
              Math.min(
                (adaptiveResolutionHeight / conferenceVideoResolution.height) * bitrate,
                videoBitrateLimit ?? this.videoBitrateLimit
              ),
              64 // min bitrate hardcode
            ) * 1024;
          const videoParams = videoSender.getParameters();
          const framerate =
            videoSource.sourceType === DeviceSourceType.SCREEN
              ? MAX_SCREENCAST_BITRATE(this.localStorage)
              : MAX_HARDWARE_BITRATE(this.localStorage);

          if (Array.isArray(videoParams.encodings) && videoParams.encodings[0]) {
            const encoding = videoParams.encodings[0];
            encoding.scaleResolutionDownBy = downscaleFactor;
            encoding.maxBitrate = Math.ceil(newVideoBitrate);
            // @ts-ignore
            encoding.maxFramerate = framerate;
          }

          return Promise.all([
            videoSender.setParameters(videoParams).catch(error => {
              return this.logger.error(error);
            })
          ]).then(() => {
            return undefined;
          });
        })
      );
    })
  );

  availableSources$: Observable<UserMediaSource[]> = this.mediaDevicesService.availableSources$;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private mediaDevicesService: MediaDevicesService,
    private appService: AppService,
    private webrtcService: WebrtcService,
    @Inject(WEBRTC_MEDIA_EFFECTS) private mediaEffectsService: MediaEffectsService,
    private webrtcDialogService: WebrtcDialogService,
    private stateService: StateService,
    private settingsService: SettingsService,
    @Inject(config) private webrtcConfig: WebrtcConfig,
    @Inject(DEFAULT_WEBRTC_CONSTRAINTS) private webrtcConstraints: any,
    private logger: LoggerService,
    private localStorage: LocalStorage
  ) {
    this.mediaEffectsService.loading$.next(false);
  }

  get volume(): number {
    return this.videoStream.nativeElement.volume;
  }

  set volume(value: number) {
    this.videoStream.nativeElement.volume = value;
  }

  isActiveScreenSource(mediaStream: MediaStream, mediaSources: UserMediaSource[]): boolean {
    const resolvedTracks = mediaStream.getTracks().filter(track => {
      return !(<any>track).isDummy && track.readyState === 'live';
    });
    const resolvedSources = resolvedTracks
      .map(track => {
        return this.webrtcService.trackToSource(track);
      })
      .concat(
        mediaSources.filter(source => {
          return source.resolveNull;
        })
      );

    return resolvedSources.some(source => {
      return source.kind === MediaSourceKind.VIDEO_INPUT && source.sourceType === DeviceSourceType.SCREEN;
    });
  }

  ngOnInit(): void {
    this.stateService.isAppVisible$.pipe(untilDestroyed(this)).subscribe(async visible => {
      const pipVideo: HTMLVideoElement = (<any>document).pictureInPictureElement;

      if (!visible) {
        if (this.stateService.isAppFocused()) {
          return;
        }

        if (pipVideo) {
          return;
        }

        if (!this.videoStream || !this.videoStream.nativeElement.srcObject) {
          return;
        }

        const video = this.videoStream.nativeElement;

        if (!video.srcObject) {
          return;
        }

        await video.play();

        if (typeof (<any>video).requestPictureInPicture === 'function') {
          try {
            (<any>video)
              .requestPictureInPicture()
              .then(() => {
                return this.pipEnabled.emit(true);
              })
              .catch(() => {
                this.pipEnabled.emit(false);
              });
          } catch {
            this.pipEnabled.emit(false);
          }
        }
      } else {
        if (pipVideo) {
          (<any>document).exitPictureInPicture();
          this.pipEnabled.emit(false);
        }
      }
    });

    this.remoteStream$
      .pipe(
        switchMap(stream => {
          return this.setStreamToVideoTag$(stream);
        }),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.videoResolved.emit(true);
      });

    let extServers: RTCIceServer[];

    try {
      const serversPlain = this.localStorage.getItem('extIceServers');

      if (serversPlain) {
        const servers = JSON.parse(serversPlain);
        if (Array.isArray(servers)) {
          extServers = servers;
        }
      }
    } catch {}

    const peerConnectionConfig: RTCConfiguration = {
      bundlePolicy: <any>this.localStorage.getItem('bundlePolicy') || this.webrtcConfig.bundlePolicy,
      rtcpMuxPolicy: <any>this.localStorage.getItem('rtcpMuxPolicy') || this.webrtcConfig.rtcpMuxPolicy,
      iceCandidatePoolSize:
        parseInt(this.localStorage.getItem('iceCandidatePoolSize')) || this.webrtcConfig.iceCandidatePoolSize,
      iceTransportPolicy: <any>this.localStorage.getItem('iceTransportPolicy') || this.webrtcConfig.iceTransportPolicy,
      iceServers: extServers || this.webrtcConfig.iceServers
    };
    combineLatest([this.localStream$.pipe(take(1)), this.iceServers$.pipe(take(1))])
      .pipe(
        waitFor(() => {
          return this.connectedTrigger$;
        }),
        untilDestroyed(this)
      )
      .subscribe(([, iceServers]) => {
        if (iceServers) {
          peerConnectionConfig.iceServers = iceServers;
        }
        // @ts-ignore
        this.rtcPeerConnection$.next(new RTCPeerConnection(peerConnectionConfig, this.webrtcConstraints));
        this.recalcResolutionTrigger$.next();
      });

    this.rtcPeerConnection$.pipe(bufferCount(2, 1), untilDestroyed(this)).subscribe(([prevConnection]) => {
      return prevConnection.close();
    });

    this.resolvedConstraints$
      .pipe(
        filter(isTruthy),
        pluck('screen'),
        distinctUntilChanged(),
        skip(1),
        withLatestFrom(this.resolvedConstraints$),
        untilDestroyed(this)
      )
      .subscribe(([_, mediaToggles]) => {
        this.updateConstraintsScreen.emit(mediaToggles);
      });

    this.remoteIceCandidate$.pipe(untilDestroyed(this)).subscribe();
    this.remoteWebrtcMessage$.pipe(untilDestroyed(this)).subscribe();
    this.remoteSessionDescriptionInit$.pipe(untilDestroyed(this)).subscribe();
    this.localStreamSetter$.pipe(untilDestroyed(this)).subscribe();
    this.remoteIceCandidateSetter$.pipe(untilDestroyed(this)).subscribe();
    this.mediaOutputDeviceSetter$.pipe(untilDestroyed(this)).subscribe();
    this.localIceCandidateSender$.pipe(untilDestroyed(this)).subscribe();
    this.localSessionDescriptionSender$.pipe(untilDestroyed(this)).subscribe();
    this.remoteSessionDescriptionSetter$.pipe(untilDestroyed(this)).subscribe();
    this.senderParamsSetter$.pipe(untilDestroyed(this)).subscribe();
    // TODO #alekssakovsky когда-то это снова будет включено BREEZ-795: проверить блок кода в WebrtcClientComponent
    // this.sendBitrateFactor$.pipe(tap(console.log), untilDestroyed(this)).subscribe();
    this.localVideoStatsSender$.pipe(untilDestroyed(this)).subscribe().unsubscribe();
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnChanges(_: SimpleChanges): void {}

  exitPictureInPicture(): void {
    const exitPictureInPicture = (<any>document).exitPictureInPicture;
    if (isTruthy(exitPictureInPicture) && typeof exitPictureInPicture === 'function') {
      (<any>document).exitPictureInPicture();
      this.pipEnabled.emit(false);
    }
  }

  async enterPictureInPicture(): Promise<void> {
    const pipVideo: HTMLVideoElement = (<any>document).pictureInPictureElement;
    const video = this.videoStream.nativeElement;

    if (pipVideo === video) {
      this.exitPictureInPicture();
      return;
    }

    if (!video.srcObject) {
      return;
    }

    await video.play();

    if (typeof (<any>video).requestPictureInPicture === 'function') {
      try {
        (<any>video)
          .requestPictureInPicture()
          .then(() => {
            return this.pipEnabled.emit(true);
          })
          .catch(() => {
            this.pipEnabled.emit(false);
          });
      } catch {
        this.pipEnabled.emit(false);
      }
    }
  }

  ngAfterViewInit(): void {
    fromEvent(this.videoStream.nativeElement, 'leavepictureinpicture')
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        return this.pipEnabled.emit(false);
      });

    fromEvent(this.videoStream.nativeElement, 'play')
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        return this.isPlaying.emit(true);
      });

    fromEvent(this.videoStream.nativeElement, 'pause')
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        return this.isPlaying.emit(false);
      });
  }

  makeUserSourcesFromConstraints$(video: boolean, audio: boolean, screen: boolean): Observable<UserMediaSource[]> {
    if (!video && !audio && !screen) {
      return of([]);
    }
    this.readyToOverlay.emit(true);

    return this.wasOffline$.pipe(
      take(1),
      switchMap(wasOffline => {
        return combineLatest([this.mediaDevicesService.availableSources$, this.videoSize$]).pipe(
          take(1),
          waitFor(() => {
            return wasOffline
              ? this.wasOffline$.pipe(
                  filter(_wasOffline => {
                    return _wasOffline === false;
                  })
                )
              : of(null);
          }),
          map(([defaultMediaSources, videoSize]) => {
            let displayMediaSource = screen ? this.mediaDevicesService.getDisplayMediaSource() : null;

            let videoSource = video
              ? defaultMediaSources.find(source => {
                  return source.kind === MediaSourceKind.VIDEO_INPUT && source.isDefault;
                })
              : null;

            const audioSource = audio
              ? defaultMediaSources.find(source => {
                  return source.kind === MediaSourceKind.AUDIO_INPUT && source.isDefault;
                })
              : null;

            if (videoSource && videoSize) {
              videoSource = Object.assign({}, videoSource, <Partial<UserMediaSource>>{
                constraints: {
                  width: this.videoSize.width,
                  height: this.videoSize.height,
                  frameRate: { min: 10 }
                }
              });
            }

            if (displayMediaSource) {
              displayMediaSource = Object.assign({}, displayMediaSource, <Partial<UserMediaSource>>{
                constraints: {
                  frameRate: { min: 10 }
                }
              });
            }

            return [videoSource, audioSource, displayMediaSource].filter(isTruthy);
          })
        );
      })
    );
  }

  skipIceNegotiationError(): void {
    this.clearWebrtcMaxError(WebrtcMessages.ICE_NEGOTIATION_ERROR);
  }

  skipRtpCriticalError(): void {
    this.clearWebrtcMaxError(WebrtcMessages.RTP_CRITICAL_ERROR);
  }

  clearWebrtcMaxError(errorKey, value = 0): void {
    const errorsCounters = this.webrtcConnectionError$.value;
    if (!isTruthy(errorsCounters[errorKey])) {
      return;
    }
    errorsCounters[errorKey] = value;
    this.webrtcConnectionError$.next(errorsCounters);
  }

  setLocalStreamToPeerConnection$(stream: MediaStream, connection: RTCPeerConnection): Observable<void> {
    const currentSenders = connection.getSenders().filter(sender => {
      return sender.track;
    });

    const hasScreenTrack = stream.getTracks().some(track => {
      return (<any>track).displayMedia;
    });
    let potentialTracks = stream.getTracks();

    if (hasScreenTrack) {
      potentialTracks = potentialTracks.filter(track => {
        return track.kind !== 'video' || (<any>track).displayMedia;
      });
    }

    if (!stream || !(stream instanceof MediaStream)) {
      return from(Promise.resolve());
    }

    return from(
      Promise.all(
        potentialTracks.map(track => {
          const sameKindSender = currentSenders.find(sender => {
            return sender.track.kind === track.kind;
          });
          // if there is no same kind track then add it with self-resolving promise
          if (!sameKindSender) {
            connection.addTrack(track, stream);
            return Promise.resolve();
          }
          if (sameKindSender.track.id === track.id) {
            return Promise.resolve();
          }
          return sameKindSender.replaceTrack(track);
        })
      ).then(() => {
        return Promise.resolve();
      })
    ).pipe(mapTo(undefined));
  }

  setStreamToVideoTag$(stream: MediaStream): Observable<void> {
    if (!this.videoStream) {
      return of(null);
    }

    if (this.videoStream.nativeElement.srcObject !== stream) {
      this.videoStream.nativeElement.srcObject = stream;
    }

    return from(this.playVideo());
  }

  onRemoteMediaStreamEvent$(connection: RTCPeerConnection): Observable<MediaStream> {
    return new Observable(subscriber => {
      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
      connection.ontrack = track => {
        if (track.streams[0]) {
          return subscriber.next(track.streams[0]);
        }
        const tracks = (<RTCPeerConnection>track.target)
          .getReceivers()
          .map(receiver => {
            return receiver.track;
          })
          .filter(isTruthy);
        const mediaStream = new MediaStream(tracks);
        subscriber.next(mediaStream);
      };
    });
  }

  onLocalIceCandidateEvent$(connection: RTCPeerConnection): Observable<RTCIceCandidate> {
    return new Observable(subscriber => {
      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
      connection.onicecandidate = event => {
        if (!event.candidate) {
          return;
        }
        subscriber.next(event.candidate);
      };
    });
  }

  addRemoteIceCandidate$(ice: RTCIceCandidate, connection: RTCPeerConnection): Observable<void> {
    return of(ice).pipe(
      switchMap(newIce => {
        return from(connection.addIceCandidate(newIce));
      }),
      catchError(error => {
        return throwError(error);
      }),
      retry(5)
    );
  }

  createAnswer$(connection: RTCPeerConnection): Observable<RTCSessionDescription> {
    return from(
      connection
        .createAnswer()
        .then(descriptionInit => {
          return connection.setLocalDescription(descriptionInit);
        })
        .then(() => {
          return connection.localDescription;
        })
    );
  }

  setRemoteSessionDescription$(
    description: RTCSessionDescriptionInit,
    connection: RTCPeerConnection
  ): Observable<RTCSessionDescriptionInit> {
    return from(connection.setRemoteDescription(description)).pipe(mapTo(description));
  }

  ngOnDestroy(): void {
    this.rtcPeerConnection$.pipe(take(1)).subscribe(connection => {
      return connection.close();
    });
    this.localConstraints$.complete();
    this.dynamicConstraints$.complete();
    this.localStream$.pipe(take(1)).subscribe(stream => {
      return stream.getTracks().forEach(track => {
        return track.stop();
      });
    });
    this.mediaEffectsService.stop();
  }

  playVideo(): Promise<void> {
    return this.videoStream.nativeElement
      .play()
      .then(() => {
        this.isPlaying.emit(true);
      })
      .catch(() => {
        return this.isPlaying.emit(false);
      });
  }

  setMediaOutputDevice$(outputDevice: UserMediaSource): Observable<void> {
    if (this.videoStream === null || this.videoStream === undefined) {
      return of(null);
    }
    const videoElement = this.videoStream.nativeElement;
    if (typeof (<any>videoElement).setSinkId === 'function') {
      return from((<any>videoElement).setSinkId(outputDevice ? outputDevice.id : null)) as Observable<void>;
    }
    return throwError('No setSinkId function');
  }

  createOffer$(connection: RTCPeerConnection): Observable<RTCSessionDescription> {
    return from(
      connection
        .createOffer()
        .then(descriptionInit => {
          return connection.setLocalDescription(descriptionInit);
        })
        .then(() => {
          return connection.localDescription;
        })
    );
  }

  silentEnter(): Observable<{
    sources: UserMediaSource[];
    mediaToggles: { video: boolean; audio: boolean; screen: boolean; volume?: boolean };
  }> {
    return this.availableSources$.pipe(
      take(1),
      map(availableSources => {
        const sources =
          isTruthy(availableSources) && availableSources.length > 0
            ? availableSources.filter(source => {
                return source.isDefault;
              })
            : null;
        const video: boolean =
          sources.find(source => {
            return source.kind === MediaSourceKind.VIDEO_INPUT;
          })?.enabled ?? false;
        const audio: boolean =
          sources.find(source => {
            return source.kind === MediaSourceKind.AUDIO_INPUT;
          })?.enabled ?? false;
        const mediaToggles = {
          video,
          audio,
          screen: false
        };

        this.webrtcService.setDefaultMediaSourcesIds(sources, true);
        const audioOutput = sources.find(source => {
          return source.kind === MediaSourceKind.AUDIO_OUTPUT;
        });
        this.dynamicMediaOutputDevice$.next(audioOutput);
        this.dynamicConstraints$.next(mediaToggles);
        return { sources, mediaToggles };
      })
    );
  }

  openUserSettingsDialog(
    _: boolean,
    constraint: { audio: boolean; video: boolean; screen: boolean }
  ): Observable<{
    sources: UserMediaSource[];
    mediaToggles: { video: boolean; audio: boolean; screen: boolean; volume?: boolean };
    effectsSdkSetting: { blur: boolean };
  }> {
    const currentMedia: { audio: boolean; video: boolean; volume: boolean; screen: boolean } = {
      audio: constraint.audio,
      video: constraint.video,
      volume: this.volume !== 0,
      screen: constraint.screen
    };
    return this.webrtcDialogService.requestUserPreConferenceSettings(EMPTY, true, currentMedia).pipe(
      take(1),
      delay(this.appService.isAndroid ? 1200 : 0),
      tap(({ sources, mediaToggles }) => {
        this.webrtcService.setDefaultMediaSourcesIds(sources);
        const audioOutput = sources.find(source => {
          return source.kind === MediaSourceKind.AUDIO_OUTPUT;
        });
        this.dynamicMediaOutputDevice$.next(audioOutput);
        this.dynamicConstraints$.next(mediaToggles);
      })
    );
  }

  clickButtonBox(): void {
    this.callId$.pipe(take(1)).subscribe(callId => {
      return this.clickBox.emit(callId);
    });
  }

  reconnectWebRtc(): void {
    this.document.defaultView.location.reload();
  }
}
