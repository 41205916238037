import { UserStatus } from '@breez/modules/user/modules/models/user-status.enum';
import { Expose, Transform } from 'class-transformer';
import { Event } from '@breez/shared/models/event.model';

export class UserStatusEvent {
  @Expose({ name: 'key' })
  @Transform(({ value }) => {
    return Number(value);
  })
  userId: number;

  @Expose({ name: 'value' })
  status: UserStatus;
}

export class UserStatusSubscribeEvent extends Event<any> {
  @Expose({ name: 'data' })
  data: any;
}
