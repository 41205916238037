import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '@breez/shared/pipes/pipes.module';
import { UserTreeModule } from '@breez/modules/user/user-tree/user-tree.module';
import { UserSelectComponent } from '@breez/modules/user/user-select/user-select.component';
import { ControlsModule } from '@breez/modules/controls/controls.module';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { SharedModule } from '@breez/shared/shared.module';

@NgModule({
  declarations: [UserSelectComponent],
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    CommonModule,
    MatExpansionModule,
    UserTreeModule,
    PipesModule,
    ControlsModule,
    MatButtonToggleModule,
    MatTabsModule,
    SharedModule
  ],
  exports: [UserSelectComponent]
})
export class UserSelectModule {}
