import { Injectable } from '@angular/core';
import { User } from '@breez/models';
import { ViewParticipantRightModel } from '@breez/models/conference/view-participant-right.model';
import { Participant } from '@breez/models/shared/participant/participant.model';
import { AuthService } from '@breez/modules/auth/services/auth.service';
import { ConferenceRights } from '@breez/modules/permissions/ConferenceRights.enum';
import { WebsocketEvents, WebsocketService } from '@breez/modules/websocket';
import { replayWhileSubs } from '@breez/shared/rxjs-operators';
import { ParticipantsService } from '@breez/shared/services/participants.service';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';
import { ConferenceUserRole } from '@breez/models/conference-user-role.enum';
import { ObjectType } from '@breez/shared/enums/object-type.enum';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  permissions$: Observable<string[]> = this.authService.currentUser$.pipe(
    switchMap(user => {
      if (isTruthy(user)) {
        return this.ngxPermissionsService.permissions$.pipe(
          map(permissions => {
            return Object.keys(permissions).map(key => {
              return permissions[key].name;
            });
          }),
          replayWhileSubs()
        );
      } else {
        return of([]);
      }
    })
  );

  constructor(
    private websocketService: WebsocketService,
    private participantService: ParticipantsService,
    private ngxPermissionsService: NgxPermissionsService,
    private authService: AuthService
  ) {}

  subscribeConferenceRightsEvents(participant$: Observable<Participant>): Observable<ViewParticipantRightModel[]> {
    return participant$.pipe(
      filter(isTruthy),
      map(participant => {
        const { rights: rightsUpdates, object: conferenceReference } = participant;
        if (Array.isArray(rightsUpdates)) {
          const previousRights = Object.keys(this.ngxPermissionsService.getPermissions())
            .filter(permission => {
              return permission.includes(conferenceReference.id.toString());
            })
            .filter(permission => {
              return !permission.includes(ConferenceRights.OPERATOR);
            });

          const currentRights = rightsUpdates.map(right => {
            return `${right.key}::${conferenceReference.id.toString()}`;
          });

          previousRights
            .filter(right => {
              return !currentRights.includes(right);
            })
            .forEach(right => {
              return this.removeConferenceRight(right);
            });

          this.addConferenceRights(
            currentRights.filter(right => {
              return !previousRights.includes(right);
            })
          );
          return rightsUpdates;
        } else {
          return <ViewParticipantRightModel[]>[];
        }
      }),
      distinctUntilChanged((previous, current) => {
        return previous
          .map(right => {
            return right.key;
          })
          .isSame(
            current.map(right => {
              return right.key;
            })
          );
      })
    );
  }

  addConferenceRights(rights: string | string[]): void {
    const currentRights = Object.keys(this.ngxPermissionsService.getPermissions());
    if (rights instanceof Array) {
      rights.forEach(right => {
        if (
          !currentRights.find(currentRight => {
            return currentRight === right;
          })
        ) {
          this.ngxPermissionsService.addPermission(right);
        }
      });
      return;
    }
    if (
      !currentRights.find(currentRight => {
        return currentRight === rights;
      })
    ) {
      this.ngxPermissionsService.addPermission(rights);
    }
  }

  deleteConferenceRights(conferenceId: number): void {
    const permissions = this.ngxPermissionsService.getPermissions();
    Object.keys(permissions)
      .filter(permission => {
        return permission.includes(conferenceId.toString());
      })
      .map(permission => {
        return this.ngxPermissionsService.removePermission(permission);
      });
  }

  removeConferenceRight(right: ConferenceRights | string): void {
    const permission = this.ngxPermissionsService.getPermission(right);
    this.ngxPermissionsService.removePermission(permission.name);
  }

  hasConferenceRight(conferenceId: number, right: ConferenceRights | string): boolean {
    const permission = this.ngxPermissionsService.getPermission(`${right}::${conferenceId}`);
    return !!permission;
  }

  getConferenceParticipantRights(conferenceId: number, participantId: number): Observable<ViewParticipantRightModel[]> {
    return this.websocketService
      .send(WebsocketEvents.RECEIVE.CONFERENCE.USER.ROLES, {
        id: participantId,
        parent: {
          id: conferenceId,
          resource: ObjectType.CONFERENCE
        }
      })
      .pipe(
        catchError(() => {
          return of(false);
        })
      );
  }

  setConferenceParticipantRights(conferenceId: number, participant: Participant): Observable<boolean> {
    return this.websocketService
      .send(WebsocketEvents.SEND.CONFERENCE.USER.ROLES, {
        id: participant.participantReference.id,
        data: {
          ...participant.rights
        },
        parent: {
          id: conferenceId,
          resource: ObjectType.CONFERENCE
        }
      })
      .pipe(
        map(() => {
          return true;
        }),
        catchError(() => {
          return of(false);
        })
      );
  }

  canManagingUser(currentParticipant: Participant, targetParticipant: Participant, conferenceCreator: User): boolean {
    if (!currentParticipant || !targetParticipant || !conferenceCreator) {
      return false;
    }
    if (currentParticipant.compareCarrier(targetParticipant)) {
      return false;
    }

    const isTargetParticipantCreator = targetParticipant.participantReference.id === conferenceCreator.id;
    const isTargetParticipantOperator = targetParticipant.hasRole(ConferenceUserRole.OPERATOR);
    const isCurrentParticipantOperator = currentParticipant.hasRole(ConferenceUserRole.OPERATOR);
    const isCurrentParticipantSpeaker = currentParticipant.hasRole(ConferenceUserRole.SPEAKER);
    if (isCurrentParticipantOperator) {
      return !isTargetParticipantCreator && !isTargetParticipantOperator;
    }
    if (isCurrentParticipantSpeaker) {
      return !isTargetParticipantOperator && !isTargetParticipantCreator;
    }
  }

  // пока получаем permissions из AuthService
  loadPermissions(permissions: string[]): void {
    Object.keys(this.ngxPermissionsService.getPermissions())
      .filter(permission => {
        return !/::\d+/.test(permission);
      })
      .forEach(permission => {
        return this.removeConferenceRight(permission);
      });
    this.ngxPermissionsService.addPermission(permissions);
  }

  hasPermission(permission: string): boolean {
    return !!this.ngxPermissionsService.getPermission(permission);
  }

  hasPermissionAsync(permission: string): Observable<boolean> {
    return this.permissions$.pipe(
      take(1),
      map(() => {
        return this.hasPermission(permission);
      })
    );
  }
}
