import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DesktopCapturerSource } from 'electron';
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, from } from 'rxjs';
import { filter, shareReplay, switchMap } from 'rxjs/operators';

import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { fromControl } from '@breez/shared/rxjs-operators/from-control';

@UntilDestroy()
@Component({
  selector: 'vks-screenshare-picker',
  templateUrl: './screenshare-picker.component.html',
  styleUrls: ['./screenshare-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScreensharePickerComponent implements OnInit, AfterViewInit {
  @ViewChild('videoElement') videoElement: ElementRef<HTMLVideoElement>;

  form = new UntypedFormGroup({
    selectedWindow: new UntypedFormControl(null)
  });

  mediaStream$: Observable<MediaStream> = fromControl(this.selectorFormControl, 100).pipe(
    switchMap(deviceId => {
      const constraints = {
        audio: false,
        video: {
          mandatory: {
            chromeMediaSource: 'desktop',
            chromeMediaSourceId: deviceId
          }
        }
      };

      // @ts-ignore
      return <Observable<MediaStream>>from(navigator.mediaDevices.getUserMedia(constraints));
    }),
    shareReplay(1)
  );

  get selectorFormControl(): AbstractControl {
    return this.form.get('selectedWindow');
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public sources: DesktopCapturerSource[],
    private readonly dialogRef: MatDialogRef<ScreensharePickerComponent>
  ) {}

  ngOnInit(): void {
    this.selectorFormControl.patchValue(this.sources[0].id, { emitEvent: true });
  }

  ngAfterViewInit(): void {
    this.mediaStream$.pipe(filter(isTruthy), untilDestroyed(this)).subscribe((stream: MediaStream) => {
      this.videoElement.nativeElement.pause();
      this.videoElement.nativeElement.srcObject = stream;
      this.videoElement.nativeElement.load();
      this.videoElement.nativeElement.play();
    });
  }

  closeDialog(): void {
    this.dialogRef.close(null);
  }

  selectScreen(): void {
    this.dialogRef.close(this.selectorFormControl.value);
  }
}
