import { Injectable } from '@angular/core';
import { ConferenceChatEnum, ConferenceChatTitleEnum, EventsObserverEnum } from '../enums';
import { Locale } from '@breez/models/template/conference-template-info.model';
import { EventTypeEnum, User } from '@breez/models';
import { WebsocketEvents, WebsocketService } from '@breez/modules/websocket';
import { arrToClass, replayWhileSubs, toClass, toPlain } from '@breez/shared/rxjs-operators';
import { fixDateDeserialization } from '@breez/shared/utilities/fixDateDeserialization';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, EMPTY, from, Observable, of, Subject, throwError } from 'rxjs';
import {
  catchError,
  concatMap,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  mapTo,
  startWith,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { ObjectType } from '@breez/shared/enums/object-type.enum';
import { BlockChatRequest, IssueWarningRequest } from '../interfaces';
import {
  Chat,
  ChatList,
  ChatParticipant,
  ChatParticipantEvent,
  FoundChat,
  IncomingMessagesSubscribeEvent,
  Message,
  MessageEvent,
  MessagesList,
  MessageStatusEvent,
  MessageStatusSubscribeEvent,
  UnreadMessagesCount
} from '../models';
import { Attachment } from '@breez/models/shared/files/processing-file.model';
import { AppNotificationData } from '@breez/modules/notification/models/app-notification.model';
import { OverlayPositionType } from '@breez/modules/overlay/models/overlay-position-type.enum';
import { PlacementType } from '@breez/models/notification/placement-type.enum';
import { NotificationService } from '@breez/modules/notification';
import { Router } from '@angular/router';
import { AuthService } from '@breez/modules/auth/services/auth.service';
import { DeclensionService } from '@breez/shared/services/declension.service';
import { StateService } from '@breez/shared/services/state.service';
import { ElectronService } from '@breez/modules/core/services';
import { ELECTRON_CHANNEL_LIST } from '../../../../../electron-channel-list';
import { LangChangeEvent } from '@ngx-translate/core/lib/translate.service';
import { CHAT_ID, MESSAGE_ID } from '../types';
import { CHATS_MUTE_KEY, CHATS_NOTIFICATION_KEY } from '../consts';
import { MessageService } from './message.service';
import { USER_ID } from '@breez/modules/users/types/user-id.type';
import { Store } from '@ngrx/store';
import * as ModuleState from '@breez/modules/chat/+state/module.state';
import * as ChatUnreadMessageCountActions from '@breez/modules/chat/+state/chatUnreadMessage/chatUnreadMessage.actions';
import { ChatEntity } from '@breez/modules/chat/models/+state/chatEntity';
import * as ChatActions from '@breez/modules/chat/+state/chat/chat.actions';
import { waitFor } from '@breez/shared/rxjs-operators/wait-for';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  getConferenceChatsUnreadMessagesCount(conferenceId: number): Observable<UnreadMessagesCount[]> {
    return this.wsService
      .send(WebsocketEvents.RECEIVE.CHAT.UNREAD_MESSAGES_ID, {
        data: {
          conferenceid: conferenceId
        }
      })
      .pipe(take(1), arrToClass(UnreadMessagesCount));
  }

  fetchOnConnectTrigger$ = this.wsService.status$.pipe(
    distinctUntilChanged(),
    filter(inSleepMode => {
      return inSleepMode;
    }),
    replayWhileSubs()
  );

  roleAccess$: Observable<boolean> = this.authService
    .checkRoles$(['chat:access'])
    .pipe(distinctUntilChanged(), replayWhileSubs());

  readonly step = 10;
  loadedChatsAmount = 1;
  loadChatTrigger$: Subject<void> = new Subject<void>();
  preventNotifications$ = new BehaviorSubject<boolean>(false);
  chatBase$: Observable<Chat[]> = this.loadChatTrigger$.pipe(
    debounceTime(100),
    switchMap(() => {
      return this.fetchChatList();
    }),
    map(chatList => {
      return this.setChatsLastMessageSender(chatList);
    }),
    map(chatList => {
      return chatList.chats;
    }),
    replayWhileSubs()
  );

  chatChanges$: Observable<Chat> = this.wsService.listen<Chat>({ path: WebsocketEvents.RECEIVE.CHAT.STATUS }).pipe(
    // TODO обработка event "будем удалять чаты, будем скрывать"
    map(response => {
      return (<any>response?.data).data;
    }),
    toClass(Chat)
  );

  messagesStatus$: Observable<MessageStatusEvent> = this.wsService
    .listen({ path: WebsocketEvents.RECEIVE.CHAT.MESSAGES_STATUS })
    .pipe(
      map(response => {
        return response?.data;
      }),
      toClass(MessageStatusSubscribeEvent),
      map(messageStatusSubscribeEvent => {
        return messageStatusSubscribeEvent.data;
      })
    );

  selectedLang$: Observable<string> = this.translateService.onLangChange.pipe(
    map((event: LangChangeEvent) => {
      return event.lang;
    }),
    startWith(this.translateService.currentLang),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  readonly demoConferenceChatModel: Chat = new Chat({
    id: -1,
    conferenceChatType: ConferenceChatEnum.GROUP_CHAT,
    conferenceId: -1,
    name: 'MAIN_CHAT'
  });

  private readonly QTSTagLocale: Locale = {
    ru: '#ВопросВедущему',
    en: '#QuestionToSpeaker'
  };

  readonly QTSTag = this.QTSTagLocale[this.translateService.currentLang];
  chatParticipantChanges$: Observable<ChatParticipantEvent> = this.wsService
    .listen<any>({ path: WebsocketEvents.RECEIVE.CHAT.PARTICIPANTS_CHANGES })
    .pipe(
      filter(({ data, parent }) => {
        return isTruthy(data?.event) && isTruthy(parent?.id);
      }),
      map(({ data, parent }) => {
        data.affectedchatid = parent.id;
        if (parent.hasOwnProperty('conferenceid')) {
          data.conferenceid = (<any>parent).conferenceid;
        }
        return data;
      }),
      toClass(ChatParticipantEvent)
    );

  // @ts-ignore
  private messageEventsObserver$: Observable<MessageEvent> = this.authService.currentUser$.pipe(
    switchMap(currentUser => {
      return !!currentUser ? this.wsService.listen<any>({ path: WebsocketEvents.RECEIVE.CHAT.MESSAGE }) : of(null);
    }),
    filter(isTruthy),
    map(resp => {
      return resp.data;
    }),
    toClass(IncomingMessagesSubscribeEvent),
    map(({ data }) => {
      return data;
    }),
    filter(isTruthy),
    filter(({ users, chats, messageEvents }) => {
      return isTruthy(users) && isTruthy(chats) && isTruthy(messageEvents);
    }),
    map(({ users, chats, messageEvents }) => {
      messageEvents.forEach(event => {
        const message = event.message;
        const user = users.find(user_ => {
          return user_.id === message.userId;
        });
        const sender = new ChatParticipant({ userId: message.userId, user });
        this.messageAddOriginalSender(message, users);
        const chat = chats.find(chat_ => {
          if (event.eventType === EventTypeEnum.DELETE) {
            return chats[0];
          }
          return chat_.id === message.chatId;
        });
        Object.assign(message, { sender, chat });
        if (isTruthy(chat.conferenceId) && message.deliveredUserIds?.length === 0 && isTruthy(sender.userId)) {
          Object.assign(message, { deliveredUserIds: [sender.userId] });
        }
      });
      return messageEvents;
    }),

    // toClass
    switchMap(messages => {
      return from(messages);
    })
  );

  messageEventsEffect$: Observable<MessageEvent> = this.messageEventsObserver$.pipe(filter(isTruthy));

  messageIncomingEffect$: Observable<Message> = this.messageEventsObserver$.pipe(
    withLatestFrom(this.authService.currentUser$, this.roleAccess$),
    filter(([event, currentUser, access]) => {
      const messageSenderId = event?.message?.sender?.userId;
      return (
        access &&
        event?.eventType === EventTypeEnum.INSERT &&
        messageSenderId !== currentUser?.id &&
        !!currentUser &&
        !!messageSenderId &&
        !!event
      );
    }),
    map(([event]) => {
      return event.message;
    })
  );

  changeReactionOnMessage(messageId: number, reaction: string): Observable<any> {
    return this.wsService.send(WebsocketEvents.SEND.CHAT.CHANGE_REACTION, {
      data: {
        messageid: messageId,
        reaction
      }
    });
  }

  messageAddOriginalSender(message: Message, users: User[]): Message {
    if (this.messageService.messageIsForwarded(message)) {
      const senderId: number = Number(message.meta?.forward?.userid);
      if (isTruthy(senderId)) {
        const senderUser = users.find(user => {
          return user.id === senderId;
        });
        if (senderUser) {
          message.originalSender = new ChatParticipant({
            userId: senderId,
            user: users.find(user => {
              return user.id === senderId;
            })
          });
        }
      }
    }
    return message;
  }

  constructor(
    private wsService: WebsocketService,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private router: Router,
    private authService: AuthService,
    private declensionService: DeclensionService,
    private stateService: StateService,
    private messageService: MessageService,
    private electronService: ElectronService,
    private store: Store<ModuleState.State>
  ) {
    if (this.electronService.isElectron) {
      this.electronService.electronApi.on(ELECTRON_CHANNEL_LIST.WINDOW_OPEN_CHAT_BY_ID, (_, chatId) => {
        this.openChat(chatId);
      });
    }
  }

  replyMessageTrigger$ = new Subject<Message>();
  getChatInfo(chatId: CHAT_ID): Observable<FoundChat> {
    return this.wsService
      .send(WebsocketEvents.RECEIVE.CHAT.GET_INFO, {
        data: {
          chatid: chatId
        }
      })
      .pipe(toClass(FoundChat));
  }

  getChatBanInfo(chatId: CHAT_ID, userId: number): Observable<BlockChatRequest> {
    return this.wsService
      .send(WebsocketEvents.RECEIVE.CHAT.GET_INFO, {
        data: {
          chatid: chatId
        }
      })
      .pipe(
        toClass(FoundChat),
        map(foundChat => {
          return foundChat.chat;
        }),
        filter(isTruthy),
        map((data: any) => {
          return (<any[]>data.participants).find(user => {
            return user.userid === userId;
          });
        }),
        filter(isTruthy),
        map(plain => {
          return <BlockChatRequest>{
            reason: plain.banreason,
            expires: plain.banexpires ? new Date(plain.banexpires * 1000) : null,
            banned: plain.banned
          };
        })
      );
  }

  chatBanEvent(chatId: CHAT_ID): Observable<BlockChatRequest> {
    return this.wsService.listen({ path: WebsocketEvents.RECEIVE.CHAT.BAN }).pipe(
      filter(response => {
        return response?.data?.chatId === chatId;
      }),
      filter(isTruthy),
      map(response => {
        const data = response?.data;
        return <BlockChatRequest>{
          banned: data.chatBanned,
          reason: data.chatBanReason,
          expires: data.chatBanExpires ? new Date(fixDateDeserialization(data.chatBanExpires)) : null
        };
      })
    );
  }

  banChatParticipants$: Observable<BlockChatRequest> = this.wsService
    .listen<any>({ path: WebsocketEvents.RECEIVE.CHAT.PARTICIPANTS_CHANGES })
    .pipe(
      filter(plain => {
        return plain.data.hasOwnProperty('banned');
      }),
      map(plain => {
        return {
          chatId: plain.parent?.id,
          conferenceId: plain.data.conferenceid,
          banned: plain.data.banned,
          expires: plain.data.banexpires ? new Date(plain.data.banexpires * 1000) : null,
          userId: plain.data.userid,
          reason: plain.data.banreason
        } as BlockChatRequest;
      })
    );

  unbanChatForUser(request: Partial<BlockChatRequest>): Observable<boolean> {
    return this.wsService
      .send(WebsocketEvents.SEND.CHAT.BAN, {
        data: {
          userid: request.userId,
          chatId: request.chatId,
          conferenceId: request.conferenceId,
          chatBanned: false
        }
      })
      .pipe(
        mapTo(true),
        catchError(() => {
          return of(false);
        })
      );
  }

  banChatForUser(request: BlockChatRequest): Observable<boolean> {
    return this.wsService
      .send(WebsocketEvents.SEND.CHAT.BAN, {
        data: {
          userid: request.userId,
          chatBanExpires: request.expires,
          chatId: request.chatId,
          conferenceId: request.conferenceId,
          chatBanReason: request.reason
        }
      })
      .pipe(
        map(() => {
          return true;
        }),
        catchError(() => {
          return of(false);
        })
      );
  }

  issueWarning(request: IssueWarningRequest): Observable<boolean> {
    return this.wsService
      .send(WebsocketEvents.SEND.CHAT.WARNING, {
        data: {
          userid: request.userId,
          message: request.message,
          chatId: request.chatId
        }
      })
      .pipe(
        mapTo(true),
        catchError(() => {
          return of(false);
        })
      );
  }

  getBlockReasonSuggestions(): Observable<string[]> {
    return this.translateService.get(['FLOOD', 'ADS', 'PROFANITY']).pipe(map(Object.values));
  }

  getWarningSuggestions(): Observable<string[]> {
    return this.translateService.get(['WARNING']).pipe(map(Object.values));
  }

  fetchMessages(
    data: { chatid?: CHAT_ID; messageids?: number[]; from?: number; count?: number },
    depth = 1
  ): Observable<Message[]> {
    return this.wsService.send<any[]>(WebsocketEvents.RECEIVE.MESSAGE.GET_LIST, { data }).pipe(
      take(1),
      toClass(MessagesList),
      map(({ messages, users }) => {
        messages.forEach(message => {
          message.sender = new ChatParticipant({
            userId: message.userId,
            user: users.find(user => {
              return user.id === message.userId;
            })
          });
          this.messageAddOriginalSender(message, users);
        });

        messages.reverse();
        return messages;
      }),

      switchMap(messages => {
        if (!depth) {
          return of(messages);
        }

        return of(messages);
      })
    );
  }

  getMessagesByChatId(chatId: CHAT_ID, depth = 1, messageSenderId?: number, count?: number): Observable<Message[]> {
    if (!chatId) {
      return of(<Message[]>[]);
    }
    return this.fetchMessages({ chatid: chatId, from: messageSenderId, count }, depth);
  }

  getMessageById(chatId: CHAT_ID, messageId: MESSAGE_ID, depth = 1): Observable<Message> {
    return this.fetchMessages({ chatid: chatId, messageids: [messageId] }, depth).pipe(
      map(([message]) => {
        return message;
      })
    );
  }

  getMessagesById(chatId: CHAT_ID, messageIds: MESSAGE_ID[], depth = 1): Observable<Message[]> {
    return this.fetchMessages({ chatid: chatId, messageids: messageIds }, depth);
  }

  observeMessageEvents(chatId: CHAT_ID): Observable<boolean> {
    return this.wsService.send(WebsocketEvents.SEND.CHAT.ENTER, {
      parent: {
        resource: ObjectType.CHAT,
        id: chatId
      }
    });
  }

  messageEvents(
    chatId: CHAT_ID,
    _: number = 1,
    emitObserve: boolean = true,
    eventsObserverType: EventsObserverEnum = EventsObserverEnum.GLOBAL
  ): Observable<MessageEvent> {
    if (!chatId) {
      return EMPTY;
    }

    return of(null)
      .pipe(
        switchMap(() => {
          return this.observeMessageEvents(chatId);
        }),
        catchError(e => {
          return throwError(e);
        }),
        switchMap(() => {
          return this.observeMessagesEvents$(eventsObserverType);
        })
      )
      .pipe(
        filter(event => {
          return event.message.chatId === chatId || event.eventType === EventTypeEnum.DELETE;
        }),
        concatMap(event => {
          return of(event);
        }),
        finalize(() => {
          if (!emitObserve) {
            return;
          }
          this.leaveChat(chatId).pipe(take(1)).subscribe();
        })
      );
  }

  leaveChat(chatId: CHAT_ID): Observable<boolean> {
    return this.wsService.send<boolean>(WebsocketEvents.SEND.CHAT.LEAVE, {
      parent: { resource: ObjectType.CHAT, id: chatId }
    });
  }

  sendMessage(message: Message, conferenceId?: number): Observable<MESSAGE_ID> {
    return of(message).pipe(
      toPlain(),
      map((plain: { [key: string]: string | number }) => {
        plain.conferenceid = conferenceId;
        return plain;
      }),
      switchMap(data => {
        return this.wsService.send<MESSAGE_ID>(WebsocketEvents.SEND.MESSAGE.SEND, { data });
      })
    );
  }

  markMessagesRangeAsRead(fromMessage: Message, toMessage: Message, read: boolean = true): Observable<number> {
    const chatId = fromMessage.chatId;
    return this.wsService
      .query(WebsocketEvents.RECEIVE.CHAT.READ_MESSAGES, {
        data: {
          chatid: chatId,
          frommessageid: fromMessage.id,
          tomessageid: toMessage.id,
          read
        }
      })
      .pipe(
        tap(() => {
          return this.store.dispatch(ChatUnreadMessageCountActions.loadUnreadMessages({}));
        })
      );
  }

  getRangeOfMessagesBySet(messages: Message[]): { from: Message; to: Message } {
    if (!Array.isArray(messages)) {
      return null;
    }
    if (!messages.length) {
      return null;
    }

    const set = messages.sort((messageA, messageB) => {
      return messageA.sentDate?.getTime() - messageB.sentDate?.getTime();
    });
    return { from: set[0], to: set[set.length - 1] };
  }

  markChatAsRead(chatId: CHAT_ID, read: boolean = true): Observable<number> {
    return this.wsService
      .send(WebsocketEvents.RECEIVE.CHAT.READ_MESSAGES, {
        data: {
          chatid: chatId,
          read
        }
      })
      .pipe(
        catchError(() => {
          return of(false);
        })
      );
  }

  updateMessage(messageId: MESSAGE_ID, messageBody: string, attachments?: string[]): Observable<any> {
    return this.wsService.send(WebsocketEvents.RECEIVE.CHAT.UPDATE_MESSAGE, {
      data: {
        messageid: messageId,
        text: messageBody,
        attachments: attachments
      }
    });
  }

  deleteMessage(messageId: MESSAGE_ID, messageBody: string): Observable<any> {
    return this.wsService.send(WebsocketEvents.RECEIVE.CHAT.DELETE_MESSAGE, {
      data: {
        messageid: messageId,
        text: messageBody
      }
    });
  }

  replyToMessage(chatid: CHAT_ID, text: string, replyid: number): Observable<any> {
    return this.wsService.send<any>(WebsocketEvents.SEND.MESSAGE.SEND, { data: { chatid, text, replyid } });
  }

  hasQTSTagInBody(body: string): boolean {
    if (!body) {
      return false;
    }

    if (typeof body !== 'string') {
      return false;
    }

    return Object.keys(this.QTSTagLocale).some(key => {
      return body.toLocaleLowerCase().includes(this.QTSTagLocale[key].toLocaleLowerCase());
    });
  }

  excludeQTSTagFromBody(body: string): string {
    Object.keys(this.QTSTagLocale).forEach(key => {
      if (body.toLocaleLowerCase().includes(this.QTSTagLocale[key].toLocaleLowerCase())) {
        body = body.replace(this.QTSTagLocale[key], '');
      }
    });

    return body;
  }

  forwardMessages(chatId: CHAT_ID, messagesIds: number[]): Observable<any> {
    return this.wsService.send(WebsocketEvents.SEND.CHAT.FORWARD_MESSAGES, {
      data: {
        chatid: chatId,
        messageids: messagesIds
      }
    });
  }

  warnings(): Observable<string> {
    return this.wsService.listen({ path: WebsocketEvents.RECEIVE.CHAT.WARNING }).pipe(
      map((event: any) => {
        return event?.data?.banPrevention;
      }),
      filter(isTruthy)
    );
  }

  onfetchList(): Observable<Message> {
    return this.wsService.listen<{ messages: any[] }>({ path: WebsocketEvents.RECEIVE.MESSAGE.GET_LIST }).pipe(
      take(1),
      map(event => {
        return event?.data?.messages[0] ?? null;
      })
    );
  }

  pinChat(chatId: CHAT_ID, pinnedOrder?: number): Observable<{ chatid: CHAT_ID; pinnedorder: number; userid: number }> {
    const data = pinnedOrder ? { chatid: chatId, pinnedorder: pinnedOrder } : { chatid: chatId };
    return this.wsService
      .send(WebsocketEvents.RECEIVE.CHAT.ADD_ANCHORAGE, {
        data
      })
      .pipe(
        catchError(() => {
          return of(false);
        })
      );
  }

  unpinChat(chatId: CHAT_ID): Observable<{ chatid: CHAT_ID; pinnedorder: number; userid: number }> {
    return this.wsService
      .send(WebsocketEvents.RECEIVE.CHAT.REMOVE_ANCHORAGE, {
        data: {
          chatid: chatId
        }
      })
      .pipe(
        catchError(() => {
          return of(false);
        })
      );
  }

  observeParticipantChanges$(): Observable<ChatParticipantEvent> {
    return this.chatParticipantChanges$.pipe(
      filter(participantEvent => {
        return isTruthy(participantEvent.affectedChatId);
      })
    );
  }

  observeUnreadChats$(): Observable<CHAT_ID> {
    return this.wsService.listen({ path: WebsocketEvents.RECEIVE.CHAT.CHAT_CHANGES }).pipe(
      map(event => {
        return event?.parent.id;
      })
    );
  }

  setDefaultConferenceChatsTitle(chat: Chat | ChatEntity, conferenceId?: number): Chat | ChatEntity {
    if (chat.name === null) {
      chat.name = ConferenceChatTitleEnum.MAIN_CHAT;
    }

    if (chat.name === ConferenceChatEnum.QUESTION_TO_SPEAKER) {
      chat.name = ConferenceChatTitleEnum.QUESTIONS_TO_SPEAKER;
    }
    if (isTruthy(conferenceId)) {
      chat.conferenceId = conferenceId;
    }

    return chat;
  }

  observeMessagesEvents$(observerType: EventsObserverEnum): Observable<MessageEvent> {
    return this.messageEventsObserver$.pipe(
      withLatestFrom(this.roleAccess$),
      filter(([messageEvent, roleAccess]) => {
        const message = messageEvent?.message;

        return !!message
          ? observerType === EventsObserverEnum.CONFERENCE
            ? isTruthy(message?.chat?.conferenceId)
            : roleAccess
          : false;
      }),
      map(([messageEvent]) => {
        return messageEvent;
      })
    );
  }

  processDeletedChatParticipant$(event: ChatParticipantEvent, chats: Chat[]): Observable<Chat[]> {
    const targetChatId = event.affectedChatId;
    const targetChat = chats.find(chat => {
      return chat.id === targetChatId;
    });
    const affectedUser = new User({
      id: event.updatedUserId,
      name: event.updatedUserName
    });

    targetChat.participantUserIds = targetChat.participantUserIds.filter(participantId => {
      return participantId !== affectedUser.id;
    });

    return of(chats);
  }

  setChatsLastMessageSender(chatList: ChatList): ChatList {
    const { chats, users } = chatList;
    return { chats, users };
  }

  getChatParticipantsCount(participantsCount: number): Observable<string> {
    return this.declensionService.getDeclension('CHAT_PARTICIPANTS', participantsCount);
  }

  processDeletedMessage$(initialChatList: Chat[], message: Message): Observable<Chat[]> {
    const chats = initialChatList;
    const targetChat = chats.find(chat => {
      const lastMessageId = isTruthy(chat.lastMessage) ? chat.lastMessage.id : -1;
      return lastMessageId === message.id;
    });
    const targetChatLastMessage = isTruthy(targetChat) ? targetChat.lastMessage : null;

    if (isTruthy(targetChatLastMessage) && targetChatLastMessage.id === message.id) {
      return this.getNewMessages(targetChat.id, null, 1).pipe(
        toClass(MessagesList),
        map(messagesList => {
          return messagesList.messages;
        }),
        switchMap(previousMessages => {
          const lastMessage = previousMessages[0];
          if (!lastMessage) {
            targetChat.lastMessage = null;
            return of(chats);
          }
          targetChat.lastMessage = lastMessage;
          return of(chats);
        })
      );
    }

    return of(chats);
  }

  processChatParticipantEvent$(event: ChatParticipantEvent, chats: Chat[]): Observable<Chat[]> {
    const eventType = event.eventType;
    const targetChatId = event.affectedChatId;
    const targetChat = chats.find(chat => {
      return chat.id === targetChatId;
    });

    if (!targetChat) {
      return of(chats);
    }

    if (eventType === EventTypeEnum.DELETE) {
      return this.processDeletedChatParticipant$(event, chats);
    }

    if (eventType === EventTypeEnum.INSERT) {
      return this.processNewChatParticipant$(event, chats);
    }

    return of(chats);
  }

  processNewChatParticipant$(event: ChatParticipantEvent, chats: Chat[]): Observable<Chat[]> {
    const targetChatId = event.affectedChatId;
    const targetChat = chats.find(chat => {
      return chat.id === targetChatId;
    });
    const affectedUser = new User({
      id: event.updatedUserId,
      name: event.updatedUserName
    });
    targetChat.participantUserIds = targetChat.participantUserIds.concat(affectedUser.id);
    return of(chats);
  }

  addParticipantsToChat(participantsIds: number[], chatId: CHAT_ID): Observable<boolean> {
    return this.wsService.send(WebsocketEvents.RECEIVE.CHAT.ADD_PARTICIPANTS, {
      data: {
        chatid: chatId,
        participantids: participantsIds
      }
    });
  }

  removeChatParticipants(participantsIds: number[], chatId: CHAT_ID): Observable<boolean> {
    return this.wsService.send(WebsocketEvents.RECEIVE.CHAT.REMOVE_PARTICIPANTS, {
      data: {
        chatid: chatId,
        participantids: participantsIds
      }
    });
  }

  getNewMessages(chatId: CHAT_ID, fromIndex?: number, count?: number): Observable<Message[]> {
    return this.wsService.send(WebsocketEvents.RECEIVE.CHAT.GET_MESSAGES, {
      data: {
        chatid: chatId,
        from: fromIndex,
        count
      }
    });
  }

  // noinspection JSUnusedGlobalSymbols
  getChatAttachments(chatId: CHAT_ID): Observable<Attachment> {
    return this.wsService.send('chat.getAttachments', {
      data: {
        chatid: chatId
      }
    });
  }

  openChat(chatId: CHAT_ID): void {
    this.store.dispatch(ChatActions.selectChat({ chatId }));
    this.router.navigate(['chat'], {
      queryParams: {
        id: chatId
      }
    });
  }

  getChatByParticipantsAndConferenceIds(participantIds: number[], conferenceId?: number): Observable<FoundChat> {
    return this.wsService.send(WebsocketEvents.RECEIVE.CHAT.GET_BY_PARTICIPANTS, {
      data: {
        participantids: participantIds,
        conferenceid: conferenceId
      }
    });
  }

  recountLoadedChats(): void {
    this.loadedChatsAmount += this.step;
    this.loadChatTrigger$.next();
  }

  updateChat(chatTitle: string, chatId: CHAT_ID, callId = 0): Observable<any> {
    return this.wsService.send(WebsocketEvents.RECEIVE.CHAT.UPDATE, {
      data: {
        chatid: chatId,
        name: chatTitle,
        callid: callId || null
      }
    });
  }

  getMessagePreviewContent(message: Message): Observable<string> {
    if (!isTruthy(message)) {
      return of(null);
    }

    const attachmentsAmount = message.attachments.length;
    const attachmentsPrefix = attachmentsAmount > 1 ? attachmentsAmount : '';

    return attachmentsAmount > 0 && !message.body
      ? this.declensionService.getDeclension('ATTACHMENTS', attachmentsAmount).pipe(
          map(translated => {
            return `${attachmentsPrefix} ${translated}`;
          })
        )
      : this.messageService.convertMessageToPlainText$(message);
  }

  createChat(name?: string, participantUserIds?: USER_ID[], conferenceId?: number): Observable<number> {
    return this.wsService
      .send(WebsocketEvents.SEND.CHAT.CREATE, {
        data: {
          name,
          participantids: participantUserIds,
          conferenceid: conferenceId
        }
      })
      .pipe(take(1));
  }

  getAccessInfo(chatId: CHAT_ID): Observable<boolean> {
    return this.wsService.send<boolean>(WebsocketEvents.RECEIVE.CHAT.GET_ACCESS_INFO, {
      data: {
        chatid: chatId
      }
    });
  }

  getList(): Observable<Chat[]> {
    return this.chatBase$;
  }

  getChatById(chatId: CHAT_ID): Observable<FoundChat> {
    return this.wsService.send(WebsocketEvents.RECEIVE.CHAT.GET, { data: { chatid: chatId } }).pipe(
      take(1),
      toClass(FoundChat),
      filter(({ chat, users }) => {
        return isTruthy(chat) && isTruthy(users);
      })
    );
  }

  onMessageIncoming(message: Partial<Message>, chatName?: string): void {
    const dutyMessage = [
      'CALL_DECLINED',
      'CALL_CANCELED',
      'CALL_TRANSFERRED',
      'CALL_HOLDED',
      'CALL_ENDED',
      'CALL_STARTED',
      'USER_REGISTERED'
    ].includes(message.body);

    if (dutyMessage) {
      return;
    }

    const forbiddenUrls = [/^\/conference\/add/];
    const chatsMute: CHAT_ID[] = this.stateService.getFromLocal(CHATS_MUTE_KEY) ?? [];
    const isConferencePage = document.querySelector('vks-conference');
    const isOnlyPushUrl = this.router.url.includes('chat');
    const isForbidden = forbiddenUrls.reduce((acc: boolean, val) => {
      acc = val.test(this.router.url) || acc;
      return acc;
    }, false);
    if (!isForbidden && !chatsMute.includes(message.chatId)) {
      combineLatest([
        this.translateService.get('NEW_MESSAGE'),
        this.getMessagePreviewContent(<Message>message)
      ]).subscribe(([text, messageContent]) => {
        const title = chatName ?? message?.chat?.name;
        const subtitle = this.messageService.messageAvatarEntity(message).name;
        const data: Partial<AppNotificationData> = {
          containerId: message.id,
          title,
          tag: text,
          subtitle: title === subtitle ? null : subtitle,
          message: messageContent,
          position: OverlayPositionType.SIDE,
          timeout: 4000
        };
        if (this.stateService.getFromLocal<number>(CHATS_NOTIFICATION_KEY) < data.containerId) {
          this.stateService.saveToLocal(CHATS_NOTIFICATION_KEY, data.containerId);
          const notification = this.notificationService.notify({
            ...data,
            placement: isOnlyPushUrl || isConferencePage ? PlacementType.PUSH : PlacementType.BOTH,
            avatar: this.messageService.messageAvatarEntity(message)
          });

          notification.onClick$.subscribe(() => {
            window.focus();
            notification.close();
            if (!this.electronService.isElectron) {
              this.openChat(message.chatId);
            } else {
              this.electronService.openChat(message.chatId);
            }
          });
        }
      });
    }
  }

  fetchChatList(conferenceId?: number): Observable<ChatList> {
    return this.wsService
      .send(WebsocketEvents.RECEIVE.CHAT.GET, {
        data: {
          conferenceid: conferenceId
        }
      })
      .pipe(toClass(ChatList));
  }

  fetchChatsUnreadMessageCount(): Observable<UnreadMessagesCount[]> {
    return this.fetchOnConnectTrigger$.pipe(
      waitFor(() => {
        return this.authService.currentUser$.pipe(
          filter(user => {
            return !!user.id;
          })
        );
      }),
      switchMap(() => {
        return this.wsService.query(WebsocketEvents.RECEIVE.CHAT.UNREAD_MESSAGES_ID);
      }),
      filter(isTruthy),
      arrToClass(UnreadMessagesCount)
    );
  }
}
