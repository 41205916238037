import { animate, state, style, transition, trigger } from '@angular/animations';
import { FocusMonitor } from '@angular/cdk/a11y';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Call } from '@breez/modules/call/call';
import { CallDestinationType, CallModel, CallStatus } from '@breez/modules/call/models';
import { OverlayContainer } from '@breez/modules/overlay/models';
import { OverlayPositionType } from '@breez/modules/overlay/models/overlay-position-type.enum';
import { DeviceSourceType } from '@breez/models/webrtc/device-source-type.enum';
import { MediaSourceKind } from '@breez/models/webrtc/media-source-kind.enum';
import { UserMediaSource } from '@breez/models/webrtc/media-source.model';
import { MediaDevicesService } from '@breez/modules/webrtc/services/media-devices.service';
import { WebrtcDialogService } from '@breez/modules/webrtc/webrtc-dialog.service';
import { StateService } from '@breez/shared/services/state.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, defer, EMPTY, fromEvent, merge, Observable, of, ReplaySubject, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  finalize,
  map,
  mapTo,
  scan,
  startWith,
  switchMap,
  take,
  takeUntil,
  takeWhile,
  withLatestFrom
} from 'rxjs/operators';
import { CallContentType } from '@breez/modules/call/models/call-content-type.enum';
import { CallLocalMediaSource } from '@breez/modules/call/models/call-media-source.model';
import { replayWhileSubs, toClass } from '@breez/shared/rxjs-operators';
import { BACKDROP_CLICK } from '@breez/modules/overlay/overlay-types/overlay-entry-event.provider';
import { User } from '@breez/models';
import { waitFor } from '@breez/shared/rxjs-operators/wait-for';
import { FoundChat, ChatService } from '@breez/modules/chat';
import { AuthService } from '@breez/modules/auth/services/auth.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as ChatActions from '@breez/modules/chat/+state/chat/chat.actions';
import { guid } from '@breez/helpers/guid';

@UntilDestroy()
@Component({
  selector: 'vks-call-status-accept',
  templateUrl: './type.component.html',
  styleUrls: [
    './type.component.scss',
    './type.component.media-max600.scss',
    './type.component.media-max420.scss',
    './type.component.media-max350.scss'
  ],
  animations: [
    trigger('volumeTrackCoverAnimation', [
      state('collapsed', style({ opacity: 0, minHeight: 0 })),
      state('expanded', style({ opacity: 1, minHeight: '144px' })),
      transition('collapsed <=> expanded', [animate('300ms ease')])
    ]),
    trigger('volumeSliderAnimation', [
      state('collapsed', style({ opacity: 0, minHeight: 0 })),
      state('expanded', style({ opacity: 1, minHeight: '128px' })),
      transition('collapsed <=> expanded', [animate('300ms ease')])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CallStatusAcceptComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @ViewChild('slider', { static: true, read: ElementRef }) sliderElementRef: ElementRef;

  @Input() call: Call;
  @Input() callModel: CallModel;
  @Input() overlayContainer: OverlayContainer;

  @Output() changeOverlayContainer = new EventEmitter<OverlayContainer>();

  @HostBinding('class.smart')
  isSmartOverlayContainer: boolean;

  @HostBinding('class.fullscreen')
  isFullscreenContainer: boolean;

  peer: User;

  @HostBinding('class.narrow')
  narrow = false;

  @ViewChild('remoteVideo')
  remoteVideo: ElementRef<HTMLVideoElement>;

  @ViewChild('remoteScreen')
  remoteScreen: ElementRef<HTMLVideoElement>;

  @ViewChild('localVideo')
  localVideo: ElementRef<HTMLVideoElement>;

  @ViewChild('remoteAudio')
  remoteAudio: ElementRef<HTMLAudioElement>;

  volumeControlState: 'collapsed' | 'expanded' = 'collapsed';

  callStatus = CallStatus;

  callDestinationType: typeof CallDestinationType = CallDestinationType;

  ngAfterViewInit$ = new ReplaySubject<void>(1);

  overlayContainer$ = new ReplaySubject<OverlayContainer>(1);
  isFullscreen$: Observable<boolean> = this.stateService.isFullscreen$;
  canRestore$: Observable<boolean> = combineLatest([this.isFullscreen$, this.overlayContainer$.asObservable()]).pipe(
    map(([isFullscreen, overlayContainer]) => {
      return !isFullscreen && !overlayContainer.position.includes(OverlayPositionType.CENTER);
    })
  );

  CallContentType = CallContentType;

  windowSize$ = this.stateService.windowSize$;

  reducedRemoteAudioTrack$: Observable<MediaStreamTrack>;
  reducedRemoteVideoTrack$: Observable<MediaStreamTrack>;
  reducedRemoteScreenTrack$: Observable<MediaStreamTrack>;

  reducedRemoteVideoStream$: Observable<MediaStream>;
  reducedRemoteAudioStream$: Observable<MediaStream>;
  reducedRemoteScreenMediaStream$: Observable<MediaStream>;
  reducedLocalMediaStream$: Observable<MediaStream>;

  foregroundVideoRemoteStream$: Observable<MediaStream> = defer(() => {
    return combineLatest([this.reducedRemoteVideoTrack$, this.reducedRemoteScreenTrack$]).pipe(
      scan((mediaStream, [video, screen]) => {
        if (screen) {
          mediaStream.getTracks().forEach(track => {
            if (track !== screen) {
              mediaStream.removeTrack(track);
            }
          });
          if (mediaStream.getTracks().length === 0) {
            mediaStream.addTrack(screen);
          }
          return mediaStream;
        }

        if (video) {
          mediaStream.getTracks().forEach(track => {
            if (track !== video) {
              mediaStream.removeTrack(track);
            }
          });
          if (mediaStream.getTracks().length === 0) {
            mediaStream.addTrack(video);
          }

          return mediaStream;
        }

        mediaStream.getTracks().forEach(track => {
          return mediaStream.removeTrack(track);
        });
        return mediaStream;
      }, new MediaStream())
    );
  });

  availableSources$: Observable<UserMediaSource[]> = this.mediaDevicesService.availableSources$;

  defaultSources$: Observable<UserMediaSource[]> = this.availableSources$.pipe(
    map(sources => {
      return sources.filter(source => {
        return source.isDefault;
      });
    })
  );

  availableAudioDevice$: Observable<boolean> = this.availableSources$.pipe(
    map(sources => {
      return sources.some(source => {
        return source.kind === MediaSourceKind.AUDIO_INPUT;
      });
    })
  );

  availableHardwareDevice$: Observable<boolean> = this.availableSources$.pipe(
    map(sources => {
      return sources.some(source => {
        return source.kind === MediaSourceKind.VIDEO_INPUT && source.sourceType === DeviceSourceType.HARDWARE;
      });
    })
  );

  videoInputAvailable$: Observable<boolean> = this.mediaDevicesService.videoInputAvailable$;
  audioInputAvailable$: Observable<boolean> = this.mediaDevicesService.audioInputAvailable$;
  screencastAvailable$: Observable<boolean> = this.mediaDevicesService.screencastAvailable$;

  selectedSources = new Map<CallContentType, CallLocalMediaSource>();

  // todo вынести в фабрику "videoPlaying"
  remoteVideoPlaying$ = this.ngAfterViewInit$.pipe(
    switchMap(() => {
      return merge(
        fromEvent(this.remoteVideo.nativeElement, 'playing').pipe(mapTo(true)),
        fromEvent(this.remoteVideo.nativeElement, 'pause').pipe(mapTo(false))
      ).pipe(startWith(this.isPlaying(this.remoteVideo.nativeElement)));
    })
  );

  remoteScreenPlaying$ = this.ngAfterViewInit$.pipe(
    switchMap(() => {
      return merge(
        fromEvent(this.remoteScreen.nativeElement, 'playing').pipe(mapTo(true)),
        fromEvent(this.remoteScreen.nativeElement, 'pause').pipe(mapTo(false))
      ).pipe(startWith(this.isPlaying(this.remoteScreen.nativeElement)));
    })
  );

  localBlur$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  remotePlaying$ = combineLatest([this.remoteVideoPlaying$, this.remoteScreenPlaying$]).pipe(
    map(([video, screen]) => {
      return video || screen;
    })
  );

  facingVideo$: Observable<HTMLVideoElement> = combineLatest([
    this.remoteVideoPlaying$,
    this.remoteScreenPlaying$
  ]).pipe(
    waitFor(() => {
      return this.ngAfterViewInit$;
    }),
    map(([video, screen]) => {
      let facingVideo: HTMLVideoElement = null;
      if (screen) {
        facingVideo = this.remoteScreen.nativeElement;
      } else if (video) {
        facingVideo = this.remoteVideo.nativeElement;
      }

      if (!facingVideo) {
        return null;
      }

      return facingVideo;
    }),
    replayWhileSubs()
  );

  facingVideoRatio$: Observable<number> = defer(() => {
    return this.facingVideo$;
  }).pipe(
    switchMap(facingVideo => {
      if (!facingVideo) {
        return of(4 / 3);
      }

      return fromEvent(facingVideo, 'resize').pipe(
        startWith(<Event>null),
        map(() => {
          return facingVideo.videoWidth / facingVideo.videoHeight;
        })
      );
    }),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  hostSizePart$ = of(0.8);

  hostSize$: Observable<{ width: number; height: number }> = combineLatest([
    this.windowSize$,
    this.facingVideoRatio$,
    this.hostSizePart$,
    this.isFullscreen$
  ]).pipe(
    map(([windowSize, videoRatio, hostSizePart, fullScreen]) => {
      if (fullScreen) {
        return windowSize;
      }

      const maxWidth = windowSize.width * hostSizePart;
      const maxHeight = windowSize.height * hostSizePart;

      if (videoRatio < 1) {
        let width = maxHeight * videoRatio,
          height = maxHeight;
        if (width > maxWidth) {
          width = maxWidth;
          height = maxWidth / videoRatio;
        }
        return { width, height };
      } else if (videoRatio > 1) {
        let width = maxWidth,
          height = maxWidth / videoRatio;
        if (height > maxHeight) {
          height = maxHeight;
          width = maxHeight * videoRatio;
        }
        return { width, height };
      } else {
        const sideSize = Math.min(maxWidth, maxHeight);
        return { width: sideSize, height: sideSize };
      }
    }),
    replayWhileSubs()
  );

  destroyed$: Subject<void> = new Subject<void>();

  @HostListener('click')
  handleClick(): void {
    this.tryRestore();
  }

  constructor(
    private elementRef: ElementRef,
    private stateService: StateService,
    private mediaDevicesService: MediaDevicesService,
    private webrtcDialogService: WebrtcDialogService,
    private cd: ChangeDetectorRef,
    @Inject(BACKDROP_CLICK) private backdropClicked: Observable<MouseEvent>,
    private chatService: ChatService,
    private authService: AuthService,
    private router: Router,
    public focusMonitor: FocusMonitor,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.overlayContainer$.next(this.overlayContainer);

    this.stateService.isAppVisible$.pipe(untilDestroyed(this)).subscribe(async visible => {
      const pipVideo: HTMLVideoElement = (<any>document).pictureInPictureElement;

      if (!visible) {
        if (this.stateService.isAppFocused()) {
          return;
        }

        if (pipVideo) {
          return;
        }

        if (!this.remoteVideo || !this.remoteVideo.nativeElement.srcObject) {
          return;
        }

        const video = document.createElement('video');

        if (this.isPlaying(this.remoteScreen.nativeElement)) {
          video.srcObject = this.remoteScreen.nativeElement.srcObject;
        } else if (this.isPlaying(this.remoteVideo.nativeElement)) {
          video.srcObject = this.remoteVideo.nativeElement.srcObject;
        }

        if (!video.srcObject) {
          return;
        }

        await video.play();

        if (typeof (<any>video).requestPictureInPicture === 'function') {
          try {
            (<any>video).requestPictureInPicture();
          } catch {}
        }
      } else {
        if (pipVideo) {
          (<any>document).exitPictureInPicture();
        }
      }
    });

    this.backdropClicked.pipe(untilDestroyed(this)).subscribe(() => {
      return this.onCollapse();
    });

    this.canRestore$.pipe(untilDestroyed(this)).subscribe(canRestore => {
      return (this.isSmartOverlayContainer = canRestore);
    });

    this.reducedRemoteAudioTrack$ = this.call.incomingCallMediaSources$.pipe(
      map(sources => {
        return sources.find(source => {
          return source.contentType === CallContentType.FACING_AUDIO;
        });
      }),
      map(source => {
        return source && source.resolvedMediaSource.track;
      }),
      distinctUntilChanged()
    );

    this.reducedRemoteVideoTrack$ = this.call.incomingCallMediaSources$.pipe(
      map(sources => {
        return sources.find(source => {
          return source.contentType === CallContentType.FACING_VIDEO;
        });
      }),
      map(source => {
        return source && source.resolvedMediaSource.track;
      }),
      distinctUntilChanged(),
      replayWhileSubs()
    );

    this.reducedRemoteScreenTrack$ = this.call.incomingCallMediaSources$.pipe(
      map(sources => {
        return sources.find(source => {
          return source.contentType === CallContentType.SCREEN_VIDEO;
        });
      }),
      map(source => {
        return source && source.resolvedMediaSource.track;
      }),
      distinctUntilChanged(),
      replayWhileSubs()
    );

    this.reducedRemoteAudioStream$ = this.reducedRemoteAudioTrack$.pipe(
      map(track => {
        return track ? new MediaStream([track]) : null;
      }),
      replayWhileSubs()
    );

    this.reducedLocalMediaStream$ = this.call.resolvedCallMediaSources$.pipe(
      map(sources => {
        return sources.find(source => {
          return source.contentType === CallContentType.FACING_VIDEO;
        });
      }),
      map(source => {
        return source ? source.resolvedMediaSource.track : null;
      }),
      distinctUntilChanged(),
      scan((stream, track) => {
        if (!track) {
          if (stream.getTracks()[0]) {
            stream.removeTrack(stream.getTracks()[0]);
          }
          return stream;
        }

        if (stream.getTracks()[0] !== track) {
          if (stream.getTracks()[0]) {
            stream.removeTrack(stream.getTracks()[0]);
          }
          stream.addTrack(track);
        }

        return stream;
      }, new MediaStream())
    );

    this.isFullscreen$.pipe(untilDestroyed(this)).subscribe(isFullscreen => {
      return (this.isFullscreenContainer = isFullscreen);
    });

    this.call.resolvedCallMediaSources$.pipe(untilDestroyed(this)).subscribe(sources => {
      this.selectedSources.clear();
      sources.forEach(source => {
        return this.selectedSources.set(source.contentType, source);
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.callModel && this.callModel) {
      this.peer =
        this.callModel.destinationType === this.callDestinationType.INCOMING
          ? this.callModel.caller
          : this.callModel.answerer;
    }
  }

  ngOnDestroy(): void {
    if ((<any>document).pictureInPictureElement) {
      (<any>document).exitPictureInPicture();
    }
    this.destroyed$.next();
  }

  setVolume(volume: number, saveValue: boolean = true): void {
    this.remoteAudio.nativeElement.volume = volume;
    if (saveValue) {
      this.call.volume$.next(volume);
    }
  }

  ngAfterViewInit(): void {
    this.focusMonitor.stopMonitoring(this.sliderElementRef.nativeElement);
    this.reducedRemoteVideoStream$ = this.reducedRemoteVideoTrack$.pipe(
      map(track => {
        return track ? new MediaStream([track]) : null;
      }),
      scan(
        (prevStream, currStream) => {
          // TODO эту вот логику нужно перетащить
          //  в обзёрвабл-сеттер и не держать тут старого мусора, а то враньё получается

          if (!currStream && this.remoteVideo) {
            this.remoteVideo.nativeElement.pause();
            return prevStream;
          }
          this.remoteVideo.nativeElement.srcObject = currStream;
          this.remoteVideo.nativeElement.play();
          return currStream;
        },
        <MediaStream>null
      ),
      replayWhileSubs()
    );

    this.reducedRemoteScreenMediaStream$ = this.reducedRemoteScreenTrack$.pipe(
      map(track => {
        return track ? new MediaStream([track]) : null;
      }),
      scan(
        (prevStream, currStream) => {
          if (!currStream && this.remoteScreen) {
            this.remoteScreen.nativeElement.pause();
            return prevStream;
          }
          this.remoteScreen.nativeElement.srcObject = currStream;
          this.remoteScreen.nativeElement.play();
          return currStream;
        },
        <MediaStream>null
      ),
      replayWhileSubs()
    );

    this.reducedLocalMediaStream$.pipe(untilDestroyed(this)).subscribe(stream => {
      if (this.localVideo) {
        this.localVideo.nativeElement.srcObject = stream;
        if (stream) {
          this.localVideo.nativeElement.play();
        } else {
          this.localVideo.nativeElement.pause();
        }
      }
    });

    this.reducedRemoteAudioStream$.pipe(untilDestroyed(this)).subscribe(stream => {
      if (this.remoteAudio) {
        this.remoteAudio.nativeElement.srcObject = stream;
        this.remoteAudio.nativeElement.volume = this.call.volume$.value;

        if (stream) {
          this.remoteAudio.nativeElement.play();
        } else {
          this.remoteAudio.nativeElement.pause();
        }
      }
    });

    this.reducedRemoteVideoStream$.pipe(untilDestroyed(this)).subscribe();

    this.reducedRemoteScreenMediaStream$.pipe(untilDestroyed(this)).subscribe();

    this.ngAfterViewInit$.next();

    this.facingVideo$.pipe(untilDestroyed(this)).subscribe(facingVideo => {
      const pipVideo: HTMLVideoElement = (<any>document).pictureInPictureElement;
      if (!pipVideo) {
        return;
      }

      if (!facingVideo && pipVideo) {
        (<any>document).exitPictureInPicture();
        return;
      }

      pipVideo.srcObject = facingVideo.srcObject;
      pipVideo.play();
    });

    this.hostSize$.pipe(untilDestroyed(this)).subscribe(size => {
      this.narrow = size.width < 512;
    });
  }

  onChangeCall(status: CallStatus, event?: MouseEvent): void {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    if (status === CallStatus.HANGUP) {
      this.setDefaultValues();
    }

    this.call.changeCall(status).subscribe();
  }

  onCollapse(): void {
    this.resize(OverlayPositionType.TOP, false, true);
  }

  onRestore(): void {
    this.resize(OverlayPositionType.CENTER, true, false);
  }

  @HostListener('dblclick')
  onHostDoubleClick(): void {
    this.isFullscreen$.pipe(take(1)).subscribe(isFullscreen => {
      return this.onFullScreen(!isFullscreen);
    });
  }

  toggleVolume(): void {
    const volume = this.call.volume$.value === 0 ? 1 : 0;
    this.setVolume(volume);
  }

  setDefaultValues(): void {
    this.call.volume$.next(1);
  }

  onFullScreen(enable?: boolean): void {
    if (enable) {
      this.stateService.openInFullscreen(this.elementRef, 'landscape-primary').catch(() => {
        const video = document.createElement('video') as any;
        this.foregroundVideoRemoteStream$
          .pipe(
            untilDestroyed(this),
            takeUntil(fromEvent(video, 'webkitendfullscreen')),
            takeWhile(mediaStream => {
              return mediaStream.getTracks().length > 0;
            }),
            map(stream => {
              return (video.srcObject = stream);
            }),
            switchMap(() => {
              return video.play();
            }),
            finalize(() => {
              video.webkitExitFullscreen();
            })
          )
          .subscribe(() => {
            video.webkitEnterFullScreen();
          });
      });
    } else {
      this.stateService.exitFullscreen().then();
    }
  }

  resize(position: OverlayPositionType, modal: boolean, draggable = false): void {
    this.changeOverlayContainer.emit({
      position,
      modal,
      draggable
    });
  }

  onOpenSettings(): void {
    const currentMedia: { audio: boolean; video: boolean; volume: boolean } = {
      audio: this.isSelected(CallContentType.FACING_AUDIO),
      video: this.isSelected(CallContentType.FACING_VIDEO),
      volume: this.remoteAudio.nativeElement.volume !== 0
    };

    this.call.isBlurEnable$
      .pipe(
        take(1),
        switchMap(isBlurEnable => {
          return this.webrtcDialogService
            .requestUserPreConferenceSettings(this.destroyed$, false, currentMedia, { blur: isBlurEnable })
            .pipe(
              take(1),
              switchMap(({ sources, mediaToggles, effectsSdkSetting }) => {
                this.mediaDevicesService.isCallBlurEnabled$.next(effectsSdkSetting?.blur ?? false);
                if (!Array.isArray(sources)) {
                  return EMPTY;
                }

                this.setToggles({ audio: mediaToggles.audio, video: mediaToggles.video, volume: mediaToggles.volume });

                this.mediaDevicesService.setDefaultMediaSourcesIds(sources, true);
                const audioOutput = sources.find(source => {
                  return source.kind === MediaSourceKind.AUDIO_OUTPUT;
                });
                this.mediaDevicesService.dynamicMediaOutputDevice$.next(audioOutput);

                if (typeof (<any>this.remoteAudio.nativeElement).setSinkId === 'function') {
                  (<any>this.remoteAudio.nativeElement).setSinkId(audioOutput.id);
                }

                return this.defaultSources$.pipe(take(1));
              })
            );
        })
      )
      .subscribe(defaultSources => {
        Array.from(this.selectedSources.entries()).forEach(([contentType, source]) => {
          let newDefaultSource: UserMediaSource;
          switch (contentType) {
            case CallContentType.FACING_VIDEO:
              newDefaultSource = defaultSources.find(source_ => {
                return source_.kind === MediaSourceKind.VIDEO_INPUT && source_.sourceType === DeviceSourceType.HARDWARE;
              });
              break;
            case CallContentType.FACING_AUDIO:
              newDefaultSource = defaultSources.find(source_ => {
                return source_.kind === MediaSourceKind.AUDIO_INPUT;
              });
              break;
          }

          if (!newDefaultSource) {
            this.selectedSources.delete(contentType);
            return;
          }
          if (this.mediaDevicesService.compareSources(newDefaultSource, source.mediaSource)) {
            return;
          }

          this.selectedSources.set(contentType, <CallLocalMediaSource>{
            ...source,
            mediaSource: newDefaultSource,
            resolvedMediaSource: null
          });
        });
        this.call.setCallMediaSources(Array.from(this.selectedSources.values()));
      });
  }

  setToggles({ video, audio, volume }): void {
    const volumeValue = this.call.volume$.value;

    this.onChangeAudio(audio);
    this.onChangeVideo(video, CallContentType.FACING_VIDEO);
    this.setVolume(volume ? (volumeValue > 0 ? volumeValue : 1) : 0, !!volume);
  }

  onChangeAudio(enable: boolean): void {
    if (enable) {
      this.defaultSources$.pipe(take(1)).subscribe(sources => {
        const result = sources.find(source => {
          return source.kind === MediaSourceKind.AUDIO_INPUT;
        });
        if (!!result) {
          this.addUserMediaSource({ mediaSource: result, contentType: CallContentType.FACING_AUDIO });
        }
      });
    } else {
      this.removeUserMediaSource(CallContentType.FACING_AUDIO);
    }
  }

  onChangeVideo(enable: boolean, callContentType: CallContentType): void {
    if (enable) {
      if (callContentType === CallContentType.FACING_VIDEO) {
        this.defaultSources$.pipe(take(1)).subscribe(sources => {
          const result = sources.find(source => {
            return source.kind === MediaSourceKind.VIDEO_INPUT;
          });
          if (!!result) {
            this.addUserMediaSource({ mediaSource: result, contentType: CallContentType.FACING_VIDEO });
          }
        });
      } else {
        this.availableSources$
          .pipe(
            map(sources => {
              return sources.find(source => {
                return source.kind === MediaSourceKind.VIDEO_INPUT && source.sourceType === DeviceSourceType.SCREEN;
              });
            }),
            take(1)
          )
          .subscribe(source => {
            return this.addUserMediaSource({ mediaSource: source, contentType: CallContentType.SCREEN_VIDEO });
          });
      }
    } else {
      this.removeUserMediaSource(callContentType);
    }
  }

  isSelected(contentType: CallContentType): boolean {
    const source = this.selectedSources.get(contentType);
    return !!source;
  }

  addUserMediaSource(callMediaSource: CallLocalMediaSource): void {
    this.selectedSources.set(callMediaSource.contentType, callMediaSource);
    this.call.setCallMediaSources(Array.from(this.selectedSources.values()));
  }

  removeUserMediaSource(callContentType: CallContentType): void {
    this.selectedSources.delete(callContentType);
    this.call.setCallMediaSources(Array.from(this.selectedSources.values()));
  }

  tryRestore(): void {
    if (this.isSmartOverlayContainer) {
      this.onRestore();
    }
  }

  isPlaying(element: HTMLMediaElement): boolean {
    return !!(element.currentTime > 0 && !element.paused && !element.ended && element.readyState > 2);
  }

  visitChat(): void {
    this.call.callModel$
      .pipe(
        withLatestFrom(this.authService.currentUser$),
        map(([callModel, currentUser]) => {
          return currentUser.id === callModel.answererId
            ? ((<any>callModel.caller).userid as number)
            : callModel.answererId;
        }),
        switchMap(interlocutorId => {
          return this.chatService.getChatByParticipantsAndConferenceIds([interlocutorId]).pipe(
            toClass(FoundChat),
            map(response => {
              const foundChat = response.chat;
              if (foundChat) {
                return foundChat.id;
              }

              const tempId = guid();
              const participantIds = [interlocutorId];
              this.store.dispatch(ChatActions.createChat({ participantIds, tempId }));
              return tempId;
            })
          );
        })
      )
      .subscribe(chatId => {
        this.onCollapse();
        this.chatService.openChat(chatId);
      });
  }
}
