import { environment } from '@breez/environment';
import { Expose, Transform, Type } from 'class-transformer';
import * as uuid from 'uuid';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const reduceVersion = ({ value }) => {
  return typeof value !== 'string'
    ? value
    : value
        .split(' ')
        .map((part, index, array) => {
          return index === array.length - 1 ? part.slice(0, 8) : part;
        })
        .join(' ');
};

export class EnvironmentAbstractionData {
  @Expose({ name: 'name' })
  name: string;

  @Expose({ name: 'version' })
  version: number;

  constructor(base: Partial<EnvironmentAbstractionData>) {
    return Object.assign(this, base);
  }
}

export class EnvironmentData {
  @Expose({ name: 'os' })
  @Type(() => {
    return EnvironmentAbstractionData;
  })
  os: EnvironmentAbstractionData;

  @Expose({ name: 'browser' })
  @Type(() => {
    return EnvironmentAbstractionData;
  })
  browser: EnvironmentAbstractionData;

  @Expose({ name: 'application' })
  @Transform(({ value }) => {
    return value ? value.name : null;
  })
  applicationVersion;

  @Expose({ name: 'buildtype' })
  buildType;

  constructor(base: Partial<EnvironmentData>) {
    return Object.assign(this, base);
  }
}

export class ClientIdentification {
  @Expose({ name: 'useragent' })
  userAgent: string;

  @Expose({ name: 'clientversion' })
  @Transform(reduceVersion, { toClassOnly: true })
  clientVersion: string;

  @Expose({ name: 'clientid' })
  clientId: string;

  @Expose({ name: 'clientenvironment' })
  @Type(() => {
    return EnvironmentData;
  })
  environment: EnvironmentData;

  constructor(base: Partial<ClientIdentification>) {
    Object.assign(this, base);

    if (!this.clientId) {
      this.clientId = uuid.v4();
    }

    if (!this.clientVersion) {
      this.clientVersion = environment.version;
    }

    if (!this.userAgent) {
      this.userAgent = navigator.userAgent;
    }

    return this;
  }
}

export class ClientInfo extends ClientIdentification {
  @Expose({ name: 'ipaddress' })
  ipAddress: string;
}

export class TerminalInfo {
  /*
   * приём -> от клиента к серверу (fromclient)
   * передача -> от сервера к клиенту (fromserver)
   */
  @Expose({ name: 'fromserver' })
  transmittingData: { [key: string]: string };

  @Expose({ name: 'fromclient' })
  receivingData: { [key: string]: string };

  @Expose({ name: 'mimeaudio' })
  audioCodec: string;

  @Expose({ name: 'mimevideo' })
  videoCodec: string;

  @Expose({ name: 'terminalid' })
  terminalId: string;
}

export class ClientStats {
  @Expose({ name: 'session' })
  @Type(() => {
    return ClientInfo;
  })
  clientInfo: ClientInfo;

  @Expose({ name: 'terminal' })
  @Type(() => {
    return TerminalInfo;
  })
  terminalInfo: TerminalInfo;
}

export interface KindTerminalInfo {
  receive: { [key: string]: string };
  transmit: { [key: string]: string };
}
