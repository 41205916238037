import { provideCallStatusComponent } from '@breez/modules/call/call-statuses/call-status-component.provider';
import { CallStatusRingIncomingComponent } from './components';
import { CallDestinationType, CallStatus } from '@breez/modules/call/models';

export const OVERLAY_PROVIDERS = [
  provideCallStatusComponent(
    {
      status: CallStatus.RING,
      destinationType: CallDestinationType.INCOMING
    },
    CallStatusRingIncomingComponent
  )
];
