export function enumToArray<T extends string, TEnumValue extends number | string>(enumVariable: {
  [key in T]: TEnumValue;
}): string[] {
  return Object.keys(enumVariable).filter(key => {
    return !!enumVariable[key];
  });
}

export function getEnumByValue<T>(myEnum: T, enumValue): T {
  const key = Object.keys(myEnum).find(x => {
    return myEnum[x] === enumValue;
  });
  return !!key ? myEnum[key] : null;
}

export function getEnumKeyByValue<T>(myEnum: T, enumValue): string {
  const key = Object.keys(myEnum).find(x => {
    return myEnum[x] === enumValue;
  });
  return !!key ? key : null;
}

export function createEnumChecker<T extends string, TEnumValue extends string>(enumVariable: {
  [key in T]: TEnumValue;
}) {
  const enumValues = Object.values(enumVariable);
  return (value: string): value is TEnumValue => {
    return enumValues.includes(value);
  };
}
