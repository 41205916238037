import { Expose } from 'class-transformer';

export class Link {
  @Expose({ name: 'linkid' })
  id: number;

  @Expose({ name: 'ownerid' })
  ownerId: number;

  @Expose({ name: 'name' })
  name: string;

  @Expose({ name: 'url' })
  url: string;

  @Expose({ name: 'visibleforguest' })
  visibleForGuest: boolean;
}
