import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { AppService } from '@breez/app.service';
import { environment } from '@breez/environment';
import { User } from '@breez/models';
import { ADVANCED_MODE_TEMPLATE } from '@breez/models/template/advanced-mode-template';
import { IConferenceTemplateInfo } from '@breez/models/template/conference-template-info.model';
import { AuthService } from '@breez/modules/auth/services/auth.service';
import { ConferencePlannerService } from '@breez/modules/conference/modules/planner/services/conference-planner/conference-planner.service';
import { TemplatesInfo } from '@breez/modules/conference/modules/planner/services/conference-templates-info/templates-info.service';
import { BuildType } from '@breez/shared/enums/build-type.enum';
import { StateService } from '@breez/shared/services/state.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { replayWhileSubs } from '@breez/shared/rxjs-operators';
import { combineLatest, merge, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';
import { SettingsService } from '@breez/modules/settings/services/settings.service';

@Component({
  selector: 'vks-header',
  templateUrl: './header.component.html',
  styleUrls: [
    './header.component.scss',
    './header.component.media-max975.scss',
    './header.component.media-max450.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {
  readonly isFnsBuild = environment.buildType === BuildType.FNS;

  menuBurgerIconClass = 'square32';
  isMobile: boolean = this.appService.isMobile;

  advancedModeTemplate = ADVANCED_MODE_TEMPLATE;

  title$: Observable<string> = this.stateService.currentTitle$;
  subtitle$: Observable<string> = this.stateService.currentSubtitle$;

  currentUser$: Observable<User> = this.authService.currentUser$;

  isMobileDevice$: Observable<boolean> = this.stateService.isMobileDevice$;

  hideSideBar$: Observable<boolean> = this.stateService.hideSideBar$;

  hasConferenceAddRoute$: Observable<boolean> = this.router.events.pipe(
    filter(event => {
      return event instanceof NavigationEnd;
    }),
    map(() => {
      return location.pathname.includes('/conference/add') || location.hash.includes('#/conference/add');
    }),
    startWith(false)
  );

  isLoginPage$: Observable<boolean> = merge(this.router.events, this.authService.currentUser$).pipe(
    map(() => {
      return location.pathname.includes('/auth/login');
    }),
    replayWhileSubs()
  );

  showPlannerButtons$: Observable<boolean> = this.hasConferenceAddRoute$.pipe(
    map(hasConferenceAddRoute => {
      return !hasConferenceAddRoute;
    }),
    startWith(false),
    replayWhileSubs()
  );

  templateInfos$: Observable<IConferenceTemplateInfo[]> = this.templatesInfo$.pipe(
    map(templatesInfo => {
      return templatesInfo.filter(templateInfo => {
        return templateInfo.title !== null;
      });
    }),
    replayWhileSubs()
  );

  showConferencePlannerLink$: Observable<boolean> = combineLatest([
    this.showPlannerButtons$,
    this.templateInfos$,
    this.authService.currentUser$
  ]).pipe(
    map(([showPlannerButtons, templates]) => {
      return showPlannerButtons && templates.length > 1;
    })
  );

  showPlannerButtonForSingleTemplate$: Observable<boolean> = combineLatest([
    this.showPlannerButtons$,
    this.templateInfos$,
    this.authService.currentUser$
  ]).pipe(
    map(([showPlannerButtons, templates]) => {
      return showPlannerButtons && templates.length === 1;
    })
  );

  selectedLang$: Observable<string> = this.translateService.onLangChange.pipe(
    map((params: LangChangeEvent) => {
      return params.lang;
    }),
    startWith(this.translateService.currentLang),
    replayWhileSubs()
  );

  menuBurgerIcon$ = of('breez-app-en').pipe(distinctUntilChanged(), replayWhileSubs());

  canUseAdvancedMode$: Observable<boolean> = this.authService.roles$.pipe(
    filter(() => {
      return !this.isFnsBuild;
    }),
    map(roles => {
      return roles.some(role => {
        return role === 'planner:confparam:tuneparams';
      });
    }),
    startWith(false)
  );

  singleTemplateId$: Observable<number> = this.templateInfos$.pipe(
    filter(templates => {
      return templates.length === 1;
    }),
    map(([template]) => {
      return template.conferencetemplateid;
    })
  );

  currentRouteQueryParams$: Observable<Params> = this.activatedRoute.queryParams;

  constructor(
    @Inject(TemplatesInfo) private readonly templatesInfo$: Observable<IConferenceTemplateInfo[]>,
    private router: Router,
    private authService: AuthService,
    private stateService: StateService,
    public appService: AppService,
    private conferencePlannerService: ConferencePlannerService,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private settingsService: SettingsService
  ) {}

  showLoginPopup(): void {
    this.authService.authLogin();
  }

  logout(): void {
    this.authService.logout();
  }

  openMenu(): void {
    this.stateService.openMenu();
    this.stateService.showLargeMenu();
  }

  createConference(): void {
    this.conferencePlannerService.openCreateConferencePopup();
  }

  userSettings(): void {
    this.settingsService.openUserSettingsPopup();
  }
}
