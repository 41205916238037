import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { ValidationError } from './validation-error.model';

const ERROR = {
  type: 'required',
  label: 'FIELD_REQUIRED'
} as ValidationError;

export function RequiredWithWhitespaceValidator(customLabel?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const controlValue = control.value;

    if (isTruthy(controlValue) && controlValue.toString().trim().length > 0) {
      return null;
    }

    return { [ERROR.type]: !!customLabel ? { ...ERROR, label: customLabel } : ERROR } as ValidationErrors;
  };
}
