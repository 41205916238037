import { InjectionToken, Provider, Type } from '@angular/core';
import { NgxdProvider } from '@ngxd/core';
import { OverlayEntryType } from '@breez/modules/overlay/models/overlay-entry-type.enum';

export interface OverlayEntryTypeProvider extends NgxdProvider<OverlayEntryType, Type<any>> {}

export const OVERLAYENTRY_PROVIDER = new InjectionToken<OverlayEntryTypeProvider>('Overlay entry Provider');

export function provideOverlayEntryType(type: OverlayEntryType, component: Type<any>): Provider {
  return {
    provide: OVERLAYENTRY_PROVIDER,
    useValue: <OverlayEntryTypeProvider>{
      type,
      component
    },
    multi: true
  };
}
