import * as RangeState from '@breez/modules/chat/+state/viewRange/viewRange.state';
import { Action, createReducer, on } from '@ngrx/store';
import * as RangeActions from '@breez/modules/chat/+state/viewRange/viewRange.actions';
import { isTruthy } from '@breez/shared/utilities/is-truthy';

const reducer = createReducer(
  RangeState.initialState,
  on(RangeActions.updateRange, (state, action) => {
    return !isTruthy(action.range.anchorIndex) ? RangeState.adapter.upsertOne(action.range, state) : state;
  })
);

export function viewRangeReducer(state: RangeState.State | undefined, action: Action): RangeState.State {
  return reducer(state, action);
}
