import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReconnectRequestComponent } from '@breez/components/reconnect-request/reconnect-request.component';
import { FeaturesAvailabilityModel } from '@breez/models';
import { WebsocketEvents, WebsocketService } from '@breez/modules/websocket';
import { ClientIdentification, EnvironmentData } from '@breez/shared/models/environment-data.model';
import { distinctUntilChangedByJsonCompare, replayWhileSubs, toPlain } from '@breez/shared/rxjs-operators';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { TranslateService } from '@ngx-translate/core';
import { FileSaverService } from 'ngx-filesaver';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { filter, pluck, shareReplay, startWith, switchMap, take, tap } from 'rxjs/operators';
import { environment } from '@breez/environment';
import { LocalStorage } from '@breez/shared/modules/storage/interfaces/local-storage.interface';
import { RestApiService } from '@breez/rest-api.service';
import { BuildType } from '@breez/shared/enums/build-type.enum';
import { ElectronService } from './modules/core/services';
import { AppInfoConferenceModel, AppInfoMaintenanceModel, AppInfoModel } from '@breez/models/app-info.model';
import { AuthService } from './modules/auth/services/auth.service';
import { guid } from '@breez/helpers/guid';
import { LoggerService } from '@breez/shared/services/logger.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  readonly currentConferenceEnterIdSubject$: ReplaySubject<number> = new ReplaySubject<number>(1);
  readonly isElectronApp: boolean = this.electronService.isElectron;
  private readonly clientIdSubject$: ReplaySubject<string> = new ReplaySubject<string>(1);

  readonly clientId$: Observable<string> = this.clientIdSubject$.pipe(shareReplay(1));

  private readonly ident$: Observable<ClientIdentification> = this.restApiService.ident$.pipe(shareReplay(1));

  readonly isFnsBuild: boolean = environment.buildType === BuildType.FNS;
  readonly isMobile: boolean = this.detectMobileDevice();
  readonly isSafari: boolean = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  readonly isAndroid: boolean = /Android/i.test(navigator.userAgent);
  readonly isMac: boolean = /(Mac)/i.test(navigator.platform);
  readonly pictureInPictureSupport: boolean = (<any>document).pictureInPictureEnabled;
  readonly browserRecommendation$: ReplaySubject<number> = new ReplaySubject<number>();
  readonly closeNotSupportPage$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  readonly servicePeriod$: Observable<AppInfoMaintenanceModel> = this.restApiService.info$.pipe(
    pluck<AppInfoModel, 'maintenance'>('maintenance'),
    filter(isTruthy),
    distinctUntilChangedByJsonCompare(),
    replayWhileSubs()
  );

  readonly conferenceSettings$: Observable<AppInfoConferenceModel> = this.restApiService.info$.pipe(
    pluck<AppInfoModel, 'conference'>('conference'),
    filter(isTruthy),
    startWith({ create_disabled: true }),
    distinctUntilChangedByJsonCompare(),
    replayWhileSubs()
  );

  readonly environment: EnvironmentData = this.restApiService.environment;
  readonly isFireFox: boolean = this.environment.browser.name.toLowerCase() === 'firefox'; //Output audio devices empty

  constructor(
    @Inject('FILE_TOKEN_KEY') private fileTokenKey: string,
    private wsService: WebsocketService,
    private authService: AuthService,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private fileSaverService: FileSaverService,
    private electronService: ElectronService,
    private localStorage: LocalStorage,
    private restApiService: RestApiService,
    private loggerService: LoggerService
  ) {
    this.wsService.status$
      .pipe(
        filter(isConnected => {
          return isConnected;
        }),
        switchMap(() => {
          return this.wsService.connectionWasLost$.pipe(take(1));
        }),
        switchMap(connectionWasLost => {
          if (connectionWasLost) {
            const ident = this.restApiService.createIdent();
            this.restApiService.ident$.next(ident);
            return of(ident);
          }
          return this.ident$;
        }),
        tap(ident => {
          return (this.clientId = ident.clientId);
        }),
        toPlain(),
        switchMap(data => {
          return this.wsService.send<string>(WebsocketEvents.SEND.SESSION.IDENTIFICATION, { data });
        }),
        tap(ident => {
          this.localStorage.setItem(this.fileTokenKey, ident);
        })
      )
      .subscribe();

    this.wsService
      .on<boolean>(WebsocketEvents.RECEIVE.SESSION.CLOSED)
      .pipe(untilDestroyed(this, 'destroy'))
      .subscribe(() => {
        if (this.isElectronApp) {
          this.authService.keycloakLogout();
        } else {
          window.location.reload();
        }
      });

    this.wsService
      .on<boolean>(WebsocketEvents.RECEIVE.SESSION.LIMIT)
      .pipe(
        untilDestroyed(this, 'destroy'),
        switchMap(() => {
          return this.translateService.get('SESSION_LIMIT_ALERT');
        })
      )
      .subscribe(message => {
        this.loggerService.warn(message);
      });
  }

  private _clientId: string;

  get clientId(): string {
    return this._clientId;
  }

  set clientId(value: string) {
    this._clientId = value;
    this.clientIdSubject$.next(value);
  }

  destroy(): void {}

  checkFeaturesAvailability(): FeaturesAvailabilityModel {
    if (
      !!navigator.userAgent.match(/Trident/g) ||
      !!navigator.userAgent.match(/MSIE/g) ||
      !!navigator.userAgent.match(/Edge/g)
    ) {
      return {
        interface: false,
        webrtc: false
      };
    }

    return {
      interface: true,
      webrtc: true
    };
  }

  isMobileIosSafari(): boolean {
    const mobileIosMatch = [/iPhone/i, /iPad/i];

    const safariMatch = /safari/i;

    const isMobileIos = mobileIosMatch.some(toMatchItem => {
      return navigator.userAgent.match(toMatchItem);
    });

    const isSafari = !!navigator.userAgent.match(safariMatch);

    return isMobileIos ? isSafari : false;
  }

  getLogoPngUrl(): string {
    return 'assets/icons/en/icon-128x128.png';
  }

  saveFileByByteArray(data: Uint8Array, name: string): void {
    const blob = new Blob([data], { type: 'octet/stream' });
    this.fileSaverService.save(blob, name);
  }

  newGuid(): string {
    return guid();
  }

  private waitForUserReconnectionRequest$(messageString: string, translate = false): Observable<boolean> {
    return (translate ? this.translateService.get(messageString) : of(messageString)).pipe(
      switchMap(message => {
        return this.dialog
          .open(ReconnectRequestComponent, {
            data: { message }
          })
          .afterClosed();
      })
    );
  }

  private detectMobileDevice(): boolean {
    const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

    let isMobile = toMatch.some(toMatchItem => {
      return navigator.userAgent.match(toMatchItem);
    });

    if (!isMobile) {
      if (navigator.userAgent.indexOf('Macintosh') > -1) {
        try {
          document.createEvent('TouchEvent');
          isMobile = true;
        } catch {}
      }
    }

    return isMobile;
  }
}
