import { Directive, HostListener, HostBinding } from '@angular/core';
@Directive({
  selector: '[vksTrackFocus]'
})
export class TrackFocusDirective {
  @HostBinding('class.vks-focused') isFocused: boolean;

  @HostListener('focus', ['$event']) onFocus(): void {
    this.isFocused = true;
  }

  @HostListener('blur', ['$event']) onblur(): void {
    this.isFocused = false;
  }
}
