import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'existInArray'
})
export class ExistInArrayPipe<T> implements PipeTransform {
  transform(searchedValue: T, array: T[]): boolean {
    return array.indexOf(searchedValue) > -1;
  }
}
