import { AbstractControl } from '@angular/forms';
import { defer, Observable } from 'rxjs';
import { debounceTime, startWith } from 'rxjs/operators';
import { isTruthy } from '@breez/shared/utilities/is-truthy';

export function fromControl<T = any>(control: AbstractControl, debounce?: number): Observable<T> {
  if (isTruthy(debounce)) {
    return defer(() => {
      return control.valueChanges.pipe(debounceTime(debounce), startWith(<T>control.value));
    });
  }
  return defer(() => {
    return control.valueChanges.pipe(startWith(<T>control.value));
  });
}
