import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { ParticipationIntervalsInfoRequest } from '@breez/models/shared/participation-intervals-info';
import { ObjectService } from '@breez/shared/services/object.service';
import { Observable, ReplaySubject } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import ruLocale from '@fullcalendar/core/locales/ru';
import { EventObject } from '@breez/shared/components/events-calendar-new/events-calendar-new.component';
import { CalendarOptions, DatesSetArg } from '@fullcalendar/core';

@Component({
  selector: 'vks-participation-intervals-info',
  templateUrl: './participation-intervals-info.component.html',
  styleUrls: ['./participation-intervals-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParticipationIntervalsInfoComponent {
  initialView: 'month' | 'basicWeek' | 'basicDay' | 'timeGridDay' = 'timeGridDay';
  calendarOptions: CalendarOptions = {
    locales: [ruLocale],
    eventTimeFormat: {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    },
    headerToolbar: {
      left: 'prev title next',
      right: '',
      center: ''
    },
    height: '100%',
    initialView: this.initialView,
    allDaySlot: false,
    // slotMinTime: this.dialogInputData.defaultConfig,
    // slotMaxTime: '20:00:00',
    slotLabelFormat: {
      hour: '2-digit',
      minute: '2-digit'
    },
    initialDate: this.dialogInputData.request.startdate,
    ...this.dialogInputData.defaultConfig
  };

  events$: Observable<EventObject[]> = this.getEvents(this.dialogInputData.request);
  calendarOptions$: Observable<any> = this.events$.pipe(
    map(events => {
      return { ...this.calendarOptions, events };
    })
  );

  eventsAdd$: ReplaySubject<EventObject[]> = new ReplaySubject<EventObject[]>();

  constructor(
    private objectService: ObjectService,
    private dialogRef: MatDialogRef<ParticipationIntervalsInfoComponent>,
    @Inject(MAT_DIALOG_DATA)
    public dialogInputData: {
      request: ParticipationIntervalsInfoRequest;
      defaultConfig: CalendarOptions;
    }
  ) {}

  getEvents(request: ParticipationIntervalsInfoRequest): Observable<EventObject[]> {
    return this.objectService.getParticipationIntervalsInfo(request).pipe(
      map(response => {
        return {
          data: response.data
            .filter(row => {
              return row.users.length > 0;
            })
            .reduce((acc, item) => {
              item.users = item.users.filter((uid, pos, self) => {
                return self.indexOf(uid) === pos;
              });
              if (acc.length === 0) {
                acc.push(item);
              } else {
                const last = acc[acc.length - 1];
                if (
                  last.endDate?.getTime() === item.startDate?.getTime() &&
                  JSON.stringify(last.users.sort()) === JSON.stringify(item.users.sort())
                ) {
                  last.endDate = item.endDate;
                } else {
                  acc.push(item);
                }
              }
              return acc;
            }, []),
          duration: response.duration,
          users: new Map<number, string>(
            response.users.map(user => {
              return [user.id, user.name];
            })
          )
        };
      }),
      map(({ data, duration, users }) => {
        return data.map(row => {
          const title = row.users
            .map(userId => {
              return users.get(userId);
            })
            .sort()
            .join(', ');
          return {
            id: row.startDate?.getTime() + title,
            title,
            allDay: duration === 1440,
            start: row.startDate,
            end: row.endDate,
            editable: false,
            backgroundColor: 'rgba(235, 87, 87, 0.15)',
            borderColor: 'rgba(235, 87, 87, 0.7)'
          };
        });
      })
    );
  }

  close(): void {
    this.dialogRef.close();
  }

  onNewDate($event: DatesSetArg): void {
    this.getEvents({ ...this.dialogInputData.request, startdate: $event.start, enddate: $event.end })
      .pipe(
        take(1),
        tap(newEvents => {
          return this.eventsAdd$.next(newEvents);
        })
      )
      .subscribe();
  }
}
