<ng-container *ngFor="let container of displayContainers$ | async; trackBy: trackContainerFunction">
  <div
    #containerElement
    [@.disabled]="container.position !== 'side' && container.position !== 'top'"
    [@fadeOut]
    [ngClass]="generateHostClass(container.position)"
    (click)="handleClick($event, containerElement)"
    [ngStyle]="styleOverlay(container)"
    cdkDrag
    [cdkDragDisabled]="!container.draggable"
    (cdkDragStarted)="startDragging($event)"
    (cdkDragEnded)="stopDragging($event)"
    cdkDragBoundary="vks-root"
  >
    <vks-overlay-entry-dynamic
      *ngFor="let entry of container.entries; trackBy: trackEntryFunction"
      [@listAnimation]
      [backdropClickTrigger]="backdropClicked$"
      [entry]="entry"
      (changeOverlayContainer)="onChangeOverlayContainer(entry, $event)"
    ></vks-overlay-entry-dynamic>
  </div>
</ng-container>
