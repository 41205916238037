<div [class.full-width]="isTabletDevice$ | async" class="app-layout">
  <nav
    *ngIf="tabsActivate$ | async"
    mat-tab-nav-bar
    [tabPanel]="tabPanel"
    color="primary"
    class="vks-navigations-tabs"
    cdkScrollable
    [disablePagination]="true"
  >
    <a
      mat-tab-link
      #linkTabsElement
      *ngFor="let linkTab of availableTabs$ | async; index as i"
      [queryParams]="linkTab.params"
      [routerLink]="linkTab.link"
      [active]="isLinkActive(linkTab.params, linkTab.link, i) | async"
      >{{ linkTab.label | translate }}</a
    >
  </nav>
  <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
  <ng-content></ng-content>
</div>
