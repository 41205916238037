import { InjectionToken, Provider, Type } from '@angular/core';
import { NgxdProvider } from '@ngxd/core';
import { NotificationType } from '@breez/models/notification/notification-type.enum';

export interface NotificationTypesComponentProvider extends NgxdProvider<NotificationType, Type<any>> {}

export const NOTIFICATIONTYPES_PROVIDER = new InjectionToken<string>('Notification type Provider');

export function provideNotificationTypeComponent(type: NotificationType, component: Type<any>): Provider {
  return {
    provide: NOTIFICATIONTYPES_PROVIDER,
    useValue: <NotificationTypesComponentProvider>{
      type,
      component
    },
    multi: true
  };
}
