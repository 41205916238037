<vks-modal-header [title]="headerTitle | translate" (close)="close()"></vks-modal-header>
<vks-text-reducer [text]="title" class="title"></vks-text-reducer>
<p class="subtitle">{{ subtitle }}</p>
<ng-container *ngIf="users.length > 0; else noUsers">
  <div class="user-tree">
    <vks-user-tree
      [allowExpand]="false"
      [divisionsList]="[]"
      [multiSelect]="false"
      [selectable]="false"
      [userList]="users"
    ></vks-user-tree>
  </div>
  <p class="stub middle">{{ 'TOTAL' | translate }}: {{ users.length }}</p>
</ng-container>
<ng-template #noUsers>
  <p class="stub middle">{{ 'NO_USERS' | translate }}</p>
</ng-template>
