import { Expose, Transform } from 'class-transformer';
import { fixDateDeserialization } from '@breez/shared/utilities/fixDateDeserialization';
import { ConferenceLayoutFrameModel } from '@breez/models/conference/layout/conference-layout-frame.model';

export class ConferenceLayoutModel {
  @Expose({ name: 'conferencelayoutid' })
  conferenceLayoutId: string;

  @Expose()
  name: string;

  @Expose()
  description?: string;

  @Expose({ name: 'ownerid' })
  ownerId?: number;

  @Expose({ name: 'createdon' })
  @Transform(({ value }) => {
    return value ? new Date(fixDateDeserialization(value)) : undefined;
  })
  createdOn?: Date;

  @Expose({ name: 'conferenceid' })
  conferenceId?: number;

  @Expose()
  height: number;

  @Expose()
  width: number;

  @Expose({ name: 'greatestcommondivisorforresolution' })
  greatestCommonDivisorForResolution: {
    height: number;
    width: number;
  };

  @Expose({ name: 'framescount' })
  framesCount: number;

  @Expose({ name: 'oldlayoutid' })
  oldLayoutId: number;

  @Expose({ name: 'canuseautolayout' })
  canUseAutoLayout: boolean;

  frames?: ConferenceLayoutFrameModel[];
}
