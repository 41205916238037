import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { ControlsModule } from '@breez/modules/controls/controls.module';
import { PermissionsModule } from '@breez/modules/permissions/permissions.module';

import { ControlLanguageSelectorComponent } from '@breez/shared/components/control-language-selector';
import { EventsCalendarComponent } from '@breez/shared/components/events-calendar/events-calendar.component';
import { ParticipantRightsSelectorComponent } from '@breez/shared/components/participant-rights-selector';
import { UiControlsModule } from '@breez/shared/modules/ui-controls/ui-controls.module';
import { FiltersService } from '@breez/shared/services/filters/filters.service';
import { TranslateModule } from '@ngx-translate/core';
import { allSharedLinks } from '@breez/shared/shared-buttons';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TooltipModule } from 'ng2-tooltip-directive';
import { ImageCropperModule } from 'ngx-image-cropper';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { QuillModule } from 'ngx-quill';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { UserAvatarModule } from '../modules/user/user-avatar/user-avatar.module';
import { UserTreeModule } from '../modules/user/user-tree/user-tree.module';
import { AudioPlayerComponent } from './components/audio-player/audio-player.component';

import { Base64FileSaverComponent } from './components/base64-file-saver';
import { ConfirmPopupComponent } from './components/confirm-popup/confirm-popup.component';
import { CountdownTimerComponent } from './components/countdown-timer';
import { DateRangeFilterComponent } from './components/filters/date-range-filter/date-range-filter.component';
import { DateRangeComponent } from './components/filters/date-range-filter/date-range/date-range.component';
import { FiltersFactoryComponent } from './components/filters/filters-factory/filters-factory.component';
import { SelectOptionFilterComponent } from './components/filters/select-oprtion-filter/select-option-filter.component';
import { GridLineComponent } from './components/grid-line/grid-line.component';
import { GridListComponent } from './components/grid-list/grid-list.component';
import { ImageCropperComponent } from './components/image-cropper';
import { ImageComponent } from './components/image/';
import { LayoutComponent } from './components/layout';
import { LoginPopupComponent } from './components/login-popup/';
import { ModalHeaderComponent } from './components/modal-header';
import { ModuleHeaderComponent } from './components/module-header';
import { PopUpHeaderComponent } from './components/pop-up-header/pop-up-header.component';
import { ScreensharePickerComponent } from './components/screenshare-picker/screenshare-picker.component';
import { ScrollControllerComponent } from './components/scroll-controller';
import { SharedButtonsComponent } from './components/shared-buttons/shared-buttons.component';
import { TextReducerComponent } from './components/text-reducer/text-reducer.component';
import { UserListModalComponent } from './components/user-list-modal/user-list-modal.component';
import { PipesModule } from './pipes/pipes.module';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ParticipationIntervalsInfoComponent } from '@breez/shared/components/participation-intervals-info/participation-intervals-info.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { EventsCalendarNewComponent } from './components/events-calendar-new/events-calendar-new.component';
import { DebounceClickDirective } from './directives/debounce-click.directive';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { TrackFocusDirective } from './directives/track-focus.directive';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { CanComponentDeactivateGuard } from '@breez/shared/guards';
import { SelectServerComponent } from './components/select-server/select-server.component';
import { ControlLanguageSelectorMenuComponent } from './components/vks-control-language-selector-menu/vks-control-language-selector-menu.component';
import { LoadingConditionDirective } from './directives/loading-condition.directive';

@NgModule({
  declarations: [
    EventsCalendarComponent,
    ModuleHeaderComponent,
    CountdownTimerComponent,
    ModalHeaderComponent,
    GridLineComponent,
    PopUpHeaderComponent,
    SelectOptionFilterComponent,
    ImageComponent,
    LoginPopupComponent,
    SharedButtonsComponent,
    FiltersFactoryComponent,
    DateRangeFilterComponent,
    DateRangeComponent,
    ImageCropperComponent,
    LayoutComponent,
    Base64FileSaverComponent,
    ControlLanguageSelectorComponent,
    ScrollControllerComponent,
    AudioPlayerComponent,
    TextReducerComponent,
    UserListModalComponent,
    ParticipationIntervalsInfoComponent,
    ParticipantRightsSelectorComponent,
    ScreensharePickerComponent,
    GridListComponent,
    ConfirmPopupComponent,
    EventsCalendarNewComponent,
    DebounceClickDirective,
    AutoFocusDirective,
    TrackFocusDirective,
    SelectServerComponent,
    ControlLanguageSelectorMenuComponent,
    LoadingConditionDirective
  ],
  exports: [
    EventsCalendarComponent,
    EventsCalendarNewComponent,
    UserAvatarModule,
    UserTreeModule,
    PipesModule,
    ModuleHeaderComponent,
    CountdownTimerComponent,
    GridLineComponent,
    PopUpHeaderComponent,
    GridLineComponent,
    ModalHeaderComponent,
    ImageComponent,
    SharedButtonsComponent,
    Base64FileSaverComponent,
    SelectOptionFilterComponent,
    FiltersFactoryComponent,
    DateRangeFilterComponent,
    ImageCropperComponent,
    LayoutComponent,
    ControlLanguageSelectorComponent,
    ControlsModule,
    ScrollControllerComponent,
    AudioPlayerComponent,
    TextReducerComponent,
    PermissionsModule,
    MatCheckboxModule,
    GridListComponent,
    UiControlsModule,
    LoginPopupComponent,
    DebounceClickDirective,
    TrackFocusDirective,
    SelectServerComponent,
    ControlLanguageSelectorMenuComponent,
    LoadingConditionDirective,
    AutoFocusDirective
  ],
  imports: [
    PickerModule,
    FullCalendarModule,
    PermissionsModule,
    ControlsModule,
    PipesModule,
    UserTreeModule,
    UserAvatarModule,
    MatDialogModule,
    CommonModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatButtonToggleModule,
    MatTreeModule,
    MatIconModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    AngularSvgIconModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatMenuModule,
    MatTableModule,
    MatTooltipModule,
    MatSortModule,
    ImageCropperModule,
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,
    MatSelectModule,
    ShareButtonsModule.withConfig({
      theme: 'modern-dark',
      include: allSharedLinks
    }),
    MatSlideToggleModule,
    TooltipModule,
    QuillModule.forRoot(),
    MatCardModule,
    InfiniteScrollModule,
    UiControlsModule,
    ScrollingModule,
    MatPaginatorModule,
    MatTabsModule,
    RouterModule
  ],
  providers: [FiltersService, CanComponentDeactivateGuard]
})
export class SharedModule {}
