<ng-container *ngIf="(isConferenceChat$ | async | not) && (chat$ | async)?.id !== 'favorites'; else conferenceChatHeader">
  <div *ngIf="currentUser$ | async as currentUser" class="header-conversation">
    <div *ngIf="!titleEditingState; else titleEdit" class="chat-info conference">
      <ng-container *ngIf="chatParticipantsCount$ | async as chatParticipantsCount">
        <ng-container *ngIf="chat$ | async as chat">
          <ng-container *ngIf="chat | chatAvatarEntity | async as avatarEntity">
            <vks-icon
              *ngIf="isMobileDevice$ | async"
              (click)="deselectChat()"
              class="square16"
              icon="keyboard-left"
            ></vks-icon>
            <vks-avatar-smart [cardByHover]="true" [entity]="avatarEntity" size="32"></vks-avatar-smart>
            <div
              [class.is-mobile]="isMobileDevice$ | async"
              [class.low-max-width]="isThereActiveCall$ | async"
              class="title-container"
            >
              <p class="title">{{ avatarEntity?.name }}</p>
              <button
                (buttonClick)="toggleEditState(currentUser)"
                *ngIf="chatParticipantsCount > 2"
                [matTooltip]="'RENAME' | translate"
                class="btn-basic btn-compressed"
                color="#005259"
                icon="edit"
                vks-button
              ></button>
            </div>
            <div class="spacer">
              <div
                *ngIf="showHeaderCallButton$ | async"
                [matTooltip]="
                  (canCreateCall$ | async | not) ? ('CALL.UNABLE_TO_CALL' | translate) : ('CALL.ACTION' | translate)
                "
                class="button-wrapper"
              >
                <button
                  (debounceClick)="onCallChat()"
                  [disabled]="(canCreateCall$ | async | not) || (hasActiveCall$ | async)"
                  class="btn-basic btn-compressed"
                  color="#005259"
                  icon="menu-conferences"
                  iconClass="square20"
                  vks-button
                  vks-debounce-click
                ></button>
              </div>

              <button
                (buttonClick)="visitGroupCall(chat.callId)"
                *ngIf="isThereActiveCall$ | async"
                [label]="'JOIN' | translate"
                class="join-button btn-compressed"
                color="#005259"
                icon="menu-conferences"
                iconClass="square20 active"
                vks-button
              ></button>
            </div>
          </ng-container>
          <div class="actions">
            <span (click)="initParticipantsEdit.emit()" *ngIf="isMobileDevice$ | async | not" class="anchor title">{{
              getChatParticipantsCount(chatParticipantsCount) | async
            }}</span>
            <button
              (buttonClick)="initParticipantsEdit.emit()"
              *ngIf="isMobileDevice$ | async"
              class="btn-basic btn-compressed"
              color="#005259"
              icon="users"
              iconClass="square20"
              vks-button
            ></button>
            <button
              (buttonClick)="deselectChat()"
              *ngIf="isMobileDevice$ | async | not"
              [matTooltip]="'CLOSE' | translate"
              [routerLink]="'/chat'"
              class="btn-basic btn-compressed"
              color="#005259"
              icon="x-12"
              iconClass="square16"
              vks-button
            ></button>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <ng-template #titleEdit>
      <ng-container *ngIf="chatParticipantsCount$ | async as chatParticipantsCount">
        <ng-container *ngIf="chat$ | async as chat">
          <ng-container *ngIf="chat | chatAvatarEntity | async as avatarEntity">
            <form (ngSubmit)="updateChatTitle(avatarEntity?.name)" [formGroup]="titleFormGroup" class="chat-info">
              <div class="edit-form">
                <vks-user-avatar [entity]="avatarEntity" size="32"></vks-user-avatar>
                <input [value]="avatarEntity?.name" autofocus formControlName="chatTitle" type="text" vks-cinput />
              </div>
              <div class="edit-actions">
                <button
                  [class.no-hover]="chatTitleControl.invalid || chatTitleControl.pristine"
                  [color]="chatTitleControl.invalid || chatTitleControl.pristine ? '#9daec3' : '#005259'"
                  [matTooltipDisabled]="chatTitleControl.invalid || chatTitleControl.pristine"
                  [matTooltip]="'APPLY' | translate"
                  buttonType="submit"
                  class="btn-basic btn-compressed"
                  icon="done"
                  iconClass="square20"
                  vks-button
                ></button>
                <button
                  (buttonClick)="toggleEditState()"
                  [matTooltip]="'CANCEL' | translate"
                  class="btn-basic btn-mini"
                  color="#005259"
                  icon="x-12"
                  iconClass="square16"
                  vks-button
                ></button>
              </div>
            </form>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-template>
  </div>
</ng-container>
<ng-template #conferenceChatHeader>
  <ng-container *ngIf="(chat$ | async)?.name === qtsChatTitle; else usualChatHeader">
    <vks-module-header
      (hideHeader)="onHideHeader()"
      [title]="chatTitle || (defaultChatTitle$ | async)"
    ></vks-module-header>
  </ng-container>
  <ng-template #usualChatHeader>
    <ng-container *ngIf="chat$ | async as chat">
      <div class="header-conversation">
        <div class="chat-info conference">
          <ng-container *ngIf="chat | chatAvatarEntity | async as avatarEntity">
            <vks-icon (click)="deselectChat()" class="square16" icon="keyboard-left"></vks-icon>
            <vks-avatar-smart [entity]="avatarEntity" size="32"></vks-avatar-smart>
            <div
              [class.is-mobile]="isMobileDevice$ | async"
              [class.low-max-width]="isThereActiveCall$ | async"
              class="title-container"
            >
              <p class="title">{{ avatarEntity?.name | translate }}</p>
            </div>
            <ng-container *ngIf="chatParticipantsCount$ | async as chatParticipantsCount">
              <button
                (buttonClick)="toggleUserTree()"
                *ngIf="chatParticipantsCount > 2"
                [matTooltip]="getChatParticipantsCount(chatParticipantsCount) | async"
                class="btn-basic btn-compressed right"
                color="#005259"
                icon="{{ showUserTree$.value ? 'x-10' : 'users' }}"
                iconClass="{{ showUserTree$.value ? 'square15' : 'square20' }}"
                vks-button
              ></button>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-template>
</ng-template>
<section (click)="showWarning = false" *ngIf="showWarning" class="warning clickable">
  <h3>{{ 'WARNING' | translate }}</h3>
  <p class="message">{{ warningMessage$ | async }}</p>
</section>
<ng-container *ngIf="showUserTree$ | async">
  <div *ngIf="conferenceParticipantsAsUsers$ | async as filteredContacts; else loading" class="contacts-list">
    <vks-user-tree
      (deselectUser)="clearSelectedUsers([$event])"
      (removeUsers)="clearSelectedUsers($event)"
      (selectDivision)="selectDivisionUsers($event)"
      (selectUser)="selectConversationWithUser($event)"
      *ngIf="filteredContacts | isEmpty | not; else stub"
      [allowExpand]="true"
      [allowSelecting]="true"
      [checklistSelection]="userTreeSelectionModel"
      [disabledUserIds]="currentUserIdAsArray$ | async"
      [divisionsList]="[]"
      [expandAll]="true"
      [multiSelect]="true"
      [reduceDivisions]="true"
      [userList]="filteredContacts"
      [virtualScroll]="false"
    ></vks-user-tree>
    <div *ngIf="selectedUsers && selectedUsers.length > 0" class="actions">
      <button
        (buttonClick)="updateParticipant()"
        [disabled]="!selectedUsers || selectedUsers.length < 2"
        [label]="'APPLY' | translate"
        class="btn-anchor"
        vks-button
      ></button>
    </div>
    <ng-template #stub>
      <p class="stub center">{{ 'EMPTY_LIST' | translate }}</p>
    </ng-template>
  </div>
  <ng-template #loading>
    <div class="loader">
      <mat-spinner diameter="30" strokeWidth="2"></mat-spinner>
    </div>
  </ng-template>
</ng-container>

<div *ngIf="!isFavoriteChat" style="display: flex; width: 100%;align-items: center;cursor: pointer;width: 100%;
background-color: #f5f5f6;
padding: 12px 18px;justify-content: space-between;"
(click)="togglePinnesMessagesShowing()">
<span   style='font-size: 13px;
color: #007981;
font-weight: 600;'>
	{{showPinnedMessages  ? ('RETURN_TO_CHAT' | translate): ('PINNED_MESSAGES' | translate ) + getPinnedMessagesCount() }}
</span>
<vks-icon class="square20"
icon="pin-on"></vks-icon>
</div>

<div [class.hidden]="showChatMessages$ | async | not" class="chat-content">
  <!--<div *ngIf="(showLoader$ | async)"
			 class="loader-cover">
		<div class="loader">
			<mat-spinner diameter="35"
									 strokeWidth="3"></mat-spinner>
		</div>
	</div> -->
  <div *ngIf="showNoMessagesTip$ | async">
    <div class="loader-cover">
      <span class="loader stub">{{ 'NO_MESSAGES' | translate }}</span>
    </div>
  </div>
  <!--// TODO LOADER-->
  <vks-scroll-controller #messageListContainer [sideDetectAllowance]="512" class="message-list">
    <vks-message-list
      #messageListComponent
      (blockChat)="blockChat($event)"
      (deleteMessage)="deleteMessage($event)"
      (editMessage)="editMessage($event)"
      (retrySendMessage)="submitMessage($event)"
      (forwardMessage)="forwardToSpeakersChat($event)"
      (forwardMessageToChat)="forwardMessageToChat($event)"
      (issueWarning)="issueWarning($event)"
      (listInitialized)="listInitialized$.next()"
      (markAsRead)="markMessageAsRead($event)"
      (replyMessage)="replyMessage($event)"
      (unblockChat)="unblockChat($event)"
						(pinMessage)="pinMessage($event)"
						(addToFavorites)="addToFavorites($event)"
      [allowForward]="isSpeaker && allowForward && !isQTSChat"
      [chatParticipants]="chatParticipants"
      [class.list-loading]="showLoader$ | async"
      [conferenceCreator]="conferenceCreator"
      [conferenceId]="conferenceId"
      [excludeQTS]="excludeQTS"
      [isActiveConference]="isActiveConference"
      [isBanned]="isChatBanned$ | async"
      [isDemoChat]="demoChat"
      [messages]="(showPinnedMessages ? pinnedMessages : messages$) | async"
						[isFavorite]="isFavoriteChat"
      [reverseOnMobile]="reverseOnMobile"
    ></vks-message-list>
  </vks-scroll-controller>
  <div
    [ngClass]="{ 'conference-chat': isConferenceChat$ | async, visible: btnGoToBottomVisibleTrigger$ | async }"
    [ngStyle]="{ transform: btnGoToBottomTranslate$ | async }"
    class="btn-go-to-bottom"
  >
    <div *ngIf="(unreadMessagesCount$ | async) > 0" class="badge">{{ unreadMessagesCount$ | async }}</div>

    <button (click)="goToBottom()" mat-icon-button type="button">
      <vks-icon icon="go-to-bottom"></vks-icon>
    </button>
  </div>
</div>

<section
  *ngIf="isChatBanned$ | async | not; else banWarning"
  [class.hidden]="showChatMessages$ | async | not"
  [hidden]="hideMessageEditArea"
  class="message-form"
>
  <vks-chat-message-edit
    #messageEditComponent
    (keydown.arrowUp)="initMessageEdit($event)"
    (keydown.escape)="cancelEditing($event)"
    (submitMessage)="submitMessage($event)"
    (textAreaResize)="textAreaResized$.next($event)"
    (updateMessage)="updateMessage($event)"
    (scrollToBottom)="goToBottom()"
    [allowAttachments]="!demoChat"
    [allowQTSAnchor]="!isSpeaker"
    [chatLoaded]="showLoader$ | async | not"
    [chat]="chat$ | async"
    [editingMessage]="editingMessageClone$ | async"
    [forGlobalChat]="inputConferenceId$ | async | not"
    [forwardingMessages]="forwardingMessagesClone$ | async"
    [handleQTSTag]="true"
    [isQTSChat]="isQTSChat"
    [isQTSEnabled]="isQTSChatEnabled"
    [replyingMessage]="replyingMessageClone$ | async"
  >
  </vks-chat-message-edit>
</section>
<ng-template #banWarning>
  <ng-container *ngIf="chatBan$ | async as chatBan">
    <section class="warning blocked">
      <h3>{{ 'CHAT_BLOCKED' | translate }}</h3>
      <span *ngIf="!!chatBan.expired"
        >{{ 'UNTIL' | translate }} {{ chatBan.expired | formatDate: 'd MMMM yyyy HH:mm' }}</span
      >
      <p *ngIf="!!chatBan.reason" class="message">{{ 'REASON' | translate }}: {{ chatBan.reason }}</p>
    </section>
  </ng-container>
</ng-template>
