import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { MessageDraftEntity } from '@breez/modules/chat';

interface State extends EntityState<MessageDraftEntity> {}
const adapter: EntityAdapter<MessageDraftEntity> = createEntityAdapter<MessageDraftEntity>({
  // @ts-ignore
  selectId: (draftEntity: MessageDraftEntity) => {
    return draftEntity.chatId;
  },
  sortComparer: (_, __) => {
    return 1;
  }
});

const initialState: State = adapter.getInitialState({});

export { State, adapter, initialState };
