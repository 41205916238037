import { InjectionToken } from '@angular/core';

export const CREATE_CONFERENCE_DIALOG_ID = new InjectionToken<string>(
  "create conference dialog's id to simple get it through MatDialog.getDialogById",
  {
    factory: (): string => {
      return 'create-dialog';
    }
  }
);
