import { UserMediaSource } from '@breez/models/webrtc/media-source.model';
import { CallDestinationType, CallState, CallStatus } from '@breez/modules/call/models';
import { CallModel } from '@breez/modules/call/models/call.model';
import { MediaDevicesService } from '@breez/modules/webrtc/services/media-devices.service';
import { DEFAULT_WEBRTC_CONSTRAINTS, generateWebrtcConfig } from '@breez/modules/webrtc/webrtc.config';
import { WebrtcService } from '@breez/modules/webrtc/webrtc.service';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { DeviceSourceType } from '@breez/models/webrtc/device-source-type.enum';
import { MediaSourceKind } from '@breez/models/webrtc/media-source-kind.enum';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  from,
  fromEvent,
  interval,
  merge,
  Observable,
  of,
  OperatorFunction,
  ReplaySubject,
  Subject,
  toArray
} from 'rxjs';
import {
  concatMap,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  scan,
  share,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeUntil,
  withLatestFrom
} from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { CALL_ID } from '@breez/modules/call/call-id.provider';
import { replayWhileSubs } from '@breez/shared/rxjs-operators';
import { CallLocalMediaSource, CallMediaSource } from '@breez/modules/call/models/call-media-source.model';
import { ResolvedUserMediaSource } from '@breez/models/webrtc/resolved-user-media-source.model';
import { CallContentType } from '@breez/modules/call/models/call-content-type.enum';
import { SdpModel } from '@breez/models/webrtc/sdp.model';
import { AppService } from '@breez/app.service';
import { serialExpand } from '@breez/shared/rxjs-operators/serialExpand';
import { LoggerService } from '@breez/shared/services/logger.service';
import { CallModelCarrier } from '@breez/modules/call/models/call-model-carrier.model';
import { CallService } from '@breez/modules/call/services/call.service';
import { CURRENT_CALL_MODELS } from '@breez/modules/call/current-call-models.provider';
import { LocalStorage } from '@breez/shared/modules/storage/interfaces/local-storage.interface';
import { MediaEffectsService } from '@breez/modules/webrtc/services/media-effects.service';
import { WEBRTC_MEDIA_EFFECTS } from '@breez/modules/webrtc/call-media-effects-service.provider';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const P2P_FRAMERATE = localStorage => {
  return parseInt(localStorage.getItem('vks-p2p-framerate')) || 30;
};
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const P2P_RESOLUTION = localStorage => {
  return parseInt(localStorage.getItem('vks-p2p-resolution')) || null;
};

@Injectable({ providedIn: 'root' })
export class Call implements CallModelCarrier {
  // currentUser$ = this.authService.currentUser$;
  callModel$: Observable<CallModel> = this.currentCallModels$.pipe(
    map(callModels => {
      return callModels.find(callModel => {
        return callModel.id === this.callId;
      });
    }),
    distinctUntilChanged(),
    shareReplay(1)
  );

  callDurationDate$: Observable<Date> = this.callModel$.pipe(
    switchMap(callModel => {
      if (callModel.status !== CallStatus.ACCEPT) {
        return EMPTY;
      }
      return interval(1000);
    }),
    scan(duration => {
      return duration + 1;
    }, 0),
    map(seconds => {
      return new Date(seconds * 1000);
    }),
    replayWhileSubs()
  );

  readonly volume$ = new BehaviorSubject<number>(1);
  close$ = new Subject<void>();
  rtcPeerClosedEvent$ = new Subject<void>();

  private makingOffer = false;
  private ignoreRemoteOffer = false;

  iceServers$: Observable<RTCIceServer[]> = this.webrtcService.fetchIceServers();

  state$: Observable<CallState> = this.callModel$.pipe(
    filter(isTruthy),
    map(callModel => {
      if (
        callModel.status !== CallStatus.RING &&
        !(callModel.answererPeerId === this.appService.clientId || callModel.callerPeerId === this.appService.clientId)
      ) {
        return CallState.FOREIGN;
      }
      switch (callModel.status) {
        case CallStatus.RING:
          return CallState.RUNNING;
        case CallStatus.ACCEPT:
          return CallState.PENDING;
        case CallStatus.HANGUP:
        case CallStatus.CANCEL:
        case CallStatus.DECLINE: //TODO 2010??
          return CallState.ENDED;
        default:
          return CallState.RUNNING;
      }
    }),
    distinctUntilChanged()
  );

  constructor(
    @Inject(CALL_ID) public callId,
    @Inject(CURRENT_CALL_MODELS) private currentCallModels$: Observable<CallModel[]>,
    @Inject(DEFAULT_WEBRTC_CONSTRAINTS) private webrtcConstraints: any,
    private callService: CallService,
    private webrtcService: WebrtcService,
    private mediaDevicesService: MediaDevicesService,
    @Inject(WEBRTC_MEDIA_EFFECTS) private mediaEffectsService: MediaEffectsService,
    private appService: AppService,
    private logger: LoggerService,
    private localStorage: LocalStorage
  ) {
    this.mediaEffectsService.loading$.next(false);

    this.state$.pipe(withLatestFrom(this.iceServers$), takeUntil(this.close$)).subscribe(([state, iceServers]) => {
      if (state === CallState.ENDED) {
        this.setCallMediaSources([]);
      }
      switch (state) {
        case CallState.PENDING:
          const config = generateWebrtcConfig();

          if (iceServers) {
            config.iceServers = iceServers;
          }

          // @ts-ignore
          this.rtcPeerConnection = new RTCPeerConnection(config, this.webrtcConstraints);
          this.logger.info(['rtc peer connection config', config, this.webrtcConstraints]);
          this.handleRTCPeerConnection(this.rtcPeerConnection);
          return;
        default:
          if (this.rtcPeerConnection) {
            this.rtcPeerConnection.close();
            this.rtcPeerConnection = null;
          }
      }
    });

    this.callDurationDate$.pipe(takeUntil(this.close$)).subscribe();
  }

  rtcPeerConnection: RTCPeerConnection;

  requestedUserMediaSources$ = new ReplaySubject<CallLocalMediaSource[]>(1);

  resolvedCallMediaSources$: Observable<CallLocalMediaSource[]> = serialExpand(
    this.requestedUserMediaSources$,
    (resolvedSources, requestedSources) => {
      const requestedUserMediaSources = requestedSources.map(source => {
        return source.mediaSource;
      });
      const resolvedUserMediaSources = resolvedSources.map(source => {
        return source.resolvedMediaSource;
      });

      return this.mediaDevicesService
        .resolveTracksByExistingSources(resolvedUserMediaSources, requestedUserMediaSources)
        .pipe(
          map(nextResolvedUserMediaSources => {
            return nextResolvedUserMediaSources.map(resolvedMediaSource => {
              const callMediaSource = requestedSources.find(source => {
                return this.mediaDevicesService.compareSources(source.mediaSource, resolvedMediaSource);
              });
              return <CallLocalMediaSource>{
                ...callMediaSource,
                resolvedMediaSource
              };
            });
          })
        );
    },
    <CallLocalMediaSource[]>[]
  ).pipe(
    this.handleSourcesBySDKEffects(),
    // debounceTime(500),
    switchMap(sources => {
      const resolvedTracks = sources
        .filter(source => {
          return (
            source.resolvedMediaSource &&
            source.resolvedMediaSource.track &&
            source.resolvedMediaSource.track.readyState === 'live'
          );
        })
        .map(source => {
          return source.resolvedMediaSource && source.resolvedMediaSource.track;
        });

      return merge(
        ...resolvedTracks.map(track => {
          return fromEvent(track, 'ended');
        })
      ).pipe(
        startWith({}),
        map(() => {
          return sources.filter(source => {
            return (
              source.resolvedMediaSource &&
              source.resolvedMediaSource.track &&
              source.resolvedMediaSource.track.readyState === 'live'
            );
          });
        })
      );
    }),
    replayWhileSubs(),
    takeUntil(this.rtcPeerClosedEvent$)
  );

  isCallBlurEnabled$: Observable<boolean> = this.mediaDevicesService.isCallBlurEnabled$.pipe(
    distinctUntilChanged(),
    replayWhileSubs()
  );

  isBlurEnable$: Observable<boolean> = merge(
    this.mediaDevicesService.isBlurGlobalEnabled$,
    this.isCallBlurEnabled$
  ).pipe(distinctUntilChanged(), shareReplay(1));

  localStream$: Observable<MediaStream> = this.resolvedCallMediaSources$.pipe(
    map(sources => {
      return new MediaStream(
        sources
          .filter(source => {
            return source.resolvedMediaSource.track;
          })
          .map(source => {
            return source.resolvedMediaSource.track;
          })
      );
    }),
    shareReplay(1)
  );

  remoteSessionDescription$: Observable<SdpModel> = this.callModel$.pipe(
    switchMap(callModel => {
      return this.webrtcService.remoteSessionDescription(callModel.id);
    }),
    share()
  );

  remoteSources$ = new Subject<CallMediaSource[]>();

  remoteIceCandidate$ = this.callModel$.pipe(
    switchMap(callModel => {
      return this.webrtcService.remoteIceCandidates(callModel.id);
    })
  );

  incomingCallMediaSources$ = new ReplaySubject<CallLocalMediaSource[]>(1);

  private handleSourcesBySDKEffects(): OperatorFunction<CallLocalMediaSource[], CallLocalMediaSource[]> {
    return input$ => {
      return input$.pipe(
        switchMap((sources: CallLocalMediaSource[]) => {
          return this.isBlurEnable$.pipe(
            debounceTime(250),
            switchMap(isBlurEnable => {
              if (isBlurEnable) {
                if (
                  !isTruthy(
                    sources.find(source => {
                      return CallContentType.FACING_VIDEO === source.contentType;
                    })
                  )
                ) {
                  this.mediaEffectsService.stop();
                }
                return from(sources).pipe(
                  concatMap(source => {
                    if (CallContentType.FACING_VIDEO === source.contentType) {
                      let track = source.resolvedMediaSource.track;
                      const stream = new MediaStream();
                      stream.addTrack(track);
                      return this.mediaEffectsService.getStream$(stream.clone(), false).pipe(
                        map(effectsStream => {
                          const effectTrack = effectsStream?.getVideoTracks()[0] ?? track;
                          stream.getTracks().forEach(_track => {
                            _track.stop();
                            stream.removeTrack(_track);
                          });
                          const resolvedMediaSource = source.resolvedMediaSource;
                          return { ...source, resolvedMediaSource: { ...resolvedMediaSource, track: effectTrack } };
                        })
                      );
                    } else {
                      return of(source);
                    }
                  }),
                  toArray()
                );
              } else {
                this.mediaEffectsService.stop();
                return of(sources);
              }
            })
          );
        })
      );
    };
  }

  handleRTCPeerConnection(connection: RTCPeerConnection): void {
    const closedEvent$ = fromEvent(connection, 'connectionstatechange').pipe(
      filter(event => {
        return (<RTCPeerConnection>event.target).signalingState === 'closed';
      }),
      take(1)
    );

    this.resolvedCallMediaSources$.pipe(debounceTime(300), takeUntil(this.rtcPeerClosedEvent$)).subscribe(sources => {
      sources = this.setSourcesToPeerConnection(sources, connection);
      this.logger.info(['on setting sources -> transceivers', connection.getTransceivers(), sources]);
    });

    fromEvent<RTCPeerConnectionIceEvent>(connection, 'negotiationneeded')
      .pipe(takeUntil(closedEvent$), withLatestFrom(this.callModel$))
      .subscribe(async ([, callModel]) => {
        try {
          this.logger.info('making offer');
          this.makingOffer = true;
          const sdpInit = await connection.createOffer();
          this.logger.info('obtained offer', sdpInit);

          if (!sdpInit) {
            this.logger.info('null offer init, cancel');
            return;
          }
          await connection.setLocalDescription(sdpInit);

          const callSources = this.convertToCallMediaSources(
            await this.getResolvedCallMediaSources(),
            connection.getTransceivers()
          );

          this.logger.info(callSources);
          const sdp = connection.localDescription;
          this.logger.info('set offer', sdp);

          if (!sdp) {
            this.logger.info('null offer init, cancel');
            return;
          }

          this.webrtcService.sendSessionDescription(sdp, callSources, callModel.id, callModel.isConference).subscribe();
          this.logger.info('sent offer');
        } catch (error) {
          this.logger.error(error);
        } finally {
          this.makingOffer = false;
          this.logger.info('made offer');
        }
      });

    this.remoteSessionDescription$
      .pipe(
        takeUntil(closedEvent$),
        withLatestFrom(this.callModel$),
        concatMap(async ([sdpModel, callModel]) => {
          this.logger.info('remote sdp event', sdpModel);
          const polite = callModel.destinationType === CallDestinationType.INCOMING;
          this.logger.info('polite', polite);
          const sdp = sdpModel.sdp;
          const offerCollision = sdp.type === 'offer' && (this.makingOffer || connection.signalingState !== 'stable');
          this.logger.info('offer collision', offerCollision);
          this.logger.info('signaling state', connection.signalingState);

          this.ignoreRemoteOffer = !polite && offerCollision;
          if (this.ignoreRemoteOffer) {
            this.logger.info('ignoring offer');
            return Promise.resolve();
          }

          this.logger.info('setting remote description');

          try {
            await connection.setRemoteDescription(sdp);
          } catch (error) {
            this.logger.error(error);
            return Promise.resolve();
          }

          this.remoteSources$.next(sdpModel.activeTracks);

          if (sdp.type === 'offer') {
            this.logger.info('creating answer');
            const callSources = this.convertToCallMediaSources(
              await this.getResolvedCallMediaSources(),
              connection.getTransceivers()
            );
            const answer = await connection.createAnswer();
            await connection.setLocalDescription(answer);
            this.logger.info('created answer');
            const localSdp = connection.localDescription;
            this.logger.info('answer set', localSdp);
            this.webrtcService
              .sendSessionDescription(localSdp, callSources, callModel.id, callModel.isConference)
              .subscribe();
            this.logger.info('answer sent');
          }
          return Promise.resolve();
        })
      )
      .subscribe();

    this.remoteIceCandidate$.pipe(takeUntil(closedEvent$)).subscribe(async ice => {
      try {
        await connection.addIceCandidate(ice);
      } catch (error) {
        if (!this.ignoreRemoteOffer) {
          this.logger.error(error);
        }
      }
    });

    fromEvent<RTCPeerConnectionIceEvent>(connection, 'icecandidate')
      .pipe(takeUntil(closedEvent$), withLatestFrom(this.callModel$))
      .subscribe(([event, callModel]) => {
        const ice = event.candidate;
        if (ice) {
          this.webrtcService.sendIceCandidate(ice, callModel.id, callModel.isConference).subscribe();
        }
      });

    this.onRemoteMediaStreamEvent$(connection)
      .pipe(takeUntil(closedEvent$))
      .subscribe(sources => {
        return this.incomingCallMediaSources$.next(sources);
      });
  }

  setCallMediaSources(sources: CallLocalMediaSource[]): void {
    const videoSource = sources.find(source => {
      return (
        source.mediaSource.kind === MediaSourceKind.VIDEO_INPUT &&
        source.mediaSource.sourceType === DeviceSourceType.HARDWARE
      );
    });
    if (videoSource) {
      //frameRate: { max: 10 }
      const frameRate = isTruthy(P2P_FRAMERATE(this.localStorage))
        ? { frameRate: { max: P2P_FRAMERATE(this.localStorage) } }
        : {};
      const resolution = isTruthy(P2P_RESOLUTION(this.localStorage))
        ? { height: P2P_RESOLUTION(this.localStorage) }
        : {};
      videoSource.mediaSource.constraints = { ...frameRate, ...resolution };
    }
    this.requestedUserMediaSources$.next(sources);
  }

  /*
   * @deprecated Should be used __setCallMediaSources__
   * */
  setUserMediaSources(sources: UserMediaSource[]): void {
    const callMediaSources = sources.map(source => {
      let contentType: CallContentType;

      switch (source.kind) {
        case MediaSourceKind.VIDEO_INPUT:
          contentType = CallContentType.FACING_VIDEO;
          break;
        case MediaSourceKind.AUDIO_INPUT:
          contentType = CallContentType.FACING_AUDIO;
          break;
      }

      return <CallLocalMediaSource>{
        mediaSource: source,
        contentType
      };
    });

    this.setCallMediaSources(callMediaSources);
  }

  changeCall(
    status: CallStatus,
    data?: { kind: MediaSourceKind; sourceType?: DeviceSourceType; call?: Pick<CallModel, 'id' | 'status'> },
    eventInitiatorTab = false
  ): Observable<boolean> {
    if (data) {
      const availableSources$ = this.mediaDevicesService.availableSources$;
      const acceptTypes = [
        {
          kind: data.kind,
          sourceType: data.sourceType || DeviceSourceType.HARDWARE
        }
      ];
      if (data.kind === MediaSourceKind.VIDEO_INPUT) {
        acceptTypes.push({
          kind: MediaSourceKind.AUDIO_INPUT,
          sourceType: DeviceSourceType.HARDWARE
        });
      }

      availableSources$
        .pipe(
          map(sources => {
            return acceptTypes
              .map(type => {
                return sources.find(source => {
                  return (
                    source.kind === type.kind && source.sourceType === DeviceSourceType.HARDWARE && source.isDefault
                  );
                });
              })
              .filter(isTruthy);
          }),
          take(1)
        )
        .subscribe(sources => {
          const callSources = sources
            .map(source => {
              let contentType: CallContentType;
              switch (source.kind) {
                case MediaSourceKind.VIDEO_INPUT:
                  if (source.sourceType === DeviceSourceType.SCREEN) {
                    contentType = CallContentType.SCREEN_VIDEO;
                    break;
                  }
                  contentType = CallContentType.FACING_VIDEO;
                  break;
                case MediaSourceKind.AUDIO_INPUT:
                  contentType = CallContentType.FACING_AUDIO;
                  break;
              }
              if (!contentType) {
                return null;
              }

              return <CallLocalMediaSource>{
                mediaSource: source,
                contentType: contentType
              };
            })
            .filter(isTruthy);
          this.setCallMediaSources(callSources);
        });
    }

    let changing$ = of(null);
    if (data?.call) {
      changing$ = this.currentCallModels$.pipe(
        switchMap(callModels => {
          const callModel = callModels.find(callModel_ => {
            return callModel_.id === data.call.id;
          });
          if (callModel) {
            const callStatus = data.call.status;
            return this.callService.changeCall(callModel, callStatus, eventInitiatorTab);
          }
          return of(null);
        })
      );
    }

    return changing$.pipe(
      withLatestFrom(this.callModel$),
      switchMap(([, callModel]) => {
        return this.callService.changeCall(callModel, status, eventInitiatorTab);
      }),
      take(1)
    );
  }

  private setSourcesToPeerConnection(
    sources: CallLocalMediaSource[],
    connection: RTCPeerConnection
  ): CallLocalMediaSource[] {
    const currentSenders = connection.getSenders().filter(sender => {
      return sender.track;
    });

    currentSenders
      .filter(sender => {
        return sender.track.readyState === 'ended';
      })
      .map(sender => {
        return connection.getTransceivers().find(transceiver => {
          return transceiver.sender === sender;
        });
      })
      .forEach(transceiver => {
        try {
          connection.removeTrack(transceiver.sender);

          if (typeof transceiver.stop === 'function') {
            transceiver.stop();
          }
        } catch (e) {
          this.logger.warn(e);
        }
      });

    const currentTransceivers = connection.getTransceivers();

    return sources
      .map(source => {
        let track = source.resolvedMediaSource.track;

        let sourceTransceiver = currentTransceivers.find(transceiver => {
          return transceiver.sender.track === track;
        });

        if (!sourceTransceiver) {
          sourceTransceiver = connection.addTransceiver(track);
        }
        return !!sourceTransceiver
          ? {
              ...source,
              sharedId: sourceTransceiver.mid
            }
          : null;
      })
      .filter(isTruthy);
  }

  private onRemoteMediaStreamEvent$(connection: RTCPeerConnection): Observable<CallLocalMediaSource[]> {
    return combineLatest([this.remoteSources$, fromEvent(connection, 'track')]).pipe(
      map(([remoteSources]) => {
        const transceivers = connection.getTransceivers();
        this.logger.info('on remote -> transceivers', transceivers);
        return remoteSources
          .map(meta => {
            const transceiver = transceivers.find(transceiver_ => {
              return transceiver_.mid === meta.sharedId;
            });
            if (!transceiver) {
              return null;
            }

            const track = transceiver.receiver.track;
            const mediaSource = this.mediaDevicesService.trackToSource(track);
            const resolvedMediaSource = <ResolvedUserMediaSource>{
              ...mediaSource,
              track
            };

            return <CallLocalMediaSource>{
              ...meta,
              mediaSource,
              resolvedMediaSource
            };
          })
          .filter(isTruthy);
      })
    );
  }

  private getResolvedCallMediaSources(): Promise<CallLocalMediaSource[]> {
    return this.resolvedCallMediaSources$.pipe(take(1)).toPromise();
  }

  private convertToCallMediaSources(
    sources: CallLocalMediaSource[],
    transceivers: RTCRtpTransceiver[]
  ): CallMediaSource[] {
    return sources
      .map(source => {
        const trackTransceiver = transceivers.find(transceiver => {
          return transceiver.sender.track === source.resolvedMediaSource.track;
        });
        const sharedId = trackTransceiver?.mid;
        return sharedId
          ? {
              contentType: source.contentType,
              sharedId
            }
          : null;
      })
      .filter(isTruthy);
  }

  setVolume(value: number): void {
    this.callModel$.pipe(
      map(callModel => {
        return { ...callModel, volume: value };
      })
    );
  }

  stopEffects(): void {
    this.mediaEffectsService.stop();
  }
}
