import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PermissionsService } from '@breez/modules/permissions/services/permissions.service';
import { NgxPermissionsModule } from 'ngx-permissions';

@NgModule({
  imports: [CommonModule, NgxPermissionsModule.forChild()],
  providers: [PermissionsService],
  exports: [NgxPermissionsModule]
})
export class PermissionsModule {}
