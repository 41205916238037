import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { declensionNumEn, declensionNumRu } from '@breez/shared/utilities/declension';

@Injectable({
  providedIn: 'root'
})

// TODO: использовать имеющийся плагин i18n вместо собственного решения - ngx-translate-messageformat-compiler
export class DeclensionService {
  constructor(private translateService: TranslateService) {}

  getDeclension(wordKey: string, num: number): Observable<string> {
    let wordNumberInDict: number;

    // TODO сделать расширяемым
    switch (this.translateService.currentLang) {
      case 'en':
        wordNumberInDict = declensionNumEn(num);
        break;
      case 'ru':
        wordNumberInDict = declensionNumRu(num);
        break;
      default:
        return of(null);
    }
    return this.translateService.get(`${wordKey}.${wordNumberInDict}`);
  }

  getInstantDeclension(wordKey: string, num: number): string {
    let wordNumberInDict: number;

    switch (this.translateService.currentLang) {
      case 'en':
        wordNumberInDict = declensionNumEn(num);
        break;
      case 'ru':
        wordNumberInDict = declensionNumRu(num);
        break;
      default:
        return null;
    }
    return this.translateService.instant(`${wordKey}.${wordNumberInDict}`);
  }
}
