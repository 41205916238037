import { Expose, Type } from 'class-transformer';
import { ParticipantEventType } from './participant-event-type.enum';
import { Participant } from '@breez/models/shared/participant/participant.model';

export class ParticipantEvent {
  @Expose({ name: 'event' })
  type: ParticipantEventType;

  @Expose({ name: 'data' })
  @Type(() => {
    return Participant;
  })
  data: Participant[];
}
