import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { ValidationError } from './validation-error.model';

const ERROR = {
  type: 'unexpectedfiletype',
  label: 'WRONG_FILE_TYPE'
} as ValidationError;

export function fileTypeValidator(): ValidatorFn {
  const allowedTypesForConferenceCover = ['image/jpeg', 'image/png'];

  return (control: AbstractControl): ValidationErrors | null => {
    const controlValue = control.value;

    let file;
    let fileType;

    if (!controlValue) {
      return null;
    }

    if (Array.isArray(controlValue) && controlValue.length === 0) {
      return null;
    }

    if (Object.keys(controlValue).length === 0) {
      return null;
    }

    if (Array.isArray(controlValue) && controlValue.length > 0) {
      file = controlValue[0];
    } else {
      file = controlValue;
    }

    if (!isTruthy(file)) {
      return null;
    }

    // при редактировании приходит просто блоб, достанем из него тип
    if (file instanceof Blob) {
      fileType = file.type;
    } else if (typeof file === 'object' && file.hasOwnProperty('blob') && typeof file.blob === 'string') {
      fileType = file.blob.split(';')[0].substring(5);
    } else {
      return null;
    }

    if (allowedTypesForConferenceCover.includes(fileType)) {
      return null;
    }

    return { [ERROR.type]: ERROR } as ValidationErrors;
  };
}
