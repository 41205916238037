import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { replayWhileSubs } from '@breez/shared/rxjs-operators';
import { EmitOnChange } from '@breez/shared/utilities/decorators/emit-on-change.decorator';

@Component({
  selector: 'vks-media-sdk-effects-control',
  templateUrl: './media-sdk-effects-control.component.html',
  styleUrls: ['./media-sdk-effects-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaSdkEffectsControlComponent implements OnChanges {
  @Output() changeEnabled = new EventEmitter<boolean>();

  @Input() enabled: boolean;

  @EmitOnChange('enabled')
  enabled$ = new ReplaySubject<boolean>(1);

  isBlurEnable$ = this.enabled$.pipe(distinctUntilChanged(), replayWhileSubs());

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnChanges(_: SimpleChanges): void {}

  toggle(): void {
    const value = !this.enabled;
    this.changeEnabled.next(value);
    this.enabled$.next(value);
  }
}
