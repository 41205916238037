import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { User } from '@breez/models';
import { Participant } from '@breez/models/shared/participant/participant.model';
import { AuthService } from '@breez/modules/auth/services/auth.service';
import { PermissionsService } from '@breez/modules/permissions/services/permissions.service';
import { StateService } from '@breez/shared/services/state.service';
import { EmitOnChange } from '@breez/shared/utilities/decorators/emit-on-change.decorator';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import moment from 'moment';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { MessageListEntryEnum } from '../../enums';
import { MessageToForwardModel } from '../../interfaces';
import { MessageListDateStub, MessageListEntry, MessageGroupEntry, Message } from '../../models';
import { ChatService } from '../../services';

@Component({
  selector: 'vks-message-list',
  templateUrl: './chat-message-list.component.html',
  styleUrls: ['./chat-message-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatMessageListComponent implements AfterViewChecked, OnChanges {
  ngAfterViewChecked$ = new Subject<void>();

  messageListEntryEnum = MessageListEntryEnum;

  @Input() messages: Message[];
  @Input() conferenceId: number;
  @Input() isDemoChat = false;
  @Input() conferenceCreator: User;
  @Input() chatParticipants: Participant[];
  @Input() allowForward: boolean;
  @Input() excludeQTS = false;
  @Input() reverseOnMobile = false;
  @Input() isBanned = false;
  @Input() isActiveConference: boolean;
  @Input() isFavorite: boolean = false;

  @Output() editMessage = new EventEmitter<Message>();
  @Output() retrySendMessage = new EventEmitter<Message>();
  @Output() forwardMessage = new EventEmitter<Message>();
  @Output() replyMessage = new EventEmitter<Message>();
  @Output() deleteMessage = new EventEmitter<Message>();
  @Output() blockChat = new EventEmitter<number>();
  @Output() unblockChat = new EventEmitter<number>();
  @Output() issueWarning = new EventEmitter<number>();
  @Output() listInitialized = new EventEmitter<void>();
  @Output() markAsRead = new EventEmitter<Message>();
  @Output() forwardMessageToChat = new EventEmitter<MessageToForwardModel>();
  @Output() pinMessage = new EventEmitter<Message>();
  @Output() addToFavorites = new EventEmitter<Message>();

  @EmitOnChange('messages', { onlyTruthy: true })
  messages$ = new ReplaySubject<Message[]>(1);

  notEmptyList$: Observable<boolean> = this.messages$.pipe(
    map(messages => {
      return messages.length > 0;
    })
  );

  @EmitOnChange('chatParticipants', { onlyTruthy: true })
  participants$ = new BehaviorSubject<Participant[]>([]);

  user$ = this.authService.currentUser$.pipe(filter(isTruthy));

  initializedMessageIds = new Set<number>();

  isMobileDevice$ = this.stateService.isMobileDevice$;
  mobileLayout$: Observable<boolean> = this.isMobileDevice$.pipe(
    map(isMobileDevice => {
      return this.reverseOnMobile ? isMobileDevice : false;
    }),
    distinctUntilChanged()
  );

  listGroups$: Observable<MessageGroupEntry[]> = combineLatest([
    this.messages$,
    this.user$,
    this.mobileLayout$,
    this.participants$
  ]).pipe(
    map(([messages, user, isMobile, participants]) => {
      return this.patchMessages(isMobile ? [...messages].reverse() : messages, user, participants);
    })
  );

  constructor(
    private authService: AuthService,
    private chatService: ChatService,
    private stateService: StateService,
    private permissionsService: PermissionsService
  ) {}

  /**
   * Patch isGroupFirst & isGroupLast & isOut & isQuestionToSpeaker flag to messages by sender
   * @param messages
   * @param currentUser
   * @param participants
   */
  private patchMessages(messages: Message[], currentUser: User, participants: Participant[]): MessageGroupEntry[] {
    const groupEntries: MessageGroupEntry[] = [];

    Array.from(this.initializedMessageIds.values()).forEach(id => {
      if (
        !messages.some(message => {
          return message.id === id;
        })
      ) {
        this.initializedMessageIds.delete(id);
      }
    });

    messages.forEach((message, index) => {
      const listMessage = new MessageListEntry();
      message = new Message(message);

      let groupEntry: MessageGroupEntry = groupEntries[groupEntries.length - 1];

      if (!messages[index - 1] || messages[index - 1].sentDate.getDate() !== message.sentDate.getDate()) {
        const dateStub = new MessageListDateStub({ date: message.sentDate });
        groupEntry = new MessageGroupEntry({ dateStub });
        groupEntries.push(groupEntry);
      }

      if (
        !messages[index - 1] ||
        (messages[index - 1] && messages[index - 1].sender.userId !== message.sender.userId) ||
        messages[index - 1].sentDate.getDate() !== message.sentDate.getDate()
      ) {
        listMessage.isGroupFirst = true;
      }

      if (
        !messages[index + 1] ||
        messages[index + 1].sender.userId !== message.sender.userId ||
        messages[index + 1].sentDate.getDate() !== message.sentDate.getDate()
      ) {
        listMessage.isGroupLast = true;
      }

      if (currentUser && message.sender.userId === currentUser.id) {
        listMessage.isOut = true;
      }

      listMessage.isRead = message.readUserIds.some(id => {
        return listMessage.isOut ? currentUser.id !== id : currentUser.id === id;
      });
      listMessage.isDelivered = message.deliveredUserIds.some(id => {
        return currentUser.id !== id;
      });

      message = this.handleQTSTag(message, this.excludeQTS);

      message.participant = participants.find(participant => {
        return message.userId === participant.participantReference.id;
      });

      if (message.participant) {
        message.participant.isBlockable = this.permissionsService.canManagingUser(
          participants.find(participant => {
            return participant.participantReference.id === currentUser.id;
          }),
          message.participant,
          this.conferenceCreator
        );
      }

      listMessage.message = message;
      listMessage.type = MessageListEntryEnum.MESSAGE;

      if (message.isFirstIncomingUnread) {
        groupEntry.messageEntries.push(
          new MessageListEntry({
            type: MessageListEntryEnum.UNREAD_STUB
          })
        );
      }

      groupEntry.messageEntries.push(listMessage);
    });

    return groupEntries;
  }

  handleQTSTag(message: Message, excludeQTSTag = false): Message {
    if (excludeQTSTag && this.chatService.hasQTSTagInBody(message.body)) {
      message.body = this.chatService.excludeQTSTagFromBody(message.body);
    }
    return message;
  }

  trackByGroup(_, entry: MessageGroupEntry): string {
    return `date_${moment(entry.dateStub.date).startOf('day').unix()}`;
  }

  trackByMessage(_, entry: MessageListEntry): number {
    switch (entry.type) {
      case MessageListEntryEnum.MESSAGE:
        return entry.message.id;
      default:
        return null;
    }
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnChanges(_: SimpleChanges): void {}

  ngAfterViewChecked(): void {
    this.ngAfterViewChecked$.next();
  }
}
