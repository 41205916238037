import { Expose, Type } from 'class-transformer';
import { User } from '@breez/models';
import { Message } from './message.model';

export class MessagesList {
  @Expose()
  @Type(() => {
    return Message;
  })
  messages: Message[];

  @Expose()
  @Type(() => {
    return User;
  })
  users: User[];
}
