import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from '@breez/app.service';
import { DeviceSourceType, PresentationMode } from '@breez/models/webrtc/device-source-type.enum';
import { UserMediaSourceBase } from '@breez/models/webrtc/media-source-base.model';
import { MediaSourceKind } from '@breez/models/webrtc/media-source-kind.enum';
import { UserMediaSource } from '@breez/models/webrtc/media-source.model';
import { ResolvedUserMediaSource } from '@breez/models/webrtc/resolved-user-media-source.model';
import { ElectronService } from '@breez/modules/core/services';
import { ScreensharePickerComponent } from '@breez/shared/components/screenshare-picker/screenshare-picker.component';
import { LocalStorage } from '@breez/shared/modules/storage/interfaces/local-storage.interface';
import { replayWhileSubs } from '@breez/shared/rxjs-operators';
import { LoggerService } from '@breez/shared/services/logger.service';
import { isTruthy } from '@breez/shared/utilities/is-truthy';

import { Size } from 'electron';
import { BehaviorSubject, combineLatest, from, fromEvent, merge, Observable, of, ReplaySubject, Subject } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { ELECTRON_CHANNEL_LIST } from '../../../../../electron-channel-list';
import { mediaSourceKindHelper } from '@breez/modules/webrtc/helpers/media-source-kind.helper';

export const DEFAULT_USER_MEDIA_SOURCES_KEY = 'default-user-media-sources';
const ENABLE_BLUR_KEY = 'enable-blur-key';

@Injectable({
  providedIn: 'root'
})
export class MediaDevicesService {
  isElectronApp: boolean = this.electronService.isElectron;

  screenSize$: Observable<Size> = this.isElectronApp ? this.electronService.screenSize$ : of(null);

  private requestAvailableDevices = new ReplaySubject<void>();
  mediaDevicesChanges$: Observable<void> = fromEvent(navigator.mediaDevices, 'devicechange').pipe(mapTo(undefined));

  requestAvailableDevicesTrigger$: Observable<void> = merge(this.requestAvailableDevices, this.mediaDevicesChanges$);
  // @ts-ignore
  availableDevices$: Observable<InputDeviceInfo[]> = this.requestAvailableDevicesTrigger$.pipe(
    switchMap(() => {
      return this.enumerateDevices();
    }),
    shareReplay(1)
  );

  sourceWarnings = new BehaviorSubject<UserMediaSource[]>([]);
  devicesWarnings$: Observable<UserMediaSource[]> = this.sourceWarnings.asObservable();

  screencastAvailable$: Observable<boolean> = of(this.appService.isMobile).pipe(
    map(isMobile => {
      if (!isMobile) {
        return typeof (<any>navigator.mediaDevices).getDisplayMedia === 'function';
      }
      return false;
    }),
    shareReplay(1)
  );

  videoInputAvailable$: Observable<boolean> = this.availableDevices$.pipe(
    map(devices => {
      return devices.some(device => {
        return device.kind === MediaSourceKind.VIDEO_INPUT;
      });
    })
  );

  audioInputAvailable$: Observable<boolean> = this.availableDevices$.pipe(
    map(devices => {
      return devices.some(device => {
        return device.kind === MediaSourceKind.AUDIO_INPUT;
      });
    })
  );

  videoPermitted$: Observable<boolean> = this.availableDevices$.pipe(
    map(devices => {
      return devices.some(device => {
        return device.label !== '' && device.kind === MediaSourceKind.VIDEO_INPUT;
      });
    }),
    switchMap(isPermitted => {
      return mediaSourceKindHelper(MediaSourceKind.VIDEO_INPUT).hasPermission(isPermitted);
    }),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  audioPermitted$: Observable<boolean> = this.availableDevices$.pipe(
    map(devices => {
      return devices.some(device => {
        return device.label !== '' && device.kind === MediaSourceKind.AUDIO_INPUT;
      });
    }),
    switchMap(isPermitted => {
      return mediaSourceKindHelper(MediaSourceKind.AUDIO_INPUT).hasPermission(isPermitted);
    }),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  dynamicDefaultDevicesBases$ = new Subject<UserMediaSourceBase[]>();

  savedDefaultDeviceBases$: Observable<UserMediaSourceBase[]> = fromEvent<StorageEvent>(window, 'storage').pipe(
    filter(event => {
      return event.key === DEFAULT_USER_MEDIA_SOURCES_KEY;
    }),
    map(event => {
      return event.newValue;
    }),
    startWith(this.localStorage.getItem(DEFAULT_USER_MEDIA_SOURCES_KEY)),
    map(plain => {
      return JSON.parse(plain);
    }),
    map(parsed => {
      return Array.isArray(parsed) ? <UserMediaSourceBase[]>parsed : [];
    }),
    catchError(() => {
      return [];
    })
  );

  isBlurGlobalEnabled$: Observable<boolean> = fromEvent<StorageEvent>(window, 'storage').pipe(
    filter(event => {
      return event.key === ENABLE_BLUR_KEY;
    }),
    map(event => {
      return event.newValue;
    }),
    map(plain => {
      return JSON.parse(plain);
    }),
    startWith(this.isBlurEnable()),
    distinctUntilChanged(),
    shareReplay(1),
    catchError(() => {
      return [];
    })
  );

  isCallBlurEnabled$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  defaultBases$: Observable<UserMediaSourceBase[]> = merge(
    this.dynamicDefaultDevicesBases$,
    this.savedDefaultDeviceBases$
  );

  availableSources$: Observable<UserMediaSource[]> = combineLatest([
    this.availableDevices$,
    this.defaultBases$,
    this.screencastAvailable$,
    this.devicesWarnings$
  ]).pipe(
    map(([devices, defaults, screencast, warnings]) => {
      const patchedSources = devices.map(device => {
        const defaultSource = defaults.find(base => {
          return base.kind === device.kind && base.id === device.deviceId;
        });
        return this.deviceInfoToSource(device, defaultSource);
      });

      for (const mediaSourceKind of Object.values(MediaSourceKind)) {
        if (
          !patchedSources.some(source => {
            return source.kind === mediaSourceKind && source.isDefault;
          })
        ) {
          const firstSource = patchedSources.find(source => {
            return source.kind === mediaSourceKind;
          });
          if (firstSource) {
            firstSource.isDefault = true;
            firstSource.enabled = true;
          }
        }
      }

      if (screencast) {
        patchedSources.push({
          kind: MediaSourceKind.VIDEO_INPUT,
          sourceType: DeviceSourceType.SCREEN
        } as UserMediaSource);
      }

      warnings.forEach(warningSource => {
        const patchedSource = patchedSources.find(source => {
          return this.compareSources(warningSource, source, true);
        });
        if (!patchedSource) {
          return;
        }
        patchedSource.warning = warningSource.warning;
      });

      return patchedSources;
    }),
    shareReplay(1)
    // todo повесить здесь проверку на изменения
  );

  defaultMediaOutputDevice$: Observable<UserMediaSource> = this.availableSources$.pipe(
    map(sources => {
      return (
        sources.find(source => {
          return source.kind === MediaSourceKind.AUDIO_OUTPUT;
        }) || null
      );
    }),
    take(1)
  );

  dynamicMediaOutputDevice$ = new Subject<UserMediaSource>();

  electronDesktopCapturer$: Observable<MediaStream> = this.isElectronApp
    ? of(null).pipe(
        switchMap(() => {
          return from(this.electronService.electronApi.invoke(ELECTRON_CHANNEL_LIST.PICK_SOURCE_LIST));
        }),
        switchMap(sources => {
          return this.dialog
            .open(ScreensharePickerComponent, {
              data: sources,
              disableClose: true,
              width: 'fit-content',
              position: {
                top: '30px'
              }
            })
            .afterClosed();
        }),
        withLatestFrom(this.screenSize$),
        switchMap(([screenSourceId, screenSize]) => {
          return screenSourceId ? <Observable<MediaStream>>from(
                navigator.mediaDevices.getUserMedia({
                  audio: false,
                  video: {
                    // @ts-ignore
                    mandatory: {
                      chromeMediaSource: 'desktop',
                      chromeMediaSourceId: screenSourceId,
                      minWidth: screenSize.width,
                      minHeight: screenSize.height
                    }
                  }
                })
              ) : of(null);
        })
      )
    : of(null);

  constructor(
    private appService: AppService,
    private electronService: ElectronService,
    private dialog: MatDialog,
    private logger: LoggerService,
    private localStorage: LocalStorage
  ) {
    this.requestAvailableDevices.next();
    merge(this.videoPermitted$, this.audioPermitted$).subscribe(() => {
      return this.requestAvailableDevices.next();
    });
  }

  // @ts-ignore
  enumerateDevices(): Observable<InputDeviceInfo[]> {
    // @ts-ignore
    return from(navigator.mediaDevices.enumerateDevices()) as Observable<InputDeviceInfo[]>;
  }

  getDisplayMediaSource(mediaTrackSettings?: MediaTrackSettings): UserMediaSource {
    if (!(navigator.mediaDevices && (<any>navigator.mediaDevices).getDisplayMedia)) {
      return null;
    }

    return <UserMediaSource>{
      id: 'vks-display-media-source',
      title: 'DISPLAY_TRANSLATION',
      sourceType: DeviceSourceType.SCREEN,
      kind: MediaSourceKind.VIDEO_INPUT,
      isDefault: false,
      displaySurface: mediaTrackSettings ? (<any>mediaTrackSettings).displaySurface : undefined
    };
  }

  deviceInfoToSource(deviceInfo: MediaDeviceInfo, defaultSourceBase: UserMediaSourceBase = null): UserMediaSource {
    const kind: MediaSourceKind = deviceInfo.kind as MediaSourceKind;

    let presentationMode: PresentationMode;
    // @ts-ignore
    if (deviceInfo.kind === MediaSourceKind.VIDEO_INPUT && typeof deviceInfo.getCapabilities === 'function') {
      // @ts-ignore
      const capabilities = deviceInfo.getCapabilities();

      if (Array.isArray(capabilities.facingMode)) {
        if (
          capabilities.facingMode.some(mode => {
            return mode === 'user';
          })
        ) {
          presentationMode = PresentationMode.USER;
        }
        if (
          capabilities.facingMode.some(mode => {
            return mode === 'environment';
          })
        ) {
          presentationMode = PresentationMode.ENVIRONMENT;
        }
      }
    }

    return <UserMediaSource>{
      id: deviceInfo.deviceId,
      groupId: deviceInfo.groupId,
      title: deviceInfo.label,
      sourceType: DeviceSourceType.HARDWARE,
      presentationMode,
      isDefault: !!defaultSourceBase,
      enabled: !!defaultSourceBase?.enabled,
      kind
    };
  }

  trackToSource(track: MediaStreamTrack): UserMediaSource {
    const settings = track.getSettings();
    let kind: MediaSourceKind;
    switch (track.kind) {
      case 'video':
        kind = MediaSourceKind.VIDEO_INPUT;
        break;
      case 'audio':
        kind = MediaSourceKind.AUDIO_INPUT;
        break;
    }

    const screenDeviceId = new RegExp(/^(screen|window):\w+:\w+$/);
    const isDisplaySource = (settings as any)?.displaySurface || screenDeviceId.test(settings?.deviceId);
    if (isDisplaySource) {
      return this.getDisplayMediaSource(settings);
    }

    return <UserMediaSource>{
      id: settings.deviceId || '',
      groupId: settings.groupId || '',
      presentationMode: settings.facingMode,
      displaySurface: (<any>settings).displaySurface,
      sourceType: DeviceSourceType.HARDWARE,
      kind
    };
  }

  /**
   * TODO Should be used __MediaDevicesService.resolveTracksByExistingSources`__
   */
  makeMediaStream(mediaStream: MediaStream, sources: UserMediaSource[], weakCompare = false): Observable<MediaStream> {
    if (!mediaStream) {
      mediaStream = new MediaStream();
    }

    mediaStream
      .getTracks()
      .filter(track => {
        return track.readyState === 'ended';
      })
      .forEach(track => {
        return mediaStream.removeTrack(track);
      });

    let tracks = mediaStream.getTracks();
    tracks
      .filter(track => {
        return !sources.some(source => {
          return this.compareSources(this.trackToSource(track), source, weakCompare);
        });
      })
      .forEach(track => {
        track.stop();
        mediaStream.removeTrack(track);
      });

    tracks = mediaStream.getTracks();
    const newSources = sources.filter(source => {
      return !tracks.some(track => {
        return this.compareSources(this.trackToSource(track), source, weakCompare);
      });
    });

    return this.resolveTracksBySources(newSources).pipe(
      map(resolvedSources => {
        resolvedSources
          .filter(source => {
            return source.track;
          })
          .forEach(source => {
            return mediaStream.addTrack(source.track);
          });
        this.logger.info('resolveTracks', {
          Requested: newSources,
          Resolved: resolvedSources,
          Errors: resolvedSources.filter(source => {
            return source.error;
          }).length,
          Summary: mediaStream.getTracks().map(this.trackToSource.bind(this))
        });
        return mediaStream;
      }),
      map(stream => {
        return stream;
      })
    );
  }

  resolveTracksByExistingSources(
    resolvedSources: ResolvedUserMediaSource[],
    requestedSources: UserMediaSource[]
  ): Observable<ResolvedUserMediaSource[]> {
    resolvedSources
      .filter(resolvedSource => {
        return !requestedSources.some(requestedSource => {
          return this.compareSources(resolvedSource, requestedSource);
        });
      })
      .forEach(resolvedSource => {
        resolvedSource.track.stop();
      });
    resolvedSources = resolvedSources.filter(source => {
      return source.track.readyState !== 'ended';
    });

    const newSources = requestedSources.filter(requestedSource => {
      return !resolvedSources.some(resolvedSource => {
        return this.compareSources(requestedSource, resolvedSource);
      });
    });

    return this.resolveTracksBySources(newSources).pipe(
      map(additionResolvedSources => {
        return [
          ...resolvedSources,
          ...additionResolvedSources.filter(source => {
            return source.track;
          })
        ];
      })
    );
  }

  resolveTracksBySources(mediaSources: UserMediaSource[]): Observable<ResolvedUserMediaSource[]> {
    if (!Array.isArray(mediaSources) || mediaSources.filter(isTruthy).length === 0) {
      return of([]);
    }

    return combineLatest(
      mediaSources
        .map(source => {
          if (!source) {
            return of(<ResolvedUserMediaSource>null);
          }

          if (!navigator.mediaDevices) {
            return of(<ResolvedUserMediaSource>{
              ...source,
              track: null,
              error: new Error('navigator.mediaDevices is not defined')
            });
          }

          if (source.resolveNull) {
            return of(<ResolvedUserMediaSource>{ ...source, track: null, error: new Error('Resolved null on demand') });
          }

          if (source.sourceType === DeviceSourceType.SCREEN) {
            if (typeof (<any>navigator.mediaDevices).getDisplayMedia !== 'function') {
              return of(<ResolvedUserMediaSource>{
                ...source,
                track: null,
                error: new Error('getDisplayMedia is not a function')
              });
            }

            if (this.electronService.isElectron) {
              return this.electronDesktopCapturer$.pipe(
                switchMap((stream: MediaStream) => {
                  return from(this.tryResolveScreenTrack(stream.getTracks()[0]));
                }),
                map((track: MediaStreamTrack) => {
                  return <ResolvedUserMediaSource>{ ...source, track };
                }),
                map(mediaSource => {
                  (<any>mediaSource.track).displayMedia = true;
                  return mediaSource;
                }),
                catchError(error => {
                  return of(<ResolvedUserMediaSource>{ ...source, track: null, error: error });
                })
              );
            }

            return from((<any>navigator.mediaDevices).getDisplayMedia()).pipe(
              switchMap((stream: MediaStream) => {
                return from(this.tryResolveScreenTrack(stream.getTracks()[0]));
              }),
              map((track: MediaStreamTrack) => {
                return <ResolvedUserMediaSource>{ ...source, track };
              }),
              map(mediaSource => {
                (<any>mediaSource.track).displayMedia = true;

                return mediaSource;
              }),
              catchError(error => {
                return of(<ResolvedUserMediaSource>{ ...source, track: null, error });
              })
            );
          } else if (source.sourceType === DeviceSourceType.HARDWARE) {
            if (typeof navigator.mediaDevices.getUserMedia !== 'function') {
              return of(<ResolvedUserMediaSource>{
                ...source,
                track: null,
                error: new Error('getUserMedia is not a function')
              });
            }

            return from(this.tryResolveHardwareTrack(source)).pipe(
              map(track => {
                return <ResolvedUserMediaSource>{ ...source, track };
              }),
              catchError(error => {
                return of(<ResolvedUserMediaSource>{ ...source, error });
              })
            );
          }
        })
        .filter(isTruthy)
    ).pipe(
      tap(sources => {
        return this.setSourceWarnings(sources);
      }),
      take(1)
    );
  }

  compareSources(source1: UserMediaSource, source2: UserMediaSource, weak = false): boolean {
    if (!(source1 && source2)) {
      return false;
    }

    return (
      source1.id === source2.id &&
      (weak || source1.groupId === source2.groupId) &&
      source1.kind === source2.kind &&
      source1.sourceType === source2.sourceType &&
      source1.presentationMode === source2.presentationMode
    );
  }

  setDefaultMediaSourcesIds(sources: (UserMediaSource | UserMediaSourceBase)[], withoutEnabled = false): void {
    let defaultSources: UserMediaSourceBase[] =
      JSON.parse(this.localStorage.getItem(DEFAULT_USER_MEDIA_SOURCES_KEY)) ?? [];
    let mediaSourceBases = sources.filter(isTruthy).map(({ id, kind, groupId, enabled }) => {
      return { id, kind, groupId, enabled } as UserMediaSourceBase;
    });
    const oldSources = defaultSources.filter(item => {
      return mediaSourceBases.find(source => {
        return source.kind === item.kind;
      });
    });
    if (withoutEnabled) {
      mediaSourceBases = mediaSourceBases.map(sourceBase => {
        const old = oldSources.find(source => {
          return source.kind === sourceBase.kind;
        });
        sourceBase.enabled = old.enabled ?? sourceBase.enabled;
        return sourceBase;
      });
    }

    defaultSources = defaultSources.filter(item => {
      return !mediaSourceBases.find(source => {
        return source.kind === item.kind;
      });
    });
    defaultSources.push(
      ...mediaSourceBases.filter(item => {
        return !defaultSources.find(source => {
          return source.kind === item.kind;
        });
      })
    );
    this.localStorage.setItem(
      DEFAULT_USER_MEDIA_SOURCES_KEY,
      JSON.stringify(
        defaultSources.filter(source => {
          return isTruthy(source.kind);
        })
      )
    );
    this.dynamicDefaultDevicesBases$.next(defaultSources);
  }

  setVideoBlurSetting(value: boolean): void {
    const newValue = JSON.stringify(value);
    const event = new StorageEvent('storage', {
      key: ENABLE_BLUR_KEY,
      newValue
    });
    this.localStorage.setItem(ENABLE_BLUR_KEY, newValue);
    window.dispatchEvent(event);
  }

  isBlurEnable(): boolean {
    const item = this.localStorage.getItem(ENABLE_BLUR_KEY);
    return JSON.parse(item) ?? false;
  }

  async tryResolveScreenTrack(track: MediaStreamTrack): Promise<MediaStreamTrack> {
    await track.applyConstraints({ frameRate: { min: 10, ideal: 20, max: 25 } });
    return track;
  }

  async tryResolveHardwareTrack(source: UserMediaSource): Promise<MediaStreamTrack> {
    const constraints: MediaStreamConstraints = {};

    let kind: 'video' | 'audio';
    switch (source.kind) {
      case MediaSourceKind.VIDEO_INPUT:
        kind = 'video';
        break;
      case MediaSourceKind.AUDIO_INPUT:
        kind = 'audio';
        break;
    }

    if (!kind) {
      return null;
    }

    constraints[kind] = {};

    const availableDevices = await this.availableDevices$.pipe(take(1)).toPromise();
    let arranged = false;

    const arrange = (field, capabilities): boolean => {
      if (typeof constraints[kind][field] === 'number') {
        const supports = navigator.mediaDevices.getSupportedConstraints();
        if (!capabilities[field] || !supports[field]) {
          return false;
        }

        constraints[kind][field] = {
          ideal: Math.max(capabilities[field].min, Math.min(capabilities[field].max, constraints[kind][field]))
        };
        return true;
      } else if (typeof constraints[kind][field] === 'object') {
        constraints[kind][field] = { ...capabilities[field], ...constraints[kind][field] };
        return true;
      }

      return false;
    };

    const device = availableDevices.find(device_ => {
      return this.compareSources(this.deviceInfoToSource(device_), source);
    });
    if (device && source.constraints) {
      constraints[kind] = { ...source.constraints };
      // @ts-ignore
      if (typeof device.getCapabilities === 'function') {
        // @ts-ignore
        const capabilities = device.getCapabilities();

        if (!this.appService.isMobileIosSafari()) {
          arrange('width', capabilities);
          arrange('height', capabilities);
          arrange('frameRate', capabilities);
        } else {
        }

        arranged = true;
      }
    }

    let mediaStream: MediaStream;

    if (source.id) {
      (<MediaTrackConstraints>constraints[kind]).deviceId = source.id;
    }

    try {
      mediaStream = await navigator.mediaDevices.getUserMedia(constraints);
      this.requestAvailableDevices.next();
    } catch (error) {
      throw error;
    }

    if (!mediaStream) {
      constraints[kind] = { deviceId: source.id };
      try {
        mediaStream = await navigator.mediaDevices.getUserMedia(constraints);
        this.requestAvailableDevices.next();
      } catch (error) {
        throw error;
      }
    }

    if (!mediaStream) {
      return null;
    }

    let track = mediaStream.getTracks()[0];
    this.requestAvailableDevices.next();

    if (source.id) {
      const settings = track.getSettings();
      if (settings.deviceId !== source.id) {
        track.stop();
        mediaStream = await navigator.mediaDevices.getUserMedia(constraints);
        track = mediaStream.getTracks()[0];
      }
    }

    if (!arranged) {
      if (typeof track.getCapabilities === 'function') {
        const capabilities = track.getCapabilities();
        let needApply = false;
        if (!this.appService.isMobileIosSafari()) {
          needApply = arrange('width', capabilities) || needApply;
          needApply = arrange('height', capabilities) || needApply;
          needApply = arrange('frameRate', capabilities) || needApply;
        }

        if (needApply) {
          try {
            await track.applyConstraints(constraints[kind] as MediaTrackConstraints);
          } catch (error) {
            this.logger.error(error);
            throw error;
          }
        }
      }
    }

    return track;
  }

  setSourceWarnings(sources: ResolvedUserMediaSource[]): void {
    const warnings = this.sourceWarnings.value || [];
    let needPush = false;

    sources.forEach(source => {
      let sourceWithWarning = warnings.find(source_ => {
        return this.compareSources(source_, source, true);
      });
      const warning = source.error;

      if (warning) {
        if (!sourceWithWarning) {
          sourceWithWarning = { ...source, warning };
          warnings.push(sourceWithWarning);
          needPush = true;
          return;
        }
        if (sourceWithWarning.warning === warning) {
          return;
        }
        sourceWithWarning.warning = warning;
        needPush = true;
        return;
      }

      if (!sourceWithWarning) {
        return;
      }

      delete sourceWithWarning.warning;
      warnings.splice(warnings.indexOf(sourceWithWarning), 1);
      needPush = true;
      return;
    });

    if (needPush) {
      this.sourceWarnings.next(warnings);
    }
  }

  stopTracks(args: { mediaStream?: MediaStream; tracks?: MediaStreamTrack[] }): void {
    const tracks = args.mediaStream?.getTracks() ?? args.tracks;
    if (!!tracks) {
      tracks.forEach(track => {
        return track.stop();
      });
    }
  }
}
