import { Action, createReducer, on } from '@ngrx/store';
import * as ChatUnreadMessageCountState from './chatUnreadMessage.state';
import * as ChatUnreadMessageCountActions from './chatUnreadMessage.actions';
import { CHAT_ID, UnreadMessagesCount } from '@breez/modules/chat';
import { isTruthy } from '@breez/shared/utilities/is-truthy';

// @ts-ignore
const reducer = createReducer(
  ChatUnreadMessageCountState.initialState,
  on(ChatUnreadMessageCountActions.loadUnreadMessagesSuccess, (state, { result }) => {
    if (!result || result.length === 0) {
      return ChatUnreadMessageCountState.adapter.removeAll(state);
    }
    const resultChatsId: CHAT_ID[] = result.map(entity => {
      return entity.chatId;
    });
    const unusableKeys: string[] = Object.values(state.entities)
      .map(entity => {
        return entity.chatId;
      })
      .filter(chatId => {
        return !resultChatsId.includes(chatId);
      })
      .map(String);
    const filterState: ChatUnreadMessageCountState.State = ChatUnreadMessageCountState.adapter.removeMany(
      unusableKeys,
      state
    );
    return ChatUnreadMessageCountState.adapter.upsertMany(result, filterState);
  }),
  on(ChatUnreadMessageCountActions.updateUnreadMessagesCount, (state, { readIds, unreadIds, chatId, baseCount }) => {
    const unreadMessagesCountModel = state.entities[chatId] ?? new UnreadMessagesCount({ chatId });
    let changes: Partial<UnreadMessagesCount> = {};
    if (isTruthy(baseCount)) {
      changes.baseCount = baseCount;
    }
    // baseCount = baseCount ?? unreadMessagesCountModel?.baseCount ?? 0;
    if (unreadIds && unreadIds.length > 0) {
      changes = { unreadIds: [...new Set([...unreadMessagesCountModel.unreadIds, ...unreadIds])] };
    }
    if (readIds && readIds.length > 0) {
      const unreadSet = new Set(changes?.unreadIds ?? unreadMessagesCountModel.unreadIds);
      baseCount = unreadMessagesCountModel?.baseCount ?? 0;
      readIds?.forEach(readId => {
        if (unreadSet.has(readId)) {
          unreadSet.delete(readId);
        } else {
          --baseCount;
        }
      });
      changes = { unreadIds: [...unreadSet], baseCount };
    }

    // @ts-ignore
    return ChatUnreadMessageCountState.adapter.updateOne(
      { id: chatId as any, changes },
      state.entities[chatId] ? state : ChatUnreadMessageCountState.adapter.upsertOne(unreadMessagesCountModel, state)
    );
  })
);

export function chatUnreadMessageCountReducer(
  state: ChatUnreadMessageCountState.State | undefined,
  action: Action
): ChatUnreadMessageCountState.State {
  return reducer(state, action);
}
