import { IParticipantRight } from '@breez/models/conference/participant-right.model';
import { Expose } from 'class-transformer';

export class ParticipantRight implements IParticipantRight {
  @Expose({ name: 'value' })
  value: any;

  @Expose({ name: 'key' })
  key: string;
}
