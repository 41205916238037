import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UsersRoutingModule } from './users-routing.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { USER_FEATURE_KEY } from '@breez/modules/users/+state/user-feature-key.const';
import { effects, reducers } from '@breez/modules/users/+state';
import { UsersService } from '@breez/modules/users/services/users.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    UsersRoutingModule,
    StoreModule.forFeature(USER_FEATURE_KEY, reducers),
    EffectsModule.forFeature(effects)
  ],
  providers: [UsersService]
})
export class UsersModule {}
