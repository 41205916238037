export enum ELECTRON_CHANNEL_LIST {
  PICK_SOURCE_LIST = 'PICK_SOURCE_LIST',
  GET_PRIMARY_DISPLAY = 'GET_PRIMARY_DISPLAY',
  WINDOW_UID = 'WINDOW_UID',
  ELECTRON_SECONDARY_WINDOW = 'ELECTRON_SECONDARY_WINDOW',
  WINDOW_SET_SIZE = 'WINDOW_SET_SIZE',
  WINDOW_GET_SIZE = 'WINDOW_GET_SIZE',
  WINDOW_SET_POSITION = 'WINDOW_SET_POSITION',
  WINDOW_GET_POSITION = 'WINDOW_GET_POSITION',
  WINDOW_MAXIMIZE = 'WINDOW_MAXIMIZE',
  WINDOW_SHOW = 'WINDOW_SHOW',
  UPDATE_BADGE_NATIVE = 'update-badge-native',
  UPDATE_BADGE = 'update-badge',
  OPEN_ICS_NATIVE = 'OPEN_ICS',
  SET_UPDATE_SETTINGS = 'SET_UPDATE_SETTINGS',
  FIX_FOCUS = 'FIX_FOCUS',
  INSTALL_UPDATE = 'INSTALL_UPDATE',
  UPDATE_PREPARED = 'UPDATE_PREPARED',
  UPDATE_AVAILABLE = 'UPDATE_AVAILABLE',
  SET_LANGUAGE = 'SET_LANGUAGE',
  WINDOW_OPEN_URL = 'WINDOW_OPEN_URL',
  WINDOW_OPEN_CHAT_BY_ID = 'WINDOW_OPEN_CHAT_BY_ID',
  LOG = 'LOG',
  OS_INFO = 'OS_INFO',
  SET_API = 'SET_API',
  SET_BASE = 'SET_BASE',
  SET_ADMIN = 'SET_ADMIN',
  DIALOG_OPEN = 'DIALOG_OPEN',
  BLOCK_INTERFACE = 'BLOCK_INTERFACE',
  EFFECTS_SDK = 'EFFECTS_SDK'
}
