<image-cropper
  [aspectRatio]="aspectRatio"
  [class.transparent]="!cropperLoaded"
  [cropper]="cropperPosition"
  [imageBase64]="imageFile?.base64"
  [maintainAspectRatio]="maintainAspectRatio"
  [onlyScaleDown]="true"
  [resizeToHeight]="resizeToHeight"
  [resizeToWidth]="resizeToWidth"
  (cropperReady)="onCropperReady()"
  (imageCropped)="handleCropped($event)"
></image-cropper>
<div *ngIf="!cropperLoaded" class="vks-logo pre" [class.en]="(selectedLang$ | async) !== 'ru'"></div>
