import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { ValidationError } from './validation-error.model';

const ERROR = {
  type: 'filemaxsize',
  label: 'MAX_SIZE_OF_FILE'
} as ValidationError;

export function fileSizeValidator(maxSizeMbPerFile: number, multipleFiles: boolean = false): ValidatorFn {
  const error = {
    ...ERROR,
    args: { size: maxSizeMbPerFile }
  };
  return (control: AbstractControl): ValidationErrors | null => {
    if (!isTruthy(control.value)) {
      return null;
    }

    let files = control.value;
    if (!multipleFiles) {
      files = [control.value[0] ? control.value[0] : control.value];
    }
    for (const file of files) {
      const fileSizeMb = Number(file.size) / (1024 * 1024);
      if (fileSizeMb > maxSizeMbPerFile) {
        return { [error.type]: error } as ValidationErrors;
      }
    }

    return null;
  };
}
