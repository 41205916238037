import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '@breez/modules/auth/services/auth.service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { User } from '@breez/models';
import { replayWhileSubs } from '@breez/shared/rxjs-operators';
import { UntypedFormControl } from '@angular/forms';
import { fromControl } from '@breez/shared/rxjs-operators/from-control';
import { map, take } from 'rxjs/operators';
import { StateService } from '@breez/shared/services/state.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { ChatListPopupData } from '../../interfaces';
import { Chat } from '../../models';

import { ChatEntity } from '@breez/modules/chat/models/+state/chatEntity';

@Component({
  selector: 'vks-chat-list-popup',
  templateUrl: './chat-list-popup.component.html',
  styleUrls: ['./chat-list-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatListPopupComponent implements OnInit {
  currentUser$: Observable<User> = this.authService.currentUser$.pipe(replayWhileSubs());

  isMobileDevice$: Observable<boolean> = this.stateService.isMobileDevice$;

  currentChatId$: Observable<any> = this.activatedRoute.queryParams.pipe(
    take(1),
    map(params => {
      return parseInt(params.id, 10);
    })
  );

  currentChatIdBuffer$: BehaviorSubject<number> = new BehaviorSubject<number>(-1);

  keyword = new UntypedFormControl('');
  keyword$: Observable<string> = fromControl(this.keyword, 300);

  chats: Chat[] | ChatEntity[] = this.data.chats;
  chats$: BehaviorSubject<Chat[] | ChatEntity[]> = new BehaviorSubject<Chat[] | ChatEntity[]>([]);
  filteredChats$: Observable<Chat[] | ChatEntity[]> = combineLatest([this.chats$, this.keyword$]).pipe(
    map(([chats, keywords]) => {
      if (!keywords) {
        return chats;
      }

      return chats.filter(chat => {
        if (!chat.name === null) {
          return chat;
        }

        const chatTitleWords = (
          (isTruthy(chat.name) && this.translateService.instant(chat.name)) ||
          /* TODO chat.getAvatarEntity(currentUser).name ||*/ ''
        )
          .toLocaleLowerCase()
          .split(/[\s.,?!]+/);

        return keywords
          .toLocaleLowerCase()
          .split(/[\s.,?!]+/)
          .every(keyword => {
            return chatTitleWords.some(word => {
              return word.includes(keyword);
            });
          });
      });
    })
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ChatListPopupData,
    private readonly authService: AuthService,
    private readonly stateService: StateService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.currentChatId$.subscribe(this.currentChatIdBuffer$);
    // надо ли прятать текущий чат?
    this.chats$.next(
      this.chats.filter(chat => {
        return chat.id !== this.currentChatIdBuffer$.getValue();
      })
    );
  }
}
