import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { OverlayContainer, OverlayEntry } from '@breez/modules/overlay/models';
import { OverlayEntryTypeResolver } from '@breez/modules/overlay/overlay-types/overlay-entry-type.resolver';
import { Observable } from 'rxjs';
import { BACKDROP_CLICK } from '@breez/modules/overlay/overlay-types/overlay-entry-event.provider';

@Component({
  selector: 'vks-overlay-entry-dynamic',
  template: `
    <div class="box">
      <ng-container
        [ngxComponentOutlet]="resolver | resolve: entry.type"
        [ngxComponentOutletInjector]="childInjector"
      ></ng-container>
    </div>
  `,
  styleUrls: ['./dynamic.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverlayEntryDynamicComponent implements OnChanges {
  @Input() entry: OverlayEntry;
  @Input() backdropClickTrigger: Observable<MouseEvent>;

  @Output() changeOverlayContainer = new EventEmitter<OverlayContainer>();

  childInjector: Injector;

  constructor(
    public resolver: OverlayEntryTypeResolver,
    private injector: Injector
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.backdropClickTrigger) {
      this.childInjector = Injector.create({
        parent: this.injector,
        providers: [{ provide: BACKDROP_CLICK, useValue: this.backdropClickTrigger }]
      });
    }
  }
}
