import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as ModuleState from '@breez/modules/chat/+state/module.state';
import { CHAT_FEATURE_KEY } from '@breez/modules/chat/+state';
import { createEntityAdapter } from '@ngrx/entity';
import { CHAT_ID } from '@breez/modules/chat';
import { MessageRange } from '@breez/modules/chat/models/+state/messageRange';
import { getMessages } from '@breez/modules/chat/+state/message';
import { isTruthy } from '@breez/shared/utilities/is-truthy';

export const getFeatureState = createFeatureSelector<ModuleState.State>(CHAT_FEATURE_KEY);
export const getState = createSelector(getFeatureState, state => {
  return state.viewRange;
});

const { selectAll } = createEntityAdapter<MessageRange>().getSelectors(getState);

export const ranges = selectAll;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const rangeByChatId = (chatId: CHAT_ID) => {
  return createSelector(getState, getMessages(chatId), (state, messages) => {
    const entity = state.entities[chatId];
    const total = messages.length;
    const anchorIndex = entity?.anchor
      ? (messages.findIndex(message => {
          return message.id === entity.anchor.id;
        }) ?? null)
      : null;

    return isTruthy(entity) ? { ...entity, total, anchorIndex } : undefined;
  });
};
