import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Pipe({
  name: 'formatDate',
  pure: false
})
export class FormatDatePipe extends DatePipe implements PipeTransform, OnDestroy {
  private cache;
  private previousParams = [];

  private destroyed$ = new Subject<void>();

  constructor(@Inject(LOCALE_ID) locale: string, translateService: TranslateService) {
    super(locale);
    translateService.onLangChange
      .pipe(
        map(event => {
          return event.lang;
        }),
        startWith(translateService.currentLang),
        takeUntil(this.destroyed$)
      )
      .subscribe(language => {
        (<any>this).locale = language;
        this.cache = null;
      });
  }

  // @ts-ignore
  transform(value: any, format?: string, timezone?: string, locale?: string): string | null {
    const currentParams = [value, format, timezone, locale];
    if (!this.cache || !this.previousParams.equals(currentParams)) {
      this.previousParams = currentParams;
      this.cache = super.transform(value, format, timezone, locale);
    }

    return this.cache;
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
