import { Conference } from './conference.model';
import { ObjectListResponse } from '@breez/models/shared/paging';
import { Expose, Type } from 'class-transformer';

export class ConferenceListResponse extends ObjectListResponse<Conference> {
  @Expose()
  @Type(() => {
    return Conference;
  })
  conferences: Conference[];
}
