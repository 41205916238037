import { provideCallStatusComponent } from '@breez/modules/call/call-statuses/call-status-component.provider';
import { CallStatusHoldComponent } from './components';
import { CallDestinationType, CallStatus } from '@breez/modules/call/models';

export const OVERLAY_PROVIDERS = [
  provideCallStatusComponent(
    {
      status: CallStatus.HOLD,
      destinationType: CallDestinationType.OUTGOING
    },
    CallStatusHoldComponent
  ),
  provideCallStatusComponent(
    {
      status: CallStatus.HOLD,
      destinationType: CallDestinationType.INCOMING
    },
    CallStatusHoldComponent
  )
];
