import { Injectable } from '@angular/core';
import { EntityReference } from '@breez/models/shared/entity-reference.model';
import { WebsocketEvents, WebsocketService } from '@breez/modules/websocket';
import { Room } from '@breez/modules/social/models/room.model';
import { arrToClass, toClass } from '@breez/shared/rxjs-operators';
import { ParticipantService } from '@breez/shared/services/participant.service';
import { Observable, of } from 'rxjs';
import { User } from '@breez/models';
import { map, switchMap } from 'rxjs/operators';
import { ObjectType } from '@breez/shared/enums/object-type.enum';

@Injectable({
  providedIn: 'root'
})
export class RoomsService {
  constructor(
    private websocketService: WebsocketService,
    private participantService: ParticipantService
  ) {}

  getRooms(): Observable<Room[]> {
    return this.websocketService.send(WebsocketEvents.SEND.ROOM.GET).pipe(arrToClass(Room));
  }

  // добавляется "комната", отвечающая за отсутствие выбора
  getRoomsForPlanner(): Observable<Room[]> {
    return this.getRooms().pipe(
      map(rooms => {
        return [new Room({}), ...rooms];
      })
    );
  }

  getRoom(id: number): Observable<Room> {
    return this.websocketService
      .send(WebsocketEvents.SEND.ROOM.GET, {
        id
      })
      .pipe(toClass(Room));
  }

  createRoom(subject: string, description: string): Observable<number> {
    return this.websocketService.send(WebsocketEvents.SEND.ROOM.CREATE, {
      data: {
        subject,
        description
      }
    });
  }

  deleteRoom(id: number): Observable<any> {
    return this.websocketService.send(WebsocketEvents.SEND.ROOM.DELETE, {
      data: { roomid: id }
    });
  }

  updateRoom(room: Room): Observable<number> {
    return this.websocketService.send(WebsocketEvents.SEND.ROOM.UPDATE, {
      data: {
        roomid: room.id,
        description: room.description,
        subject: room.name
      }
    });
  }

  addRoomParticipants(roomId: number, usersId: number[]): Observable<any> {
    return this.websocketService.send(WebsocketEvents.SEND.ROOM.ADD_PARTICIPANTS, {
      data: {
        roomid: roomId,
        participantids: usersId
      }
    });
  }

  removeRoomParticipants(roomId: number, usersId: number[]): Observable<any> {
    return this.websocketService.send(WebsocketEvents.SEND.ROOM.REMOVE_PARTICIPANTS, {
      data: {
        roomid: roomId,
        participantids: usersId
      }
    });
  }

  getSelectedRoomUsers(roomId: number): Observable<User[]> {
    return of(roomId).pipe(
      switchMap(selectedRoomId => {
        if (selectedRoomId < 1) {
          return of([]);
        }
        const object = new EntityReference({ type: ObjectType.ROOM, id: selectedRoomId });
        return this.participantService
          .getParticipants({
            object
          })
          .pipe(
            map(references => {
              return references.map(reference => {
                return reference.participantReference;
              }) as User[];
            })
          );
      })
    );
  }
}
