import { SimpleChanges } from '@angular/core';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { Subject } from 'rxjs';

export type OnChangeEmitter<V, S> = (value: V, subjectInstance: TargetSubject<V | S>, classContext) => void;

export type TargetSubject<V> = Subject<V>;

export interface EmittersMap<V, S> {
  [propertyName: string]: OnChangeEmitter<V, S>;
}

// eslint-disable-next-line unused-imports/no-unused-vars
interface BaseEmitOptions<V, S> {
  onlyTruthy?: boolean;
}

export interface SingleEmitOptions<V, S> extends BaseEmitOptions<V, S> {
  emitter?: OnChangeEmitter<V, S>;
}

export interface MultipleEmitOptions<V, S> extends BaseEmitOptions<V, S> {
  emitters?: EmittersMap<V, S>;
}

const baseOptions: BaseEmitOptions<any, any> = {
  onlyTruthy: false
};

const NG_COMPONENT_DEF = 'ɵcmp';

function EmitOnchangeSingle<V, S>(propertyName: string, options: SingleEmitOptions<V, S> = baseOptions) {
  options = {
    ...baseOptions,
    ...options
  };

  const onlyTruthy = options.onlyTruthy;
  const defaultEmitter: OnChangeEmitter<V, S> = (value, subject) => {
    return subject.next(value);
  };
  const emitter: OnChangeEmitter<V, S> = options.emitter || defaultEmitter;

  return (target: Object, propertyKey: string): void => {
    const targetPrototype = target.constructor[NG_COMPONENT_DEF].type.prototype;
    const originalOnChanges = targetPrototype.ngOnChanges;

    if (!originalOnChanges) {
      throw new Error(`${targetPrototype.constructor.name} использует EmitOnChanges, но не реализует ngOnChanges`);
    }

    targetPrototype.ngOnChanges = function (changes: SimpleChanges): void {
      const subject = this[propertyKey];
      const bindInputChanges = changes[propertyName];
      if (bindInputChanges && ((onlyTruthy && isTruthy(bindInputChanges.currentValue)) || !onlyTruthy)) {
        emitter.call(this, bindInputChanges.currentValue, subject, this);
      }
      originalOnChanges.call(this, changes);
    };
  };
}

function EmitOnchangeMultiple<S>(propertyName: string[], options: MultipleEmitOptions<any, S> = baseOptions) {
  options = {
    ...baseOptions,
    ...options
  };

  const onlyTruthy = options.onlyTruthy;
  const defaultEmitter: OnChangeEmitter<any, S> = (value, subject) => {
    return subject.next(value);
  };
  const emitters: EmittersMap<any, S> = options.emitters || {};

  const propertyEmittersKeys = Object.keys(emitters);
  if (propertyName.length === 0) {
    throw new Error('Количество привязанных @input компонента не может быть равно 0');
  } else {
    if (propertyEmittersKeys.length !== propertyName.length) {
      propertyName
        .filter(name => {
          return !propertyEmittersKeys.includes(name);
        })
        .forEach(name => {
          return (emitters[name] = defaultEmitter);
        });
    }
    propertyEmittersKeys.map(propertyEmitterName => {
      return (emitters[propertyEmitterName] = emitters[propertyEmitterName]);
    });
  }

  return (target: Object, propertyKey: string): void => {
    const targetPrototype = target.constructor[NG_COMPONENT_DEF].type.prototype;
    const originalOnChanges = targetPrototype.ngOnChanges;

    if (!originalOnChanges) {
      throw new Error(`${targetPrototype.constructor.name} использует EmitOnChanges, но не реализует ngOnChanges`);
    }

    targetPrototype.ngOnChanges = function (changes: SimpleChanges): void {
      const inputChanged = Object.keys(changes);
      const subject = this[propertyKey];
      let bindInputUpdated = inputChanged.filter(changedInput => {
        return propertyName.includes(changedInput);
      });
      if (onlyTruthy) {
        bindInputUpdated = bindInputUpdated.filter(inputName => {
          return isTruthy(changes[inputName].currentValue);
        });
      }
      if (bindInputUpdated.length) {
        bindInputUpdated.forEach(inputName => {
          return emitters[inputName].call(this, changes[inputName].currentValue, subject, this);
        });
      }
      originalOnChanges.call(this, changes);
    };
  };
}

export function EmitOnChange<V, S>(
  propertyName: string | string[],
  options: SingleEmitOptions<V, S> | MultipleEmitOptions<V, S> = baseOptions
): any {
  if (Array.isArray(propertyName)) {
    return EmitOnchangeMultiple(propertyName, options);
  } else {
    return EmitOnchangeSingle(propertyName, options);
  }
}
