import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TimePick } from '@breez/models/shared/time-pick.model';
import { isInteger } from '@breez/shared/utilities/isInteger';
import { pad } from '@breez/shared/utilities/pad';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { merge, Observable, ReplaySubject } from 'rxjs';
import { map, share, shareReplay } from 'rxjs/operators';

type ControlTimeComponentMode = 'text' | 'input';

@UntilDestroy()
@Component({
  selector: 'vks-control-time',
  templateUrl: './control-time.component.html',
  styleUrls: ['./control-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => {
        return ControlTimeComponent;
      }),
      multi: true
    }
  ]
})
export class ControlTimeComponent implements OnInit, ControlValueAccessor, OnDestroy, OnChanges {
  @Input() utc = false;
  @Input() mode: ControlTimeComponentMode = 'input';

  @Input() minutesItemsStep = 15;

  @Input() disable: boolean;

  minDate = new Date(0);

  changedTrigger: (value: any) => void;
  touchedTrigger: () => void;

  userInput = new UntypedFormControl();

  pickerInput$ = new ReplaySubject<TimePick>(1);

  userInput$: Observable<TimePick> = this.userInput.valueChanges.pipe(map(this.parseToTimePick), share());

  inputDate$: Observable<Date> = merge(this.pickerInput$, this.userInput$).pipe(
    map(pick => {
      if (!pick) {
        return null;
      }

      const date = new Date(0);

      if (this.utc) {
        date.setUTCHours(pick.hours);
        date.setUTCMinutes(pick.minutes);
      } else {
        date.setHours(pick.hours);
        date.setMinutes(pick.minutes);
      }

      return date;
    }),
    share()
  );

  dateFromAbove$ = new ReplaySubject<Date>(1);

  selectedDate$: Observable<Date> = merge(this.inputDate$, this.dateFromAbove$).pipe(shareReplay(1));

  userInputHours$: Observable<number> = this.selectedDate$.pipe(
    map(date => {
      return date && date instanceof Date ? (this.utc ? date.getUTCHours() : date.getHours()) : null;
    }),
    shareReplay(1)
  );

  userInputMinutes$: Observable<number> = this.selectedDate$.pipe(
    map(date => {
      return date && date instanceof Date ? (this.utc ? date.getMinutes() : date.getMinutes()) : null;
    }),
    shareReplay(1)
  );

  ngOnInit(): void {
    this.inputDate$.pipe(untilDestroyed(this)).subscribe(date => {
      if (this.changedTrigger && typeof this.changedTrigger === 'function') {
        this.changedTrigger(date);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disable && changes.disable.currentValue === true) {
      this.userInput.disable();
    } else {
      this.userInput.enable();
    }
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy(): void {}

  registerOnChange(fn: (value: any) => void): void {
    if (fn && typeof fn === 'function') {
      this.changedTrigger = fn;
    }
  }

  registerOnTouched(fn: () => void): void {
    if (fn && typeof fn === 'function') {
      this.touchedTrigger = fn;
    }
  }

  writeValue(date: Date): void {
    if (date && !(date instanceof Date)) {
      date = null;
    }
    this.dateFromAbove$.next(date);
    if (this.utc) {
      this.silentUpdateInputField(date ? { hours: date.getUTCHours(), minutes: date.getUTCMinutes() } : null);
    } else {
      this.silentUpdateInputField(date ? { hours: date.getHours(), minutes: date.getMinutes() } : null);
    }
  }

  updateTimeFromPicker(picked: TimePick): void {
    this.pickerInput$.next(picked);
    this.silentUpdateInputField(picked);
  }

  silentUpdateInputField(picked: TimePick): void {
    const text = picked ? `${pad(picked.hours, 2)}:${pad(picked.minutes, 2)}` : null;
    this.userInput.patchValue(text, { onlySelf: true, emitEvent: false });
  }

  parseToTimePick(value: string): TimePick {
    if (typeof value !== 'string') {
      return null;
    }

    const stringUnits = value.split(':');
    if (stringUnits.length !== 2) {
      return null;
    }

    const units = stringUnits.map(unit => {
      return parseInt(unit);
    });
    if (units.some(isNaN)) {
      return null;
    }

    const [hours, minutes] = units;

    if (!isInteger(hours) || !isInteger(minutes)) {
      return null;
    }

    if (0 > hours || hours > 23 || 0 > minutes || minutes > 59) {
      return null;
    }

    return { hours, minutes };
  }

  setTouched(): void {
    this.touchedTrigger();
  }
}
