import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { NavigationEnd, Router } from '@angular/router';
import { AppService } from '@breez/app.service';
import { ShortcutsDialogComponent } from '@breez/components/shortcuts-dialog/shortcuts-dialog.component';
import { environment } from '@breez/environment';
import { AuthService } from '@breez/modules/auth/services/auth.service';
import { CallsService } from '@breez/modules/call/services/calls.service';
import { CHATS_NOTIFICATION_KEY, ChatService, EventsObserverEnum } from '@breez/modules/chat';
import { PermissionsService } from '@breez/modules/permissions/services/permissions.service';
import { WebsocketService } from '@breez/modules/websocket/websocket.service';
import { BuildType } from '@breez/shared/enums/build-type.enum';
import { LocalStorage } from '@breez/shared/modules/storage/interfaces/local-storage.interface';
import { replayWhileSubs } from '@breez/shared/rxjs-operators';
import {
  CONFERENCE_CURRENT_PARAMS_KEY,
  CONFERENCE_RECORDS_CURRENT_PARAMS_KEY,
  CURRENT_CHAT_ID_KEY,
  StateService
} from '@breez/shared/services/state.service';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ElectronService } from '@breez/modules/core/services';
import { BehaviorSubject, combineLatest, forkJoin, fromEvent, merge, Observable, of, ReplaySubject, timer } from 'rxjs';
import {
  catchError,
  delay,
  distinctUntilChanged,
  filter,
  map,
  pluck,
  scan,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeWhile,
  tap,
  timeout,
  withLatestFrom
} from 'rxjs/operators';
import { SettingsService } from './modules/settings/services/settings.service';
import { SettingsSectionsKeys } from '@breez/modules/settings/enums/settings-section-keys';
import {
  CLIENT_ERS_CHANNEL,
  CLIENT_ERS_ENABLE_UPDATE,
  CLIENT_ERS_FLAVOR,
  CLIENT_ERS_URL,
  ElectronSettings
} from '@breez/models/settings/settings-electron';
import { ELECTRON_CHANNEL_LIST } from '../../electron-channel-list';
import { AppInfoModel } from './models/app-info.model';
import { RestApiService } from './rest-api.service';
import { PushNotificationsService } from '@breez/shared/push-service/push-notifications.service';
import { Store } from '@ngrx/store';
import * as AccountAction from '@breez/+state/account/account.actions';
import * as ModuleState from '@breez/+state/account/account.state';

const WAITING_TIMEOUT = 5000;
const RETRY_TIMEOUT = 5000;
const ELECTRON_WAITING_TIMEOUT = 3000;

@UntilDestroy()
@Component({
  selector: 'vks-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss', './app.component.media-max600.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
  routerActivateSubject$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  isProblemMessage$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isProblemMessageButton$ = this.isProblemMessage$.pipe(
    switchMap(isProblemMessage => {
      return !!isProblemMessage
        ? timer(RETRY_TIMEOUT).pipe(
            map(() => {
              return true;
            })
          )
        : of(false);
    })
  );

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(): void {
    this.ngOnDestroy();
  }

  readonly version = environment.version;
  readonly release = environment.release;
  readonly isElectronApp: boolean = this.electronService.isElectron;
  readonly isPwaApp: boolean = this.pwaService.isPwaApp;
  menuBurgerIconClass = 'square32';
  selectedLang$: Observable<string> = this.translateService.onLangChange.pipe(
    map((params: LangChangeEvent) => {
      return params.lang;
    }),
    startWith(this.translateService.currentLang),
    replayWhileSubs()
  );

  hideInterfaceState$: Observable<boolean> = this.stateService.hideInterface$;

  connectionWasLost$: Observable<boolean> = this.wsService.connectionWasLost$;

  showOopsPage$: Observable<boolean> = this.wsService.socketReconnect$.pipe(
    filter(() => {
      return !this.isElectronApp;
    }),
    scan(
      (connections, connection) => {
        // For testing websocket successful reconnect when oops page visible
        // return  connection ? [...new Set(connections.concat(connection))]: [connection] ;
        return [...new Set(connections.concat(connection))];
      },
      <boolean[]>[]
    ),
    map(connections => {
      return !connections.some(connection => {
        return connection;
      });
    })
  );

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private authService: AuthService,
    private appService: AppService,
    private wsService: WebsocketService,
    private translateService: TranslateService,
    private stateService: StateService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private router: Router,
    private callsService: CallsService,
    private electronService: ElectronService,
    private settingsService: SettingsService,
    private chatService: ChatService,
    private changeDetectorRef: ChangeDetectorRef,
    private localStorage: LocalStorage,
    private permissionsService: PermissionsService,
    private restApiService: RestApiService,
    private pwaService: PushNotificationsService,
    private store: Store<ModuleState.State>
  ) {}

  isMobileDevice$: Observable<boolean> = this.stateService.isMobileDevice$;
  isMenuOpened$: Observable<boolean> = this.stateService.isMenuOpened$.pipe(
    tap(() => {
      return this.changeDetectorRef.detectChanges();
    })
  );

  isMenuCompact$: Observable<boolean> = this.stateService.compactMenu$.asObservable().pipe(distinctUntilChanged());
  isMinimumMenuWidth$: Observable<boolean> = this.stateService.isMinimumContentWidth$;
  isMediumContentWidth$: Observable<boolean> = this.stateService.isMediumContentWidth$;
  hideSideBar$: Observable<boolean> = this.stateService.hideSideBar$;
  backdropShow$: Observable<boolean> = this.stateService.backdropShow$;

  browserRecommendationRest$: Observable<number> = this.restApiService.info$.pipe(
    pluck<AppInfoModel, 'browser'>('browser'),
    map(Number),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  browserRecommendation$ = /*merge(*/ this.browserRecommendationRest$ /*,this.browserRecommendationSocket$*/ /*)*/
    .pipe(startWith(0), distinctUntilChanged(), replayWhileSubs());

  appAvailable$ = this.browserRecommendation$.pipe(
    map(value => {
      return value === 5 || value === 0;
    }),
    replayWhileSubs()
  );

  expandedGUI$: Observable<boolean> = this.stateService.isExpandedGUI$.pipe(
    startWith(window.screen.width >= 800),
    shareReplay(1)
  );

  hideInterface$: Observable<boolean> = this.authService.currentUser$.pipe(
    startWith(null),
    switchMap(() => {
      return this.hideInterfaceState$;
    }),
    /*map(([currentUser,hideInterfaceState]) => {
			return
			/!*if (!this.isElectronApp) {
				return false;
			}
			return currentUser === undefined || currentUser === null;*!/
		}),*/
    shareReplay(1)
  );

  blockedApp$: Observable<boolean> = this.stateService.isBlockedGUI$.pipe(shareReplay());

  isLogin$: Observable<boolean> = this.authService.user$.pipe(
    startWith(null),
    map(currentUser => {
      return currentUser !== undefined && currentUser !== null;
    })
  );

  showGlobalHeader$: Observable<boolean> = combineLatest([this.expandedGUI$, this.hideInterface$]).pipe(
    map(([isExpanded, hideInterface]) => {
      return isExpanded && !hideInterface;
    }),
    startWith(false),
    distinctUntilChanged(),
    tap(() => {
      this.changeDetectorRef.detectChanges();
    })
  );

  menuElementClass$: Observable<string> = combineLatest([
    this.hideSideBar$,
    this.isMenuOpened$,
    this.expandedGUI$,
    this.hideInterface$
  ]).pipe(
    map(([hideSideBar, menuOpened, expandedGUI, hideInterface]) => {
      if (!expandedGUI || hideInterface) {
        return 'closed';
      }

      if (!hideSideBar) {
        return 'fixed';
      }

      if (menuOpened) {
        return 'fixed';
      }

      return 'closed';
    }),
    shareReplay(1)
  );

  mainWithOffset$: Observable<boolean> = combineLatest([this.isMediumContentWidth$, this.expandedGUI$]).pipe(
    map(([isMediumWidth, expandedGUI]) => {
      return isMediumWidth && expandedGUI;
    })
  );

  showNotSupportServicePage$: Observable<boolean> = combineLatest([
    this.browserRecommendation$,
    this.appAvailable$,
    this.appService.closeNotSupportPage$
  ]).pipe(
    map(([recommendationIndex, appAvailable, closeNotSupportPage]) => {
      if (this.isElectronApp || this.isPwaApp) {
        return false;
      }

      if (closeNotSupportPage) {
        return false;
      }
      const recommendationChecked = this.localStorage.getItem('browser-checked');
      return (!recommendationChecked && recommendationIndex !== 0 && recommendationIndex !== null) || !appAvailable;
    }),
    replayWhileSubs()
  );

  private readonly isProdBuild: boolean = environment.buildType === BuildType.K2T;
  private readonly isDemoBuild: boolean = environment.buildType === BuildType.DEMO;

  @HostListener('document:keyup', ['$event'])
  openShortcutsDialog($event: KeyboardEvent): void {
    if ($event.code === 'KeyG' && $event.ctrlKey) {
      $event.preventDefault();
      const openedDialog = this.document.querySelector('.mat-dialog-container');
      if (isTruthy(openedDialog)) {
        return;
      }
      this.dialog.open(ShortcutsDialogComponent, {
        width: '435px',
        panelClass: 'low-padding'
      });
    }
  }

  ngOnInit(): void {
    this.store.dispatch(AccountAction.loadAccount({}));
    this.stateService.saveToLocal(CHATS_NOTIFICATION_KEY, 0);
    this.routerActivateSubject$
      .pipe(
        take(1),
        timeout(environment.type === 'electron' ? ELECTRON_WAITING_TIMEOUT : WAITING_TIMEOUT),
        catchError(() => {
          this.isProblemMessage$.next(true);
          return of(null);
        })
      )
      .subscribe();

    this.translateService
      .stream(['CLOSE', 'DEMO_SNACKBAR_INFO'])
      .pipe(
        filter(_ => {
          return this.isDemoBuild;
        }),
        delay(1000),
        takeWhile(() => {
          return this.localStorage.getItem('demo-agreement') !== 'true';
        }),
        scan(
          (_, labels) => {
            if (this.snackBar && this.snackBar.dismiss) {
              this.snackBar.dismiss();
            }
            return this.snackBar.open(labels.DEMO_SNACKBAR_INFO, labels.CLOSE, {
              duration: 0,
              verticalPosition: 'top',
              panelClass: 'service-info'
            });
          },
          <MatSnackBarRef<SimpleSnackBar>>null
        ),
        switchMap(snack => {
          return snack.afterDismissed();
        })
      )
      .subscribe(event => {
        if (event.dismissedByAction) {
          this.localStorage.setItem('demo-agreement', 'true');
        }
      });

    if (this.electronService.isElectron) {
      this.electronService.electronApi.on(ELECTRON_CHANNEL_LIST.UPDATE_AVAILABLE, (_, store) => {
        this.translateService
          .get(['CLOSE', store.message])
          .pipe(
            take(1),
            scan(
              (snack, labels) => {
                if (snack) {
                  snack.dismiss();
                }
                return this.snackBar.open(labels[store.message], labels.CLOSE, {
                  duration: 0,
                  verticalPosition: 'top',
                  panelClass: 'service-info'
                });
              },
              <MatSnackBarRef<SimpleSnackBar>>null
            ),
            switchMap(snack => {
              return snack.afterDismissed();
            })
          )
          .subscribe();
      });

      this.electronService.electronApi.on(ELECTRON_CHANNEL_LIST.UPDATE_PREPARED, (_, store) => {
        this.translateService
          .get(['CLOSE_AND_INSTALL', store.message])
          .pipe(
            take(1),
            scan(
              (snack, labels) => {
                if (snack) {
                  snack.dismiss();
                }
                return this.snackBar.open(labels[store.message], labels.CLOSE_AND_INSTALL, {
                  duration: 0,
                  verticalPosition: 'top',
                  panelClass: 'service-info'
                });
              },
              <MatSnackBarRef<SimpleSnackBar>>null
            ),
            switchMap(snack => {
              return snack.afterDismissed();
            })
          )
          .subscribe(snackEvent => {
            if (snackEvent.dismissedByAction) {
              this.electronService.electronApi.send(ELECTRON_CHANNEL_LIST.INSTALL_UPDATE, true);
            }
          });
      });

      const electronSettingsKeys = [CLIENT_ERS_URL, CLIENT_ERS_FLAVOR, CLIENT_ERS_CHANNEL, CLIENT_ERS_ENABLE_UPDATE];

      const settingsValueByApi$: Observable<ElectronSettings> = this.restApiService.info$.pipe(
        filter(info => {
          return isTruthy(info.electron);
        }),
        pluck<AppInfoModel, 'electron'>('electron'),
        map(info => {
          return <ElectronSettings>{
            [CLIENT_ERS_ENABLE_UPDATE]: info.enable ? '1' : '0',
            [CLIENT_ERS_URL]: info.url,
            [CLIENT_ERS_FLAVOR]: info.flavor,
            [CLIENT_ERS_CHANNEL]: info.channel
          };
        }),
        filter(settings => {
          return settings[CLIENT_ERS_ENABLE_UPDATE] === '1';
        })
      );

      const settingsValues$: Observable<ElectronSettings> = this.settingsService
        .getValuesBySectionName(SettingsSectionsKeys.WEB)
        .pipe(
          map(section => {
            return section
              .filter(item => {
                return electronSettingsKeys.includes(item.key);
              })
              .map(({ key, value }) => {
                return { key, value };
              })
              .reduce((item, obj) => {
                item[obj.key] = obj.value;
                return item;
              }, {});
          }),
          filter(settings => {
            return settings[CLIENT_ERS_ENABLE_UPDATE] === '1';
          })
        ); //SettingsItemModel

      merge(settingsValueByApi$, settingsValues$)
        .pipe(take(1))
        .subscribe(settings => {
          this.electronService.electronApi.send(ELECTRON_CHANNEL_LIST.SET_UPDATE_SETTINGS, settings);
        });
    }

    combineLatest([this.appService.servicePeriod$, this.selectedLang$])
      .pipe(
        switchMap(([servicePeriod, language]) => {
          return this.translateService.get('CLOSE').pipe(
            map(closeMessage => {
              return { servicePeriod, language, closeMessage };
            })
          );
        }),
        untilDestroyed(this)
      )
      .subscribe(({ servicePeriod, language, closeMessage }) => {
        if (this.snackBar && this.snackBar.dismiss) {
          this.snackBar.dismiss();
        }
        if (!servicePeriod.enabled) {
          return;
        }

        let message = language === 'ru' ? servicePeriod.message.ru : servicePeriod.message.en;

        setTimeout(() => {
          this.snackBar.open(message, closeMessage, {
            duration: 0,
            verticalPosition: 'top',
            panelClass: 'service-info'
          });
        }, 500);
      });

    fromEvent(window, 'resize')
      .pipe(startWith(null))
      .subscribe(() => {
        const height = window.innerHeight;
        this.document.documentElement.style.setProperty('--window-height', height + 'px');
      });

    this.callsService.currentCalls$.subscribe(calls => {
      if (calls.length !== 0) {
        window.onbeforeunload = (event: any): void => {
          event.stopPropagation();
          event.preventDefault();
          event.returnValue = '';
          event.cancelBubble = true;
          return;
        };
      } else {
        window.onbeforeunload = undefined;
      }
    });
    this.chatService.observeMessagesEvents$(EventsObserverEnum.GLOBAL).subscribe();

    this.router.events
      .pipe(
        filter(event => {
          return event instanceof NavigationEnd;
        }),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.stateService.closeMenu();
        this.changeDetectorRef.detectChanges();
      });

    this.authService.roles$.pipe(untilDestroyed(this)).subscribe(roles => {
      return this.permissionsService.loadPermissions(roles);
    });

    this.connectionWasLost$
      .pipe(
        switchMap(isConnectionLost => {
          return forkJoin(
            this.translateService.get('CLOSE'),
            this.translateService.get('CONNECTION_LOST_SNACK_1')
          ).pipe(
            map(([closeMessage, message]) => {
              return { isConnectionLost, closeMessage, message };
            })
          );
        }),

        untilDestroyed(this),
        withLatestFrom(this.isLogin$)
      )
      .subscribe(([{ isConnectionLost, closeMessage, message }, isLogin]) => {
        if (isConnectionLost && isLogin) {
          this.snackBar.open(message, closeMessage, {
            duration: 0,
            verticalPosition: 'top',
            panelClass: 'connection-lost-info'
          });
        } else if (isConnectionLost !== null) {
          this.snackBar.dismiss();
        }
      });
    if (this.appService.isMobile) {
      this.pwaService.showInstallPwaComponentByCondition();
    }
  }

  ngOnDestroy(): void {
    this.stateService.saveToLocal(CURRENT_CHAT_ID_KEY, null);
    this.stateService.saveToLocal(CONFERENCE_CURRENT_PARAMS_KEY, null);
    this.stateService.saveToLocal(CONFERENCE_RECORDS_CURRENT_PARAMS_KEY, null);
  }

  changeMenuState(): void {
    this.stateService.changeMenuState();
    this.changeDetectorRef.detectChanges();
  }

  closeMenu(): void {
    this.stateService.closeMenu();
  }

  onActivate(): void {
    this.routerActivateSubject$.next(true);
  }

  onDeactivate(): void {
    this.routerActivateSubject$.next(false);
  }

  refresh(): void {
    window.location.reload();
  }
}
