import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProcessingFile } from '@breez/models/shared/files/processing-file.model';
import { UntypedFormControl } from '@angular/forms';
import { ImageSize } from '@breez/models/shared/image-size.model';

@Component({
  selector: 'vks-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageCropperComponent implements OnInit {
  imageControl = new UntypedFormControl();
  ratio: number = null;
  resizeTo: ImageSize = null;

  constructor(
    private dialogRef: MatDialogRef<ImageCropperComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogInputData: { file: ProcessingFile; ratio: number; resizeTo: ImageSize }
  ) {}

  ngOnInit(): void {
    const file = this.dialogInputData.file;

    if (this.dialogInputData.ratio) {
      this.ratio = this.dialogInputData.ratio;
    }

    if (this.dialogInputData.resizeTo) {
      this.resizeTo = this.dialogInputData.resizeTo;
    }

    this.imageControl.patchValue(file);
  }

  cancel(): void {
    this.dialogRef.close();
  }

  save(): void {
    this.dialogRef.close(this.imageControl.value);
  }
}
