import { Inject, Pipe, PipeTransform } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CompressedEmojiData, emojis, EmojiService } from '@ctrl/ngx-emoji-mart/ngx-emoji';

@Pipe({
  name: 'replaceEmojis'
})
export class ReplaceEmojisPipe implements PipeTransform {
  private static cachedEmojiRegex: RegExp;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private sanitizer: DomSanitizer,
    private emojiService: EmojiService
  ) {}

  /**
   * Regex matching all unicode emojis contained in emoji-mart
   */
  private get emojiRegex(): RegExp {
    if (ReplaceEmojisPipe.cachedEmojiRegex) {
      return ReplaceEmojisPipe.cachedEmojiRegex;
    }

    let characterRegexStrings: string[] = [];
    for (const emoji of emojis) {
      characterRegexStrings.push(
        this.emojiService.unifiedToNative(emoji.unified).replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
      );
    }

    characterRegexStrings = characterRegexStrings.sort((a, b) => {
      if (a.length > b.length) {
        return -1;
      }

      if (b.length > a.length) {
        return 1;
      }

      return 0;
    });

    const strings = characterRegexStrings;
    const reString = '(' + strings.join('|') + ')';
    ReplaceEmojisPipe.cachedEmojiRegex = new RegExp(reString, 'gu');

    return ReplaceEmojisPipe.cachedEmojiRegex;
  }

  transform(html: string, size = 18): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(this.emojisToImages(html, size));
  }

  /**
   * Replaces all unicode emojis available through emoji-mart with a span displaying
   * the image representation of that emoji
   * @param html The original html
   * @param size px size of emoji
   */
  emojisToImages(html: string, size = 18): string {
    // Ensure most html entities are parsed to unicode:
    const div = <Element>this.document.createElement('div');
    div.innerHTML = html;
    html = div.innerHTML;

    html = html
      // Replace zero width joins with their unicode representations:
      .replace(/&zwj;/g, '\u200d')

      // Replace every emoji with a span with a background image:
      .replace(this.emojiRegex, unicodeEmoji => {
        const hexCodeSegments = [];
        let i = 0;
        while (i < unicodeEmoji.length) {
          const segment = unicodeEmoji.codePointAt(i).toString(16).toUpperCase();
          hexCodeSegments.push(segment);

          i += Math.ceil(segment.length / 4);
        }
        const hexCode = hexCodeSegments.join('-');
        const matchingData = this.findEmojiData(hexCode);
        if (matchingData) {
          const span = document.createElement('span');
          span.style.width = `${size}px`;
          span.style.height = `${size}px`;
          span.style.display = 'inline-block';
          span.style.backgroundImage = `url(assets/images/emoji.png)`;
          span.style.backgroundSize = `${100 * 58}%`;
          const multiply = 100 / 57;
          span.style.backgroundPosition = `${multiply * matchingData.sheet[0]}% ${multiply * matchingData.sheet[1]}%`;
          span.style.textIndent = '-10000px';
          span.style.overflow = 'hidden';
          span.innerText = unicodeEmoji;

          return span.outerHTML;
        }

        return unicodeEmoji;
      });
    return html;
  }

  /**
   * Find raw emoji-mart data for a specific emoji hex code
   * @param hexCode String representation of the emoji hex code
   */
  private findEmojiData(hexCode: string): CompressedEmojiData {
    for (const emojiData of emojis) {
      if (emojiData.unified === hexCode) {
        return emojiData;
      }

      if (emojiData.skinVariations) {
        for (const skinVariation of emojiData.skinVariations) {
          if (skinVariation.unified === hexCode) {
            const skinData = Object.assign({}, emojiData);
            skinData.sheet = skinVariation.sheet;
            return skinData;
          }
        }
      }
    }

    return null;
  }
}
