import * as DateUtils from '@breez/shared/utilities/date-utils';
import { IFilterPeriod } from '@breez/models/shared/filtered-entity.model';
import { Periods } from '@breez/shared/enums/filters/periods.enum';
import moment from 'moment';

function day(): IFilterPeriod {
  return {
    start: DateUtils.getStartOfDay(),
    end: DateUtils.getEndOfDay()
  };
}

function week(): IFilterPeriod {
  return {
    start: DateUtils.getStartOfDay(DateUtils.getFirstMondayBeforeDate(new Date())),
    end: DateUtils.getEndOfDay(DateUtils.getEndOfWeek(new Date()))
  };
}

function currentWeek(): IFilterPeriod {
  return {
    start: DateUtils.getStartOfDay(),
    end: DateUtils.getEndOfDay(DateUtils.getEndOfWeek(new Date()))
  };
}

function nextWeek(): IFilterPeriod {
  const now = new Date();
  const start = DateUtils.getStartOfDay(DateUtils.getFirstMondayAfterDate(now));
  return {
    start,
    end: DateUtils.getEndOfDay(DateUtils.getEndOfWeek(start))
  };
}

function previousWeek(): IFilterPeriod {
  const now = new Date();
  now.setDate(now.getDate() - 7);
  return {
    start: DateUtils.getFirstMondayBeforeDate(now),
    end: DateUtils.getEndOfDay(DateUtils.getEndOfWeek(now))
  };
}

function month(): IFilterPeriod {
  return {
    start: DateUtils.getStartOfMonth(),
    end: DateUtils.getEndOfMonth()
  };
}

function currentMonthRelativeToNow(startFromNow: boolean): IFilterPeriod {
  let start, end;
  if (startFromNow) {
    start = DateUtils.getStartOfDay();
    end = DateUtils.getEndOfMonth();
  } else {
    start = DateUtils.getStartOfMonth();
    end = DateUtils.getEndOfDay();
  }
  return {
    start,
    end
  };
}

function treeDays(): IFilterPeriod {
  return {
    start: DateUtils.getNDayBeforeNow(2),
    end: DateUtils.getEndOfDay()
  };
}

function quarter(): IFilterPeriod {
  const startYear = DateUtils.getStartOfYear();
  let startQuarter;
  let endQuarter;
  const now = new Date();
  for (let i = 0; i < 4; i++) {
    startQuarter = moment(startYear)
      .add(i * 3, 'month')
      .toDate();
    endQuarter = moment(startYear)
      .add((i + 1) * 3, 'month')
      .subtract(1, 'day')
      .toDate();
    if (now?.getTime() <= DateUtils.getEndOfDay(endQuarter)?.getTime() && now?.getTime() >= startQuarter?.getTime()) {
      break;
    }
  }
  return {
    start: DateUtils.getStartOfDay(startQuarter),
    end: DateUtils.getEndOfDay(endQuarter)
  };
}

function halfYear(): IFilterPeriod {
  return {
    start: DateUtils.getStartOfDay(moment().subtract(6, 'months').toDate()),
    end: DateUtils.getEndOfDay()
  };
}

function year(): IFilterPeriod {
  return {
    start: DateUtils.getStartOfYear(),
    end: DateUtils.getEndOfYear()
  };
}

export const periodGettersMap = {
  [Periods.DAY]: day,
  [Periods.WEEK]: week,
  [Periods.CURRENT_WEEK]: currentWeek,
  [Periods.NEXT_WEEK]: nextWeek,
  [Periods.PREVIOUS_WEEK]: previousWeek,
  [Periods.MONTH]: month,
  [Periods.CURRENT_MONTH]: currentMonthRelativeToNow,
  [Periods.THREE_DAYS]: treeDays,
  [Periods.QUARTER]: quarter,
  [Periods.HALF_YEAR]: halfYear,
  [Periods.YEAR]: year
};
