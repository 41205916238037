export const WebsocketEvents = {
  CONFERENCE: {
    PARTICIPANT: {
      STATUS: {
        OBSERVE: 'conference.participant.observe',
        LEAVE: 'conference.participant.leave',
        EVENT: 'conference.participant'
      }
    }
  },
  USER: {
    GET: 'user.getAdvancedInfo',
    STATUS: {
      OBSERVE: 'user.status.observe',
      LEAVE: 'user.status.leave',
      EVENT: 'user.status'
    }
  },
  RECEIVE: {
    PONG: 'pong',
    ACCOUNT: {
      INIT: 'account.init',
      UPDATE: 'account.update',
      ACTIVE_PEERS: 'account.getActiveSessions',
      HAS_CONFERENCE: 'account.hasConference',
      GET_ROLES: 'account.getRoles',
      GET_LINKS: 'account.getLinks',
      HAS_ACCESS_TO_CONFERENCE: 'account.hasAccessToConference'
    },
    SESSION: {
      CLOSED: 'session.closed',
      LIMIT: 'session.limitExceeded'
    },
    AUTHORIZE: {
      LOGIN: 'auth.login',
      LOGOUT: 'auth.logout'
    },
    CONFERENCE: {
      GET_LAYOUTS: 'conference.getLayouts',
      GET_LAYOUT_FRAMES: 'conference.getLayoutFrames',
      LAYOUT: 'conference.layout',
      REMINDER: 'reminder',
      BANNED: 'conference.ban',
      LIST: {
        ALL: 'conference.get'
      },
      CERTAIN: 'conference.get',
      LEAVE: 'conference.leave',
      USER: {
        GET: 'user.get',
        OBSERVE: 'terminal',
        ROLES: 'user.getRoles'
      },
      PARTICIPANT_SESSION_INFO: 'conference.getParticipantSessionInfo',
      CALENDAR: 'conference.calendar',
      STATUS: 'conference.status',
      PARTICIPANTS: 'conference.participants'
    },
    USER: {
      LIST: 'user.get',
      CURRENT: 'user.get'
    },
    NOTIFICATION: {
      LIST: {
        ALL: 'notification.list',
        UNREAD: 'notification.list-unread'
      }
    },
    CHAT: {
      ADD_ANCHORAGE: 'chat.addAnchorage',
      REMOVE_ANCHORAGE: 'chat.removeAnchorage',
      GET_ACCESS_INFO: 'chat.getAccessInfo',
      MESSAGE: 'chat.message',
      GET: 'chat.get',
      READ_MESSAGES: 'chat.readMessages',
      LIST: 'chat.getList',
      BAN: 'chat.ban',
      WARNING: 'chat.banPrevention',
      GET_INFO: 'chat.getInfo',
      GET_BY_PARTICIPANTS: 'chat.getByParticipants',
      UPDATE: 'chat.update',
      STATUS: 'chat.status',
      UNREAD_MESSAGES_ID: 'chat.getUnreadMessageIds',
      REMOVE_PARTICIPANTS: 'chat.removeParticipants',
      ADD_PARTICIPANTS: 'chat.addParticipants',
      PARTICIPANTS_CHANGES: 'chat.participants',
      GET_MESSAGES: 'chat.getMessages',
      UPDATE_MESSAGE: 'chat.updateMessage',
      DELETE_MESSAGE: 'chat.deleteMessage',
      MESSAGES_STATUS: 'chat.messagestatus',
      CHAT_CHANGES: 'chat.unread'
    },
    MESSAGE: {
      INCOMING: 'message',
      GET_LIST: 'chat.getMessages',
      GET: 'message.get',
      SEND: 'message.send'
    },
    WEBRTC: {
      ICE: 'webrtc.ice',
      SDP: 'webrtc.sdp',
      CALL: 'webrtc.call',
      STATS: 'webrtc.stat',
      MESSAGE: 'webrtc.message'
    },
    WEBRTC_OLD: {
      ICE: 'ice',
      SDP: 'sdp'
    },
    CONFERENCE_SURVEY: {
      EVENT: 'question.status',
      GET: 'PollingQuestion.get'
    },
    CONFERENCE_SURVEY_ANSWER: {
      GET: 'PollingQuestion.getAnswers',
      EVENT: 'question.answer'
    },
    SETTINGS: {
      SECTION: {
        GET: 'settings.getSections'
      },
      VALUE: {
        GET: 'settings.getValues',
        GET_BY_SECTION: 'settings.getValuesBySection'
      },
      KEY: {
        GET: 'setting.getByKey'
      },
      BROWSER_INFO: 'setting.getBrowserInfo',
      SERVICE_PERIOD_INFORMATION: 'setting.getServicePeriodInfo',
      LAYOUT: {
        GET: 'setting.getConferenceLayouts'
      }
    },
    CONTACTS: {
      OBSERVE: 'contacts'
    },
    STATISTICS: {
      GET: 'statistics.get',
      GET_DETAILS: 'statistics.getDetails',
      STATISTICS_CHANGES: 'conference.terminalstatistics'
    },
    TEMPLATE: {
      GET: 'conference.getTemplate'
    },
    TEMPLATES: {
      GET: 'conference.getTemplates'
    },
    CALL: {
      STATUS: 'call.status'
    }
  },
  SEND: {
    PING: 'ping',
    RECORD: {
      GET: 'record.get',
      DELETE: 'record.delete',
      UPDATE: 'record.update',
      GET_PARTICIPANTS: 'record.getParticipants',
      ADD_PARTICIPANTS: 'record.addParticipants',
      REMOVE_PARTICIPANTS: 'record.removeParticipants',

      SET_PARTICIPANTS: 'records.setParticipants',
      CHANGE_ACCESS: 'record.changeAccess'
    },
    WEBRTC: {
      CONNECT: 'webrtc.connect',
      ICE: 'webrtc.ice',
      SDP: 'webrtc.sdp',
      CALL: 'webrtc.call',
      STATS: 'webrtc.stat'
    },
    NOTIFICATION: {
      SEND_BY_TEMPLATE: 'Notification.sendByTemplate'
    },
    SESSION: {
      IDENTIFICATION: 'ident.client',
      GEOLOCATION: 'ident.geolocation'
    },
    AUTHORIZE: {
      RECONNECT: 'auth.reconnect',
      TOKEN: 'auth.token',
      LOGIN: 'auth.login',
      LOGIN_BY_EMAIL: 'auth.loginByEmail',
      SEND_CONFIRMATION_EMAIL: 'auth.sendConfirmationEmail',
      LOGOUT: 'auth.logout'
    },
    USER: {
      CURRENT: 'account.get',
      LIST: 'user.get',
      UPDATE: 'user.update'
    },
    ROOM: {
      GET: 'room.get',
      CREATE: 'room.create',
      DELETE: 'room.delete',
      UPDATE: 'room.update',
      GET_PARTICIPANTS: 'room.getParticipants',
      ADD_PARTICIPANTS: 'room.addParticipants',
      REMOVE_PARTICIPANTS: 'room.removeParticipants'
    },
    CONFERENCE: {
      CHANGE_VOICE_ACTIVATE: 'conference.changeVoiceActivate',
      MULTICAST: {
        COUNT_CHECK: 'conference.checkMcastCount'
      },
      LAYOUT: {
        CHANGE: 'conference.changeLayout'
      },
      SPEECH: {
        REQUEST: 'conference.sendRequestForSpeech',
        CANCEL: 'conference.cancelRequestForSpeech'
      },
      BAN: 'conference.ban',
      ENTER: 'conference.enter',
      LEAVE: 'conference.leave',
      REGISTER: 'conference.register',
      REGISTER_BY_EMAIL: 'conference.registerByEmail',
      AUTH_BY_TOKEN: 'conference.authByToken',
      VERIFY_CODE: 'conference.verifyCode',
      GET_ACCESS_INFO: 'conference.getAccessInfo',
      REQUEST_ACCESS: 'conference.requestAccess',
      PARTICIPANT_INFO: 'conference.getParticipantsInfo',
      INVITE_PARTICIPANTS: 'conference.inviteParticipants',
      RECORD: 'conference.record',
      REMOVE_PARTICIPANTS: 'conference.removeParticipants',
      ADD_PARTICIPANTS: 'conference.addParticipants',
      SCHEDULE: {
        GET: 'schedule.get'
      },
      USER: {
        GET: 'user.get',
        DELETE: 'user.delete',
        OBSERVE: 'terminal.observe',
        LEAVE: 'terminal.leave',
        ADD: 'user.put',
        ADD_BY_ID: 'conference.addParticipantById',
        ROLES: 'user.setRoles'
      },
      UPDATE: 'conference.update',
      DELETE: 'conference.delete',
      CREATE: 'conference.create',
      EDIT: 'conference.edit',
      EXISTS: 'conference.exists',
      STATUS_CHANGE: 'conference.updateStatus',
      GET_ID_BY_ALIAS: 'conference.getIdByAlias',
      PARTICIPANT_RIGHTS: {
        OBSERVE: 'conference.participantrights.observe',
        MESSAGE: 'conference.participantrights',
        LEAVE: 'participantrights.leave'
      },
      REMINDER: {
        OBSERVE: 'conference.reminder.observe',
        LEAVE: 'conference.reminder.leave'
      },
      STATUS: {
        OBSERVE: 'conference.status.observe',
        LEAVE: 'conference.status.leave'
      },
      START: 'conference.start',
      SNAPSHOT: {
        OBSERVE: 'conference.snapshot.observe',
        LEAVE: 'conference.snapshot.leave'
      },
      STATISTICS: {
        OBSERVE: 'conference.terminalStatistics.observe',
        LEAVE: 'conference.terminalStatistics.leave'
      }
    },
    CHAT: {
      WARNING: 'chat.banPrevention',
      BAN: 'chat.ban',
      ENTER: 'chat.message.observe',
      ENTER_CONFERENCE: 'chat.enterConferenceChat',
      LEAVE: 'chat.message.leave',
      CHANGE_REACTION:'chat.changeReactionForMessage',
      CREATE: 'chat.create',
      FORWARD_MESSAGES: 'chat.forwardMessages'
    },
    MESSAGE: {
      GET: 'message.get',
      SEND: 'chat.addMessage',
      EDIT: 'message.edit',
      ATTACH: 'message.attach'
    },
    DIVISIONS: {
      GET: 'division.getList'
    },
    CONFERENCE_SURVEY: {
      ADD: 'PollingQuestion.add'
    },
    CONFERENCE_SURVEY_ANSWER: {
      ADD: 'PollingQuestion.addAnswer'
    },
    SETTING: {
      SCHEDULE: {
        CHECK: 'setting.checkPeriodBySchedule',
        GET: 'setting.getScheduleByPeriod'
      }
    },
    SETTINGS: {
      VALUE: {
        SET: 'settings.setValue'
      }
    },
    CONTACT: {
      ADD: 'contact.addToContactList',
      DELETE: 'contact.deleteFromContactList'
    },
    CONTACTS: {
      ADD: 'userGroup.addContacts',
      DELETE: 'userGroup.removeContacts',
      OBSERVE: 'contacts.observe',
      GET: 'userGroup.getContacts',
      GET_USER_GROUPS: 'contact.getUserGroups',
      USER_GROUP: {
        ADD: 'userGroup.addGroup',
        EDIT: 'userGroup.editGroup',
        DELETE: 'userGroup.deleteGroup',
        GET: 'userGroup.getGroups'
      }
    },
    FILE: {
      ADD: 'file.add',
      GET: 'file.get',
      DELETE: 'file.delete',
      EDIT: 'file.edit'
    },
    OBJECT: {
      PARTICIPANTS: {
        GET: 'object.getParticipants',
        OBSERVE: 'object.participant.observe',
        LEAVE: 'object.participant.leave',
        EVENT: 'object.participant'
      },
      PARTICIPATION_INTERVALS_INFO: 'object.getInfoUsersParticipationToIntervals',
      PARTICIPATION_INFO: 'object.getInfoFromUsersParticipation'
    },
    INVITING: {
      GENERATE_INVITE: 'user.generateInvite'
    }
  },
  PWA: {
    SET_PUSH_SUBSCRIPTION: 'notification.setPushSubscription'
  }
};
