import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { TemplatePortal } from '@angular/cdk/portal';
import { Overlay, OverlayConfig, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { merge, Observable, Subject } from 'rxjs';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { ESCAPE } from '@angular/cdk/keycodes';

@Component({
  selector: 'vks-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopupComponent implements OnInit {
  @Input() under: ElementRef;
  @Input() template: TemplateRef<any>;
  @ViewChild('content') content: TemplateRef<any>;

  overlayRef$ = new Subject<OverlayRef>();

  dynamicCloseEvent$ = new Subject<Event>();

  escapeKeyEvent$: Observable<KeyboardEvent> = this.overlayRef$.pipe(
    switchMap(overlay => {
      return overlay.keydownEvents();
    }),
    filter(event => {
      return event.keyCode === ESCAPE;
    })
  );

  detachmentsEvent$: Observable<Event> = this.overlayRef$.pipe(
    switchMap(overlay => {
      return overlay.detachments().pipe(
        map(() => {
          return new Event('overlaydetachments');
        })
      );
    })
  );

  backdropClickEvent$: Observable<MouseEvent> = this.overlayRef$.pipe(
    switchMap(overlay => {
      return overlay.backdropClick();
    })
  );

  needCloseEvent$: Observable<{ event: Event; ref: OverlayRef }> = merge(
    this.escapeKeyEvent$,
    this.detachmentsEvent$,
    this.backdropClickEvent$,
    this.dynamicCloseEvent$
  ).pipe(
    withLatestFrom(this.overlayRef$),
    map(([event, ref]) => {
      return { event, ref };
    })
  );

  constructor(
    private viewContainerRef: ViewContainerRef,
    private viewElementRef: ElementRef,
    private matOverlay: Overlay
  ) {}

  ngOnInit(): void {
    this.needCloseEvent$.subscribe(({ event, ref }) => {
      event.preventDefault();
      ref.detach();
    });
  }

  open(): void {
    const portal = new TemplatePortal(this.template || this.content, this.viewContainerRef);
    const overlayConfig = new OverlayConfig({
      positionStrategy: this.createPopupPositionStrategy(),
      scrollStrategy: this.matOverlay.scrollStrategies.reposition(),
      hasBackdrop: true
    });
    const overlay = this.matOverlay.create(overlayConfig);
    overlay.attach(portal);
    this.overlayRef$.next(overlay);
  }

  close(): void {
    this.dynamicCloseEvent$.next(new Event('popupclose'));
  }

  private createPopupPositionStrategy(): PositionStrategy {
    return this.matOverlay
      .position()
      .flexibleConnectedTo(this.under ? this.under : this.viewElementRef)
      .withFlexibleDimensions(false)
      .withViewportMargin(8)
      .withLockedPosition()
      .withPositions([
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top'
        },
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom'
        }
      ]);
  }
}
