import { Injectable } from '@angular/core';
import { WebsocketEvents, WebsocketService } from '@breez/modules/websocket';
import { toClass } from '@breez/shared/rxjs-operators';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChatBanEvent } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ChatBanEventsService {
  constructor(private readonly websocketService: WebsocketService) {}

  private readonly banChatEvents$: Observable<ChatBanEvent> = this.websocketService
    .listen({ path: WebsocketEvents.RECEIVE.CHAT.BAN })
    .pipe(
      map((event: { [key: string]: {} }) => {
        return event.data;
      }),
      toClass(ChatBanEvent)
    );

  listenChatsBanEvents(): Observable<ChatBanEvent> {
    return this.banChatEvents$;
  }
}
