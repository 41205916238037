import { ElementRef, Injectable, InjectionToken, Injector } from '@angular/core';
import { ConnectedPosition, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { MatDialog } from '@angular/material/dialog';
import { ContactCardComponent } from '@breez/modules/social/components/contact-card/contact-card.component';
import { take } from 'rxjs/operators';
import { ComponentPortal } from '@angular/cdk/portal';
import { ContactCardWrapperComponent } from '@breez/modules/social/components/contact-card-wrapper/contact-card-wrapper.component';
import { UserGroup } from '@breez/modules/social/models';
import { Observable, ReplaySubject } from 'rxjs';

export const CURRENT_CARD_DATA = new InjectionToken<UserCardData>('Current card data');

export interface UserCardData {
  userId: number;
  selectedUserGroup: UserGroup;
  isMobileDevices: boolean;
  overlayRef?: OverlayRef;
}

@Injectable({
  providedIn: 'root'
})
export class UserCardService {
  isCardVisible$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  private overlayRef: OverlayRef;

  constructor(
    private overlay: Overlay,
    private dialog: MatDialog
  ) {
    this.isCardVisible$.next(false);
  }

  createUserCardDialog(userId, isMobileDevices = false, center = true, selectedUserGroup = null): Observable<any> {
    this.closeDialog();
    return this.dialog
      .open(ContactCardComponent, {
        data: { userId, selectedUserGroup, isMobileDevices },
        scrollStrategy: center ? this.overlay.scrollStrategies.reposition() : this.overlay.scrollStrategies.noop(),
        closeOnNavigation: true,
        hasBackdrop: center,
        maxHeight: !isMobileDevices ? '90vh' : 'fit-content',
        minHeight: !isMobileDevices ? '423px' : 'fit-content',
        width: !isMobileDevices ? '552px' : '80vw',
        maxWidth: !isMobileDevices ? '600px' : '80vw',
        restoreFocus: false,
        autoFocus: false
      })
      .afterClosed()
      .pipe(take(1));
  }

  createUserCardOverlay(
    userId: number | string,
    isMobileDevices = false,
    _center = true,
    element: ElementRef = null,
    toTop = false
  ): void {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }

    const positions = !toTop
      ? [
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'top'
          } as ConnectedPosition,
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'bottom'
          } as ConnectedPosition,
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'top'
          } as ConnectedPosition,
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'bottom'
          } as ConnectedPosition
        ]
      : [
          {
            originX: 'end',
            originY: 'center',
            overlayX: 'start',
            overlayY: 'bottom'
          } as ConnectedPosition
        ];

    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(element)
      .withFlexibleDimensions(false)
      .withPositions(positions);

    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create({
        positionStrategy: positionStrategy,
        scrollStrategy: this.overlay.scrollStrategies.reposition(),
        disposeOnNavigation: true,
        width: 300,
        height: 500
      });
    } else {
      this.overlayRef.updatePositionStrategy(positionStrategy);
    }

    const injector = Injector.create({
      providers: [
        {
          provide: CURRENT_CARD_DATA,
          useValue: { userId, selectedUserGroup: null, isMobileDevices, overlayRef: this.overlayRef }
        }
      ]
    });
    const contactCardComponentPortal = new ComponentPortal(ContactCardWrapperComponent, null, injector);
    const ref = this.overlayRef.attach(contactCardComponentPortal);
    ref.instance.closePanel.subscribe(() => {
      return this.closeOverlay();
    });
    this.isCardVisible$.next(true);
  }

  closeDialog(): void {
    this.dialog.closeAll();
  }

  closeOverlay(): void {
    this.isCardVisible$.next(false);
    this.overlayRef.detach();
  }
}
