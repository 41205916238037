import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChange,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: '[vks-button]',
  templateUrl: './control-button.component.html',
  styleUrls: ['./control-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ControlButtonComponent implements OnChanges {
  @Output() buttonClick: EventEmitter<PointerEvent> = new EventEmitter();
  @Input() icon: string;
  @Input() iconPos: 'left' | 'right' = 'left';
  @Input() iconClass: string;
  @Input() color: string;

  @HostBinding('class.btn-icon-only') get isOnlyIcon(): boolean {
    return !this.label;
  }

  @HostListener('click', ['$event'])
  onHostButtonClick($event: PointerEvent): void {
    if (!this.disabled) {
      this.elementRef.nativeElement.blur();
      this.buttonClick.emit($event);
    } else {
      $event.preventDefault();
      $event.stopPropagation();
    }
  }

  @Input() label: string;

  @Input() size: 'lg' | 'sm';

  // TODO #ShvetsDaniil BREEZ-629: Привести использования спиннеров в control-button.component к единому виду
  @HostBinding('class.btn-loading')
  @Input()
  loading: boolean = false;

  @HostBinding('class.btn-lg') get isButtonLgClass(): boolean {
    return this.size === 'lg';
  }

  @HostBinding('class.btn-sm') get isButtonSmClass(): boolean {
    return this.size === 'sm';
  }

  @HostBinding('class.btn-disabled')
  @Input()
  disabled = false;

  @HostBinding('attr.type')
  @Input()
  buttonType = 'button';

  spinnerDiameter: number = 19;
  iconPosition: 'left' | 'right' | 'center';

  spinnerSizes = {
    lg: 23,
    sm: 14
  };

  @Input()
  spinnerIcon: string;

  constructor(private readonly elementRef: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.icon && (this.changeDefined(changes.label) || this.changeDefined(changes.iconPos))) {
      if (this.label) {
        this.iconPosition = this.iconPos;
      } else {
        this.iconPosition = 'center';
      }
    }
    if (this.changeDefined(changes.size)) {
      this.spinnerDiameter = this.spinnerSizes[this.size];
    }
  }

  private changeDefined(change: SimpleChange): any {
    return change && change.currentValue;
  }
}
