import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OverlayCallModule } from '@breez/modules/call/overlay-call/overlay-call.module';
import { OverlayUserListModule } from '@breez/modules/user/overlay-user-list/overlay-user-list.module';
import { OverlayNotificationModule } from '@breez/modules/notification/overlay-notification/overlay-notification.module';
import { OverlayDynamicModule } from './overlay-types/dynamic/dynamic.module';

import { MODULE_COMPONENTS, MODULE_EXPORTCOMPONENTS } from './components';
import { ImagePreviewModule } from '@breez/modules/image-preview/image-preview.module';
import { DragDropModule } from '@angular/cdk/drag-drop';

const OVERLAY_TYPES_MODULES = [OverlayCallModule, OverlayNotificationModule, OverlayUserListModule, ImagePreviewModule];

@NgModule({
  declarations: [...MODULE_COMPONENTS],
  imports: [OverlayDynamicModule, ...OVERLAY_TYPES_MODULES, CommonModule, DragDropModule],
  exports: [...MODULE_EXPORTCOMPONENTS]
})
export class OverlayModule {}
