<h3>{{ message }}</h3>
<mat-dialog-actions>
  <button
    vks-button
    [label]="'RECONNECT' | translate"
    (buttonClick)="resolve()"
    buttonType="submit"
    class="btn-primary"
  ></button>
</mat-dialog-actions>
