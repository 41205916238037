<ng-container *ngFor="let filterItem of filters" [ngSwitch]="filterTypes[filterItem.type]">
  <ng-container *ngIf="!filterItem.isHidden">
    <ng-container *ngSwitchCase="filterTypes.SELECT_OPTION_FILTER">
      <ng-container *ngTemplateOutlet="selectOption"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="filterTypes.DURATION_FILTER">
      <ng-container *ngTemplateOutlet="selectOption"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="filterTypes.DATE_RANGE_FILTER">
      <ng-container *ngTemplateOutlet="dateRange"></ng-container>
    </ng-container>

    <ng-template #dateRange>
      <vks-date-range-filter [item]="filterItem" [valueFromParams]="filterValues"> </vks-date-range-filter>
    </ng-template>
    <ng-template #selectOption>
      <vks-select-option-filter [item]="filterItem" [valueFromParams]="filterValues[filterItem.paramName]">
      </vks-select-option-filter>
    </ng-template>
  </ng-container>
</ng-container>
