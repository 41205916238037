import { AfterContentInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[vks-auto-focus]'
})
export class AutoFocusDirective implements AfterContentInit {
  constructor(private el: ElementRef) {}

  ngAfterContentInit(): void {
    setTimeout(() => {
      this.el.nativeElement.focus();
    }, 300);
  }
}
