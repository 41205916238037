import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: '[vks-ctextarea]',
  templateUrl: './control-textarea.component.html',
  styleUrls: ['./control-textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ControlTextareaComponent implements OnInit, OnDestroy {
  @Input() autoResize = true;

  lineHeight = 18;

  @HostBinding('style.line-height')
  styleLineHeight = `${this.lineHeight}px`;

  constructor(
    private control: NgControl,
    private element: ElementRef
  ) {}

  ngOnInit(): void {
    this.control.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      if (!this.autoResize) {
        return;
      }
      const height = this.element.nativeElement.offsetHeight,
        contentHeight = this.element.nativeElement.scrollHeight;
      if (height + this.lineHeight >= contentHeight) {
        this.element.nativeElement.style.height = contentHeight + 2 + 'px';
      }
    });
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy(): void {}
}
