import { createEntityAdapter } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
// import {Chat} from '@breez/modules/chat/models';
import * as ModuleState from '../module.state';
import { CHAT_FEATURE_KEY } from '@breez/modules/chat/+state/chat-feature-key.const';
import { CHAT_ID, MessageDraftEntity } from '@breez/modules/chat';

export const getFeatureState = createFeatureSelector<ModuleState.State>(CHAT_FEATURE_KEY);
export const getState = createSelector(getFeatureState, state => {
  return state?.draft;
});

const { selectAll } = createEntityAdapter<MessageDraftEntity>().getSelectors(getState);

export const drafts = selectAll;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const chatDraft = (chatId: CHAT_ID) => {
  return createSelector(getState, state => {
    return state?.entities[chatId]?.draft ?? '';
  });
};
