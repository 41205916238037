import { InjectionToken, Provider, Type } from '@angular/core';
import { NgxdProvider } from '@ngxd/core';
import { CallDestinationType, CallStatus } from '@breez/modules/call/models';

export interface CallStatusProviderType {
  status: CallStatus;
  destinationType?: CallDestinationType;
}

export interface CallStatusComponentProvider extends NgxdProvider<string, Type<any>> {}

export const CALLSTATUS_PROVIDER = new InjectionToken<string>('Call Status Provider');

export function generateCallStatusProviderType({ status, destinationType }: CallStatusProviderType): string {
  return `${status}_${destinationType || ''}`;
}

export function provideCallStatusComponent(type: CallStatusProviderType, component: Type<any>): Provider {
  return {
    provide: CALLSTATUS_PROVIDER,
    useValue: <CallStatusComponentProvider>{
      type: generateCallStatusProviderType(type),
      component
    },
    multi: true
  };
}
