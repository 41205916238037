import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ElectronService } from '@breez/modules/core/services';

@Component({
  selector: 'vks-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconComponent implements OnChanges {
  @Input()
  icon: string;

  @Input()
  @HostBinding('style.--icon-color')
  color: string | '#4C617B';

  @Input()
  @HostBinding('style.--icon-hover-color')
  hoverColor: string;

  @Input()
  @HostBinding('class.has-hover')
  hasHover = false;

  @HostBinding('class.has-color')
  hasColor = false;

  @HostBinding('class.has-hover-color')
  hasHoverColor = false;

  iconName: string;
  hoverIconName: string;

  constructor(private readonly electronService: ElectronService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.color) {
      this.hasColor = !!this.color;
    }

    if (changes.color) {
      this.hasHoverColor = !!this.hoverColor;
    }

    if (changes.icon) {
      this.iconName = this.getSrc(this.icon);
      this.hoverIconName = this.getSrc(this.icon, true);
    }
  }

  hoverNotLoaded(): void {
    this.hasHover = false;
  }

  private getSrc(icon: string, needHover: boolean = false): string {
    return icon
      ? `${this.electronService.isElectron ? '' : '/'}assets/svg/icon-${icon.toLocaleLowerCase().replace(/ /i, '-')}${
          needHover ? '-hover' : ''
        }.svg`
      : null;
  }
}
