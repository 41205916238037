import { ElementRef, QueryList } from '@angular/core';
import { isTruthy } from '@breez/shared/utilities/is-truthy';

function findElementByClass(elements: QueryList<ElementRef>, className: string): HTMLDivElement | null {
  if (!isTruthy(elements)) {
    return null;
  }

  return elements
    .map(element => {
      return element.nativeElement;
    })
    .find(nativeElement => {
      return (nativeElement.classList as DOMTokenList).contains(className);
    });
}

export const DomHelper = {
  findElementByClass
};
