import { deleteUndefined } from '@breez/shared/utilities/delete-undefined';

declare global {
  // to access the global type Array
  interface Array<T> {
    groupBy<K extends keyof T>(key: K): { [groupKey: string]: T[] };

    updateItemsByAnotherArray(arr2: T[], compareCarrier: (item1: T, item2: T) => boolean);

    removeItemsByAnotherArray(arr2: T[], compareCarrier: (item1: T, item2: T) => boolean);

    intersection(arr2: T[]): T[];

    equals(arr2: T[], options?: { comparer?: (item1: T, item2: T) => boolean }): boolean;

    isSame(
      arr2: T[],
      options?: { comparer?: (item1: T, item2: T) => boolean; sort?: (item1: T, item2: T) => number }
    ): boolean;

    removeDuplicates<T>(): T[];

    move(from: number, to: number): T[];
  }
}

Array.prototype.move = function (from, to): any[] {
  if (this.length === 0) {
    return this;
  }
  while (from < 0) {
    from += this.length;
  }
  while (to < 0) {
    to += this.length;
  }
  if (to >= this.length) {
    let k = to - this.length;
    while (k-- + 1) {
      this.push(undefined);
    }
  }
  this.splice(to, 0, this.splice(from, 1)[0]);
  return this;
};

Array.prototype.groupBy = function <T, K extends keyof T>(key: K): { [groupKey: string]: T[] } {
  const arr1 = this as T[];
  return arr1.reduce((acc, cur) => {
    const groupKey = cur[key];
    (acc[groupKey.toString()] = acc[groupKey.toString()] || []).push(cur);
    return acc;
  }, {});
};

Array.prototype.updateItemsByAnotherArray = function <T>(
  arr2: T[],
  compareCarrier: (item1: T, item2: T) => boolean
): void {
  const arr1 = this as T[];
  if (arr1.length > arr2.length) {
    arr2.forEach(item2 => {
      arr1
        .filter(item1 => {
          return compareCarrier(item1, item2);
        })
        .forEach(item1 => {
          return Object.assign(item1, deleteUndefined(item2));
        });
    });
  } else {
    arr1.forEach(item1 => {
      arr2
        .filter(item2 => {
          return compareCarrier(item2, item1);
        })
        .forEach(item2 => {
          return Object.assign(item1, deleteUndefined(item2));
        });
    });
  }
};

Array.prototype.removeItemsByAnotherArray = function <T>(
  arr2: T[],
  compareCarrier: (item1: T, item2: T) => boolean
): void {
  const arr1 = this as T[];
  arr2.forEach(item2 => {
    const index = arr1.findIndex(item1 => {
      return compareCarrier(item1, item2);
    });
    if (index !== -1) {
      arr1.splice(index, 1);
    }
  });
};

Array.prototype.intersection = function <T>(arr2: T[]): T[] {
  const arr1 = this as T[];

  return arr1.length > arr2.length
    ? arr2.filter(item => {
        return arr1.indexOf(item) > -1;
      })
    : arr1.filter(item => {
        return arr2.indexOf(item) > -1;
      });
};

export function defaultComparer<T>(item1: T, item2: T): boolean {
  return item1 === item2;
}

Array.prototype.equals = function <T>(
  arr2: T[],
  options: { comparer?: (item1: T, item2: T) => boolean } = {}
): boolean {
  const arr1 = this as T[];
  if (!arr1 && !arr2) {
    return true;
  }

  if (!arr1 || !arr2) {
    return false;
  }

  if (arr1.length !== arr2.length) {
    return false;
  }

  const comparer = options.comparer ?? defaultComparer;

  for (let index = 0; index < arr1.length; index++) {
    if (!comparer(arr1[index], arr2[index])) {
      return false;
    }
  }

  return true;
};

Array.prototype.isSame = function <T>(
  arr2: T[],
  options: { comparer?: (item1: T, item2: T) => boolean; sort?: (item1: T, item2: T) => number } = {}
): boolean {
  const arr1 = this as T[];
  if (!arr1 && !arr2) {
    return true;
  }

  if (!arr1 || !arr2) {
    return false;
  }

  if (arr1.length !== arr2.length) {
    return false;
  }

  const newArr1 = arr1.concat().sort(options.sort);
  const newArr2 = arr2.concat().sort(options.sort);

  const comparer = options.comparer ?? defaultComparer;

  for (let index = 0; index < newArr1.length; index++) {
    if (!comparer(newArr1[index], newArr2[index])) {
      return false;
    }
  }

  return true;
};

Array.prototype.removeDuplicates = function <T>(): T[] {
  const arr1 = this as T[];
  const arrayResult = [];
  for (let index = 0; index < arr1.length; index++) {
    const element = arr1[index];
    if (arrayResult.indexOf(element) === -1) {
      arrayResult.push(element);
    }
  }

  return arrayResult;
};

export function sortArray<T>(fieldName: keyof T, asc: boolean = true): (item1: T, item2: T) => number {
  return (item1, item2): number => {
    let result = null;
    if (!item1.hasOwnProperty(fieldName)) {
      result = -1;
    }

    if (!item2.hasOwnProperty(fieldName)) {
      result = !!result ? 0 : 1;
    }

    if (!result) {
      const item1Value = item1[fieldName];
      const item2Value = item2[fieldName];

      if (typeof item1Value === 'number' && typeof item2Value === 'number') {
        if (Number.isInteger(item1Value) && Number.isInteger(item2Value)) {
          return item1Value - item2Value;
        }
      }

      if (typeof item1Value === 'string' && typeof item2Value === 'string') {
        if (!Number.isNaN(Date.parse(item1Value)) && !Number.isNaN(Date.parse(item2Value))) {
          return Date.parse(item1Value) - Date.parse(item2Value);
        }

        result = item1Value.toLowerCase() < item2Value.toLowerCase() ? -1 : 1;
      }
    }

    return result * (asc ? 1 : -1);
  };
}
