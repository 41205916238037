import { DOCUMENT } from '@angular/common';
import { decode } from 'html-entities';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { ScrollControllerComponent } from '@breez/shared/components/scroll-controller';
import { fromElementBecomeVisible } from '@breez/shared/rxjs-operators/from-intersection-observer';
import { StateService } from '@breez/shared/services/state.service';
import { EmitOnChange } from '@breez/shared/utilities/decorators/emit-on-change.decorator';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ClipboardService } from 'ngx-clipboard';
import { combineLatest, defer, EMPTY, interval, merge, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import {
  delay,
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  share,
  shareReplay,
  startWith,
  switchMap,
  take
} from 'rxjs/operators';
import { AppService } from '@breez/app.service';
import { TranslateService } from '@ngx-translate/core';
import { LangChangeEvent } from '@ngx-translate/core/lib/translate.service';
import { distinctUntilChangedByJsonCompare, replayWhileSubs } from '@breez/shared/rxjs-operators';
import { ChatListPopupComponent } from '../chat-list-popup';
import { MessageContentBlockEnum } from '../../enums';
import { ChatListPopupData, MessageToForwardModel } from '../../interfaces';
import { Chat, ChatParticipant, Message } from '../../models';
import { ChatService, MessageService } from '../../services';
import { Store } from '@ngrx/store';
import * as ModuleState from '@breez/modules/chat/+state/module.state';
import * as ChatSelectors from '@breez/modules/chat/+state/chat/chat.selectors';
import * as MessageSelectors from '@breez/modules/chat/+state/message/message.selectors';
import { ROOT_STORE_SELECTORS } from '@breez/+state';
import * as MessageActions from '@breez/modules/chat/+state/message/message.actions';
import * as UsersSelectors from '@breez/modules/users/+state/users/user.selectors';
import { AuthService } from '@breez/modules/auth/services/auth.service';
import { EMOJI_LABELS_RU, EMOJI_LABELS_DEFAULT } from '../../consts';
import * as ParticipantsSelectors from '@breez/modules/chat/+state/participants/participants.selectors';

@UntilDestroy()
@Component({
  selector: 'vks-chat-message-view',
  templateUrl: './chat-message-view.component.html',
  styleUrls: ['./chat-message-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatMessageViewComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild(MatMenuTrigger) contextMenuTrigger: MatMenuTrigger;
  @ViewChild('messageBody') messageBody: ElementRef;
  @ViewChild('hammerTarget') hammerTarget: ElementRef;

  contextMenuPosition = { x: '', y: '' };

  @Input()
  conferenceId: number;

  @Input()
  isDemoChat = false;

  @Input()
  conferenceCreator: any;

  @Input()
  message: Message;

  @Input()
  enableContextMenu = false;

  @Input()
  isDelivered = false;

  @Input()
  isRead = false;

  @Input()
  isBanned = false;

  @Input()
  @HostBinding('class.out')
  isOut = false;

  @Input()
  allowForward = false;

  @Input()
  isQuestionToSpeaker = false;

  @Input() isActiveConference: boolean;

  @HostBinding('class.question-to-speaker')
  markMessage = false;

  @Input()
  isLast = false;

  @Input()
  isFavoriteChat: boolean;

  @Input()
  @HostBinding('class.first')
  isGroupFirst = false;

  @Input()
  @HostBinding('class.last')
  isGroupLast = false;

  @EmitOnChange('message')
  message$ = new ReplaySubject<Message>(1);

  participants$: Observable<ChatParticipant[]>;

  messageBody$: Observable<string> = this.message$.pipe(
    map(message => {
      return message.body;
    }),
    distinctUntilChanged()
  );

  isFirstUnreadTrigger$ = this.message$.pipe(
    map(message => {
      return message.isScrollTarget;
    }),
    distinctUntilChanged(),
    filter(isFirstUnread => {
      return isFirstUnread;
    }),
    mapTo(undefined)
  );

  @EmitOnChange('isRead')
  isRead$ = new ReplaySubject<boolean>(1);

  @EmitOnChange('isOut')
  isOut$ = new ReplaySubject<boolean>(1);

  @EmitOnChange('isLast', {
    emitter: (value, subject) => {
      if (value) {
        subject.next(value);
      }
    }
  })
  isLastMessageTrigger$ = new ReplaySubject<void>(1);

  emojiMartTranslate$: Observable<any>;

  messageEmojiConfig = null;

  scrollToLastMessageTrigger$ = this.isLastMessageTrigger$.pipe(
    filter(() => {
      return this.isOut || this.scrollContainer.isOnBottom();
    })
  );

  @Output()
  initialized = new EventEmitter<void>();

  @Output()
  editMessage = new EventEmitter<void>();

  @Output()
  replyMessage = new EventEmitter<Message>();

  @Output()
  retrySendMessage = new EventEmitter<Message>();

  @Output()
  forwardMessage = new EventEmitter<Message>();

  @Output()
  deleteMessage = new EventEmitter<Message>();

  @Output()
  blockChat = new EventEmitter<void>();

  @Output()
  unblockChat = new EventEmitter<void>();

  @Output()
  issueWarning = new EventEmitter<void>();

  @Output()
  markAsRead = new EventEmitter<void>();

  @Output()
  forwardMessageToChat = new EventEmitter<MessageToForwardModel>();

  @Output()
  replyMessageByDblclick = new EventEmitter<void>();

  @Output()
  pinMessage = new EventEmitter<Message>();

  @Output()
  addToFavorites = new EventEmitter<Message>();

  selectedReplyMessageTrigger$: Observable<void> = this.chatService.replyMessageTrigger$.pipe(
    filter(message => {
      return message.id === this.message.id;
    }),
    mapTo(undefined),
    share()
  );

  scrollToTrigger$: Observable<void> = merge(
    this.isFirstUnreadTrigger$,
    this.scrollToLastMessageTrigger$,
    this.selectedReplyMessageTrigger$
  );

  highlightContainer$: Observable<boolean> = this.selectedReplyMessageTrigger$.pipe(
    switchMap(() => {
      return of(false).pipe(delay(2000), startWith(true));
    }),
    startWith(false)
  );

  executionProcessing$: Observable<boolean> = this.message$.pipe(
    filter(message => {
      return isTruthy(message) && isTruthy(message.id);
    }),
    // @ts-ignore
    switchMap(({ id }) => {
      return this.store.select(
        ROOT_STORE_SELECTORS.execution.executionProcessing(MessageActions.sendMessage, id as any)
      );
    }),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  executionCompleted$: Observable<boolean> = this.message$.pipe(
    filter(message => {
      return isTruthy(message) && isTruthy(message.id);
    }),
    // @ts-ignore
    switchMap(({ id }) => {
      return this.store.select(ROOT_STORE_SELECTORS.execution.executionResult(MessageActions.sendMessage, id as any));
    }),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  executionError$: Observable<boolean> = this.message$.pipe(
    filter(message => {
      return isTruthy(message) && isTruthy(message.id);
    }),
    // @ts-ignore
    switchMap(({ id }) => {
      return this.store.select(ROOT_STORE_SELECTORS.execution.executionError(MessageActions.sendMessage, id as any));
    }),
    map(isTruthy),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  appVisible$: Observable<boolean> = this.stateService.isAppVisible$;

  isMobileDevice$: Observable<boolean> = this.stateService.isMobileDevice$;

  isMobile: boolean = this.appService.isMobile;

  messageVisible$: Observable<void> = defer(() => {
    return fromElementBecomeVisible(this.hostElement.nativeElement);
  });

  messageReadTrigger$: Observable<void> = combineLatest([this.isRead$, this.isOut$, this.appVisible$]).pipe(
    switchMap(([isRead, isOut, isAppVisible]) => {
      if (isRead || isOut || !isAppVisible) {
        return EMPTY;
      }
      return this.messageVisible$.pipe(take(1));
    })
  );

  menuOpened = false;
  contextMenuHammerPressSubscription: Subscription;
  links$: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private store: Store<ModuleState.State>,
    private stateService: StateService,
    private chatService: ChatService,
    private hostElement: ElementRef<HTMLElement>,
    @Optional() private scrollContainer: ScrollControllerComponent,
    private clipboardService: ClipboardService,
    private dialog: MatDialog,
    private appService: AppService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  selectedLang$: Observable<string> = this.translateService.onLangChange.pipe(
    map((event: LangChangeEvent) => {
      return event.lang;
    }),
    startWith(this.translateService.currentLang),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  ngOnInit(): void {
    this.participants$ = this.store.select(ParticipantsSelectors.getParticipants(this.message.chatId));
    this.emojiMartTranslate$ = this.translateService.onLangChange.pipe(
      startWith({
        lang: this.translateService.currentLang,
        translations: this.translateService.translations[this.translateService.currentLang]
      }),
      map(({ lang }) => {
        switch (lang) {
          case 'ru':
            return EMOJI_LABELS_RU;
          default:
            return EMOJI_LABELS_DEFAULT;
        }
      }),
      shareReplay(),
      distinctUntilChangedByJsonCompare()
    );

    this.messageBody$.pipe(untilDestroyed(this)).subscribe(body => {
      this.changeDetectorRef.detectChanges();
      this.processMessageBody(body);
    });

    this.scrollToTrigger$.pipe(untilDestroyed(this)).subscribe(() => {
      setTimeout(() => {
        return this.hostElement.nativeElement.scrollIntoView({ block: 'center' });
      }, 10);
    });

    this.messageReadTrigger$.pipe(untilDestroyed(this)).subscribe(() => {
      return this.markAsRead.emit();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.markMessage = this.isQuestionToSpeaker && this.allowForward;

    if (changes.message && changes.message.currentValue && this.messageBody) {
      // console.log('onCHange ' + this.message.body);
    }
  }

  ngAfterViewInit(): void {
    this.initialized.emit();
    // this.processMessageBody(this.message.body);
  }

  scrollToRepliedMessage(): void {
    if (isTruthy(this.message.replyMessageId)) {
      this.store
        .select(MessageSelectors.getMessage(this.message.replyMessageId))
        .pipe(
          filter(message => {
            return message.id === this.message.replyMessageId;
          }),
          take(1)
        )
        .subscribe(message => {
          this.chatService.replyMessageTrigger$.next(message);
        });
    }
  }

  forwardToSpeakersChat(): void {
    this.forwardMessage.emit(this.message);
  }

  processMessageBody(content: string): void {
    function normalizeLink(link: string): string {
      link = link.replace(/^(https?:\/\/)?/, '');
      link = link.replace(/^www\./, '');
      link = link.replace(/\/$/, '');

      return link;
    }

    this.selectedLang$
      .pipe(
        untilDestroyed(this),
        switchMap(() => {
          const vars = this.message.meta?.var;
          return this.messageService.parseMessage(content, this.messageService.messageIsSystem(this.message), vars);
        })
      )
      .subscribe(({ messageItems, links }) => {
        const uniqueLinks = Array.from(
          new Set(
            links
              .filter(link => {
                return link.typeContent === MessageContentBlockEnum.LINK;
              })
              .map(messageItem => {
                return normalizeLink(messageItem.content);
              })
          )
        );
        this.links$.next(uniqueLinks);

        if (messageItems.length && this.messageBody) {
          const messageBodyNativeElement = this.messageBody.nativeElement as HTMLParagraphElement;
          this.messageService.fillNative(messageItems, messageBodyNativeElement);
        }
      });
  }

  openContextMenu($event: MouseEvent): void {
    if (this.isBanned || (!this.isMobile && window.getSelection().toString().length > 0)) {
      return;
    }
    this.menuOpened = true;
    $event.preventDefault();
    this.contextMenuPosition.x = $event.clientX + 'px';
    this.contextMenuPosition.y = $event.clientY + 20 + 'px';
    this.contextMenuTrigger.openMenu();
  }

  copyMessageBodyToClipboard(body: string): void {
    this.translateService
      .get(body)
      .pipe(take(1))
      .subscribe(traslated => {
        return this.clipboardService.copy(decode(traslated));
      });
  }

  startContextMenuTimer($event: any): void {
    $event.preventDefault();
    this.document.body.style.webkitUserSelect = 'none';
    this.contextMenuPosition.x = $event.center.x + 'px';
    this.contextMenuPosition.y = $event.center.y + 20 + 'px';
    this.contextMenuHammerPressSubscription = interval(400)
      .pipe(take(1))
      .subscribe(() => {
        if (this.isBanned || (!this.isMobile && window.getSelection().toString().length > 0)) {
          return; // Fix context menu open on long press select in web
        }
        this.menuOpened = true;
        this.contextMenuTrigger.openMenu();
        this.document.body.style.webkitUserSelect = 'text';
      });
  }

  clearContextMenuTimer(): void {
    if (this.contextMenuHammerPressSubscription !== undefined) {
      this.contextMenuHammerPressSubscription.unsubscribe();
      this.document.body.style.webkitUserSelect = 'text';
    }
  }

  openChatList(messageToForward: Message): void {
    combineLatest([
      this.authService.currentUser$,
      this.isMobileDevice$,
      this.store.select(UsersSelectors.getUsers()),
      this.store.select(ChatSelectors.chats)
    ])
      .pipe(
        take(1),
        switchMap(([currUser, isMobileDevice, users, chats]) => {
          const currentUserId = currUser.id;
          const chatsData = (chats as Chat[]).map(chat => {
            if (chat?.participantUserIds?.length && chat?.participantUserIds?.length === 2) {
              const interlocutorsId = chat.participantUserIds.find(id => {
                return id !== currentUserId;
              });
              const interlocutorName = users.find(user => {
                return user.id === interlocutorsId;
              }).name;
              return { ...chat, name: interlocutorName };
            }

            return chat;
          });

          const dialogData: ChatListPopupData = {
            chats: chatsData,
            messageToForward
          };
          const dialogClassList = ['no-padding'];
          if (isMobileDevice) {
            dialogClassList.push('full-width');
          }

          return this.dialog
            .open(ChatListPopupComponent, {
              data: dialogData,
              width: isMobileDevice ? null : '450px',
              height: isMobileDevice ? null : '520px',
              panelClass: dialogClassList,
              position: {
                bottom: isMobileDevice ? '0' : null
              }
            })
            .afterClosed();
        })
      )
      .subscribe(result => {
        if (result) {
          const data: MessageToForwardModel = {
            chatId: result,
            messages: [messageToForward],
            inConference: isTruthy(this.conferenceId)
          };
          this.forwardMessageToChat.emit(data);
        }
      });
  }

  isPinned(message): boolean {
    const chatsStorage = localStorage.getItem('chats-info');
    if (!chatsStorage) {
      return false;
    }

    return !!JSON.parse(chatsStorage).find(x => {
      return (
        x.chatId === message.chatId &&
        x.messages?.find(y => {
          return y === message.id;
        }) != null
      );
    });
  }

  backgroundImageFn = (_, __): string => {
    return 'assets/images/emoji.png';
  };

  getReactionTooltip(reaction): any {
    return this.participants$.pipe(
      map(x => {
        return `${
          x.find(participant => {
            return participant.user.id === reaction.userid;
          })?.user.name
        }`;
      })
    );
  }

  hasCurrentUserReaction(message): Observable<boolean> {
    return this.authService.user$.pipe(
      map(user => {
        return message.meta?.reaction
          ?.map(reaction => {
            return reaction?.userid;
          })
          .includes(user.id);
      })
    );
  }

  deleteReaction(messageId): void {
    this.chatService.changeReactionOnMessage(messageId, null).subscribe();
  }

  addEmoji($event): void {
    this.chatService.changeReactionOnMessage(this.message.id, $event.emoji.native).subscribe(() => {
      this.messageEmojiConfig = null;
    });
  }

  toggleEmojiPanel(state): void {
    this.messageEmojiConfig = state;
  }

  isFavorite(message): boolean {
    const chatsStorage = localStorage.getItem('favorites');
    if (!chatsStorage) {
      return false;
    }

    return !!JSON.parse(chatsStorage).find(x => {
      return (
        x.chatId === message.chatId &&
        x.messages.find(y => {
          return y === message.id;
        }) != null
      );
    });
  }

  selectText($event: MouseEvent): void {
    $event.stopPropagation();
    return;
  }

  retrySend(): void {
    this.retrySendMessage.emit(this.message);
  }
}
