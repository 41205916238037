import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@breez/shared/shared.module';
import { COMPONENTS } from './components';
import { OVERLAY_PROVIDERS } from './overlay-provider';

@NgModule({
  imports: [CommonModule, TranslateModule, MatTooltipModule, SharedModule],
  declarations: [COMPONENTS],
  providers: [...OVERLAY_PROVIDERS]
})
export class CallStatusHoldModule {}
