import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { NavigationStart, Router } from '@angular/router';
import { AppService } from '@breez/app.service';
import { environment } from '@breez/environment';
import { User } from '@breez/models';
import { ProcessingFileModel } from '@breez/models/shared/files/processing-file.model';
import { AuthService } from '@breez/modules/auth/services/auth.service';
import { ControlFileDropAreaComponent } from '@breez/modules/controls/components/control-file-drop-area';
import { FileService } from '@breez/modules/file/file.service';
import { PermissionsService } from '@breez/modules/permissions/services/permissions.service';
import { distinctUntilChangedByJsonCompare, replayWhileSubs } from '@breez/shared/rxjs-operators';
import { fromControl } from '@breez/shared/rxjs-operators/from-control';
import { StateService } from '@breez/shared/services/state.service';
import { EmitOnChange } from '@breez/shared/utilities/decorators/emit-on-change.decorator';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, EMPTY, forkJoin, from, fromEvent, Observable, of, ReplaySubject } from 'rxjs';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  map,
  scan,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import * as breezValidators from '@breez/shared/validators';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { ChangeEvent, CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import { CKEditor5 } from '@ckeditor/ckeditor5-angular/ckeditor';
import { uniqueArrayOfObjects } from '@breez/shared/utilities/uniqueArrayOfObjects';
import { CHAT_ID, MESSAGE_ID } from '../../types';
import { EMOJI_LABELS_DEFAULT, EMOJI_LABELS_RU } from '../../consts';
import { Chat, Message } from '../../models';
import { ChatService, MessageService } from '../../services';
import { ChatEntity } from '@breez/modules/chat/models/+state/chatEntity';
import { generateChatParticipantsFromUsers } from '@breez/modules/chat';
import { Store } from '@ngrx/store';
import * as ModuleState from '@breez/modules/chat/+state/module.state';
import * as ChatDraftSelectors from '@breez/modules/chat/+state/chatDraftMessage/chatDraftMessage.selectors';
import * as ChatDraftActions from '@breez/modules/chat/+state/chatDraftMessage/chatDraftMessage.actions';
import { GiphyContentTypeEnum } from '@breez/modules/chat/enums/giphy-content-type.enum';
import { GiphyDialogResult, GiphyService } from '@breez/modules/chat/services/giphy.service';

interface CommandEvent {
  commandName: string;
  value: boolean;
}

const MAX_OF_SENDING_MESSAGE_LENGTH = 4000;

const COMMANDS_ADVANCED_MODE = [
  'blockQuote',
  'code',
  'link',
  'bulletedList',
  'numberedList',
  'outdentList',
  'insertTable',
  'codeBlock'
];

@UntilDestroy()
@Component({
  selector: 'vks-chat-message-edit',
  templateUrl: './chat-message-edit.component.html',
  styleUrls: ['./chat-message-edit.component.scss', './chat-message.edit.component.media-max520.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatMessageEditComponent implements OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('editor') editor: CKEditorComponent;
  ckeditor = Editor;

  maxCharactersTrigger$ = new ReplaySubject<boolean>(1);
  private previousHeight: number;

  maxCharacters$: Observable<string> = this.maxCharactersTrigger$.pipe(
    switchMap(maxCharactersTrigger => {
      if (!maxCharactersTrigger) {
        return of(null);
      }

      return this.selectedLang$.pipe(
        map(() => {
          return { size: MAX_OF_SENDING_MESSAGE_LENGTH };
        }),
        switchMap(params => {
          return this.translateService.get('MAX_NUMBER_CHARACTERS', params);
        })
      );
    }),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  @HostListener('document:keydown', ['$event'])
  enterKeyDown($event): void {
    if ($event.key === 'Enter') {
      this.showEmoji$.pipe(take(1)).subscribe(showEmoji => {
        if (showEmoji) {
          if ($event?.target?.type !== 'search') {
            this.ckKeydown($event);
          }
        }
      });
    }
  }

  @ViewChild('messageBody') messageBodyTextarea: { nativeElement: HTMLTextAreaElement };
  @ViewChild('dropArea') dropArea: ControlFileDropAreaComponent;

  @Input()
  isQTSChat: boolean;

  @Input()
  allowAttachments = true;

  @Input()
  allowQTSAnchor = false;

  @Input()
  isQTSEnabled: boolean;

  @Input()
  handleQTSTag = false;

  @Input()
  replyingMessage: Message;

  @EmitOnChange('replyingMessage', { onlyTruthy: true })
  replyingMessage$ = new ReplaySubject<Message>(1);

  @Input()
  editingMessage: Message;

  @EmitOnChange('editingMessage', { onlyTruthy: true })
  editingMessage$ = new ReplaySubject<Message>(1);

  @Input()
  forwardingMessages: Message[];

  @Input()
  placeholder = 'ENTER_YOUR_MESSAGE';

  @Input()
  forGlobalChat = true;

  @Input()
  chat: Chat | ChatEntity;

  @EmitOnChange('chat', { onlyTruthy: true })
  chat$ = new ReplaySubject<Chat | ChatEntity>(1);

  chatId$: Observable<CHAT_ID> = this.chat$.pipe(
    map(chat => {
      return chat?.id;
    }),
    filter(isTruthy),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  @Input()
  chatLoaded = false;

  @EmitOnChange('chatLoaded', { onlyTruthy: true })
  inputChatLoaded$ = new ReplaySubject<boolean>(1);

  @Output()
  submitMessage = new EventEmitter<Message>();

  @Output()
  updateMessage = new EventEmitter<Message>();

  @Output()
  scrollToBottom = new EventEmitter<void>();

  @Output()
  textAreaResize = new EventEmitter<number>();

  isLoading$ = new BehaviorSubject<boolean>(false);
  isLoadingDelayed$ = this.isLoading$.pipe(
    switchMap(isLoading => {
      if (isLoading) {
        return of(isLoading);
      } else {
        return of(isLoading).pipe(delay(500));
      }
    }),
    distinctUntilChangedByJsonCompare(),
    replayWhileSubs()
  );

  selectedLang$: Observable<string> = this.translateService.onLangChange.pipe(
    map((params: LangChangeEvent) => {
      return params.lang;
    }),
    startWith(this.translateService.currentLang),
    replayWhileSubs()
  );

  setFocusTrigger$: ReplaySubject<void> = new ReplaySubject<void>(1);

  editingMessageText$ = this.editingMessage$.pipe(
    switchMap(message => {
      return this.messageService.convertMessageToPlainText$(message);
    }),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  replyingMessageText$ = this.replyingMessage$.pipe(
    switchMap(message => {
      return this.messageService.convertMessageToPlainText$(message);
    }),
    filter(text => {
      return text.length > 0;
    }),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  focused = false;
  preventBlur = false;
  fileSizeLimit = 20;
  allowedFormats: string[] = this.fileService.allowedExtensions;
  QTSTag = this.chatService.QTSTag;
  messageForm = new UntypedFormGroup({
    id: new UntypedFormControl(null),
    body: new UntypedFormControl('', []),
    attachments: new UntypedFormControl([], [breezValidators.fileSizeValidator(this.fileSizeLimit, true)]),
    replyMessageId: new UntypedFormControl(null),
    editMessageId: new UntypedFormControl(null),
    forwardingMessages: new UntypedFormControl(null)
  });

  @EmitOnChange('allowQTSAnchor')
  allowQTSAnchor$ = new ReplaySubject(1);

  includesQTSTag$: Observable<boolean> = this.messageFormBodyControl.valueChanges.pipe(
    map(message => {
      return (<string>message || '').toLocaleLowerCase().includes(this.QTSTag.toLocaleLowerCase());
    }),
    startWith(false)
  );

  @EmitOnChange('isQTSEnabled')
  isQTSChatEnabled$ = new ReplaySubject<boolean>(1);

  displayQTSAnchor$: Observable<boolean> = combineLatest([
    this.allowQTSAnchor$,
    this.includesQTSTag$,
    this.isQTSChatEnabled$
  ]).pipe(
    map(([allowQTS, includesTag, isQTSChatEnabled]) => {
      return allowQTS && !includesTag && !this.isQTSChat && isQTSChatEnabled;
    })
  );

  isAnyFileAttached$: Observable<boolean> = fromControl(this.attachmentsFormControl).pipe(
    untilDestroyed(this),
    startWith(false),
    map(value => {
      return value && value.length > 0;
    }),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  currentUser$: Observable<User> = this.authService.currentUser$.pipe(filter(isTruthy));
  currentUserId$: Observable<number> = this.currentUser$.pipe(
    filter(isTruthy),
    distinctUntilChanged((x, y) => {
      return x.id === y.id;
    }),
    map(user => {
      return user.id;
    })
  );

  messageDraftBody$: Observable<string> = this.chatId$.pipe(
    switchMap(chatId => {
      return this.store.select(ChatDraftSelectors.chatDraft(chatId));
    })
  );

  saveMessageOnWindowUnload$: Observable<Event> = fromEvent(window, 'unload');
  routerNavigationStartEvents$ = this.router.events.pipe(
    filter(event => {
      return event instanceof NavigationStart;
    }),
    untilDestroyed(this)
  );

  lastBody$: ReplaySubject<string> = new ReplaySubject(1);
  ngModelCk$: ReplaySubject<string> = new ReplaySubject<string>(1);
  isMobileDevice$: Observable<boolean> = this.stateService.isMobileDevice$;
  isDragInProcess$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  textAreaStyle: { lineHeight: number; paddingTop: number; paddingBottom: number };
  enableGiphy: boolean = !!environment.enableGiphy;
  canAttachFiles$ = this.permissionsService.permissions$.pipe(
    map(permissions => {
      return permissions.includes('chat:message:attachment');
    }),
    replayWhileSubs()
  );

  canUseGiphy$ = this.permissionsService.permissions$.pipe(
    map(permissions => {
      return permissions.includes('chat:message:animation');
    }),
    replayWhileSubs()
  );

  textAreaRightOffset$ = combineLatest([this.canUseGiphy$, this.isQTSChatEnabled$]).pipe(
    map(([canUserGify, qtsEnabled]) => {
      if (canUserGify && qtsEnabled) {
        return 120;
      } else if ((canUserGify && !qtsEnabled) || (!canUserGify && qtsEnabled)) {
        return 80;
      } else {
        return 40;
      }
    })
  );

  showEmojiDelayed = false;
  showEmojiSubject$: ReplaySubject<boolean> = new ReplaySubject<boolean>();
  showEmoji$: Observable<boolean> = this.showEmojiSubject$.pipe(
    startWith(this.showEmojiDelayed),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  private textAreaMaxRowsCount = 10;

  ckHideToolbarState$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  ckIsToolbarHide$: Observable<boolean> = this.ckHideToolbarState$.pipe(distinctUntilChanged(), replayWhileSubs());

  currentChatParticipant$ = this.authService.currentUser$.pipe(
    map(user => {
      return generateChatParticipantsFromUsers(user)[0];
    }),
    replayWhileSubs()
  );

  constructor(
    private chatService: ChatService,
    private fileService: FileService,
    private readonly giphyService: GiphyService,
    private stateService: StateService,
    private appService: AppService,
    private store: Store<ModuleState.State>,
    private router: Router,
    private authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef,
    private permissionsService: PermissionsService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) {
    this.showEmoji$.pipe(delay(300), untilDestroyed(this)).subscribe(showEmoji => {
      this.showEmojiDelayed = showEmoji;
    });
  }

  get messageFormBodyControl(): UntypedFormControl {
    return this.messageForm.get('body') as UntypedFormControl;
  }

  get editMessageIdFormControl(): UntypedFormControl {
    return this.messageForm.get('editMessageId') as UntypedFormControl;
  }

  get forwardingMessagesFormControl(): UntypedFormControl {
    return this.messageForm.get('forwardingMessages') as UntypedFormControl;
  }

  get attachmentsFormControl(): UntypedFormControl {
    return this.messageForm.get('attachments') as UntypedFormControl;
  }

  get replyingMessageIdFormControl(): UntypedFormControl {
    return this.messageForm.get('replyMessageId') as UntypedFormControl;
  }

  backgroundImageFn = (_, __): string => {
    return 'assets/images/emoji.png';
  };

  emojiMartTranslate$: Observable<any>;

  ckeditorReady$: Observable<CKEditor5.Editor>;

  ckCommandStack$: ReplaySubject<CommandEvent> = new ReplaySubject<CommandEvent>();
  ckCommand$: Observable<CommandEvent> = this.ckCommandStack$.pipe(startWith(null));

  ckCommandList$ = this.ckCommand$.pipe(
    scan((commands: [], current) => {
      return current ? uniqueArrayOfObjects<CommandEvent>([...commands, current], 'commandName') : [];
    }, [])
  );

  ckAdvancedMode$: Observable<boolean> = combineLatest([this.ckIsToolbarHide$, this.ckCommandList$]).pipe(
    map(([isToolbarHide, commands]) => {
      return (
        !isToolbarHide ||
        commands.some(command => {
          return command.value === true;
        })
      );
    }),
    startWith(!this.ckHideToolbarState$.value),
    untilDestroyed(this),
    replayWhileSubs()
  );

  ngModelCk: string = null;

  ckGetCommandsCurrentValue$(): Observable<CommandEvent[]> {
    return this.ckeditorReady$.pipe(
      map(ckeditor => {
        return COMMANDS_ADVANCED_MODE.map(commandName => {
          const command = ckeditor.commands.get(commandName);
          const value = command.value;
          const commandEvent: CommandEvent = { commandName, value };
          return commandEvent;
        }).filter(commandEvent => {
          return isTruthy(commandEvent.value);
        });
      })
    );
  }

  ckStaticAdvanceMod$(): Observable<boolean> {
    return this.ckGetCommandsCurrentValue$().pipe(
      map(commands => {
        return commands.some(command => {
          return command.value === true;
        });
      }),
      take(1)
    );
  }

  ckCommandValue(value, commandName): boolean {
    return commandName === 'outdentList' ? (value === false ? true : false) : value === false ? false : true;
  }

  ngAfterViewInit(): void {
    this.ckeditorReady$ = from(this.editor.ready).pipe(take(1), replayWhileSubs());

    this.focusMessageEditor();

    this.ckeditorReady$.subscribe(ckeditor => {
      this.translateService.onLangChange
        .pipe(
          untilDestroyed(this),
          switchMap(() => {
            return this.translateService.get('ENTER_YOUR_MESSAGE');
          })
        )
        .subscribe(value => {
          const placeholderDom = ckeditor.ui.view.editable.element.querySelector('p.ck-placeholder');

          if (placeholderDom) {
            placeholderDom.setAttribute('data-placeholder', value);
            placeholderDom.textContent = value;
          }
        });

      this.setFocusTrigger$.pipe(debounceTime(10), untilDestroyed(this)).subscribe(() => {
        if (this.forGlobalChat) {
          ckeditor.editing.view.focus();
        }
        ckeditor.model.change(writer => {
          writer.setSelection(writer.createPositionAt(ckeditor.model.document.getRoot(), 'end'));
        });
      });

      /* blockQuote, code, link, bulletedList, numberedList, outdentList, insertTable, codeBlock */
      COMMANDS_ADVANCED_MODE.map(commandName => {
        const command = ckeditor.commands.get(commandName);
        command.on('execute', ({ path }) => {
          of(null)
            .pipe(delay(25), take(1))
            .subscribe(() => {
              const value = this.ckCommandValue(path[0].value, commandName);
              if (commandName === 'outdentList') {
                this.ckCommandStack$.next({ commandName: 'bulletedList', value });
                this.ckCommandStack$.next({ commandName: 'numberedList', value });
              } else {
                this.ckCommandStack$.next({ commandName, value });
              }
            });
        });

        return command;
      });
    });

    this.emojiMartTranslate$ = this.translateService.onLangChange.pipe(
      startWith({
        lang: this.translateService.currentLang,
        translations: this.translateService.translations[this.translateService.currentLang]
      }),
      map(({ lang }) => {
        switch (lang) {
          case 'ru':
            return EMOJI_LABELS_RU;
          default:
            return EMOJI_LABELS_DEFAULT;
        }
      }),
      shareReplay(),
      distinctUntilChangedByJsonCompare()
    );

    this.focusMessageEditor();

    const textAreaStyles = getComputedStyle(this.messageBodyTextarea.nativeElement);
    this.textAreaStyle = {
      paddingTop: Number(textAreaStyles.paddingTop.replace(/px/, '')),
      paddingBottom: Number(textAreaStyles.paddingBottom.replace(/px/, '')),
      lineHeight: Number(textAreaStyles.lineHeight.replace(/px/, ''))
    };
    this.textAreaResize
      .pipe(
        untilDestroyed(this),
        map(() => {
          const paddingTop = this.textAreaStyle.paddingTop;
          const paddingBottom = this.textAreaStyle.paddingBottom;
          const lineHeight = this.textAreaStyle.lineHeight;

          return (this.messageBodyTextarea.nativeElement.scrollHeight - paddingBottom - paddingTop) / lineHeight;
        }),
        distinctUntilChanged()
      )
      .subscribe(linesCount => {
        if (linesCount > this.textAreaMaxRowsCount) {
          this.messageBodyTextarea.nativeElement.style.overflow = 'auto';
        } else {
          this.messageBodyTextarea.nativeElement.style.overflow = 'hidden';
        }
      });

    combineLatest([this.messageDraftBody$, this.showEmoji$])
      .pipe(untilDestroyed(this))
      .subscribe(([messageDraft, showEmoji]) => {
        const body = this.messageFormBodyControl;
        if (body.value !== messageDraft && !showEmoji) {
          body.patchValue(messageDraft ?? '');
          this.resizeTextArea();
          this.messageBodyTextarea.nativeElement.scrollTop = 0;
          this.focusMessageEditor();
        }
      });

    this.ngModelCk$.pipe(withLatestFrom(this.chatId$), untilDestroyed(this)).subscribe(([draft, chatId]) => {
      this.store.dispatch(ChatDraftActions.upsertDraft({ chatId, draft }));
    });

    this.inputChatLoaded$.subscribe(isChatLoaded => {
      if (isChatLoaded) {
        this.focusMessageEditor();
      } else {
        this.disableTextArea();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.replyingMessage) {
      this.setReplyingMessage(this.replyingMessage);
    }
    if (changes.editingMessage) {
      this.setEditingMessage(this.editingMessage);
    }
    if (changes.forwardingMessages && changes.forwardingMessages.currentValue) {
      this.setForwardingMessages(this.forwardingMessages);
    }
  }

  submitForm(isQuestionToSpeaker = false): void {
    if (!this.messageForm.valid) {
      this.isLoading$.next(false);
      return;
    }

    if (this.isLoading$.value) {
      return;
    }

    this.showEmojiSubject$.next(false);

    const files: ProcessingFileModel[] = this.attachmentsFormControl.value || [];

    let messageToSend = (<string>this.messageFormBodyControl.value || '').trim();

    combineLatest([this.currentChatParticipant$, this.messageService.convertHtmlToPlainText$(messageToSend)])
      .pipe(take(1))
      .subscribe(([currentChatParticipant, clearedText]) => {
        if (clearedText.length > MAX_OF_SENDING_MESSAGE_LENGTH) {
          this.maxCharactersTrigger$.next(true);
          return;
        }

        const replyMessageId: MESSAGE_ID = this.replyingMessageIdFormControl.value;
        const notMessageFormEmpty = Object.values(this.messageForm.value).some(value => {
          return isTruthy(value);
        });

        if (!notMessageFormEmpty && files.length === 0) {
          return;
        }

        if (messageToSend === '' && files.length === 0 && !this.forwardingMessagesFormControl.value) {
          return;
        }

        this.isLoading$.next(true);
        this.changeDetectorRef.detectChanges();

        this.focusMessageEditor();

        if (this.editMessageIdFormControl.value) {
          const messageId = this.editMessageIdFormControl.value;
          const attachments: ProcessingFileModel[] = this.attachmentsFormControl.value || [];
          const newAttachments = attachments;

          const filesToUpload$: Observable<ProcessingFileModel[]> = of(EMPTY).pipe(
            switchMap(() => {
              if (newAttachments.length === 0) {
                return of([]);
              }
              return forkJoin(
                newAttachments.map(file => {
                  return file.ownerId ? of(file) : this.fileService.add(file);
                })
              );
            })
          );

          return filesToUpload$.subscribe((processedFiles: ProcessingFileModel[]) => {
            const isQTS = this.handleQTSTag ? isQuestionToSpeaker : false;

            this.updateMessage.emit(
              new Message({
                id: messageId,
                body: isQTS ? this.messageToQTS(messageToSend) : messageToSend,
                isQuestionToSpeaker: isQTS,
                replyMessageId,
                attachments: processedFiles,
                sender: currentChatParticipant
              })
            );
            this.messageBodyTextarea.nativeElement.style.height = 'auto';
          });
        }

        if (this.forwardingMessagesFormControl.value) {
          const forwardingMessagesIds = (<Message[]>this.forwardingMessagesFormControl.value).map(message => {
            return message.id;
          });

          const resultMessage = new Message({
            body: messageToSend,
            forwardIds: forwardingMessagesIds,
            isQuestionToSpeaker: false,
            attachments: [],
            replyMessageId: null,
            sender: currentChatParticipant
          });

          this.messageBodyTextarea.nativeElement.style.height = 'auto';
          this.ckClearCommandList();
          this.setForwardingMessages(null);
          return this.submitMessage.emit(resultMessage);
        }

        let filesToSend$ = of([]);
        if (files.length > 0) {
          filesToSend$ = forkJoin(
            files.map(file => {
              return this.fileService.add(file);
            })
          );
        }

        filesToSend$.subscribe(modifiedFiles => {
          messageToSend = (<string>this.messageFormBodyControl.value || '').trim();

          if (!messageToSend && files.length === 0) {
            return;
          }

          const isQTS = this.handleQTSTag ? isQuestionToSpeaker : false;

          const message = new Message({
            body: isQTS ? this.messageToQTS(messageToSend) : messageToSend,
            isQuestionToSpeaker: isQTS,
            attachments: modifiedFiles,
            replyMessageId,
            sender: currentChatParticipant
          });

          this.submitMessage.emit(message);
          this.ckClearCommandList();
          this.messageBodyTextarea.nativeElement.style.height = 'auto';
        });
      });
  }

  textAreaKeydown(event: KeyboardEvent): void {
    if (event.key === 'Enter' && !(event.shiftKey || this.appService.isMobile)) {
      event.preventDefault();
      this.submitForm();
    }
  }

  resizeTextArea(): void {
    const paddingTop = this.textAreaStyle.paddingTop;
    const paddingBottom = this.textAreaStyle.paddingBottom;
    const lineHeight = this.textAreaStyle.lineHeight;

    const linesCount = (this.messageBodyTextarea.nativeElement.scrollHeight - paddingBottom - paddingTop) / lineHeight;

    this.messageBodyTextarea.nativeElement.style.height = 'auto';
    if (linesCount <= this.textAreaMaxRowsCount) {
      this.messageBodyTextarea.nativeElement.style.height = this.messageBodyTextarea.nativeElement.scrollHeight + 'px';
    } else {
      const diff = (linesCount - this.textAreaMaxRowsCount) * lineHeight;
      this.messageBodyTextarea.nativeElement.style.height =
        this.messageBodyTextarea.nativeElement.scrollHeight - diff + 'px';
    }
    this.messageBodyTextarea.nativeElement.scrollTop = this.messageBodyTextarea.nativeElement.scrollHeight;
    this.textAreaResize.emit(Number(this.messageBodyTextarea.nativeElement.style.height.replace(/px/, '')));
  }

  messageToQTS(message: string): string {
    if (message.trim()) {
      message = `${this.QTSTag} ${message}`;
    }
    return message;
  }

  onBlurTextArea($event: FocusEvent): void {
    if (this.preventBlur) {
      $event.preventDefault();
      $event.stopPropagation();
      this.messageBodyTextarea.nativeElement.focus();
      this.preventBlur = false;
      return;
    }

    this.focused = false;
  }

  setPreventBlur(button: MatButton): void {
    button._elementRef.nativeElement.blur();
    this.preventBlur = true;
  }

  handlePaste($event: ClipboardEvent): void {
    /* если есть файлы, то мы запрещаем вставку текста
			с названиями файлов в форму сообщения;
			если файлов нет в прицнипе, то метод этот и не должен ничего делать
		 */
    const dataTypes = $event.clipboardData.types;

    if (dataTypes.includes('Files')) {
      $event.preventDefault();
    } else {
      return;
    }

    const files = this.fileService.getFilesFromClipboardEvent($event);

    this.dropArea.handleSelectedFiles(files);
  }

  setReplyingMessage(message: Message): void {
    const isMessageFormBusy =
      isTruthy(this.editMessageIdFormControl.value) ||
      (isTruthy(this.forwardingMessagesFormControl.value) && this.forwardingMessagesFormControl.value.length > 0);

    if (isMessageFormBusy && message !== null) {
      return;
    }
    this.replyingMessage = message;
    this.messageForm.patchValue({ replyMessageId: message ? message.id : null });
  }

  setEditingMessage(message: Message): void {
    const isMessageFormBusy =
      isTruthy(this.replyingMessageIdFormControl.value) ||
      (isTruthy(this.forwardingMessagesFormControl.value) && this.forwardingMessagesFormControl.value.length > 0);

    if (!message || isMessageFormBusy) {
      return;
    }
    this.editingMessage = message;
    const attachments = this.editingMessage.attachments || [];
    this.messageForm.patchValue({
      body: message ? message.body : null,
      editMessageId: message ? message.id : null,
      attachments
    });
    this.resizeTextArea();
  }

  setForwardingMessages(messages: Message[]): void {
    this.forwardingMessages = messages;
    this.messageForm.patchValue({
      forwardingMessages: isTruthy(messages) ? messages : null,
      body: isTruthy(messages) ? '' : null
    });
  }

  focusMessageEditor(): void {
    if (this.chat && !this.appService.isMobile) {
      this.setFocusTrigger$.next();
    }
  }

  reset(clearDraft = false): void {
    const isForwardingMessagesControlNotEmpty = isTruthy(this.forwardingMessagesFormControl.value);
    if (isForwardingMessagesControlNotEmpty) {
      return;
    }
    this.messageForm.reset();
    if (clearDraft) {
      this.store.dispatch(ChatDraftActions.upsertDraft({ chatId: this.chat.id, draft: '' }));
    }
  }

  getMessagePreview(message: Message): Observable<string> {
    return this.chatService.getMessagePreviewContent(message);
  }

  openEmojiDialog(): Observable<void> {
    return this.showEmoji$.pipe(
      take(1),
      map(showEmoji => {
        return this.showEmojiSubject$.next(!showEmoji);
      })
    );
  }

  isGiphyEnabled$: Observable<boolean> = this.giphyService.isGiphyEnabled$.pipe(
    distinctUntilChanged(),
    replayWhileSubs()
  );

  hasGiphyApiKey$: Observable<boolean> = this.giphyService.giphyApiKey$.pipe(
    map(giphyApiKey => {
      return isTruthy(giphyApiKey) && giphyApiKey !== '';
    }),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  noGiphyData = false;

  openGiphyDialog(emojiButton?: MatButton): void {
    this.giphyService
      .openDialog$()
      .pipe(
        switchMap(result => {
          return of(result).pipe(
            switchMap(({ contentType, file }: GiphyDialogResult) => {
              if (!isTruthy(file) || contentType === GiphyContentTypeEnum.GIFS) {
                return of(file);
              }

              return this.fileService.add(file).pipe(
                tap(file_ => {
                  return this.submitMessage.emit(
                    new Message({
                      body: '',
                      attachments: [file_],
                      isQuestionToSpeaker: false
                    })
                  );
                })
              );
            }),
            map(file => {
              if (!isTruthy(file)) {
                return;
              }
              this.attachmentsFormControl.patchValue([file]);
            })
          );
        }),
        take(1)
      )
      .subscribe(() => {
        emojiButton?._elementRef.nativeElement.blur();
        this.messageBodyTextarea.nativeElement.focus();
      });
  }

  disableTextArea(): void {
    this.messageBodyTextarea.nativeElement.setAttribute('disabled', 'true');
  }

  enableAndFocusTextarea(): void {
    this.messageBodyTextarea.nativeElement.removeAttribute('disabled');
    this.messageBodyTextarea.nativeElement.focus();
  }

  addEmoji($event: any): void {
    const { selection } = this.editor.editorInstance.model.document;
    const range = selection.getFirstRange();

    this.editor.editorInstance.model.change(writer => {
      writer.insertText($event.emoji.native, range.start);
    });
  }

  clickOutside($event: Event, actvated = false): void {
    if ($event.isTrusted && actvated) {
      this.showEmojiSubject$.next(false);
    }
  }

  ckClearCommandList(): void {
    this.ckCommandStack$.next(null);
  }

  ckeditorChangeData($event: ChangeEvent): void {
    setTimeout(() => {
      const editor = $event.editor;
      const currentHeight = this.getEditorHeight(editor);

      if (currentHeight !== this.previousHeight) {
        this.scrollToBottom.next();
      }

      this.previousHeight = currentHeight;
    });

    this.maxCharactersTrigger$.next(false);
    if (this.ngModelCk?.length === 0) {
      this.ckClearCommandList();
    }
    this.ngModelCk$.next(this.ngModelCk);
  }

  onReady(editor: CKEditor5.Editor): void {
    this.previousHeight = this.getEditorHeight(editor);
  }

  ckSwitchAdvancedMode(): void {
    this.ckHideToolbarState$.next(!this.ckHideToolbarState$.value);
  }

  ckKeydown(event: KeyboardEvent): void {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const submit = () => {
      event.preventDefault();
      this.submitForm();
    };
    // this.emojiActivated
    if (event.key === 'Enter' && !(event.shiftKey || this.appService.isMobile)) {
      if (event.ctrlKey) {
        submit();
      } else {
        this.ckAdvancedMode$
          .pipe(
            take(1),
            switchMap(ckAdvancedMode => {
              return ckAdvancedMode ? of(true) : this.ckStaticAdvanceMod$();
              // Перепроверяем, список был и пользователь повторно зашел в него нет события
            })
          )
          .subscribe(ckAdvancedMode => {
            if (!ckAdvancedMode) {
              submit();
            }
          });
      }
    }
  }

  private getEditorHeight(editor: CKEditor5.Editor): number {
    const editorElement = editor.ui.view.editable.element;
    return editorElement.scrollHeight;
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy(): void {}
}
