import { TypedAction } from '@ngrx/store/src/models';
import { ErrorMessageModel, ExecutionItem, ExecutionModel, ExecutionResult } from '@breez/models/+state';

function generateExecutionItem<
  IA extends TypedAction<string>,
  RA extends TypedAction<string> | (TypedAction<string> & ErrorMessageModel),
  R extends keyof RA
>(initAction: IA, resultAction: RA = null, resultProp: R = null): ExecutionItem {
  const isError = !!resultAction && !!resultAction.hasOwnProperty('errorMessage');
  const hasResult = !!resultAction && !!resultAction[resultProp];
  return {
    [initAction.type]: {
      processing: !hasResult && !isError,
      result: !!hasResult,
      errorMessage: isError ? (resultAction as ErrorMessageModel).errorMessage : null
    }
  } as ExecutionItem;
}

function generateExecution<
  IA extends TypedAction<string>,
  RA extends TypedAction<string> | (TypedAction<string> & ErrorMessageModel),
  R extends keyof RA
>(
  execution: ExecutionModel,
  objectId: string | number,
  initAction: IA,
  resultAction: RA = null,
  resultProp: R = null
): ExecutionModel {
  return {
    ...execution,
    [objectId]: {
      ...execution[objectId],
      ...generateExecutionItem(initAction, resultAction, resultProp)
    }
  };
}

function executionSelector(objectId: string, action: TypedAction<string>) {
  return (state: ExecutionModel): ExecutionResult => {
    if (!objectId) {
      return null;
    }
    const execution = state[objectId];
    if (!execution) {
      return null;
    }

    return execution[action.type] || null;
  };
}

function executionSelectorProcessing(execution: ExecutionResult): boolean {
  return (execution || ({ processing: false } as ExecutionResult)).processing;
}

function executionSelectorResult(execution: ExecutionResult): boolean {
  return (execution || ({ result: false } as ExecutionResult)).result;
}

function executionSelectorError(execution: ExecutionResult): string {
  return (execution || ({} as ErrorMessageModel)).errorMessage;
}

export const ExecutionResultHelper = {
  generateExecution,
  executionSelector,
  executionSelectorProcessing,
  executionSelectorResult,
  executionSelectorError
};
