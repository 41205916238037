import { Expose, Transform } from 'class-transformer';
import { ObjectType } from '@breez/shared/enums/object-type.enum';

export class ParticipationIntervalsInfo {
  @Expose({ name: 'intervalorder' })
  order: number;

  @Expose({ name: 'startdate' })
  @Transform(({ value }) => {
    return new Date(value * 1000);
  })
  startDate: Date;

  @Expose({ name: 'enddate' })
  @Transform(({ value }) => {
    return new Date(value * 1000);
  })
  endDate: Date;

  @Expose({ name: 'type' })
  @Transform(({ value }) => {
    return value ? ObjectType[value.toUpperCase()] : undefined;
  })
  objectType: ObjectType;

  @Expose()
  users: number[];
}
