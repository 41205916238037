import { ConferenceChatEnum } from '../enums';
import { CHAT_ID } from '../types';
import { User } from '@breez/models';

export class ChatModel {
  chatId: CHAT_ID;

  type: ConferenceChatEnum;

  title?: string;

  userId?: number;

  userName?: string;

  constructor(base: Partial<ChatModel>) {
    return Object.assign(this, base);
  }

  getUser(): User {
    return new User({
      id: this.userId,
      name: this.userName
    });
  }
}
