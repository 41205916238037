import { Inject, Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { UserStatus } from '@breez/modules/user/modules/models/user-status.enum';
import { IUserStatusSource } from '@breez/modules/user/modules/models/user-status-source.model';
import { delay, distinctUntilChanged, finalize, startWith, tap } from 'rxjs/operators';
import { replayWhileSubs } from '@breez/shared/rxjs-operators';
import { USER_STATUS_SOURCE } from '../user-status-source.provider';
import { UserStatusStorageService } from '@breez/modules/user/modules/avatar-smart/services/user-status-storage.service';
import { WebsocketService } from '@breez/modules/websocket';

@Injectable({
  providedIn: 'root'
})
export class UserStatusService {
  sources = new Map<number, Observable<UserStatus>>();

  isSocketConnect$ = this.wsService.socketReconnect$.pipe(distinctUntilChanged(), replayWhileSubs());

  constructor(
    @Inject(USER_STATUS_SOURCE) private readonly userStatusSourceService: IUserStatusSource,
    private readonly userStatusStorageService: UserStatusStorageService,
    private readonly wsService: WebsocketService
  ) {}

  observeUserStatusById(userId: number): Observable<UserStatus> {
    let userStatusStream$ = this.sources.get(userId);

    if (!userStatusStream$) {
      const userStatusStreamRequest$ = this.userStatusSourceService.observeUserStatusById(userId).pipe(
        tap(userStatus => {
          return this.userStatusStorageService.setByUserId(userId, userStatus);
        }),
        startWith(this.userStatusStorageService.getByUserId(userId))
      );

      userStatusStream$ = this.isSocketConnect$.pipe(
        delay(500),
        switchMap(isSocketConnect => {
          return isSocketConnect ? userStatusStreamRequest$ : of(null);
        }),
        finalize(() => {
          return this.clearObserverById(userId);
        }),
        replayWhileSubs()
      );

      this.sources.set(userId, userStatusStream$);
    }

    return userStatusStream$;
  }

  private clearObserverById(userId: number): void {
    this.sources.delete(userId);
  }
}
