import { InjectionToken, NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { HomeComponent } from '@breez/components/home/home.component';
import { environment } from '@breez/environment';
import { NotFoundPageComponent } from '@breez/pages/not-found-page/not-found-page.component';
import { AuthGuard } from '@breez/modules/auth/guards/auth.guard';
import { LeavingComponent } from '@breez/components/leaving/leaving.component';

export const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');
const APP_MODULES_LAZY: Routes = [
  {
    path: 'auth',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    loadChildren: () => {
      return import('./modules/auth/auth.module').then(module => {
        return module.AuthModule;
      });
    }
  },
  {
    path: 'file',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    loadChildren: () => {
      return import('./modules/file/file.module').then(module => {
        return module.FileModule;
      });
    }
  },
  {
    path: 'conference',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    loadChildren: () => {
      return import('./modules/conference/conference.module').then(module => {
        return module.ConferenceModule;
      });
    }
  },
  {
    path: 'verify',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    loadChildren: () => {
      return import('./modules/verify/verify.module').then(module => {
        return module.VerifyModule;
      });
    }
  },
  {
    path: 'settings',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    loadChildren: () => {
      return import('./modules/settings/settings.module').then(module => {
        return module.SettingsModule;
      });
    }
  },
  {
    path: 'social',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    loadChildren: () => {
      return import('./modules/social/social.module').then(module => {
        return module.SocialModule;
      });
    }
  },
  {
    path: 'statistics',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    loadChildren: () => {
      return import('./modules/statistics/statistics.module').then(module => {
        return module.StatisticsModule;
      });
    }
  },
  {
    path: 'chat',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    loadChildren: () => {
      return import('./modules/chat/chat.module').then(module => {
        return module.ChatModule;
      });
    }
  },
  {
    path: 'records',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    loadChildren: () => {
      return import('./modules/conference-records/conference-records.module').then(module => {
        return module.ConferenceRecordsModule;
      });
    }
  },
  {
    path: 'record',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    loadChildren: () => {
      return import('./modules/conference-records/conference-records.module').then(module => {
        return module.ConferenceRecordsModule;
      });
    }
  },
  {
    path: 'help',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    loadChildren: () => {
      return import('./modules/help/help.module').then(module => {
        return module.HelpModule;
      });
    }
  },
  {
    path: 'registration',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    loadChildren: () => {
      return import('./modules/inviting/guest-registration').then(module => {
        return module.GuestRegistrationModule;
      });
    }
  }
];

const routes: Routes = [
  ...APP_MODULES_LAZY,
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
    canActivate: environment.type === 'electron' ? [AuthGuard] : [] // electron не показывал форму логина
  },
  {
    path: 'externalRedirect',
    pathMatch: 'full',
    redirectTo: '404'
  },
  {
    path: 'go-to-app',
    pathMatch: 'full',
    component: LeavingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    pathMatch: 'full',
    component: NotFoundPageComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: environment.type !== 'production' && environment.enableTracing,
      useHash: environment.type === 'electron',
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
      scrollOffset: [0, 50],
      preloadingStrategy: PreloadAllModules
      // onSameUrlNavigation: 'reload'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
