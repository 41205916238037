import { Pipe, PipeTransform } from '@angular/core';
import { DeclensionService } from '@breez/shared/services/declension.service';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'durationFormat'
})
export class DurationFormatPipe implements PipeTransform {
  constructor(private declensionService: DeclensionService) {}

  transform(startDate: Date, endDate: Date, showSeconds = true): Observable<string> {
    if (endDate?.getTime() < startDate?.getTime()) {
      return of('CONFERENCE_CANCELLED');
    }
    if (startDate && endDate) {
      const milliseconds = +endDate - +startDate;
      const diff = moment.duration(milliseconds);
      const seconds = diff.seconds().toString().padStart(2, '0');
      const minutes = diff.minutes().toString().padStart(2, '0');
      const hours = diff.hours().toString().padStart(2, '0');
      const days = Math.floor(diff.asDays());
      return this.declensionService.getDeclension('DAY', days).pipe(
        map(declension => {
          return days > 0 ? `${days} ${declension}` : '';
        }),
        map(d => {
          return `${d} ${hours}:${minutes}${showSeconds ? `:${seconds}` : ``}`.toLowerCase();
        })
      );
    }
    return of('—');
  }
}
