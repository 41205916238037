import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Inject, Output } from '@angular/core';
import { CURRENT_CARD_DATA } from '@breez/modules/social/services/user-card.service';

@Component({
  selector: 'vks-contact-card-wrapper',
  templateUrl: './contact-card-wrapper.component.html',
  styleUrls: ['./contact-card-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactCardWrapperComponent {
  @Output() closePanel = new EventEmitter<void>();

  @HostBinding('class.mat-dialog-container') matDialog = true;

  constructor(@Inject(CURRENT_CARD_DATA) public data) {}

  close(): void {
    this.closePanel.emit();
  }
}
