import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ControlUserSelectModule } from '@breez/modules/user/control-user-select';
import { WebrtcModule } from '@breez/modules/webrtc/webrtc.module';
import { SharedModule } from '@breez/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { CallDynamicModule } from '@breez/modules/call/call-statuses/dynamic/dynamic.module';
import { CALLSTATUS_TYPES_MODULES } from '@breez/modules/call/call-statuses/types';

@NgModule({
  imports: [
    CommonModule,
    ControlUserSelectModule,
    SharedModule,
    ReactiveFormsModule,
    TranslateModule,
    WebrtcModule,
    MatSlideToggleModule,
    MatCardModule,
    MatSlideToggleModule,
    MatTooltipModule,
    CallDynamicModule,
    ...CALLSTATUS_TYPES_MODULES
  ],
  providers: []
})
export class CallModule {}
