import { InjectionToken } from '@angular/core';
import { environment } from '@breez/environment';
import { WebrtcConfig } from '@breez/models/user-select/webrtc-config.model';

export const config = new InjectionToken('webrtc');
export const DEFAULT_WEBRTC_CONSTRAINTS = new InjectionToken('default_webrtc_constraints');

export function generateWebrtcConfig(): WebrtcConfig {
  const iceServersDomain = environment.iceServersDomain || window.location.hostname;
  const iceServers: RTCIceServer[] = [
    {
      urls: `stun:${iceServersDomain}`
    },
    {
      urls: `turn:${iceServersDomain}`,
      credential: 'vksuser',
      username: 'vksuser'
    }
  ];

  return <WebrtcConfig>{
    bundlePolicy: 'balanced',
    rtcpMuxPolicy: 'require',
    iceCandidatePoolSize: undefined,
    iceTransportPolicy: 'all',
    iceServers
  };
}

export function generateWebrtcConstraints(): any {
  let localStorageConfig;

  try {
    localStorageConfig = JSON.parse(localStorage.getItem('rtc-constraints'));
  } catch {}

  if (localStorageConfig) {
    return localStorageConfig;
  }

  return {
    optional: [
      { googHighStartBitrate: 0 },
      { googPayloadPadding: true },
      { googScreencastMinBitrate: 250 },
      { googCpuOveruseDetection: true },
      { googCpuOveruseEncodeUsage: true },
      { googCpuUnderuseThreshold: 45 },
      { googCpuOveruseThreshold: 75 }
    ]
  };
}
