<div *ngIf="isMobileDevice$ | async | not" class="spacer"></div>

<div class="list">
  <span style="display: none">
    <vks-icon [color]="'#00000000'" [icon]="'info-outlined'"></vks-icon>
    <vks-icon [color]="'#00000000'" [icon]="'control-time'"></vks-icon>
    <vks-icon [color]="'#00000000'" [icon]="'message-check-delivered'"></vks-icon>
    <vks-icon [color]="'#00000000'" [icon]="'message-check-sent'"></vks-icon>
  </span>

  <div *ngFor="let groupEntry of listGroups$ | async; trackBy: trackByGroup">
    <vks-chat-message-date-stub *ngIf="!conferenceId" [stub]="groupEntry.dateStub"></vks-chat-message-date-stub>
    <ng-container
      *ngFor="let messageEntry of groupEntry.messageEntries; trackBy: trackByMessage"
      [ngSwitch]="messageEntry.type"
    >
      <vks-chat-message-view
        *ngSwitchCase="messageListEntryEnum.MESSAGE"
        [allowForward]="allowForward"
        [conferenceCreator]="conferenceCreator"
        [conferenceId]="conferenceId"
        [isActiveConference]="isActiveConference"
        [isDelivered]="messageEntry.isDelivered"
        [isDemoChat]="isDemoChat"
        [isGroupFirst]="messageEntry.isGroupFirst"
        [isGroupLast]="messageEntry.isGroupLast"
        [isLast]="messageEntry.isLast"
        [isOut]="messageEntry.isOut"
        [isFavoriteChat]="isFavorite"
        [isBanned]="isBanned"
        [isQuestionToSpeaker]="messageEntry.message.isQuestionToSpeaker"
        [isRead]="messageEntry.isRead"
        [message]="messageEntry.message"
        (blockChat)="blockChat.emit(messageEntry.message.sender.userId)"
        (replyMessageByDblclick)="replyMessage.emit(messageEntry.message)"
        (deleteMessage)="deleteMessage.emit(messageEntry.message)"
        (editMessage)="editMessage.emit(messageEntry.message)"
        (retrySendMessage)="retrySendMessage.emit(messageEntry.message)"
        (forwardMessage)="forwardMessage.emit($event)"
        (forwardMessageToChat)="forwardMessageToChat.emit($event)"
        (issueWarning)="issueWarning.emit(messageEntry.message.sender.userId)"
        (markAsRead)="markAsRead.emit(messageEntry.message)"
        (replyMessage)="replyMessage.emit(messageEntry.message)"
        (unblockChat)="unblockChat.emit(messageEntry.message.sender.userId)"
        (pinMessage)="pinMessage.emit(messageEntry.message)"
        (addToFavorites)="addToFavorites.emit(messageEntry.message)"
      ></vks-chat-message-view>
      <ng-container *ngIf="!conferenceId">
        <vks-chat-message-unread-stub *ngSwitchCase="messageListEntryEnum.UNREAD_STUB"></vks-chat-message-unread-stub>
      </ng-container>
    </ng-container>
  </div>
  <!--	</cdk-virtual-scroll-viewport>-->
</div>

