import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@breez/shared/shared.module';
import { COMPONENTS } from './components';
import { OVERLAY_PROVIDERS } from './overlay-provider';
import { MatSliderModule } from '@angular/material/slider';

@NgModule({
  imports: [CommonModule, TranslateModule, MatTooltipModule, SharedModule, MatMenuModule, MatSliderModule],
  declarations: [COMPONENTS],
  providers: [...OVERLAY_PROVIDERS]
})
export class CallStatusRingAcceptModule {}
