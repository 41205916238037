import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'timeDurationFormat'
})
export class TimeDurationFormatPipe implements PipeTransform {
  transform(durationDate: Date): string {
    if (!(durationDate instanceof Date)) {
      return '—';
    }

    return moment(durationDate).utc().format('HH:mm:ss') as string;
  }
}
