import { Inject, Injectable } from '@angular/core';
import { NotificationService } from '@breez/modules/notification/notification.service';
import { WebsocketEvents } from '@breez/modules/websocket/websocket.events';
import { untilDestroyed } from 'ngx-take-until-destroy';
import {
  BehaviorSubject,
  defer,
  EMPTY,
  fromEvent,
  interval,
  merge,
  Observable,
  of,
  ReplaySubject,
  Subject
} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  mergeMap,
  scan,
  share,
  shareReplay,
  skip,
  startWith,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { webSocket, WebSocketSubject, WebSocketSubjectConfig } from 'rxjs/webSocket';
import * as uuid from 'uuid';
import { WEBSOCKET_CONFIG } from './websocket.config';
import {
  IQueryData,
  IQueryParameters,
  IRemotePayload,
  IWebsocketMessageIdentifier,
  IWebsocketService,
  IWsMessage,
  WebSocketConfig
} from './websocket.interfaces';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { replayWhileSubs } from '@breez/shared/rxjs-operators';
import { LoggerService } from '@breez/shared/services/logger.service';
import { LocalStorage } from '@breez/shared/modules/storage/interfaces/local-storage.interface';
import { ORIGIN_URL_STORAGE_KEY } from '@breez/shared/utilities/generate-origin';
import { environment } from '@breez/environment';
import { KeycloakAuthService } from '@breez/modules/auth/services/keycloak-auth.service';
import { waitFor } from '@breez/shared/rxjs-operators/wait-for';
import { IAuthResponse } from '@breez/models';

export type Message = IWsMessage;

export const WEBSOCKET_URL_STORAGE_KEY = 'vks-ws-url';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService implements IWebsocketService {
  connectionWasLostSubject$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  connectionWasLost$: Observable<boolean> = this.connectionWasLostSubject$.pipe(
    distinctUntilChanged(),
    replayWhileSubs()
  );

  socketReconnect$ = new ReplaySubject<boolean>(1);

  private checkAuthorizedSocket$: Observable<boolean> = this.socketReconnect$.pipe(
    switchMap(socketReconnect => {
      return socketReconnect
        ? defer(() => {
            return this.listen<IAuthResponse>({ path: WebsocketEvents.RECEIVE.AUTHORIZE.LOGIN }, () => {
              return null;
            });
          })
        : of(null);
    }),
    map(isTruthy),
    replayWhileSubs()
  );

  isAuthorizedSocket$ = this.checkAuthorizedSocket$.pipe(
    filter(authSocket => {
      return authSocket;
    })
  );

  private websocketUrl: string;

  isClientOnline$: Observable<boolean> = merge(
    fromEvent(window, 'online').pipe(mapTo(true)),
    fromEvent(window, 'offline').pipe(mapTo(false))
  ).pipe(startWith(navigator.onLine), distinctUntilChanged(), shareReplay(1));

  authInstanceTrigger$: ReplaySubject<void> = new ReplaySubject(1);

  sendingMessage$ = new Subject<Message>();

  createWebsocketInstanceTrigger$ = new ReplaySubject<void>(1);

  websocketInstance$: Observable<WebSocketSubject<Message>> = this.authInstanceTrigger$.pipe(
    switchMap(() => {
      return this.keycloakAuthService.checkAuth().pipe(
        filter(auth => {
          return auth === true;
        }),
        switchMap(() => {
          return this.createWebsocketInstanceTrigger$.pipe(
            // createWebsocketConnection отдаст значение только при удачном соединение
            switchMap(() => {
              return this.createWebsocketConnection().pipe(
                tap(() => {
                  this.socketReconnect$.next(true);
                  this.logger.log('Connected to websocket');
                }),
                // когда соединение будет закрыто или провалится с ошибкой, из createWebsocketConnection вывалится ошибка
                catchError(error => {
                  this.socketReconnect$.next(false);
                  let message = 'Disconnected from websocket on demand';
                  if (error) {
                    this.logger.error(error);
                    message = 'Connection to websocket failed';
                  }

                  this.logger.error(message);

                  // она будет перехвачена здесь и превратит инстанс в null
                  return of(null as WebSocketSubject<Message>);
                })
              );
            }),
            scan((acc, cur) => {
              if (isTruthy(acc)) {
                acc.unsubscribe();
                acc.complete();
                acc = null;
              }

              return cur;
            }, null as WebSocketSubject<Message>)
          );
        }),
        replayWhileSubs()
      );
    }),
    replayWhileSubs()
  );

  websocketReconnectTrigger$ = this.websocketInstance$.pipe(
    // проверяем, определёно ли следующее значение инстанса
    map(isTruthy),
    switchMap(defined => {
      // если определено, значит ошибки не было, а сокет удачно соединился
      if (defined || !this.autoReconnect) {
        // this.autoReconnect = true; // TODO для IOS без рефреша
        return EMPTY;
      }

      // иначе, если сокет отключился или провалился, заводим два триггера
      // триггер нужен только один раз (какой из двух раньше сработает), чтобы инициировать попытку соединения;
      // если она провалится, триггеры всё равно заведутся по новой
      return merge(
        interval(this.reconnectInterval), // первый сработает через интервал
        this.isClientOnline$ // второй в момент возвращения соединения с интернетом;
          .pipe(
            skip(1), // skip нужен, чтобы обойти shareReplay
            filter(isOnline => {
              return isOnline;
            })
          )
      ).pipe(take(1));
    })
  );

  incomingMessages$: Observable<Message> = this.websocketInstance$.pipe(
    switchMap(websocketInstance => {
      return websocketInstance || EMPTY;
    }),
    share()
  );

  messageSender$: Observable<void> = this.sendingMessage$.pipe(
    tap(message => {
      if (message.event !== WebsocketEvents.SEND.PING) {
        this.logger.info(message);
      }
    }),
    mergeMap(message => {
      if (!message) {
        return EMPTY;
      }
      return this.websocketInstance$.pipe(
        filter(isTruthy),
        take(1),
        map(websocketInstance => {
          return websocketInstance.next(message);
        })
      );
    })
  );

  status$: Observable<boolean> = this.websocketInstance$.pipe(map(isTruthy), shareReplay(1));

  private readonly defaultConfig: WebSocketSubjectConfig<Message>;

  autoReconnect = true;
  private reconnectInterval: number = this.wsConfig.reconnectInterval || 5000;
  private pingInterval: number = this.wsConfig.pingInterval || 30000;

  constructor(
    @Inject(WEBSOCKET_CONFIG) private wsConfig: WebSocketConfig,
    private notificationService: NotificationService,
    private logger: LoggerService,
    private localStorage: LocalStorage,
    private keycloakAuthService: KeycloakAuthService
  ) {
    this.websocketUrl = wsConfig.url;
    this.defaultConfig = {
      url: wsConfig.url,
      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
      serializer: (value: any) => {
        return typeof value === 'string' ? value : JSON.stringify(value);
      },
      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
      deserializer: event => {
        try {
          const message = JSON.parse(event.data);
          return message.event || message.traceId ? (message as Message) : ({ data: message } as Message);
        } catch {
          return { code: 200, data: event.data } as Message;
        }
      }
    };

    this.listen<boolean>({ path: WebsocketEvents.RECEIVE.SESSION.CLOSED })
      .pipe(withLatestFrom(this.websocketInstance$), untilDestroyed(this, 'destroy'))
      .subscribe(([, websocketInstance]) => {
        this.autoReconnect = false;
        if (websocketInstance) {
          websocketInstance.unsubscribe();
          websocketInstance.complete();
          websocketInstance = null;
        }
      });

    this.isAuthorizedSocket$.pipe(untilDestroyed(this, 'destroy')).subscribe();

    this.websocketInstance$.pipe(untilDestroyed(this, 'destroy')).subscribe();

    this.messageSender$.pipe(untilDestroyed(this, 'destroy')).subscribe();

    this.websocketReconnectTrigger$.pipe(untilDestroyed(this, 'destroy')).subscribe(() => {
      this.createWebsocketInstanceTrigger$.next();
    });
    this.createWebsocketInstanceTrigger$.next();

    this.isClientOnline$
      .pipe(
        filter(status => {
          return !!status;
        }),
        switchMap(() => {
          return interval(this.pingInterval).pipe(
            switchMap(() => {
              // this.pongValidationObservableSubscription = this.pongValidationObservable.subscribe();
              return this.query(WebsocketEvents.SEND.PING);
            })
          );
        }),
        untilDestroyed(this, 'destroy')
      )
      .subscribe();

    this.keycloakAuthService.isKeycloakLoggedIn$
      .pipe(
        filter(isKeycloakLoggedIn => {
          return isKeycloakLoggedIn === true;
        }),
        take(1)
      )
      .subscribe(() => {
        return this.authInstanceTrigger$.next();
      });
  }

  getWebsocketClearUrl(url?: string): any {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const clear = hostedUrl => {
      return hostedUrl.replace(/(^\w+:|^)\/\//, '');
    };
    return clear(url ? url : this.websocketUrl).replace(environment.wsApi, '');
  }

  updateWebsocketUrl(wsUrl: string): boolean {
    const clearWsUrl = this.getWebsocketClearUrl(wsUrl);
    const wssRequire = new RegExp('^(wss)://', 'i');
    const websocketUrl = wssRequire.test(clearWsUrl) ? clearWsUrl : `wss://${clearWsUrl}`;
    if (websocketUrl !== this.websocketUrl) {
      this.websocketUrl = websocketUrl + environment.wsApi;
      this.localStorage.setItem(WEBSOCKET_URL_STORAGE_KEY, websocketUrl);
      this.localStorage.setItem(ORIGIN_URL_STORAGE_KEY, `https://${this.getWebsocketClearUrl()}`);
      return true;
    }
    return false;
  }

  createWebsocketConnection(): Observable<WebSocketSubject<any>> {
    return new Observable(subscriber => {
      let webSocketSubject: WebSocketSubject<Message>;
      const config: WebSocketSubjectConfig<Message> = {
        ...this.defaultConfig,
        ...{ url: this.websocketUrl },
        openObserver: {
          next: () => {
            return subscriber.next(webSocketSubject);
          }
        },
        closeObserver: {
          next: event => {
            return subscriber.error(event);
          }
        },
        closingObserver: {
          next: () => {
            return subscriber.error();
          },
          error: error => {
            return subscriber.error(error);
          }
        }
      };
      webSocketSubject = webSocket(config);
      webSocketSubject
        .pipe(
          catchError(error => {
            if (error.type === 'close') {
              this.connectionWasLostSubject$.next(true); // hard reset
            }
            return EMPTY;
          })
        )
        .subscribe();

      const clientOnlineSubscription = this.isClientOnline$
        .pipe(
          filter(isOnline => {
            return !isOnline;
          })
        )
        .subscribe(() => {
          return subscriber.error(new Error('Lost connection'));
        });

      return () => {
        clientOnlineSubscription.unsubscribe();
        if (!this.connectionWasLostSubject$.value) {
          this.connectionWasLostSubject$.next(true);
        }
        if (!webSocketSubject) {
          return;
        }
        webSocketSubject.unsubscribe();
        webSocketSubject.complete();
        webSocketSubject = null;
      };
    });
  }

  // noinspection JSUnusedLocalSymbols
  private destroy(): void {}

  reconnect(): void {
    return this.createWebsocketInstanceTrigger$.next();
  }

  send_(plain: IWsMessage, immediately = false): void {
    if (!plain) {
      throw new Error('Plain object must be defined');
    }
    if (immediately) {
      this.sendingMessage$.next(plain);
    } else {
      this.isAuthorizedSocket$.pipe(take(1)).subscribe(() => {
        return this.sendingMessage$.next(plain);
      });
    }
  }

  listen<T = any>(
    { traceId, path, parent }: IWebsocketMessageIdentifier,
    catchAction?: (obj: any) => any
  ): Observable<IRemotePayload<T>> {
    if (!traceId && !path) {
      return EMPTY;
    }

    return this.incomingMessages$.pipe(
      filter(isTruthy),
      filter(message => {
        if (!!traceId && message.traceId) {
          return traceId === message.traceId;
        }

        if (typeof path !== 'string' || typeof message.event !== 'string') {
          return false;
        }

        if (parent && message.parent) {
          return parent.id === message.parent.id && parent.resource === message.parent.resource;
        }

        return (path ?? '').toLocaleLowerCase() === message.event.toLowerCase();
      }),
      map(message => {
        if ((message.code || 200) < 400 && !message.errorMessage) {
          return { data: message.data, parent: message.parent } as IRemotePayload;
        }

        this.logger.error(message);
        return {
          data: message,
          error: new Error(message.errorMessage)
        } as IRemotePayload;
      }),
      catchError(obj => {
        this.logger.error(obj);
        return (catchAction ?? of)(obj);
      })
    );
  }

  observe<RespData = any, ReqData = any>(
    path: string,
    payload: IQueryData<ReqData> = {},
    params: IQueryParameters = {}
  ): Observable<RespData> {
    const { sendImmediately, responseFrom } = params;

    const instructions = (): Observable<RespData> => {
      const traceId = uuid.v4();
      const plain = { event: path, traceId, ...payload } as IWsMessage;

      const identificationToSwitch: IWebsocketMessageIdentifier = responseFrom || { traceId };

      this.send_(plain, sendImmediately);
      return this.listen(identificationToSwitch).pipe(
        map(({ data, error }) => {
          if (error) {
            throw { data, error };
          }
          return data;
        })
      );
    };

    if (!sendImmediately) {
      return defer(() => {
        return instructions();
      });
    }

    return instructions();
  }

  query<R = any, Q = any>(path: string, payload: IQueryData<Q> = {}, params: IQueryParameters = {}): Observable<R> {
    return this.observe<R, Q>(path, payload, params).pipe(take(1));
  }

  /**
   * @deprecated Should be used __WebsocketService.listen__
   */
  on<T>(path: string): Observable<T> {
    return this.listen<T>({ path }).pipe(
      map(resp => {
        return resp.data;
      })
    );
  }

  send<T = any>(event: string, request?: IQueryData): Observable<T> {
    return of(null).pipe(
      waitFor(() => {
        return this.isAuthorizedSocket$.pipe(filter(isTruthy), take(1));
      }),
      switchMap(() => {
        return this.query<T>(event, request);
      })
    );
  }

  /**
   * @deprecated
   */
  getErrorMessage(error: any): any {
    try {
      error = JSON.parse(error);
    } catch {}
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const getErrorText = (errorObj: any) => {
      return errorObj.ExceptionMessage || error.Message || errorObj.exceptionMessage || errorObj.message || errorObj;
    };

    let err = '';
    if (error.error) {
      err = getErrorText(error.error);
    }

    return typeof err === 'string' ? err : getErrorText(error);
  }
}
