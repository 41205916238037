import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

import { LocalStorage } from '../interfaces/local-storage.interface';
import { MemoryStorage } from '../interfaces/memory-storage.interface';
import { storageAvailable } from '../utils/storage.util';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class BaseLocalStorage implements LocalStorage {
  readonly changes = new ReplaySubject<{ key: string; value: string }>(1);
  /**
   * Storage
   */
  private readonly storage: Storage;

  constructor(
    memoryStorage: MemoryStorage,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId) && storageAvailable('localStorage')) {
      this.storage = window.localStorage;
    } else {
      this.storage = memoryStorage;
    }

    this.initChanges();
  }

  get length(): number {
    return this.storage.length;
  }

  clear(): void {
    this.initChanges(true);
    this.storage.clear();
  }

  getItem(key: string): string | null {
    return this.storage.getItem(key);
  }

  key(index: number): string | null {
    return this.storage.key(index);
  }

  removeItem(key: string): void {
    this.storage.removeItem(key);
    this.changes.next({ key, value: null });
  }

  setItem(key: string, value: string): void {
    this.storage.setItem(key, value);
    this.changes.next({ key, value });
  }

  private initChanges($needNullValue: boolean = false): void {
    for (let index = 0; index < this.length; index++) {
      const key = this.key(index);
      const value = !$needNullValue ? this.getItem(key) : null;
      this.changes.next({ key, value });
    }
  }
}
