<div class="filter-item">
  <p>{{ item.filterName | translate | uppercase }}:</p>
  <div [matMenuTriggerFor]="menu" class="arrow">
    {{ valueFromParams | translate | uppercase }}
    <vks-icon class="square24" color="#005259" icon="keyboard-arrow-down"></vks-icon>
  </div>
  <mat-menu #menu="matMenu" [hasBackdrop]="true" backdropClass="back-drop" class="filter-menu">
    <p class="menu-label">{{ item.header | translate | uppercase }}</p>
    <button
      *ngFor="let option of item.options"
      [class.selected-option]="option.key === valueFromParams"
      (click)="selectFilterValue(option)"
      mat-menu-item
    >
      {{ option.key | translate }}
    </button>
  </mat-menu>
</div>
