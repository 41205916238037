import { Injectable } from '@angular/core';
import { ViewParticipantRightModel } from '@breez/models/conference/view-participant-right.model';

@Injectable({
  providedIn: 'root'
})
export class ParticipantsService {
  getPossibleSpeakerRights(): ViewParticipantRightModel[] {
    return [
      {
        key: 'BLOCKING_MEMBER',
        title: 'BLOCKING_MEMBER',
        icon: 'rights-ban',
        tooltip: 'BLOCKING_MEMBER_TOOLTIP'
      },
      {
        key: 'BLOCKING_CHAT',
        title: 'BLOCKING_CHAT',
        icon: 'rights-write',
        tooltip: 'BLOCKING_CHAT_TOOLTIP'
      },
      {
        key: 'REALIZATION_OF_SURVEYS',
        title: 'REALIZATION_OF_SURVEYS',
        icon: 'rights-survey',
        tooltip: 'REALIZATION_OF_SURVEYS_TOOLTIP'
      },
      {
        key: 'MANAGING_THE_LAYOUT',
        title: 'MANAGING_THE_LAYOUT',
        icon: 'grid',
        tooltip: 'MANAGING_THE_LAYOUT_TOOLTIP'
      }
    ];
  }
}
