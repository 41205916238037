<ng-container *ngIf="virtualScroll; then virtualScrollTemplate; else defaultTemplate"></ng-container>
<ng-template #defaultTemplate>
  <div [class.colorize-nodes]="colorizeNodes" class="tree-default-container">
    <ng-container *ngFor="let node of dataNodes; trackBy: nodesTrackById">
      <ng-container *ngTemplateOutlet="abstractNode; context: { $implicit: node }"></ng-container>
    </ng-container>
  </div>
</ng-template>
<ng-template #virtualScrollTemplate>
  <cdk-virtual-scroll-viewport [class.colorize-nodes]="colorizeNodes" itemSize="32">
    <ng-container *cdkVirtualFor="let node of dataNodes; trackBy: nodesTrackById">
      <ng-container *ngTemplateOutlet="abstractNode; context: { $implicit: node }"></ng-container>
    </ng-container>
  </cdk-virtual-scroll-viewport>
</ng-template>
<ng-template #abstractNode let-node>
  <div
    [class.disabled]="node.disabled"
    [class.division]="!node.unit.type || node.unit?.type !== 'user'"
    [class.user]="node.unit?.type === 'user'"
    [style.padding-left.px]="node.level * 20"
    class="node color{{ node.classIndex }}"
  >
    <div [class.disabled]="node.disabled" class="content">
      <ng-container
        *ngTemplateOutlet="node.unit?.type === 'user' ? userNode : divisionNode; context: { $implicit: node }"
      >
      </ng-container>
    </div>
  </div>
</ng-template>
<ng-template #userNode let-node>
  <ng-container *ngIf="allowExpand">
    <div *ngIf="!node.expandable" class="margin-stub"></div>
    <button
      (click)="toggleExpansion(node)"
      *ngIf="node.expandable"
      class="button-chevron"
      mat-icon-button
      tabindex="-1"
    >
      <vks-icon [icon]="treeControl.isExpanded(node) ? 'chevron-down' : 'chevron-right'"></vks-icon>
    </button>
  </ng-container>
  <mat-checkbox
    (change)="toggleSelectLeaf(node)"
    *ngIf="allowSelecting && multiSelect"
    [checked]="leafSelected(node)"
    [disabled]="node.disabled"
    class="checklist-leaf-node"
  ></mat-checkbox>
  <div (click)="allowSelecting && toggleSelectLeaf(node)" class="user-snippet">
    <vks-user-avatar
      [displayIndicators]="node?.unit"
      [entity]="node?.unit"
      [size]="32"
      [status]="node?.unit?.status"
    ></vks-user-avatar>
    <span
      #user_title
      (contextmenu)="openContextMenu($event, node)"
      [class.clickable]="selectable"
      [class.invalid]="$any(node?.unit?.errors)?.invalidemail"
      [matTooltipDisabled]="userTooltipDisabled(user_title)"
      [matTooltip]="node.unit.displayName || node.unit.name || node.unit.email"
      class="label"
      >{{ node.unit.displayName || node.unit.name || node.unit.email }}</span
    >
  </div>
  <div class="indicators">
    <vks-icon
      *ngIf="$any(node?.unit?.errors)?.invalidemail as error"
      [matTooltip]="error.label | translate: error.args"
      class="indicator warn"
      icon="indicator-warning"
    ></vks-icon>
    <vks-icon-button
      (click)="openParticipationIntervalsInfo()"
      *ngIf="$any(node?.unit.errors)?.conferenceuserbusy as error"
      [matTooltip]="'CONFERENCE_USER_IS_BUSY' | translate"
    >
      <vks-icon class="indicator warn" color="#F37553" icon="user-busy"></vks-icon>
    </vks-icon-button>
  </div>
  <div *ngIf="allowRemove" class="controls">
    <button
      (buttonClick)="removeUser(node)"
      *ngIf="!node.unit.canNotBeRemovedFromPlannerList"
      class="btn-basic fill-gray"
      icon="close"
      iconClass="square24"
      tabindex="-1"
      vks-button
    ></button>
  </div>
</ng-template>
<ng-template #divisionNode let-node>
  <button (click)="toggleExpansion(node)" *ngIf="allowExpand" class="button-chevron" mat-icon-button tabindex="-1">
    <vks-icon [icon]="treeControl.isExpanded(node) ? 'chevron-down' : 'chevron-right'"></vks-icon>
  </button>
  <mat-checkbox
    (click)="toggleNodeCheck(node, $event)"
    *ngIf="allowSelecting && multiSelect"
    [checked]="descendantsAllSelected(node)"
    [disabled]="!hasDescendants(node)"
    [indeterminate]="descendantsPartiallySelected(node)"
  ></mat-checkbox>
  <span
    (click)="allowExpand ? treeControl.toggle(node) : allowSelecting && multiSelect && toggleNodeCheck(node)"
    (contextmenu)="openContextMenu($event, node)"
    [class.clickable]="selectable"
    class="label"
  >
    {{ node.unit.name }}
    <span class="inline-badge">{{ descendantsCount(node) }}</span>
  </span>
  <div *ngIf="allowRemove" class="controls">
    <button
      (buttonClick)="removeDivision(node)"
      class="btn-basic fill-gray"
      icon="close"
      iconClass="square24"
      tabindex="-1"
      vks-button
    ></button>
  </div>
</ng-template>
<span
  #divisionContextMenuTrigger="matMenuTrigger"
  [matMenuTriggerFor]="divisionContextMenu"
  [style.left.px]="contextMenuX"
  [style.top.px]="contextMenuY"
  style="position: fixed"
></span>
<mat-menu #divisionContextMenu>
  <button (click)="item.action()" *ngFor="let item of contextMenuItems" mat-menu-item tabindex="-1">
    {{ item.label | translate }}
  </button>
</mat-menu>
