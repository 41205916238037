import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { FileService } from '@breez/modules/file/file.service';
import { isTruthy } from '@breez/shared/utilities/is-truthy';

@Component({
  selector: 'vks-control-file-drop-area',
  templateUrl: './control-file-drop-area.component.html',
  styleUrls: ['./control-file-drop-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => {
        return ControlFileDropAreaComponent;
      }),
      multi: true
    }
  ]
})
export class ControlFileDropAreaComponent implements ControlValueAccessor, OnChanges {
  counter = 0;

  @Input() multiple = false;
  @Input() acceptFormats: string[];
  @Input() enableDrop = true;

  @Output() dragOver = new EventEmitter();
  @Output() dragLeave = new EventEmitter();
  @Output() filesChanges = new EventEmitter<File[]>();

  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;

  acceptFormatsPlain: string;

  @HostBinding('class.drag-over')
  isDragOver = false;

  selectedFiles: File[] = [];

  changedTrigger: (value: any) => void;
  touchedTrigger: () => void;

  constructor(private fileService: FileService) {}

  @HostListener('dragover', ['$event'])
  onDragOver(event: any): void {
    if (!this.enableDrop) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: any): void {
    if (!this.enableDrop) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    this.counter--;
    if (this.counter < 1) {
      this.isDragOver = false;
      this.dragLeave.emit();
    }
  }

  @HostListener('dragenter', ['$event'])
  onDragEnter(event: any): void {
    if (!this.enableDrop) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    this.counter++;
    if (this.counter > 0) {
      this.isDragOver = true;
      this.dragOver.emit();
    }
  }

  @HostListener('drop', ['$event'])
  onAreaFileDrop(event: any): void {
    if (!this.enableDrop) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = false;
    this.dragLeave.emit();
    this.counter = 0;

    const files = event.dataTransfer.files ? Array.from(event.dataTransfer.files) : null;
    this.handleSelectedFiles(<File[]>files);
  }

  onInputFilesChange(event: Event): void {
    const files = <HTMLInputElement>event.target ? Array.from((<HTMLInputElement>event.target).files) : null;
    this.handleSelectedFiles(files);
    this.fileInput.nativeElement.value = '';
  }

  handleSelectedFiles(files: File[]): void {
    this.selectedFiles = files.filter(file => {
      const fileExtension = this.fileService.getFileExtension(file.name);
      return (
        this.fileService.allowedExtensions.includes(`.${fileExtension}`) &&
        !this.fileService.preventedExtensions.includes(fileExtension)
      );
    });

    if (!Array.isArray(this.selectedFiles) || this.selectedFiles.length <= 0) {
      this.selectedFiles = [];
    }

    if (typeof this.touchedTrigger === 'function' && typeof this.changedTrigger === 'function') {
      this.touchedTrigger();
      this.changedTrigger(this.selectedFiles);
    }
    this.filesChanges.emit(this.selectedFiles);
  }

  showFileSelectionDialog(attachmentsButton?: MatButton): void {
    if (isTruthy(attachmentsButton)) {
      attachmentsButton._elementRef.nativeElement.blur();
    }
    this.fileInput.nativeElement.click();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.acceptFormats) {
      if (Array.isArray(this.acceptFormats)) {
        this.acceptFormatsPlain = this.acceptFormats.join(',');
      }
    }
  }

  writeValue(files: File[]): void {
    if (!!this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }

    if (!Array.isArray(files)) {
      this.selectedFiles = [];
      return;
    }

    this.selectedFiles = files;
  }

  registerOnTouched(fn: any): void {
    if (fn && typeof fn === 'function') {
      this.touchedTrigger = fn;
    }
  }

  registerOnChange(fn: (value: any) => void): void {
    if (fn && typeof fn === 'function') {
      this.changedTrigger = fn;
    }
  }
}
