import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatTreeModule } from '@angular/material/tree';
import { UserAvatarModule } from '@breez/modules/user/user-avatar/user-avatar.module';
import { UserTreeComponent } from '@breez/modules/user/user-tree/user-tree.component';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ControlsModule } from '@breez/modules/controls/controls.module';
import { TranslateModule } from '@ngx-translate/core';
import { AvatarSmartModule } from '@breez/modules/user/modules/avatar-smart/avatar-smart.module';
import { MatMenuModule } from '@angular/material/menu';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
  declarations: [UserTreeComponent],
  imports: [
    MatTreeModule,
    MatCheckboxModule,
    MatIconModule,
    CommonModule,
    UserAvatarModule,
    MatButtonModule,
    ControlsModule,
    MatTooltipModule,
    TranslateModule,
    AvatarSmartModule,
    MatMenuModule,
    ScrollingModule
  ],
  exports: [UserTreeComponent]
})
export class UserTreeModule {}
