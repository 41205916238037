import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { ContextControl } from '@breez/models/shared/context-control.model';
import { UntilDestroy } from '@ngneat/until-destroy';
import { isTruthy } from '@breez/shared/utilities/is-truthy';

@UntilDestroy()
@Component({
  selector: 'vks-context-controls',
  templateUrl: './context-controls.component.html',
  styleUrls: ['./context-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContextControlsComponent implements OnChanges {
  @Input() iconLayout: 'default' | 'force-under-context' | 'force-inline' = 'default';
  @Input() disabled = false;
  @Input() hideIcon = false;
  @Input() iconsCompressed: boolean = true;
  @Input() actions: ContextControl[];
  // TODO зло!
  @Input() contextMenuIcon = '3-dots';
  @Input() contextMenuIconClass = undefined;
  // TODO зло!
  @Input() asyncContextActions = false;
  // TODO зло!
  @Input() contextMenuIconColor: string;

  @Output() contextMenuOpened = new EventEmitter();
  @Output() contextMenuClosed = new EventEmitter();

  inlineActions: ContextControl[];
  underContextMenuActions: ContextControl[];
  hasIcons = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.actions) {
      this.organizeActions();
    }
    if (changes.iconLayout) {
      this.organizeActions();
    }
  }

  organizeActions(): void {
    if (!this.actions || !Array.isArray(this.actions)) {
      this.inlineActions = [];
      this.underContextMenuActions = [];
      return;
    }

    this.actions = this.actions.sort((actionA, actionB) => {
      if (!isTruthy(actionA.order) && !isTruthy(actionB.order)) {
        return 0;
      }
      if (!isTruthy(actionA.order)) {
        return -1;
      }
      if (!isTruthy(actionB.order)) {
        return 1;
      }
      return actionA.order - actionB.order;
    });

    this.hasIcons = this.actions.some(action => {
      return action.icon;
    });

    switch (this.iconLayout) {
      case 'force-inline':
        this.inlineActions = this.actions.slice();
        this.underContextMenuActions = [];
        break;
      case 'force-under-context':
        this.inlineActions = [];
        this.underContextMenuActions = this.actions.slice();
        break;
      case 'default':
      default:
        this.inlineActions = this.actions.filter(action => {
          return !action.isUnderContextMenu;
        });
        this.underContextMenuActions = this.actions.filter(action => {
          return action.isUnderContextMenu;
        });
        break;
    }
  }

  triggerAction(event: MouseEvent, item: ContextControl): void {
    if (item.disabled || item.keepOpen) {
      event.stopPropagation();
    }

    if (item.disabled) {
      return;
    }

    item.action();
  }

  onContextMenuOpened(): void {
    this.contextMenuOpened.emit();
  }

  onContextMenuClosed(): void {
    this.contextMenuClosed.emit();
  }
}
