import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { PipeTranslationDependency } from '@breez/shared/pipes/base/pipe-translation-dependecy.class';
import { DeclensionService } from '@breez/shared/services/declension.service';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'translatedDeclension',
  pure: false
})
export class TranslatedDeclensionPipe extends PipeTranslationDependency implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID) locale: string,
    private translateService: TranslateService,
    private declensionService: DeclensionService
  ) {
    super(locale, translateService);
  }

  /**
   * @param key {string} - ключ переводимого выражения
   * @param translateParams {Object} - [key] - интерполируемый параметер в ключе перевода, [value] - единица склонения (day, minute...).
   * Если параметр не нуждается в склонении передать [value] равное значению интерполируемого параметра
   * @param declensionValues {number[]} - количество единиц интеполируемого параметра с таким же индексом как и в translateParams.
   * Если параметр не нуждается в склонении передать null
   */
  transform(key: string, translateParams?: Object, declensionValues?: number[]): unknown {
    if (this.savedValue) {
      return this.savedValue;
    }
    this.savedValue = this.getTransformedValue(key, translateParams, declensionValues);
    return this.savedValue;
  }

  getTransformedValue(translateKey: string, params: Object, declensionValues: number[]): string | any {
    let translateParams = {};
    if (isTruthy(params) && isTruthy(declensionValues)) {
      const keys = Object.keys(params);
      if (keys.length !== declensionValues.length) {
        throw new Error('Число параметров для перевода не совпадает с единицами склонения');
      }
      const translateMap: Map<string, string> = new Map<string, string>();
      for (let i = 0; i < keys.length; i++) {
        if (isTruthy(declensionValues[i])) {
          translateMap.set(keys[i], this.declensionService.getInstantDeclension(params[keys[i]], declensionValues[i]));
        }
      }
      translateParams = Array.from(translateMap).reduce((obj, [key, value]) => {
        const declensionValue = declensionValues[keys.indexOf(key)];
        obj[key] = `${declensionValue} ${this.translateService.instant(value).toLowerCase()}`;
        return obj;
      }, {});
    }
    return this.translateService.instant(translateKey, {
      ...params,
      ...translateParams
    });
  }
}
