import { Injectable } from '@angular/core';
import { WebsocketEvents, WebsocketService } from '@breez/modules/websocket';
import { Observable, of, ReplaySubject } from 'rxjs';
import { UserGroup, UserGroupType } from '@breez/modules/social/models/user-group.model';
import { arrToClass, replayWhileSubs, toClass, toPlain } from '@breez/shared/rxjs-operators';
import { catchError, filter, map, mapTo, startWith, switchMap, take } from 'rxjs/operators';
import { Contact } from '../models/contact.model';
import { MenuItem } from '@breez/components/menu/menu.component';
import * as uuid from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class UserGroupService {
  fetchUserGroupTrigger$ = new ReplaySubject<void>(1);

  constructor(private wsService: WebsocketService) {}

  getUserGroups(userId?: number): Observable<UserGroup[]> {
    const typeOrder = [
      UserGroupType.ALLUSERS,
      UserGroupType.COMPANY,
      UserGroupType.MYCONTACTS,
      UserGroupType.MYINVITATIONS,
      UserGroupType.CUSTOM
    ];

    return this.wsService.send(WebsocketEvents.SEND.CONTACTS.USER_GROUP.GET, { data: { userid: userId } }).pipe(
      arrToClass(UserGroup),
      map(groups => {
        return groups.sort((groupA, groupB) => {
          if (!groupA.createdOn || !groupB.createdOn) {
            return 0;
          }

          if (!groupA.createdOn) {
            return 1;
          }

          if (!groupB.createdOn) {
            return -1;
          }

          return groupA.createdOn?.getTime() - groupB.createdOn?.getTime();
        });
      }),
      take(1),
      map(groups => {
        return groups.sort((groupA, groupB) => {
          return typeOrder.indexOf(groupA.type) - typeOrder.indexOf(groupB.type);
        });
      }),
      map(groups => {
        const index = groups.findIndex(group => {
          return group.name === UserGroupType.MYCONTACTS;
        });
        return index < 0 ? groups : groups.move(index, 1);
      })
    );
  }

  userGroups$: Observable<UserGroup[]> = this.fetchUserGroupTrigger$.pipe(
    switchMap(() => {
      return this.getUserGroups();
    }),
    startWith([] as UserGroup[]),
    filter(data => {
      return data?.length >= 0;
    }),
    replayWhileSubs()
  );

  getMenuItemsFromGroups(userGroups: UserGroup[]): MenuItem[] {
    const hasMultipleCompanies =
      userGroups.filter(x => {
        return x.type === UserGroupType.COMPANY;
      }).length > 1;
    return userGroups
      .filter(group => {
        return group.type !== UserGroupType.CUSTOM;
      })
      .map(group => {
        const labelAsIs = [UserGroupType.CUSTOM, UserGroupType.COMPANY].includes(group.type);

        const params = { group: group.type };
        if (group.type === UserGroupType.COMPANY && hasMultipleCompanies && !!group.id) {
          (<any>params).id = group.id;
        }
        return <MenuItem>{
          id: uuid.v4(),
          label: labelAsIs ? group.name : `USER_GROUP.${group.name}`,
          labelAsIs,
          link: ['/', 'social'],
          params
        };
      })
      .concat(<MenuItem>{
        id: uuid.v4(),
        label: 'USER_GROUP.my_groups',
        labelAsIs: false,
        link: ['/', 'social'],
        params: { group: 'custom' }
      })
      .concat(<MenuItem>{
        id: uuid.v4(),
        label: 'ROOMS',
        labelAsIs: false,
        onlyWithRoles: ['planner:planner:access', 'planner:planner:view', 'planner:planner:add'],
        link: ['/', 'social', 'rooms']
      });
  }

  addUserGroup(name: string, contacts: Contact[]): Observable<UserGroup> {
    const userGroup = new UserGroup({ name, contacts });
    return of(userGroup).pipe(
      toPlain(),
      switchMap(userGroupPlain => {
        return this.wsService.send(WebsocketEvents.SEND.CONTACTS.USER_GROUP.ADD, { data: userGroupPlain });
      }),
      take(1),
      toClass(UserGroup)
    );
  }

  editUserGroup(groupId: number, name: string): Observable<boolean> {
    return this.wsService
      .send(WebsocketEvents.SEND.CONTACTS.USER_GROUP.EDIT, {
        data: {
          groupid: groupId,
          name
        }
      })
      .pipe(
        take(1),
        mapTo(true),
        catchError(() => {
          return of(false);
        })
      );
  }

  deleteUserGroup(group: UserGroup): Observable<boolean> {
    const groupId = group.id;
    return this.wsService
      .send(WebsocketEvents.SEND.CONTACTS.USER_GROUP.DELETE, {
        data: { groupid: groupId }
      })
      .pipe(
        take(1),
        mapTo(true),
        catchError(() => {
          return of(false);
        })
      );
  }
}
