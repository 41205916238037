import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { isTruthy } from '@breez/shared/utilities/is-truthy';

@Component({
  selector: 'vks-grid-line',
  templateUrl: './grid-line.component.html',
  styleUrls: ['./grid-line.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridLineComponent implements OnInit, AfterViewInit, OnChanges {
  constructor(private readonly cd: ChangeDetectorRef) {}

  @ViewChild('gridLineText', { static: false }) gridLineText: ElementRef;

  readonly maxTextHeight = 60;

  @Input()
  label: string;

  @Input()
  text: unknown = '';

  @Input()
  contentTemplate: TemplateRef<any>;

  @Input()
  templateContext: { [key: string]: any };

  @Input()
  labelTemplate: TemplateRef<any>;

  @Input()
  labelWidth = 150;

  @Input()
  isMobile: boolean;

  @Input()
  isTablet = false;

  @Input()
  addMargin = true;

  formattedLabelWidth: string;

  expandable = false;
  isExpanded = false;

  ngOnInit(): void {
    this.formattedLabelWidth = this.labelWidth + 'px';
  }

  toggleExpand(): void {
    this.isExpanded = !this.isExpanded;
  }

  ngAfterViewInit(): void {
    this.checkIfExpandable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.text && changes.text.currentValue) {
      if (isTruthy(this.gridLineText)) {
        this.checkIfExpandable();
      }
    }
  }

  checkIfExpandable(): void {
    const realTextHeight = this.gridLineText.nativeElement.scrollHeight;
    this.expandable = realTextHeight >= this.maxTextHeight;
    this.cd.detectChanges();
  }
}
