import { Inject, Injectable } from '@angular/core';

import { Size } from 'electron';
import { from, merge, Observable, ReplaySubject, switchMap } from 'rxjs';
import { ELECTRON_CHANNEL_LIST } from '../../../../../../electron-channel-list';
import { debounceTime, distinctUntilChanged, map, startWith, take } from 'rxjs/operators';
import { replayWhileSubs } from '@breez/shared/rxjs-operators';
import { environment } from '@breez/environment';
import { WINDOW } from '@breez/helpers/window.provider';
import { ElectronApi } from '../../../../../../static/preload';
import { LoggerService } from '@breez/shared/services/logger.service';
import { CHAT_ID } from '@breez/modules/chat';

interface WindowExt extends Window {
  electronApi: ElectronApi;
}

@Injectable({
  providedIn: 'root'
})
export class ElectronService {
  electronApi: ElectronApi;
  screenSize$: Observable<Size>;
  electronSecondaryWindow$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  electronBlockInterface$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  isBlockInterface$: Observable<boolean> = merge(
    this.electronBlockInterface$,
    this.electronBlockInterface$.pipe(
      debounceTime(5000),
      map(() => {
        return false;
      })
    )
  ).pipe(startWith(false), distinctUntilChanged(), replayWhileSubs());

  isSecondaryWindow$: Observable<boolean> = this.electronSecondaryWindow$.pipe(
    distinctUntilChanged(),
    replayWhileSubs()
  );

  electronUidWindow$: ReplaySubject<string> = new ReplaySubject<string>(1);
  uid$: Observable<string> = this.electronUidWindow$.pipe(distinctUntilChanged(), replayWhileSubs());
  private _isMac: any;
  private _isWindows: any;
  private _isLinux: any;

  constructor(
    @Inject(WINDOW) private readonly window: WindowExt,
    private loggerService: LoggerService
  ) {
    if (this.isElectron) {
      this.electronApi = this.window.electronApi;

      this.screenSize$ = from(this.electronApi.invoke(ELECTRON_CHANNEL_LIST.GET_PRIMARY_DISPLAY)).pipe(
        map(primaryDisplay => {
          return primaryDisplay.workAreaSize;
        })
      );

      from(this.electronApi.invoke(ELECTRON_CHANNEL_LIST.OS_INFO))
        .pipe(take(1))
        .subscribe(({ isMac, isWindows, isLinux }) => {
          this._isMac = isMac;
          this._isWindows = isWindows;
          this._isLinux = isLinux;
        });

      this.electronApi.on(ELECTRON_CHANNEL_LIST.OS_INFO, (_, [{ isMac, isWindows, isLinux }]) => {
        this._isMac = isMac;
        this._isWindows = isWindows;
        this._isLinux = isLinux;
      });

      this.electronApi.on(ELECTRON_CHANNEL_LIST.ELECTRON_SECONDARY_WINDOW, (_, [secondary]) => {
        this.electronSecondaryWindow$.next(secondary);
      });

      this.electronApi.on(ELECTRON_CHANNEL_LIST.BLOCK_INTERFACE, (_, [block]) => {
        this.electronBlockInterface$.next(block);
      });

      this.electronApi.on(ELECTRON_CHANNEL_LIST.LOG, (_, [data]) => {
        this.loggerService.log('Electron', data);
      });

      this.electronApi.on(ELECTRON_CHANNEL_LIST.WINDOW_UID, (_, [uid]) => {
        this.electronUidWindow$.next(uid);
      });
    }
  }

  get isWindows(): boolean {
    return this._isWindows;
  }

  get isMac(): boolean {
    return this._isMac;
  }

  get isLinux(): boolean {
    return this._isLinux;
  }

  get isElectron(): boolean {
    // Renderer process
    if (typeof window !== 'undefined' && typeof window.process === 'object' && window.process.type === 'renderer') {
      return true;
    }

    // Detect the user agent when the `nodeIntegration` option is set to false
    return (
      typeof navigator === 'object' &&
      typeof navigator.userAgent === 'string' &&
      navigator.userAgent.indexOf('Electron') >= 0
    );
  }

  windowSetPosition(left: number, top: number, animate?: boolean): void {
    this.uid$.pipe(take(1)).subscribe(uid => {
      this.electronApi.send(ELECTRON_CHANNEL_LIST.WINDOW_SET_POSITION, { uid, left, top, animate });
    });
  }

  sendSpecialUrl(): void {
    this.electronApi.send(ELECTRON_CHANNEL_LIST.SET_API, environment.api);
    this.electronApi.send(ELECTRON_CHANNEL_LIST.SET_ADMIN, environment.adminLink);
  }

  windowSetSize(width: number, height: number, animate?: boolean): void {
    this.uid$.pipe(take(1)).subscribe(uid => {
      this.electronApi.send(ELECTRON_CHANNEL_LIST.WINDOW_SET_SIZE, { uid, width, height, animate });
    });
  }

  windowMaximize(): void {
    this.uid$.pipe(take(1)).subscribe(uid => {
      this.electronApi.send(ELECTRON_CHANNEL_LIST.WINDOW_MAXIMIZE, { uid });
    });
  }

  windowShow(isMain = false): void {
    this.uid$.pipe(take(1)).subscribe(uid => {
      this.electronApi.send(ELECTRON_CHANNEL_LIST.WINDOW_SHOW, isMain ? { uid: null } : { uid });
    });
  }

  openUrl(url: string, isMain = false, external = false): void {
    this.uid$.pipe(take(1)).subscribe(uid => {
      this.electronApi.send(
        ELECTRON_CHANNEL_LIST.WINDOW_OPEN_URL,
        isMain ? { uid: null, url, external } : { uid, url, external }
      );
    });
  }

  openChat(chatId: CHAT_ID, isMain = true): void {
    this.uid$.pipe(take(1)).subscribe(uid => {
      this.electronApi.send(
        ELECTRON_CHANNEL_LIST.WINDOW_OPEN_CHAT_BY_ID,
        isMain ? { uid: null, chatId } : { uid, chatId }
      );
    });
  }

  windowGetSize$(): Observable<number[]> {
    return this.uid$.pipe(
      take(1),
      switchMap(uid => {
        return from(this.electronApi.invoke(ELECTRON_CHANNEL_LIST.WINDOW_GET_SIZE, { uid }));
      })
    );
  }

  windowGetPosition$(): Observable<Size> {
    return this.uid$.pipe(
      take(1),
      switchMap(uid => {
        return from(this.electronApi.invoke(ELECTRON_CHANNEL_LIST.WINDOW_GET_POSITION, { uid }));
      })
    );
  }

  setBaseUrl(environmentOrigin: string): void {
    this.electronApi.send(ELECTRON_CHANNEL_LIST.SET_BASE, environmentOrigin);
  }
}
