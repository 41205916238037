import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { CropperPosition, ImageCroppedEvent } from 'ngx-image-cropper';
import { ProcessingFile } from '@breez/models/shared/files/processing-file.model';
import { FileModification } from '@breez/models/shared/files/modification/file-modifications.enum';
import { CropFileModification } from '@breez/models/shared/files/modification/crop-file-modification.model';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { ImageSize } from '@breez/models/shared/image-size.model';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { replayWhileSubs } from '@breez/shared/rxjs-operators';

@Component({
  selector: 'vks-control-image-cropper',
  templateUrl: './control-image-cropper.component.html',
  styleUrls: ['./control-image-cropper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => {
        return ControlImageCropperComponent;
      }),
      multi: true
    }
  ]
})
export class ControlImageCropperComponent implements ControlValueAccessor, OnChanges {
  cropperLoaded = false;

  changedTrigger: (value: any) => void;
  touchedTrigger: () => void;

  @Input() imageFile: ProcessingFile;
  @Input() ratio: number;
  @Input() resizeTo: ImageSize;
  @Input() cropperPosition: CropperPosition = { x1: 0, x2: 0, y1: 0, y2: 0 };

  @Output() modifiedFile = new EventEmitter<ProcessingFile>();

  aspectRatio = 1;
  maintainAspectRatio = false;

  resizeToWidth = null;
  resizeToHeight = null;

  selectedLang$: Observable<string> = this.translateService.onLangChange.pipe(
    map((params: LangChangeEvent) => {
      return params.lang;
    }),
    startWith(this.translateService.currentLang),
    replayWhileSubs()
  );

  constructor(private translateService: TranslateService) {}

  handleCropped(imageCroppedEvent: ImageCroppedEvent): void {
    const modifiedFile = { ...this.imageFile };
    modifiedFile.modifiedBlob = imageCroppedEvent.base64;

    if (!Array.isArray(modifiedFile.modifications)) {
      modifiedFile.modifications = [];
    }

    modifiedFile.modifications = modifiedFile.modifications.filter(modification => {
      return modification.type !== FileModification.CROP;
    });

    modifiedFile.modifications = [
      ...modifiedFile.modifications,
      <CropFileModification>{
        type: FileModification.CROP,
        cropperPosition: imageCroppedEvent.cropperPosition
      }
    ];

    this.modifiedFile.emit(modifiedFile);
    this.touchedTrigger();
    this.changedTrigger(modifiedFile);
  }

  writeValue(file: ProcessingFile): void {
    this.imageFile = file;
  }

  registerOnTouched(fn: any): void {
    if (fn && typeof fn === 'function') {
      this.touchedTrigger = fn;
    }
  }

  registerOnChange(fn: (value: any) => void): void {
    if (fn && typeof fn === 'function') {
      this.changedTrigger = fn;
    }
  }

  setCropperPosition(): void {
    if (Array.isArray(this.imageFile.modifications)) {
      const cropModification = this.imageFile.modifications.find(modification => {
        return modification.type === FileModification.CROP;
      });
      if (cropModification) {
        this.cropperPosition = Object.assign({}, (<CropFileModification>cropModification).cropperPosition);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ratio) {
      if (!isTruthy(this.ratio)) {
        this.maintainAspectRatio = false;
        return;
      }
      this.aspectRatio = this.ratio;
      this.maintainAspectRatio = true;
    }

    if (changes.resizeTo) {
      if (!isTruthy(this.resizeTo)) {
        this.resizeToWidth = null;
        this.resizeToHeight = null;
      }
      this.resizeToWidth = this.resizeTo.width;
      this.resizeToHeight = this.resizeTo.height;
    }
  }

  onCropperReady(): void {
    this.setCropperPosition();
    this.cropperLoaded = true;
  }
}
