import { Expose, Type } from 'class-transformer';
import { Chat } from './chat';
import { User } from '@breez/models';

export class ChatList {
  @Expose()
  @Type(() => {
    return Chat;
  })
  chats: Chat[];

  @Expose()
  @Type(() => {
    return User;
  })
  users: User[];
}
