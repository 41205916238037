import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { StateService } from '@breez/shared/services/state.service';
import { AuthService } from '@breez/modules/auth/services/auth.service';
import { LocalStorage } from '@breez/shared/modules/storage/interfaces/local-storage.interface';

@Component({
  selector: 'vks-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomePageComponent implements OnInit {
  constructor(
    private stateService: StateService,
    private authService: AuthService,
    private localStorage: LocalStorage
  ) {}

  ngOnInit(): void {
    this.stateService.setHeader(null);
    this.stateService.setExpandedGUI(true);
  }

  logout(): void {
    this.authService.logout();
  }

  setOperatorRights(): void {
    this.localStorage.setItem('i-am-admin', 'true');
  }
}
