import { MessageListDateStub } from './message-list-date-stub.model';
import { Message } from './message.model';
import { MessageListEntryEnum } from '../enums';

export class MessageListEntry {
  type: MessageListEntryEnum;

  isLast?: boolean;

  isGroupFirst?: boolean;

  isGroupLast?: boolean;

  isOut?: boolean; // TODO Rename this

  isRead?: boolean;

  isDelivered?: boolean;

  message?: Message;

  dateStub?: MessageListDateStub;

  constructor(base?: Partial<MessageListEntry>) {
    return Object.assign(this, base);
  }
}
