<vks-modal-header [title]="'LANGUAGE' | translate" (close)="close()"></vks-modal-header>
<p>{{ 'CHOOSE_LANGUAGE' | translate }}</p>
<div class="language-list">
  <ng-container *ngFor="let language of languages$ | async">
    <div class="language">
      <button
        vks-button
        [icon]="language.icon"
        [label]="language.name"
        (buttonClick)="resolveDialog(language)"
        class="btn-anchor"
      ></button>
    </div>
  </ng-container>
</div>
