import { Action, createReducer, on } from '@ngrx/store';
import * as AccountState from './account.state';
import * as AccountActions from './account.actions';

const reducer = createReducer(
  AccountState.initialState,
  on(AccountActions.loadAccountSuccess, (state, { account }) => {
    return { ...state, account };
  })
);

export function accountReducer(state: AccountState.State | undefined, action: Action): AccountState.State {
  return reducer(state, action);
}
