import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as NotificationActions from './notification.actions';
import { NotificationService } from '@breez/modules/notification';

@Injectable()
export class NotificationEffects {
  @Effect({ dispatch: false })
  add$: Observable<Action> = this.actions$.pipe(
    ofType(NotificationActions.ActionTypes.Add),
    tap((action: NotificationActions.Add) => {
      return this.notificationService.notify(action.payload);
    })
  );

  constructor(
    private actions$: Actions,
    private notificationService: NotificationService
  ) {}
}
