import { Injectable } from '@angular/core';
import { SettingsItemModel, SettingsSectionModel } from '@breez/models/settings';
import { SettingsSectionsKeys } from '@breez/modules/settings/enums/settings-section-keys';
import { WebsocketEvents, WebsocketService } from '@breez/modules/websocket';
import { Observable, of } from 'rxjs';
import { catchError, mapTo, take } from 'rxjs/operators';
import { SettingsDialogService } from '@breez/modules/settings/services/settings.dialog.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  openUserSettingsPopup(): void {
    this.settingsDialogService
      .displayUserSettings()
      .pipe(take(1))
      .subscribe(() => {});
  }

  constructor(
    private wsService: WebsocketService,
    private settingsDialogService: SettingsDialogService
  ) {}

  getValues(): Observable<SettingsItemModel[]> {
    return this.wsService.send<SettingsItemModel[]>(WebsocketEvents.RECEIVE.SETTINGS.VALUE.GET).pipe(take(1));
  }

  getValue(section: string, key: string): Observable<SettingsItemModel> {
    return this.wsService
      .send<SettingsItemModel>(WebsocketEvents.RECEIVE.SETTINGS.KEY.GET, { data: { section, key } })
      .pipe(take(1));
  }

  getSections(): Observable<SettingsSectionModel[]> {
    return this.wsService.send<SettingsSectionModel[]>(WebsocketEvents.RECEIVE.SETTINGS.SECTION.GET).pipe(take(1));
  }

  setValue(key: string, sectionId: number, value: string): Observable<boolean> {
    return this.wsService.send(WebsocketEvents.SEND.SETTINGS.VALUE.SET, { data: { key, sectionId, value } }).pipe(
      take(1),
      catchError(() => {
        return of(false);
      }),
      mapTo(true)
    );
  }

  getValuesBySectionName(sectionName: SettingsSectionsKeys): Observable<SettingsItemModel[]> {
    return this.wsService
      .query(WebsocketEvents.RECEIVE.SETTINGS.VALUE.GET_BY_SECTION, {
        data: {
          section: sectionName
        }
      })
      .pipe(take(1));
  }
}
