import { ConferenceParticipantConnection } from '@breez/models/conference/conference-participant-connection.model';
import { EntityReferenceCarrier } from '@breez/models/conference/entity-reference-carrier.model';
import { ConferenceParticipantStatus } from '@breez/models/conference/enums/conference-participant-status.enum';
import { ViewParticipantRightModel } from '@breez/models/conference/view-participant-right.model';
import { EntityReference } from '@breez/models/shared/entity-reference.model';
import { Participant } from '@breez/models/shared/participant/participant.model';
import { ConferenceUserRole } from '@breez/models/conference-user-role.enum';
import { createEnumChecker, getEnumByValue } from '@breez/shared/utilities/enum';
import { Exclude, Expose, Transform, Type } from 'class-transformer';

function lostTransformer({ value }): string | undefined {
  if (typeof value === 'number') {
    return Number.isInteger(value) ? value.toString() : value.toFixed(2);
  } else if (value === null) {
    return '-';
  } else {
    return undefined;
  }
}

export class ConferenceParticipant implements EntityReferenceCarrier {
  @Expose({ name: 'participant' })
  @Type(() => {
    return EntityReference;
  })
  participantReference: EntityReference;

  @Expose({ name: 'object' })
  @Type(() => {
    return EntityReference;
  })
  object: EntityReference;

  @Expose({ name: 'connection' })
  @Type(() => {
    return ConferenceParticipantConnection;
  })
  connectionInfo: ConferenceParticipantConnection;

  @Exclude({ toPlainOnly: true })
  participant: Participant;

  @Expose({ name: 'id' })
  id: string;

  get isWebClient(): boolean {
    return !Number(this.id);
  }

  @Expose({ name: 'status' })
  status: ConferenceParticipantStatus;

  @Expose({ name: 'conferenceid' })
  conferenceId: number;

  @Expose({ name: 'pinnedchannel' })
  pinnedChannel: number;

  @Expose({ name: 'titleenabled' })
  titleEnabled: boolean;

  @Expose({ name: 'audioenabled' })
  audioEnabled: boolean;

  @Expose({ name: 'fullscreenenabled' })
  fullScreenEnabled: boolean;

  @Expose({ name: 'videoenabled' })
  videoEnabled: boolean;

  @Expose({ name: 'fitenabled' })
  fitEnabled: boolean;

  @Expose({ name: 'isready' })
  terminalRemoteReady: boolean;

  @Expose()
  position: string;

  @Expose({ name: 'invitedon' })
  @Transform(({ value }) => {
    return value !== undefined ? new Date(value) : undefined;
  })
  invitedOn: string;

  @Expose()
  channel?: number;

  @Expose({ name: 'rights' })
  rights: ViewParticipantRightModel[];

  @Expose({ name: 'handraised' })
  @Transform(({ value }) => {
    return value !== undefined ? (!!value ? new Date() : null) : undefined;
  })
  requestForSpeech: Date;

  @Expose({ name: 'handraisedmessage' })
  requestForSpeechMessage: string;

  @Expose()
  @Transform(({ value }) => {
    if (!value) {
      return undefined;
    }

    const isConferenceUserRole = createEnumChecker(ConferenceUserRole);
    return value
      .filter(item => {
        return isConferenceUserRole(item);
      })
      .map(item => {
        return getEnumByValue(ConferenceUserRole, item);
      });
  })
  @Expose({ name: 'lostsfromserver' })
  @Transform(lostTransformer)
  lostsFromServer: number;

  @Expose({ name: 'lostsfromclient' })
  @Transform(lostTransformer)
  lostsFromClient: number;

  @Expose({ name: 'lostsaudio' })
  @Transform(lostTransformer)
  audioLosses: number;

  @Expose({ name: 'lostsvideo' })
  @Transform(lostTransformer)
  videoLosses: number;

  isBlockable?: boolean;

  isSpeaker(): boolean {
    return this.channel > 0;
  }

  constructor(participant: Partial<ConferenceParticipant>) {
    Object.assign(this, participant);
  }

  compareCarrier(anotherCarrier: EntityReferenceCarrier): boolean {
    return (
      this.participantReference.compare(anotherCarrier.participantReference) &&
      this.object.compare(anotherCarrier.object)
    );
  }
}
