import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { INotificationModel } from '@breez/models/notification';
import { OverlayContainer } from '@breez/modules/overlay/models';
import { NotificationTypesComponentResolver } from '../notification-types-component.resolver';

@Component({
  selector: 'vks-notification-dynamic',
  template: '<ng-container *ngxComponentOutlet="resolver | resolve:notification.type"></ng-container>',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationTypeDynamicComponent {
  @Input() notification: INotificationModel;
  @Output() changeOverlayContainer = new EventEmitter<OverlayContainer>();

  constructor(public resolver: NotificationTypesComponentResolver) {}
}
