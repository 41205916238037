import { Injectable } from '@angular/core';
import { Header } from '@breez/shared/services/state.service';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  private header = null;

  constructor(private readonly translateService: TranslateService) {}

  setHeader(header: Header | string): void {
    this.header = header;
  }

  getHeader$(): Observable<Header> {
    const contentToTranslate = this.getCompleteHeader();
    const headerValues = Object.values(contentToTranslate);

    return this.translateService.stream(Object.values(headerValues)).pipe(
      map(({ ...translated }) => {
        return {
          title: translated[headerValues[0]],
          subtitle: translated[headerValues[1]] || null
        };
      })
    );
  }

  private getCompleteHeader(): Header {
    if (!isTruthy(this.getHeaderSubtitle())) {
      return {
        title: this.getHeaderTitle()
      };
    }
    return {
      title: this.getHeaderTitle(),
      subtitle: this.getHeaderSubtitle()
    };
  }

  private getHeaderTitle(): string {
    if (!isTruthy(this.header)) {
      return '';
    }
    return typeof this.header === 'string' ? this.header : this.header.title;
  }

  private getHeaderSubtitle(): string {
    if (!isTruthy(this.header)) {
      return '';
    }
    return typeof this.header === 'string' ? null : this.header.subtitle;
  }
}
