import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ValidationError } from '@breez/shared/validators/validation-error.model';

const ERROR = {
  type: 'confirm-password',
  label: 'CONFIRM_PASSWORD'
} as ValidationError;

export function removeError(control: AbstractControl, error: string): void {
  const err = control?.errors;
  if (err) {
    delete err[error];
    if (!Object.keys(err).length) {
      control.setErrors(null);
    } else {
      control.setErrors(err);
    }
  }
}

export function controlsValueEqualValidator(controlName, _controlName): ValidatorFn {
  return (group: AbstractControl): ValidationErrors | null => {
    if (!controlName || !_controlName) {
      return null;
    }

    const control = group.get(controlName);
    const confirmControl = group.get(_controlName);
    let pass = control?.value;
    let confirmPass = confirmControl?.value;

    if (!pass || !confirmPass || pass === confirmPass) {
      removeError(control, ERROR.type);
      removeError(confirmControl, ERROR.type);
      return null;
    }

    const errors = { [ERROR.type]: ERROR } as ValidationErrors;
    group.get(controlName).setErrors(errors);
    group.get(_controlName).setErrors(errors);

    return errors;
  };
}

export const confirmPasswordErrorType = ERROR;
