<mat-toolbar class="vks-header">
  <div *ngIf="hideSideBar$ | async" class="menu-control">
    <button
      vks-button
      [iconClass]="menuBurgerIconClass"
      [icon]="menuBurgerIcon$ | async"
      (buttonClick)="openMenu()"
      class="btn-basic fill-gray"
    ></button>
  </div>
  <div class="naming no-user-select">
    <span [innerHTML]="title$ | async | safe: 'html'" class="title"></span>
    <span *ngIf="subtitle$ | async; let subtitle" [innerHTML]="subtitle | safe: 'html'" class="subtitle"></span>
  </div>
  <span class="spacer"></span>
  <div *ngIf="currentUser$ | async" class="controls">
    <ng-container *ngIf="showPlannerButtons$ | async">
      <vks-invite-guest-button [bolder]="true"></vks-invite-guest-button>
    </ng-container>
    <ng-container *ngxPermissionsOnly="'conferences:create'">
      <button
        *ngIf="showPlannerButtons$ | async"
        vks-button
        [class.hide-text]="isMobileDevice$ | async"
        [label]="'START_CONFERENCE' | translate"
        (buttonClick)="createConference()"
        class="add-conference btn-anchor btn-unfocused"
        icon="start-conference"
        iconClass="square24"
      ></button>
    </ng-container>
    <ng-container *ngxPermissionsOnly="'planner:planner:access'">
      <ng-container *ngxPermissionsOnly="'planner:planner:add'">
        <button
          *ngIf="isMobile && showConferencePlannerLink$ | async"
          vks-button
          [class.hide-text]="isMobileDevice$ | async"
          [label]="'SCHEDULE_CONFERENCE' | translate"
          [routerLink]="['conference', 'add']"
          class="add-conference btn-anchor btn-unfocused"
          icon="plan-conference"
          iconClass="square18"
        ></button>
        <button
          *ngIf="!isMobile && showConferencePlannerLink$ | async"
          vks-button
          [class.hide-text]="isMobileDevice$ | async"
          [label]="'SCHEDULE_CONFERENCE' | translate"
          [matMenuTriggerFor]="templatesMenu"
          [queryParams]="currentRouteQueryParams$ | async"
          class="add-conference btn-anchor btn-unfocused"
          icon="plan-conference"
          iconClass="square18"
        ></button>
        <button
          *ngIf="showPlannerButtonForSingleTemplate$ | async"
          vks-button
          [class.hide-text]="isMobileDevice$ | async"
          [label]="'SCHEDULE_CONFERENCE' | translate"
          [queryParams]="{ templateId: singleTemplateId$ | async }"
          [routerLink]="['conference', 'add']"
          class="add-conference btn-anchor btn-unfocused"
          icon="plan-conference"
          iconClass="square18"
        ></button>
      </ng-container>
    </ng-container>
  </div>
  <div *ngIf="currentUser$ | async as user; else loginButton" [matMenuTriggerFor]="userMenu" class="user">
    <vks-avatar-smart [entity]="user" size="36"></vks-avatar-smart>
    <div class="user-name-position no-user-select">
      <div class="user-name">{{ user?.name }}</div>
    </div>
    <mat-menu #userMenu="matMenu" xPosition="before">
      <button mat-menu-item translate (click)="userSettings()">SETTINGS</button>
      <mat-divider></mat-divider>
      <button (click)="logout()" mat-menu-item translate>LOGOUT</button>
    </mat-menu>
  </div>
  <ng-template #loginButton>
    <button
      *ngIf="isLoginPage$ | async | not"
      vks-button
      [label]="'ENTER' | translate | uppercase"
      (buttonClick)="showLoginPopup()"
      class="btn-primary"
    ></button>
  </ng-template>
</mat-toolbar>
<mat-menu #templatesMenu="matMenu">
  <button
    *ngFor="let template of templateInfos$ | async"
    [queryParams]="{ templateId: template.conferencetemplateid }"
    [routerLink]="['/', 'conference', 'add']"
    mat-menu-item
  >
    <vks-icon [icon]="template.icon" class="square24" color="#005259"></vks-icon>
    <span>{{ template?.title ? template?.title[(selectedLang$ | async)] : ('UNSPECIFIED' | translate) }}</span>
  </button>
  <mat-divider></mat-divider>
  <button *ngIf="canUseAdvancedMode$ | async" [routerLink]="['/', 'conference', 'add', 'advanced']" mat-menu-item>
    <vks-icon
      [icon]="advancedModeTemplate.icon"
      [style.margin-left]="'5px'"
      [style.margin-right]="'17px'"
      class="square14"
      color="#005259"
    ></vks-icon>
    <span>{{ advancedModeTemplate.title[selectedLang$ | async] }}</span>
  </button>
</mat-menu>
