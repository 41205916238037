import { provideCallStatusComponent } from '@breez/modules/call/call-statuses/call-status-component.provider';
import { CallStatusRingOutgoingComponent } from './components';
import { CallDestinationType, CallStatus } from '@breez/modules/call/models';

export const OVERLAY_PROVIDERS = [
  provideCallStatusComponent(
    {
      status: CallStatus.RING,
      destinationType: CallDestinationType.OUTGOING
    },
    CallStatusRingOutgoingComponent
  )
];
