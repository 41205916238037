<ng-container *ngIf="!callModel.isConferenceCall; else conference">
  <h3>{{ callModel?.caller?.name }}</h3>
  <vks-user-avatar [entity]="callModel.caller" size="64"></vks-user-avatar>
  <div class="info">{{ 'CALL.CALLS_YOU' | translate }}</div>
  <div class="actions">
    <vks-icon
      *ngIf="!anotherActiveCallId"
      [hasHover]="true"
      [matTooltip]="'CALL.ACCEPT_WITH_VIDEO' | translate"
      (click)="
        onChangeCall(callStatus.ACCEPT, { kind: mediaSourceKind.VIDEO_INPUT, sourceType: deviceSourceType.HARDWARE })
      "
      class="square48"
      color="white"
      hoverColor="white"
      icon="call-accept-video"
    ></vks-icon>
    <vks-icon
      *ngIf="!!anotherActiveCallId"
      [hasHover]="true"
      [matTooltip]="'CALL.ACCEPT_WITH_AUDIO' | translate"
      (click)="
        onChangeCall(callStatus.ACCEPT, {
          kind: mediaSourceKind.AUDIO_INPUT,
          call: { id: anotherActiveCallId, status: callStatus.HOLD }
        })
      "
      class="square48"
      hoverColor="white"
      icon="call-hold-accept"
    ></vks-icon>
    <vks-icon
      [hasHover]="true"
      [matTooltip]="'CALL.DECLINE' | translate"
      (click)="onChangeCall(callStatus.DECLINE)"
      class="square48"
      color="white"
      hoverColor="white"
      icon="call-cancel"
    ></vks-icon>
    <vks-icon
      *ngIf="!anotherActiveCallId"
      [hasHover]="true"
      [matTooltip]="'CALL.ACCEPT_WITH_AUDIO' | translate"
      (click)="onChangeCall(callStatus.ACCEPT, { kind: mediaSourceKind.AUDIO_INPUT })"
      class="square48"
      color="white"
      hoverColor="white"
      icon="call-accept-audio"
    ></vks-icon>
    <vks-icon
      *ngIf="!!anotherActiveCallId"
      [hasHover]="true"
      [matTooltip]="'CALL.ACCEPT_WITH_AUDIO' | translate"
      (click)="
        onChangeCall(callStatus.ACCEPT, {
          kind: mediaSourceKind.AUDIO_INPUT,
          call: { id: anotherActiveCallId, status: callStatus.HANGUP }
        })
      "
      class="square48"
      color="white"
      hoverColor="white"
      icon="call-end-accept"
    ></vks-icon>
  </div>
</ng-container>

<ng-template #conference>
  <h3>{{ callModel?.caller?.name }}</h3>
  <vks-user-avatar *ngIf="!!callModel?.caller" [entity]="callModel?.caller" size="64"></vks-user-avatar>
  <div class="info" *ngIf="!!callModel?.caller">{{ 'CALL.CALLS_YOU_CONFERENCE' | translate }}</div>
  <div class="info" *ngIf="!callModel?.caller">{{ 'CALL.CALLS_YOU_CONFERENCE_NOBODY' | translate }}</div>
  <div class="actions">
    <vks-icon
      [hasHover]="true"
      [matTooltip]="'CALL.DECLINE' | translate"
      (click)="onChangeCall(callStatus.DECLINE)"
      class="square48"
      color="white"
      hoverColor="white"
      icon="call-cancel"
    ></vks-icon>
    <vks-icon
      [hasHover]="true"
      [matTooltip]="'ENTER' | translate"
      (click)="onChangeCall(callStatus.HANGUP, undefined, true)"
      class="square48"
      color="white"
      hoverColor="white"
      icon="call-accept-conference"
    ></vks-icon>
  </div>
</ng-template>
