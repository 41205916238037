import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EventTypeEnum, User } from '@breez/models';
import { AppConfigModel } from '@breez/models/app-config.model';
import { Conference } from '@breez/models/conference/conference.model';
import { ConferenceState } from '@breez/models/conference/enums/conference-state.enum';
import { NotificationType } from '@breez/models/notification/notification-type.enum';
import { Participant } from '@breez/models/shared/participant/participant.model';
import { MediaSourceKind } from '@breez/models/webrtc/media-source-kind.enum';
import { AuthService } from '@breez/modules/auth/services/auth.service';
import { CallsService } from '@breez/modules/call/services/calls.service';
import { ConferencePlannerService } from '@breez/modules/conference/modules/planner/services/conference-planner/conference-planner.service';
import { NotificationService } from '@breez/modules/notification';
import { UserStatusService } from '@breez/modules/user/modules/avatar-smart/services/user-status.service';
import { USER_STATUS_SOURCE } from '@breez/modules/user/modules/avatar-smart/user-status-source.provider';
import { MediaDevicesService } from '@breez/modules/webrtc/services/media-devices.service';
import { ScrollControllerComponent } from '@breez/shared/components/scroll-controller';
import { clone, replayWhileSubs } from '@breez/shared/rxjs-operators';
import { ConferencesService } from '@breez/shared/services/conferences.service';
import { StateService } from '@breez/shared/services/state.service';
import { EmitOnChange } from '@breez/shared/utilities/decorators/emit-on-change.decorator';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { ElectronService } from '@breez/modules/core/services';
import moment from 'moment';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  forkJoin,
  merge,
  mergeMap,
  Observable,
  of,
  race,
  ReplaySubject,
  Subject,
  Subscription,
  timer
} from 'rxjs';
import {
  auditTime,
  bufferTime,
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  mapTo,
  pairwise,
  sample,
  scan,
  share,
  skip,
  startWith,
  switchMap,
  take,
  takeUntil,
  takeWhile,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import * as breezValidators from '@breez/shared/validators';
import { SelectionModel } from '@angular/cdk/collections';
import {
  InviteParticipantsModeEnum,
  InviteParticipantsStatesEnum
} from '@breez/shared/models/invite-participants.model';
import { ConferenceChatTitleEnum, EventsObserverEnum } from '../../enums';
import { CHAT_CONTEXT_CARRIER_TOKEN } from '../../consts';
import { BlockChatRequest, ChatContextCarrier, MessageToForwardModel } from '../../interfaces';
import { Chat, ChatParticipant, Message, MessageEvent } from '../../models';
import { ChatBlockFormComponent } from '../chat-block-form';
import { ChatMessageEditComponent, ChatWarningFormComponent } from '@breez/modules/chat';
import { ChatService, MessageService, UserStatusChatContextSourceService } from '../../services';
import { CHAT_ID } from '../../types';
import { Store } from '@ngrx/store';
import * as ModuleState from '@breez/modules/chat/+state/module.state';
import * as ChatActions from '@breez/modules/chat/+state/chat/chat.actions';
import * as ChatSelectors from '@breez/modules/chat/+state/chat/chat.selectors';
import * as ChatUnreadMessageSelectors from '@breez/modules/chat/+state/chatUnreadMessage/chatUnreadMessage.selectors';
import * as ParticipantsSelectors from '@breez/modules/chat/+state/participants/participants.selectors';
import * as ExecutionSelectors from '@breez/+state/execution/execution.selectors';
import * as MessageSelectors from '@breez/modules/chat/+state/message/message.selectors';
import * as RangeSelectors from '@breez/modules/chat/+state/viewRange/viewRange.selectors';
import * as RangeActions from '@breez/modules/chat/+state/viewRange/viewRange.actions';
import * as MessageActions from '@breez/modules/chat/+state/message/message.actions';
import * as ChatUnreadMessageActions from '@breez/modules/chat/+state/chatUnreadMessage/chatUnreadMessage.actions';
import { ChatEntity } from '@breez/modules/chat/models/+state/chatEntity';
import { PARTICIPANT_ID } from '@breez/modules/chat/types/participant-id.type';
import { getAvatarEntity } from '@breez/modules/chat/helpers';
import { guid } from '@breez/helpers/guid';
import { MessageRange } from '@breez/modules/chat/models/+state/messageRange';
import { CallModel, CallStatus } from '@breez/modules/call/models';
import { plainToInstance } from 'class-transformer';

@UntilDestroy()
@Component({
  selector: 'vks-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: CHAT_CONTEXT_CARRIER_TOKEN,
      useExisting: forwardRef(() => {
        return ChatComponent;
      })
    },
    {
      provide: USER_STATUS_SOURCE,
      useClass: UserStatusChatContextSourceService
    }
  ],

  host: { '[class.user-tree]': '!!showUserTree$.value' }
})
export class ChatComponent implements OnInit, ChatContextCarrier, AfterViewInit, OnDestroy, OnChanges {
  readonly maximumMessageBufferSize: number = 100;
  readonly messageListAdditionSize: number = 40;
  readonly qtsChatTitle: ConferenceChatTitleEnum = ConferenceChatTitleEnum.QUESTIONS_TO_SPEAKER;

  @ViewChild('messageListContainer', { static: true })
  messageListContainer: ScrollControllerComponent;

  btnGoToBottomTranslate$: Observable<string>;
  btnGoToBottomVisibleTrigger$: Observable<boolean>;

  @ViewChild('messageEditComponent')
  messageEditComponent: ChatMessageEditComponent;

  @Input() chatTitle: string;

  @Input() conferenceCreator: User;

  @Input() isQTSChat: boolean;

  @Input() chatId: CHAT_ID;

  @Input() chatParticipants: Participant[];

  @EmitOnChange<Participant[], Participant[]>('chatParticipants', {
    onlyTruthy: true,
    emitter: (value, subject) => {
      if (value.length) {
        subject.next(value);
      }
    }
  })
  conferenceParticipants$: BehaviorSubject<Participant[]> = new BehaviorSubject<Participant[]>([]);

  conferenceParticipantsAsUsers$: Observable<User[]> = this.conferenceParticipants$.pipe(
    map(participants => {
      return participants.map(conferenceParticipant => {
        return new User({ ...conferenceParticipant.participantReference });
      });
    })
  );

  loadLatestMessages$: ReplaySubject<void> = new ReplaySubject<void>(1);

  userTreeSelectionModel = new SelectionModel<User>(true, []);

  isFavoriteChat = false;

  loadAdditionalAdditionalChatTrigger = new BehaviorSubject<string>(null);

  pinnedMessages = new BehaviorSubject<Message[]>(null);

  chatsStorage = localStorage.getItem('chats-info');

  showPinnedMessages = false;

  @Input() conferenceId: number;

  @Input() isActiveConference: boolean;

  @Input() isSpeaker: boolean;
  @Input() allowForward: boolean = false;
  @Input() excludeQTS: boolean = false;
  @Input() hideMessageEditArea: boolean = false;

  @Input() isQTSChatEnabled: boolean;

  @Input() demoChat: boolean = false;

  @Input() countMissedMessages: boolean = false;

  @Input() siblingChats: (Chat | ChatEntity)[] = [];

  @Input() emitObserve: boolean = true;
  @Input() reverseOnMobile: boolean = false;

  @Output() hide = new EventEmitter<void>();
  @Output() missedMessages = new EventEmitter<number>();
  @Output() initParticipantsEdit = new EventEmitter<void>();
  @Output() closeChat = new EventEmitter<void>();
  @Output() clearMainChatUnreadMessages = new EventEmitter<boolean>();
  @Output() editChatSelect = new EventEmitter<CHAT_ID>();

  @Output() selectedUsersOutput = new EventEmitter<User[]>();
  selectedUsers: User[];

  @EmitOnChange('chatId', { onlyTruthy: true })
  inputChatId$: ReplaySubject<CHAT_ID> = new ReplaySubject<CHAT_ID>(1);

  @EmitOnChange('conferenceId', { onlyTruthy: true })
  conferenceId$: ReplaySubject<number> = new ReplaySubject<number>(1);

  @EmitOnChange('countMissedMessages')
  countMissedMessages$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

  @EmitOnChange('conferenceId', { onlyTruthy: true })
  inputConferenceId$: ReplaySubject<number> = new ReplaySubject<number>(1);

  currentUser$: Observable<User> = this.authService.currentUser$.pipe(filter(isTruthy));
  currentUserId$: Observable<number> = this.currentUser$.pipe(
    map(currentUser => {
      return currentUser.id;
    })
  );

  chat$: Observable<ChatEntity> = this.inputChatId$.pipe(
    switchMap(chatId => {
      return this.store.select(ChatSelectors.chatEntityById(chatId));
    }),
    filter(isTruthy),
    replayWhileSubs()
  );

  chatParticipantsEntity$: Observable<PARTICIPANT_ID[]> = this.inputChatId$.pipe(
    switchMap(chatId => {
      return this.store.select(ParticipantsSelectors.getParticipantsEntity(chatId));
    })
  );

  chatParticipantsCount$: Observable<number> = this.chatParticipantsEntity$.pipe(
    map(chatParticipantsEntity => {
      return chatParticipantsEntity.length ?? 0;
    }),
    startWith(0),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  chatParticipants$: Observable<ChatParticipant[]> = this.inputChatId$.pipe(
    switchMap(chatId => {
      return this.store.select(ParticipantsSelectors.getParticipants(chatId));
    })
  );

  chatParticipantsAsUsers$: Observable<User[]> = this.chatParticipants$.pipe(
    map(chatParticipants => {
      if (!!this.selectedUsers && this.selectedUsers.length > 0) {
        return this.selectedUsers;
      }
      return chatParticipants.map(participant => {
        return participant.user;
      });
    })
  );

  showUserTree$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showChatMessages$: Observable<boolean> = this.showUserTree$.pipe(
    map(showTree => {
      return !showTree;
    }),
    startWith(true),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  chatId$: Observable<CHAT_ID> = this.chat$
    .pipe(
      map(chat => {
        return chat.id;
      })
    )
    .pipe(distinctUntilChanged(), replayWhileSubs());

  isElectronApp: boolean = this.electronService.isElectron;

  localMessageEvent$: Subject<MessageEvent> = new Subject<MessageEvent>();

  defaultChatTitle$: Observable<string | any> = this.translateService.get('CHAT');

  editingMessage$: ReplaySubject<Message> = new ReplaySubject<Message>(1);
  editingMessageClone$: Observable<Message> = this.editingMessage$.pipe(clone());
  listInitialized$: Subject<void> = new Subject<void>();

  isMobileDevice$: Observable<boolean> = this.stateService.isMobileDevice$;
  mobileLayout$: Observable<boolean> = this.isMobileDevice$.pipe(
    map(isMobileDevice => {
      return this.reverseOnMobile ? isMobileDevice : false;
    }),
    distinctUntilChanged()
  );

  replyingMessage$: ReplaySubject<Message> = new ReplaySubject<Message>();
  replyingMessageClone$: Observable<Message> = this.replyingMessage$.pipe(clone());

  forwardingMessages$: ReplaySubject<Message[]> = new ReplaySubject<Message[]>();
  forwardingMessagesClone$: Observable<Message[]> = this.forwardingMessages$.pipe(clone());

  currentUserIdAsArray$: Observable<number[]> = this.currentUserId$.pipe(
    map(currentUserId => {
      return [currentUserId];
    })
  );

  completeMessages$: Subject<void> = new Subject<void>();

  rangeByChat$: Observable<{ chatId: CHAT_ID; range: MessageRange }> = this.chatId$.pipe(
    switchMap(chatId => {
      return this.store.select(RangeSelectors.rangeByChatId(chatId)).pipe(
        map(range => {
          return { chatId, range };
        })
      );
    }),
    distinctUntilChanged((a, b) => {
      return (
        isTruthy(a) &&
        isTruthy(b) &&
        a.chatId === b.chatId &&
        a.range?.anchorIndex === b.range?.anchorIndex &&
        a.range?.total === b.range?.total &&
        a.range?.count === b.range?.count &&
        (isTruthy(a.range?.anchor) && isTruthy(b.range?.anchor)
          ? a.range?.anchor.id === b.range?.anchor.id
          : !isTruthy(a.range?.anchor && !isTruthy(b.range?.anchor)))
      );
    })
  );

  messages$: Observable<Message[]> = this.rangeByChat$.pipe(
    filter(rangeByChat => {
      return isTruthy(rangeByChat.range);
    }),
    switchMap(rangeByChat => {
      return this.store
        .select(
          MessageSelectors.getMessagesRange(rangeByChat.chatId, rangeByChat.range.anchor, rangeByChat.range.count)
        )
        .pipe(
          map(({ messages }) => {
            return messages;
          })
        );
    }),
    startWith([]),
    takeUntil(this.completeMessages$),
    replayWhileSubs()
  );

  loadedCount$: Observable<number> = this.chatId$.pipe(
    switchMap(chatId => {
      return this.store.select(MessageSelectors.getMessagesCount(chatId));
    })
  );

  unreadMessagesCount$: Observable<number> = this.inputChatId$.pipe(
    switchMap(chatId => {
      return this.store.select(ChatUnreadMessageSelectors.getUnreadMessagesCount(chatId));
    }),
    filter(isTruthy),
    replayWhileSubs()
  );

  missedMessages$: Observable<number> = this.countMissedMessages$.pipe(
    switchMap(counting => {
      if (!counting) {
        return of(null);
      }
      return this.messageEvents$.pipe(
        filter(event => {
          return event.eventType === EventTypeEnum.INSERT;
        }),
        scan(count => {
          return ++count;
        }, 0),
        auditTime(500),
        map(count => {
          return count || null;
        })
      );
    })
  );

  scrollTrigger$: Observable<void> = this.chatId$.pipe(sample(this.messages$), mapTo(undefined));

  warningMessage$: Observable<string> = this.chatService.warnings().pipe(replayWhileSubs());

  showWarning: boolean = false;

  chatInfo$: Observable<BlockChatRequest> = combineLatest([
    this.chatId$.pipe(
      filter(chatId => {
        return isTruthy(chatId) && chatId !== -1;
      })
    ),
    this.currentUser$
  ]).pipe(
    switchMap(([chatId, user]) => {
      return this.chatService.getChatBanInfo(chatId, user.id);
    })
  );

  isChatBanned$: Observable<boolean> = combineLatest([this.chatId$, this.currentUserId$]).pipe(
    switchMap(([chatId, currentUserId]) => {
      return this.store.select(ParticipantsSelectors.isChatBan(chatId, currentUserId));
    })
  );

  chatBan$: Observable<{ id: PARTICIPANT_ID; reason: string; expired?: Date }> = combineLatest([
    this.chatId$,
    this.currentUserId$
  ]).pipe(
    switchMap(([chatId, currentUserId]) => {
      return this.store.select(ParticipantsSelectors.chatBan(chatId, currentUserId));
    })
  );

  lastReadMessage$: Observable<Message> = this.messages$.pipe(
    //TODO STORE
    withLatestFrom(this.currentUser$),
    map(([messages, user]) => {
      const orderedMessages = messages.slice().sort((messageA, messageB) => {
        return messageB.sentDate?.getTime() - messageA.sentDate?.getTime();
      });

      const lastRead = orderedMessages.find(message => {
        return message.readUserIds.includes(user.id);
      });

      if (lastRead) {
        return lastRead;
      }

      return orderedMessages[orderedMessages.length - 1];
    })
  );

  markAsRead$: ReplaySubject<Message> = new ReplaySubject<Message>(1);
  markAsReadSetter$: Observable<number> = this.markAsRead$.pipe(
    bufferTime(500),
    filter(messages => {
      return messages?.length > 0;
    }),
    withLatestFrom(this.lastReadMessage$),
    map(([messages, lastRead]) => {
      let to = <Message>{ id: 0 };
      const lastReadMessage = lastRead || <Message>{ id: 0 };
      if (messages.length > 0) {
        to = this.chatService.getRangeOfMessagesBySet(messages).to;
      }
      return lastReadMessage.id > to.id ? lastRead : to;
    }),
    scan(
      (prev, to) => {
        return {
          changed:
            prev.to.id < to.id || (isTruthy(prev?.to?.chatId) && isTruthy(to.chatId) && prev?.to?.chatId !== to.chatId),
          to
        };
      },
      {
        changed: false,
        to: <Message>{ id: 0 }
      }
    ),
    switchMap(({ changed, to }) => {
      if (!changed || !isTruthy(to.chatId)) {
        return EMPTY;
      }

      return this.chatService.markMessagesRangeAsRead(<Message>{ chatId: to.chatId, id: 0 }, to, true);
    })
  );

  titleEditingState: boolean = false;
  titleFormGroup: UntypedFormGroup = new UntypedFormGroup({
    chatTitle: new UntypedFormControl(null, [breezValidators.RequiredWithWhitespaceValidator()])
  });

  chatTitleControl: UntypedFormControl = this.titleFormGroup.controls.chatTitle as UntypedFormControl;

  isConferenceChat$: Observable<boolean> = this.conferenceId$.pipe(
    map(conferenceId => {
      return isTruthy(conferenceId);
    }),
    tap(isConferenceChat => {
      return this.showLoader$.next(!isConferenceChat);
    }),
    startWith(false),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  latestChatId: CHAT_ID = null;

  messageEvents$: Observable<MessageEvent> = this.chatService.fetchOnConnectTrigger$.pipe(
    withLatestFrom(this.isConferenceChat$),
    switchMap(([, isConferenceChat]) => {
      return merge(
        this.chatId$.pipe(
          debounceTime(50),

          switchMap(chatId => {
            if (chatId !== this.latestChatId) {
              if (isTruthy(this.latestChatId)) {
                this.chatService
                  .leaveChat(this.latestChatId)
                  .pipe(take(1))
                  .subscribe(result => {
                    if (result) {
                      this.latestChatId = chatId;
                    }
                  });
              } else {
                this.latestChatId = chatId;
              }
            }
            return this.chatService.messageEvents(
              chatId,
              1,
              this.emitObserve,
              isConferenceChat ? EventsObserverEnum.CONFERENCE : EventsObserverEnum.GLOBAL
            );
          })
        ),
        this.localMessageEvent$
      );
    }),
    share()
  );

  showLoader$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  changedChat$: Observable<boolean> = this.chat$.pipe(
    skip(1),
    pairwise(),
    map(([previousValue, currentValue]: any[]) => {
      return previousValue.id !== currentValue.id;
    }),
    replayWhileSubs()
  );

  lastOutGoingMessage$: Observable<Message> = this.messages$.pipe(
    withLatestFrom(this.currentUserId$),
    take(1),
    map(([messages, currentUserId]) => {
      const reversedMessages = [...messages].reverse();
      return reversedMessages.find(message => {
        return (
          !this.messageService.messageIsForwarded(message) &&
          (message.sender.userId || message.userId) === currentUserId &&
          this.messageService.messageIsSomeDelivered(message)
        );
      });
    }),
    filter(isTruthy)
  );

  lastMessage$: Observable<Message> = this.chatId$.pipe(
    switchMap(chatId => {
      return this.store.select(MessageSelectors.getLastMessage(chatId));
    }),
    replayWhileSubs()
  );

  onMessageSend$: Observable<boolean> = this.lastMessage$.pipe(
    filter(message => {
      return isTruthy(message?.id);
    }),
    distinctUntilChanged((a, b) => {
      return a?.id === b?.id;
    }),
    mergeMap(message => {
      return this.store.select(ExecutionSelectors.executionResult(MessageActions.sendMessage, String(message.id)));
    }),
    filter(isSend => {
      return isSend === true;
    })
  );

  currentChatCall$: Observable<Conference> = this.chat$.pipe(
    map(chat => {
      return chat.callId;
    }),
    switchMap(chatCallId => {
      return isTruthy(chatCallId) ? this.conferencesService.observeConferenceByConferenceId(chatCallId) : of(null);
    }),
    replayWhileSubs()
  );

  currentChatCallStatus$: Observable<ConferenceState> = this.currentChatCall$.pipe(
    map(currentCall => {
      return isTruthy(currentCall) ? currentCall.state : null;
    }),
    replayWhileSubs()
  );

  isThereActiveCall$: Observable<boolean> = this.currentChatCallStatus$.pipe(
    map(call => {
      return isTruthy(call) ? call === ConferenceState.ACTIVE : false;
    }),
    replayWhileSubs()
  );

  hasP2pAccess$: Observable<boolean> = this.authService.roles$.pipe(
    map(roles => {
      return !!roles.find(role => {
        return role === 'p2p:access';
      });
    }),
    replayWhileSubs()
  );

  showNoMessagesTip$: Observable<boolean> = combineLatest([
    this.showLoader$,
    this.messages$,
    this.isConferenceChat$
  ]).pipe(
    map(([showLoader, messages, isConferenceChat]) => {
      return !showLoader && messages.length === 0 && !isConferenceChat;
    }),
    distinctUntilChanged()
  );

  canCreateConferences$: Observable<boolean> = this.authService.roles$.pipe(
    map(roles => {
      return roles.includes('conferences:create');
    })
  );

  showHeaderCallButton$: Observable<boolean> = combineLatest([
    this.hasP2pAccess$,
    this.isThereActiveCall$,
    this.canCreateConferences$,
    this.chatParticipantsEntity$
  ]).pipe(
    map(([hasP2pAccess, isThereCall, canCreateConferences, chatParticipantsEntity]) => {
      return (
        !isThereCall &&
        ((hasP2pAccess && chatParticipantsEntity.length === 2) ||
          (chatParticipantsEntity.length > 2 && canCreateConferences))
      );
    }),
    distinctUntilChanged()
  );

  isMessageEditing: boolean = false;

  textAreaResized$: Subject<number> = new Subject<number>();

  notSentNotificationTime: number = this.appConfig.messageNotSentNotificationTime;

  canCreateCall$: Observable<boolean> = combineLatest([this.chatParticipantsAsUsers$, this.currentUser$]).pipe(
    debounceTime(200),
    switchMap(([chatParticipantsAsUsers, currentUser]) => {
      const destinationUser = chatParticipantsAsUsers.find(user => {
        return user.id !== currentUser.id;
      });
      const canCreate = chatParticipantsAsUsers.length > 2;
      return canCreate
        ? of(true)
        : chatParticipantsAsUsers.length <= 2 && isTruthy(destinationUser)
          ? this.userStatusService.observeUserStatusById(destinationUser?.id).pipe(
              filter(isTruthy),
              map(status => {
                return !!status;
              }),
              startWith(false),
              distinctUntilChanged()
            )
          : of(false);
    }),
    startWith(false),
    distinctUntilChanged()
  );

  hasActiveCall$: Observable<boolean> = combineLatest([
    this.callsService.currentCallModels$,
    this.chatParticipantsAsUsers$
  ]).pipe(
    map(([currentCallModels, chatParticipantsAsUsers]) => {
      const usersId: number[] = chatParticipantsAsUsers
        ?.map(user => {
          return user.id;
        })
        .filter(isTruthy);
      if (usersId?.length !== 2) {
        return false;
      } else {
        const currentCallModel: CallModel = currentCallModels?.find(currentCallModelItem => {
          if (!currentCallModelItem.answerer || !currentCallModelItem.caller) {
            return false;
          }
          const answerer: User = plainToInstance(User, currentCallModelItem.answerer); // TODO Разобрать почему user plain и поменять применение в других местах
          const caller: User = plainToInstance(User, currentCallModelItem.caller);
          return (
            usersId.includes(answerer?.id) &&
            usersId.includes(caller?.id) &&
            [CallStatus.ACCEPT, CallStatus.RING, CallStatus.HOLD].includes(currentCallModelItem.status)
          );
        });

        return isTruthy(currentCallModel);
      }
    }),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  @HostListener('document:keyup.esc', ['$event'])
  closeChatEmit($event: KeyboardEvent): void {
    const inputElements = Array.from(this.document.querySelectorAll('input'));
    const textAreaElements = Array.from(this.document.querySelectorAll('textarea'));

    if (
      inputElements.includes(<HTMLInputElement>$event.target) ||
      textAreaElements.includes(<HTMLTextAreaElement>$event.target)
    ) {
      return;
    }
    this.closeChat.emit();
  }

  constructor(
    @Inject('APP_CONFIG') private readonly appConfig: AppConfigModel,
    @Inject(DOCUMENT) private document: Document,
    @Inject('ORIGIN') private origin: string,
    private store: Store<ModuleState.State>,
    private chatService: ChatService,
    private stateService: StateService,
    private translateService: TranslateService,
    private authService: AuthService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private cd: ChangeDetectorRef,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private elementRef: ElementRef,
    private mediaDevicesService: MediaDevicesService,
    private callsService: CallsService,
    private conferencePlannerService: ConferencePlannerService,
    private electronService: ElectronService,
    private conferencesService: ConferencesService,
    private userStatusService: UserStatusService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.loadAdditionalAdditionalChatTrigger
      .pipe(
        filter(x => {
          return x !== null;
        })
      )
      .subscribe(chat => {
        if (chat === 'pinned') {
          let pinnedMessages = [];
          const pinnedMessagesStorage = this.chatsStorage;
          if (pinnedMessagesStorage) {
            pinnedMessages = JSON.parse(pinnedMessagesStorage);
          }

          const pinnedChat = pinnedMessages.find(x => {
            return x?.chatId === this.chatId;
          });

          this.pinnedMessages.next(
            this.pinnedMessages.value?.filter(x => {
              return pinnedChat?.messages?.includes(x.id);
            })
          );
        }
      });

    this.messageEvents$.pipe(untilDestroyed(this)).subscribe();

    this.chatId$
      .pipe(
        tap(chatId => {
          this.store.dispatch(
            RangeActions.updateRange({
              range: {
                chatId: chatId,
                count: this.messageListAdditionSize,
                anchorIndex: null,
                anchor: null
              }
            })
          );
          if (this.titleEditingState) {
            this.toggleEditState();
          }
        }),
        switchMap(() => {
          return timer(1000, 0).pipe(take(1));
        }),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.messageListContainer.scrollAfterChecked(false);
        this.changeDetectorRef.markForCheck();
      });

    this.messageListContainer.needLoadTopTrigger$
      .pipe(withLatestFrom(this.rangeByChat$), untilDestroyed(this))
      .subscribe(([, rangeByChat]) => {
        const count = rangeByChat.range.count;
        const newCount = count + this.messageListAdditionSize;
        const from = rangeByChat.range.anchorIndex;
        const delta = this.maximumMessageBufferSize - newCount;
        const newFrom = delta >= 0 ? from : (from ?? 0) + delta;
        // if(rangeByChat.range.total < newCount + (from ?? 0)){
        this.store.dispatch(
          RangeActions.updateRange({
            range: {
              chatId: rangeByChat.chatId,
              count: Math.min(newCount, this.maximumMessageBufferSize),
              anchorIndex: newFrom
            }
          })
        );
        // }
      });

    this.onMessageSend$.pipe(untilDestroyed(this)).subscribe(() => {
      this.goToBottom();
    });

    this.messageListContainer
      .getScrollToBottomTrigger(32)
      .pipe(withLatestFrom(this.rangeByChat$), untilDestroyed(this))
      .subscribe(([, rangeByChat]) => {
        if (isTruthy(rangeByChat?.range) && isTruthy(rangeByChat.range?.anchor)) {
          const count = rangeByChat?.range.count;
          const newCount = Math.min(count + this.messageListAdditionSize, this.maximumMessageBufferSize);
          const from = rangeByChat?.range.anchorIndex + this.messageListAdditionSize;
          const range =
            from >= rangeByChat.range.total - 1
              ? {
                  chatId: rangeByChat.chatId,
                  count: newCount,
                  anchorIndex: null,
                  anchor: null
                }
              : {
                  chatId: rangeByChat.chatId,
                  count: newCount,
                  anchorIndex: from
                };

          this.store.dispatch(
            RangeActions.updateRange({
              range
            })
          );
        }
      });

    this.stateService.isMobileDevice$
      .pipe(
        untilDestroyed(this),
        withLatestFrom(this.inputConferenceId$),
        finalize(() => {
          return this.document.body.classList.remove('hide-header');
        })
      )
      .subscribe(([isMobile, conferenceId]) => {
        if (isMobile && !isTruthy(conferenceId)) {
          this.document.body.classList.add('hide-header');
        } else {
          this.document.body.classList.remove('hide-header');
        }
      });

    this.markAsReadSetter$.pipe(withLatestFrom(this.chat$)).subscribe(([result, chat]) => {
      if (result > 0 && isTruthy(chat.conferenceId)) {
        this.store.dispatch(ChatUnreadMessageActions.loadUnreadMessages({ conferenceId: chat.conferenceId }));
      }
    });

    this.warningMessage$.pipe(untilDestroyed(this)).subscribe(() => {
      this.showWarning = true;
      this.cd.markForCheck();
    });

    this.chatId$
      .pipe(withLatestFrom(this.isConferenceChat$), untilDestroyed(this))
      .subscribe(([_, isConferenceChat]) => {
        if (this.messageEditComponent && !isConferenceChat) {
          this.messageEditComponent.reset(isTruthy(this.messageEditComponent.editMessageIdFormControl.value));
        }
      });

    // Прокрутка к посл сообщениям если мы находимся почти внизу и изменился rangeByChat
    this.rangeByChat$.pipe(untilDestroyed(this)).subscribe(rangeByChat => {
      const anchorIndex = rangeByChat?.range?.anchorIndex;
      const allowance = 64; // кол-во пикселей снизу в пределах которых мы считаем что мы внизу
      if (!isTruthy(anchorIndex) && this.messageListContainer.isOnBottom(allowance)) {
        this.messageListContainer.scrollAfterChecked(false);
        this.changeDetectorRef.markForCheck();
      }
    });

    // Запрос сообщений с бека
    this.rangeByChat$
      .pipe(startWith(null))
      .pipe(
        pairwise(),
        filter(([_rangeByChat, rangeByChat]) => {
          return isTruthy(rangeByChat) && isTruthy(rangeByChat.range);
        }),
        untilDestroyed(this)
      )
      .subscribe(([_rangeByChat, rangeByChat]) => {
        const range = rangeByChat?.range,
          chatRange = _rangeByChat?.range,
          count = rangeByChat.range.count,
          total = rangeByChat.range.total,
          anchorIndex = rangeByChat.range.anchorIndex,
          space = isTruthy(anchorIndex) ? (anchorIndex > 0 ? total - anchorIndex : -anchorIndex) : null;

        if (
          isTruthy(range) &&
          isTruthy(chatRange) &&
          _rangeByChat.chatId === rangeByChat.chatId &&
          range?.count === chatRange?.count &&
          (isTruthy(range?.anchor) && isTruthy(chatRange?.anchor)
            ? range?.anchor.id === chatRange?.anchor.id
            : !isTruthy(range?.anchor && !isTruthy(chatRange?.anchor)))
        ) {
          if (isTruthy(chatRange.anchor) && !isTruthy(range.anchor)) {
            // при ручной прокрутке обратно к последним
            this.store.dispatch(
              MessageActions.loadMessages({
                chatId: range.chatId,
                from: 1,
                count: 2 * this.messageListAdditionSize,
                forcedLoad: true
              })
            );
          }
          // else no need load
          return;
        }

        if (isTruthy(space)) {
          if (space + count < total) {
            // no need load
            return;
          } else {
            this.store.dispatch(
              MessageActions.loadMessages({
                chatId: range.chatId,
                from: total + 1,
                count: this.messageListAdditionSize
              })
            );
          }
        } else {
          if (count === this.messageListAdditionSize) {
            // latest
            this.store.dispatch(
              MessageActions.loadMessages({
                chatId: range.chatId,
                from: 1,
                count: 2 * this.messageListAdditionSize,
                forcedLoad: true
              })
            );
          } else {
            // add
            this.store.dispatch(
              MessageActions.loadMessages({
                chatId: range.chatId,
                from: total + 1,
                count: this.messageListAdditionSize
              })
            );
          }
        }
      });

    combineLatest([this.mobileLayout$, this.scrollTrigger$])
      .pipe(sample(this.listInitialized$), untilDestroyed(this))
      .subscribe(() => {
        // this.scrollMessageListToEnd(true);
      });

    this.missedMessages$.pipe(untilDestroyed(this)).subscribe(count => {
      return this.missedMessages.emit(count);
    });

    combineLatest([this.isConferenceChat$, this.changedChat$]).subscribe(([isConferenceChat, isChangedChat]) => {
      if (!isConferenceChat) {
        this.showLoader$.next(false);
      }
      this.showLoader$.next(isChangedChat);
    });

    this.textAreaResized$
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged(),
        tap(() => {
          return this.setChatContentHeight();
        })
      )
      .subscribe();

    this.chat$
      .pipe(
        untilDestroyed(this),
        filter(chat => {
          return chat.name === ConferenceChatTitleEnum.MAIN_CHAT;
        }),
        finalize(() => {
          return this.clearMainChatUnreadMessages.emit(false);
        })
      )
      .subscribe(() => {
        return this.clearMainChatUnreadMessages.emit(true);
      });
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnChanges(_: SimpleChanges): void {}

  ngOnDestroy(): void {
    this.completeMessages$.next();
    this.chatId$
      .pipe(
        switchMap(chatId => {
          return this.chatService.leaveChat(chatId);
        })
      )
      .subscribe();
    this.clearMainChatUnreadMessages.emit(false);
  }

  ngAfterViewInit(): void {
    this.chat$.subscribe(x => {
      if (x.id == 'favorites') {
        this.isFavoriteChat = true;
        this.pinnedMessages = new BehaviorSubject(null);
        const pinnedMessagesStorage = localStorage.getItem('favorites');
        let chats = JSON.parse(pinnedMessagesStorage)?.filter(chat => {
          return chat?.messages?.length > 0;
        });

        this.showPinnedMessages = true;
        if (!chats || chats.length === 0) {
          return;
        }

        const messageObservables = chats.map(chat => {
          return this.chatService.fetchMessages({ chatid: chat.chatId, messageids: chat.messages ?? [] });
        });

        forkJoin(messageObservables)
          .pipe(
            map((responses: any) => {
              return responses
                .reduce((acc, curr) => {
                  return acc.concat(curr);
                }, [])
                .sort((a, b) => {
                  return new Date(a.sentDate).getTime() - new Date(b.sentDate).getTime();
                });
            })
          )
          .subscribe(x => {
            this.pinnedMessages.next(x);
          });
      } else {
        this.isFavoriteChat = false;
        this.showPinnedMessages = false;
      }
    });

    this.setChatContentHeight();
    this.btnGoToBottomTranslate$ = timer(1000).pipe(
      switchMap(() => {
        return combineLatest([this.messageListContainer.marginBottom$, this.unreadMessagesCount$]).pipe(
          map(([bottom, unread]) => {
            bottom = 350 - bottom;
            return `translateY(${bottom <= 0 || unread > 0 ? `0` : `${bottom}px`})`;
          })
        );
      })
    );

    this.btnGoToBottomVisibleTrigger$ = this.btnGoToBottomTranslate$.pipe(
      take(1),
      map(() => {
        return true;
      })
    );
  }

  setChatContentHeight(): void {
    combineLatest([this.isMobileDevice$, this.isConferenceChat$])
      .pipe(take(1))
      .subscribe(([isMobile]) => {
        if (isMobile) {
          // вычитаем высоту шапки приложения, формы ввода текста сообщения и шапки чата
          const messageFormHeight = this.getElementHeight(this.elementRef.nativeElement.querySelector('.message-form'));
          const chatHeaderHeight = this.getElementHeight(
            this.elementRef.nativeElement.querySelector('.header-conversation')
          );
          this.document.documentElement.style.setProperty(
            '--chat-content-height',
            `${innerHeight - messageFormHeight - chatHeaderHeight}px`
          );
        } else {
          this.document.documentElement.style.setProperty('--chat-content-height', `auto`);
        }
      });
  }

  getElementHeight(element: HTMLElement): number {
    return element ? Number(getComputedStyle(element).height.replace(/px/, '')) : 0;
  }

  submitMessage(message: Message): void {
    if (this.demoChat) {
      this.messageEditComponent.isLoading$.next(false);
      this.messageEditComponent.reset(true);
      combineLatest([this.authService.currentUser$, this.translateService.get('GUEST')])
        .pipe(take(1))
        .subscribe(([user, guest]) => {
          message.id = Math.floor(Math.random() * 100000);
          message.sender = new ChatParticipant({
            userId: user ? user.id : null,
            user: user || <User>{ name: guest, id: null, isGuest: true }
          });
          message.meta = {};
          this.localMessageEvent$.next(new MessageEvent({ message, eventType: EventTypeEnum.INSERT }));
        });
      return;
    }

    const hasTobeForwarded = !message.body && Array.isArray(message.forwardIds) && message.forwardIds.length > 0;

    if (hasTobeForwarded) {
      this.forwardMessages(message.forwardIds).subscribe(this.onMessageSent.bind(this));
    } else {
      this.sendMessage$(message).subscribe(this.onMessageSent.bind(this));
    }
    if (message.isQuestionToSpeaker) {
      const siblingQTSChat = this.siblingChats.find(chat => {
        return chat.name === ConferenceChatTitleEnum.QUESTIONS_TO_SPEAKER;
      });
      if (!siblingQTSChat) {
        this.messageEditComponent.isLoading$.next(false);
        this.messageEditComponent.messageForm.reset();
        return;
      }

      this.chatId$
        .pipe(
          takeWhile(chatId => {
            return siblingQTSChat.id !== chatId;
          }),
          mapTo(message),
          clone(),
          tap(message_ => {
            message_.chatId = siblingQTSChat.id;
            const tempId = guid();
            // @ts-ignore
            message_.id = tempId;
            // @ts-ignore
            this.store.dispatch(
              MessageActions.sendMessage({ message: message_, tempId: tempId as any, objectId: tempId, timeout: 30000 })
            );
          })
        )
        .subscribe(this.onMessageSent.bind(this));
    }
  }

  onMessageSent(message: Message): void {
    if (message) {
      this.messageEditComponent.isLoading$.next(false);
      this.messageEditComponent.reset(true);
    } else {
      this.messageEditComponent.isLoading$.next(false);
      this.notificationService.show({
        type: NotificationType.Warning,
        message: this.translateService.instant('MESSAGE_WAS_NOT_SEND')
      });
    }
  }

  sendMessage$(messageToSend: Message): Observable<Message> {
    // const timer$ = timer(this.notSentNotificationTime).pipe(map(() => null));
    return of(messageToSend).pipe(
      switchMap(message => {
        return this.chatId$.pipe(
          take(1),
          map(chatId => {
            const tempId = isTruthy(message.id) ? message.id : guid();
            message.chatId = chatId;
            // @ts-ignore
            message.id = tempId;
            // @ts-ignore
            this.store.dispatch(
              MessageActions.sendMessage({
                message,
                tempId: tempId as any,
                objectId: tempId,
                conferenceId: this.conferenceId,
                timeout: 30000
              })
            );
            return message;
          })
        );
      })
    );
  }

  forwardToSpeakersChat(message: Message): void {
    const siblingQTSChat = this.siblingChats.find(chat => {
      return chat.name === ConferenceChatTitleEnum.QUESTIONS_TO_SPEAKER;
    });
    this.chatService.replyToMessage(siblingQTSChat.id, null, message.id).pipe(take(1)).subscribe();
  }

  forwardMessages(messagesIds: number[]): Observable<any> {
    const timer$ = timer(this.notSentNotificationTime).pipe(
      map(() => {
        return null;
      })
    );
    const send$ = this.chatId$.pipe(
      switchMap(chatId => {
        return this.chatService.forwardMessages(chatId, messagesIds);
      }),
      take(1)
    );
    return race(timer$, send$);
  }

  onHideHeader(): void {
    this.hide.emit();
  }

  issueWarning(userId: number): void {
    const dialogRef = this.dialog.open(ChatWarningFormComponent);
    dialogRef
      .afterClosed()
      .pipe(
        take(1),
        withLatestFrom(this.chatId$),
        switchMap(([request, chatId]) => {
          if (!request) {
            return EMPTY;
          }

          request.chatId = chatId;
          request.userId = userId;

          return this.chatService.issueWarning(request);
        })
      )
      .subscribe(success => {
        if (!success) {
          this.notificationService.show({
            type: NotificationType.Error,
            message: 'Не удалось отправить предупреждение'
          });
          return;
        }

        this.notificationService.show({
          type: NotificationType.Success,
          message: 'Предупреждение отправлено'
        });
      });
  }

  blockChat(userId: number): void {
    const dialogRef = this.dialog.open(ChatBlockFormComponent);
    dialogRef
      .afterClosed()
      .pipe(
        take(1),
        withLatestFrom(this.chatId$),
        switchMap(([request, chatId]) => {
          if (!request) {
            return EMPTY;
          }

          if (request.duration) {
            request.expires = moment(new Date()?.getTime() + request.duration?.getTime())
              .endOf('minute')
              .toDate();
          }
          request.userId = userId;
          request.chatId = chatId;

          return this.chatService.banChatForUser(request);
        })
      )
      .subscribe(success => {
        if (!success) {
          this.notificationService.show({
            type: NotificationType.Error,
            message: 'Не удалось заблокировать чат пользователю'
          });
          return;
        }

        this.notificationService.show({
          type: NotificationType.Success,
          message: 'Чат для пользователя заблокирован'
        });
      });
  }

  unblockChat(userId: number): void {
    this.chatId$
      .pipe(
        take(1),
        switchMap(chatId => {
          const request: Partial<BlockChatRequest> = {};
          request.userId = userId;
          request.chatId = chatId;

          return this.chatService.unbanChatForUser(request);
        })
      )
      .subscribe(success => {
        if (!success) {
          this.notificationService.show({
            type: NotificationType.Error,
            message: 'Не удалось разблокировать чат пользователю'
          });
          return;
        }

        this.notificationService.show({
          type: NotificationType.Success,
          message: 'Чат для пользователя разблокирован'
        });
      });
  }

  replyMessage(message: Message): void {
    const hasEditingMessage = this.messageEditComponent.messageForm.get('editMessageId').value;
    if (hasEditingMessage) {
      return;
    }
    this.replyingMessage$.next(message);
    this.messageEditComponent.focusMessageEditor();
  }

  markMessageAsRead(message: Message): void {
    this.markAsRead$.next(message);
  }

  toggleEditState(currentUser?: User): void {
    combineLatest([this.chat$, this.chatParticipants$])
      .pipe(take(1))
      .subscribe(([chat, chatParticipants]) => {
        this.titleEditingState = !this.titleEditingState;
        if (this.titleEditingState) {
          this.chatTitleControl.patchValue(getAvatarEntity(chat, chatParticipants, currentUser).name);
        } else {
          this.chatTitleControl.patchValue(null);
        }
      });
  }

  updateChatTitle(currentChatTitle: string): void {
    const name = this.chatTitleControl.value.slice(0, 149);

    if (this.chatTitleControl.invalid) {
      return;
    }
    this.chat$.pipe(take(1)).subscribe(chat => {
      if (!(this.chatTitleControl.pristine || currentChatTitle === name)) {
        this.store.dispatch(ChatActions.changeChat({ chat: { ...chat, name }, needSendToBackend: true }));
      }
      this.chatTitleControl.patchValue(null);
      this.chatTitleControl.markAsPristine();
      this.titleEditingState = false;
    });
  }

  editMessage($event: Message): void {
    this.editingMessage$.next($event);
    this.messageEditComponent.focusMessageEditor();
  }

  updateMessage($event: Message): Subscription {
    const attachmentsIds = $event.attachments.map(file => {
      return file.fileId;
    });
    const timer$ = timer(this.notSentNotificationTime).pipe(
      map(() => {
        return null;
      })
    );
    const send$ = this.chatService.updateMessage($event.id, $event.body, attachmentsIds).pipe(take(1));

    return race(timer$, send$).subscribe(this.onMessageSent.bind(this));
  }

  deleteMessage($event: Message): void {
    this.store.dispatch(MessageActions.deleteMessage({ messageId: $event.id, messageBody: $event.body }));
  }

  getChatParticipantsCount(participantsCount: number): Observable<string> {
    return this.chatService.getChatParticipantsCount(participantsCount).pipe(
      map(translated => {
        return `${participantsCount} ${translated}`;
      })
    );
  }

  initMessageEdit($event: KeyboardEvent): void {
    const messageEditForm = this.messageEditComponent.messageForm;
    const attachmentsControl = messageEditForm.get('attachments');
    const hasReplyingMessage = messageEditForm.get('replyMessageId').value;
    const hasAttachmentsControlValue = attachmentsControl.value && attachmentsControl.value.length > 0;
    const hasForwardingMessagesControlValue =
      messageEditForm.get('forwardingMessages').value && messageEditForm.get('forwardingMessages').value.length > 0;

    if (
      messageEditForm.get('body').value ||
      hasAttachmentsControlValue ||
      hasReplyingMessage ||
      hasForwardingMessagesControlValue
    ) {
      return;
    }
    if (!this.isMessageEditing) {
      $event.preventDefault();
    }
    this.isMessageEditing = true;
    this.lastOutGoingMessage$.pipe(take(1)).subscribe(foundMessage => {
      this.editMessage(foundMessage);
      this.isMessageEditing = false;
    });
  }

  cancelEditing($event?: KeyboardEvent): void {
    const isReplyingOrEditingMessage =
      this.messageEditComponent.messageForm.get('replyMessageId').value ||
      this.messageEditComponent.messageForm.get('editMessageId').value;
    if (!isReplyingOrEditingMessage) {
      return;
    }
    $event.stopPropagation();
    this.isMessageEditing = false;
    this.messageEditComponent.reset(true);
  }

  onCallChat(disabled = false): void {
    if (disabled) {
      return;
    }
    combineLatest(this.chat$, this.chatParticipants$, this.currentUser$)
      .pipe(take(1))
      .subscribe(([chat, chatParticipants, currentUser]) => {
        if (chatParticipants.length === 2) {
          const answererId = chatParticipants.find(chatUserId => {
            return chatUserId.userId !== currentUser.id;
          }).userId;
          this.hasActiveCall$
            .pipe(
              take(1),
              filter(hasActiveP2pCall => {
                return hasActiveP2pCall === false;
              }),
              switchMap(() => {
                return this.mediaDevicesService.availableSources$;
              }),
              map(devices => {
                const sources = [];
                const audioDevice = devices.find(device => {
                  return device.isDefault && device.kind === MediaSourceKind.AUDIO_INPUT;
                });
                if (!!audioDevice) {
                  sources.push(audioDevice);
                }

                const videoDevice = devices.find(device => {
                  return device.isDefault && device.kind === MediaSourceKind.VIDEO_INPUT;
                });
                if (!!videoDevice) {
                  sources.push(videoDevice);
                }

                return sources;
              }),
              switchMap(sources => {
                return this.callsService.createCall({ answererId }).pipe(
                  tap(call => {
                    return call.setUserMediaSources(sources);
                  })
                );
              }),
              take(1)
            )
            .subscribe();
        } else {
          this.conferencePlannerService
            .createConferenceFromGroupChat(chat, chatParticipants, currentUser)
            .pipe(
              take(1),
              switchMap(conferenceId => {
                if (isTruthy(conferenceId)) {
                  this.visitGroupCall(conferenceId);
                  return this.conferencesService.inviteParticipants(conferenceId, {
                    mode: InviteParticipantsModeEnum.STATES,
                    participantStates: [InviteParticipantsStatesEnum.ALL]
                  });
                }
                return of(null);
              })
            )
            .subscribe();
        }
      });
  }

  visitGroupCall(callId: number): void {
    if (isTruthy(callId)) {
      this.stateService.goToUrl(`${this.isElectronApp ? '#' : location.origin}/conference/${callId}`);
    }
  }

  forwardMessageToChat($event: MessageToForwardModel): Subscription {
    const { chatId, messages, inConference } = $event;
    if (inConference) {
      const messagesIds = messages.map(message => {
        return message.id;
      });
      return this.chatService
        .forwardMessages(chatId, messagesIds)
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          const chat = this.siblingChats.find(({ id }) => {
            return id === chatId;
          });
          if (chat.name === ConferenceChatTitleEnum.QUESTIONS_TO_SPEAKER) {
            this.editChatSelect.emit(chat.id);
          } else {
            this.store.dispatch(ChatActions.selectChat({ chatId }));
          }
        });
    }
    this.forwardingMessages$.next(messages);

    this.router.navigate(['/', 'chat'], {
      queryParams: {
        id: chatId
      }
    });
    this.store.dispatch(ChatActions.selectChat({ chatId }));
  }

  toggleUserTree(): void {
    this.chatParticipantsAsUsers$.pipe(take(1)).subscribe(chatUsers => {
      if (!this.showUserTree$.value) {
        this.userTreeSelectionModel = new SelectionModel<User>(true, chatUsers);
      } else {
        this.selectedUsers = [];
      }

      this.showUserTree$.next(!this.showUserTree$.value);
    });
  }

  clearSelectedUsers(users: User[]): void {
    this.chatParticipantsAsUsers$.pipe(take(1)).subscribe(chatUsers => {
      return (this.selectedUsers = chatUsers.filter(user => {
        return !users
          .map(_user => {
            return _user.id;
          })
          .includes(user.id);
      }));
    });
  }

  selectDivisionUsers(_: User[]): void {}

  selectConversationWithUser(user: User): void {
    this.chatParticipantsAsUsers$.pipe(take(1)).subscribe(chatUsers => {
      chatUsers.push(user);
      this.selectedUsers = chatUsers;
    });
  }

  updateParticipant(): void {
    this.showUserTree$.next(false);
    const users = [...this.selectedUsers];
    this.selectedUsersOutput.next(users);
    this.selectedUsers = [];
  }

  goToBottom(): void {
    this.chatId$
      .pipe(
        take(1),
        tap(chatId => {
          this.store.dispatch(
            RangeActions.updateRange({
              range: {
                chatId: chatId,
                count: this.messageListAdditionSize,
                anchorIndex: null,
                anchor: null
              }
            })
          );
        }),
        withLatestFrom(this.chat$),
        switchMap(([chatId, chat]) => {
          return this.chatService.markChatAsRead(chatId).pipe(
            tap(() => {
              const conferenceId = chat?.conferenceId ?? null;
              if (isTruthy(conferenceId)) {
                this.store.dispatch(ChatUnreadMessageActions.loadUnreadMessages({ conferenceId }));
              }
            })
          );
        }),
        take(1),
        delay(100)
      )
      .subscribe(() => {
        this.messageListContainer.scrollToBottom();
      });
  }

  getPinnedMessagesCount(): number {
    const chat = JSON.parse(this.chatsStorage)?.find(x => {
      return x?.chatId === this.chatId;
    });

    return (chat && chat?.messages?.length) || 0;
  }

  pinMessage(evt): void {
    let pinnedMessages = [];
    const pinnedMessagesStorage = this.chatsStorage;
    if (pinnedMessagesStorage) {
      pinnedMessages = JSON.parse(pinnedMessagesStorage);
    }

    const pinnedIndex = pinnedMessages.findIndex(x => {
      return x?.chatId === evt.chatId;
    });
    const pinnedChat = pinnedMessages.find(x => {
      return x?.chatId === evt.chatId;
    });
    if (pinnedChat) {
      let messages = pinnedChat.messages ?? [];
      const pinnedMessage = messages.findIndex(x => {
        return x === evt.id;
      });

      if (pinnedMessage !== -1) {
        messages.splice(pinnedMessage, 1);
      } else {
        messages.push(evt.id);
      }

      pinnedMessages[pinnedIndex] = { ...pinnedChat, messages };
    } else {
      const messages = [];
      messages.push(evt.id);

      pinnedMessages.push({ chatId: evt.chatId, messages });
    }

    localStorage.setItem('chats-info', JSON.stringify(pinnedMessages));
    this.chatsStorage = localStorage.getItem('chats-info');
    this.loadAdditionalAdditionalChatTrigger.next('pinned');
  }

  addToFavorites(message): void {
    const favoritesStorage = localStorage.getItem('favorites');
    let pinnedMessages = JSON.parse(favoritesStorage) ?? [];

    const favoritesIndex = pinnedMessages.findIndex(x => {
      return x?.chatId === message.chatId;
    });
    const favoritesChat = pinnedMessages.find(x => {
      return x?.chatId === message.chatId;
    });
    if (favoritesChat) {
      let messages = favoritesChat.messages ?? [];
      const pinnedMessage = messages.findIndex(x => {
        return x === message.id;
      });

      if (pinnedMessage !== -1) {
        this.pinnedMessages.next(
          this.pinnedMessages.value?.filter(x => {
            return x.id !== message.id;
          })
        );
        messages.splice(pinnedMessage, 1);
      } else {
        messages.push(message.id);
      }

      pinnedMessages[favoritesIndex] = { ...favoritesChat, messages };
    } else {
      const messages = [];
      messages.push(message.id);

      pinnedMessages.push({ chatId: message.chatId, messages });
    }

    localStorage.setItem('favorites', JSON.stringify(pinnedMessages));
  }

  togglePinnesMessagesShowing(): void {
    this.showPinnedMessages = !this.showPinnedMessages;
    const pinnned =
      JSON.parse(this.chatsStorage).find(x => {
        return x.chatId === this.chatId;
      })?.messages ?? [];

    if (pinnned.length === 0) {
      this.pinnedMessages.next([]);
      return;
    }

    this.chatService
      .fetchMessages({ chatid: this.chatId, messageids: pinnned })
      .pipe(
        map(x => {
          return x.sort((a, b) => {
            return a.sentDate.getTime() - b.sentDate.getTime();
          });
        })
      )
      .subscribe(x => {
        this.pinnedMessages = this.pinnedMessages ?? new BehaviorSubject(null);
        this.pinnedMessages.next(x);
        this.changeDetectorRef.detectChanges();
      });
  }

  deselectChat(): void {
    if (this.titleEditingState) {
      this.toggleEditState();
    }
    this.closeChat.emit();
    this.store.dispatch(ChatActions.deselectChat());
  }
}
