import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CallDynamicModule } from '@breez/modules/call/call-statuses/dynamic/dynamic.module';
import { COMPONENTS } from '@breez/modules/call/overlay-call/components';

import { OVERLAY_PROVIDERS } from '@breez/modules/call/overlay-call/overlay-provider';

@NgModule({
  imports: [CommonModule, CallDynamicModule],
  declarations: [COMPONENTS],
  providers: [OVERLAY_PROVIDERS]
})
export class OverlayCallModule {}
