import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CallStatus } from '@breez/modules/call/models';
import { scan } from 'rxjs/operators';
import { takeThenCloseIf } from '@breez/shared/rxjs-operators/take-then-close-if';
import { CALL_MODEL_CARRIER } from '@breez/modules/call/call-model-carrier.provider';
import { CallModelCarrier } from '@breez/modules/call/models/call-model-carrier.model';
import { audio } from '@breez/shared/utilities/audio';
import { isTruthy } from '@breez/shared/utilities/is-truthy';

@Injectable({ providedIn: 'root' })
export class CallRingtoneManager {
  ringtoneAudio$: Observable<HTMLAudioElement> = this.call.callModel$.pipe(
    scan((audioElement, callModel) => {
      let ringtoneName = 'call/' + callModel.status;
      let loop = false;

      if (callModel.status === CallStatus.RING) {
        loop = true;
        ringtoneName += '_' + callModel.destinationType;
      }

      audioElement = audio.play(audioElement, ringtoneName, loop);

      if (![CallStatus.HANGUP, CallStatus.TRANSFER, CallStatus.CANCEL, CallStatus.DECLINE].includes(callModel.status)) {
        return audioElement;
      }

      return null;
    }, null as HTMLAudioElement)
  );

  constructor(@Inject(CALL_MODEL_CARRIER) public call: CallModelCarrier) {
    this.ringtoneAudio$
      .pipe(
        takeThenCloseIf(ringtoneAudio => {
          return !isTruthy(ringtoneAudio);
        })
      )
      .subscribe();
  }

  close(): void {}
}
