import { UserMediaSource } from '@breez/models/webrtc/media-source.model';
import { EMPTY_ID } from '@breez/models';

const EMPTY = {
  id: EMPTY_ID,
  enabled: false
} as UserMediaSource;

export const mediaSourceHelper = {
  empty: EMPTY
};
