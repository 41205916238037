import { Injectable } from '@angular/core';
import { NGXLogger, NgxLoggerLevel } from 'ngx-logger';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  authToken$: ReplaySubject<string> = new ReplaySubject<string>(1);

  constructor(private logger: NGXLogger) {
    this.authToken$.pipe(untilDestroyed(this, 'destroy')).subscribe(token => {
      return this.logger.setCustomHttpHeaders(new HttpHeaders({ Authorization: `Bearer ${token}` }));
    });
  }

  private static getParams(level: NgxLoggerLevel): HttpParams {
    return new HttpParams().set('type', NgxLoggerLevel[level]);
  }

  trace(message: any, ...additional: any[]): void {
    this.logger.setCustomParams(LoggerService.getParams(NgxLoggerLevel.TRACE));
    this.logger.trace(message, additional);
  }

  debug(message: any, ...additional: any[]): void {
    this.logger.setCustomParams(LoggerService.getParams(NgxLoggerLevel.DEBUG));
    this.logger.debug(message, additional);
  }

  info(message: any, ...additional: any[]): void {
    this.logger.setCustomParams(LoggerService.getParams(NgxLoggerLevel.INFO));
    this.logger.info(message, additional);
  }

  log(message: any, ...additional: any[]): void {
    this.logger.setCustomParams(LoggerService.getParams(NgxLoggerLevel.LOG));
    this.logger.log(message, additional);
  }

  warn(message: any, ...additional: any[]): void {
    this.logger.setCustomParams(LoggerService.getParams(NgxLoggerLevel.WARN));
    this.logger.warn(message, additional);
  }

  error(message: any, ...additional: any[]): void {
    this.logger.setCustomParams(LoggerService.getParams(NgxLoggerLevel.ERROR));
    this.logger.error(message, additional);
  }

  fatal(message: any, ...additional: any[]): void {
    this.logger.setCustomParams(LoggerService.getParams(NgxLoggerLevel.FATAL));
    this.logger.fatal(message, additional);
  }

  destroy(): void {}
}
