import { Inject, Injectable } from '@angular/core';
import { PushNotificationService } from '@breez/modules/notification/push-browser.service';
import { merge, Observable, of, Subject } from 'rxjs';
import { PushNotification } from '@breez/modules/notification/models/push-notification.model';
import { filter, map, mapTo, switchMap, take, takeWhile } from 'rxjs/operators';
import { CallDestinationType, CallModel, CallStatus } from '@breez/modules/call/models';
import { NotificationData } from '@breez/modules/notification/models/notification-data.model';
import { scanAsync } from '@breez/shared/rxjs-operators/scanAsync';
import { StateService } from '@breez/shared/services/state.service';
import { TranslateService } from '@ngx-translate/core';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { takeThenCloseIf } from '@breez/shared/rxjs-operators/take-then-close-if';
import { AppService } from '@breez/app.service';
import { CALL_MODEL_CARRIER } from '@breez/modules/call/call-model-carrier.provider';
import { CallModelCarrier } from '@breez/modules/call/models/call-model-carrier.model';

@Injectable({ providedIn: 'root' })
export class CallNotificationManager {
  ringNotification$: Observable<PushNotification> = scanAsync(
    this.call.callModel$.pipe(
      takeThenCloseIf(callModel => {
        return callModel.status !== CallStatus.RING;
      })
    ),
    (notification, callModel) => {
      if (callModel.status === CallStatus.RING && callModel.destinationType === CallDestinationType.INCOMING) {
        if (!notification && !this.stateService.isAppFocused()) {
          return this.sendRingNotification(callModel);
        }
        return of(notification);
      }
      // otherwise
      if (notification) {
        notification.close();
        notification = null;
      }
      return of(notification);
    },
    <PushNotification>null
  ).pipe(filter(isTruthy));

  cancelNotification$: Observable<PushNotification> = scanAsync(
    this.call.callModel$.pipe(
      takeWhile(callModel => {
        return [CallStatus.RING, CallStatus.CANCEL].includes(callModel.status);
      })
    ),
    (notification, callModel) => {
      if (callModel.status === CallStatus.CANCEL && callModel.destinationType === CallDestinationType.INCOMING) {
        if (!notification && !this.stateService.isAppFocused()) {
          return this.sendMissedNotification(callModel);
        }
        return of(notification);
      }
      // otherwise
      if (notification) {
        notification.close();
        notification = null;
      }
      return of(notification);
    },
    <PushNotification>null
  ).pipe(filter(isTruthy));

  closing$ = new Subject<void>();

  constructor(
    @Inject(CALL_MODEL_CARRIER) public call: CallModelCarrier,
    private stateService: StateService,
    private pushNotificationService: PushNotificationService,
    private translateService: TranslateService,
    private appService: AppService
  ) {
    this.ringNotification$
      .pipe(
        switchMap(notification => {
          return merge(
            notification.onClick$.pipe(mapTo({ notification, result: true })),
            notification.onDismiss$.pipe(mapTo({ notification, result: false }))
          ).pipe(take(1));
        })
      )
      .subscribe(({ notification, result }) => {
        if (result) {
          window.focus();
        }
        notification.close();
      });

    this.cancelNotification$
      .pipe(
        switchMap(notification => {
          return merge(
            notification.onClick$.pipe(mapTo({ notification, result: true })),
            notification.onDismiss$.pipe(mapTo({ notification, result: false }))
          ).pipe(take(1));
        })
      )
      .subscribe(({ notification, result }) => {
        if (result) {
          window.focus();
          // TODO здесь нужно переводить юзера на страницу пропущенных звонков
        }
        notification.close();
      });
  }

  private sendRingNotification(callModel: CallModel): Observable<PushNotification> {
    return this.translateService
      .get(['INCOMING_CALL_PUSH_TITLE', 'INCOMING_CALL_PUSH_BODY'], { caller: callModel.caller.name })
      .pipe(
        map(labels => {
          const data: NotificationData = {
            token: `Call(${callModel.id})__Status`,
            title: labels.INCOMING_CALL_PUSH_TITLE,
            message: labels.INCOMING_CALL_PUSH_BODY,
            requireInteraction: true,
            iconUrl: this.appService.getLogoPngUrl()
          };
          return this.pushNotificationService.create(data);
        }),
        take(1)
      );
  }

  private sendMissedNotification(callModel: CallModel): Observable<PushNotification> {
    return this.translateService
      .get(['MISSED_CALL_PUSH_TITLE', 'MISSED_CALL_PUSH_BODY'], { caller: callModel.caller.name })
      .pipe(
        map(labels => {
          const data: NotificationData = {
            token: `Call(${callModel.id})__Status`,
            title: labels.MISSED_CALL_PUSH_TITLE,
            message: labels.MISSED_CALL_PUSH_BODY,
            requireInteraction: true,
            iconUrl: this.appService.getLogoPngUrl()
          };
          return this.pushNotificationService.create(data);
        })
      );
  }

  close(): void {
    this.closing$.next();
    this.closing$.complete();
  }
}
