import { Inject, Injectable } from '@angular/core';
import { DialogPosition, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ITemplate } from '@breez/models/template/template.model';
import { AuthService } from '@breez/modules/auth/services/auth.service';
import { CreateConferencePopupComponent } from '@breez/modules/conference/modules/planner/components/create-conference-popup/create-conference-popup.component';
import { CREATE_CONFERENCE_DIALOG_ID } from '@breez/modules/conference/modules/planner/services/create-conference-dialog/create-conefrence-dialog-id';
import { SimpleConferenceTemplateService } from '@breez/modules/conference/modules/planner/services/simple-conference-template/simple-conference-template.service';
import { replayWhileSubs } from '@breez/shared/rxjs-operators';
import { StateService } from '@breez/shared/services/state.service';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CreateConferenceDialogService {
  isMobileDevice$: Observable<boolean> = this.stateService.isMobileDevice$.pipe(replayWhileSubs());

  constructor(
    @Inject(CREATE_CONFERENCE_DIALOG_ID) private readonly dialogId: string,
    private readonly dialog: MatDialog,
    private readonly stateService: StateService,
    private readonly simpleConferenceTemplateService: SimpleConferenceTemplateService,
    private readonly authService: AuthService
  ) {}

  openCreateConferencePopup(): Observable<number> {
    return combineLatest([
      this.simpleConferenceTemplateService.simpleConferenceTemplate$(),
      this.getDialogConfig(),
      this.authService.roles$.pipe(take(1))
    ]).pipe(
      switchMap(([simpleConferenceTemplate, config, roles]) => {
        const data = CreateConferenceDialogService.changeTemplateControlsByRole(simpleConferenceTemplate, roles);
        this.stateService.setPopupOpened(true);
        return this.dialog
          .open(CreateConferencePopupComponent, {
            data,
            ...config
          })
          .afterClosed();
      }),
      tap(() => {
        return this.stateService.setPopupOpened(false);
      })
    );
  }

  private getDialogClassList(): Observable<string[]> {
    const dialogClassList = ['low-padding'];

    return this.isMobileDevice$.pipe(
      take(1),
      map(isMobileDevice => {
        if (isMobileDevice) {
          dialogClassList.push('full-width');
        }
        return dialogClassList;
      })
    );
  }

  private getDialogPosition(): Observable<DialogPosition> {
    return this.isMobileDevice$.pipe(
      take(1),
      map(isMobileDevice => {
        return { top: isMobileDevice ? null : '5px' };
      })
    );
  }

  private getDialogWidth(): Observable<string> {
    return this.isMobileDevice$.pipe(
      take(1),
      map(isMobileDevice => {
        return isMobileDevice ? null : '680px';
      })
    );
  }

  private getDialogConfig(): Observable<MatDialogConfig> {
    return combineLatest([this.getDialogClassList(), this.getDialogPosition(), this.getDialogWidth()]).pipe(
      map(([classList, position, width]) => {
        return <MatDialogConfig>{
          position,
          width,
          panelClass: classList,
          id: this.dialogId,
          disableClose: true,
          autoFocus: false
        };
      })
    );
  }

  private static changeTemplateControlsByRole(template: ITemplate, roles: string[]): ITemplate {
    if (!roles.includes('conferences:client-recording-visible')) {
      const index = template.controls.findIndex(control => {
        return control.key === 'allowclientrecording';
      });

      if (index > -1) {
        template.controls.splice(index, 1);
      }
    }
    return template;
  }
}
