import { Inject, Injectable, Injector } from '@angular/core';
import { AppService } from '@breez/app.service';
import { AuthService } from '@breez/modules/auth/services/auth.service';
import { Call } from '@breez/modules/call/call';
import { CALL_ID } from '@breez/modules/call/call-id.provider';
import { CALL_MODEL_CARRIER } from '@breez/modules/call/call-model-carrier.provider';
import { CallRingtoneManager } from '@breez/modules/call/call-ringtone-manager';
import { CURRENT_CALL_MODELS } from '@breez/modules/call/current-call-models.provider';
import { CallDestinationType, CallStatus } from '@breez/modules/call/models';
import { CallModel } from '@breez/modules/call/models/call.model';
import { CallService } from '@breez/modules/call/services/call.service';
import { WebsocketEvents, WebsocketService } from '@breez/modules/websocket';
import { distinctUntilChangedByJsonCompare, replayWhileSubs, toClass, toPlain } from '@breez/shared/rxjs-operators';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { combineLatest, merge, Observable, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  scan,
  shareReplay,
  startWith,
  switchMap,
  take,
  withLatestFrom
} from 'rxjs/operators';
import { CallNotificationManager } from '../call-notification-manager';
import { plainToInstance } from 'class-transformer';
import { WEBRTC_MEDIA_EFFECTS } from '@breez/modules/webrtc/call-media-effects-service.provider';
import { MediaEffectsService } from '@breez/modules/webrtc/services/media-effects.service';

@Injectable({
  providedIn: 'root'
})
export class CallsService {
  currentConferenceId$ = this.appService.currentConferenceEnterIdSubject$
    .pipe(startWith(null), replayWhileSubs())
    .pipe(debounceTime(5000), distinctUntilChanged(), replayWhileSubs());

  isThisTabEventInitiator$ = this.callService.isThisTabEventInitiator$;

  currentUser$ = this.authService.currentUser$;

  wasOffline$: Observable<boolean> = this.wsService.connectionWasLost$;

  wsStatus$: Observable<boolean> = this.wsService.status$.pipe(distinctUntilChanged(), replayWhileSubs());

  userLogout$: Observable<boolean> = this.authService.userLogout$;

  readonly isFnsBuild = this.appService.isFnsBuild;

  lastCallModel$ = this.wsService.on(WebsocketEvents.RECEIVE.CALL.STATUS).pipe(replayWhileSubs());

  // userLogoutProcess$:Observable<boolean> = this.authService.userLogoutProcess$

  // TODO Ошибка при разырве со стороны приниающей
  currentCallModels$: Observable<CallModel[]> = this.currentUser$.pipe(
    switchMap(() => {
      return this.authService.roles$;
    }),
    distinctUntilChangedByJsonCompare(),
    switchMap(roles => {
      return this.currentConferenceId$.pipe(
        map(conferenceId => {
          return { roles, conferenceId };
        })
      );
    }),
    switchMap(({ roles, conferenceId }) => {
      return combineLatest([this.wsStatus$, this.lastCallModel$]).pipe(
        filter(([_, _receivedCall]) => {
          const receivedCall = plainToInstance(CallModel, _receivedCall);
          const destinationType =
            this.appService.clientId === receivedCall.callerPeerId
              ? CallDestinationType.OUTGOING
              : CallDestinationType.INCOMING;

          if (
            destinationType === CallDestinationType.OUTGOING &&
            !roles.find(role => {
              return role === 'p2p:access';
            })
          ) {
            return false;
          }

          if (!receivedCall.isConferenceCall) {
            return true;
          }

          return (
            !this.isFnsBuild && // не фнс
            destinationType === CallDestinationType.INCOMING && // и входящий
            conferenceId !== receivedCall.conferenceId
          ); // и не текущая конфа
        }),
        scan((calls: CallModel[], [wsStatus, _receivedCall]) => {
          const receivedCall = plainToInstance(CallModel, _receivedCall);
          const existingCall = calls.find(call => {
            return call.id === receivedCall.id;
          });

          receivedCall.destinationType =
            this.appService.clientId === receivedCall.callerPeerId
              ? CallDestinationType.OUTGOING
              : CallDestinationType.INCOMING;

          if (!wsStatus || [CallStatus.CANCEL, CallStatus.DECLINE].includes(existingCall?.status)) {
            receivedCall.status =
              receivedCall.destinationType === CallDestinationType.OUTGOING ? CallStatus.CANCEL : CallStatus.DECLINE;
          }

          if (!existingCall) {
            return [...calls, receivedCall];
          }

          return calls.map(call => {
            return call.id === existingCall.id ? receivedCall : call;
          });
        }, [] as CallModel[])
      );
    }),
    startWith([]),
    shareReplay(1)
  );

  currentCalls$: Observable<Call[]> = combineLatest([this.wsStatus$, this.currentCallModels$]).pipe(
    withLatestFrom(this.isThisTabEventInitiator$),
    scan(
      (calls: Call[], [[wsStatus, callModels], isThisTabEventInitiator]) => {
        return callModels
          .map(callModel => {
            let call = calls.find(item => {
              return item.callId === callModel.id;
            });
            let anotherActiveCallId = null;
            const isEnded = [CallStatus.HANGUP, CallStatus.DECLINE, CallStatus.CANCEL].includes(callModel.status);
            if (!wsStatus) {
              callModel.status =
                callModel.destinationType === CallDestinationType.OUTGOING ? CallStatus.CANCEL : CallStatus.DECLINE;
            }

            if (!isEnded && !call) {
              const injector = Injector.create({
                parent: this.injector,
                providers: [
                  { provide: CALL_ID, useValue: callModel.id },
                  { provide: CURRENT_CALL_MODELS, useValue: this.currentCallModels$ },
                  { provide: Call, useClass: Call }
                ]
              });
              call = injector.get(Call);

              anotherActiveCallId = (
                callModels.find(model => {
                  return model.status === CallStatus.ACCEPT;
                }) || { id: null }
              ).id;
            }

            if (call) {
              this.callService.changeOverlayContainer(call, callModel, anotherActiveCallId, isThisTabEventInitiator);
              if (!wsStatus) {
                call.changeCall(callModel.status);
              }
            }

            if (isEnded) {
              call?.stopEffects();
              return null;
            }

            return call;
          })
          .filter(isTruthy);
      },
      <Call[]>[]
    ),
    shareReplay(1)
  );

  callNotificationManagers$: Observable<CallNotificationManager[]> = this.currentCalls$.pipe(
    scan(
      (managers, calls) => {
        managers
          .filter(manager => {
            return !calls.some(call => {
              return manager.call.callId === call.callId;
            });
          })
          .forEach(manager => {
            return manager.close();
          });
        return calls.map(call => {
          const manager = managers.find(manager_ => {
            return manager_.call.callId;
          });

          if (manager) {
            return manager;
          }

          return Injector.create({
            parent: this.injector,
            providers: [
              { provide: CALL_MODEL_CARRIER, useValue: call },
              { provide: CallNotificationManager, useClass: CallNotificationManager }
            ]
          }).get(CallNotificationManager);
        });
      },
      <CallNotificationManager[]>[]
    )
  );

  callRingtoneManagers$: Observable<CallRingtoneManager[]> = this.currentCalls$.pipe(
    scan(
      (managers, calls) => {
        managers
          .filter(manager => {
            return !calls.some(call => {
              return manager.call.callId === call.callId;
            });
          })
          .forEach(manager => {
            manager.close();
          });
        return calls.map(call => {
          const manager = managers.find(manager_ => {
            return manager_.call.callId;
          });
          if (manager) {
            return manager;
          }
          return Injector.create({
            parent: this.injector,
            providers: [
              { provide: CALL_MODEL_CARRIER, useValue: call },
              { provide: CallRingtoneManager, useClass: CallRingtoneManager }
            ]
          }).get(CallRingtoneManager);
        });
      },
      <CallRingtoneManager[]>[]
    )
  );

  constructor(
    private callService: CallService,
    private appService: AppService,
    private authService: AuthService,
    private wsService: WebsocketService,
    private injector: Injector,
    @Inject(WEBRTC_MEDIA_EFFECTS) private mediaEffectsService: MediaEffectsService
  ) {
    this.currentConferenceId$.subscribe();

    this.lastCallModel$.subscribe();
    this.currentCalls$.subscribe();
    this.callNotificationManagers$.subscribe();
    this.callRingtoneManagers$.subscribe();

    /*
		Завершение звонка в случае разрыва соединения или выхода пользователя
		 */
    merge(this.userLogout$, this.wasOffline$)
      .pipe(
        filter(action => {
          return !!action;
        }),
        withLatestFrom(this.currentCalls$),
        mergeMap(([, currentCalls]) => {
          if (currentCalls.length == 0) {
            return of(null);
          }

          return combineLatest(
            currentCalls.map(call => {
              return call.callModel$;
            })
          ).pipe(
            mergeMap(callModels => {
              return combineLatest(
                callModels.map(callModel => {
                  return this.deactivateCall(currentCalls, callModel);
                })
              );
            })
          );
        })
      )
      .subscribe();
  }

  private deactivateCall(calls: Call[], callModel: Pick<CallModel, 'id' | 'destinationType'>): Observable<boolean> {
    const call = calls.find(item => {
      return item.callId === callModel.id;
    });
    // звонок отсутствует, значит завершение не требуется
    if (!call) {
      return of(true);
    }

    return call.changeCall(
      callModel.destinationType == CallDestinationType.INCOMING ? CallStatus.DECLINE : CallStatus.CANCEL
    );
  }

  createCall(value: { answererId?: number; answererPeerId?: string }): Observable<Call> {
    const call = new CallModel(value);
    return of(call).pipe(
      toPlain(),
      switchMap(data => {
        return this.wsService.send(WebsocketEvents.SEND.WEBRTC.CALL, { data });
      }),
      toClass(CallModel),
      switchMap(callModel => {
        return this.getCallById(callModel.id);
      }),
      catchError(() => {
        return of(null);
      })
    );
  }

  getCallById(callId: number): Observable<Call> {
    return this.currentCalls$.pipe(
      map(calls => {
        return calls.find(call => {
          return call.callId === callId;
        });
      }),
      filter(isTruthy),
      take(1)
    );
  }
}
