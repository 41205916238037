import { TypedAction } from '@ngrx/store/src/models';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { SendToBack } from '@breez/models/+state/send-to-back.model';

interface ActionProps extends SendToBack {
  [key: string]: any;
}

export declare interface PropertiesActionObjectId {
  readonly objectId: string | number;
}

export declare interface PropertiesActionProcessing extends PropertiesActionObjectId {
  readonly processing?: boolean;
  readonly timeout?: number;
}

declare interface PropertiesActionProcessingSource extends PropertiesActionProcessing {
  readonly sourceAction: TypedAction<string>;
  readonly resultProp?: string;
}

export function propsProcessing<TParams extends ActionProps>(
  payload: TParams,
  sourceAction?: TypedAction<string>,
  resultProp?: string,
  processing = true,
  timeout = null
): (TParams & PropertiesActionProcessing) | (TParams & PropertiesActionProcessingSource) {
  if (isTruthy(payload.needSendToBackend) && payload.needSendToBackend === false) {
    processing = false;
  }
  let result = { ...payload, processing } as TParams & PropertiesActionProcessing;
  if (sourceAction) {
    result = { ...result, sourceAction } as TParams & PropertiesActionProcessingSource;
  }
  if (resultProp) {
    result = { ...result, resultProp } as TParams & PropertiesActionProcessingSource;
  }
  if (isTruthy(timeout)) {
    result = { ...result, timeout } as TParams & PropertiesActionProcessingSource;
  }
  return result;
}
