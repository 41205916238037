<div [ngStyle]="(settings$ | async).containerStyle" class="table-container">
  <table
    [class.hover]="(settings$ | async).hover"
    [dataSource]="dataSource"
    [ngStyle]="(settings$ | async).style"
    (matSortChange)="sortChange.emit($event)"
    mat-table
    matSort
    multiTemplateDataRows
  >
    <vks-table-columns
      *ngFor="let item of columns$ | async; trackBy: trackBy"
      [column]="item"
      [isAdvancedView]="isAdvancedView"
      [showPinIcon]="(settings$ | async).showPinIcon"
      (cellClick)="settingsSubject$.value?.cellClick($event)"
    >
    </vks-table-columns>
    <ng-container *ngFor="let column of expandedColumns$ | async">
      <ng-container [matColumnDef]="column.columnName">
        <td *matCellDef="let data" [colSpan]="columns.length / expandedColumns.length" mat-cell>
          <div [@expandRow]="data === expandedRow ? 'expanded' : 'collapsed'">
            <ng-container *ngTemplateOutlet="column.template; context: { data: data }"></ng-container>
          </div>
        </td>
      </ng-container>
    </ng-container>
    <tr *matHeaderRowDef="columnNames$ | async; sticky: (settings$ | async).stickyHeader" mat-header-row></tr>
    <tr
      *matRowDef="let row; columns: columnNames$ | async"
      [@nextRows]
      [class.expanded-row]="expandedRow === row"
      mat-row
      (click)="rowClickAction.emit(row)"
    ></tr>
    <tr
      *matRowDef="let row; columns: expandedColumnsNames$ | async"
      [class.additional-row-expanded]="row === expandedRow"
      class="additional-row"
      mat-row
    ></tr>
  </table>
  <!--Информационные сообщения-->
  <ng-container *ngIf="isFetching">
    <div class="message-container">
      <ng-container *ngTemplateOutlet="loading"></ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="!isFetching && (dataSource.data | isEmpty)">
    <div class="message-container">
      <ng-container *ngTemplateOutlet="emptyMessage"></ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="!isFetching && dataSource.filter && (dataSource.filteredData | isEmpty)">
    <div class="message-container">
      <ng-container *ngTemplateOutlet="nothingFoundMessage"></ng-container>
    </div>
  </ng-container>
</div>
<mat-paginator
  *ngIf="(settings$ | async)?.pagination"
  [pageSizeOptions]="(settings$ | async).pageSizeOptions"
  [length]="(paging$ | async).length"
  [pageIndex]="(paging$ | async).pageIndex"
  [pageSize]="(paging$ | async).pageSize"
  (page)="pageChange.emit($event)"
  style="bottom: 0; position: sticky; z-index: 10"
>
</mat-paginator>
<ng-template #loading>
  <div *ngIf="(settings$ | async).loader === 'text'" class="table-message stub middle">
    <p>
      {{ 'LOADING' | translate }}
    </p>
  </div>
  <mat-spinner *ngIf="(settings$ | async).loader === 'spinner'" diameter="48"></mat-spinner>
</ng-template>

<ng-template #empty>
  <div class="table-message stub middle">
    <p>
      {{ 'IS_EMPTY_TABLE' | translate }}
    </p>
  </div>
</ng-template>

<ng-template #nothingFound>
  <div class="table-message stub middle">
    <p>
      {{ 'NOTHING_FOUND' | translate }}
    </p>
  </div>
</ng-template>

<ng-template #chevronColumn let-row="data">
  <vks-icon-button (click)="expandedRow = expandedRow === row ? null : row">
    <vks-icon
      [class.expand-trigger-rotate]="expandedRow === row"
      class="square24 expand-trigger"
      icon="chevron-right"
    ></vks-icon>
  </vks-icon-button>
</ng-template>
