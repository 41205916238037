<h3>{{ 'BLOCK_CHAT' | translate }}</h3>
<mat-dialog-content>
  <form [formGroup]="blockForm">
    <div class="control-row">
      <div vks-control-group>
        <label [for]="banReasonInputId">{{ 'REASON' | translate }}</label>
        <input vks-cinput [id]="banReasonInputId" class="full-width" formControlName="reason" type="text" />
      </div>
      <div vks-control-group class="enumeration closer">
        <button
          *ngFor="let suggestion of suggestions$ | async"
          vks-button
          [label]="suggestion"
          (buttonClick)="setSuggestionToReasonInput(suggestion)"
          class="btn-anchor"
          type="button"
        ></button>
      </div>
    </div>
    <div class="control-row">
      <div vks-control-group>
        <label>{{ 'DURATION.default_duration' | translate }}</label>
        <mat-radio-group (change)="durationSet($event)">
          <mat-radio-button [checked]="true" [value]="false">{{ 'FOREVER' | translate }}</mat-radio-button>
          <mat-radio-button [value]="true">{{ 'FOR_A_WHILE' | translate }}</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="control-row">
      <div *ngIf="banForAWhile" vks-control-group>
        <label>{{ 'SET_DURATION' | translate }}</label>
        <vks-control-time [minutesItemsStep]="1" formControlName="duration" mode="input" utc="true"></vks-control-time>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button vks-button [label]="'BLOCK' | translate" (buttonClick)="blockChat()" class="btn-primary"></button>
  <button vks-button [label]="'CANCEL' | translate" (buttonClick)="cancel()" class="btn-anchor"></button>
</mat-dialog-actions>
