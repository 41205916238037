import { Injectable } from '@angular/core';
import { from, fromEvent, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { PushNotification } from './models/push-notification.model';
import { NotificationData } from '@breez/modules/notification/models/notification-data.model';
import { isTruthy } from '@breez/shared/utilities/is-truthy';

export class PushNotificationOptions {
  body: string;
  icon: string;
  sound: string;
  data: any;
  tag: string;
  dir = 'auto' as NotificationDirection;
  lang = 'en-US';
  renotify = false;
  sticky = false;
  vibrate: Array<number>;
  noscreen = false;
  silent = true;
}

@Injectable()
export class PushNotificationService {
  checkCompatibility(): boolean {
    return 'Notification' in window;
  }

  requestPermission(): Observable<boolean> {
    return from(Notification.requestPermission()).pipe(
      map(permission => {
        return permission === 'granted';
      }),
      take(1)
    );
  }

  public isPermissionGranted(): boolean {
    return Notification && Notification.permission === 'granted';
  }

  public isPermissionDenied(): boolean {
    return !Notification || Notification.permission === 'denied';
  }

  public create(data: NotificationData): PushNotification {
    if (!this.checkCompatibility()) {
      throw new Error('Notifications are not available in this browser.');
    }

    if (this.isPermissionDenied()) {
      return null;
    }

    if (!this.isPermissionGranted()) {
      this.requestPermission();
      return null;
    }

    const title = data.title;
    const body = data.subtitle ? `${data.subtitle}\n${data.message}` : data.message;
    const options: NotificationOptions & any = {
      body,
      icon: 'assets/icons/en/icon-128x128.png', // data.iconUrl,
      image: data.imageUrl,
      renotify: isTruthy(data.token),
      tag: data.token,
      requireInteraction: data.requireInteraction
    };

    const notification = new Notification(title, options);
    return <PushNotification>{
      data,
      onClick$: fromEvent(notification, 'click'),
      onDismiss$: fromEvent(notification, 'close'),
      onError$: fromEvent(notification, 'error'),
      onShow$: fromEvent(notification, 'show'),
      close: () => {
        return notification.close();
      }
    };
  }
}
