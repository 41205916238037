import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MediaSourceKind } from '@breez/models/webrtc/media-source-kind.enum';
import { MediaDevicesService } from '@breez/modules/webrtc/services/media-devices.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { UserMediaSourceBase } from '@breez/models/webrtc/media-source-base.model';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { Observable, timer } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { replayWhileSubs } from '@breez/shared/rxjs-operators';

@UntilDestroy()
@Component({
  selector: 'vks-user-devices-settings-pop-up',
  templateUrl: './user-devices-settings-pop-up.component.html',
  styleUrls: ['./user-devices-settings-pop-up.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserDevicesSettingsPopUpComponent implements OnInit {
  mediaSources: UserMediaSourceBase[] = [];
  setDefaultMedia: boolean;
  currentMedia: { audio: boolean; video: boolean; volume: boolean };
  effectsSdkSetting: { blur: boolean };

  timer$: Observable<boolean> = timer(3000, 5000).pipe(map(isTruthy), replayWhileSubs());
  blur: boolean;
  constructor(
    private readonly dialogRef: MatDialogRef<UserDevicesSettingsPopUpComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      setDefaultMedia: boolean;
      currentMedia: { audio: boolean; video: boolean; volume: boolean };
      effectsSdkSetting: { blur: boolean };
    },
    private mediaDevicesService: MediaDevicesService
  ) {
    this.dialogRef.disableClose = true;
  }

  applyAndClose(): void {
    const sources = this.mediaSources.filter(isTruthy);
    const mediaToggles = {
      video:
        this.mediaSources.find(source => {
          return source.kind === MediaSourceKind.VIDEO_INPUT;
        })?.enabled ?? false,
      audio:
        this.mediaSources.find(source => {
          return source.kind === MediaSourceKind.AUDIO_INPUT;
        })?.enabled ?? false,
      volume:
        this.mediaSources.find(source => {
          return source.kind === MediaSourceKind.AUDIO_OUTPUT;
        })?.enabled ?? false,
      screen: false
    };
    if (this.setDefaultMedia) {
      this.mediaDevicesService.setDefaultMediaSourcesIds(sources);
      if (isTruthy(this.effectsSdkSetting)) {
        this.mediaDevicesService.setVideoBlurSetting(this.effectsSdkSetting.blur ?? false);
      }
    }

    this.dialogRef.close({ sources, mediaToggles, effectsSdkSetting: this.effectsSdkSetting });
  }

  saveMediaSources(mediaSources: UserMediaSourceBase[]): void {
    this.mediaSources = mediaSources;
  }

  close(): void {
    if (!this.dialogRef.disableClose) {
      this.dialogRef.close(null);
    }
  }

  ngOnInit(): void {
    this.setDefaultMedia = this.data ? (this.data?.setDefaultMedia ?? true) : true;
    this.currentMedia = this.data ? this.data?.currentMedia : undefined;
    this.blur = this.data?.effectsSdkSetting?.blur;
    this.timer$.pipe(take(1)).subscribe(() => {
      this.dialogRef.disableClose = false;
    });
  }

  saveEffectsSdkSettings($event: { blur: boolean }): void {
    this.effectsSdkSetting = $event;
  }
}
