<span>{{ 'SHORTCUTS_LIST' | translate }}</span>
<mat-divider></mat-divider>
<table>
  <tbody>
    <tr *ngFor="let shortcut of shortcutsList$ | async" class="table-row">
      <td class="table-cell action">
        <span [matTooltipDisabled]="!shortcut.extra" [matTooltip]="shortcut.extra | translate">{{
          shortcut.action | translate
        }}</span>
      </td>
      <td class="table-cell keys">
        <kbd *ngFor="let key of shortcut.keysCombination">
          <span [innerHTML]="getKeyboardKeyCode(key) | safe: 'html'"></span>
        </kbd>
      </td>
    </tr>
  </tbody>
</table>
