import { ChangeDetectionStrategy, Component } from '@angular/core';
import { OverlayService } from '@breez/modules/overlay/services/overlay.service';
import { Observable, Subject } from 'rxjs';
import { OverlayContainer, OverlayEntry } from '@breez/modules/overlay/models';
import { map } from 'rxjs/operators';
import { OverlayPositionType } from '@breez/modules/overlay/models/overlay-position-type.enum';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { IOverlayContainer } from '@breez/modules/overlay/models/overlay-container.model';
import { OverlayEntryType } from '@breez/modules/overlay/models/overlay-entry-type.enum';
import { CdkDragEnd, CdkDragStart } from '@angular/cdk/drag-drop';

@Component({
  selector: 'vks-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('listAnimation', [
      transition('* <=> *', [
        query(':enter', [style({ opacity: 0 }), stagger(100, [animate('0.2s ease-out', style({ opacity: 1 }))])], {
          optional: true
        }),
        query(':leave', [style({ opacity: 1 }), animate('0.2s ease-out', style({ opacity: 0 }))], { optional: true })
      ])
    ]),
    trigger('fadeOut', [transition(':leave', animate('0.2s ease-out', style({ opacity: 0 })))])
  ]
})
export class OverlayComponent {
  private backdropClicked = new Subject<MouseEvent>();
  backdropClicked$: Observable<MouseEvent> = this.backdropClicked.asObservable();

  displayEntries$: Observable<OverlayEntry[]> = this.overlayService.overlayEntries$;

  displayContainers$: Observable<IOverlayContainer[]> = this.displayEntries$.pipe(
    map(entries => {
      return entries.reduce(
        (acc, entry) => {
          const positionEntries = acc.find(item => {
            return item.position === entry.position;
          });
          if (!positionEntries) {
            acc.push({
              position: entry.position,
              zIndex: entry.zIndex,
              noBackdrop: entry.noBackdrop,
              entries: [entry],
              draggable: entry.draggable || false
            });
            return acc;
          }
          positionEntries.entries.push(entry);
          return acc;
        },
        <Array<IOverlayContainer>>[]
      );
    }),
    map((resultEntries: IOverlayContainer[]) => {
      const overlays = resultEntries[0] ? resultEntries[0].entries : undefined;
      if (!overlays) {
        return resultEntries;
      }
      const thereIsACall = overlays.some(entry => {
        return entry.type === OverlayEntryType.CALL;
      });

      if (thereIsACall) {
        resultEntries[0].entries = resultEntries[0].entries.sort((a, b) => {
          if (a.type === OverlayEntryType.CALL || b.type === OverlayEntryType.CALL) {
            return -1;
          }
          return 0;
        });
      }
      return resultEntries;
    })
  );

  isDragging: boolean;

  constructor(private overlayService: OverlayService) {}

  handleClick(event: MouseEvent, containerElement: HTMLDivElement): void {
    if (event.target === containerElement) {
      this.backdropClicked.next(event);
    }
  }

  generateHostClass(position: OverlayPositionType): { [key: string]: boolean } {
    return { [position]: true };
  }

  onChangeOverlayContainer(entry: OverlayEntry, data: OverlayContainer): void {
    if (!!data && !!data.position) {
      if (!this.isDragging) {
        this.overlayService.changeOverlayContainer(entry, data);
      }
    } else {
      this.overlayService.removeContainer(entry);
    }
  }

  trackEntryFunction(_, entry: OverlayEntry): any {
    return entry.id;
  }

  trackContainerFunction(_, container: any): any {
    return container.position;
  }

  startDragging(_: CdkDragStart): void {
    this.isDragging = true;
  }

  stopDragging(_: CdkDragEnd): void {
    setTimeout(() => {
      return (this.isDragging = false);
    }, 100);
  }

  styleOverlay(container: IOverlayContainer): any {
    return {
      ...(container.zIndex >= 0 ? { 'z-index': container.zIndex } : {}),
      ...(container.noBackdrop ? { 'background-color': 'transparent' } : {})
    };
  }
}
