import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ValidationError } from './validation-error.model';

const ERROR = {
  type: 'serviceperiod',
  label: 'SERVICE_PERIOD_VALIDATION_FEEDBACK'
} as ValidationError;

export function createDisabledValidator(): ValidatorFn {
  const error = {
    ...ERROR,
    args: {
      startdate: null,
      starttime: null,
      enddate: null,
      endtime: null
    }
  };
  return (_: AbstractControl): ValidationErrors | null => {
    // TODO #alekssakovsky BREEZ-797: удалить валидатор createDisabledValidator

    // const servicePeriod = servicePeriod$.value;
    // if (!isTruthy(servicePeriod)) {
    return null;
    // }

    /*if (!servicePeriod.conferenceCreationBlocked) {
			return null;
		}*/

    /*error.args.startdate = datePipe.transform(servicePeriod.dateStart, 'shortDate');
		error.args.starttime = datePipe.transform(servicePeriod.dateStart, 'shortTime');
		error.args.enddate = servicePeriod.dateStart.getDate() !== servicePeriod.dateEnd.getDate()
			? datePipe.transform(servicePeriod.dateEnd, 'shortDate')
			: '';
		error.args.endtime = datePipe.transform(servicePeriod.dateEnd, 'shortTime');*/
    return { [error.type]: error } as ValidationErrors;
  };
}
