import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { encode } from 'html-entities';
import { ReplaceEmojisPipe } from '@breez/shared/pipes/replace-emojis.pipe';
import * as AdaptiveCards from 'adaptivecards';
import { AdaptiveCard, IMarkdownProcessingResult } from 'adaptivecards';
import * as MarkdownIt from 'markdown-it';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { MessageContentBlock } from '../interfaces';
import { Message } from '../models';
import { REGEXP_LINK } from '../consts';
import { MessageContentBlockEnum } from '../enums';
import { MessageAvatarEntityPipe } from '@breez/modules/chat/pipes/message-avatar-entity.pipe';
import { AvatarDisplayable } from '@breez/modules/user/modules/models/avatar-displayable.model';
import { MessageIsSystemPipe } from '@breez/modules/chat/pipes/message-is-system.pipe';
import { MessageIsForwardedPipe } from '@breez/modules/chat/pipes/message-is-forwarded.pipe';
import { MessageIsSomeDeliveredPipe } from '@breez/modules/chat/pipes/message-is-delivered.pipe';

const hljs = require('highlight.js');

const markdownIt = MarkdownIt({
  html: true,
  linkify: true,
  typographer: true,
  breaks: true,
  highlight: function (str, lang) {
    if (lang && hljs.getLanguage(lang)) {
      try {
        return hljs.highlight(str, { language: lang }).value;
      } catch {}
    }

    return ''; // use external default escaping
  }
})
  .use(require('markdown-it-highlightjs'), { inline: true, hljs })
  .use(require('markdown-it-sup'))
  .use(require('markdown-it-sub'))
  .use(require('markdown-it-footnote'))
  .use(require('markdown-it-deflist'))
  .use(require('markdown-it-abbr'))
  .use(require('markdown-it-emoji'))
  .use(require('markdown-it-ins'))
  .use(require('markdown-it-mark'))
  .use(require('markdown-it-hashtags'))
  .use(require('markdown-it-expand-tabs'))
  // .use(require('markdown-it-replace-link'), {
  // 	processHTML: true, // defaults to false for backwards compatibility
  // 	replaceLink: function (link, env, token, htmlToken) {
  // 		return "http://me.com/" + link;
  // 	}
  // })
  .use(require('markdown-it-task-lists'));

// markdownIt.use(require('markdown-it-container'), 'spoiler', {
//
// 	validate: function(params) {
// 		return params.trim().match(/^spoiler\s+(.*)$/);
// 	},
//
// 	render: function (tokens, idx) {
// 		var m = tokens[idx].info.trim().match(/^spoiler\s+(.*)$/);
//
// 		if (tokens[idx].nesting === 1) {
// 			// opening tag
// 			return '<details><summary>' + this.markdownIt.utils.escapeHtml(m[1]) + '</summary>\n';
//
// 		} else {
// 			// closing tag
// 			return '</details>\n';
// 		}
// 	}
// });

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  adaptiveCard: AdaptiveCard;

  constructor(
    private replaceEmojisPipe: ReplaceEmojisPipe,
    private translateService: TranslateService,
    private messageAvatarEntityPipe: MessageAvatarEntityPipe,
    private messageIsSystemPipe: MessageIsSystemPipe,
    private messageIsSomeDeliveredPipe: MessageIsSomeDeliveredPipe,
    private messageIsForwardedPipe: MessageIsForwardedPipe
  ) {
    this.adaptiveCard = new AdaptiveCards.AdaptiveCard();
    this.adaptiveCard.hostConfig = new AdaptiveCards.HostConfig({
      fontFamily: 'Segoe UI, Helvetica Neue, sans-serif'
    });
  }

  messageAvatarEntity(message: Partial<Message>): AvatarDisplayable {
    return this.messageAvatarEntityPipe.transform(message);
  }

  // TODO переименовать messageIs* и pipe
  messageIsSystem(message: Partial<Message>): boolean {
    return this.messageIsSystemPipe.transform(message);
  }

  messageIsSomeDelivered(message: Partial<Message>): boolean {
    return this.messageIsSomeDeliveredPipe.transform(message);
  }

  messageIsForwarded(message: Partial<Message>): boolean {
    return this.messageIsForwardedPipe.transform(message);
  }

  parseAdaptiveCard(text: string): MessageContentBlock {
    try {
      const formattedContent = JSON.parse(text);
      if (!formattedContent || !formattedContent.hasOwnProperty('type') || formattedContent.type !== 'AdaptiveCard') {
        return null;
      }

      return {
        typeContent: MessageContentBlockEnum.ADAPTIVE_CARD,
        formattedContent,
        content: text
      } as MessageContentBlock;
    } catch {
      return null;
    }
  }

  parseMarkdown(text: any, result: IMarkdownProcessingResult = null): MessageContentBlock {
    if (!result) {
      result = {} as IMarkdownProcessingResult;
    }

    // Экранируем все восклицательные знаки
    const escapedText = text.replace(/!/g, '\\!');
    const renderedText = markdownIt.render(escapedText);

    // Возвращаем восклицательные знаки, убрав экранирование
    result.outputHtml = renderedText.replace(/\\!/g, '!');
    result.didProcess = result.outputHtml !== text;

    return {
      typeContent: MessageContentBlockEnum.MARKDOWN,
      formattedContent: result.outputHtml,
      content: text
    } as MessageContentBlock;
  }

  parseMessage(
    text: string,
    translate = false,
    translateConfig?: any
  ): Observable<{ messageItems: MessageContentBlock[]; links?: MessageContentBlock[] }> {
    return (
      translate
        ? translateConfig
          ? this.translateService.get(text, translateConfig)
          : this.translateService.get(text)
        : of(text)
    ).pipe(
      map(messageText => {
        const adaptiveCard = this.parseAdaptiveCard(messageText);
        if (!!adaptiveCard) {
          return { messageItems: [adaptiveCard], links: [] };
        }
        const links =
          messageText.match(REGEXP_LINK)?.map(link => {
            return {
              content: link,
              typeContent: MessageContentBlockEnum.LINK,
              formattedContent: this.plainLinkToHTML(link)
            };
          }) ?? [];

        return { messageItems: [this.parseMarkdown(messageText)], links };
      })
    );
  }

  convertHtmlToPlainText$(text, translate = false, translateConfig?: any): Observable<string> {
    return this.parseMessage(text, translate, translateConfig).pipe(
      map(({ messageItems }) => {
        let markdownHtml = '';
        messageItems.forEach(parsed => {
          markdownHtml += parsed.formattedContent ?? parsed.content;
        });
        return markdownHtml;
      }),
      map(html => {
        const htmlElement = document.createElement('div');

        htmlElement.innerHTML = html;
        return encode(htmlElement.textContent || htmlElement.innerText);
      })
    );
  }

  messageForwardBySystem(message: Message): boolean {
    const metaForward = message.meta?.forward;
    const forwardUserId = metaForward?.userid;
    return forwardUserId ? isTruthy(forwardUserId) && forwardUserId === 1 : false;
  }

  convertMessageToPlainText$(message: Message): Observable<string> {
    const vars = message.meta?.var;
    return this.convertHtmlToPlainText$(
      message?.body,
      this.messageIsSystem(message) || this.messageForwardBySystem(message),
      vars ?? undefined
    );
  }

  plainLinkToHTML(link): string {
    return link.replace(REGEXP_LINK, foundUrl => {
      const includesProtocol = foundUrl.indexOf('http://') > -1 || foundUrl.indexOf('https://') > -1;
      const protocolLink = includesProtocol ? foundUrl : 'https://' + foundUrl;
      const linkTextContent = protocolLink.length >= 250 ? `${foundUrl.substring(0, 250)}...` : foundUrl;
      return `<a title="${protocolLink}" href="${protocolLink}" target="_blank">${linkTextContent}</a>`;
    });
  }

  fillNative(messageItems: MessageContentBlock[], messageBodyNativeElement: HTMLParagraphElement): void {
    if (!messageBodyNativeElement) {
      return;
    }

    messageBodyNativeElement.innerHTML = '';
    messageBodyNativeElement.innerText = '';
    messageItems.forEach(messageItem => {
      if (messageItem.typeContent === MessageContentBlockEnum.ADAPTIVE_CARD) {
        this.insertAdaptiveCard(messageItem, messageBodyNativeElement);
      } else {
        messageBodyNativeElement.insertAdjacentHTML(
          'beforeend',
          this.replaceEmojisPipe.emojisToImages(messageItem.formattedContent ?? messageItem.content ?? '')
        );
      }
    });
  }

  private insertAdaptiveCard(messageItem: MessageContentBlock, messageBodyNativeElement: HTMLParagraphElement): void {
    this.adaptiveCard.onExecuteAction = function (): void {
      alert('Ow!');
    };
    this.adaptiveCard.parse(messageItem.formattedContent ?? messageItem.content ?? '');

    AdaptiveCard.onProcessMarkdown = this.parseMarkdown;

    messageBodyNativeElement.appendChild(this.adaptiveCard.render());
  }
}
