<h2>{{ 'NOTIFICATION.' + notification.type | translate }}</h2>
<p [innerHTML]="notification.message | translate"></p>
<div class="actions">
  <button
    vks-button
    *ngIf="helpLink$ | async as helpLink"
    [label]="'HELP_BUTTON' | translate"
    (buttonClick)="openHelpLink(helpLink.url)"
    class="btn-anchor"
  ></button>
  <button vks-button [label]="'ACCEPT' | translate" (buttonClick)="onClose()" class="btn-primary"></button>
</div>
