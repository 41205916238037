import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { AvatarDisplayable } from '@breez/modules/user/modules/models/avatar-displayable.model';
import { Message } from '@breez/modules/chat';

@Pipe({
  name: 'messageAvatarEntity'
})
@Injectable({
  providedIn: 'root'
})
export class MessageAvatarEntityPipe implements PipeTransform {
  transform(message: Partial<Message>): AvatarDisplayable {
    if (message?.userId !== 1) {
      return message.sender?.user ?? { id: null, name: '...', avatarUrl: '' };
    } else {
      return { id: null, name: '', avatarUrl: '' };
    }
  }
}
