import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  private title = '';

  getActualTitle(moduleName: string, brand: string, notificationsCount: number, isPwaApplication = false): string {
    this.clearTitle();

    if (notificationsCount > 0) {
      this.setNotificationsCountToTitle(notificationsCount);
    }

    if (moduleName) {
      this.setCurrentAppModuleToTitle(moduleName);
      if (!isPwaApplication) {
        this.divideTitleAndBrandByHyphen();
      }
    }
    if (!isPwaApplication) {
      this.setAppBrandToTitle(brand);
    }

    return this.title;
  }

  private clearTitle(): void {
    this.title = '';
  }

  private setCurrentAppModuleToTitle(moduleName: string): void {
    this.title += `${moduleName} `;
  }

  private setNotificationsCountToTitle(notificationsCount: number): void {
    this.title += `(${notificationsCount}) `;
  }

  private setAppBrandToTitle(brand: string): void {
    this.title += brand;
  }

  private divideTitleAndBrandByHyphen(): void {
    this.title += '- ';
  }
}
