<vks-modal-header *ngIf="dialogRef" [title]="'AUTHORIZE' | translate" (close)="cancel()"></vks-modal-header>
<form [formGroup]="loginForm" (ngSubmit)="login()">
  <div class="control-row">
    <div *ngIf="isElectronApp" vks-control-group class="va-middle toggle">
      <label for="server">{{ 'SERVER' | translate }}</label>
      <input vks-cinput formControlName="wsServerUrl" id="server" type="url" />
    </div>

    <div vks-control-group class="va-middle toggle">
      <label class="no-user-select" for="login">{{ 'LOGIN' | translate }}</label>
      <input vks-cinput vks-auto-focus formControlName="login" id="login" type="text" autocapitalize="off" />
    </div>
    <div vks-control-group class="va-middle toggle password">
      <label class="no-user-select" for="password">{{ 'PASSWORD' | translate }}</label>
      <input
        vks-cinput
        [class.additional-padding]="passwordFormControl.value"
        [type]="isPasswordVisible ? 'text' : 'password'"
        formControlName="password"
        id="password"
      />
      <vks-icon
        *ngIf="passwordFormControl.value"
        [icon]="isPasswordVisible ? 'visibility-off' : 'visibility-on'"
        [matTooltip]="(isPasswordVisible ? 'HIDE_PASSWORD' : 'SHOW_PASSWORD') | translate"
        (click)="togglePasswordShow()"
        class="square24"
      ></vks-icon>
    </div>

    <div vks-control-group class="va-middle">
      <!--							[disabled]="!connection"-->
      <!--							-->
      <button
        vks-button
        [loading]="isLoading$ | async"
        [label]="'ENTER' | translate | uppercase"
        buttonType="submit"
        class="btn-primary"
      ></button>
    </div>
  </div>
</form>
