import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeText'
})
export class RemoveTextPipe implements PipeTransform {
  transform(value: string, ...args: string[]): string {
    return !args
      ? value
      : args.reduce((acc, curr) => {
          return acc.replace(curr, '');
        }, value);
  }
}
