import { NgModule } from '@angular/core';

import { NgxdModule } from '@ngxd/core';
import { CallStatusesModule } from '../call-statuses.module';
import { CallStatusDynamicComponent } from './dynamic.component';

@NgModule({
  imports: [NgxdModule, CallStatusesModule],
  declarations: [CallStatusDynamicComponent],
  exports: [CallStatusDynamicComponent]
})
export class CallDynamicModule {}
