import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { Call } from '@breez/modules/call/call';
import { CallDestinationType, CallModel, CallStatus } from '@breez/modules/call/models';
import { OverlayContainer } from '@breez/modules/overlay/models';

@Component({
  selector: 'vks-call-status-hold',
  templateUrl: './type.component.html',
  styleUrls: ['./type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CallStatusHoldComponent {
  @Input() callModel: CallModel;
  @Input() call: Call;

  @Output() changeOverlayContainer = new EventEmitter<OverlayContainer>();
  callStatus = CallStatus;
  callDestinationType = CallDestinationType;

  onChangeCall(status: CallStatus): void {
    this.call.changeCall(status).subscribe();
  }
}
