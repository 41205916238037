import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, take } from 'rxjs/operators';
import { UsersService } from '@breez/modules/users/services/users.service';
import * as UsersActions from '@breez/modules/users/+state/users/user.actions';
import { catchError, of } from 'rxjs';

@Injectable()
export class UserEffects {
  // noinspection JSUnusedGlobalSymbols
  loadUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UsersActions.loadUsers),
      mergeMap(action => {
        return this.usersService.getUsers().pipe(
          take(1),
          map(users => {
            return UsersActions.loadUsersSuccess(
              UsersActions.loadUsersSuccess({
                ...action,
                users
              })
            );
          }),
          catchError(errorMessage => {
            return of(
              UsersActions.loadUsersFailure({
                ...action,
                errorMessage
              })
            );
          })
        );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private usersService: UsersService
  ) {}
}
