import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { User } from '@breez/models';
import { AuthService } from '@breez/modules/auth/services/auth.service';
import { replayWhileSubs, toClass } from '@breez/shared/rxjs-operators';
import { ConfirmPopupService } from '@breez/shared/services/confirm-popup/confirm-popup.service';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { ChatService } from '../../services';
import { ChatParticipant, FoundChat } from '../../models';
import { CHAT_ID } from '@breez/modules/chat';
import { Store } from '@ngrx/store';
import * as ModuleState from '@breez/modules/chat/+state/module.state';
import * as ParticipantsSelectors from '@breez/modules/chat/+state/participants/participants.selectors';
import * as ParticipantsAction from '../../+state/participants/participants.actions';
import { ChatParticipantsEvent } from '@breez/models/chat/chat-participants-event';
import { guid } from '@breez/helpers/guid';
import * as ChatActions from '@breez/modules/chat/+state/chat/chat.actions';

@Component({
  selector: 'vks-chat-edit-form',
  templateUrl: './chat-participants.component.html',
  styleUrls: ['./chat-participants.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatParticipantsComponent {
  participants$: Observable<ChatParticipant[]> = this.store.select(ParticipantsSelectors.getParticipants(this.chatId));

  get isConferenceChat(): boolean {
    return false;
  }

  currentUser$: Observable<User> = this.authService.currentUser$.pipe(replayWhileSubs());
  currentUserId$: Observable<number> = this.currentUser$.pipe(
    map(currentUser => {
      return currentUser.id;
    }),
    replayWhileSubs()
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) private chatId: CHAT_ID,
    private store: Store<ModuleState.State>,
    private authService: AuthService,
    private openedDialog: MatDialogRef<ChatParticipantsComponent>,
    private chatService: ChatService,
    private router: Router,
    private confirmPopupService: ConfirmPopupService
  ) {}

  deleteUserFromParticipant(user: User): void {
    const usersToDelete = [user.id];
    const chatId = this.chatId;
    this.confirmPopupService
      .confirm({ question: 'REMOVE_USER_FROM_CHAT', action: 'YES' })
      .pipe(
        take(1),
        filter(approve => {
          return approve === true;
        })
      )
      .subscribe(() => {
        this.store.dispatch(
          ParticipantsAction.changeChatParticipants({
            chatId,
            participantsId: usersToDelete,
            status: ChatParticipantsEvent.REMOVED
          })
        );
      });
  }

  openChat(participantsId: number): void {
    //TODO переделать
    if (this.isConferenceChat) {
      return;
    }
    this.currentUserId$
      .pipe(
        switchMap(currentUserId => {
          if (participantsId === currentUserId) {
            this.openedDialog.close(false);
            return of(null);
          }

          return this.chatService.getChatByParticipantsAndConferenceIds([participantsId]).pipe(
            toClass(FoundChat),
            map(response => {
              return response.chat;
            }),
            map(foundChat => {
              if (!foundChat) {
                const tempId = guid();
                const participantIds = [currentUserId, participantsId];
                this.store.dispatch(ChatActions.createChat({ participantIds, tempId }));
                return tempId;
              }

              return foundChat.id;
            })
          );
        })
      )
      .subscribe(foundChatId => {
        if (foundChatId) {
          this.openedDialog.close(false);
          this.router.navigate(['/', 'chat'], { queryParams: { id: foundChatId } }).then();
        }
      });
  }

  openUserPicker(): void {
    this.openedDialog.close(true);
  }

  open($event: MouseEvent): void {
    $event.preventDefault();
  }
}
