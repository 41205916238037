import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { environment } from '@breez/environment';
import { MenuState } from '@breez/models/webrtc/menu-states.enum';
import { AuthService } from '@breez/modules/auth/services/auth.service';
import { BuildType } from '@breez/shared/enums/build-type.enum';
import {
  CURRENT_CHAT_ID_KEY,
  CONFERENCE_CURRENT_STATUS_KEY,
  StateService,
  CONFERENCE_CURRENT_PARAMS_KEY,
  CONFERENCE_RECORDS_CURRENT_PARAMS_KEY
} from '@breez/shared/services/state.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, interval, Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import * as uuid from 'uuid';
import { enumKey } from '@breez/shared/utilities/enumKey';
import { ConferenceState } from '@breez/models/conference/enums/conference-state.enum';
import { ElectronService } from '@breez/modules/core/services';
import { RestApiService } from '@breez/rest-api.service';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { Store } from '@ngrx/store';
import * as ModuleState from '@breez/+state/root.state';
import * as ChatUnreadMessageCountActions from '@breez/modules/chat/+state/chatUnreadMessage/chatUnreadMessage.actions';
import { replayWhileSubs } from '@breez/shared/rxjs-operators';

type CallbackFunctionForRouteParams = () => { [key: string]: string };

export interface MenuItem {
  id: string;
  label: string;
  labelAsIs?: boolean;
  expanded?: boolean;
  icon?: string;
  link?: any[];
  plainLink?: string;
  blankPage?: boolean;
  params?: { [key: string]: string } | CallbackFunctionForRouteParams;
  children?: MenuItem[];
  onlyAuth?: boolean;
  onlyAdmin?: boolean;
  onlyWithRoles?: string[];
  canBeWithRoles?: string[];
  badgeCount$?: Observable<number>;
  strongCompareRoute?: boolean;
  conferenceLinkParams?: boolean;
}

export const allConferencesChildMenuItem: MenuItem = {
  id: uuid.v4(),
  label: 'ALL',
  link: ['/', 'conference'],
  onlyWithRoles: ['conferences:access'],
  params: { status: enumKey(ConferenceState, ConferenceState.ALL) }
};

@UntilDestroy()
@Component({
  selector: 'vks-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuComponent implements OnInit, AfterViewInit {
  readonly isElectronApp: boolean = this.electronService.isElectron;
  readonly isFnsBuild = environment.buildType === BuildType.FNS;

  chatBadgeCount$ = new BehaviorSubject<number>(0);

  conferenceLinkParams(): any {
    const status = this?.stateService?.getFromLocal<string>(CONFERENCE_CURRENT_STATUS_KEY) ?? null;
    const params = this?.stateService?.getFromLocal<Params>(CONFERENCE_CURRENT_PARAMS_KEY) ?? null;
    return (
      params ? params : status ? { status: status } : { status: enumKey(ConferenceState, ConferenceState.ALL) }
    ) as { [key: string]: string };
  }

  conferenceRecordParams(): any {
    const params = this?.stateService?.getFromLocal<Params>(CONFERENCE_RECORDS_CURRENT_PARAMS_KEY) ?? null;
    return (params ? params : null) as { [key: string]: string };
  }

  chatLinkParams(): any {
    return { id: this?.stateService?.getFromLocal<string>(CURRENT_CHAT_ID_KEY) ?? null };
  }

  items: MenuItem[] = [
    {
      id: uuid.v4(),
      label: 'CALENDAR',
      icon: 'plan-conf',
      link: ['/', 'conference', 'calendar'],
      params: null,
      onlyAuth: true,
      canBeWithRoles: ['conferences:access']
    },
    {
      id: allConferencesChildMenuItem.id,
      label: 'CONFERENCES',
      icon: 'menu-conferences',
      expanded: false,
      onlyAuth: true,
      canBeWithRoles: ['conferences:access', 'planner:planner:view'],
      link: ['/', 'conference'],
      params: (): any => {
        return this.conferenceLinkParams();
      },
      conferenceLinkParams: true,
      strongCompareRoute: true
    },
    {
      id: uuid.v4(),
      label: 'RECORDS',
      icon: 'records',
      onlyAuth: true,
      onlyWithRoles: ['records:access'],
      link: ['/', 'records'],
      params: (): any => {
        return this.conferenceRecordParams();
      }
    },
    {
      id: uuid.v4(),
      label: 'CHATS',
      icon: 'global-chat',
      onlyWithRoles: ['chat:access'],
      link: ['/', 'chat'],
      params: (): any => {
        return this.chatLinkParams();
      },
      onlyAuth: true,
      badgeCount$: this.chatBadgeCount$
    },
    {
      id: uuid.v4(),
      label: 'ADDRESS_BOOK',
      icon: 'users',
      onlyWithRoles: ['contacts:access'],
      expanded: false,
      onlyAuth: true,
      link: ['/', 'social'],
      params: {
        group: 'allusers'
      }
    },
    {
      id: uuid.v4(),
      label: 'MONITORING',
      icon: 'menu-monitoring',
      link: ['/', 'statistics', 'dashboard'],
      params: { period: 'day' },
      onlyAuth: true,
      onlyWithRoles: ['planner:planner:add', 'logs:access']
    },
    {
      id: uuid.v4(),
      label: 'SETTINGS',
      icon: 'menu-settings',
      onlyWithRoles: ['settings:access'],
      onlyAuth: true,
      plainLink: `${this.origin}${environment.adminLink ?? ':8090/settings'}` // VKS_Client_WEB-1815
    }
  ];

  helpMenuItem: MenuItem = {
    id: uuid.v4(),
    label: 'HELP',
    expanded: true,
    icon: 'menu-help',
    children: []
  };

  currentUser$ = this.authService.currentUser$;

  menuItems$ = new ReplaySubject<MenuItem[]>(1);
  roles$ = this.authService.roles$;
  chatAccess$: Observable<boolean> = this.authService
    .checkRoles$(['chat:access'])
    .pipe(distinctUntilChanged(), replayWhileSubs());

  isCompactMenu$: Observable<boolean> = this.stateService.compactMenu$;
  isMediumContentSize$: Observable<boolean> = this.stateService.isMediumContentWidth$;
  isTabletDevice$: Observable<boolean> = this.stateService.isTabletDevice$;
  isMobileDevice$ = this.stateService.isMobileDevice$;

  helpLink$: Observable<string> = this.restApiService.info$.pipe(
    map(info => {
      return info.help;
    }),
    filter(isTruthy)
  );

  downloadLink$: Observable<string> = this.restApiService.info$.pipe(
    map(info => {
      return info?.client?.download;
    })
  );

  displayMenuItems$: Observable<MenuItem[]> = combineLatest([
    this.menuItems$,
    this.currentUser$,
    this.roles$,
    this.helpLink$,
    this.downloadLink$
  ]).pipe(
    map(([menuItems, currentUser, roles, helpLink, downloadLink]) => {
      if (!currentUser) {
        menuItems = menuItems
          .filter(item => {
            return !item.onlyAuth;
          })
          .map(item => {
            return {
              ...item,
              children: item.children
                ? item.children.filter(childItem => {
                    return !childItem.onlyAuth;
                  })
                : []
            };
          });
      }
      menuItems = menuItems
        .filter(item => {
          return item.onlyWithRoles
            ? !item.onlyWithRoles
                .map(roleName => {
                  return roles.some(role => {
                    return role === roleName;
                  });
                })
                .some(has => {
                  return !has;
                })
            : true;
        })
        .map(item => {
          return {
            ...item,
            children: item.children
              ? item.children.filter(child => {
                  return child.onlyWithRoles
                    ? !child.onlyWithRoles
                        .map(roleName => {
                          return roles.some(role => {
                            return role === roleName;
                          });
                        })
                        .some(has => {
                          return !has;
                        })
                    : true;
                })
              : []
          };
        });

      menuItems = menuItems
        .filter(item => {
          return item.canBeWithRoles
            ? item.canBeWithRoles.some(roleName => {
                return roles.some(role => {
                  return role === roleName;
                });
              })
            : true;
        })
        .map(item => {
          return {
            ...item,
            children: item.children
              ? item.children.filter(child => {
                  return child.onlyWithRoles
                    ? !child.onlyWithRoles
                        .map(roleName => {
                          return roles.some(role => {
                            return role === roleName;
                          });
                        })
                        .some(has => {
                          return !has;
                        })
                    : true;
                })
              : []
          };
        });

      if (helpLink) {
        if (currentUser) {
          this.helpMenuItem.children = [
            <MenuItem>{
              id: uuid.v4(),
              label: 'HELP_AND_SUPPORT',
              plainLink: helpLink,
              link: this.isFnsBuild ? ['/help'] : null,
              blankPage: true
            }
          ];
          if (!this.isFnsBuild) {
            this.helpMenuItem?.children?.push(<MenuItem>{
              id: uuid.v4(),
              label: 'DEMO.NAME',
              link: ['/', 'conference', 'demo'],
              blankPage: true
            });
          }
          if (isTruthy(downloadLink)) {
            this.helpMenuItem?.children?.push(<MenuItem>{
              id: uuid.v4(),
              label: 'DOWNLOAD_APP',
              plainLink: downloadLink,
              blankPage: true
            });
          }
        } else {
          this.helpMenuItem.children = [
            <MenuItem>{
              id: uuid.v4(),
              label: 'HELP_AND_SUPPORT',
              plainLink: this.isFnsBuild ? ['/help'] : helpLink,
              blankPage: true,
              children: null,
              icon: 'menu-help'
            }
          ];
          if (isTruthy(downloadLink)) {
            this.helpMenuItem?.children?.push(<MenuItem>{
              id: uuid.v4(),
              label: 'DOWNLOAD_APP',
              plainLink: downloadLink,
              blankPage: true
            });
          }
        }
      }

      return menuItems.filter(item => {
        return item.link || item.plainLink || (item.children && item.children.length > 0);
      });
    })
  );

  private isOpenedTroughMouseMoving = false;

  private lastItemId: string = null;

  constructor(
    private store: Store<ModuleState.RootState>,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private stateService: StateService,
    @Inject('ORIGIN') public origin,
    private electronService: ElectronService,
    private changeDetectorRef: ChangeDetectorRef,
    private restApiService: RestApiService
  ) {}

  ngOnInit(): void {
    this.menuItems$.next(this.items);

    this.isCompactMenu$
      .pipe(withLatestFrom(this.displayMenuItems$), untilDestroyed(this))
      .subscribe(([isCompact, items]) => {
        if (isCompact) {
          items
            .filter(item => {
              return item.children && item.children.length > 0;
            })
            .forEach(item => {
              return this.expandMenuItems(item, false);
            });
        } else if (!this.isOpenedTroughMouseMoving) {
          items
            .filter(item => {
              return item.children && item.children.length > 0;
            })
            .forEach(item => {
              return this.expandMenuItems(item, true);
            });
        }
        this.changeDetectorRef.detectChanges();
      });
  }

  toggleItemMenu(item: MenuItem, menuTrigger?: MatMenuTrigger): void {
    combineLatest([this.isTabletDevice$, this.isCompactMenu$])
      .pipe(take(1))
      .subscribe(([isTabletDevice, isCompactMenu]) => {
        if (isTabletDevice && menuTrigger) {
          menuTrigger.openMenu();
        } else if (!isCompactMenu && !item.expanded) {
          item.expanded = !item.expanded;
        }
      });
  }

  changeItemMenuState(item: MenuItem): void {
    item.expanded = !item.expanded;
  }

  toggleCompactMenu(): void {
    this.isMediumContentSize$.pipe(take(1)).subscribe(isMedium => {
      if (isMedium) {
        this.stateService.showCompactMenu();
      } else {
        if (this.stateService.menuState === MenuState.LARGE) {
          this.stateService.showLargeMenu();
        } else if (this.stateService.menuState === MenuState.COMPACT) {
          this.stateService.showCompactMenu();
        }
      }
    });
  }

  enterMenuItem(item: MenuItem): void {
    this.lastItemId = item.id;
  }

  leaveMenuItem(item: MenuItem): void {
    interval(500)
      .pipe(take(1))
      .subscribe(() => {
        if (this.lastItemId === item.id) {
          return;
        }
      });

    if (this.lastItemId === item.id) {
      this.lastItemId = null;
    }
  }

  expandMenuItems(item: MenuItem, expand: boolean): void {
    if (!item.children) {
      return;
    }

    const menuItem = this.items.find(i => {
      return i.id === item.id;
    });
    if (menuItem) {
      menuItem.expanded = expand;
      this.menuItems$.next(this.items);
    }
  }

  isLinkActive(_: { [key: string]: string }, link: any[], strongCompare = false): Observable<boolean> {
    return this.activatedRoute.queryParams.pipe(
      untilDestroyed(this),
      map(routeParams => {
        const routerUrl = this.router.url;
        const routeParamsKeys = Object.keys(routeParams);

        if (routeParamsKeys.length === 0) {
          return strongCompare
            ? link.join('/').substr(1) === routerUrl
            : routerUrl.indexOf(link.join('/').substr(1)) === 0;
        }
        const paramsIndex = routerUrl.indexOf('?');
        const routerLinkWithoutParams = paramsIndex === -1 ? this.router.url : this.router.url.slice(0, paramsIndex);

        return link.join('/').substr(1) === routerLinkWithoutParams;
      })
    );
  }

  isChildLinkActive(parentMenuItem: MenuItem): Observable<boolean> {
    const linksState = parentMenuItem.children.map(childItem => {
      return this.isLinkActive(this.getParams(childItem.params), childItem.link, childItem.strongCompareRoute ?? false);
    });
    return combineLatest([this.isCompactMenu$, ...linksState]).pipe(
      map(([isCompactMenu, ...states]) => {
        if (!isCompactMenu) {
          return false;
        }
        return states.some(state => {
          return state;
        });
      })
    );
  }

  openLinkIfFns(menuItem: MenuItem): void {
    if (this.isFnsBuild && menuItem.label === 'HELP_AND_SUPPORT') {
      const menuItemLink = menuItem.link;
      const linkAsString = Array.isArray(menuItemLink) ? menuItemLink.join('') : menuItemLink;

      this.stateService.goToUrl(linkAsString, this.getParams(menuItem.params));
    }
  }

  getParams(params: any): any {
    return params instanceof Function ? params() : params;
  }

  shouldHaveRouterLink(menuItem: MenuItem): boolean {
    if (menuItem.label !== 'HELP_AND_SUPPORT') {
      return true;
    }
    return !this.isFnsBuild;
  }

  ngAfterViewInit(): void {
    this.stateService.isAppVisible$
      .pipe(
        switchMap(() => {
          return this.store.select(unreadChats => {
            return unreadChats.chatUnreadMessageCount.ids;
          });
        }),
        untilDestroyed(this)
      )
      .pipe(
        map(unreadChatsCount => {
          return unreadChatsCount.length ?? 0;
        })
      )
      .subscribe(unreadChats => {
        if (this.stateService.isPwaApplication()) {
          (navigator as any).setAppBadge(unreadChats);
        }

        this.chatBadgeCount$.next(unreadChats);
        this.stateService.notificationsCount$.next(unreadChats);
      });

    this.chatAccess$
      .pipe(
        filter(access => {
          return access === true;
        })
      )
      .subscribe(() => {
        this.store.dispatch(ChatUnreadMessageCountActions.loadUnreadMessages({}));
      });
  }
}
