import { Pipe, PipeTransform } from '@angular/core';
import { encode } from 'html-entities';

@Pipe({
  name: 'encodeHTML'
})
export class EncodeHTMLPipe implements PipeTransform {
  transform(value: string): string {
    return encode(value);
  }
}
