import { MonoTypeOperatorFunction, pipe } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

export function distinctUntilChangedByJsonCompare<T>(): MonoTypeOperatorFunction<T> {
  return pipe(
    distinctUntilChanged((x: T, y: T) => {
      return JSON.stringify(x) === JSON.stringify(y);
    })
  );
}
