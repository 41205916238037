import { Expose } from 'class-transformer';
import { CHAT_ID } from '../types';

export class UnreadMessagesCount {
  @Expose({ name: 'chatid' })
  chatId: CHAT_ID;

  @Expose({ name: 'count' })
  baseCount?: number; // оставляем для чатов конференции

  @Expose({ name: 'ids' })
  unreadIds = [];

  constructor(base?: Partial<UnreadMessagesCount>) {
    return Object.assign(this, base);
  }
}
