import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { CHAT_ID } from '@breez/modules/chat/types';
import { ChatEntity } from '@breez/modules/chat/models/+state/chatEntity';
import { sortChatsByOrderThenSortByLastMessageDateThenSortByName } from '@breez/modules/chat';

interface State extends EntityState<ChatEntity> {
  isLoad?: boolean;
  isConference?: number;
  selectedId?: CHAT_ID; // which Chat record has been selected
}

const adapter: EntityAdapter<ChatEntity> = createEntityAdapter<ChatEntity>({
  // @ts-ignore
  selectId: (chat: ChatEntity) => {
    return chat.id;
  },
  sortComparer: sortChatsByOrderThenSortByLastMessageDateThenSortByName
});

const initialState: State = adapter.getInitialState({
  selectedId: null
});

export { State, adapter, initialState };
