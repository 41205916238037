import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { COMPONENTS } from './components';
import { OVERLAY_PROVIDERS } from './overlay-provider';
import { ControlsModule } from '@breez/modules/controls/controls.module';
import { PipesModule } from '@breez/shared/pipes/pipes.module';
import { UserAvatarModule } from '@breez/modules/user/user-avatar/user-avatar.module';

@NgModule({
  imports: [CommonModule, TranslateModule, MatTooltipModule, ControlsModule, PipesModule, UserAvatarModule],
  declarations: [COMPONENTS],
  providers: [...OVERLAY_PROVIDERS]
})
export class NotificationInformationModule {}
