import { Account } from '@breez/shared/models/account.model';

interface State {
  account: Account;
}

const initialState: State = {
  account: null
};

export { State, initialState };
