import { Pipe, PipeTransform } from '@angular/core';
import { ConferenceState } from '@breez/models/conference/enums/conference-state.enum';

@Pipe({
  name: 'conferenceState'
})
export class ConferenceStatePipe implements PipeTransform {
  transform(value: number): string {
    return ConferenceState[value] ? `CONFERENCE_STATUS.${ConferenceState[value]}` : String(value);
  }
}
