<button
  vks-button
  vks-debounce-click
  (debounceClick)="toggle()"
  [icon]="(isBlurEnable$ | async) ? 'background-replace' : 'person'"
  [matTooltip]="((isBlurEnable$ | async) ? 'BLUR_ENABLED' : 'BLUR_DISABLED') | translate"
  class="icon-only mini btn-basic"
  color="white"
  iconClass="square24"
></button>
