import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  QueryList,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import { StateService } from '@breez/shared/services/state.service';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { MenuItem } from '@breez/components/menu/menu.component';
import { EmitOnChange } from '@breez/shared/utilities/decorators/emit-on-change.decorator';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@breez/modules/auth/services/auth.service';
import { distinctUntilChangedByJsonCompare, replayWhileSubs } from '@breez/shared/rxjs-operators';
import { CdkScrollable } from '@angular/cdk/overlay';

@UntilDestroy()
@Component({
  selector: 'vks-layout',
  templateUrl: './layout.component.html',
  styleUrls: [
    './layout.component.scss',
    './layout.component.media-min521-max975.scss',
    './layout.component.media-max975.scss'
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutComponent implements AfterViewInit, OnDestroy, OnChanges {
  @Input() linkTabs: MenuItem[];

  @ViewChildren('linkTabsElement') linkTabsElement: QueryList<ElementRef>;

  @ViewChildren(CdkScrollable) cdkScrollable: QueryList<CdkScrollable>;

  @HostBinding('style.--flex-direction')
  @Input()
  flexDirection: string = 'row';

  @EmitOnChange<MenuItem[], MenuItem[]>('linkTabs', {
    onlyTruthy: true,
    emitter: (value, subject) => {
      if (value.length) {
        subject.next(value);
      }
    }
  })
  tabs$: ReplaySubject<MenuItem[]> = new ReplaySubject();

  activeRoles$ = this.authService.roles$;

  availableTabs$: Observable<any> = combineLatest([this.activeRoles$, this.tabs$]).pipe(
    map(([activeRoles, tabs]) => {
      return tabs
        .filter(item => {
          return item.onlyWithRoles
            ? !item.onlyWithRoles
                .map(roleName => {
                  return activeRoles.some(role => {
                    return role === roleName;
                  });
                })
                .some(has => {
                  return !has;
                })
            : true;
        })
        .filter(item => {
          return item.canBeWithRoles
            ? item.canBeWithRoles.some(roleName => {
                return activeRoles.some(role => {
                  return role === roleName;
                });
              })
            : true;
        });
    }),
    replayWhileSubs(),
    distinctUntilChangedByJsonCompare()
  );

  tabsActivate$: Observable<boolean> = this.availableTabs$.pipe(
    map(tabs => {
      return !!tabs && tabs.length >= 2;
    })
  );

  isMobileDevice$: Observable<boolean> = this.stateService.isMobileDevice$;
  isTabletDevice$: Observable<boolean> = this.stateService.isTabletDevice$;

  activeTabIndexSubject$: ReplaySubject<number> = new ReplaySubject<number>();

  activeTabIndex$ = this.activeTabIndexSubject$.pipe(distinctUntilChanged());

  constructor(
    private stateService: StateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnChanges(_: SimpleChanges): void {}

  ngAfterViewInit(): void {
    let firstScroll = true;
    this.activeTabIndex$.pipe(debounceTime(100), untilDestroyed(this)).subscribe(activeTabIndex => {
      const target = this.linkTabsElement.get(activeTabIndex)?.nativeElement;
      if (target) {
        this.cdkScrollable.first.scrollTo({
          left: Math.max(target.offsetLeft - target.offsetWidth / 3, 0),
          behavior: firstScroll ? 'auto' : 'smooth'
        });
        firstScroll = false;
      }
    });
  }

  isLinkActive(linkParam: { [key: string]: string }, link: any[], index): Observable<boolean> {
    return this.activatedRoute.queryParams.pipe(
      untilDestroyed(this),
      map(routeParams => {
        const routerUrl = this.router.url;
        const routeParamsKeys = Object.keys(routeParams);

        if (routeParamsKeys.length === 0) {
          return linkParam ? false : link.join('/').substr(1) === routerUrl;
        } else {
          if (!linkParam) {
            return false;
          }
          const paramsIndex = routerUrl.indexOf('?');
          const routerLinkWithoutParams = paramsIndex === -1 ? this.router.url : this.router.url.slice(0, paramsIndex);
          if (link.join('/').substr(1) === routerLinkWithoutParams) {
            return !Object.keys(linkParam)
              .map(key => {
                return (routeParams[key] ?? '').toString() === (linkParam[key] ?? '').toString();
              })
              .includes(false);
          }
        }
      }),
      tap(isActive => {
        if (isActive) {
          this.activeTabIndexSubject$.next(index);
        }
      })
    );
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy(): void {}
}
