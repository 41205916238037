import { NgModule } from '@angular/core';

import { NgxdModule } from '@ngxd/core';
import { OverlayEntryTypeModule } from '@breez/modules/overlay/overlay-types/overlay-entry-type.module';
import { OverlayEntryDynamicComponent } from '@breez/modules/overlay/overlay-types/dynamic/dynamic.component';

@NgModule({
  imports: [NgxdModule, OverlayEntryTypeModule],
  declarations: [OverlayEntryDynamicComponent],
  exports: [OverlayEntryDynamicComponent]
})
export class OverlayDynamicModule {}
