import { createAction, props } from '@ngrx/store';
import { Message } from '../../models';
import { MESSAGE_ID } from '../../types';
import { ErrorMessageModel } from '@breez/models/+state';
import {
  PropertiesActionObjectId,
  PropertiesActionProcessing,
  propsProcessing
} from '@breez/helpers/+state/execution-action.helper';
import {
  LoadMessagesAction,
  LoadMessagesActionResult
} from '@breez/modules/chat/interfaces/+state/load-messages-action';
import { ChatSendMessageAction } from '@breez/modules/chat/interfaces/+state/chat-send-message-action';
import { ChatAddMessageToStateAction } from '@breez/modules/chat/interfaces/+state/chat-add-message-to-state-action';
import { ChatDeleteMessageAction } from '@breez/modules/chat/interfaces/+state/chat-delete-message-action';

export const loadMessages = createAction(
  '[Chat/API] Load messages',
  propsProcessing<LoadMessagesAction & Partial<PropertiesActionObjectId>>
);

export const loadMessagesSuccess = createAction(
  '[Chat/API] Load messages Success',
  (params: LoadMessagesActionResult) => {
    return propsProcessing<LoadMessagesActionResult>(params, loadMessages, 'messages');
  }
);

export const loadMessagesFailure = createAction(
  '[Chat/API] Load messages Failure',
  (params: ErrorMessageModel & LoadMessagesAction) => {
    return propsProcessing<ErrorMessageModel & LoadMessagesAction>(params, loadMessages);
  }
);

export const sendMessage = createAction(
  '[Chat/API] Send message',
  propsProcessing<ChatSendMessageAction & Partial<PropertiesActionProcessing>>
);

export const sendMessageSuccess = createAction('[Chat/API] Send message Success', (params: ChatSendMessageAction) => {
  return propsProcessing<ChatSendMessageAction>(params, sendMessage, 'message', true);
});

export const sendMessageFailure = createAction(
  '[Chat/API] Send message Failure',
  (params: ErrorMessageModel & ChatSendMessageAction) => {
    return propsProcessing<ErrorMessageModel & ChatSendMessageAction>(params, sendMessage);
  }
);

export const deleteMessage = createAction(
  '[Chat/API] Delete message',
  propsProcessing<ChatDeleteMessageAction & Partial<PropertiesActionObjectId>>
);

export const deleteMessageSuccess = createAction(
  '[Chat/API] Delete message Success',
  (params: ChatDeleteMessageAction) => {
    return propsProcessing<ChatDeleteMessageAction>(params, deleteMessage, 'messageId');
  }
);

export const deleteMessageFailure = createAction(
  '[Chat/API] Delete message Failure',
  (params: ErrorMessageModel & ChatDeleteMessageAction) => {
    return propsProcessing<ErrorMessageModel & ChatDeleteMessageAction>(params, deleteMessage);
  }
);

export const addMessageToState = createAction('[Chat] Add message to state', props<ChatAddMessageToStateAction>());

export const removeMessageFromState = createAction(
  '[Chat] Remove message from state',
  props<{ message: Message; messageId?: MESSAGE_ID }>()
);

export const updateMessageInStateWaitExist = createAction(
  '[Chat] Update message in state, wait for exist',
  props<{ messageId: MESSAGE_ID; partialMessage: Partial<Message> }>()
);

export const updateMessageInState = createAction(
  '[Chat] Update message in state',
  props<{ messageId: MESSAGE_ID; partialMessage: Partial<Message> }>()
);
