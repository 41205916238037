import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as ModuleState from '@breez/modules/chat/+state/module.state';
import { CHAT_FEATURE_KEY } from '@breez/modules/chat/+state';
import { CHAT_ID, generateChatParticipantsFromUsers } from '@breez/modules/chat';
import { usersByChatId } from '@breez/modules/users/+state/users';
import { USER_ID } from '@breez/modules/users/types/user-id.type';
import { isTruthy } from '@breez/shared/utilities/is-truthy';

export const getFeatureState = createFeatureSelector<ModuleState.State>(CHAT_FEATURE_KEY);
export const getState = createSelector(getFeatureState, state => {
  return state.participants;
});

// export const participantIdsByChat = selectAll;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getParticipantsEntity = (chatId: CHAT_ID) => {
  return createSelector(getState, state => {
    return state.entities[chatId]?.participants ?? [];
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const isChatBan = (chatId: CHAT_ID, participantId: USER_ID) => {
  return createSelector(getState, state => {
    const current = new Date();
    const bans = (state.entities[chatId]?.bannedUsers ?? []).filter(bannedUser => {
      return bannedUser.id === participantId && (!isTruthy(bannedUser.expired) || bannedUser.expired > current);
    });
    return bans.length > 0;
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const chatBan = (chatId: CHAT_ID, participantId: USER_ID) => {
  return createSelector(getState, state => {
    const current = new Date();
    const bans = (state.entities[chatId]?.bannedUsers ?? []).filter(bannedUser => {
      return bannedUser.id === participantId && (!isTruthy(bannedUser.expired) || bannedUser.expired > current);
    });
    return bans[bans.length - 1] ?? null;
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getCountOfParticipants = (chatId: CHAT_ID) => {
  return createSelector(getState, state => {
    return state.entities[chatId]?.participants.length ?? 0;
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getParticipants = (chatId: CHAT_ID) => {
  return createSelector(usersByChatId(chatId), users => {
    return generateChatParticipantsFromUsers(users);
  });
};
