<div
  [class.inline-labels]="inlineLabels"
  [matTooltip]="getTooltipContent() | translate"
  [style.cursor]="conferenceState === 2 ? 'pointer' : 'default'"
  (click)="onChangeDirection()"
  class="timer"
>
  <div *ngIf="showDay$ | async" class="group">
    <span class="digits">{{ daysLeft$ | async | pad: 2 }}</span>
    <span *ngIf="showLabels" class="label">{{ daysLeftLabel$ | async }}</span>
  </div>
  <div *ngIf="showHour$ | async" class="group">
    <span class="digits">{{ hoursLeft$ | async | pad: 2 }}</span>
    <span *ngIf="showLabels && !inlineLabels" class="label">{{ hoursLeftLabel$ | async }}</span>
  </div>
  <div *ngIf="showHour$ | async" class="group no-width">
    <span class="digits">:</span>
  </div>
  <div *ngIf="showMinute$ | async" class="group">
    <span class="digits">{{ minutesLeft$ | async | pad: 2 }}</span>
    <span *ngIf="showLabels && !inlineLabels" class="label">{{ minutesLeftLabel$ | async }}</span>
  </div>
  <div *ngIf="showMinute$ | async" class="group no-width">
    <span class="digits">:</span>
  </div>
  <div *ngIf="showSecond$ | async" class="group">
    <span class="digits">{{ secondsLeft$ | async | pad: 2 }}</span>
    <span *ngIf="showLabels && !inlineLabels" class="label">{{ secondsLeftLabel$ | async }}</span>
  </div>
</div>
