import { Expose } from 'class-transformer';
import { DivisionTypeEnum } from '@breez/models/division.enum';

export class Division {
  @Expose({ name: 'divisionname' })
  name: string;

  @Expose({ name: 'divisiontype' })
  divisionType: DivisionTypeEnum;

  @Expose({ name: 'divisionid' })
  id: number;

  @Expose({ name: 'divisionorder' })
  order: number;

  @Expose()
  key: number;

  @Expose({ name: 'pkey' })
  parentKey: number;

  @Expose({ name: 'level' })
  currentLevelFromRoot: number;
}
