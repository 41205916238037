<ng-container *ngIf="files.length > 1">
  <div (click)="previous()" class="turn previous clickable">
    <vks-icon color="white" icon="image-prev"></vks-icon>
  </div>
  <div (click)="next()" class="turn next clickable">
    <vks-icon color="white" icon="image-next"></vks-icon>
  </div>
</ng-container>
<ng-container *ngIf="files[selectedFileIndex] as selectedFile">
  <img
    [style.transform]="'scale(' + scale + ')'"
    [alt]="selectedFile.name"
    [src]="selectedFile.previewContentUrl"
    (click)="next()"
    class="clickable"
  />
</ng-container>
<ng-container *ngIf="files[selectedFileIndex] as selectedFile">
  <div class="header">
    <div class="file-name">{{ selectedFile.name }}</div>
    <div class="file-order">{{ selectedFileIndex + 1 }} / {{ files.length }}</div>
    <div class="actions">
      <div
        matTooltipPosition="above"
        matTooltipClass="tooltip-overlay"
        [matTooltip]="'ZOOM_OUT' | translate"
        (click)="scale = scale - 0.1"
        class="action clickable"
      >
        <vks-icon class="clickable square16" color="white" hoverColor="white" icon="zoom-out"></vks-icon>
      </div>
      <div
        matTooltipPosition="above"
        matTooltipClass="tooltip-overlay"
        [matTooltip]="'ZOOM_IN' | translate"
        (click)="scale = scale + 0.1"
        class="action clickable"
      >
        <vks-icon class="clickable square16" color="white" hoverColor="white" icon="zoom-in"></vks-icon>
      </div>

      <a [download]="selectedFile.name" [href]="selectedFile.contentUrl | safe: 'url'">
        <div
          [matTooltip]="'DOWNLOAD_IMAGE' | translate"
          matTooltipPosition="above"
          matTooltipClass="tooltip-overlay"
          class="action clickable"
        >
          <vks-icon class="clickable square16" color="white" hoverColor="white" icon="download"></vks-icon>
        </div>
      </a>
      <div
        *ngIf="showCopyToClipboard"
        matTooltipPosition="above"
        matTooltipClass="tooltip-overlay"
        [matTooltip]="'COPY_TO_CLIPBOARD' | translate"
        (click)="copyToClipboard()"
        class="action clickable"
      >
        <vks-icon class="clickable square16" color="white" hoverColor="white" icon="copy"></vks-icon>
      </div>
      <div (click)="close()" class="action clickable">
        <vks-icon class="clickable square16" color="white" hoverColor="white" icon="x-12"></vks-icon>
      </div>
    </div>
  </div>
</ng-container>
<div class="board" (click)="close()"></div>
