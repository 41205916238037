import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'vks-module-header',
  templateUrl: './module-header.component.html',
  styleUrls: ['./module-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModuleHeaderComponent {
  @Input() title: string;

  @Output() hideHeader = new EventEmitter();
  @Output() pin = new EventEmitter();
}
