<div *ngIf="displayRipples$ | async" class="overlay column">
  <!--	<vks-icon icon="ripple-anim"></vks-icon>-->
  <!--	<vks-icon icon="camera-bg"></vks-icon>-->
  <span>{{ 'CONNECTION_WAITING' | translate }}</span>
</div>

<video
  #videoStream
  [class.fullscreen]="isFullScreen$ | async"
  [class.paused]="displayControl$ | async"
  (click)="playVideo()"
  autoplay
  class="player"
  playsinline
>
  Your browser doesn't support video
</video>
<div *ngIf="waitingForConnection$ | async" class="overlay column">
  <span>{{ 'CONNECTION_PREPARED' | translate }}</span>
</div>

<div *ngIf="isConferenceWaiting$ | async" class="info-box overlay column">
  <vks-icon [class.square64]="true" color="white" icon="meeting" class="icon"> </vks-icon>
  <p
    *ngxPermissionsOnly="['OPERATOR::' + (callId$ | async)]"
    [innerHTML]="'START_THE_CONFERENCE_IN_BOX' | translate | safe: 'html'"
    style="text-align: center"
  ></p>
  <p
    *ngxPermissionsExcept="['OPERATOR::' + (callId$ | async)]"
    [innerHTML]="'WILL_BEGIN_SOON' | translate | safe: 'html'"
    style="text-align: center"
  ></p>
  <button
    *ngxPermissionsOnly="['OPERATOR::' + (callId$ | async)]"
    [label]="'START_CONFERENCE' | translate"
    (buttonClick)="clickButtonBox()"
    vks-button
    class="btn-primary"
  ></button>
</div>

<div *ngIf="isOffline$ | async" class="overlay">
  <div class="message">
    <vks-icon icon="connection-lost"></vks-icon>
    <span>{{ 'CONNECTION_LOST' | translate }}</span>
  </div>
</div>
<div *ngIf="displayControl$ | async" (click)="playVideo()" class="overlay clickable">
  <vks-icon icon="play-bg"></vks-icon>
</div>
<!--<div *ngIf="iceNegotiationError$ | async" class="conference-message">-->
<!---->
<vks-webrtc-error-message
  *ngIf="(isConferenceWaiting$ | async | not) && (iceNegotiationError$ | async)"
  [headerText]="'ICE_NEGOTIATION_ERROR_HEADER'"
  [contentText]="'ICE_NEGOTIATION_ERROR'"
  [actionText]="'RETRY'"
  (action)="reconnectWebRtc()"
  (closeAction)="skipIceNegotiationError()"
></vks-webrtc-error-message>

<vks-webrtc-error-message
  *ngIf="(isConferenceWaiting$ | async | not) && (rtpCriticalError$ | async)"
  [headerText]="'RTP_CRITICAL_ERROR_HEADER'"
  [contentText]="'RTP_CRITICAL_ERROR'"
  [actionText]="'RECONNECT'"
  (action)="reconnectWebRtc()"
  (closeAction)="skipRtpCriticalError()"
></vks-webrtc-error-message>

<div
  *ngIf="(isConferenceWaiting$ | async | not) && (rtpCriticalErrorAlert$ | async)"
  class="overlay column connection-errors"
>
  <div class="message">
    <vks-icon icon="connection-lost"></vks-icon>
    <span>{{ 'RTP_CRITICAL_ERROR_ALERT' | translate }}</span>
  </div>
</div>
<pre *ngIf="displayStats" style="position: absolute; top: 0; left: 0; background: rgba(255, 255, 255, 0.4)">{{
  rtcConnectionStats$ | async | json
}}</pre>
