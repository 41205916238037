import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';

@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {
  constructor(private readonly sanitizer: DomSanitizer) {}

  transform(value: any, context: string): SafeHtml | SafeResourceUrl | SafeScript | SafeStyle | SafeUrl {
    if (value === null || value === undefined) {
      return;
    }

    switch (context) {
      case 'html': {
        this.sanitizer.sanitize(SecurityContext.HTML, value);
        return this.sanitizer.bypassSecurityTrustHtml(value);
      }
      case 'resourceUrl': {
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      }
      case 'script': {
        this.sanitizer.sanitize(SecurityContext.SCRIPT, value);
        return this.sanitizer.bypassSecurityTrustScript(value);
      }
      case 'style': {
        this.sanitizer.sanitize(SecurityContext.STYLE, value);
        return this.sanitizer.bypassSecurityTrustStyle(value);
      }
      case 'url': {
        this.sanitizer.sanitize(SecurityContext.URL, value);
        return this.sanitizer.bypassSecurityTrustUrl(value);
      }
    }
  }
}
