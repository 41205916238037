import { Chat, ChatParticipant, Message } from '../models';
import { User } from '@breez/models';
import { ChatEntity } from '@breez/modules/chat/models/+state/chatEntity';
import { AvatarDisplayable } from '@breez/modules/user/modules/models/avatar-displayable.model';
import { CONFERENCE_CUSTOM_CHAT_NAME_PLACEHOLDER, ConferenceChatTitleEnum } from '@breez/modules/chat';
import { isTruthy } from '@breez/shared/utilities/is-truthy';

export const EMPTY_CHAT_ENTITY: ChatEntity = {
  name: '',
  id: '',
  avatarUrl: '',
  conferenceId: null,
  mute: null,
  createdOn: null,
  callId: null,
  conferenceChatType: null,
  lastMessage: null,
  hasMessagesUpdates: null,
  pinnedOrder: null,
  unreadMessagesCount: null
};

function sortChatsByOrder(chatA: Chat, chatB: Chat): number {
  const chatAOrder = chatA.pinnedOrder || Number.MAX_SAFE_INTEGER;
  const chatBOrder = chatB.pinnedOrder || Number.MAX_SAFE_INTEGER;

  return chatAOrder - chatBOrder;
}

function sortChatsByLastMessageDate(chatA: Chat, chatB: Chat): number {
  const getDate = (chat: Chat): Date => {
    return chat.lastAction ?? (chat.lastMessage ? chat.lastMessage.sentDate : chat.createdOn);
  };
  if (!getDate(chatA)) {
    return 1;
  }
  if (!getDate(chatB)) {
    return -1;
  }
  return getDate(chatB)?.getTime() - getDate(chatA)?.getTime();
}

function sortDivisionsByTitleAlphabetically(chatA: Chat, chatB: Chat): number {
  if (chatA.pinnedOrder === null && chatB.pinnedOrder === null) {
    return (chatA.name || '').localeCompare(chatB.name || '');
  }
  return 0;
}

export function sortChatsByOrderThenSortByLastMessageDateThenSortByName(chatA: Chat, chatB: Chat): number {
  return (
    sortChatsByOrder(chatA, chatB) ||
    sortChatsByLastMessageDate(chatA, chatB) ||
    sortDivisionsByTitleAlphabetically(chatA, chatB)
  );
}

export function changeChatMute(chat: Chat | ChatEntity, mute: boolean): Chat | ChatEntity {
  chat.mute = mute;
  return chat;
}

export function generateChatParticipantsFromUsers(data: User | User[], _?: Chat): ChatParticipant[] {
  // todo by Chat model, add blocked, status итд
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const generateChatParticipant = user => {
    return new ChatParticipant({
      userId: user ? user.id : null,
      user
    });
  };
  if (data instanceof User) {
    return [generateChatParticipant(data)];
  } else if (data instanceof Array) {
    return data.map(user => {
      return generateChatParticipant(user);
    });
  } else {
    return undefined;
  }
}

// TODO TRANSFORM PIPE
export function getAvatarEntity(
  chat: ChatEntity | Chat,
  chatParticipants: ChatParticipant[],
  currentUser?: User
): AvatarDisplayable {
  // console.log(chat);
  // console.log(chatParticipants);
  // console.log(currentUser);
  if (!isTruthy(chat)) {
    chat = { ...EMPTY_CHAT_ENTITY, name: '...' };
  }
  function getChatInterlocutors(currentUserId: number): ChatParticipant[] {
    return chatParticipants.filter(chatParticipant => {
      return chatParticipant.userId !== currentUserId;
    });
  }

  if (chat.id === -1) {
    return <AvatarDisplayable>{
      name: chat.name,
      avatarUrl: chat.avatarUrl
    };
  }

  if (!chatParticipants) {
    return chat;
  }

  if (
    chat.name &&
    chat.name !== CONFERENCE_CUSTOM_CHAT_NAME_PLACEHOLDER &&
    (!isTruthy(chat.conferenceId) || chat.name in ConferenceChatTitleEnum)
  ) {
    return <AvatarDisplayable>{
      name: chat.name,
      avatarUrl: chat.avatarUrl
    };
  }
  const interlocutors = getChatInterlocutors(currentUser?.id).sort((a, b) => {
    return a.user?.name?.localeCompare(b.user?.name);
  });

  if (interlocutors.length === 1) {
    return interlocutors[0].user;
  }

  if (
    (!chat.name || chat.name === CONFERENCE_CUSTOM_CHAT_NAME_PLACEHOLDER || isTruthy(chat?.conferenceId)) &&
    chat.name !== ConferenceChatTitleEnum.MAIN_CHAT
  ) {
    const currentInterlocutors = interlocutors.length > 3 ? interlocutors.slice(0, 3) : interlocutors;
    const currentInterlocutorsName = currentInterlocutors
      .filter(interlocutor => {
        return !!interlocutor.user?.name;
      })
      .map(interlocutor => {
        return interlocutor.user?.name;
      })
      .join(', ');
    const chatName = interlocutors.length > 3 ? currentInterlocutorsName.concat(', ...') : currentInterlocutorsName;
    return <AvatarDisplayable>{
      name: chatName,
      avatarUrl: chat.avatarUrl
    };
  }
  return Object.assign({}, chat);
}

export function isChatPin(chat: ChatEntity | Chat): boolean {
  return isTruthy(chat.pinnedOrder) && chat.pinnedOrder > 0;
}

export function isChatMute(chat: ChatEntity | Chat): boolean {
  return chat.mute || false;
}

export const EMPTY_MESSAGE = (): Message => {
  return new Message({ body: '...', sentDate: new Date() });
};

export const concatMessages = (oldMessage, partialMessage): Message => {
  let base = { ...oldMessage, ...partialMessage };
  if (isTruthy(partialMessage.deliveredUserIds)) {
    const deliveredUserIds = [...new Set([...oldMessage.deliveredUserIds, ...partialMessage.deliveredUserIds])];
    base = { ...base, deliveredUserIds };
  }
  if (isTruthy(partialMessage.readUserIds)) {
    const readUserIds = [...new Set([...oldMessage.readUserIds, ...partialMessage.readUserIds])];
    base = { ...base, readUserIds };
  }
  return new Message(base);
};
