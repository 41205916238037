import { OverlayConfig } from '@angular/cdk/overlay';

export const DEFAULT_OVERLAY_CONFIG: OverlayConfig = {
  hasBackdrop: true
};

export const ignoredErrors = [
  'TypeError: Cannot read properties of undefined',
  "DOMException: Failed to execute 'requestPictureInPicture' on 'HTMLVideoElement'",
  'DOMException: screen.orientation.lock() is not available on this device.',
  'DOMException: The play() request was interrupted'
];
