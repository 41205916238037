import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import '@khmyznikov/pwa-install';
import {
  IS_USER_HIDE_PWA_INSTALLER_COMPONENT,
  PushNotificationsService
} from '@breez/shared/push-service/push-notifications.service';
import { take } from 'rxjs/operators';

const TEXT = {
  // перевод в библиотеке работает плохо, пришлось вынести отдельно
  ru: {
    BREEZ: 'Бриз',
    INSTALL_PWA_APP: 'Установка мобильного приложения',
    INSTALL_PWA_DESCRIPTION:
      'Оставайтесь на связи, где бы вы ни были. Выполните следующие шаги для установки мобильного приложения на ваше устройство.',
    INSTALL_PWA_DESCRIPTION_FAIL: 'Извините, установка не прошла успешно, попробуйте перезагрузить приложение'
  },
  en: {
    BREEZ: 'Breez',
    INSTALL_PWA_APP: 'Mobile app installation',
    INSTALL_PWA_DESCRIPTION:
      'Stay connected from any location. Follow these steps to install the mobile app on your device.',
    INSTALL_PWA_DESCRIPTION_FAIL: 'Sorry, installation was not successful, try restarting the application'
  }
};

@Component({
  selector: 'pwa-install-component',
  template: ` <pwa-install
    #pwaInstall
    icon="/assets/icons/en/icon-384x384.png"
    manual-apple="true"
    manifest-url="/manifest.webmanifest"
    name="{{ appName }}"
    description="{{ installPwa }}"
    install-description="{{ installDescription }}"
  ></pwa-install>`,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PwaInstallComponent implements OnInit, AfterViewInit {
  readonly appName: string;
  readonly installPwa: string;
  readonly installDescription: string;
  private readonly installPwaFail: string;
  @ViewChild('pwaInstall') private readonly pwaInstall!: ElementRef;

  constructor(private pwaService: PushNotificationsService) {
    const russian = navigator.language.includes('ru');
    this.appName = russian ? TEXT.ru.BREEZ : TEXT.en.BREEZ;
    this.installPwa = russian ? TEXT.ru.INSTALL_PWA_APP : TEXT.en.INSTALL_PWA_APP;
    this.installDescription = russian ? TEXT.ru.INSTALL_PWA_DESCRIPTION : TEXT.en.INSTALL_PWA_DESCRIPTION;
    this.installPwaFail = russian ? TEXT.ru.INSTALL_PWA_DESCRIPTION_FAIL : TEXT.en.INSTALL_PWA_DESCRIPTION_FAIL;
  }

  ngOnInit(): void {
    this.pwaService.showPwaInstallComponent$.pipe(take(1)).subscribe(() => {
      this.pwaInstall.nativeElement.showDialog();
    });
  }

  ngAfterViewInit(): void {
    this.pwaInstall.nativeElement.hideDialog();
    this.pwaInstall.nativeElement.externalPromptEvent = (<any>window).promptEvent;
    this.pwaInstall.nativeElement.addEventListener(
      'pwa-user-choice-result-event',
      () => {
        localStorage.setItem(IS_USER_HIDE_PWA_INSTALLER_COMPONENT, 'true');
      },
      { once: true }
    );
    this.pwaInstall.nativeElement.addEventListener(
      'pwa-install-fail-event',
      () => {
        localStorage.setItem(IS_USER_HIDE_PWA_INSTALLER_COMPONENT, 'null');
        alert(this.installPwaFail);
      },
      { once: true }
    );
  }
}
