import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { IAbstractControl } from '@breez/models/template/template.model';

export function buildForm(schema: IAbstractControl[]): UntypedFormGroup {
  const formControls = {};
  schema.forEach(control => {
    if (control.type === 'step' || control.type === 'datetime') {
      formControls[control.key] = new UntypedFormGroup({});
    } else {
      formControls[control.key] = new UntypedFormControl(null);
    }
  });
  return new UntypedFormGroup(formControls);
}
