<button
  *ngFor="let action of inlineActions"
  vks-button
  [class.alt]="action?.altIconStyle"
  [class.btn-compressed]="iconsCompressed"
  [disabled]="action?.disabled"
  [iconClass]="action.iconClass"
  [icon]="action.icon"
  [matTooltip]="action?.label | translate"
  (buttonClick)="action?.action()"
  class="btn-basic"
></button>
<button
  *ngIf="!hideIcon"
  vks-button
  [class.btn-compressed]="iconsCompressed"
  [color]="contextMenuIconColor"
  [disabled]="disabled || (underContextMenuActions?.length === 0 && !asyncContextActions)"
  [icon]="contextMenuIcon"
  [iconClass]="contextMenuIconClass"
  [matMenuTriggerFor]="userMenu"
  [matMenuTriggerRestoreFocus]="false"
  (menuClosed)="onContextMenuClosed()"
  (menuOpened)="onContextMenuOpened()"
  class="btn-basic"
></button>
<mat-menu #userMenu="matMenu" xPosition="before">
  <div class="context-menu-item">
    <ng-container *ngFor="let control of underContextMenuActions">
      <button
        class="context-menu-text"
        *ngxPermissionsOnly="control.accessRight"
        (click)="triggerAction($event, control)"
        mat-menu-item
        translate
      >
        <vks-icon *ngIf="hasIcons" [icon]="control?.icon" class="square20" color="black"></vks-icon>
        <span *ngIf="control.translatingLabel; else noTranslate">{{ control?.translatingLabel | translate }}</span>
        <ng-template #noTranslate>
          <span>{{ control?.label | translate }}</span>
        </ng-template>
      </button>
    </ng-container>
  </div>
</mat-menu>
