import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ValidationError } from './validation-error.model';

const ERROR = {
  type: 'maxlength',
  label: 'MAX_LENGTH'
} as ValidationError;

/**
 * args {requiredLength: 5, actualLength: 7}
 */
// TODO: Нужна ли разработка валидатора?
export function maxLengthValidator(): ValidatorFn {
  return (_: AbstractControl): ValidationErrors | null => {
    return { [ERROR.type]: ERROR } as ValidationErrors;
  };
}

export const maxLengthErrorType = ERROR;
