import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ViewParticipantRightModel } from '@breez/models/conference/view-participant-right.model';
import { Participant } from '@breez/models/shared/participant/participant.model';

class DialogData {
  participant: Participant;
  availableRights: ViewParticipantRightModel[];
  title?: string;
}

@Component({
  selector: 'vks-participant-rights-selector',
  templateUrl: './participant-rights-selector.component.html',
  styleUrls: ['./participant-rights-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParticipantRightsSelectorComponent implements OnInit {
  title: string;
  participant: Participant;
  assignedRights: ViewParticipantRightModel[];
  availableRights: ViewParticipantRightModel[] = [];

  constructor(
    private dialogRef: MatDialogRef<ParticipantRightsSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogInputData: DialogData
  ) {}

  ngOnInit(): void {
    this.participant = this.dialogInputData.participant;
    this.availableRights = this.dialogInputData.availableRights;
    if (!this.participant.rights) {
      this.participant.rights = [];
    }
    this.assignedRights = this.participant.rights ? this.participant.rights.slice(0) : [];
    this.title = this.dialogInputData.title;
  }

  hasRight(right: ViewParticipantRightModel): boolean {
    const uRight = this.assignedRights.find(uRight_ => {
      return uRight_.key === right.key;
    });
    return uRight && uRight.value;
  }

  toggleRight(right: ViewParticipantRightModel, checked: boolean): void {
    const uRight = this.assignedRights.find(pRight => {
      return pRight.key === right.key;
    });

    if (!checked && !uRight) {
      return;
    }

    if (!checked && uRight) {
      this.assignedRights = this.assignedRights.filter(pRights => {
        return pRights !== uRight;
      });
      return;
    }

    if (checked && uRight) {
      uRight.value = true;
      return;
    }

    this.assignedRights.push({ ...right, value: true });
  }

  cancel(): void {
    this.dialogRef.close();
  }

  resolve(): void {
    this.participant.rights = this.assignedRights;
    this.dialogRef.close(this.participant);
  }
}
