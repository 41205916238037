import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProcessingFile } from '@breez/models/shared/files/processing-file.model';
import { ImageCropperComponent } from '@breez/shared/components/image-cropper';
import { MatDialog } from '@angular/material/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { map } from 'rxjs/operators';
import { ImageSize } from '@breez/models/shared/image-size.model';

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  constructor(
    private dialog: MatDialog,
    private overlay: Overlay
  ) {}

  cropImage(imageFile: ProcessingFile, ratio: number, resizeTo: ImageSize): Observable<ProcessingFile> {
    const dialog = this.dialog.open(ImageCropperComponent, {
      data: { file: imageFile, ratio, resizeTo },
      width: '820px',
      height: '80%',
      scrollStrategy: this.overlay.scrollStrategies.reposition()
    });

    return dialog.afterClosed().pipe(
      map(processingFile => {
        return processingFile || null;
      })
    );
  }
}
