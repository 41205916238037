import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DurationFormatPipe } from './duration-format.pipe';
import { EmptyWrapperPipe } from './empty-wrapper.pipe';
import { ExistInArrayPipe } from './exist-in-array/exist-in-array.pipe';
import { FormatDatePipe } from './format-date.pipe';
import { IsEmptyPipe } from './is-empty.pipe';
import { IsPipe } from './is.pipe';
import { NestedFieldsPipe } from './nested-field-pipe/nested-fields.pipe';
import { NotPipe } from './not.pipe';
import { PadPipe } from './pad.pipe';

import { SanitizeUrlPipe } from './sanitize-url.pipe';
import { SimplifyNumberPipe } from './simplify-number/simplify-number.pipe';
import { BypassSecurityStylePipe } from './security/bypass-security-style.pipe';
import { RoundPipe } from './round.pipe';
import { SafePipe } from './security/safe.pipe';
import { TimeDurationFormatPipe } from './time-duration-format.pipe';
import { HumanizeDatePipe } from './humanize-date.pipe';
import { FileSizePipe } from './file-size/file-size.pipe';
import { DurationPipe } from './duration.pipe';
import { TranslatedDeclensionPipe } from './translated-declension.pipe';
import { ReplaceEmojisPipe } from './replace-emojis.pipe';
import { EncodeHTMLPipe } from './encode-html.pipe';
import { ConferenceStatePipe } from './conference-state.pipe';
import { RemoveTextPipe } from './replace-text.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    SanitizeUrlPipe,
    PadPipe,
    IsPipe,
    RoundPipe,
    NotPipe,
    FormatDatePipe,
    IsEmptyPipe,
    DurationFormatPipe,
    EmptyWrapperPipe,
    NestedFieldsPipe,
    ExistInArrayPipe,
    SimplifyNumberPipe,
    BypassSecurityStylePipe,
    RoundPipe,
    SafePipe,
    TimeDurationFormatPipe,
    HumanizeDatePipe,
    FileSizePipe,
    DurationPipe,
    TranslatedDeclensionPipe,
    ReplaceEmojisPipe,
    EncodeHTMLPipe,
    ConferenceStatePipe,
    RemoveTextPipe
  ],
  exports: [
    SanitizeUrlPipe,
    PadPipe,
    IsPipe,
    NotPipe,
    FormatDatePipe,
    IsEmptyPipe,
    DurationFormatPipe,
    EmptyWrapperPipe,
    NestedFieldsPipe,
    ExistInArrayPipe,
    SimplifyNumberPipe,
    RoundPipe,
    SafePipe,
    TimeDurationFormatPipe,
    HumanizeDatePipe,
    FileSizePipe,
    DurationPipe,
    BypassSecurityStylePipe,
    TranslatedDeclensionPipe,
    ReplaceEmojisPipe,
    EncodeHTMLPipe,
    ConferenceStatePipe,
    RemoveTextPipe
  ],
  providers: [NestedFieldsPipe, FileSizePipe, DurationPipe, EmptyWrapperPipe, DurationFormatPipe, ReplaceEmojisPipe]
})
export class PipesModule {}
