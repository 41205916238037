import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {
  // value в секундах
  transform(value: number): unknown {
    if (value === 0 || value < 0) {
      return '00:00';
    }
    const size = [86400, 3600, 60, 1];
    let pattern = '&0:&1:&2:&3';
    for (let i = 0; i < size.length; i++) {
      const units = value / size[i];
      const regexp = new RegExp(`&${i}`);
      if (units >= 1) {
        pattern = pattern.replace(regexp, Math.trunc(units).toString());
        value = value % size[i];
      } else {
        pattern = pattern.replace(regexp, '0');
      }
    }
    // TODO #alekssakovsky BREEZ-793: отрефакторить pipe DurationPipe
    let groups = pattern.split(':');
    groups[1] = String(Number(groups[1]) + Number(groups[0]) * 24);
    groups = groups.slice(1).map(group => {
      if (group.length === 1) {
        return '0' + group;
      } else {
        return group;
      }
    });

    return groups.join(':');
  }
}
