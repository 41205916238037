<h3>{{ 'ISSUE_A_WARNING' | translate }}</h3>
<form [formGroup]="warningForm" (ngSubmit)="submit()">
  <div class="control-row">
    <div vks-control-group>
      <label for="conference-title">{{ 'REASON' | translate }}</label>
      <input vks-cinput class="full-width" formControlName="reason" id="conference-title" type="text" />
    </div>
    <div vks-control-group class="enumeration closer">
      <button
        *ngFor="let suggestion of suggestions$ | async"
        vks-button
        [label]="suggestion"
        (buttonClick)="setSuggestionToReasonInput(suggestion)"
        class="btn-anchor"
        type="button"
      ></button>
    </div>
  </div>
  <div class="control-row">
    <div vks-control-group class="ga-right">
      <button vks-button [label]="'CANCEL' | translate" (buttonClick)="cancel()" class="btn-anchor"></button>
      <button vks-button [label]="'SEND' | translate" buttonType="submit" class="btn-primary"></button>
    </div>
  </div>
</form>
