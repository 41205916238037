<ng-container *ngIf="scrollable; else simpleList">
  <div
    (scrolledUp)="onScrolled(false)"
    (scrolled)="onScrolled(true)"
    class="grid-list"
    infinite-scroll
    infiniteScrollDistance="2"
    infiniteScrollThrottle="50"
  >
    <mat-card *ngFor="let item of items; trackBy: trackBy" (click)="itemClick.emit(item)">
      <ng-container *ngTemplateOutlet="itemTemplate; context: { item: item }"></ng-container>
    </mat-card>
  </div>
</ng-container>

<ng-template #simpleList>
  <div class="grid-list">
    <mat-card *ngFor="let item of items; trackBy: trackBy" (click)="itemClick.emit(item)">
      <ng-container *ngTemplateOutlet="itemTemplate; context: { item: item }"></ng-container>
    </mat-card>
  </div>
</ng-template>
