import { ApplicationRef, ElementRef, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '@breez/environment';
import { MenuState } from '@breez/models/webrtc/menu-states.enum';
import { BuildType } from '@breez/shared/enums/build-type.enum';
import { LocalStorage } from '@breez/shared/modules/storage/interfaces/local-storage.interface';
import { distinctUntilChangedByJsonCompare, replayWhileSubs, toClass } from '@breez/shared/rxjs-operators';
import { HeaderService } from '@breez/shared/services/header/header.service';
import { TitleService } from '@breez/shared/services/title/title.service';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { TranslateService } from '@ngx-translate/core';
import { ElectronService } from '@breez/modules/core/services';
import { BehaviorSubject, combineLatest, fromEvent, merge, Observable } from 'rxjs';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ELECTRON_CHANNEL_LIST } from '../../../../electron-channel-list';
import { SessionStorage } from '@breez/shared/modules/storage/interfaces/session-storage.interface';
import * as uuid from 'uuid';
import { queryParamsToString } from '@breez/shared/utilities/query-params-to-string';
import { VisibilityStateEnum } from '@breez/modules/core/enums/notifications.enum';

export interface Header {
  title: string;
  subtitle?: string;
}

const MENU_STATE_KEY = 'menu-state';
export const LATEST_URL_KEY = 'latest-url';

/*Whitelist url для сохранения состояния*/
const LATEST_URL_REGEXP_FILTER = [
  new RegExp(/\/conference\/calendar$/),
  new RegExp(/\/conference$/),
  new RegExp(/\/records$/),
  new RegExp(/\/chat$/),
  new RegExp(/\/social$/),
  new RegExp(/\/$/)
];

const LATEST_WINDOW_SIZE_KEY = 'latest-window-size';
const LATEST_WINDOW_POSITION_KEY = 'latest-window-position';
const ELECTRON_MINIMUM_SCREEN_HEIGHT = 800;
const ELECTRON_FULLSCREEN_FACTOR = 0.95;

export const CONFERENCE_CURRENT_VIEW_KEY = 'conference-catalog-current-view-key';

export const CONFERENCE_CURRENT_STATUS_KEY = 'conference-catalog-current-status-key';
export const CONFERENCE_CURRENT_PARAMS_KEY = 'conference-catalog-current-params';

export const CONFERENCE_RECORDS_CURRENT_VIEW_KEY = 'conference-records-current-view-key';
export const CONFERENCE_RECORDS_CURRENT_PARAMS_KEY = 'conference-records-current-params';
export const CURRENT_CHAT_ID_KEY = 'global-chat-current-key';

enum MenuStateEnum {
  LARGE = 'LARGE',
  COMPACT = 'COMPACT'
}

@Injectable({
  providedIn: 'root'
})
export class StateService {
  compactMenu$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  menuState: MenuState;
  notificationsCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  projectBuildType: BuildType = environment.buildType;
  fullscreenChangeEvent$: Observable<boolean> = new Observable(subscriber => {
    const eventHandler = (): any => {
      return subscriber.next(
        !!(
          document.fullscreenElement ||
          (<any>document).webkitCurrentFullScreenElement ||
          (<any>document).webkitFullScreenElement ||
          (<any>document).msFullscreenElement ||
          (<any>document).mozFullScreenElement
        )
      );
    };

    ['webkitfullscreenchange', 'mozfullscreenchange', 'fullscreenchange', 'MSFullscreenChange'].forEach(event => {
      return document.addEventListener(event, eventHandler);
    });
  });

  isSecondaryWindow$: Observable<boolean> = this.electronService.isSecondaryWindow$;
  electronWindowUid$: Observable<string> = this.electronService.uid$;

  windowPosition$: Observable<{ top: number; left: number }> = fromEvent(window, 'mouseover').pipe(
    startWith(<Window>null),
    map(() => {
      return { top: window.screenTop, left: window.screenLeft };
    }),
    distinctUntilChangedByJsonCompare(),
    tap(_ => {
      this.storePositionAndSize();
    })
  );

  windowSize$: Observable<{ width: number; height: number }> = fromEvent(window, 'resize').pipe(
    startWith(<Window>null),
    map(() => {
      return { width: window.innerWidth, height: window.innerHeight };
    }),
    tap(_ => {
      this.storePositionAndSize();
    }),
    replayWhileSubs()
  );

  isFullscreen$: Observable<boolean> = merge(this.fullscreenChangeEvent$).pipe(startWith(false), shareReplay(1));
  isMinimumContentWidth$: Observable<boolean> = this.windowSize$.pipe(
    map(({ width }) => {
      return width <= 450;
    }),
    shareReplay(1)
  );

  isMediumContentWidth$: Observable<boolean> = this.windowSize$.pipe(
    map(({ width }) => {
      return width >= 451 && width <= 1024;
    }),
    shareReplay(1),
    distinctUntilChanged()
  );

  isMobileDevice$: Observable<boolean> = fromEvent(window, 'resize').pipe(
    startWith(this.isMobileLayout()),
    map(() => {
      return this.isMobileLayout();
    }),
    distinctUntilChanged(),
    shareReplay(1)
  );

  isTabletDevice$: Observable<boolean> = fromEvent(window, 'resize').pipe(
    startWith(this.isTabletLayout()),
    map(() => {
      return this.isTabletLayout();
    }),
    distinctUntilChanged(),
    shareReplay(1)
  );

  isButtonExitConferenceLayout$: Observable<boolean> = this.windowSize$.pipe(
    map(({ width }) => {
      return width <= 650;
    }),
    distinctUntilChanged(),
    shareReplay(1)
  );

  hideSideBar$: Observable<boolean> = fromEvent(window, 'resize').pipe(
    startWith(() => {
      return window.innerWidth <= 450;
    }),
    map(() => {
      return window.innerWidth <= 450;
    }),
    distinctUntilChanged(),
    shareReplay(1)
  );

  private readonly isAppVisibleSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly isAppVisible$: Observable<boolean> = this.isAppVisibleSubject.asObservable();
  readonly isFocused$ = new BehaviorSubject<boolean>(false);
  private header$ = new BehaviorSubject<{ title?: string; subtitle?: string }>(null);
  currentTitle$: Observable<string> = this.header$.pipe(
    filter(isTruthy),
    map(header => {
      return header.title;
    })
  );

  documentTitle$: Observable<string> = combineLatest([this.header$, this.notificationsCount$]).pipe(
    switchMap(([header, notificationsCount]) => {
      return this.translateService.stream('BREEZ').pipe(
        map(brand => {
          return [header?.title, brand, notificationsCount];
        })
      );
    }),
    map(([currentTitle, brand, notificationsCount]) => {
      return this.titleService.getActualTitle(currentTitle, brand, notificationsCount, this.isPwaApplication());
    }),
    distinctUntilChanged()
  );

  hideInterface$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  currentSubtitle$: Observable<string> = this.header$.pipe(
    filter(isTruthy),
    map(header => {
      return header.subtitle;
    })
  );

  private expandedGUI$ = new BehaviorSubject<boolean>(false);
  isExpandedGUI$: Observable<boolean> = this.expandedGUI$.pipe(shareReplay(1));
  private popupOpened$ = new BehaviorSubject<boolean>(false);
  isPopupOpened$: Observable<boolean> = this.popupOpened$.pipe(shareReplay(1));
  private blockedGUI$ = this.electronService.isBlockInterface$;
  isBlockedGUI$: Observable<boolean> = this.blockedGUI$.pipe(shareReplay(1));
  private menuOpened$ = new BehaviorSubject<boolean>(false);
  isMenuOpened$: Observable<boolean> = this.menuOpened$.asObservable().pipe(startWith(false), shareReplay(1));
  backdropShow$: Observable<boolean> = combineLatest([
    this.isMediumContentWidth$,
    this.isMinimumContentWidth$,
    this.compactMenu$,
    this.menuOpened$
  ]).pipe(
    distinctUntilChanged((a, b) => {
      return JSON.stringify(a) === JSON.stringify(b);
    }),
    map(([isMedium, isMinimum, isCompactMenu, isMenuOpened]) => {
      return (isMedium && !isCompactMenu) || (isMinimum && isMenuOpened);
    }),
    shareReplay(1)
  );

  constructor(
    private app: ApplicationRef,
    private titleDOM: Title,
    private translateService: TranslateService,
    private electronService: ElectronService,
    private localStorage: LocalStorage,
    private sessionStorage: SessionStorage,
    private titleService: TitleService,
    private headerService: HeaderService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.visibilityStateListen();
    if (electronService.isElectron) {
      const WINDOW_UID = this.getFromLocal<string>(ELECTRON_CHANNEL_LIST.WINDOW_UID, false);
      const ELECTRON_SECONDARY_WINDOW = this.getFromLocal<boolean>(
        ELECTRON_CHANNEL_LIST.ELECTRON_SECONDARY_WINDOW,
        false
      );
      if (isTruthy(WINDOW_UID)) {
        this.electronService.electronUidWindow$.next(WINDOW_UID);
      }

      if (isTruthy(ELECTRON_SECONDARY_WINDOW)) {
        this.electronService.electronSecondaryWindow$.next(ELECTRON_SECONDARY_WINDOW);
      }

      this.electronService.uid$.subscribe(uid => {
        if (this.getFromLocal<string>(ELECTRON_CHANNEL_LIST.WINDOW_UID, false) !== uid) {
          this.saveToLocal(ELECTRON_CHANNEL_LIST.WINDOW_UID, uid, false);
        }
      });

      this.electronService.isSecondaryWindow$.subscribe(isSecondaryWindow => {
        const sessionIsSecondaryWindow = this.getFromLocal<boolean>(
          ELECTRON_CHANNEL_LIST.ELECTRON_SECONDARY_WINDOW,
          false
        );
        if (!isTruthy(sessionIsSecondaryWindow) || sessionIsSecondaryWindow !== ELECTRON_SECONDARY_WINDOW) {
          this.saveToLocal(ELECTRON_CHANNEL_LIST.ELECTRON_SECONDARY_WINDOW, isSecondaryWindow, false);
        }
      });
    }

    this.windowPosition$.pipe(debounceTime(2000)).subscribe();

    if (this.electronService.isElectron) {
      try {
        const latestUrl = JSON.parse(this.localStorage.getItem(LATEST_URL_KEY));
        if (latestUrl) {
          this.router.events
            .pipe(
              filter(data => {
                return data instanceof NavigationEnd;
              }),
              toClass(NavigationEnd),
              take(1),
              withLatestFrom(this.activatedRoute.queryParams)
            )
            .subscribe(([{ url }, queryParams]) => {
              if (url.split('?')[0] === '/' && !queryParams?.stay) {
                const redirectTo = `${latestUrl[0]}`.split('/').filter(item => {
                  return isTruthy(item) && item.length > 0;
                });
                this.router.navigate(redirectTo, latestUrl[1]);
              }
            });
        }
      } catch (error) {
        console.error(error);
      }

      try {
        this.isSecondaryWindow$.subscribe(() => {});

        this.electronService.screenSize$.subscribe(() => {});

        this.electronService.sendSpecialUrl();

        combineLatest([this.isSecondaryWindow$, this.electronService.screenSize$])
          .pipe(take(1))
          .subscribe(([electronSecondaryWindow, screenSize]) => {
            const latestWindowSize = this.getFromLocal<{ width: number; height: number }>(LATEST_WINDOW_SIZE_KEY);
            const latestWindowPosition = this.getFromLocal<{ top: number; left: number }>(
              LATEST_WINDOW_POSITION_KEY
            ) ?? { left: 0, top: 0 };

            const screen = screenSize;

            const setFullScreen =
              ((latestWindowSize?.width ?? 0) / screen.width > ELECTRON_FULLSCREEN_FACTOR &&
                (latestWindowSize?.height ?? 0) / screen.height > ELECTRON_FULLSCREEN_FACTOR) ||
              (screen.height < ELECTRON_MINIMUM_SCREEN_HEIGHT && !isTruthy(latestWindowSize?.height));

            if (latestWindowSize && !electronSecondaryWindow) {
              this.electronService.windowSetSize(latestWindowSize.width, latestWindowSize.height, true);
            } else {
              this.electronService.windowSetSize(undefined, undefined, true);
            }

            if (latestWindowSize) {
              let left = latestWindowPosition?.left ?? 0;
              let top = latestWindowPosition?.top ?? 0;

              if (electronSecondaryWindow) {
                this.electronService
                  .windowGetSize$()
                  .pipe(take(1))
                  .subscribe(electronSize => {
                    let relativeLeft = latestWindowPosition.left + 25;
                    let relativeTop = latestWindowPosition.top + 25;
                    const currentWidth = electronSize[0] ?? 0;
                    const currentHeight = electronSize[1] ?? 0;

                    if (currentWidth + relativeLeft > screen.width || currentHeight + relativeTop > screen.height) {
                      left = Math.ceil(screen.width * 0.1) + 25;
                      top = Math.ceil(screen.height * 0.1) + 25;
                    } else {
                      left = relativeLeft;
                      top = relativeTop;
                    }

                    this.electronService.windowSetPosition(left, top, true);
                  });
              } else {
                this.electronService.windowSetPosition(latestWindowPosition.left, latestWindowPosition.top, true);
              }
            } else {
              this.electronService.windowSetPosition(undefined, undefined, true);
            }

            if (setFullScreen) {
              this.electronService.windowMaximize();
            }
          });
      } catch (error) {
        console.error(error);
      }
    }

    this.router.events
      .pipe(
        filter(data => {
          return data instanceof NavigationEnd;
        }),
        toClass(NavigationEnd),
        debounceTime(5000),
        withLatestFrom(this.activatedRoute.queryParams)
      )
      .subscribe(([{ url }, queryParams]) => {
        if (!queryParams.stay) {
          const base = url.split('?')[0];
          const lastUrlFilter = LATEST_URL_REGEXP_FILTER.some(regexp => {
            return regexp.test(base);
          });
          if (lastUrlFilter) {
            if (base === '/') {
              this.localStorage.removeItem(LATEST_URL_KEY);
            } else {
              this.localStorage.setItem(LATEST_URL_KEY, JSON.stringify([base, { queryParams }]));
            }
          }
        }
      });

    this.isMobileDevice$.subscribe(isMobile => {
      if (isMobile) {
        this.closeMenu();
      }
    });
    combineLatest([this.hideSideBar$, this.isMediumContentWidth$, this.isMobileDevice$]).subscribe(
      ([isHide, isMedium, isMobileDevice]) => {
        if (isHide) {
          this.closeMenu();
        } else if (!isMobileDevice && !isMedium) {
          const initialMenuState = this.localStorage.getItem(MENU_STATE_KEY);
          if (initialMenuState) {
            this.menuState = MenuState[this.localStorage.getItem(MENU_STATE_KEY)];
            if (initialMenuState === MenuStateEnum.COMPACT) {
              this.showCompactMenu();
            } else {
              this.showLargeMenu();
            }
          } else {
            this.menuState = MenuState.COMPACT;
            this.showCompactMenu();
          }
        } else {
          this.menuState = MenuState.COMPACT;
          this.showCompactMenu();
        }
      }
    );

    this.documentTitle$.subscribe(title => {
      return this.titleDOM.setTitle(title);
    });
    this.notificationsCount$
      .pipe(
        distinctUntilChanged(),
        filter(_ => {
          return this.electronService.isElectron;
        })
      )
      .subscribe(count => {
        if (this.electronService.isWindows || this.electronService.isLinux) {
          this.setDockBadgeOnWindowsAndLinux(count);
        } else if (this.electronService.isMac) {
          this.setDockBadgeOnMacOs(count);
        }
      });
  }

  private visibilityStateListen(): void {
    window.addEventListener('visibilitychange', () => {
      const isAppVisible = document.visibilityState === VisibilityStateEnum.VISIBLE;
      this.isAppVisibleSubject.next(isAppVisible);
    });

    window.addEventListener('blur', () => {
      this.isFocused$.next(false);
    });

    window.addEventListener('focus', () => {
      this.isFocused$.next(true);
    });
  }

  isAppVisible(): boolean {
    return this.isAppVisibleSubject.getValue();
  }

  storePositionAndSize(): void {
    combineLatest([
      this.isSecondaryWindow$,
      this.electronService.windowGetSize$(),
      this.electronService.windowGetPosition$()
    ])
      .pipe(delay(1000), take(1))
      .subscribe(([electronSecondaryWindow, electronSize, electronPosition]) => {
        if (this.electronService.isElectron && !electronSecondaryWindow) {
          this.saveToLocal(LATEST_WINDOW_SIZE_KEY, { width: electronSize[0], height: electronSize[1] });
          this.saveToLocal(LATEST_WINDOW_POSITION_KEY, { top: electronPosition[1], left: electronPosition[0] });
        }
      });
  }

  isAppFocused(): boolean {
    return document.hasFocus();
  }

  isMobileLayout(): boolean {
    return window.innerWidth <= 450;
  }

  isTabletLayout(): boolean {
    return window.innerWidth <= 1024;
  }

  setHeader(header: Header | string): void {
    this.headerService.setHeader(header);
    this.headerService.getHeader$().subscribe(this.header$);
  }

  openMenu(): void {
    this.menuOpened$.next(true);
  }

  closeMenu(): void {
    this.menuOpened$.next(false);
  }

  toggleMenu(): void {
    this.compactMenu$.next(!this.compactMenu$.getValue());
  }

  showCompactMenu(): void {
    this.compactMenu$.next(true);
  }

  showLargeMenu(): void {
    this.compactMenu$.next(false);
  }

  saveToLocalRecord(item: Record<string, unknown>, localStorage = true): void {
    const storage = localStorage ? this.localStorage : this.sessionStorage;
    for (const key in item) {
      storage.setItem(key, JSON.stringify(item[key]));
    }
  }

  saveToLocal(key: string, value: any, localStorage = true): void {
    this.saveToLocalRecord({ [key]: value }, localStorage);
  }

  subscribeToLocalChanges<T>(subscriptionKey: string): Observable<T> {
    return this.localStorage.changes.pipe(
      filter(({ key }) => {
        return key === subscriptionKey;
      }),
      map(() => {
        return this.getFromLocal(subscriptionKey);
      })
    );
  }

  getFromLocal<T>(key: string, localStorage = true): T {
    const storage = localStorage ? this.localStorage : this.sessionStorage;
    try {
      return JSON.parse(storage.getItem(key)) as T;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  removeFromLocal(key: string, localStorage = true): void {
    const storage = localStorage ? this.localStorage : this.sessionStorage;
    storage.removeItem(key);
  }

  updateRouteParams(router: Router, activatedRoute: ActivatedRoute, params: { [key: string]: string }): void {
    setTimeout(() => {
      return router.navigate([], {
        relativeTo: activatedRoute,
        queryParams: params,
        queryParamsHandling: 'merge'
      });
    }, 10);
  }

  setExpandedGUI(expanded: boolean): void {
    this.expandedGUI$.next(!!expanded);
  }

  setPopupOpened(opened: boolean): void {
    this.popupOpened$.next(!!opened);
  }

  openWindowInFullscreen(): void {
    this.openInFullscreen(this.app.components[0].location);
  }

  openInFullscreen(element: ElementRef, orientation?): Promise<void> {
    const nativeElement = element.nativeElement;
    const fullScreenFunction: () => Promise<any> =
      nativeElement.requestFullscreen ||
      nativeElement.mozRequestFullScreen ||
      nativeElement.webkitRequestFullscreen ||
      nativeElement.msRequestFullscreen;

    if (!fullScreenFunction) {
      const videoElement = nativeElement.querySelector('video');

      if (!videoElement.webkitSetPresentationMode) {
        return Promise.reject();
      }

      videoElement.webkitSetPresentationMode('fullscreen');
      return Promise.resolve();
    }

    const promise = fullScreenFunction.apply(nativeElement);
    const lockOrientation: () => void = () => {
      if (!orientation) {
        return;
      }

      const windowOrientation = <any>window.screen.orientation;

      if (!(window.screen && windowOrientation && windowOrientation.lock)) {
        return;
      }

      return windowOrientation.lock(orientation);
    };

    if (promise instanceof Promise) {
      return promise.then(lockOrientation);
    }

    lockOrientation();
    return Promise.resolve();
  }

  exitFullscreen(): Promise<void> {
    const fullScreenFunction =
      document.exitFullscreen ||
      (<any>document).mozCancelFullScreen ||
      (<any>document).webkitExitFullscreen ||
      (<any>document).msExitFullscreen;

    if (window.screen && window.screen.orientation && window.screen.orientation.unlock) {
      window.screen.orientation.unlock();
    }

    if (!fullScreenFunction) {
      return Promise.reject();
    }

    return fullScreenFunction.apply(document);
  }

  changeMenuState(): void {
    combineLatest([this.isMinimumContentWidth$, this.isMediumContentWidth$, this.compactMenu$])
      .pipe(take(1))
      .subscribe(([isMinimumContentWidth, isMediumContentWidth, isCompactMenu]) => {
        this.toggleMenu();
        if (!isMediumContentWidth && !isMinimumContentWidth) {
          if (this.menuState === MenuState.COMPACT && isCompactMenu) {
            this.menuState = MenuState.LARGE;
            this.localStorage.setItem(MENU_STATE_KEY, MenuStateEnum.LARGE);
          } else if (this.menuState === MenuState.LARGE && !isCompactMenu) {
            this.menuState = MenuState.COMPACT;
            this.localStorage.setItem(MENU_STATE_KEY, MenuStateEnum.COMPACT);
          }
        }
      });
  }

  goToUrl(url: string, params?: { [key: string]: string | number }, inNewWindow?: boolean): void {
    let isNeedThisWindow = this.isPwaApplication();

    if (isTruthy(inNewWindow)) {
      isNeedThisWindow = !inNewWindow;
    }

    const currentId = sessionStorage.getItem('vks-client-id');
    const newId = uuid.v4();
    sessionStorage.setItem('vks-client-id', newId);

    if (isNeedThisWindow) {
      url = url.replace(window.origin, '');
      this.router.navigate([url]);
      return;
    }

    params = { ...params, ...{ stay: 'true' } };
    const targetUrl = isTruthy(params) ? `${url}?${queryParamsToString(params)}` : url;
    window.open(targetUrl, '_blank');
    sessionStorage.setItem('vks-client-id', currentId);
  }

  private setDockBadgeOnMacOs(notificationsCount: number): void {
    const countAsNumber = notificationsCount > 0 ? Number(notificationsCount) : null;
    this.electronService.electronApi.send(ELECTRON_CHANNEL_LIST.UPDATE_BADGE_NATIVE, Math.min(countAsNumber, 99));
  }

  private setDockBadgeOnWindowsAndLinux(notificationsCount: number): void {
    const countAsNumber = notificationsCount > 0 ? Number(notificationsCount) : null;
    this.electronService.electronApi.badge(
      ELECTRON_CHANNEL_LIST.UPDATE_BADGE,
      isTruthy(countAsNumber) ? Math.min(countAsNumber, 99) : countAsNumber
    );
  }

  isPwaApplication(): boolean {
    return (
      window.matchMedia('(display-mode: standalone)').matches === true || (<any>window.navigator).standalone === true
    );
  }
}
