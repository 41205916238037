import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { merge, Observable, of, Subject, timer } from 'rxjs';
import { distinctUntilChanged, map, pluck, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { MediaDevicesService } from '@breez/modules/webrtc/services/media-devices.service';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { UserSettingsService } from '@breez/modules/settings/services/user-settings.service';
import { UserSettingSectionId } from '../../enums/user-setting-section-id.enum';
import { UserSettingsItem } from '../../models/user-settings-item';
import { replayWhileSubs } from '@breez/shared/rxjs-operators';

@Component({
  selector: 'vks-user-settings-popup',
  templateUrl: './user-settings-popup.component.html',
  styleUrls: ['./user-settings-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserSettingsPopupComponent implements OnInit {
  displaySections$: Observable<UserSettingsItem[]>;
  currentSection$: Observable<UserSettingsItem>;
  setCurrentSection$: Subject<UserSettingsItem> = new Subject<UserSettingsItem>();
  currentSectionId$: Observable<string>;
  currentSectionId: string;
  userSettingSectionId: typeof UserSettingSectionId = UserSettingSectionId;

  sections: UserSettingsItem[] = [
    {
      id: UserSettingSectionId.USER_DEVICES,
      label: 'DEVICES',
      icon: 'call-video-on'
    }
  ];

  private readonly timer$: Observable<boolean> = timer(3000, 5000).pipe(map(isTruthy), replayWhileSubs());

  constructor(
    private dialogRef: MatDialogRef<UserSettingsPopupComponent>,
    private mediaDevicesService: MediaDevicesService,
    private userSettingsService: UserSettingsService
  ) {
    this.dialogRef.disableClose = false;
  }

  ngOnInit(): void {
    this.timer$.pipe(take(1)).subscribe(() => {
      this.dialogRef.disableClose = false;
    });

    this.sections = [...this.sections, ...this.userSettingsService.sections];

    this.displaySections$ = of(this.sections).pipe(
      map(sections => {
        return sections
          .filter(section => {
            return isTruthy(section.canActivate) ? section.canActivate : true;
          })
          .reverse();
      })
    );

    this.currentSection$ = this.displaySections$.pipe(
      map(sections => {
        return (
          sections.find(section => {
            return section.active;
          }) ?? sections[0]
        );
      }),
      switchMap(section => {
        return merge(of(section), this.setCurrentSection$);
      }),
      shareReplay()
    );

    this.currentSectionId$ = this.currentSection$.pipe(
      pluck('id'),
      distinctUntilChanged(),
      tap(id => {
        return (this.currentSectionId = id);
      })
    );
  }

  selectSection(section: UserSettingsItem): void {
    this.setCurrentSection$.next(section);
  }

  saveSources(data: any): void {
    switch (this.currentSectionId) {
      case 'user-devices':
        this.mediaDevicesService.setDefaultMediaSourcesIds(data);
        break;
      default:
        this.dialogRef.close(true);
        break;
    }
  }

  saveEffectsSdkSettings($event: { blur: boolean }): void {
    this.mediaDevicesService.setVideoBlurSetting($event.blur ?? false);
  }

  close(): void {
    if (!this.dialogRef.disableClose) {
      this.dialogRef.close(false);
    }
  }
}
