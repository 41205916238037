import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'vks-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SpinnerComponent {
  @Input()
  icon = 'sdk-loading';

  @Input()
  color: string;
}
