import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { OverlayContainer, OverlayEntry } from '@breez/modules/overlay/models';

@Component({
  selector: 'vks-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserListComponent {
  @Input()
  entry: OverlayEntry;

  @Output() changeOverlayContainer = new EventEmitter<OverlayContainer>();
}
