export enum SupportedBrowsers {
  CHROME = 'chrome',
  YANDEX = 'yandex browser',
  CHROMIUM = 'chromium',
  OPERA = 'opera',
  FIREFOX = 'firefox',
  EDGE = 'microsoft edge',
  SAFARI = 'safari'
}

export enum MinBrowsersVersions {
  CHROME = 80,
  YANDEX = 20,
  CHROMIUM = 80,
  OPERA = 70,
  FIREFOX = 79,
  EDGE = 80,
  SAFARI = 13
}
