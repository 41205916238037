import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { ProcessingFile } from '@breez/models/shared/files/processing-file.model';

@Component({
  selector: 'vks-attachments-renderer',
  templateUrl: './attachments-renderer.component.html',
  styleUrls: ['./attachments-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttachmentsRendererComponent {
  @Input() attachments: ProcessingFile[];
  @Output() delete = new EventEmitter<ProcessingFile>();
}
