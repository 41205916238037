import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ParticipantsEntity } from '@breez/modules/chat/models/+state/participantsEntity';

interface State extends EntityState<ParticipantsEntity> {}

const adapter: EntityAdapter<ParticipantsEntity> = createEntityAdapter<ParticipantsEntity>({
  // @ts-ignore
  selectId: (participantsEntity: ParticipantsEntity) => {
    return participantsEntity.chatId;
  },
  sortComparer: (a, b) => {
    return a.chatId > b.chatId ? 1 : -1;
  }
});

const initialState: State = adapter.getInitialState({});

export { State, adapter, initialState };
