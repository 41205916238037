export function fixDateDeserialization(datePlain: string): string {
  if (!datePlain) {
    return null;
  }

  datePlain = datePlain.replace(/ \+/g, '+').replace(/ /g, 'T');

  const reducedTimezone = datePlain.match(/\+[0-9][0-9]$/g);
  if (reducedTimezone) {
    datePlain += ':00';
  }

  const timeZone = datePlain.match(/\+[0-9][0-9]:[0-9][0-9]$/g);
  if (timeZone) {
    return datePlain;
  }

  const hasUTCPostfix = datePlain.match(/Z$/g);
  if (hasUTCPostfix) {
    return datePlain;
  }

  return datePlain + 'Z';
}
