export const EMOJI_LABELS_RU = {
  search: 'Поиск',
  emojilist: 'Список смайликов',
  notfound: 'Смайлики не найдены',
  clear: 'Очистить',
  categories: {
    search: 'Результаты поиска',
    recent: 'Недавно использованные',
    people: 'Смайлики и люди',
    nature: 'Животные и природа',
    foods: 'Еда и напитки',
    activity: 'Активность',
    places: 'Путешествия и места',
    objects: 'Предметы',
    symbols: 'Символы',
    flags: 'Флаги',
    custom: 'Пользовательские'
  },
  skintones: {
    1: 'Тон кожи по-умолчанию',
    2: 'Очень светлый тон кожи',
    3: 'Светлый тон кожи',
    4: 'Средний тон кожи',
    5: 'Темный тон кожи',
    6: 'Очень темный тон кожи'
  }
};
