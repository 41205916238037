import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Call } from '@breez/modules/call/call';
import { CallModel } from '@breez/modules/call/models/call.model';
import { OverlayContainer, OverlayEntry } from '@breez/modules/overlay/models';
import { Observable } from 'rxjs';

@Component({
  selector: 'vks-call',
  templateUrl: './call.component.html',
  styleUrls: ['./call.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CallComponent implements OnInit {
  @Input()
  entry: OverlayEntry;

  overlayContainer: OverlayContainer;

  @Output()
  changeOverlayContainer = new EventEmitter<OverlayContainer>();

  anotherActiveCallId: number;
  call: Call;
  callModel$: Observable<CallModel>;

  ngOnInit(): void {
    this.call = <Call>this.entry.data;
    this.overlayContainer = this.entry;

    if (!!this.entry.extended) {
      this.anotherActiveCallId = this.entry.extended.anotherActiveCallId;
    }

    this.callModel$ = this.call.callModel$;
  }
}
