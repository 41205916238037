import { ConferenceUserRole } from '@breez/models/conference-user-role.enum';
import { Expose } from 'class-transformer';

export enum InviteParticipantsModeEnum {
  STATES,
  TYPES,
  IDS
}

export enum InviteParticipantsStatesEnum {
  ALL = 'all',
  MISSING = 'missing'
}

export interface InviteParticipantParams {
  mode: InviteParticipantsModeEnum;
  participantStates?: InviteParticipantsStatesEnum[];
  participantTypes?: ConferenceUserRole[];
  participantIds?: number[];
}

export interface InviteParticipantRequestData {
  conferenceid: number /* ID конференции */;
  participantids?: number[] /* ID пользователей */;
  participanttypes?: string[] /* Типы участника */;
  participantstates?: string[] /* Состояния участника */;
}

export class InviteParticipantRequest implements InviteParticipantRequestData {
  @Expose({ name: 'conferenceId' })
  conferenceid: number;

  @Expose({ name: 'participantIds' })
  participantids?: number[];

  @Expose({ name: 'participantTypes' })
  participanttypes?: string[];

  @Expose({ name: 'participantStates' })
  participantstates?: string[];
}
