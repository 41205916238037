import { NgModule } from '@angular/core';

import { NgxdModule } from '@ngxd/core';
import { NotificationTypesModule } from '../notification-types.module';
import { NotificationTypeDynamicComponent } from './dynamic.component';

@NgModule({
  imports: [NgxdModule, NotificationTypesModule],
  declarations: [NotificationTypeDynamicComponent],
  exports: [NotificationTypeDynamicComponent]
})
export class NotificationDynamicModule {}
