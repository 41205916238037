import { MessageListDateStub, MessageListEntry } from '@breez/modules/chat';

export class MessageGroupEntry {
  dateStub: MessageListDateStub;

  messageEntries: MessageListEntry[] = [];

  constructor(base?: Partial<MessageGroupEntry>) {
    return Object.assign(this, base);
  }
}
