import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NotificationMessageComponent } from './components/type/type.component';
import { PipesModule } from '@breez/shared/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { OVERLAY_PROVIDERS } from './overlay-provider';
import { ControlsModule } from '@breez/modules/controls/controls.module';

@NgModule({
  declarations: [NotificationMessageComponent],
  imports: [CommonModule, PipesModule, TranslateModule, ControlsModule],
  providers: [...OVERLAY_PROVIDERS]
})
export class NotificationMessageModule {}
