import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { Message } from '@breez/modules/chat';
import { isTruthy } from '@breez/shared/utilities/is-truthy';

@Pipe({
  name: 'messageIsSomeDelivered'
})
@Injectable({
  providedIn: 'root'
})
export class MessageIsSomeDeliveredPipe implements PipeTransform {
  transform(message: Partial<Message>): boolean {
    return isTruthy(message.deliveredUserIds) && message.deliveredUserIds?.length > 0;
  }
}
