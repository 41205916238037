<div class="control-date">
  <input
    vks-cinput
    [formControl]="control"
    [matDatepicker]="datepicker1"
    [max]="maxValue"
    [min]="minValue"
    [placeholder]="'DD.MM.YYYY' | translate | lowercase"
    type="text"
  />
  <button
    vks-button
    (buttonClick)="datepicker1.open()"
    class="btn-anchor"
    color="#005259"
    iconClass="square24"
    icon="control-calendar"
  ></button>
  <mat-datepicker #datepicker1></mat-datepicker>
</div>
