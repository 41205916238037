import { concat, Observable, of } from 'rxjs';
import { concatMap, tap } from 'rxjs/operators';

export function serialExpand<A, V>(
  on: Observable<V>,
  accumulator: (acc: A, value: V) => Observable<A>,
  seed: A
): Observable<A> {
  let acc: A;

  return concat(
    of(seed),
    on.pipe(
      concatMap(value => {
        return accumulator(acc, value);
      })
    )
  ).pipe(
    tap(switched => {
      return (acc = switched);
    })
  );
}
