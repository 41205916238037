<vks-modal-header [title]="'CROP_IMAGE' | translate" (close)="cancel()"></vks-modal-header>
<vks-control-image-cropper
  [formControl]="imageControl"
  [ratio]="ratio"
  [resizeTo]="resizeTo"
></vks-control-image-cropper>
<div class="controls">
  <button vks-button [label]="'SAVE' | translate" (buttonClick)="save()" class="btn-primary"></button>
  <button vks-button [label]="'CANCEL' | translate" (buttonClick)="cancel()" class="btn-anchor"></button>
</div>
