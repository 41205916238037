import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostListener, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { User } from '@breez/models';
import { ChatParticipantsComponent } from '../chat-participants';
import { ControlUserSelectComponent } from '@breez/modules/user/control-user-select';
import { fromControl } from '@breez/shared/rxjs-operators/from-control';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { Store } from '@ngrx/store';
import * as ModuleState from '../../+state/module.state';
import * as ChatActions from '../../+state/chat/chat.actions';
import * as UserActions from '@breez/modules/users/+state/users/user.actions';
import * as ParticipantsAction from '../../+state/participants/participants.actions';

import * as ChatSelectors from '../../+state/chat/chat.selectors';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { filter, switchMap, withLatestFrom } from 'rxjs/operators';
import { CHAT_ID } from '@breez/modules/chat';
import { ChatParticipantsEvent } from '@breez/models/chat/chat-participants-event';
import { ChatEntity } from '@breez/modules/chat/models/+state/chatEntity';
import { PARTICIPANT_ID } from '@breez/modules/chat/types/participant-id.type';
import * as ParticipantsSelectors from '@breez/modules/chat/+state/participants/participants.selectors';

@UntilDestroy()
@Component({
  selector: 'vks-chat-wrapper',
  templateUrl: './chat-wrapper.component.html',
  styleUrls: ['./chat-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatWrapperComponent implements OnInit {
  @ViewChild('controlUserSelectComponent') controlUserSelectComponent: ControlUserSelectComponent;

  @Input() currentUser: User;
  // @Input() chat: Chat;
  @Input() rearrangeInterface: boolean;

  chat$: Observable<ChatEntity> = this.store.select(ChatSelectors.selectedChat);

  chatParticipantsEntity$: Observable<PARTICIPANT_ID[]> = this.chat$.pipe(
    filter(isTruthy),
    switchMap(chat => {
      return this.store.select(ParticipantsSelectors.getParticipantsEntity(chat.id));
    })
  );
  // part$:

  selectedUsersFormControl = new UntypedFormControl(null);

  constructor(
    private readonly dialog: MatDialog,
    private store: Store<ModuleState.State>,
    private readonly router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {}

  @HostListener('document:keydown.escape', ['$event'])
  closeChat(_: KeyboardEvent): void {
    const openedDialog = this.document.querySelector('.mat-dialog-container');
    const openedImagePreviewComponent = this.document.querySelector('vks-image-preview');
    if (isTruthy(openedDialog) || isTruthy(openedImagePreviewComponent)) {
      return;
    }
    this.store.dispatch(ChatActions.deselectChat());
    this.router.navigate(['/', 'chat']).then();
  }

  ngOnInit(): void {
    fromControl(this.selectedUsersFormControl)
      .pipe(untilDestroyed(this), withLatestFrom(this.chat$, this.chatParticipantsEntity$))
      .subscribe(([users, chat, participantUserIds]: [User[], ChatEntity, PARTICIPANT_ID[]]) => {
        if (!Array.isArray(users) || users.length === 0) {
          return;
        }

        const usersToAddIds = users
          .filter(user => {
            return !participantUserIds.includes(user.id);
          })
          .map(user => {
            return user.id;
          });

        if (!isTruthy(usersToAddIds) || usersToAddIds.length === 0) {
          return;
        }

        this.store.dispatch(UserActions.loadUsersSuccess({ users }));

        this.store.dispatch(
          ParticipantsAction.changeChatParticipants({
            chatId: chat.id,
            participantsId: usersToAddIds,
            status: ChatParticipantsEvent.ADDED
          })
        );

        this.selectedUsersFormControl.patchValue(null);
      });
  }

  showParticipantsPopup(chatId: CHAT_ID): void {
    this.dialog
      .open(ChatParticipantsComponent, {
        data: chatId,
        panelClass: 'right-align',
        width: '250px',
        maxHeight: '410px'
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.controlUserSelectComponent.showDialog();
        }
      });
  }
}
