import { ObjectType } from '@breez/shared/enums/object-type.enum';

export interface ParticipationIntervalsInfoRequest {
  startdate: Date;
  enddate: Date;
  participantids: number[];
  objecttype: ObjectType;
  statusids?: number[];
  duration: number;
}
