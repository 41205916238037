import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { RootState } from './root.state';

import * as Notification from './notification';
import * as Execution from './execution';
import * as Account from './account';
import * as ExecutionSelectors from './execution/execution.selectors';
import { NotificationEffects } from './notification/notification.effects';
import { ExecutionEffects } from '@breez/+state/execution/execution.effects';
import { localStorageSync } from 'ngrx-store-localstorage';
import * as ChatUnreadSelectors from '@breez/modules/chat/+state/chatUnreadMessage/chatUnreadMessage.selectors';
import * as UnreadChatReducer from '@breez/modules/chat/+state/chatUnreadMessage/chatUnreadMessage.reducer';
import { ChatUnreadMessageEffects } from '@breez/modules/chat/+state/chatUnreadMessage/chatUnreadMessage.effects';

const ROOT_STORE_EFFECTS = [NotificationEffects, Account.AccountEffects, ExecutionEffects, ChatUnreadMessageEffects];

const ROOT_STORE_SELECTORS = {
  notification: Notification.Selectors,
  execution: ExecutionSelectors,
  chatUnreadMessageCount: ChatUnreadSelectors
};

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: ['chat-feature', 'execution', 'notification', 'user-feature'],
    rehydrate: true
  })(reducer);
}

const ROOT_STORE_REDUCERS: ActionReducerMap<RootState> = {
  notification: Notification.Reducers.NotificationReducers,
  account: Account.accountReducer,
  execution: Execution.executionReducer,
  chatUnreadMessageCount: UnreadChatReducer.chatUnreadMessageCountReducer
};

const ROOT_STORE_DEVMETAREDUCERS: MetaReducer<RootState>[] = [
  // localStorageSyncReducer
];

const ROOT_STORE_PRODMETAREDUCERS: MetaReducer<RootState>[] = [
  // localStorageSyncReducer
];

export {
  Notification,
  RootState,
  ROOT_STORE_SELECTORS,
  ROOT_STORE_REDUCERS,
  ROOT_STORE_EFFECTS,
  ROOT_STORE_DEVMETAREDUCERS,
  ROOT_STORE_PRODMETAREDUCERS
};
