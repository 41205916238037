<div class="container">
  <img [src]="svgPath" alt="Oops" />
  <span class="oops-header">{{ 'OOPS_TITLE' | translate }}</span>
  <span class="oops-text">{{ 'OOPS_TEXT' | translate }}</span>
  <button
    vks-button
    [label]="'RENEW' | translate"
    (buttonClick)="refreshPage()"
    class="btn-primary oops-button"
  ></button>
</div>
<vks-footer></vks-footer>
