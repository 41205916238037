import { Inject, Injectable, OnDestroy } from '@angular/core';
import { tsvb } from 'effects-sdk';
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { distinctUntilChanged, filter, map, startWith, take } from 'rxjs/operators';
import { replayWhileSubs } from '@breez/shared/rxjs-operators';
import { LoggerService } from '@breez/shared/services/logger.service';
import { ErrorObject } from 'effects-sdk/types/utils/errorBus';
import { ElectronService } from '@breez/modules/core/services';
import { WINDOW } from '@breez/helpers/window.provider';
import { Account } from '@breez/shared/models/account.model';
import { Store } from '@ngrx/store';
import * as ModuleState from '@breez/+state/account/account.state';
import * as AccountSelectors from '@breez/+state/account/account.selectors';

interface WindowExt extends Window {
  inferer: any;
}

@Injectable({
  providedIn: 'root'
})
export class MediaEffectsService implements OnDestroy {
  sdk$: BehaviorSubject<tsvb> = new BehaviorSubject<tsvb>(null);
  originalStream: MediaStream = null;
  sdkStream: MediaStream = null;
  canvasSdkStream: MediaStream = null;
  canvas: HTMLCanvasElement = document.createElement('canvas');
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly hasLicence$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  customerId$: ReplaySubject<string> = new ReplaySubject<string>(1);

  isSdkReady$: Observable<boolean> = combineLatest([this.customerId$, this.sdk$]).pipe(
    map(([customerId, sdk]) => {
      return isTruthy(customerId) && isTruthy(sdk);
    }),
    startWith(false),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  constructor(
    private logger: LoggerService,
    private electronService: ElectronService,
    @Inject(WINDOW) private readonly window: WindowExt,
    private store: Store<ModuleState.State>
  ) {
    this.init();
  }

  get sdk(): tsvb {
    return this.sdk$.value;
  }

  set sdk(sdk: tsvb) {
    this.sdk$.next(sdk);
  }

  sdkReady(): Observable<void> {
    return this.isSdkReady$.pipe(
      filter(isSdkReady => {
        return isSdkReady === true;
      }),
      map(() => {
        return undefined;
      }),
      take(1)
    );
  }

  onReady = (): void => {
    this.logger.info('SDK is ready');
    this.run();
    this.blur(0.12);
    this.sdk.setFpsLimit(25);
    this.loading$.next(false);
  };

  init(): void {
    this.store
      .select(AccountSelectors.getAccount())
      .pipe(
        filter((account: Account) => {
          return !!account?.data?.license?.effects?.url;
        })
      )
      .subscribe((account: Account) => {
        this.customerId$.next(account.data.license?.effects.key);

        this.customerId$.pipe(filter(isTruthy), take(1)).subscribe(customerId => {
          this.sdk = this.electronService.isElectron
            ? new tsvb(customerId, this.window.inferer)
            : (this.sdk = new tsvb(customerId));
          this.isSdkReady$
            .pipe(
              filter(isSdkReady => {
                return isSdkReady === true;
              }),
              take(1)
            )
            .subscribe(() => {
              const isRealElectron = this.electronService.isElectron && window.location.origin !== 'https://localhost';
              const wasmPath = isRealElectron ? '/wasm/' : '/assets/effects-sdk/wasm/';
              const ortWasm = `${wasmPath}ort-wasm.wasm`;
              const ortWasmSimd = `${wasmPath}ort-wasm-simd.wasm`;

              this.sdk.config({
                api_url: account.data.license.effects.url,
                models: {
                  colorcorrector: '',
                  facedetector: '',
                  lowlighter: ''
                },
                wasmPaths: {
                  'ort-wasm.wasm':
                    window.location.origin +
                    (isRealElectron ? this.electronService.electronApi.staticUrl(ortWasm) : ortWasm),
                  'ort-wasm-simd.wasm':
                    window.location.origin +
                    (isRealElectron ? this.electronService.electronApi.staticUrl(ortWasmSimd) : ortWasmSimd)
                }
              });
              this.sdk.cache();
              this.sdk
                .preload()
                .then(() => {
                  return this.hasLicence$.next(true);
                })
                .catch(() => {
                  return this.hasLicence$.next(false);
                });
              this.sdk.onReady = this.onReady;
              this.sdk.onError = (): void => {
                this.stop();
              };
            });
        });
      });
  }

  run(): void {
    this.sdkReady().subscribe(() => {
      this.sdk.run();
    });
  }

  stop(): void {
    this.sdkReady().subscribe(() => {
      this.logger.info('SDK is stopped');
      this.sdk.clear();
      this.sdk.stop();
      this.endTracks(this.sdkStream);
      this.endTracks(this.originalStream);
      this.sdkStream = null;
      this.originalStream = null;
      if (!!this.canvasSdkStream) {
        this.endTracks(this.canvasSdkStream);
        this.canvasSdkStream = null;
      }
      this.loading$.next(false);
    });
  }

  endTracks(stream: MediaStream): void {
    if (!stream) {
      return;
    }
    const tracks = stream.getVideoTracks();
    tracks.forEach(track => {
      track.stop();
      stream.removeTrack(track);
    });
  }

  blur(opacity?: number): void {
    opacity = opacity > 1 ? 1 : opacity;
    this.sdkReady().subscribe(() => {
      if (!isTruthy(opacity) || opacity === 0) {
        this.sdk.clearBlur();
      } else {
        this.sdk.setBlur(opacity);
      }
    });
  }

  getStream$(stream: MediaStream, useCanvas = false): Observable<MediaStream> {
    const start = new Date().getTime();
    let videoTracks = stream?.getVideoTracks();

    if (!stream || !stream.active || videoTracks.length < 1) {
      this.stop();
      return of(stream);
    }

    videoTracks.forEach(videoTrack => {
      if ((<any>videoTrack).isDummy) {
        stream.removeTrack(videoTrack);
      }
    });

    videoTracks = stream?.getVideoTracks();
    this.sdk.clear();

    if (stream.active) {
      this.loading$.next(true);
      this.sdk.useStream(stream);
      this.sdkStream = this.sdk.getStream();
      this.originalStream = stream;
      return this.loading$.pipe(
        filter(loading => {
          return loading === false;
        }),
        map(() => {
          const end = new Date().getTime();
          this.logger.log('SDK is loaded', `${end - start}ms`);
          if (useCanvas) {
            const track = videoTracks[0];
            const settings = track?.getSettings();
            if (!!settings) {
              this.canvas.width = settings.width;
              this.canvas.height = settings.height;
            }

            this.sdk.toCanvas(this.canvas);

            const canvasVideoTrack = this.canvas
              .captureStream(25)
              .getVideoTracks()
              .find(() => {
                return true;
              });
            if (canvasVideoTrack) {
              this.canvasSdkStream = stream.clone();
              this.canvasSdkStream
                .getVideoTracks()
                .filter(canvasTrack => {
                  return canvasTrack.kind === 'video';
                })
                .forEach(canvasTrack => {
                  return this.canvasSdkStream.removeTrack(canvasTrack);
                });

              this.canvasSdkStream.addTrack(canvasVideoTrack);
              return this.canvasSdkStream;
            }
            return stream;
          }
          return this.sdkStream;
        }),
        take(1)
      );
    } else {
      this.stop();
      return of(stream);
    }
  }

  onError = (e: ErrorObject): void => {
    this.logger.warn('SDK Error', e);
  };

  ngOnDestroy(): void {
    this.stop();
  }
}
