import { Enum, EnumType } from 'ts-jenum';
import { SortDirection } from '@angular/material/sort';

class SortingModesBase extends EnumType<SortingModes>() {
  constructor(
    readonly name,
    readonly sortDirection: SortDirection,
    readonly icon: string
  ) {
    super();
  }
}

@Enum()
export class SortingModes extends EnumType<SortingModes>() {
  static readonly NONE = new SortingModesBase('NONE', null, 'sort-none');
  static readonly ASC = new SortingModesBase('ASC', 'asc', 'sort-asc');
  static readonly DESC = new SortingModesBase('DESC', 'desc', 'sort-desc');

  private constructor(
    readonly name,
    readonly sortDirection: SortDirection,
    readonly icon: string
  ) {
    super();
  }
}
