<div class="chat-container">
  <div *ngIf="chat$ | async as chat; else noDialog" class="messages-container">
    <vks-chat
      [chatId]="chat.id"
      [emitObserve]="false"
      (initParticipantsEdit)="showParticipantsPopup(chat.id)"
    ></vks-chat>
  </div>
</div>
<vks-control-user-select
  #controlUserSelectComponent
  [emailAddresses]="false"
  [excludedUsersIds]="chatParticipantsEntity$ | async"
  [forChat]="true"
  [formControl]="selectedUsersFormControl"
  [multi]="true"
></vks-control-user-select>
<ng-template #noDialog>
  <p class="stub">{{ 'CHOOSE_CONVERSATION' | translate }}</p>
</ng-template>
