<ng-container [ngSwitch]="sourcesPermissions">
  <ng-container *ngSwitchCase="'granted'">
    <video
      [srcObject]="mediaStream?.stream"
      [style.height.px]="ratioHeight$ | async"
      muted
      autoplay
      playsinline
    ></video>
    <div class="effects-loading-stub" *ngIf="effectsLoading$ | async">
      <vks-spinner [color]="'white'"></vks-spinner><span>{{ 'PROCESSING_VIDEO' | translate }}</span>
    </div>
  </ng-container>
  <span *ngSwitchCase="'locked'" class="warning"><p translate>DEVICES_LOCKED</p></span>
  <span *ngSwitchCase="'prompt'" class="warning"><p translate>DEVICES_PERMISSION_PROMPT</p></span>
  <span *ngSwitchCase="'denied'" class="warning"><p translate>DEVICES_PERMISSION_WARNING</p></span>
</ng-container>
<div class="row-flex row-flex-wrap border-top" vks-control-group>
  <div class="vks-sound-detector">
    <vks-icon icon="toggle-microphone-on" color="#0F1114" class="square12"></vks-icon>
    <vks-sound-detector [mediaStream]="audioCheckStream" [disabled]="!audioInput" color="#F68B28"> </vks-sound-detector>
  </div>
  <div></div>
  <vks-audio-player [outputDeviceId]="audioOutput?.id" #soundCheckPlayer source="assets/sounds/sound-check.ogg">
    <button
      [label]="('CHECK' | translate) + ' ' + ('PLAYING_DEVICES' | translate | lowercase)"
      (buttonClick)="!!audioOutput?.id ? soundCheckPlayer.play() : null"
      style="font-size: 12px"
      class="btn-anchor"
      icon="sound-check"
      iconClass="square12"
      type="button"
      vks-button
    ></button>
  </vks-audio-player>
</div>
