import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { User } from '@breez/models';

interface State extends EntityState<User> {}

const adapter: EntityAdapter<User> = createEntityAdapter<User>({
  selectId: (user: User) => {
    return user.id;
  },
  sortComparer: (a: User, b: User) => {
    return a?.name?.localeCompare(b?.name);
  }
});

const initialState: State = adapter.getInitialState();

export { State, adapter, initialState };
