import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ValidationError } from './validation-error.model';

const ERROR = {
  type: 'invalidserverurl',
  label: 'WRONG_SERVER_URL_FORMAT'
} as ValidationError;

export function serverUrlValidator(): ValidatorFn {
  const reg = new RegExp(
    '((wss://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?)|(^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(:[0-9]+)?$)'
  );
  return (control: AbstractControl): ValidationErrors | null => {
    const controlValue = control.value;

    if (!controlValue) {
      return null;
    }
    if (reg.test(controlValue)) {
      return null;
    }

    return { [ERROR.type]: ERROR } as ValidationErrors;
  };
}
