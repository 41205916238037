import { EntityReference, IEntity } from '@breez/models/shared/entity-reference.model';
import { AvatarDisplayable } from '@breez/modules/user/modules/models/avatar-displayable.model';
import { UserStatus } from '@breez/modules/user/modules/models/user-status.enum';
import { Expose, Transform } from 'class-transformer';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { ValidationErrors } from '@angular/forms';
import { ObjectType } from '@breez/shared/enums/object-type.enum';

export class User implements AvatarDisplayable, IEntity {
  @Expose({ name: 'userid' })
  id: number;

  @Expose({ name: 'name' })
  name: string;

  @Expose() login: string;
  @Expose() email: string;

  @Expose({ name: 'phone' })
  phone: string;

  @Expose({ name: 'statusid' })
  statusId: number;

  @Expose({ name: 'statusname' })
  status: UserStatus;

  @Expose({ name: 'state' })
  state: UserStatus;

  @Expose({ name: 'lastaccess' })
  @Transform(
    ({ value }) => {
      if (value instanceof Date) {
        return value;
      }
      if (typeof value === 'number') {
        return new Date(value * 1000);
      }
      return value;
    },
    { toClassOnly: true }
  )
  lastAccess: Date;

  @Expose({ name: 'departmentid' })
  departmentId: number;

  @Expose({ name: 'departmentname' })
  _departmentName: string;

  @Expose({ name: 'departmenttypeid' })
  departmentType: 'external' | 'custom';

  @Expose({ name: 'positionid' })
  positionId: number;

  @Expose({ name: 'jobtitle' })
  jobTitle: string;

  displayName: string;

  type = ObjectType.USER;

  image: string;
  avatarUrl?: string;

  @Expose({ name: 'is_guest' })
  isGuest?: boolean;

  hasNoId: boolean;

  @Expose({ name: 'cancreateconference' })
  canCreateConference: boolean;

  @Expose({ name: 'caninvitedintoconference' })
  @Transform(({ value }) => {
    return !isTruthy(value) || value;
  })
  canInviteToConference: boolean;

  @Expose({ name: 'multilogin' })
  @Transform(({ value }) => {
    return isTruthy(value) ? Number.parseInt(value, 10) : 1;
  })
  multiLogin: number;

  canNotBeRemovedFromPlannerList: boolean;

  constructor(base?: Partial<User>) {
    return Object.assign(this, base);
  }

  compare(anotherReference: IEntity): boolean {
    return EntityReference.prototype.compare.call(this, anotherReference);
  }

  get departmentName(): string {
    return this._departmentName ?? (this.departmentType === 'external' ? 'BREEZ_INVITING_USER_DEPARTMENT' : undefined);
  }
}

export class FormControlUser extends User {
  errors: ValidationErrors;

  hasError(errorKey: string): boolean {
    return this.errors.hasOwnProperty(errorKey);
  }
}
