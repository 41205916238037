import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { IconButtonComponent } from '@breez/modules/controls/components/icon-button/icon-button.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ControlButtonComponent } from './components/control-button';
import { ControlDateComponent } from './components/control-date';
import { ControlDateTimeComponent } from './components/control-date-time';
import { ControlFileDropAreaComponent } from './components/control-file-drop-area';
import { ControlFileImageSelectorComponent } from './components/control-file-image-selector';
import { ControlGroupComponent } from './components/control-group';
import { ControlImageCropperComponent } from './components/control-image-cropper';
import { ControlInputComponent } from './components/control-input';
import { ControlTextareaComponent } from './components/control-textarea';
import { ControlTimePickerComponent } from './components/control-time-picker';
import { InputComponent } from './components/input';
import { IconComponent } from './components/svg-icon';

import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ControlTimeComponent } from '@breez/modules/controls/components/control-time';
import { PipesModule } from '@breez/shared/pipes/pipes.module';
import { PopupModule } from '@breez/modules/popup/popup.module';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SearchControlComponent } from './components/search-control/search-control.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SpinnerComponent } from '@breez/modules/controls/components/spinner/spinner.component';

@NgModule({
  declarations: [
    ControlButtonComponent,
    ControlDateComponent,
    ControlDateTimeComponent,
    ControlFileDropAreaComponent,
    ControlFileImageSelectorComponent,
    ControlGroupComponent,
    ControlImageCropperComponent,
    ControlInputComponent,
    ControlTextareaComponent,
    ControlTimePickerComponent,
    ControlTimeComponent,
    InputComponent,
    IconComponent,
    IconButtonComponent,
    SearchControlComponent,
    SpinnerComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    PipesModule,
    PopupModule,
    ImageCropperModule,
    AngularSvgIconModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatButtonModule
  ],
  exports: [
    ControlButtonComponent,
    ControlDateComponent,
    ControlDateTimeComponent,
    ControlFileDropAreaComponent,
    ControlFileImageSelectorComponent,
    ControlGroupComponent,
    ControlImageCropperComponent,
    ControlInputComponent,
    ControlTextareaComponent,
    ControlTimePickerComponent,
    ControlTimeComponent,
    InputComponent,
    IconComponent,
    IconButtonComponent,
    SearchControlComponent,
    SpinnerComponent
  ]
})
export class ControlsModule {}
