import { Injectable } from '@angular/core';
import { UserStatus } from '@breez/modules/user/modules/models/user-status.enum';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserStatusStorageService {
  private userStatusesMap = new Map<number, UserStatus>();
  private userStatusesMap$ = new ReplaySubject<Map<number, UserStatus>>(1);

  getByUserId(userId: number): UserStatus {
    return this.userStatusesMap.get(userId);
  }

  setByUserId(userId: number, userStatus: UserStatus): void {
    if (this.userStatusesMap.get(userId) === userStatus) {
      return;
    }

    this.userStatusesMap.set(userId, userStatus);
    this.userStatusesMap$.next(this.userStatusesMap);
  }

  observeUserStatuses(): Observable<Map<number, UserStatus>> {
    return this.userStatusesMap$.asObservable();
  }
}
