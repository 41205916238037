import { environment } from '@breez/environment';
import { isRelativeLink } from '@breez/shared/utilities/is-relative-link';

export const ORIGIN_URL_STORAGE_KEY = 'vks-origin-url';

const isElectron = environment.type === 'electron';

export function generateOrigin(value: string): string {
  const origin =
    (isElectron ? localStorage.getItem(ORIGIN_URL_STORAGE_KEY) : null) || environment.origin || location.origin;
  if (!value) {
    return origin;
  }
  return isRelativeLink(value) ? origin : '';
}
