import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ValidationError } from './validation-error.model';

const ERROR = {
  type: 'conferencemulticastingoverflow',
  label: 'CONFERENCE_MULTICASTING_OVERFLOW'
} as ValidationError;

export function conferenceMulticastingOverflowValidator(multicastingOverflow$: BehaviorSubject<boolean>): ValidatorFn {
  return (_: AbstractControl): ValidationErrors | null => {
    if (!multicastingOverflow$.value) {
      return null;
    }
    return { [ERROR.type]: ERROR } as ValidationErrors;
  };
}

export const conferenceMulticastingOverflowErrorType = ERROR;
