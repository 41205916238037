import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { Call } from '@breez/modules/call/call';
import { CallModel, CallStatus } from '@breez/modules/call/models';
import { OverlayContainer } from '@breez/modules/overlay/models';
import { DeviceSourceType } from '@breez/models/webrtc/device-source-type.enum';
import { MediaSourceKind } from '@breez/models/webrtc/media-source-kind.enum';

@Component({
  selector: 'vks-call-status-ring-incoming',
  templateUrl: './type.component.html',
  styleUrls: ['./type.component.scss'],
  animations: [
    trigger('callInsertRemoveTrigger', [
      transition(':enter', [
        style({ transform: 'translateY(100%)' }),
        animate(500, style({ transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        //        style({ transform: 'translateY(0)' }),
        animate(500, style({ transform: 'translateY(100%)' }))
      ])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CallStatusRingIncomingComponent {
  @Input() callModel: CallModel;
  @Input() call: Call;
  @Input() anotherActiveCallId: number;

  @Output() changeOverlayContainer = new EventEmitter<OverlayContainer>();

  @HostBinding('@callInsertRemoveTrigger')
  animatePage = true;

  deviceSourceType = DeviceSourceType;
  mediaSourceKind = MediaSourceKind;
  callStatus = CallStatus;

  onChangeCall(
    status: CallStatus,
    data?: { kind: MediaSourceKind; call?: Pick<CallModel, 'id' | 'status'> },
    eventInitiatorTab = false
  ): void {
    this.call.changeCall(status, data, eventInitiatorTab).subscribe();
  }
}
