<div class="filter-item">
  <p>{{ item.filterName | translate | uppercase }}:</p>
  <div [matMenuTriggerFor]="menu" class="arrow">
    {{ displayedFilterValue | translate | uppercase }}
    <vks-icon class="square24" color="#005259" icon="keyboard-arrow-down"></vks-icon>
  </div>
  <mat-menu #menu="matMenu" [hasBackdrop]="true" backdropClass="back-drop" class="filter-menu date-picker-menu">
    <div class="wrapper">
      <div>
        <!--                TODO: подумать над тем, чтобы динамически формировать опции-->
        <p class="menu-label">{{ item.header | translate | uppercase }}</p>
        <button
          *ngFor="let option of item.options"
          [class.selected-option]="option.key === displayedFilterValue"
          (click)="selectFilterValue(option.key)"
          mat-menu-item
        >
          {{ option.key | translate }}
        </button>
      </div>
      <mat-divider [vertical]="true"></mat-divider>
      <div class="date-pickers">
        <div (click)="$event.stopPropagation()">
          <p class="menu-label">{{ 'CUSTOM_PERIOD' | translate | uppercase }}</p>
          <div class="pickers-container">
            <form [formGroup]="formGroup">
              <vks-date-range
                [control]="formGroup.controls.startDate"
                [maxValue]="formGroup.controls.endDate.value"
              ></vks-date-range>
              <div class="arrow">
                <vks-icon color="#005259" icon="arrow-down"></vks-icon>
              </div>
              <vks-date-range
                [control]="formGroup.controls.endDate"
                [minValue]="formGroup.controls.startDate.value"
              ></vks-date-range>
            </form>
          </div>
        </div>
        <button
          vks-button
          [label]="'SELECT' | translate"
          (buttonClick)="setCustomPeriod()"
          class="btn-primary btn-sm"
        ></button>
      </div>
    </div>
  </mat-menu>
</div>
