import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@breez/app.module';
import { environment } from '@breez/environment';
// tslint:disable-next-line:no-import-side-effect
import 'hammerjs';
// oO
const head = document.querySelector('head');
head.setAttribute('breez-release', environment.release);

if (environment.type === 'production' || environment.type === 'electron') {
  head.setAttribute('breez-version', environment.version);
  enableProdMode();
} else {
  const now = new Date();
  const date = `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()} ${now.getHours()}:${now.getMinutes()}`;
  head.setAttribute('breez-version', date);
}

const bootstrap = (): any => {
  return platformBrowserDynamic().bootstrapModule(AppModule);
};

bootstrap().catch(err => {
  return console.error(err);
});
