import { Injectable } from '@angular/core';
import { from, merge, Observable, of, ReplaySubject, timer } from 'rxjs';
import { replayWhileSubs } from '@breez/shared/rxjs-operators';
import { APP_KEYCLOAK_REFRESH, APP_KEYCLOAK_TOKEN, RestApiService } from '@breez/rest-api.service';
import {
  catchError,
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  map,
  pluck,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { waitFor } from '@breez/shared/rxjs-operators/wait-for';
import { environment } from '@breez/environment';
import { KeycloakLoginOptions, KeycloakTokenParsed } from 'keycloak-js';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import jwt_decode from 'jwt-decode';
import { AppInfoAuthModel } from '@breez/models/app-info.model';
import { ElectronService } from '@breez/modules/core/services';
import { LoggerService } from '@breez/shared/services/logger.service';

const TIME_TO_UPDATE_END_TOKEN = 120; // 120
const MAXIMUM_COUNT_OF_RETRY_UPDATE_TOKEN = 2;

@Injectable({
  providedIn: 'root'
})
export class KeycloakAuthService {
  private authTokenSubject$: ReplaySubject<string> = new ReplaySubject<string>();

  logoutTrigger$: ReplaySubject<void> = new ReplaySubject<void>();
  countOfRetryUpdateToken = 0;
  isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  isMac = /(Mac)/i.test(navigator.platform);

  authToken$: Observable<string> = this.authTokenSubject$.pipe(
    filter(isTruthy),
    replayWhileSubs(),
    distinctUntilChanged()
  );

  keycloakInitTrigger$: Observable<void> = this.restApiService.readyTrigger$.pipe(replayWhileSubs());

  keycloakActive$: ReplaySubject<void> = new ReplaySubject<void>();

  isKeycloakLoggedInPromise$: Promise<boolean> = this.keycloakService.isLoggedIn();

  isKeycloakLoggedInEvent$: Observable<boolean> = this.keycloakInitTrigger$.pipe(
    switchMap(() => {
      return this.keycloakService.keycloakEvents$;
    }),
    map(event => {
      switch (event.type) {
        case KeycloakEventType.OnAuthSuccess:
        case KeycloakEventType.OnAuthRefreshSuccess:
          return true;
        case KeycloakEventType.OnReady:
          return !!event?.args;
        default:
          return undefined;
      }
    }),
    filter(isTruthy),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  isKeycloakLoggedIn$: Observable<boolean> = this.isKeycloakLoggedInEvent$.pipe(
    distinctUntilChanged(),
    replayWhileSubs()
  );

  private isElectron = environment.type === 'electron';
  needRefreshTokenTrigger$: ReplaySubject<void> = new ReplaySubject<void>(1);

  constructor(
    private restApiService: RestApiService,
    private keycloakService: KeycloakService,
    private electronService: ElectronService,
    private loggerService: LoggerService
  ) {
    this.authToken$.pipe().subscribe(token => {
      return this.loggerService.authToken$.next(token);
    });

    this.keycloakActive$
      .pipe(
        switchMap(() => {
          return timer(30000, 30000).pipe(
            switchMap(() => {
              return this.checkAuth(false);
            })
          );
        }),
        filter(auth => {
          return auth === true;
        }),
        switchMap(() => {
          return this.refreshToken(TIME_TO_UPDATE_END_TOKEN);
        }),
        filter(isTruthy)
      )
      .subscribe(isRefresh => {
        if (isRefresh) {
          this.setToken().subscribe();
        }
      });
  }

  get auth$(): Observable<AppInfoAuthModel> {
    return this.restApiService.info$.pipe(pluck('auth'), filter(isTruthy));
  }

  init(directTokens?: { access_token: string; refresh_token: string }): Observable<boolean> {
    const isElectron = environment.type === 'electron';

    if (isTruthy(directTokens?.refresh_token) && isTruthy(directTokens?.access_token)) {
      this.restApiService.saveToLocal(APP_KEYCLOAK_REFRESH, directTokens?.refresh_token, this.isElectron);
      this.restApiService.saveToLocal(APP_KEYCLOAK_TOKEN, directTokens?.access_token, this.isElectron);
    }

    return this.restApiService.wrongApiStructure$.pipe(
      take(1),
      switchMap(wrongApiStructure => {
        return isElectron && wrongApiStructure
          ? of(true)
          : this.auth$.pipe(
              take(1),
              switchMap(auth => {
                const config = {
                  url: auth.url,
                  realm: auth.realm[0],
                  clientId: auth.clientid
                };

                const refreshToken =
                  directTokens?.refresh_token ??
                  this.restApiService.getFromLocal<string>(APP_KEYCLOAK_REFRESH, this.isElectron);
                const token =
                  directTokens?.access_token ??
                  this.restApiService.getFromLocal<string>(APP_KEYCLOAK_TOKEN, this.isElectron);

                const tokens = refreshToken && token ? { refreshToken, token } : {};

                let initOptions = {
                  redirectUri: auth.redirect_uri ?? window.location.origin,

                  ...tokens
                };

                if (!isElectron) {
                  initOptions = {
                    ...initOptions,
                    ...{
                      checkLoginIframe: false,
                      silentCheckSsoFallback: false,
                      silentCheckSsoRedirectUri: window.location.origin + '/assets/verify-sso.html',
                      // onLoad: (!this.isSafari || !this.isMac)?'login-required':'check-sso',
                      onLoad: `check-sso`
                    }
                  };
                } else {
                  initOptions = {
                    ...initOptions,
                    ...{
                      checkLoginIframe: false,
                      silentCheckSsoRedirectUri: window.location.origin + '/assets/verify-sso.html',
                      // onLoad: `login-required`,
                      // onLoad: `check-sso`,
                      scope: 'offline_access'
                      // adapter: 'cordova'
                    }
                  };
                }

                this.keycloakActive$.next();
                return this.keycloakService.init({
                  loadUserProfileAtStartUp: true,
                  config,
                  // @ts-ignore
                  initOptions,
                  enableBearerInterceptor: true,
                  bearerExcludedUrls: ['/assets']
                });
              })
            );
      }),
      catchError(err => {
        console.error(err);
        this.logoutTrigger$.next();
        return of(true);
      })
    );
  }

  loginToKeycloak(options?: KeycloakLoginOptions): Observable<boolean> {
    if (!this.isElectron) {
      this.keycloakService.login({
        ...options
      });
      return of(false);
    } else {
      return this.restApiService.ident$.pipe(
        take(1),
        map(ident => {
          return ident.clientId;
        }),
        filter(isTruthy),
        switchMap(clientId => {
          return this.restApiService.getAppInfo(clientId);
        }),
        tap(info => {
          this.restApiService.info = info;
          const { auth } = info;
          const url = `${auth.url}/realms/${auth.realm[0]}/protocol/openid-connect/auth?client_id=${auth.clientid}&redirect_uri=${encodeURIComponent(auth.redirect_uri)}&response_mode=fragment&response_type=code&scope=openid%20offline_access`;
          this.electronService.openUrl(url, false, true);
        }),
        switchMap(() => {
          return this.restApiService.apiCheckAuth();
        }),
        switchMap(directTokens => {
          return this.init(directTokens);
        }),
        take(1),
        switchMap(() => {
          return this.isKeycloakLoggedIn$;
        })
      );
    }
  }

  checkAuth(setToken = true): Observable<boolean> {
    return this.isKeycloakLoggedIn$.pipe(
      waitFor(() => {
        return this.restApiService.readyTrigger$;
      }),
      take(1),
      tap(auth => {
        if (auth && setToken) {
          this.setToken().subscribe();
        }
      })
    );
  }

  setToken(token?: string): Observable<string | null> {
    if (token) {
      this.authTokenSubject$.next(token);
      this.restApiService.saveToLocal(APP_KEYCLOAK_TOKEN, token, this.isElectron);
      return of(token);
    } else {
      return from(this.keycloakService.getToken()).pipe(
        take(1),
        switchMap(keycloakToken => {
          return !!keycloakToken ? this.setToken(keycloakToken) : of(null);
        })
      );
    }
  }

  accessToken$: Observable<string> = merge(
    merge(this.keycloakInitTrigger$, this.needRefreshTokenTrigger$).pipe(
      debounceTime(100),
      switchMap(() => {
        return from(this.keycloakService.getToken());
      })
    ),
    this.authToken$
  ).pipe(
    switchMap(token => {
      const parsedToken = jwt_decode<KeycloakTokenParsed>(token);
      const exp = parsedToken.exp * 1000;
      if (this.countOfRetryUpdateToken >= MAXIMUM_COUNT_OF_RETRY_UPDATE_TOKEN) {
        return of(token);
      }
      if (exp - TIME_TO_UPDATE_END_TOKEN * 1000 <= Date.now()) {
        return this.refreshToken(TIME_TO_UPDATE_END_TOKEN).pipe(
          switchMap(isRefresh => {
            if (isRefresh) {
              this.countOfRetryUpdateToken++;
            }
            this.setToken().subscribe();
            return isRefresh ? from(this.keycloakService.getToken()).pipe(delay(1000)) : of(token);
          })
        );
      }
      this.countOfRetryUpdateToken = 0;
      return of(token);
    }),
    distinctUntilChanged()
  );

  refreshToken(timeBeforeEnd: number): Observable<boolean> {
    const refreshExp = this.keycloakService.getKeycloakInstance().refreshTokenParsed.exp * 1000;
    if (refreshExp <= Date.now()) {
      this.logoutTrigger$.next();
      // this.keycloakService.logout();
      return of(null);
    }
    return from(this.keycloakService.updateToken(timeBeforeEnd)).pipe(take(1));
  }
}
