import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  SimpleChanges
} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Moment } from 'moment';

@UntilDestroy()
@Component({
  selector: 'vks-control-date',
  templateUrl: './control-date.component.html',
  styleUrls: ['./control-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => {
        return ControlDateComponent;
      }),
      multi: true
    }
  ]
})
export class ControlDateComponent implements OnInit, OnDestroy, ControlValueAccessor, OnChanges {
  @Input() disable: boolean;

  changedTrigger: (value: any) => void;
  touchedTrigger: () => void;

  control = new UntypedFormControl();

  constructor(@Optional() private upControl: UntypedFormControl) {}

  ngOnInit(): void {
    this.control.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      if (this.changedTrigger && typeof this.changedTrigger === 'function') {
        // TODO отличная мотивация избавиться от момента
        if (value && 'toDate' in value) {
          value = (<Moment>value).toDate();
        }
        this.changedTrigger(value);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disable && changes.disable.currentValue === true) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  writeValue(date: Date): void {
    if (date && date instanceof Date) {
      this.control.patchValue(date);
    }
  }

  registerOnChange(fn: (value: any) => void): void {
    if (fn && typeof fn === 'function') {
      this.changedTrigger = fn;
    }
  }

  registerOnTouched(fn: () => void): void {
    if (fn && typeof fn === 'function') {
      this.touchedTrigger = fn;
    }
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy(): void {}

  setTouched(): void {
    this.touchedTrigger();
  }
}
