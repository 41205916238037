import { Inject, Injectable, Type } from '@angular/core';
import { NgxdResolver } from '@ngxd/core';
import { NotificationType } from '@breez/models/notification/notification-type.enum';

import {
  NOTIFICATIONTYPES_PROVIDER,
  NotificationTypesComponentProvider
} from './notification-types-component.provider';

@Injectable()
export class NotificationTypesComponentResolver extends NgxdResolver<NotificationType, Type<any>> {
  constructor(@Inject(NOTIFICATIONTYPES_PROVIDER) private providers: NotificationTypesComponentProvider[]) {
    super(providers);
  }
}
