<div
  [class.fulscreen]="isFullscreen$ | async"
  [class.video-playing]="remotePlaying$ | async"
  [style.height.px]="(hostSize$ | async)?.height"
  [style.width.px]="(hostSize$ | async)?.width"
  class="ratio-box"
>
  <div class="ratio-box-entry">
    <div [class.text-shadow]="remotePlaying$ | async" class="call-info">
      <div class="peer-name">
        {{ peer.name }}
      </div>
      <div class="call-duration">
        {{ call.callDurationDate$ | async | timeDurationFormat }}
      </div>
    </div>
    <div class="avatar">
      <vks-user-avatar
        [entity]="callModel.destinationType === callDestinationType.OUTGOING ? callModel.answerer : callModel.caller"
        [size]="isSmartOverlayContainer ? 64 : 96"
      ></vks-user-avatar>
    </div>
    <audio #remoteAudio autoplay></audio>
    <video
      #remoteScreen
      [class.opacity]="remoteScreenPlaying$ | async"
      autoplay
      class="video screen"
      muted
      playsinline
    ></video>
    <video
      #remoteVideo
      [class.opacity]="remoteVideoPlaying$ | async"
      [class.reduced]="remoteScreenPlaying$ | async"
      autoplay
      class="video facing reduce-left"
      muted
      playsinline
    ></video>
    <video #localVideo autoplay class="video reduced reduce-right opacity local-video" muted playsinline></video>
    <div class="actions">
      <div class="side">
        <div class="block">
          <vks-icon
            [hasHover]="true"
            [matTooltip]="'VISIT_CHAT' | translate"
            (click)="visitChat()"
            color="white"
            icon="visit-chat-p2p"
          ></vks-icon>
        </div>
        <div class="block">
          <!--    <vks-icon (click)="onChangeCall(callStatus.TRANSFER)"-->
          <!--    <vks-icon [matTooltip]="'CALL.FORWARD' | translate"
												icon="call-forward"
												[hasHover]="true"
												color="white" [class.disabled]="true"
												hoverColor="white"></vks-icon>-->
          <!--    <vks-icon -->
          <vks-icon
            [hasHover]="true"
            [matTooltip]="'CALL.HOLD' | translate"
            (click)="onChangeCall(callStatus.HOLD)"
            color="white"
            hoverColor="white"
            icon="call-hold"
          ></vks-icon>
          <div
            (mouseenter)="volumeControlState = 'expanded'"
            (mouseleave)="volumeControlState = 'collapsed'"
            class="volume-control"
          >
            <vks-icon
              [hasHover]="true"
              [icon]="remoteAudio.volume === 0 ? 'sound-off-call' : 'sound-on-call'"
              [matTooltip]="'VOLUME' | translate"
              (click)="toggleVolume()"
              color="white"
              hoverColor="white"
            ></vks-icon>
            <div class="volume-control-track">
              <div [@volumeTrackCoverAnimation]="volumeControlState" class="volume-control-track-cover"></div>
              <mat-slider
                #slider
                [@volumeSliderAnimation]="volumeControlState"
                [value]="call.volume$ | async"
                (change)="slider.blur()"
                (input)="focusMonitor.focusVia(sliderElementRef.nativeElement, 'mouse'); setVolume($event.value)"
                max="1"
                min="0"
                step="0.01"
                vertical
              ></mat-slider>
            </div>
          </div>
          <vks-icon
            [hasHover]="true"
            [matTooltip]="'DEVICE_SETTINGS' | translate"
            (click)="onOpenSettings()"
            color="white"
            hoverColor="white"
            icon="call-settings"
          ></vks-icon>
        </div>
      </div>
      <div class="middle">
        <vks-icon
          [class.square48]="narrow"
          [class.square64]="!narrow"
          [hasHover]="true"
          [matTooltip]="'CALL.HANGUP' | translate"
          (click)="onChangeCall(callStatus.HANGUP, $event)"
          color="white"
          hoverColor="white"
          icon="call-cancel"
        ></vks-icon>
      </div>
      <div class="side">
        <div class="block">
          <ng-container></ng-container>
          <vks-icon
            *ngIf="screencastAvailable$ | async"
            [hasHover]="true"
            [icon]="isSelected(CallContentType.SCREEN_VIDEO) ? 'call-screencast-off' : 'call-screencast-on'"
            [matTooltip]="
              (isSelected(CallContentType.SCREEN_VIDEO) ? 'DISABLE_SCREENCAST' : 'ENABLE_SCREENCAST') | translate
            "
            (debounceClick)="onChangeVideo(!isSelected(CallContentType.SCREEN_VIDEO), CallContentType.SCREEN_VIDEO)"
            vks-debounce-click
            [debounceTime]="300"
            color="white"
            hoverColor="white"
          ></vks-icon>
          <vks-icon
            *ngIf="audioInputAvailable$ | async"
            [class.disabled]="!(availableAudioDevice$ | async)"
            [hasHover]="true"
            [icon]="!isSelected(CallContentType.FACING_AUDIO) ? 'call-mic-off' : 'call-mic-on'"
            [matTooltip]="
              (isSelected(CallContentType.FACING_AUDIO) ? 'DISABLE_MICROPHONE' : 'ENABLE_MICROPHONE') | translate
            "
            (debounceClick)="onChangeAudio(!isSelected(CallContentType.FACING_AUDIO))"
            vks-debounce-click
            [debounceTime]="300"
            color="white"
            hoverColor="white"
          ></vks-icon>
          <vks-icon
            *ngIf="videoInputAvailable$ | async"
            [class.disabled]="!(availableHardwareDevice$ | async)"
            [hasHover]="true"
            [icon]="!isSelected(CallContentType.FACING_VIDEO) ? 'call-video-off' : 'call-video-on'"
            [matTooltip]="(isSelected(CallContentType.FACING_VIDEO) ? 'DISABLE_CAMERA' : 'ENABLE_CAMERA') | translate"
            (debounceClick)="onChangeVideo(!isSelected(CallContentType.FACING_VIDEO), CallContentType.FACING_VIDEO)"
            vks-debounce-click
            [debounceTime]="300"
            color="white"
            hoverColor="white"
          ></vks-icon>
        </div>
        <div class="block">
          <vks-icon
            *ngIf="!(isFullscreen$ | async)"
            [hasHover]="true"
            [matTooltip]="'ENABLE_FULLSCREEN' | translate"
            (debounceClick)="onFullScreen(true)"
            vks-debounce-click
            [debounceTime]="300"
            color="white"
            hoverColor="white"
            icon="call-fullscreen-on"
          ></vks-icon>
          <vks-icon
            *ngIf="isFullscreen$ | async"
            [hasHover]="true"
            [matTooltip]="'DISABLE_FULLSCREEN' | translate"
            (debounceClick)="onFullScreen(false)"
            vks-debounce-click
            [debounceTime]="300"
            color="white"
            hoverColor="white"
            icon="call-fullscreen-off"
          ></vks-icon>
        </div>
      </div>
    </div>
  </div>
</div>
