import { NgModule } from '@angular/core';

import { NgxdModule } from '@ngxd/core';
import { UserListTypesModule } from '../user-list-types.module';
import { UserListTypeDynamicComponent } from './dynamic.component';

@NgModule({
  imports: [NgxdModule, UserListTypesModule],
  declarations: [UserListTypeDynamicComponent],
  exports: [UserListTypeDynamicComponent]
})
export class UserListDynamicModule {}
