import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ValidationError } from './validation-error.model';

const ERROR = {
  type: 'invalidpassword',
  label: ''
} as ValidationError;

export function passwordValidator(): ValidatorFn {
  const atLeastOne = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  const allowedCharacters = /^[a-zA-Z0-9_.-]*$/;
  const isNotAllowedCharacters = /[^a-zA-Z0-9_.-]/;

  return (control: AbstractControl): ValidationErrors | null => {
    const controlValue = control.value;

    if (!controlValue) {
      return null;
    }

    if (allowedCharacters.test(controlValue) && atLeastOne.test(controlValue)) {
      return null;
    }

    let errorString = '';

    if (!allowedCharacters.test(controlValue) || !atLeastOne.test(controlValue)) {
      errorString = 'BREEZ_REGISTRATION_FORMAT_PASSWORD_ERROR';
    }

    if (atLeastOne.test(controlValue) && isNotAllowedCharacters.test(controlValue)) {
      errorString = 'BREEZ_REGISTRATION_FORMAT_PASSWORD_ERROR_NOT_ALLOWED_SYMBOLS';
      return { [ERROR.type]: { ...ERROR, label: errorString } } as ValidationErrors;
    }

    if (
      (!allowedCharacters.test(controlValue) || !atLeastOne.test(controlValue)) &&
      isNotAllowedCharacters.test(controlValue)
    ) {
      errorString = 'BREEZ_REGISTRATION_FORMAT_PASSWORD_ERROR_AND_NOT_ALLOWED_SYMBOLS';
    }

    return { [ERROR.type]: { ...ERROR, label: errorString } } as ValidationErrors;
  };
}
