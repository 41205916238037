import { Inject, Injectable, Type } from '@angular/core';
import { NgxdResolver } from '@ngxd/core';
import { OverlayEntryType } from '@breez/modules/overlay/models/overlay-entry-type.enum';

import { OVERLAYENTRY_PROVIDER, OverlayEntryTypeProvider } from './overlay-entry-type.provider';

@Injectable()
export class OverlayEntryTypeResolver extends NgxdResolver<OverlayEntryType, Type<any>> {
  constructor(@Inject(OVERLAYENTRY_PROVIDER) providers: OverlayEntryTypeProvider[]) {
    super(providers);
  }
}
