import { createAction } from '@ngrx/store';
import { ErrorMessageModel } from '@breez/models/+state';
import { PropertiesActionObjectId, propsProcessing } from '@breez/helpers/+state/execution-action.helper';
import { LoadUsersAction } from '@breez/modules/users/interfaces/+state/load-users-action';

export const loadUsers = createAction(
  '[User/API] Load users',
  propsProcessing<LoadUsersAction & Partial<PropertiesActionObjectId>>
);

export const loadUsersSuccess = createAction('[User/API] Load users Success', (params: LoadUsersAction) => {
  return propsProcessing<LoadUsersAction>(params, loadUsers, 'users');
});

export const loadUsersFailure = createAction(
  '[User/API] Load users Failure',
  (params: ErrorMessageModel & LoadUsersAction) => {
    return propsProcessing<ErrorMessageModel & LoadUsersAction>(params, loadUsers);
  }
);
