<vks-layout class="global-chat-container">
  <!--	<div *ngIf="executionProcessing$ | async"
			class="loader">
		<mat-spinner diameter="30"
					 strokeWidth="2"></mat-spinner>
	</div>-->
  <vks-chat-list
    *ngIf="hideChatList$ | async | not"
    #chatListComponent
    [chats]="chats$ | async"
    [selectedChat]="chat$ | async"
    [class.full-width]="hideChatMessages$ | async"
    [currentUser]="currentUser$ | async"
    [isConferenceChatList]="false"
    class="chat-list"
  >
  </vks-chat-list>
  <vks-chat-wrapper
    *ngIf="hideChatMessages$ | async | not"
    [class.full-width]="hideChatList$ | async"
    [currentUser]="currentUser$ | async"
    [rearrangeInterface]="rearrangeInterface$ | async"
    class="chat-messages"
  >
  </vks-chat-wrapper>
</vks-layout>
