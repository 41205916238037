import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { delay, filter, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { EMPTY_ID } from '@breez/models';
import * as ExecutionActions from '@breez/+state/execution/execution.actions';
import * as ExecutionSelectors from '@breez/+state/execution/execution.selectors';
import { Store } from '@ngrx/store';
import * as ModuleState from '@breez/modules/chat/+state/module.state';
import { of } from 'rxjs';

@Injectable()
export class ExecutionEffects {
  // noinspection JSUnusedGlobalSymbols
  anyProcessing$ = createEffect(() => {
    return this.actions$.pipe(
      filter(action => {
        // @ts-ignore
        return isTruthy(action?.processing) && action?.processing === true;
      }),
      map(action => {
        // @ts-ignore
        const objectId = action.hasOwnProperty('objectId') ? action.objectId : EMPTY_ID;
        const timeout = action.hasOwnProperty('timeout') ? (<any>action).timeout : null;
        if (action.hasOwnProperty('sourceAction')) {
          return ExecutionActions.stopProcessing({ action, objectId });
        } else {
          return ExecutionActions.startProcessing(timeout ? { action, objectId, timeout } : { action, objectId });
        }
      })
    );
  });

  timeoutProcessing$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ExecutionActions.startProcessing),
      filter(action => {
        const sourceAction = action.action;
        const timeout = action.hasOwnProperty('timeout') ? action.timeout : null;
        return isTruthy(sourceAction) && isTruthy(timeout);
      }),
      mergeMap(action => {
        const sourceAction = action.action;
        const timeout = action.hasOwnProperty('timeout') ? action.timeout : null;
        const objectId = action.hasOwnProperty('objectId') ? action.objectId : EMPTY_ID;

        return of(null).pipe(
          delay(timeout),
          switchMap(() => {
            // @ts-ignore
            return this.store.select(ExecutionSelectors.executionResult(sourceAction, objectId));
          }),
          take(1),
          map(hasResult => {
            return { hasResult, action };
          })
        );
      }),
      filter(({ hasResult }) => {
        return hasResult === false;
      }),
      map(({ action }) => {
        const objectId = action.hasOwnProperty('objectId') ? action.objectId : EMPTY_ID;
        // @ts-ignore
        return ExecutionActions.stopProcessing({
          action: { ...action.action },
          objectId
        });
      })
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store<ModuleState.State>
  ) {}
}
