import { Expose } from 'class-transformer';

export class BanDescription {
  @Expose({ name: 'prevention' })
  prevention: string;

  @Expose({ name: 'reason' })
  reason: string;

  constructor(base?: Partial<BanDescription>) {
    return Object.assign(this, base);
  }
}
