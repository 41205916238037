import { Injectable } from '@angular/core';
import { LanguageModel } from '@breez/models';
import { TranslationService } from '@breez/shared/modules/translation/interfaces/translation-service.interface';
import { BehaviorSubject, interval, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';
import { ElectronService } from '@breez/modules/core/services';
import { ELECTRON_CHANNEL_LIST } from '../../../../electron-channel-list';
import { LANGUAGES } from '@breez/shared/modules/translation/services/base-translation.service';
import { TranslationStorage } from '@breez/shared/modules/translation/interfaces/translation-storage.interface';
import { WebsocketEvents, WebsocketService } from '@breez/modules/websocket';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { TranslateService } from '@ngx-translate/core';
import * as ModuleState from '@breez/+state/account/account.state';
import * as AccountSelectors from '@breez/+state/account/account.selectors';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private isSavedLocaleOnServer: string;
  private readonly interfaceLanguageSubject = new BehaviorSubject<string>(this.getInterfaceLanguage());
  readonly interfaceLanguage$: Observable<string> = this.interfaceLanguageSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  constructor(
    private translationService: TranslationService,
    private translationStorage: TranslationStorage,
    private translateService: TranslateService,
    private wsService: WebsocketService,
    private electronService: ElectronService,
    private store: Store<ModuleState.State>
  ) {
    this.interfaceLanguage$.pipe().subscribe(language => {
      this.translateService.use(language).pipe(take(1)).subscribe();
      this.translationService.setLanguage(language);
      if (this.electronService.isElectron) {
        this.electronService.electronApi.send(ELECTRON_CHANNEL_LIST.SET_LANGUAGE, language);
      }
    });
    this.getLocaleFromServer();

    // wait for electron's main process get current os lang
    // and set it to localStorage, so then we can get it here
    // and use as default
    interval(500)
      .pipe(take(1))
      .subscribe(() => {
        const startingInterfaceLanguage = this.getInterfaceLanguage() || this.translationService.getLanguage();
        this.switchInterfaceLanguage(startingInterfaceLanguage);
      });
  }

  private getLocaleFromServer(): void {
    this.store
      .select(AccountSelectors.getAccount())
      .pipe(filter(isTruthy))
      .subscribe(account => {
        this.isSavedLocaleOnServer = account.data.user?.locale || '';
        this.switchInterfaceLanguage(this.isSavedLocaleOnServer || this.translationService.getLanguage());
      });
  }

  private sendLocaleToServer(locale: string): void {
    this.wsService
      .send(WebsocketEvents.RECEIVE.ACCOUNT.UPDATE, { data: { locale } })
      .pipe(take(1))
      .subscribe(res => {
        if (res === true) {
          this.isSavedLocaleOnServer = locale;
        }
      });
  }

  switchInterfaceLanguage(language: string): void {
    this.interfaceLanguageSubject.next(language);
    this.translationStorage.setLanguage(language);
    if (this.isSavedLocaleOnServer === language || this.isSavedLocaleOnServer === undefined) {
      // undefined будет если event init еще не пришел с сервера
      return;
    }

    this.sendLocaleToServer(language);
  }

  private getInterfaceLanguage(): string {
    return this.translationStorage.getLanguage();
  }

  getLanguagesInfo(): Observable<LanguageModel[]> {
    return of(LANGUAGES);
  }

  getLanguageInfo(languageId: string): Observable<LanguageModel> {
    return of(
      LANGUAGES.find(language => {
        return language.id === languageId;
      })
    );
  }

  joinByCommasWithAndWord(entries: string[]): Observable<string> {
    if (!Array.isArray(entries)) {
      return of('');
    }

    return this.translationService.stream('AND').pipe(
      map(andWord => {
        return entries
          .slice(0, entries.length - 1)
          .join(', ')
          .concat(' ', andWord, ' ', entries[entries.length - 1]);
      })
    );
  }
}
