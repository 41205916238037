export enum CallStatus {
  RING = 'ring',
  ACCEPT = 'accept',
  DECLINE = 'decline',
  CANCEL = 'cancel',
  TRANSFER = 'forward',
  HOLD = 'hold',
  HANGUP = 'hangup' // взять и повесить
  // ACCEPT_INVITE = 'accept-invite',
  // DECLINE_INVITE = 'decline-invite'
}
