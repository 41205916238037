import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { SanitizedError } from './interfaces/sanitized-error.interfaces';
import { ERROR_HANDLER_CONFIG } from './constants/error-handler.config';
import { environment } from '@breez/environment';
import { LoggerService } from '@breez/shared/services/logger.service';
import { Router } from '@angular/router';
import { ignoredErrors } from './constants/error-handler.constants';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {
  constructor(
    private injector: Injector,
    private logger: LoggerService,
    private router: Router
  ) {}

  handleError(error: any): void {
    const currentUrl = this.router.url;
    const sanitised = this.sanitiseError(error, currentUrl);
    const { errorHandlerHooks } = this.injector.get(ERROR_HANDLER_CONFIG);
    const ngZone = this.injector.get(NgZone);
    this.logger.trace(sanitised);

    this.runHooks(error, errorHandlerHooks);
    ngZone.run(() => {
      this.handleErrorByEnvironment(sanitised, error, currentUrl);
    });
  }

  private handleErrorByEnvironment(sanitisedError: any, originalError: any, currentUrl: string): void {
    if (this.shouldIgnoreError(sanitisedError.message)) {
      return;
    }

    console.error(this.buildErrorText(sanitisedError, originalError, currentUrl));
  }

  private buildErrorText(sanitisedError: any, originalError: any, currentUrl: string): string {
    if (environment.type == 'production') {
      return sanitisedError.message;
    }

    return `${originalError.stack} (URL: ${currentUrl})`;
  }

  private shouldIgnoreError(errorMessage: string): boolean {
    return ignoredErrors.some(ignoredError => {
      return errorMessage.includes(ignoredError);
    });
  }

  private sanitiseError(error: Error | HttpErrorResponse, currentUrl: string): SanitizedError {
    const sanitisedError: SanitizedError = {
      message: `${error.message} (URL: ${currentUrl})`,
      details: []
    };
    if (error instanceof Error) {
      return this.sanitiseErrorInstance(error, sanitisedError);
    }
    if (error instanceof HttpErrorResponse) {
      return this.sanitiseHttpErrorResponse(error, sanitisedError);
    }
    return this.sanitiseUnknownError(error, sanitisedError);
  }

  private sanitiseErrorInstance(error: Error, sanitisedError: SanitizedError): SanitizedError {
    sanitisedError.details.push(error.stack);
    return sanitisedError;
  }

  private sanitiseHttpErrorResponse(error: HttpErrorResponse, sanitisedError: SanitizedError): SanitizedError {
    sanitisedError.details = Object.keys(error).map((key: string) => {
      return `${key}: ${error[key]}`;
    });
    return sanitisedError;
  }

  private sanitiseUnknownError(error: any, sanitisedError: SanitizedError): SanitizedError {
    sanitisedError.details.push(JSON.stringify(error));
    return sanitisedError;
  }

  private runHooks(error, errorHandlerHooks: Array<(error: any) => void> = []): void {
    errorHandlerHooks.forEach(hook => {
      return hook(error);
    });
  }
}
