<ng-container [sticky]="isSticky" matColumnDef>
  <th
    *matHeaderCellDef
    [disabled]="!column.columnLabel || disabledSorting"
    class="sticky"
    mat-header-cell
    mat-sort-header
  >
    {{ column.columnLabel | translate }}
    <vks-icon *ngIf="showPinIcon" [icon]="pinIcon" class="square28"></vks-icon>
  </th>

  <ng-container [ngSwitch]="column.type">
    <ng-container *ngSwitchCase="allColumnTypes.CLICK">
      <td
        *matCellDef="let data"
        [ngStyle]="column.cssProp"
        (click)="clickOnCell(data)"
        class="click-item sticky"
        mat-cell
      >
        {{ data | nestedFields: valuePath }}
      </td>
    </ng-container>
    <ng-container *ngSwitchCase="allColumnTypes.CONFERENCE_STATE">
      <td *matCellDef="let data" [ngStyle]="column.cssProp" class="sticky" mat-cell>
        {{ getConferenceState(data) | emptyWrapper: column.emptyLabel | conferenceState | translate }}
      </td>
    </ng-container>
    <ng-container *ngSwitchCase="allColumnTypes.STRING">
      <td *matCellDef="let data" [ngStyle]="column.cssProp" class="sticky" mat-cell>
        {{ data | nestedFields: valuePath | emptyWrapper: column.emptyLabel | translate }}
      </td>
    </ng-container>

    <ng-container *ngSwitchCase="allColumnTypes.DATE">
      <td *matCellDef="let data" [ngStyle]="column.cssProp" class="sticky" mat-cell>
        {{
          data.conferenceType === conferenceTypeEnum.CONSTANT
            ? '—'
            : (data | nestedFields: valuePath | formatDate: 'short')
        }}
      </td>
    </ng-container>

    <ng-container *ngSwitchCase="allColumnTypes.DURATION">
      <td *matCellDef="let data" [ngStyle]="column.cssProp" class="sticky" mat-cell>
        {{ data[column.period.start] | durationFormat: data[column.period.end] | async | translate }}
      </td>
    </ng-container>

    <ng-container *ngSwitchCase="allColumnTypes.CONFERENCE_PARAMETERS">
      <td *matCellDef="let data" [ngStyle]="column.cssProp" class="parameters sticky" mat-cell>
        <div>
          <vks-icon
            [class.disabled]="!data.recordId"
            [matTooltip]="(data.recordId ? 'RECORD_AVAILABLE' : 'RECORD_UNAVAILABLE') | translate"
            class="square16"
            color="#005259"
            icon="play-circle-fill"
          ></vks-icon>
          <vks-icon
            [icon]="conferencesService.getResolutionById(data.resolution)?.title?.toLowerCase()"
            [matTooltip]="conferencesService.getResolutionById(data.resolution)?.title"
            class="square16"
            color="#005259"
          >
          </vks-icon>
          <vks-icon
            [class.disabled]="!data.isMulticasting"
            [matTooltip]="'MULTI_CASTING' | translate"
            class="square16"
            color="#005259"
            icon="satellite"
          >
          </vks-icon>
          <vks-icon
            [class.disabled]="data.isPublic"
            [matTooltip]="(data.isPublic ? 'PUBLIC_VKS' : 'PRIVATE_VKS') | translate"
            class="square16"
            color="#005259"
            icon="lock"
          >
          </vks-icon>
        </div>
      </td>
    </ng-container>
    <ng-container *ngSwitchCase="allColumnTypes.FILE_SIZE">
      <td *matCellDef="let data" [ngStyle]="column.cssProp" class="sticky" mat-cell>
        {{ data | nestedFields: valuePath | fileSize: 'B' }}
      </td>
    </ng-container>
    <ng-container *ngSwitchCase="allColumnTypes.SIMPLE_DURATION">
      <td *matCellDef="let data" [ngStyle]="column.cssProp" class="sticky" mat-cell>
        {{ data | nestedFields: valuePath | duration }}
      </td>
    </ng-container>
    <ng-container *ngSwitchCase="allColumnTypes.USER_AVATAR">
      <td *matCellDef="let user" [ngStyle]="column.cssProp" class="name-column" mat-cell>
        <div [class.ghost]="user.ghost" class="user-avatar-info">
          <vks-avatar-smart [displayIndicators]="true" [entity]="user"></vks-avatar-smart>
          <div class="user-avatar-info-text">
            <span class="name">{{
              (currentUserId$ | async) === user.id ? user.name + ' (' + ("IT'S_YOU" | translate) + ')' : user.name
            }}</span>
            <br />
            <span *ngIf="column.template" class="additional-info">
              <ng-container *ngTemplateOutlet="column.template; context: { data: user }"></ng-container>
            </span>
          </div>
        </div>
      </td>
    </ng-container>
    <ng-container *ngSwitchCase="allColumnTypes.TEMPLATE">
      <td *matCellDef="let data" [ngStyle]="column.cssProp" mat-cell>
        <ng-container *ngTemplateOutlet="column.template; context: { data: data }"></ng-container>
      </td>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <td *matCellDef="let data" mat-cell>—</td>
    </ng-container>
  </ng-container>
</ng-container>
