<div [formGroup]="form">
  <div class="control-row">
    <div vks-control-group>
      <label>{{ 'SELECT_SCREEN_OR_WINDOW' | translate }}</label>
      <mat-select *ngIf="sources?.length > 0" formControlName="selectedWindow">
        <mat-option *ngFor="let source of sources" [value]="source.id">
          {{ source.name }}
        </mat-option>
      </mat-select>
    </div>
  </div>
  <video #videoElement muted playsinline></video>
</div>
<div class="action-buttons">
  <button vks-button [label]="'CLOSE' | translate" (buttonClick)="closeDialog()" class="btn-primary"></button>
  <button
    vks-button
    [disabled]="selectorFormControl.value === null"
    [label]="'SELECT' | translate"
    (buttonClick)="selectScreen()"
    class="btn-primary"
  ></button>
</div>
