import { Exclude, Expose, Type } from 'class-transformer';
import { ParticipantType } from './participant-type.enum';
import { ParticipantRight } from './participant-right.model';
import { EntityReference } from '@breez/models/shared/entity-reference.model';
import { EntityReferenceCarrier } from '@breez/models/conference/entity-reference-carrier.model';
import { RegistrationStatus } from '@breez/models/registration-status.enum';
import { ViewParticipantRightModel } from '@breez/models/conference/view-participant-right.model';
import { BanDescription } from '@breez/models/shared/participant/ban-description.model';

export class Participant implements EntityReferenceCarrier {
  @Expose({ name: 'participant' })
  @Type(() => {
    return EntityReference;
  })
  participantReference: EntityReference;

  @Expose({ name: 'object' })
  @Type(() => {
    return EntityReference;
  })
  object: EntityReference;

  @Expose({ name: 'type' })
  @Type(() => {
    return EntityReference;
  })
  type: ParticipantType;

  @Expose({ name: 'rights' })
  @Type(() => {
    return ParticipantRight;
  })
  rights: ViewParticipantRightModel[];

  @Expose({ name: 'email' })
  email: string;

  @Expose({ name: 'department' })
  department: string;

  @Expose({ name: 'ban' })
  @Type(() => {
    return BanDescription;
  })
  ban: BanDescription;

  @Expose({ name: 'invitedon' })
  invitedOn: Date;

  @Expose({ name: 'status' })
  registrationStatus: RegistrationStatus;

  roles: string[];

  // TODO это надо удалить, когда удалим ChatParticipants
  @Exclude({ toPlainOnly: true })
  isBlockable: boolean;

  hasRole(role: string): boolean {
    if (!Array.isArray(this.roles)) {
      return false;
    }
    return this.roles.includes(role);
  }

  compareCarrier(anotherCarrier: EntityReferenceCarrier): boolean {
    return (
      this.participantReference.compare(anotherCarrier.participantReference) &&
      this.object.compare(anotherCarrier.object)
    );
  }

  static CompareCarrier(item: Participant, item2: Participant): boolean {
    return item.participantReference.compare(item2.participantReference) && item.object.compare(item2.object);
  }
}

export class AuthParticipant extends Participant {
  @Expose({ name: 'authtoken' })
  authToken: string;
}
