import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { RestApiService } from '@breez/rest-api.service';

@Component({
  selector: 'vks-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {
  helpLink$: Observable<string> = this.restApiService.info$.pipe(
    map(info => {
      return info.help;
    }),
    filter(isTruthy)
  );

  constructor(private restApiService: RestApiService) {}
}
