/**
 * Returns filtered array by property
 * @param array
 * @param property
 */
import { isTruthy } from '@breez/shared/utilities/is-truthy';

export function uniqueArrayOfObjects<T>(array: T[], property: string): T[] {
  /**
   *  validations
   */
  if (!property) {
    return array;
  }
  if (array.length < 1) {
    return array;
  }

  if (!isTruthy(array[0][property])) {
    return array;
  }

  /**
   *  return unique array
   */
  return [
    ...new Map(
      array.map(item => {
        return [item[property], item];
      })
    ).values()
  ];
}
