import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserGroupEditingRequest } from '@breez/modules/social/models/user-group-editing-request.model';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Contact } from '../../models/contact.model';
import * as breezValidators from '@breez/shared/validators';
import { maxLengthErrorType } from '@breez/shared/validators/max-length.validator';

@Component({
  selector: 'vks-user-group-form-dialog',
  templateUrl: './user-group-form-dialog.component.html',
  styleUrls: ['./user-group-form-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserGroupFormDialogComponent implements OnInit {
  isNew = true;
  nameMaxLength = 50;
  maxLengthErrorType = maxLengthErrorType;
  userGroupForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [
      breezValidators.RequiredWithWhitespaceValidator(),
      Validators.maxLength(this.nameMaxLength)
    ]),
    contacts: new UntypedFormControl([])
  });

  constructor(
    private dialogRef: MatDialogRef<UserGroupFormDialogComponent, UserGroupEditingRequest>,
    @Inject(MAT_DIALOG_DATA) public dialogInputData: UserGroupEditingRequest
  ) {}

  ngOnInit(): void {
    this.isNew = this.dialogInputData.isNew;

    this.userGroupForm.patchValue({
      name: this.dialogInputData.name,
      contacts: this.dialogInputData.contacts
    });
  }

  save(): void {
    if (this.userGroupForm.invalid) {
      this.userGroupForm.markAllAsTouched();
      return;
    }

    const name: string = this.userGroupForm.value.name,
      contacts: Contact[] = this.userGroupForm.value.contacts,
      isNew = this.isNew;
    this.dialogRef.close({
      name,
      contacts,
      isNew
    });
  }

  cancel(): void {
    this.dialogRef.close(null);
  }
}
