import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  forwardRef,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from '@breez/environment';
import { FormControlUser, User } from '@breez/models';
import { Division } from '@breez/models/division.model';
import { ContextControl } from '@breez/models/shared/context-control.model';
import {
  UserSelectDialogExtraTab,
  UserSelectDialogRequestModel
} from '@breez/models/user-select/user-select-dialog-request.model';
import { SettingsService } from '@breez/modules/settings/services/settings.service';
import { Contact, ContactListResponse, UserGroup, UserGroupType } from '@breez/modules/social/models';
import { SocialService, UserGroupDialogService, UserGroupService } from '@breez/modules/social/services';
import { UserStatusUserGroupContextSourceService } from '@breez/modules/social/services/user-status-user-group-context-source.service';
import { USER_GROUP } from '@breez/modules/social/user-group-context.provider';
import { UserStatusService } from '@breez/modules/user/modules/avatar-smart/services/user-status.service';
import { USER_STATUS_SOURCE } from '@breez/modules/user/modules/avatar-smart/user-status-source.provider';
import { UserStatus } from '@breez/modules/user/modules/models/user-status.enum';
import { UserTreeComponent } from '@breez/modules/user/user-tree';
import { UsersService } from '@breez/modules/users/services/users.service';
import { BuildType } from '@breez/shared/enums/build-type.enum';
import { replayWhileSubs } from '@breez/shared/rxjs-operators';
import { fromControl } from '@breez/shared/rxjs-operators/from-control';
import { indicate } from '@breez/shared/rxjs-operators/indicate';
import { StateService } from '@breez/shared/services/state.service';
import { isArrayFilled } from '@breez/shared/utilities/is-array-filled';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap, take } from 'rxjs/operators';
import { ObjectListRequest } from '@breez/models/shared/paging';
import { emailErrorType } from '@breez/shared/validators/email.validator';
import { ObjectType } from '@breez/shared/enums/object-type.enum';
import * as breezValidators from '@breez/shared/validators';

export class UserGroupOrDivision extends UserGroup {
  objectType: 'usergroup' | 'division';
  availableDivisionIds: number[];
}

@UntilDestroy()
@Component({
  selector: 'vks-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    UserGroupDialogService,
    {
      provide: USER_GROUP,
      deps: [
        forwardRef(() => {
          return UserSelectComponent;
        })
      ],
      // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
      useFactory: (userListComponent: UserSelectComponent) => {
        return userListComponent.selectedTab$;
      }
    },
    {
      provide: USER_STATUS_SOURCE,
      useClass: UserStatusUserGroupContextSourceService
    },
    UserStatusService
  ]
})
export class UserSelectComponent implements OnInit, AfterViewInit {
  @ViewChild('searchControl', { static: true, read: ElementRef })
  searchControl: ElementRef<HTMLInputElement>;

  userTreeComponent_: UserTreeComponent;
  userTreeComponent$ = new ReplaySubject<UserTreeComponent>(1);

  @ViewChild('userTree')
  set userTreeComponent(component) {
    this.userTreeComponent_ = component;
    this.userTreeComponent$.next(component);
  }

  get userTreeComponent(): UserTreeComponent {
    return this.userTreeComponent_;
  }

  treeNodeExpansionChanges$ = new ReplaySubject<void>(1);

  disableBusyUsers = false;
  multiSelect = false;
  displayEmailSection = false;
  extraTabs: UserSelectDialogExtraTab[] = [];
  disabledUserIds: number[] = [];
  hideTabs = false;
  userTreeExtraContentTemplate: TemplateRef<any>;
  onlyInviteInTheConference = false;

  searchQuery = new UntypedFormControl(null);

  userGroups$ = this.userGroupService.getUserGroups().pipe(
    map(groups => {
      const typeOrder = [
        UserGroupType.ALLUSERS,
        UserGroupType.MYCONTACTS,
        UserGroupType.COMPANY,
        UserGroupType.MYINVITATIONS,
        UserGroupType.CUSTOM
      ];

      return groups
        .sort((groupA, groupB) => {
          return typeOrder.indexOf(groupA.type) - typeOrder.indexOf(groupB.type);
        })
        .concat([
          <UserGroup>{
            id: null,
            type: UserGroupType.SELECTED,
            name: 'selected',
            isEditable: false
          },
          <UserGroup>{
            id: null,
            type: UserGroupType.ONLINE,
            name: 'online',
            isEditable: false
          }
        ]);
    }),
    replayWhileSubs()
  );

  displayingTabs$: Observable<UserGroupOrDivision[]> = this.userGroups$.pipe(
    map(userGroups => {
      return userGroups.map(userGroup => {
        return {
          ...userGroup,
          objectType: ObjectType.USER_GROUP
        } as UserGroupOrDivision;
      });
    }),
    switchMap(userGroups => {
      if (environment.buildType !== BuildType.FNS) {
        return of({
          userGroups,
          divisions: null
        } as {
          userGroups: UserGroupOrDivision[];
          divisions: Array<{
            id: number;
            name: string;
            availableDivisionKeys: number[];
            availableDivisionIds: number[];
          }>;
        });
      }

      // TODO: Костыль
      return this.usersService.getDivisions().pipe(
        map(divisions => {
          const firstLevelDivisions = divisions
            .filter(division => {
              return division.currentLevelFromRoot === 1;
            })
            .map(division => {
              return {
                id: division.id,
                name: division.name,
                availableDivisionKeys: [division.key],
                availableDivisionIds: [division.id]
              };
            });

          divisions.forEach(division => {
            const firstDivision = firstLevelDivisions.find(firstLevelDivision => {
              return firstLevelDivision.availableDivisionKeys.includes(division.parentKey);
            });
            if (isTruthy(firstDivision)) {
              firstDivision.availableDivisionKeys.push(division.key);
              firstDivision.availableDivisionIds.push(division.id);
            }
          });

          return firstLevelDivisions;
        }),
        map(divisions => {
          return {
            userGroups,
            divisions
          } as {
            userGroups: UserGroupOrDivision[];
            divisions: Array<{
              id: number;
              name: string;
              availableDivisionKeys: number[];
              availableDivisionIds: number[];
            }>;
          };
        })
      );
    }),
    map(({ userGroups, divisions }) => {
      if (isTruthy(divisions)) {
        const groupCompany = userGroups.find(userGroup => {
          return [UserGroupType.ALLUSERS, UserGroupType.COMPANY].includes(userGroup.type);
        });

        let newIndex = userGroups.findIndex(userGroup => {
          return userGroup.type === UserGroupType.MYINVITATIONS;
        });
        if (newIndex === -1) {
          newIndex = userGroups.findIndex(userGroup => {
            return userGroup.type === UserGroupType.SELECTED;
          });
        }

        userGroups.splice(
          newIndex,
          0,
          ...divisions.map(division => {
            return {
              name: division.name,
              id: isTruthy(groupCompany) ? groupCompany.id : 0,
              type: isTruthy(groupCompany) ? groupCompany.type : UserGroupType.CUSTOM,
              availableDivisionIds: division.availableDivisionIds,
              objectType: 'division'
            } as UserGroupOrDivision;
          })
        );
      }

      return userGroups;
    }),
    replayWhileSubs()
  );

  selectedTabControl = new UntypedFormControl(0);

  selectedTab$ = combineLatest([this.displayingTabs$, fromControl(this.selectedTabControl)]).pipe(
    map(([tabs, index]) => {
      return tabs[index];
    }),
    replayWhileSubs()
  );

  // TODO: похоже, все эти типы можно переделывать в IEntity
  allContacts$: Observable<Contact[]> = this.userGroups$.pipe(
    switchMap(groups => {
      const group = groups.find(item => {
        return item.type === UserGroupType.ALLUSERS;
      });
      if (!group) {
        return of({} as ContactListResponse);
      }
      return this.socialService.getContacts({
        filter: { group, onlyconference: this.onlyInviteInTheConference }
      } as ObjectListRequest);
    }),
    map(({ data }) => {
      return data;
    }),
    replayWhileSubs()
  );

  displayingContacts$: Observable<User[]> = this.selectedTab$.pipe(
    switchMap(tab => {
      if (tab.objectType === 'division') {
        return (
          tab.type === UserGroupType.ALLUSERS
            ? this.allContacts$
            : this.socialService
                .getContacts({
                  filter: { group: tab, onlyconference: this.onlyInviteInTheConference }
                } as ObjectListRequest)
                .pipe(
                  map(({ data }) => {
                    return data;
                  })
                )
        ).pipe(
          map(contacts => {
            return contacts.filter(contact => {
              return tab.availableDivisionIds.includes(contact.departmentId);
            });
          })
        );
      }

      switch (tab.type) {
        case UserGroupType.ALLUSERS:
          return this.allContacts$;
        case UserGroupType.ONLINE:
          return this.allContacts$.pipe(
            map(contacts => {
              return contacts.filter(contact => {
                return contact.status !== UserStatus.OFFLINE;
              });
            })
          );
        case UserGroupType.SELECTED:
          return of(this.checklistSelection.selected);
        default: {
          return this.socialService
            .getContacts({
              filter: { group: tab, onlyconference: this.onlyInviteInTheConference }
            } as ObjectListRequest)
            .pipe(
              map(({ data }) => {
                return data;
              }),
              map(contacts => {
                if (tab.type !== UserGroupType.MYINVITATIONS) {
                  return contacts;
                }
                return contacts.map(contact => {
                  const newName =
                    contact.email + (contact.name && contact.name !== contact.email ? ` (${contact.name})` : '');
                  return Object.assign(contact, { name: newName });
                });
              })
            );
        }
      }
    })
  );

  emailListForm = new UntypedFormControl(null);

  inputSelectedUserList: User[];

  inputUserList$ = new ReplaySubject<User[]>(1);

  userList$: Observable<User[]> = this.inputUserList$.pipe(
    switchMap(list => {
      if (isArrayFilled(list)) {
        return of(list);
      } else {
        return this.displayingContacts$;
      }
    }),
    map(users => {
      if (Array.isArray(this.dialogInputData.excludedUsersIds) && this.dialogInputData.excludedUsersIds.length) {
        return users.filter(user => {
          return !this.dialogInputData.excludedUsersIds.includes(user.id);
        });
      }
      return users;
    }),
    replayWhileSubs()
  );

  filteredUserList$: Observable<User[]> = combineLatest([this.userList$, fromControl(this.searchQuery, 300)]).pipe(
    map(([users, keywords]) => {
      if (!keywords) {
        return users;
      }

      return users.filter(user => {
        if (!user.name) {
          return false;
        }

        if (!keywords) {
          return true;
        }

        const contactWords = `${user.name}`.toLocaleLowerCase().split(/[\s.,?!]+/);

        return keywords
          .toLocaleLowerCase()
          .split(/[\s.,?!]+/)
          .every(keyword => {
            return contactWords.some(word => {
              return word.includes(keyword);
            });
          });
      });
    })
  );

  divisionsList$: Observable<Division[]> = this.usersService.getDivisions().pipe(replayWhileSubs());

  treeData$: Observable<{ users: User[]; divisions: Division[] }> = combineLatest([
    this.filteredUserList$,
    this.divisionsList$
  ]).pipe(
    map(([users, divisions]) => {
      return {
        users,
        divisions
      };
    }),
    replayWhileSubs()
  );

  treeLoading$ = indicate(this.selectedTab$, this.treeData$);

  checklistSelection = new SelectionModel<User>(true);

  selectedUsers$: Observable<User[]> = this.checklistSelection.changed.pipe(
    map(() => {
      return this.checklistSelection.selected;
    }),
    startWith(<User[]>[]),
    replayWhileSubs()
  );

  isSelectedAllVisible$: Observable<boolean> = combineLatest([
    this.userTreeComponent$.pipe(filter(isTruthy)),
    this.selectedUsers$,
    this.treeData$,
    this.treeNodeExpansionChanges$
  ]).pipe(
    map(([userTreeComponent]) => {
      return userTreeComponent.isAllVisibleSelected();
    })
  );

  isSelectedAll$: Observable<boolean> = combineLatest([
    this.userTreeComponent$.pipe(filter(isTruthy)),
    this.selectedUsers$,
    this.treeData$
  ]).pipe(
    map(([userTreeComponent]) => {
      return userTreeComponent.descendantsAllSelected();
    }),
    replayWhileSubs()
  );

  isSelectedAny$: Observable<boolean> = combineLatest([
    this.userTreeComponent$.pipe(filter(isTruthy)),
    this.selectedUsers$,
    this.treeData$
  ]).pipe(
    map(([_, selectedUsers]) => {
      return selectedUsers?.length > 0;
    }),
    replayWhileSubs()
  );

  isExpandedAny$: Observable<boolean> = combineLatest([
    this.userTreeComponent$.pipe(filter(isTruthy)),
    this.treeNodeExpansionChanges$
  ]).pipe(
    map(([userTreeComponent]) => {
      return userTreeComponent.isAnyDescendantExpanded();
    }),
    replayWhileSubs()
  );

  extraContextControls$: Observable<ContextControl[]> = this.isSelectedAllVisible$.pipe(
    map(isSelectedAll => {
      return [
        {
          translatingLabel: 'EXPAND_VISIBLE',
          isUnderContextMenu: true,
          // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
          action: () => {
            return this.expandVisible();
          }
        },
        isSelectedAll
          ? {
              translatingLabel: 'DESELECT_VISIBLE',
              isUnderContextMenu: true,
              // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
              action: () => {
                return this.deselectVisible();
              }
            }
          : {
              translatingLabel: 'SELECT_VISIBLE',
              isUnderContextMenu: true,
              // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
              action: () => {
                return this.selectVisible();
              }
            },
        {
          translatingLabel: 'INVERT_VISIBLE',
          isUnderContextMenu: true,
          // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
          action: () => {
            return this.invertVisible();
          }
        }
      ];
    })
  );

  colorizeTree$: Observable<boolean> = this.settingsService.getValue('client', 'division_color').pipe(
    map(item => {
      return item.value;
    }),
    map(Number),
    map(Boolean),
    map(isColorize => {
      return isColorize && this.stateService.projectBuildType === BuildType.FNS;
    }),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  constructor(
    private settingsService: SettingsService,
    private usersService: UsersService,
    private dialogRef: MatDialogRef<UserSelectComponent>,
    private userGroupService: UserGroupService,
    private socialService: SocialService,
    private stateService: StateService,
    @Inject(MAT_DIALOG_DATA) public dialogInputData: UserSelectDialogRequestModel
  ) {}

  ngOnInit(): void {
    this.disableBusyUsers = !!this.dialogInputData.disableBusyUsers;
    this.multiSelect = !!this.dialogInputData.multiSelect;
    this.displayEmailSection = !!this.dialogInputData.displayEmailSection;
    this.extraTabs = this.dialogInputData.extraTabs || [];
    this.hideTabs = !(this.extraTabs.length > 0 || this.displayEmailSection);
    this.disabledUserIds = this.dialogInputData.disabledUserIds || [];
    this.userTreeExtraContentTemplate = this.dialogInputData.userTreeExtraContent;
    this.onlyInviteInTheConference = !!this.dialogInputData.onlyInviteInTheConference;

    this.selectedUsers$.pipe(untilDestroyed(this)).subscribe();

    if (this.dialogInputData.selectedUsers && Array.isArray(this.dialogInputData.selectedUsers)) {
      let selectedUsers = this.dialogInputData.selectedUsers;

      if (this.displayEmailSection) {
        const invitedUsers = this.dialogInputData.selectedUsers.filter(user => {
          return user.isGuest;
        });
        const invitedUsersPlain = invitedUsers
          .map(user => {
            return user.email;
          })
          .join('\n');
        selectedUsers = selectedUsers.filter(user => {
          return !user.isGuest;
        });
        this.emailListForm.patchValue(invitedUsersPlain);
      }

      this.inputSelectedUserList = selectedUsers;
      this.checklistSelection.select(...selectedUsers);
    }

    this.inputUserList$.next(this.dialogInputData.users);

    if (!this.multiSelect) {
      this.checklistSelection.changed.pipe(untilDestroyed(this)).subscribe(() => {
        return this.resolveSelection();
      });
    }
  }

  ngAfterViewInit(): void {
    this.focusOnSearchControl();
  }

  focusOnSearchControl(): void {
    (document.activeElement as HTMLElement).blur();
    this.searchControl.nativeElement.focus();
  }

  selectVisible(): void {
    this.userTreeComponent.selectVisible();
  }

  deselectVisible(): void {
    this.userTreeComponent.deselectVisible();
  }

  invertVisible(): void {
    this.userTreeComponent.invertVisible();
  }

  expandVisible(): void {
    this.userTreeComponent.expandVisible();
  }

  expandAll(): void {
    this.userTreeComponent.deepExpand();
  }

  collapseAll(): void {
    this.userTreeComponent.deepCollapse();
  }

  selectAll(): void {
    this.userTreeComponent.selectAll();
  }

  deselectAll(): void {
    this.userTreeComponent.deselectAll();
  }

  invert(): void {
    this.userTreeComponent.invertAll();
  }

  resolveSelection(): void {
    this.userList$.pipe(untilDestroyed(this), take(1)).subscribe(users => {
      const emailInput = new UntypedFormControl(null, [
        breezValidators.RequiredWithWhitespaceValidator(),
        breezValidators.emailValidator()
      ]);
      let selectedUsers = this.checklistSelection.selected;
      const emailUsersPlain = <string>this.emailListForm.value;
      let emailUsers: User[] = [];
      if (isTruthy(emailUsersPlain) && typeof emailUsersPlain === 'string' && emailUsersPlain.trim().length > 0) {
        emailUsers = emailUsersPlain
          .trim()
          .toLocaleLowerCase()
          .split('\n')
          .map(email => {
            email = email.trim();
            let usersByEmail: User[] = users
              .filter(user => {
                return typeof user.email === 'string' && user.email.toLowerCase() === email.toLocaleLowerCase();
              })
              .map(user => {
                return Object.assign(new User(), {
                  id: user.id,
                  displayName: email + (user.name ? ` (${user.name})` : ''),
                  login: user.login,
                  email: user.email,
                  isGuest: true
                });
              });
            if (usersByEmail.length === 0) {
              emailInput.patchValue(email);
              if (emailInput.valid) {
                usersByEmail = [
                  Object.assign(new User(), {
                    id: email,
                    displayName: email,
                    login: email,
                    email,
                    isGuest: true,
                    hasNoId: true
                  })
                ];
              }
            }

            return usersByEmail;
          })
          .reduce((acc, cur) => {
            return [...acc, ...cur];
          }, [] as User[])
          .sort((userA, userB) => {
            return Number(userB.hasNoId) - Number(userA.hasNoId);
          });
      }

      selectedUsers = selectedUsers.concat(emailUsers);

      const uniqueUsers: User[] = [],
        duplicatedUsers: User[] = [];
      // Алан, я тут оставил тебе твою старую проверку=)
      // Чтобы вернуть, когда всё поправится
      // Свою пришлось костылить, потому что приходит то id, то user_id

      // selectedUsers.forEach(user => {
      //   if (uniqueUsers.some(uniqueUser =>
      //     (uniqueUser.email.toLocaleLowerCase() === user.email.toLocaleLowerCase()) ||
      //     (uniqueUser.id === user.id),
      //   )) {
      //     duplicatedUsers.push(user);
      //     return;
      //   }
      //   uniqueUsers.push(user);
      // });

      // спс))

      selectedUsers.forEach(user => {
        if (
          uniqueUsers.some(uniqueUser => {
            const userId = user.id || user.id;
            const uniqueUserId = uniqueUser.id || uniqueUser.id;
            return userId === uniqueUserId;
          })
        ) {
          duplicatedUsers.push(user);
          return;
        }
        uniqueUsers.push(user);
      });

      const validatedSelectedUsers: FormControlUser[] = uniqueUsers
        .map(user => {
          if (!isTruthy(user.email) || this.isValidEmail(user.email)) {
            return <FormControlUser>user;
          }
          (<FormControlUser>user).errors = {
            [emailErrorType.type]: emailErrorType,
            ...(<FormControlUser>user).errors
          };
          return <FormControlUser>user;
        })
        .sort((userA, userB) => {
          return Number(!!userB.errors) - Number(!!userA.errors);
        });

      this.dialogRef.close({
        selectedUsers: validatedSelectedUsers,
        duplicatedEmailUsers: duplicatedUsers
      });
    });
  }

  isValidEmail(email: string): boolean {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,9})+$/.test(email);
  }

  close(): void {
    this.dialogRef.close();
  }

  resetSearchQuery(): void {
    this.searchQuery.reset();
  }

  checkExpandedAny(): void {
    this.treeNodeExpansionChanges$.next();
  }
}
