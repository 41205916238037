import { Injectable } from '@angular/core';
import { WebsocketEvents, WebsocketService } from '@breez/modules/websocket';
import { Observable } from 'rxjs';

export interface ScheduleData {
  absolute: boolean;
  date: string;
  dayofweek: number;
  reason: string;
  starttime: string;
  endtime: string;
  timezone: number;
  type: 'workingday' | 'dayoff' | 'holiday' | 'maintenance';
}

export interface CheckScheduleByPeriod {
  items: ScheduleData[];
  status: 'correct' | 'incorrect';
}

@Injectable({
  providedIn: 'root'
})
export class ConferenceSettingService {
  constructor(private readonly wss: WebsocketService) {}

  checkScheduleByPeriod(data: { startDate: Date; endDate: Date }): Observable<CheckScheduleByPeriod> {
    return this.wss.query<CheckScheduleByPeriod, { startdate: Date; enddate: Date; timezone: number }>(
      WebsocketEvents.SEND.SETTING.SCHEDULE.CHECK,
      {
        data: {
          startdate: data.startDate,
          enddate: data.endDate,
          timezone: new Date().getTimezoneOffset() / -60
        }
      }
    );
  }

  getScheduleByPeriod(data: { startDate: Date; endDate: Date }): Observable<ScheduleData[]> {
    return this.wss.query<ScheduleData[], { startdate: Date; enddate: Date; timezone: number }>(
      WebsocketEvents.SEND.SETTING.SCHEDULE.GET,
      {
        data: {
          startdate: data.startDate,
          enddate: data.endDate,
          timezone: new Date().getTimezoneOffset() / -60
        }
      }
    );
  }
}
