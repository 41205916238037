<div class="container">
  <div class="search-container">
    <input vks-cinput [formControl]="keyword" [placeholder]="'SEARCH' | translate" type="text" />
  </div>
  <div [style.minWidth]="(isMobileDevice$ | async) ? '230px' : '498px'" class="content-container">
    <div #gridTarget class="grid-target"></div>
  </div>
  <div class="footer">
    <div class="controls">
      <mat-button-toggle-group
        [class.collapse-margin]="isMobileDevice$ | async"
        [multiple]="false"
        (valueChange)="onToggleGroupValueChange($event)"
        class="gap"
        value="gifs"
      >
        <mat-button-toggle *ngFor="let item of giphyContentTypeItems$ | async" [value]="item.value">
          <vks-icon
            [class.square20]="isMobileDevice$ | async"
            [class.square24]="isMobileDevice$ | async | not"
            [icon]="item.icon"
          ></vks-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <img alt="logo" src="assets/images/powered-by-giphy.png" />
  </div>
</div>
