import { Expose, Transform } from 'class-transformer';
import { Contact } from './contact.model';
import { fixDateDeserialization } from '@breez/shared/utilities/fixDateDeserialization';
import { isTruthy } from '@breez/shared/utilities/is-truthy';

export enum UserGroupType {
  ALLUSERS = 'allusers',
  COMPANY = 'company',
  MYCONTACTS = 'mycontacts',
  MYINVITATIONS = 'myinvitations',
  CUSTOM = 'custom',
  SELECTED = 'selected',
  ONLINE = 'online',
  ROOM = 'room'
}
export class UserGroup {
  @Expose({ name: 'usergroupid' })
  id: number;

  @Expose({ name: 'name' })
  name: string;

  @Expose({ name: 'usergrouptype' })
  type: UserGroupType;

  @Expose({ name: 'editable' })
  isEditable: boolean;

  @Expose({ name: 'contactids' })
  @Transform(
    ({ value }) => {
      return value.map(contact => {
        return contact.id;
      });
    },
    { toPlainOnly: true }
  )
  contacts: Contact[] = [];

  @Expose({ name: 'createdon' })
  @Transform(
    ({ value }) => {
      return value ? new Date(fixDateDeserialization(value)) : undefined;
    },
    { toClassOnly: true }
  )
  createdOn: Date;

  constructor(base: Partial<UserGroup>) {
    return Object.assign(this, base);
  }

  get isActionsAvailable(): boolean {
    return isTruthy(this.id) || this.type === UserGroupType.MYCONTACTS;
  }
}
