import { CallStatusHoldModule } from '@breez/modules/call/call-statuses/types/hold/type.module';
import { CallStatusRingIncomingModule } from '@breez/modules/call/call-statuses/types/ring-incoming/type.module';
import { CallStatusRingOutgoingModule } from '@breez/modules/call/call-statuses/types/ring-outgoing/type.module';
import { CallStatusRingAcceptModule } from '@breez/modules/call/call-statuses/types/accept/type.module';

export const CALLSTATUS_TYPES_MODULES = [
  CallStatusRingIncomingModule,
  CallStatusRingOutgoingModule,
  CallStatusRingAcceptModule,
  CallStatusHoldModule
];
