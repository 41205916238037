import { Expose } from 'class-transformer';

export class ChatAnchorage {
  @Expose({ name: 'pinnedorder' })
  pinnedOrder?: number;

  @Expose({ name: 'chatid' })
  chatId?: number;

  @Expose({ name: 'userid' })
  userId?: number;
}
