<vks-modal-header
  [title]="(isNew ? 'CREATING_USER_GROUP' : 'EDITING_USER_GROUP') | translate"
  (close)="cancel()"
></vks-modal-header>
<mat-dialog-content>
  <form [formGroup]="userGroupForm" (ngSubmit)="save()">
    <div class="control-row">
      <div vks-control-group class="va-middle toggle">
        <div vks-control-group class="info no-margin">
          <label for="title">{{ 'TITLE' | translate }}</label>
          <span [class.alert]="userGroupForm.controls.name?.errors?.maxlength" class="counter"
            >{{ userGroupForm.controls.name?.value?.length || 0 }} / {{ nameMaxLength }}</span
          >
        </div>

        <input vks-cinput formControlName="name" id="title" type="text" />
        <ng-container *ngIf="userGroupForm.controls.name?.touched">
          <p *ngIf="userGroupForm.controls.name?.errors?.required as error" class="alert">
            {{ error.label | translate: error.args }}
          </p>
        </ng-container>
        <p *ngIf="userGroupForm.controls.name?.errors?.maxlength as error" class="alert">
          {{ maxLengthErrorType.label | translate: error }}
        </p>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="left">
  <button vks-button [label]="'SAVE' | translate" (buttonClick)="save()" class="btn-primary"></button>
  <button vks-button [label]="'CANCEL' | translate" (buttonClick)="cancel()" class="btn-anchor"></button>
</mat-dialog-actions>
