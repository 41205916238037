import { GridsterItem } from 'angular-gridster2/lib/gridsterItem.interface';
import { Expose } from 'class-transformer';
import { ConferenceParticipant } from '@breez/models';

export enum LayoutFrameType {
  DEFAULT = 'FRAME_TYPE_DEFAULT',
  VOICE_ACTIVATE = 'FRAME_TYPE_VA'
}

export class LayoutFrame implements GridsterItem {
  @Expose({ name: 'id' }) id: number;
  cols: number;
  rows: number;
  @Expose() x: number;
  @Expose() y: number;

  fullScreen?: boolean;
  participant: ConferenceParticipant;
  channel: number;

  widthProportion?: number;
  heightProportion?: number;

  voiceActivate?: boolean;
  canVoiceActivate?: boolean;

  frameType: LayoutFrameType;

  constructor() {
    this.canVoiceActivate = false;
    this.frameType = LayoutFrameType.DEFAULT;
  }

  get type(): LayoutFrameType {
    return this.frameType;
  }

  changeType(type: LayoutFrameType): void {
    this.frameType = type;
  }
}
