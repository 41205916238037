<p *ngIf="!collapsed">
  {{ text }}
  <ng-container *ngIf="needCollapse">
    <button vks-button [label]="'COLLAPSE' | translate" (buttonClick)="collapsed = true" class="btn-anchor"></button>
  </ng-container>
</p>
<p *ngIf="collapsed">
  {{ reducedText }}
  <span> ... </span>
  <button vks-button [label]="'RESTORE' | translate" (buttonClick)="collapsed = false" class="btn-anchor"></button>
</p>
