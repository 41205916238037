<div class="main">
  <div *ngIf="showSearchForm" class="header">
    <div class="search-form chat-list">
      <input vks-cinput [formControl]="keywords" [placeholder]="'SEARCH' | translate" aria-label="SEARCH" type="text" />
      <span
        *ngIf="showArchiveChats"
        style="
          display: flex;
          align-items: center;
          white-space: nowrap;
          cursor: pointer;
          font-size: 13px;
          color: #007981;
          font-weight: 600;
        "
        (click)="toggleShowArchiveChats()"
        >↩️ Архив</span
      >
      <button
        *ngxPermissionsOnly="'chat:create'"
        vks-button
        [class.rotate]="searchState$ | async | not"
        [matTooltip]="((searchState$ | async) ? 'CANCEL' : 'NEW_CHAT') | translate"
        class="btn-basic btn-compressed btn-unfocused"
        color="#9DAEC3"
        icon="x-10"
        iconClass="square12"
        (buttonClick)="showUserTree(); clicked = false"
      ></button>
    </div>
  </div>
  <ng-container *ngIf="(searchState$ | async) && showUserTree$ | async; else chatList">
    <div *ngIf="filteredContacts$ | async as filteredContacts; else loading" class="contacts-list">
      <vks-user-tree
        *ngIf="filteredContacts | isEmpty | not; else stub"
        [allowExpand]="true"
        [allowSelecting]="true"
        [checklistSelection]="userTreeSelectionModel"
        [divisionsList]="(showUserTree$ | async) ? (divisionsList$ | async) : []"
        [expandAll]="true"
        [multiSelect]="showUserTree$ | async"
        [reduceDivisions]="true"
        [userList]="filteredContacts"
        [virtualScroll]="useVirtualScroll$ | async"
        (deselectUser)="clearSelectedUsers([$event])"
        (removeUsers)="clearSelectedUsers($event)"
        (selectDivision)="selectDivisionUsers($event)"
        (selectUser)="selectConversationWithUser($event)"
      ></vks-user-tree>
      <div *ngIf="selectedUsers$ | async as selectedUsersList" class="actions">
        <button
          vks-button
          [disabled]="selectedUsers$ | async | not"
          [label]="(selectedUsersList.length > 1 ? 'CREATE_GROUP_CHAT' : 'VISIT_CHAT') | translate"
          class="btn-anchor"
          type="button"
          [disabled]="clicked"
          (buttonClick)="createGroupChat(); clicked = true"
        ></button>
      </div>
    </div>
    <ng-template #loading>
      <div class="loader">
        <mat-spinner diameter="30" strokeWidth="2"></mat-spinner>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #chatList>
    <ng-container *ngIf="(chatList$ | async)?.length > 0; else stub">
      <div [scrollWindow]="false" class="chat-list" infinite-scroll>
        <ng-container
          *ngFor="
            let chat of chatList$ | async | archiveChats: showArchiveChats : updateArchive;
            let index = index;
            trackBy: trackByChatId
          "
        >
          <div
            #chatDivs
            (click)="toSelectedChat(chat)"
            [class.active]="isLinkActive(chat?.id) | async"
            [class.unread]="(chat | chatUnreadMessagesCount | async) > 0"
            class="chat-info"
          >
            <mat-menu #menu="matMenu" class="context-menu" xPosition="after" yPosition="below">
              <button
                (click)="markChatAsRead(chat.id)"
                *ngIf="(chat | chatUnreadMessagesCount | async) > 0 && notMainChat(chat)"
                mat-menu-item
              >
                <vks-icon class="square20" icon="mark-chat-read"></vks-icon>
                <span>{{ 'MARK_AS_READ' | translate }}</span>
              </button>

              <ng-container *ngIf="!isConferenceChatList">
                <button (click)="changePinChat(chat.id, false)" *ngIf="isChatPin(chat)" mat-menu-item>
                  <vks-icon class="square20" icon="pin-off"></vks-icon>
                  <span>{{ 'UNPIN_CHAT' | translate }}</span>
                </button>
                <button (click)="changePinChat(chat.id, true)" *ngIf="!isChatPin(chat)" mat-menu-item>
                  <vks-icon class="square20" icon="pin-on"></vks-icon>
                  <span>{{ 'PIN_CHAT' | translate }}</span>
                </button>
              </ng-container>

              <ng-container *ngIf="!isConferenceChatList">
                <button (click)="addChatToArchive(chat.id, isArchived(chat.id) ? false : true)" mat-menu-item>
                  <span>{{ (isArchived(chat.id) ? 'REMOVE_FROM_ARCHIVE' : 'ADD_TO_ARCHIVE') | translate }}</span>
                </button>
                <button (click)="changeNotification(chat.id, true)" *ngIf="!isChatMute(chat)" mat-menu-item>
                  <vks-icon class="square20" icon="notifications-off"></vks-icon>
                  <span>{{ 'DISABLE_NOTIFICATION' | translate }}</span>
                </button>
                <button (click)="changeNotification(chat.id, false)" *ngIf="isChatMute(chat)" mat-menu-item>
                  <vks-icon class="square20" icon="notifications"></vks-icon>
                  <span>{{ 'ENABLE_NOTIFICATION' | translate }}</span>
                </button>
              </ng-container>
            </mat-menu>
            <div
              [matMenuTriggerFor]="menu"
              [style.left]="contextMenuPosition.x"
              [style.top]="contextMenuPosition.y"
              style="opacity: 0; position: fixed"
            ></div>
            <div
              (contextmenu)="menu._allItems.length ? openMenu($event, index) : null"
              (press)="menu._allItems.length ? startContextMenuTimer($event, index) : null"
              [style.touch-action]="'auto'"
              class="chat-preview"
            >
              <vks-avatar-smart [entity]="chat | chatAvatarEntity | async"></vks-avatar-smart>
              <div class="message">
                <div class="name-and-date">
                  <p [matTooltip]="(chat | chatAvatarEntity | async)?.name" class="chat-name">
                    {{ (chat | chatAvatarEntity | async)?.name | translate }}
                  </p>

                  <div *ngIf="isConferenceChatList && (chat | chatUnreadMessagesCount | async) > 0" class="badge">
                    !
                  </div>
                  <ng-container *ngIf="chat | chatLastMessage | async as lastMessage">
                    <p *ngIf="lastMessage && !isConferenceChatList" class="chat-date">
                      {{ lastMessage.sentDate | humanizeDate: 'time-or-date' | async }}
                    </p>
                  </ng-container>
                </div>
                <div *ngIf="!isConferenceChatList" class="message-body">
                  <div [class.unread]="(chat | chatUnreadMessagesCount | async) > 0" class="last-message">
                    <div *ngIf="chat | chatLastSenderName | async as sender" class="sender">
                      <ng-container *ngIf="chat.lastMessage | messageIsSystem | not">
                        <p>{{ (sender | translate) ?? sender }}</p>
                        <span>:&nbsp;</span>
                      </ng-container>
                    </div>
                    <span
                      [innerHTML]="chat | chatLastMessage | async | messagePreview | async | replaceEmojis: 16"
                      class="message-content"
                    >
                    </span>
                  </div>
                  <div class="message-indicators">
                    <ng-container *ngIf="isChatMute(chat)">
                      <vks-icon
                        [matTooltip]="'MUTE_NOTIFICATION' | translate"
                        class="square20 mute-icon"
                        icon="volume-off-outline"
                      ></vks-icon>
                    </ng-container>
                    <div *ngIf="(chat | chatUnreadMessagesCount | async) > 0" class="badge">
                      {{ chat | chatUnreadMessagesCount | async }}
                    </div>
                    <ng-container *ngIf="showChatPinnedBadge(chat)">
                      <vks-icon class="square20" icon="pin-chat"></vks-icon>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-template>
</div>

<ng-template #stub>
  <p class="stub center">{{ 'EMPTY_LIST_ENTITIES' | translate }}</p>
</ng-template>

