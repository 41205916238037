import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { Observable } from 'rxjs';
import { InvitingGuestPopupComponent } from '@breez/modules/inviting/inviting-guests/components/inviting-guest-popup/inviting-guest-popup.component';

@Injectable({
  providedIn: 'root'
})
export class InvitingDialogService {
  constructor(
    private matDialog: MatDialog,
    private overlay: Overlay
  ) {}

  displayInvitingForm(): Observable<any> {
    return this.matDialog
      .open(InvitingGuestPopupComponent, {
        scrollStrategy: this.overlay.scrollStrategies.reposition(),
        width: '400px',
        maxWidth: '98vw',
        height: 'fit-content',
        maxHeight: '100vh',
        autoFocus: false,
        panelClass: 'settings-popup'
      })
      .afterClosed();
  }
}
