import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { Message } from '@breez/modules/chat';
import { isTruthy } from '@breez/shared/utilities/is-truthy';

@Pipe({
  name: 'messageIsSystem'
})
@Injectable({
  providedIn: 'root'
})
export class MessageIsSystemPipe implements PipeTransform {
  transform(message: Partial<Message>): boolean {
    return isTruthy(message) && message.userId === 1;
  }
}
