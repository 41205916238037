<div class="date-time-lines">
  <div class="side">
    <span class="title">{{ 'HOURS' | translate }}</span>
    <div #hoursSide class="picker">
      <div
        *ngFor="let item of availableHours"
        [class.selected]="currentHours === item.value"
        (click)="selectHours(item)"
        class="picker-item"
      >
        {{ item.alias }}
      </div>
    </div>
  </div>
  <div class="side">
    <span class="title">{{ 'MINUTES' | translate }}</span>
    <div #minutesSide class="picker">
      <div
        *ngFor="let item of availableMinutes"
        [class.selected]="currentMinutes === item.value"
        (click)="selectMinutes(item)"
        class="picker-item"
      >
        {{ item.alias }}
      </div>
    </div>
  </div>
</div>
<div class="action">
  <button vks-button [label]="'READY' | translate" (buttonClick)="close.emit()" class="btn-anchor"></button>
</div>
