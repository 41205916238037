import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isTruthy } from '@breez/shared/utilities/is-truthy';

export function getErrors(control: AbstractControl): ValidationErrors | null {
  let errors: ValidationErrors = {};

  if (control instanceof UntypedFormGroup) {
    for (const key of Object.keys(control.controls)) {
      errors = { ...errors, ...getErrors(control.get(key)) };
    }
  }

  errors = { ...control.errors, ...errors };

  return Object.keys(errors).length > 0 ? errors : null;
}

export function getMainForm(control: AbstractControl): AbstractControl {
  if (!isTruthy(control)) {
    return null;
  }

  if (!isTruthy(control.parent)) {
    return control;
  }

  return getMainForm(control.parent);
}

export function getAllControls(control: AbstractControl): Record<string, AbstractControl> {
  if (!(control instanceof UntypedFormGroup)) {
    return null;
  }

  const controls: Record<string, AbstractControl> = {};

  const controlNames = Object.keys(control.controls);
  for (const controlName of controlNames) {
    const childControl = control.get(controlName);

    const nextLevelControls = getAllControls(childControl);
    if (!isTruthy(nextLevelControls)) {
      controls[controlName] = childControl;
    } else {
      Object.assign(controls, nextLevelControls);
    }
  }
  return Object.keys(control.controls).length > 0 ? controls : null;
}

export function getControlByName(controlName: string, formGroup: UntypedFormGroup): AbstractControl {
  const formGroupControls = formGroup.controls;

  if (!isTruthy(formGroupControls)) {
    return null;
  }

  const control = formGroupControls[controlName] || formGroupControls[controlName.toLocaleLowerCase()] || null;

  if (isTruthy(control)) {
    return control;
  }

  for (const key of Object.keys(formGroupControls)) {
    if (!(formGroupControls[key] instanceof UntypedFormGroup)) {
      continue;
    }

    const nestedControl = getControlByName(controlName, formGroupControls[key] as UntypedFormGroup);

    if (isTruthy(nestedControl)) {
      return nestedControl;
    }
  }

  return null;
}

export function addValidators(control: AbstractControl, validators: ValidatorFn | ValidatorFn[]): void {
  if (Array.isArray(validators)) {
    validators.forEach(validator => {
      addValidators(control, validator);
    });
  } else if (!control.hasValidator(validators)) {
    control.addValidators(validators);
    control.updateValueAndValidity();
  } else {
    control.updateValueAndValidity();
  }
}

export function removeValidators(control: AbstractControl, validators: ValidatorFn | ValidatorFn[]): void {
  if (Array.isArray(validators)) {
    validators.forEach(validator => {
      removeValidators(control, validator);
    });
  } else if (control.hasValidator(validators)) {
    control.removeValidators(validators);
    control.updateValueAndValidity();
  } else {
    control.updateValueAndValidity();
  }
}
