import { Expose } from 'class-transformer';
import { GridsterItem } from 'angular-gridster2/lib/gridsterItem.interface';
import { ConferenceParticipant } from '@breez/models';

export class ConferenceLayoutFrameModel implements GridsterItem {
  @Expose({ name: 'frameid' })
  id: number;

  @Expose({ name: 'rowindex' })
  y: number;

  @Expose({ name: 'colindex' })
  x: number;

  @Expose({ name: 'rowscount' })
  rows: number;

  @Expose({ name: 'colscount' })
  cols: number;

  @Expose({ name: 'voiceactivate' })
  voiceActivate?: boolean;

  @Expose({ name: 'canvoiceactivate' })
  canVoiceActivate?: boolean;

  @Expose({ name: 'fullscreen' })
  fullScreen?: boolean;

  constructor() {
    this.canVoiceActivate = false;
  }

  participant?: ConferenceParticipant;
  widthProportion?: number;
  heightProportion?: number;
}
