import { merge, Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';

/**
 * Merges event-mode observable inputs in one observable that emits index of occurred event source.
 * @param inputs Sources of events
 */
export function mergeToIndex(...inputs: Observable<any>[]): Observable<number> {
  return merge(
    ...inputs.map((input, index) => {
      return input.pipe(mapTo(index));
    })
  );
}
