import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { EmitOnChange } from '@breez/shared/utilities/decorators/emit-on-change.decorator';
import { of, ReplaySubject } from 'rxjs';
import { MessageService } from '../../services';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { replayWhileSubs } from '@breez/shared/rxjs-operators';
import { Message } from '../../models';
import { isTruthy } from '@breez/shared/utilities/is-truthy';

@Component({
  selector: 'vks-chat-message-view-nested',
  templateUrl: './chat-message-view-nested.component.html',
  styleUrls: ['./chat-message-view-nested.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatMessageViewNestedComponent implements AfterViewInit, OnChanges {
  @Input() message: Message;
  @ViewChild('messageBody') messageBody: ElementRef;

  @EmitOnChange('message', { onlyTruthy: true })
  message$ = new ReplaySubject<Message>(1);

  messageText$ = this.message$.pipe(
    switchMap(() => {
      return of(this.message.body);
    }),
    filter(text => {
      return text.length > 0;
    }),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  constructor(private readonly messageService: MessageService) {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnChanges(_: SimpleChanges): void {}

  ngAfterViewInit(): void {
    this.message$
      .pipe(
        filter(isTruthy),
        switchMap(message => {
          return this.messageService.parseMessage(message.body, false);
        }),
        map(({ messageItems }) => {
          const messageBodyNativeElement = this.messageBody?.nativeElement as HTMLParagraphElement;
          this.messageService.fillNative(messageItems, messageBodyNativeElement);
        }),
        distinctUntilChanged(),
        replayWhileSubs()
      )
      .subscribe();
  }
}
