import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { interval, Observable } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { DeclensionService } from '@breez/shared/services/declension.service';
import { ConferenceState } from '@breez/models/conference/enums/conference-state.enum';
import { replayWhileSubs } from '@breez/shared/rxjs-operators';

@Component({
  selector: 'vks-countdown-timer',
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountdownTimerComponent implements OnChanges {
  @HostBinding('style.--color')
  @Input()
  color: string;

  @HostBinding('class.short-view')
  @Input()
  shortView: boolean;

  @Input()
  removeZeroDigit: ('day' | 'hour' | 'minute' | 'second')[] = [];

  @Input()
  until: Date;

  @Input()
  untilLabel: string;

  @Input()
  startingOut: Date;

  @Input()
  startingOutLabel: string;

  @Input()
  conferenceDuration: Date;

  @Input()
  isFinished: boolean;

  @Input()
  conferenceState: ConferenceState;

  @Input()
  showLabels = true;

  @Input()
  inlineLabels = false;

  @Input()
  hasNoEndDate = false;

  showUntil = true;

  dateDiff$: Observable<Date> = interval(1000).pipe(
    startWith(0),
    map(() => {
      if (!this.until && !this.startingOut) {
        return;
      }
      if (this.conferenceState === ConferenceState.ENDED) {
        return this.conferenceDuration;
      }

      if (this.startingOut?.getTime() <= new Date()?.getTime() && this.conferenceState !== ConferenceState.ACTIVE) {
        return new Date(0);
      }

      if (
        this.until?.getTime() <= new Date()?.getTime() &&
        (this.conferenceState === ConferenceState.ACTIVE || this.conferenceState === ConferenceState.CANCELED) &&
        this.showUntil
      ) {
        return new Date(0);
      }

      let difference;
      if (this.showUntil) {
        difference = this.until?.getTime() - new Date()?.getTime();
      } else {
        difference = new Date()?.getTime() - this.startingOut?.getTime();
      }
      return new Date(difference);
    }),
    replayWhileSubs()
  );

  daysLeft$: Observable<number> = this.dateDiff$.pipe(
    map(date => {
      return Math.floor(date?.getTime() / 8.64e7);
    })
  );

  hoursLeft$: Observable<number> = this.dateDiff$.pipe(
    map(date => {
      return date.getUTCHours();
    })
  );

  showDay$ = this.dateDiff$.pipe(
    map(date => {
      return !this.removeZeroDigit.includes('day') || date?.getTime() / 1000 / 60 / 60 / 24 > 1;
    })
  );

  showHour$ = this.dateDiff$.pipe(
    map(date => {
      return !this.removeZeroDigit.includes('hour') || date?.getTime() / 1000 / 60 / 60 > 1;
    })
  );

  showMinute$ = this.dateDiff$.pipe(
    map(date => {
      return !this.removeZeroDigit.includes('minute') || date?.getTime() / 1000 / 60 > 1;
    })
  );

  showSecond$ = this.dateDiff$.pipe(
    map(date => {
      return !this.removeZeroDigit.includes('second') || date?.getTime() / 1000 > 1;
    })
  );

  minutesLeft$: Observable<number> = this.dateDiff$.pipe(
    map(date => {
      return date.getUTCMinutes();
    })
  );

  secondsLeft$: Observable<number> = this.dateDiff$.pipe(
    map(date => {
      return date.getUTCSeconds();
    })
  );

  daysLeftLabel$: Observable<string> = this.daysLeft$.pipe(
    switchMap(days => {
      return this.declensionService.getDeclension('DAY', days);
    })
  );

  hoursLeftLabel$: Observable<string> = this.hoursLeft$.pipe(
    switchMap(days => {
      return this.declensionService.getDeclension('HOUR', days);
    })
  );

  minutesLeftLabel$: Observable<string> = this.minutesLeft$.pipe(
    switchMap(days => {
      return this.declensionService.getDeclension('MINUTE', days);
    })
  );

  secondsLeftLabel$: Observable<string> = this.secondsLeft$.pipe(
    switchMap(days => {
      return this.declensionService.getDeclension('SECOND', days);
    })
  );

  constructor(private declensionService: DeclensionService) {}

  onChangeDirection(): void {
    // для быстрой конференции и звонка из чата блокируем возможность переключения направления таймера
    if (this.hasNoEndDate) {
      return;
    }

    if (this.conferenceState !== ConferenceState.ACTIVE) {
      return;
    }

    this.showUntil = !this.showUntil;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasNoEndDate && changes.hasNoEndDate.currentValue === true) {
      this.showUntil = false;
    }
    if (changes.conferenceState && changes.conferenceState.currentValue) {
      const currentState = changes.conferenceState.currentValue;

      if (currentState === ConferenceState.WAITING) {
        this.showUntil = true;
      }

      if (currentState === ConferenceState.ENDED) {
        this.showUntil = false;
      }
    }
  }

  getTooltipContent(): string {
    if (this.conferenceState === ConferenceState.ENDED) {
      return 'DURATION.default_duration';
    }
    return this.showUntil ? this.untilLabel : this.startingOutLabel;
  }
}
