<div [matTooltip]="title | translate" class="title" matTooltipClass="mat-tooltip-multiline" matTooltipPosition="above">
  <vks-icon *ngIf="icon$ | async" class="square24 title-icon" icon="{{ icon$ | async }}"></vks-icon>
  <span>{{ title }}</span>
</div>
<div class="controls">
  <button
    *ngIf="allowClose"
    vks-button
    (buttonClick)="close.emit()"
    class="fill-gray btn-basic btn-compressed"
    icon="close"
    iconClass="square20"
  ></button>
</div>
