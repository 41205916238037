import { Injectable } from '@angular/core';
import { WebsocketEvents, WebsocketService } from '@breez/modules/websocket';
import { Observable } from 'rxjs';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InvitingService {
  constructor(private wsService: WebsocketService) {}

  requestInvitingLink(email?: string, token?: string): Observable<string> {
    let data = {};

    if (isTruthy(email)) {
      data = { ...data, email };
    }

    if (isTruthy(token)) {
      data = { ...data, token };
    }

    return this.wsService.send<string>(WebsocketEvents.SEND.INVITING.GENERATE_INVITE, { data }).pipe(take(1));
  }
}
