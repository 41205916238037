import { Pipe, PipeTransform } from '@angular/core';
import { pad } from '@breez/shared/utilities/pad';

@Pipe({
  name: 'pad'
})
export class PadPipe implements PipeTransform {
  transform(value: number, digits: number): string {
    return pad(value, digits);
  }
}
