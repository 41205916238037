export const allSharedLinks = [
  'copy',
  'email',

  // 'facebook',

  // 'print',
  // 'twitter',
  // 'linkedin',
  // 'google',
  // 'pinterest',
  // 'reddit',
  // 'tumblr',
  // 'mix',
  'telegram',
  'whatsapp',
  'vk'
  // 'messenger',
  // 'xing',
  // 'line',
  // 'sms'
];
