import { expand, first, skip, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

export function scanAsync<V, A>(
  source: Observable<V>,
  project: (accumulator: A, value: V) => Observable<A>,
  seed: A
): Observable<A> {
  return source.pipe(
    first(),
    switchMap(value => {
      return project(seed, value);
    }),
    expand(accumulator => {
      return source.pipe(
        skip(1),
        first(),
        switchMap(value => {
          return project(accumulator, value);
        })
      );
    })
  );
}
