import { MediaSourceKind } from '@breez/models/webrtc/media-source-kind.enum';
import * as browserPermission from '@breez/shared/rxjs-operators/browser-permission';
import { merge, Observable } from 'rxjs';

function permissionDescriptor(mediaSourceKind: MediaSourceKind): PermissionDescriptor | null {
  let name = null;
  switch (mediaSourceKind) {
    case MediaSourceKind.VIDEO_INPUT:
      name = 'camera';
      break;
    case MediaSourceKind.AUDIO_INPUT:
      name = 'microphone';
      break;
    case MediaSourceKind.AUDIO_OUTPUT:
      name = 'speaker-selection';
      break;
  }

  if (!name) {
    return null;
  }

  return { name } as PermissionDescriptor;
}

function permissionWarningMessage(mediaSourceKind: MediaSourceKind): string {
  let message = 'GET_PERMISSION_WARNING';
  switch (mediaSourceKind) {
    case MediaSourceKind.VIDEO_INPUT: {
      message += '_CAMERA';
      break;
    }
    case MediaSourceKind.AUDIO_INPUT: {
      message += '_MICROPHONE';
      break;
    }
    case MediaSourceKind.AUDIO_OUTPUT: {
      message += '_SOUND';
      break;
    }
  }

  return message;
}

function icon(mediaSourceKind: MediaSourceKind): (enable: boolean) => string {
  return (enable: boolean) => {
    switch (mediaSourceKind) {
      case MediaSourceKind.VIDEO_INPUT: {
        return enable ? 'toggle-video-on' : 'toggle-video-off';
      }
      case MediaSourceKind.AUDIO_INPUT: {
        return enable ? 'toggle-microphone-on' : 'toggle-microphone-off';
      }
      case MediaSourceKind.AUDIO_OUTPUT: {
        return enable ? 'sound-on' : 'sound-off';
      }
      default: {
        return '';
      }
    }
  };
}

function toggleTooltip(mediaSourceKind: MediaSourceKind): (enable: boolean) => string {
  return (enable: boolean) => {
    switch (mediaSourceKind) {
      case MediaSourceKind.VIDEO_INPUT: {
        return enable ? 'ENABLE_CAMERA' : 'DISABLE_CAMERA';
      }
      case MediaSourceKind.AUDIO_INPUT: {
        return enable ? 'ENABLE_MICROPHONE' : 'DISABLE_MICROPHONE';
      }
      case MediaSourceKind.AUDIO_OUTPUT: {
        return enable ? 'ENABLE_SOUND' : 'DISABLE_SOUND';
      }
      default: {
        return '';
      }
    }
  };
}

function getPermission(mediaSourceKind: MediaSourceKind): () => Observable<PermissionState> {
  return () => {
    return browserPermission.getPermission(permissionDescriptor(mediaSourceKind));
  };
}

function hasPermission(mediaSourceKind: MediaSourceKind): (fallback: boolean) => Observable<boolean> {
  return (fallback: boolean) => {
    return browserPermission.hasPermission(permissionDescriptor(mediaSourceKind), fallback);
  };
}

function observePermission(mediaSourceKind: MediaSourceKind): () => Observable<PermissionState> {
  return () => {
    const permissionDesc = permissionDescriptor(mediaSourceKind);
    return merge(browserPermission.getPermission(permissionDesc), browserPermission.observePermission(permissionDesc));
  };
}

function requestPermission(mediaSourceKind: MediaSourceKind): (fallback: boolean) => Observable<boolean> {
  return (fallback: boolean) => {
    return browserPermission.requestPermission(permissionDescriptor(mediaSourceKind), fallback);
  };
}

export interface MediaSourceKindHelperType {
  permissionDescriptor: PermissionDescriptor;
  permissionWarningMessage: string;
  icon: (enable: boolean) => string;
  toggleTooltip: (enable: boolean) => string;
  hasPermission: (fallback: boolean) => Observable<boolean>;
  getPermission: () => Observable<PermissionState>;
  observePermission: () => Observable<PermissionState>;
  requestPermission: (fallback: boolean) => Observable<boolean>;
}

export function mediaSourceKindHelper(mediaSourceKind: MediaSourceKind): MediaSourceKindHelperType {
  return {
    permissionDescriptor: permissionDescriptor(mediaSourceKind),
    permissionWarningMessage: permissionWarningMessage(mediaSourceKind),
    icon: icon(mediaSourceKind),
    toggleTooltip: toggleTooltip(mediaSourceKind),
    hasPermission: hasPermission(mediaSourceKind),
    getPermission: getPermission(mediaSourceKind),
    observePermission: observePermission(mediaSourceKind),
    requestPermission: requestPermission(mediaSourceKind)
  } as MediaSourceKindHelperType;
}
