import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InvitingGuestPopupComponent } from './components/inviting-guest-popup/inviting-guest-popup.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@breez/shared/shared.module';
import { MatDividerModule } from '@angular/material/divider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InviteGuestButtonComponent } from './components/invite-guest-button/invite-guest-button.component';

@NgModule({
  declarations: [InvitingGuestPopupComponent, InviteGuestButtonComponent],
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule,
    MatDividerModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule
  ],
  exports: [InviteGuestButtonComponent]
})
export class InvitingGuestsModule {}
