import { Injectable } from '@angular/core';

import { CookieService, CookieServiceSetOptions } from '../interfaces/cookie-service.interface';
import { CookieStorage } from '../interfaces/cookie-storage.interface';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class BaseCookieStorage implements CookieStorage {
  readonly changes = new ReplaySubject<{ key: string; value: string }>(1);

  constructor(private cookieService: CookieService) {
    this.initChanges();
  }

  get length(): number {
    return Object.keys(this.cookieService.getAll()).length;
  }

  clear(): void {
    this.initChanges(true);
  }

  getItem(key: string): string | null {
    const item = this.cookieService.get(key);

    return item != null ? item : null;
  }

  key(index: number): string | null {
    const keys = Object.keys(this.cookieService.getAll());

    return index >= 0 && index < keys.length ? keys[index] : null;
  }

  removeItem(key: string): void {
    this.changes.next({ key, value: null });
  }

  setItem(key: string, value: string, options: Partial<CookieServiceSetOptions> = { path: '/' }): void {
    this.cookieService.put(key, value, options);
    this.changes.next({ key, value });
  }

  private initChanges($needNullValue: boolean = false): void {
    for (let index = 0; index < this.length; index++) {
      const key = this.key(index);
      const value = !$needNullValue ? this.getItem(key) : null;
      this.changes.next({ key, value });
    }
  }
}
