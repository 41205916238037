<!--<vks-events-calendar [calendarOptions]="calendarOptions"
										 [locale]="locale$ | async"
										 [events]="events$ | async"
										 [defaultView]="view">
</vks-events-calendar>-->
<!--<vks-icon-button >-->
<vks-icon
  [mat-dialog-close]="null"
  [matTooltip]="'CLOSE' | translate"
  class="square14 close-button"
  color="#9daec3"
  icon="x-8"
></vks-icon>
<!--</vks-icon-button>-->

<ng-container *ngIf="calendarOptions$ | async as calendarOptions">
  <vks-events-calendar-new
    [calendarOptions]="calendarOptions"
    (datesSet)="onNewDate($event)"
    [events]="eventsAdd$ | async"
  ></vks-events-calendar-new>
  <!--	<full-calendar [options]="calendarOptions"></full-calendar>-->
</ng-container>
