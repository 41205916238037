import { Observable } from 'rxjs';

export interface IWebsocketService {
  status$: Observable<boolean>;

  on<T>(event: string): Observable<T>;

  listen<T>(
    { traceId, path, parent }: IWebsocketMessageIdentifier,
    catchAction?: (obj) => any
  ): Observable<IRemotePayload<T>>;

  send(event: string, request?: { data?: any; id?: number }): void;
}

export interface WebSocketConfig {
  url: string;
  pingInterval?: number;
  reconnectInterval?: number;
  reconnectAttempts?: number;
}

export interface IParentIdentifier {
  resource: string;
  id: any;
}

export interface IQueryData<T = any> {
  parent?: IParentIdentifier;
  id?: any;
  data?: T;
}

export interface IQueryParameters {
  responseFrom?: IWebsocketMessageIdentifier;
  sendImmediately?: boolean;
}

export interface IRemotePayload<T = any> {
  data?: T;
  error?: Error;
  parent?: IParentIdentifier;
}

export interface IWebsocketMessageIdentifier {
  path?: string;
  traceId?: string;
  parent?: IParentIdentifier;
}

export interface IWsMessage extends IQueryData {
  event?: string;
  traceId?: string;
  code?: number;
  errorMessage?: string;
}
