import { Injectable } from '@angular/core';
import { WebsocketEvents, WebsocketService } from '@breez/modules/websocket';
import { IUserStatusSource } from '@breez/modules/user/modules/models/user-status-source.model';
import { UserStatus } from '@breez/modules/user/modules/models/user-status.enum';
import { Observable } from 'rxjs';
import { filter, finalize, map } from 'rxjs/operators';
import { UserStatusEvent, UserStatusSubscribeEvent } from '@breez/modules/user/modules/models/user-status-event.model';
import { arrToClass, toClass } from '@breez/shared/rxjs-operators';
import { convertObjectToArray } from '@breez/shared/utilities/convert-to-array';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { ObjectType } from '@breez/shared/enums/object-type.enum';

@Injectable({
  providedIn: 'root'
})
export class UserStatusIndividualSourceService implements IUserStatusSource {
  constructor(private wsService: WebsocketService) {}

  observeUserStatusById(userId: number): Observable<UserStatus> {
    return this.wsService
      .observe(
        WebsocketEvents.USER.STATUS.OBSERVE,
        { parent: { resource: ObjectType.USER, id: userId } },
        { responseFrom: { path: WebsocketEvents.USER.STATUS.EVENT } }
      )
      .pipe(
        toClass(UserStatusSubscribeEvent),
        map(({ data }) => {
          return convertObjectToArray<UserStatus>(data);
        }),
        arrToClass(UserStatusEvent),
        map(events => {
          return events.find(event => {
            return event.userId === userId;
          });
        }),
        filter(isTruthy),
        map(({ status }) => {
          return status;
        }),
        finalize(() => {
          return this.wsService.query(
            WebsocketEvents.USER.STATUS.LEAVE,
            { parent: { resource: ObjectType.USER, id: userId } },
            { sendImmediately: true }
          );
        })
      );
  }
}
