<vks-modal-header [title]="'CONTACT' | translate" (close)="close()"></vks-modal-header>
<div class="card-content" *ngIf="contact$ | async as contact">
  <div class="card-row contact-avatar">
    <vks-avatar-smart
      [size]="96"
      [displayIndicators]="false"
      [cardByHover]="false"
      [entity]="contact"
    ></vks-avatar-smart>
  </div>
  <div class="card-row contact-status">
    <span *ngIf="isOnline$ | async">{{ 'USER_STATUS.' + (state$ | async | uppercase) | translate }}</span>
    <span *ngIf="isOnline$ | async | not">
      <span *ngIf="lastAccess$ | async | not">{{ 'USER_STATUS.' + (state$ | async | uppercase) | translate }}</span>
      <span *ngIf="lastAccess$ | async as lastAccess"
        >{{ 'LAST_VISITED' | translate }} : {{ lastAccess | formatDate: 'short' }}</span
      >
    </span>
  </div>
  <div class="card-row contact-name">
    <span>{{ contact?.name }}</span>
  </div>
  <div class="card-row contact-position">
    <span>{{ contact?.jobTitle }} {{ contact?.departmentName | translate }}</span>
  </div>

  <div class="card-row contact-actions">
    <vks-contact-actions
      (close)="close()"
      [data]="contact"
      [selectedUserGroup]="selectedUserGroup$ | async"
      [isMobileDevices]="isMobileDevices"
    ></vks-contact-actions>
  </div>
  <div class="card-row contact-additional">
    <span>{{ 'E_MAIL' | translate }}</span>
    <span *ngIf="contact?.email as email; else empty"
      ><a [href]="'mailto:' + email">{{ email }}</a></span
    >
  </div>
  <div class="card-row contact-additional">
    <span>{{ 'PHONE' | translate }}</span>
    <span *ngIf="contact?.phone as phone; else empty"
      ><a [href]="'tel:' + phone">{{ phone }}</a></span
    >
  </div>
</div>

<ng-template #empty>
  <span>-</span>
</ng-template>
