import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { TimePick } from '@breez/models/shared/time-pick.model';
import { TimeUnitItem } from '@breez/models/shared/time-unit-item.model';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { pad } from '@breez/shared/utilities/pad';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'vks-control-time-picker',
  templateUrl: './control-time-picker.component.html',
  styleUrls: ['./control-time-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ControlTimePickerComponent implements OnInit, AfterViewChecked {
  @Input() currentHours: number;
  @Input() currentMinutes: number;

  @Input() minutesItemsStep = 5;

  @ViewChild('hoursSide') hoursSide: ElementRef;
  @ViewChild('minutesSide') minutesSide: ElementRef;

  @Output() close = new EventEmitter();
  @Output() opened = new EventEmitter();
  @Output() picked = new EventEmitter<TimePick>();

  ngAfterViewChecked$ = new Subject<void>();

  availableHours: TimeUnitItem[];
  availableMinutes: TimeUnitItem[];

  ngOnInit(): void {
    this.availableHours = new Array(24).fill(null).map((_, index) => {
      return {
        value: index,
        alias: pad(index, 2)
      };
    });

    this.availableMinutes = new Array(60 / this.minutesItemsStep).fill(null).map((_, index) => {
      return {
        value: index * this.minutesItemsStep,
        alias: pad(index * this.minutesItemsStep, 2)
      };
    });

    this.scrollToSelected();
    this.opened.emit();
  }

  ngAfterViewChecked(): void {
    this.ngAfterViewChecked$.next();
  }

  scrollToSelected(): void {
    this.ngAfterViewChecked$.pipe(take(1)).subscribe(() => {
      setTimeout(() => {
        const hoursPicker = this.hoursSide.nativeElement;
        const selectedHoursElement = hoursPicker.querySelector('.picker-item.selected');
        const minutesPicker = this.minutesSide.nativeElement;
        const selectedMinutesElement = minutesPicker.querySelector('.picker-item.selected');

        if (selectedHoursElement) {
          hoursPicker.scrollTop = selectedHoursElement.offsetTop - hoursPicker.offsetHeight / 2;
        }

        if (selectedMinutesElement) {
          minutesPicker.scrollTop = selectedMinutesElement.offsetTop - minutesPicker.offsetHeight / 2;
        }
      }, 0);
    });
  }

  selectHours(item: TimeUnitItem): void {
    this.currentHours = item.value;

    if (!this.currentMinutes) {
      this.currentMinutes = 0;
    }

    this.emitCurrentPickedTime();
  }

  selectMinutes(item: TimeUnitItem): void {
    this.currentMinutes = item.value;

    if (!this.currentHours) {
      this.currentHours = 0;
    }

    this.emitCurrentPickedTime();
  }

  emitCurrentPickedTime(): void {
    if (!(isTruthy(this.currentHours) && isTruthy(this.currentMinutes))) {
      return;
    }
    this.picked.emit({ hours: this.currentHours, minutes: this.currentMinutes });
  }
}
