import { Expose, Type } from 'class-transformer';
import { EventTypeEnum } from '@breez/models';

import { Message } from './message.model';

export class MessageEvent {
  @Expose()
  @Type(() => {
    return Message;
  })
  message: Message;

  @Expose({ name: 'event' })
  eventType: EventTypeEnum;

  constructor(base?: Partial<MessageEvent>) {
    return Object.assign(this, base);
  }
}
