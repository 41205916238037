<div *ngIf="isLoadingDelayed$ | async" class="form-cover"></div>
<form [class.for-chat]="forGlobalChat" [formGroup]="messageForm" (paste)="handlePaste($event)">
  <vks-control-file-drop-area
    #dropArea
    [acceptFormats]="allowedFormats"
    [enableDrop]="allowAttachments"
    [multiple]="true"
    (dragLeave)="isDragInProcess$.next(false)"
    (dragOver)="isDragInProcess$.next(true)"
  >
    <div *ngIf="replyingMessageIdFormControl.value" class="replying-message edit">
      <div class="icon">
        <vks-icon icon="reply-10"></vks-icon>
      </div>
      <div *ngIf="replyingMessageText$ | async | replaceEmojis: 18 as replyingMessageText" class="message">
        <span class="name">{{ (replyingMessage | messageAvatarEntity)?.name }}</span>
        <span class="body" [innerHTML]="replyingMessageText"></span>
      </div>
      <div class="controls">
        <button
          vks-button
          (buttonClick)="setReplyingMessage(null)"
          class="btn-basic btn-compressed"
          icon="x-10"
          iconClass="square10"
        ></button>
      </div>
    </div>
    <div *ngIf="editMessageIdFormControl.value" class="replying-message edit">
      <vks-icon class="square16" color="#12161C" icon="edit"></vks-icon>
      <div *ngIf="editingMessageText$ | async | replaceEmojis: 18 as editingMessageText" class="message">
        <span>{{ 'EDITING_MESSAGE' | translate }}</span>
        <span [title]="editingMessage.body" class="body" [innerHTML]="editingMessageText"></span>
      </div>
      <div class="controls">
        <button vks-button (buttonClick)="reset(true)" class="btn-basic btn-compressed" icon="x-10"></button>
      </div>
    </div>
    <div *ngIf="forwardingMessagesFormControl.value as messagesToForward" class="replying-message edit">
      <vks-forward-messages-preview
        [forMessageEdit]="true"
        [messageList]="messagesToForward"
        (cancelForward)="setForwardingMessages(null)"
      >
      </vks-forward-messages-preview>
    </div>
    <!--		<div [class.disabled]="!chatLoaded"-->
    <div [class.disabled]="false" class="message-form-controls">
      <ng-container *ngIf="emojiMartTranslate$ | async">
        <emoji-mart
          *ngIf="showEmoji$ | async"
          [showPreview]="false"
          [style]="{
            height: '356px',
            width: '100%',
            maxWidth: '602px',
            position: 'absolute',
            top: '-356px',
            left: '0',
            zIndex: '10000'
          }"
          [backgroundImageFn]="backgroundImageFn"
          [set]="'apple'"
          [sheetSize]="16"
          [i18n]="emojiMartTranslate$ | async"
          (emojiClick)="addEmoji($event)"
          (clickOutside)="clickOutside($event, showEmojiDelayed)"
          emoji="''"
        >
        </emoji-mart>
      </ng-container>
      <ckeditor
        #editor
        [editor]="ckeditor"
        vksTrackFocus
        [class.error]="(maxCharacters$ | async)?.length > 0"
        [class.qts-enabled]="displayQTSAnchor$ | async"
        [class.hide-toolbar]="ckIsToolbarHide$ | async"
        (focus)="focused = true"
        (keydown)="ckKeydown($event)"
        (change)="ckeditorChangeData($event)"
        (ready)="onReady($event)"
        [config]="{
          placeholder: placeholder | translate,
          removePlugins: ['Image', 'ImageCaption', 'ImageStyle', 'ImageToolbar', 'ImageUpload', 'MediaEmbed']
        }"
        [(ngModel)]="ngModelCk"
        class="indicate-focus-placeholder ckeditor"
        formControlName="body"
      >
      </ckeditor>
      <ng-container *ngTemplateOutlet="dragMarker"></ng-container>
      <textarea
        #messageBody
        vksTrackFocus
        style="display: none"
        [class.qts-enabled]="displayQTSAnchor$ | async"
        [placeholder]="placeholder | translate"
        [style.margin-left.px]="(canAttachFiles$ | async) ? 40 : 20"
        [style.margin-right.px]="textAreaRightOffset$ | async"
        (blur)="onBlurTextArea($event)"
        (focus)="focused = true"
        (input)="resizeTextArea()"
        (keydown)="textAreaKeydown($event)"
        (paste)="handlePaste($event)"
        class="hello indicate-focus-placeholder"
        formControlName="body"
        rows="1"
      ></textarea>
      <div class="controls-containers">
        <div class="controls-container left">
          <ng-container *ngIf="canAttachFiles$ | async">
            <button
              *ngIf="allowAttachments"
              #attachmentsButton
              [matTooltip]="'ATTACH_FILES' | translate"
              (click)="dropArea.showFileSelectionDialog(attachmentsButton)"
              mat-icon-button
              type="button"
            >
              <vks-icon icon="chat-attach"></vks-icon>
            </button>
          </ng-container>
          <button
            mat-icon-button
            type="button"
            [class.active]="ckAdvancedMode$ | async"
            [matTooltip]="'TEXT_FORMATTING' | translate"
            (click)="ckSwitchAdvancedMode()"
          >
            <vks-icon class="square24" icon="text-format"></vks-icon>
          </button>
          <button
            *ngIf="enableGiphy && canUseGiphy$ | async"
            [matTooltip]="'EMOJI' | translate"
            (click)="openEmojiDialog().subscribe()"
            mat-icon-button
            type="button"
          >
            <vks-icon icon="emoji"></vks-icon>
          </button>
          <button
            *ngIf="enableGiphy && (canUseGiphy$ | async) && (hasGiphyApiKey$ | async)"
            #giphyButton
            [disabled]="isGiphyEnabled$ | async | not"
            [matTooltip]="(isGiphyEnabled$ | async | not) ? ('DISABLED' | translate) : ('GIPHY' | translate)"
            (click)="openGiphyDialog(giphyButton)"
            mat-icon-button
            type="button"
          >
            <ng-container *ngIf="isGiphyEnabled$ | async; else disableGiphy">
              <vks-icon class="square28" icon="giph"></vks-icon>
            </ng-container>
            <ng-template #disableGiphy>
              <vks-icon class="square28" color="#0052594a" icon="giph"></vks-icon>
            </ng-template>
          </button>
        </div>
        <div class="controls-container center">
          <div *ngIf="maxCharacters$ | async as maxCharactersError" class="error">
            {{ maxCharactersError }}
          </div>
        </div>
        <div class="controls-container right">
          <button
            *ngIf="displayQTSAnchor$ | async"
            #qtsButton
            [matTooltip]="'QUESTION_TO_SPEAKER' | translate"
            (click)="setPreventBlur(qtsButton); submitForm(true)"
            mat-icon-button
            type="button"
          >
            <vks-icon class="primary" icon="qts"></vks-icon>
          </button>
          <button
            #sendButton
            [matTooltipDisabled]="isLoadingDelayed$ | async"
            [matTooltip]="((ckAdvancedMode$ | async) ? 'SEND_MESSAGE_EDITOR' : 'SEND_MESSAGE') | translate"
            (click)="setPreventBlur(sendButton); submitForm()"
            mat-icon-button
            type="button"
          >
            <vks-icon [icon]="(isLoadingDelayed$ | async) ? 'loader-spin' : 'chat-send'" class="primary"></vks-icon>
          </button>
        </div>
      </div>
    </div>
    <ng-template #dragMarker>
      <div *ngIf="isDragInProcess$ | async" class="cloud-upload">
        <vks-icon class="square24" icon="cloud-upload"></vks-icon>
      </div>
    </ng-template>
    <mat-divider *ngIf="isAnyFileAttached$ | async" [class.for-chat]="forGlobalChat" class="divider"></mat-divider>
    <span *ngIf="attachmentsFormControl.errors?.filemaxsize as error" class="validation-feedback">
      {{ error.label | translate: error.args }}
    </span>
    <vks-file-list
      [class.hidden]="isAnyFileAttached$ | async | not"
      [dropArea]="dropArea"
      [forMessageEdit]="true"
      [maxVisibleCount]="3"
      formControlName="attachments"
    ></vks-file-list>
  </vks-control-file-drop-area>
</form>
