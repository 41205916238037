import { MonoTypeOperatorFunction, OperatorFunction, pipe } from 'rxjs';
import { map } from 'rxjs/operators';

import { instanceToInstance, instanceToPlain, plainToInstance, ClassConstructor } from 'class-transformer';

export function toClass<V, T>(destClass: ClassConstructor<T>): OperatorFunction<V, T> {
  return pipe(
    map((plain: V) => {
      return plainToInstance(destClass, plain);
    })
  );
}

export function arrToClass<V, T>(destClass: ClassConstructor<T>): OperatorFunction<V[], T[]> {
  return pipe(
    map((plainArray: V[]) => {
      return plainToInstance(destClass, plainArray);
    })
  );
}

export function toPlain<V>(): OperatorFunction<V, object> {
  return pipe(
    map((data: V) => {
      return instanceToPlain(data);
    })
  );
}

export function clone<V>(): MonoTypeOperatorFunction<V> {
  return pipe(
    map((data: V) => {
      return instanceToInstance(data, { ignoreDecorators: true });
    })
  );
}

export function cloneArray<V>(): MonoTypeOperatorFunction<V[]> {
  return pipe(
    map((data: V[]) => {
      return instanceToInstance(data, { ignoreDecorators: true });
    })
  );
}
