import { Injectable } from '@angular/core';
import { WebsocketEvents, WebsocketService } from '@breez/modules/websocket';
import { Observable, of } from 'rxjs';
import { debounceTime, filter, map, scan, startWith, switchMap } from 'rxjs/operators';
import { Participant } from '@breez/models/shared/participant/participant.model';
import { arrToClass, toClass } from '@breez/shared/rxjs-operators';
import { ParticipantEvent } from '@breez/shared/models/participant-event.model';
import { ParticipantEventType } from '@breez/shared/models/participant-event-type.enum';
import { EntityReference } from '@breez/models/shared/entity-reference.model';

export interface ParticipantsQuery {
  object: EntityReference;
  participantReference?: EntityReference;
}

export interface ObserveParticipantsQuery extends ParticipantsQuery {
  skipSubscribe?: boolean;
  seed?: Participant[];
}

@Injectable({
  providedIn: 'root'
})
export class ParticipantService {
  constructor(private readonly websocketService: WebsocketService) {}

  observeParticipants(query: ObserveParticipantsQuery): Observable<Participant[]> {
    const { object, participantReference, seed, skipSubscribe } = query;
    return of(seed || ([] as Participant[])).pipe(
      switchMap(participants => {
        return (skipSubscribe ? of(null) : this.subscribeParticipantsEvents(object)).pipe(
          switchMap(() => {
            return this.participantsEvents(object, participantReference).pipe(
              scan((accumulator: Participant[], event: ParticipantEvent) => {
                switch (event.type) {
                  case ParticipantEventType.INIT: {
                    return event.data;
                  }
                  case ParticipantEventType.INSERT: {
                    accumulator.push(...event.data);
                    break;
                  }
                  case ParticipantEventType.UPDATE: {
                    accumulator.updateItemsByAnotherArray(event.data, Participant.CompareCarrier);
                    break;
                  }
                  case ParticipantEventType.DELETE: {
                    accumulator.removeItemsByAnotherArray(event.data, Participant.CompareCarrier);
                    break;
                  }
                }
                return accumulator;
              }, participants as Participant[]),
              debounceTime(500),
              map((participantsList: Participant[]) => {
                return participantsList.concat();
              })
            );
          }),
          startWith(participants)
        );
      })
    );
  }

  participantsEvents(object: EntityReference, participantReference?: EntityReference): Observable<ParticipantEvent> {
    return this.websocketService.listen({ path: WebsocketEvents.SEND.OBJECT.PARTICIPANTS.EVENT }).pipe(
      map(({ data }) => {
        return data;
      }),
      toClass(ParticipantEvent),
      filter((event: ParticipantEvent) => {
        return event.data.length > 0 && event.data[0].object.compare(object);
      }),
      filter((event: ParticipantEvent) => {
        return !participantReference || event.data[0].participantReference.compare(participantReference);
      })
    );
  }

  subscribeParticipantsEvents(object: EntityReference): Observable<void> {
    this.websocketService.query(
      WebsocketEvents.SEND.OBJECT.PARTICIPANTS.OBSERVE,
      { parent: { resource: 'object', id: object.id }, data: { objecttype: object.type } },
      { sendImmediately: true }
    );

    return new Observable<void>(subscriber => {
      subscriber.next();
      return () => {
        return this.unsubscribeParticipantsEvents(object);
      };
    });
  }

  private unsubscribeParticipantsEvents(object: EntityReference): Observable<void> {
    return this.websocketService.query<void>(
      WebsocketEvents.SEND.OBJECT.PARTICIPANTS.LEAVE,
      { parent: { resource: 'object', id: object.id }, data: { objecttype: object.type } },
      { sendImmediately: true }
    );
  }

  getParticipants(query: ParticipantsQuery): Observable<Participant[]> {
    if (!(query.object || query.participantReference)) {
      return of(<Participant[]>[]);
    }

    const data: Partial<{ objecttype: string; objectid: number; participanttype: string; participantid: number }> = {};
    if (query.object) {
      data.objecttype = query.object.type;
      data.objectid = query.object.id;
    }
    if (query.participantReference) {
      data.participanttype = query.participantReference.type;
      data.participantid = query.participantReference.id;
    }

    return this.websocketService
      .query(WebsocketEvents.SEND.OBJECT.PARTICIPANTS.GET, { data })
      .pipe(arrToClass(Participant));
  }
}
