import { CollectionViewer, SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FormControlUser, User } from '@breez/models';
import { Division } from '@breez/models/division.model';
import { Unit, UnitFlatNode, UnitNode } from '@breez/models/user-select/unit-node.model';
import { sortArray } from '@breez/shared/utilities/array';
import { EmitOnChange } from '@breez/shared/utilities/decorators/emit-on-change.decorator';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { SettingsService } from '@breez/modules/settings/services/settings.service';
import { replayWhileSubs } from '@breez/shared/rxjs-operators';
import { conferenceUserBusyErrorType } from '@breez/shared/validators/conference-user-busy.validator';
import { DynamicAction } from '@breez/shared/models/dynamic-action';

@UntilDestroy()
@Component({
  selector: 'vks-user-tree',
  templateUrl: './user-tree.component.html',
  styleUrls: ['./user-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class UserTreeComponent implements OnInit, CollectionViewer, OnChanges {
  @ViewChild('divisionContextMenuTrigger') divisionContextMenuTrigger: MatMenuTrigger;
  contextMenuX = 0;
  contextMenuY = 0;
  contextMenuItems: { label: string; action: Function }[] = [];

  @Input() userList: FormControlUser[] | User[];
  @Input() divisionsList: Division[];
  @Input() disabledUserIds: number[] = [];

  @Input() disableBusyUsers = false;
  @Input() multiSelect = false;

  @Input() expandAll = false;

  @Input() allowExpand = true;
  @Input() allowSelecting = true;
  @Input() allowRemove = false;

  @Input() reduceDivisions = false;

  @Input() checklistSelection = new SelectionModel<User>();

  @Input() selectable = true;

  @Input() emailValidation = false;

  @Input() virtualScroll = false;

  @Input() colorizeNodes = false;

  @Input() busyUsersId: number[];

  @Output() removeUsers = new EventEmitter<User[]>();
  @Output() selectUser = new EventEmitter<User>();
  @Output() deselectUser = new EventEmitter<User>();
  @Output() selectDivision = new EventEmitter<User[]>();

  @Output() nodeExpansionChanges = new EventEmitter<void>();

  @Output() action: EventEmitter<DynamicAction> = new EventEmitter<DynamicAction>();

  treeControl = new FlatTreeControl<UnitFlatNode>(
    node => {
      return node.level;
    },
    node => {
      return node.expandable;
    }
  );

  dataNodes: UnitFlatNode[] = [];

  @EmitOnChange('reduceDivisions')
  reduceDivisions$ = new ReplaySubject<boolean>(1);

  @EmitOnChange('expandAll')
  expandAll$ = new ReplaySubject<boolean>(1);

  @EmitOnChange('userList', { onlyTruthy: true })
  userList$ = new ReplaySubject<User[]>(1);

  @EmitOnChange('divisionsList', { onlyTruthy: true })
  divisionsList$ = new ReplaySubject<Division[]>(1);

  @EmitOnChange('disabledUserIds')
  disabledUserIds$ = new BehaviorSubject<number[]>(this.disabledUserIds);

  treeFlattener = new MatTreeFlattener<UnitNode, UnitFlatNode>(
    (node: UnitNode, level: number): UnitFlatNode => {
      // css-классы: color0, color1, color2, color3, color4, color1, color2, color3...
      const classIndex = level === 0 ? 0 : ((level - 1) % 4) + 1;
      return <UnitFlatNode>{
        disabled: node.disabled,
        unit: node.unit,
        level: level,
        expandable: node.children.length > 0,
        id: (this.isUnitDivision(node.unit) ? 'DIVISION_' : 'USER_') + node.unit.id,
        classIndex
      };
    },
    node => {
      return node.level;
    },
    node => {
      return node.expandable;
    },
    node => {
      return node.children;
    }
  );

  @EmitOnChange('busyUsersId')
  busyUsersId$: ReplaySubject<number[]> = new ReplaySubject(1);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  showDivisionAsUser$: Observable<boolean> = this.settingsService.getValue('planner', 'conf_division_as_user').pipe(
    map(setting => {
      return Boolean(Number.parseInt(setting.value, 0));
    }),
    replayWhileSubs()
  );

  userTreeDataSource$: Observable<UnitNode[]> = combineLatest([
    this.userList$,
    this.divisionsList$,
    this.disabledUserIds$,
    this.reduceDivisions$,
    this.busyUsersId$,
    this.showDivisionAsUser$
  ]).pipe(
    map(([users, divisions, disabledUserIds, reduceDivisions, busyUsersId, confDivisionAsUser]) => {
      return this.buildUserTree(users, divisions, disabledUserIds, reduceDivisions, busyUsersId, confDivisionAsUser);
    }),
    shareReplay(1)
  );

  viewChange = new BehaviorSubject<{ start: number; end: number }>({ start: 0, end: Number.MAX_VALUE });

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private ngZone: NgZone,
    private settingsService: SettingsService
  ) {
    this.dataSource.data = [];
  }

  ngOnInit(): void {
    this.ngZone.runOutsideAngular(() => {
      this.dataSource.connect(this).subscribe(visibleNodes => {
        this.dataNodes = visibleNodes;
        this.changeDetectorRef.detectChanges();
      });

      this.userTreeDataSource$
        .pipe(
          switchMap(data => {
            this.dataSource.data = data;
            return this.expandAll$;
          }),
          untilDestroyed(this)
        )
        .subscribe(expandAll => {
          if (expandAll) {
            this.treeControl.expandAll();
          } else {
            this.treeControl.collapseAll();
          }
          this.changeDetectorRef.detectChanges();
          this.nodeExpansionChanges.emit();
        });

      this.checklistSelection.changed
        .asObservable()
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          return this.changeDetectorRef.detectChanges();
        });
    });

    this.reduceDivisions$.next(this.reduceDivisions);
    this.busyUsersId$.next([]);
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnChanges(_: SimpleChanges): void {}

  isUnitDivision(unit: Unit): boolean {
    return unit instanceof Division;
  }

  nodesTrackById(_, node: UnitFlatNode): string {
    return node.id;
  }

  leafSelected(node: UnitFlatNode): boolean {
    return this.checklistSelection.selected.some(item => {
      return item.id === node.unit.id;
    });
  }

  /**
   * Проверка на выбор всех дочерних элементов
   */
  descendantsAllSelected(parentNode?: UnitFlatNode): boolean {
    const descendants = parentNode ? this.treeControl.getDescendants(parentNode) : this.getAllNodes();
    return (
      descendants.length > 0 &&
      descendants
        .filter(node_ => {
          return !node_.expandable;
        })
        .every(child => {
          return this.checklistSelection.selected.some(item => {
            return item.id === child.unit.id;
          });
        })
    );
  }

  /**
   * Проверка на выбор некоторых дочерних элементов:
   * Выбран хотя бы один элементов **и** не все элементы
   */
  descendantsPartiallySelected(parentNode?: UnitFlatNode): boolean {
    let checkedAtLeastOne = false;
    let checkedAll = true;
    const descendants = parentNode ? this.treeControl.getDescendants(parentNode) : this.getAllNodes();
    descendants
      .filter(node_ => {
        return !node_.expandable;
      })
      .forEach(child => {
        const isChecked = this.checklistSelection.selected.some(item => {
          return item.id === child.unit.id;
        });
        if (isChecked) {
          checkedAtLeastOne = true;
        } else {
          checkedAll = false;
        }
      });
    return checkedAtLeastOne && !checkedAll;
  }

  isAllDescendantSelected(parentNode?: UnitFlatNode): boolean {
    const descendants = parentNode ? this.treeControl.getDescendants(parentNode) : this.getAllNodes();
    return descendants
      .filter(node_ => {
        return !node_.expandable && !node_.disabled;
      })
      .every(child => {
        return this.checklistSelection.selected.some(item => {
          return item.id === child.unit.id;
        });
      });
  }

  isAnyDescendantSelected(node?: UnitFlatNode): boolean {
    const descendants = node ? this.treeControl.getDescendants(node) : this.getAllNodes();
    return descendants
      .filter(node_ => {
        return !node_.expandable && !node_.disabled;
      })
      .some(child => {
        return this.checklistSelection.selected.some(item => {
          return item.id === child.unit.id;
        });
      });
  }

  isAnyDescendantExpanded(parentNode?: UnitFlatNode): boolean {
    const descendants = parentNode ? this.treeControl.getDescendants(parentNode) : this.getAllNodes();
    return descendants
      .filter(descendant => {
        return descendant.expandable;
      })
      .some(descendant => {
        return this.treeControl.isExpanded(descendant);
      });
  }

  isAnyVisibleSelected(parentNode?: UnitFlatNode): boolean {
    return this.getVisibleUsers(parentNode).some(user => {
      return this.checklistSelection.selected.some(item => {
        return item.id === user.id;
      });
    });
  }

  isAllVisibleSelected(parentNode?: UnitFlatNode): boolean {
    return this.getVisibleUsers(parentNode).every(user => {
      return this.checklistSelection.selected.some(item => {
        return item.id === user.id;
      });
    });
  }

  hasDescendedDivisions(parentNode?: UnitFlatNode): boolean {
    const descendants = parentNode ? this.treeControl.getDescendants(parentNode) : this.getAllNodes();
    return descendants.some(node => {
      return this.isUnitDivision(node.unit);
    });
  }

  descendantsCount(parentNode: UnitFlatNode): number {
    return this.treeControl.getDescendants(parentNode).filter(node => {
      return !this.isUnitDivision(node.unit);
    }).length;
  }

  hasDescendants(parentNode: UnitFlatNode): boolean {
    return this.treeControl.getDescendants(parentNode).length > 0;
  }

  toggleNodeCheck(eventNode: UnitFlatNode, event?: MouseEvent): boolean {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    const descendants = this.treeControl
      .getDescendants(eventNode)
      .filter(node => {
        return !node.disabled && !this.isUnitDivision(node.unit);
      })
      .map(node => {
        return node.unit as User;
      });

    if (this.isAllDescendantSelected(eventNode)) {
      const selected = this.checklistSelection.selected.filter(item => {
        return descendants.some(user => {
          return item.id === user.id;
        });
      });
      this.checklistSelection.deselect(...selected);
      this.removeUsers.emit(selected);
    } else {
      const notSelected = descendants.filter(user => {
        return !this.checklistSelection.selected.some(item => {
          return item.id === user.id;
        });
      });
      this.checklistSelection.select(...notSelected);
      this.selectDivision.emit(notSelected);
    }

    return false;
  }

  buildUserTree(
    userList: User[],
    divisionsList: Division[],
    disableUserIds: number[],
    reduceDivisions,
    busyUsersId: number[],
    confDivisionAsUser: boolean
  ): UnitNode[] {
    userList = [...userList.slice().sort(sortArray('name'))];
    divisionsList = divisionsList.slice();
    try {
      if (busyUsersId && busyUsersId.length) {
        userList = userList.map(user => {
          if (busyUsersId.indexOf(user.id) > -1) {
            if ((<any>user).errors) {
              (<any>user).errors[conferenceUserBusyErrorType.type] = true;
            } else {
              (<any>user).errors = {
                [conferenceUserBusyErrorType.type]: true
              };
            }
          }
          return user;
        });
      } else {
        userList = userList.map(user => {
          if ((<any>user).errors) {
            (<any>user).errors[conferenceUserBusyErrorType.type] = false;
          } else {
            (<any>user).errors = {
              [conferenceUserBusyErrorType.type]: false
            };
          }
          return user;
        });
      }
    } catch {}

    // собираем пул подразделений, у которых есть пользователи
    const filledDivisions: UnitNode[] = divisionsList.reduce(
      (acc, division) => {
        const childrenUsers = userList.filter(user => {
          return user.departmentId === division.id;
        });
        // вырезаем найденных пользователей из общего пула пользователей
        userList = userList.filter(user => {
          return !childrenUsers.includes(user);
        });

        if (!childrenUsers.length) {
          return acc;
        }

        acc.push(<UnitNode>{
          unit: division,
          children: childrenUsers.map(user => {
            return this.getUserAsUnitNode(user, disableUserIds);
          })
        });

        return acc;
      },
      <UnitNode[]>[]
    );

    let rootDivisions: UnitNode[] = [];
    for (let index = 0; index < filledDivisions.length; index++) {
      let item = filledDivisions[index];
      const parentKey = (item.unit as Division).parentKey;
      // ищем родительское подразделение среди пула заполненных пользователями
      let parentNode = filledDivisions.find(node => {
        return (node.unit as Division).key === parentKey;
      });

      if (!parentNode) {
        // ищем родительское подразделение среди общего пула
        const parentDivision = divisionsList.find(division => {
          return division.key === parentKey;
        });

        if (parentDivision) {
          parentNode = {
            unit: parentDivision,
            children: []
          };
          filledDivisions.push(parentNode);
        }
      }

      if (confDivisionAsUser) {
        if (item.children.length === 1) {
          const objUsers = item.children.filter(child => {
            return !this.isUnitDivision(child.unit);
          });
          if (objUsers.length === 1) {
            item = this.transformDivisionToUser(
              objUsers[0].unit as User,
              item.unit as Division,
              item.children.concat(objUsers[0].children),
              objUsers[0].disabled
            );
            filledDivisions[index] = item;
          }
        }
      }

      if (parentNode) {
        parentNode.children.push(item);
      } else {
        // добавляем текущее подразделение к корневому пулу
        rootDivisions.push(item);
      }
    }

    if (reduceDivisions) {
      /*
      задача reduceDivisions -- поднять из глубины первое подразделение, заполненное хотя бы двумя элементами:
      + подразделение
      + + подподразделение
      + + + первое подподразделение
      + + + участник подподразделения
      + + + ещё участник подподразделения

      превращается в:
      + подподразделение
      + + первое подподразделение
      + + участник подподразделения
      + + ещё участник подподразделения
       */
      while (
        rootDivisions.length === 1 &&
        rootDivisions[0].children.length === 1 &&
        rootDivisions[0].children[0].children.length > 0
      ) {
        if (confDivisionAsUser && !this.isUnitDivision(rootDivisions[0].unit)) {
          break;
        }
        rootDivisions = rootDivisions[0].children;
      }
    }

    // объединяем корневые подразделения с пользователями, не относящимися к подразделению
    return rootDivisions.concat(
      userList.map(user => {
        return this.getUserAsUnitNode(user, disableUserIds);
      })
    );
  }

  private getUserAsUnitNode(user: User, disableUserIds: number[] = []): UnitNode {
    return {
      unit: user,
      children: [],
      disabled: (this.disableBusyUsers && user.statusId > 1) || disableUserIds.includes(user.id)
    } as UnitNode;
  }

  private transformDivisionToUser(
    user: User,
    { key, order, parentKey }: Division,
    children: UnitNode[],
    disabled: boolean
  ): UnitNode {
    return {
      children: children.filter(child => {
        return child.unit.id !== user.id;
      }),
      unit: { ...user, key, order, parentKey } as any,
      disabled
    } as UnitNode;
  }

  toggleSelectLeaf(node: UnitFlatNode): void {
    if (node.disabled) {
      return;
    }

    const user = node.unit as User;

    const selected = this.checklistSelection.selected.find(item => {
      return item.id === user.id;
    });

    if (selected) {
      this.checklistSelection.deselect(selected);
      this.deselectUser.emit(user);
    } else {
      this.checklistSelection.toggle(user);
      this.selectUser.emit(user);
    }
  }

  removeDivision(node: UnitFlatNode): void {
    const usersForRemove: User[] = this.treeControl
      .getDescendants(node)
      .filter(node_ => {
        return !node_.expandable && !node_.disabled;
      })
      .map(node_ => {
        return node_.unit as User;
      });

    this.removeUsers.emit(usersForRemove);
  }

  removeUser(node: UnitFlatNode): void {
    const usersForRemove: User[] = [node.unit as User];
    this.removeUsers.emit(usersForRemove);
  }

  getAllNodes(): UnitFlatNode[] {
    return this.treeControl.dataNodes;
  }

  getChildren(parentNode?: UnitFlatNode): UnitFlatNode[] {
    if (!parentNode) {
      return this.getAllNodes().filter(node_ => {
        return node_.level === 0;
      });
    }
    return this.treeControl.getDescendants(parentNode).filter(node_ => {
      return node_.level === parentNode.level + 1;
    });
  }

  getVisibleNodes(parentNode?: UnitFlatNode): UnitFlatNode[] {
    let currentNodes = this.getChildren(parentNode);
    let visibleNodes = [];

    while (currentNodes.length > 0) {
      const levelNodes = currentNodes.filter(node_ => {
        return node_.expandable;
      });
      visibleNodes = levelNodes.concat(visibleNodes);

      currentNodes = levelNodes
        .filter(node_ => {
          return this.treeControl.isExpanded(node_);
        })
        .reduce(
          (nodes, node_) => {
            return nodes.concat(this.getChildren(node_));
          },
          <UnitFlatNode[]>[]
        );
    }

    return visibleNodes;
  }

  getVisibleUsers(parentNode?: UnitFlatNode): User[] {
    let currentNodes = this.getChildren(parentNode);
    let selectingUsers = [];

    while (currentNodes.length > 0) {
      selectingUsers = currentNodes
        .filter(node => {
          return !node.disabled && !this.isUnitDivision(node.unit);
        })
        .map(node => {
          return node.unit as User;
        })
        .concat(selectingUsers);

      currentNodes = currentNodes
        .filter(node => {
          return node.expandable && this.treeControl.isExpanded(node);
        })
        .reduce(
          (nodes, node) => {
            return nodes.concat(this.getChildren(node));
          },
          <UnitFlatNode[]>[]
        );
    }

    return selectingUsers;
  }

  getUsers(parentNode?: UnitFlatNode): User[] {
    const nodes: UnitFlatNode[] = parentNode ? this.treeControl.getDescendants(parentNode) : this.getAllNodes();

    return nodes
      .filter(node => {
        return !node.disabled && !this.isUnitDivision(node.unit);
      })
      .map(node => {
        return node.unit as User;
      });
  }

  invertLeafs(nodes: User[]): void {
    const notSelected = nodes.filter(user => {
        return !this.checklistSelection.selected.some(item => {
          return item.id === user.id;
        });
      }),
      selected = this.checklistSelection.selected.filter(item => {
        return nodes.some(node => {
          return node.id === item.id;
        });
      });

    this.checklistSelection.select(...notSelected);
    this.checklistSelection.deselect(...selected);
  }

  toggleExpansion(node: UnitFlatNode): void {
    if (!node) {
      return;
    }
    if (this.treeControl.isExpanded(node)) {
      this.treeControl.collapse(node);
    } else {
      this.treeControl.expand(node);
    }
    this.nodeExpansionChanges.emit();
  }

  deepExpand(node?: UnitFlatNode): void {
    if (!node) {
      this.treeControl.expandAll();
    } else {
      this.treeControl.expandDescendants(node);
    }
    this.nodeExpansionChanges.emit();
  }

  deepCollapse(node?: UnitFlatNode): void {
    if (!node) {
      this.treeControl.collapseAll();
    } else {
      this.treeControl.collapseDescendants(node);
    }
    this.nodeExpansionChanges.emit();
  }

  expandVisible(node?: UnitFlatNode): void {
    this.getVisibleNodes(node).forEach(item => {
      return this.treeControl.expand(item);
    });
    this.nodeExpansionChanges.emit();
  }

  selectAll(node?: UnitFlatNode): void {
    const users = this.getUsers(node);
    if (!!node && !this.isUnitDivision(node.unit)) {
      users.push(node.unit as User);
    }
    this.checklistSelection.select(...users);
  }

  deselectAll(node?: UnitFlatNode): void {
    const users = this.getUsers(node);
    if (!!node && !this.isUnitDivision(node.unit)) {
      users.push(node.unit as User);
    }
    const checked = this.checklistSelection.selected.filter(item => {
      return users.some(user => {
        return user.id === item.id;
      });
    });
    this.checklistSelection.deselect(...checked);
  }

  invertAll(node?: UnitFlatNode): void {
    this.invertLeafs(this.getUsers(node));
  }

  selectVisible(node?: UnitFlatNode): void {
    this.checklistSelection.select(...this.getVisibleUsers(node));
  }

  deselectVisible(node?: UnitFlatNode): void {
    const visibleUsers = this.getVisibleUsers(node);
    const checked = this.checklistSelection.selected.filter(item => {
      return visibleUsers.some(user => {
        return user.id === item.id;
      });
    });
    this.checklistSelection.deselect(...checked);
  }

  invertVisible(node?: UnitFlatNode): void {
    this.invertLeafs(this.getVisibleUsers(node));
  }

  openContextMenu(event: MouseEvent, node: UnitFlatNode): void {
    if (!node.expandable) {
      return;
    }
    event.preventDefault();
    this.contextMenuX = event.clientX;
    this.contextMenuY = event.clientY;
    this.contextMenuItems = [];
    if (this.hasDescendedDivisions(node)) {
      if (this.isAnyDescendantExpanded(node)) {
        this.contextMenuItems.push({
          label: 'COLLAPSE_ALL',
          action: () => {
            return this.deepCollapse(node);
          }
        });
      } else {
        this.contextMenuItems.push({
          label: 'EXPAND_ALL',
          action: () => {
            return this.deepExpand(node);
          }
        });
      }
    }

    if (this.multiSelect) {
      if (this.descendantsAllSelected(node)) {
        this.contextMenuItems.push({
          label: 'DESELECT_ALL',
          action: () => {
            return this.deselectAll(node);
          }
        });
      } else {
        this.contextMenuItems.push({
          label: 'SELECT_EVERYONE',
          action: () => {
            return this.selectAll(node);
          }
        });
      }
    }

    if (this.treeControl.isExpanded(node) && this.hasDescendants(node)) {
      this.contextMenuItems.push({
        label: 'EXPAND_VISIBLE',
        action: () => {
          return this.expandVisible(node);
        }
      });
      if (this.getVisibleUsers(node).length > 0 && !this.isAllVisibleSelected(node)) {
        this.contextMenuItems.push({
          label: 'SELECT_VISIBLE',
          action: () => {
            return this.selectVisible(node);
          }
        });
      }
      if (this.isAnyDescendantExpanded(node)) {
        this.contextMenuItems.push({
          label: 'COLLAPSE_VISIBLE',
          action: () => {
            return this.getVisibleNodes(node).forEach(item => {
              return this.toggleExpansion(item);
            });
          }
        });
      }
      if (this.isAnyVisibleSelected(node)) {
        this.contextMenuItems.push({
          label: 'DESELECT_VISIBLE',
          action: () => {
            return this.deselectVisible(node);
          }
        });
      }
    }

    if (this.multiSelect) {
      this.contextMenuItems.push({
        label: 'INVERT',
        action: () => {
          return this.invertAll(node);
        }
      });
    }

    this.contextMenuItems = this.contextMenuItems.filter(isTruthy);

    if (!this.contextMenuItems.length) {
      return;
    }

    this.divisionContextMenuTrigger.openMenu();
  }

  openParticipationIntervalsInfo(): void {
    this.action.next({ name: 'participationintervalsinfo', action: 'show' } as DynamicAction);
  }

  userTooltipDisabled(span: HTMLSpanElement): boolean {
    return !(span.offsetHeight < span.scrollHeight);
  }
}
