import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { SharedModule } from '@breez/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { MODULE_COMPONENTS } from './components';
import { config, DEFAULT_WEBRTC_CONSTRAINTS, generateWebrtcConfig, generateWebrtcConstraints } from './webrtc.config';

import { MODULE_PROVIDERS } from './';
import { MatCardModule } from '@angular/material/card';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [MODULE_COMPONENTS],
  imports: [
    CommonModule,
    MatButtonToggleModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    SharedModule,
    TranslateModule,
    MatSelectModule,
    MatCardModule,
    MatSlideToggleModule,
    MatTooltipModule
  ],
  exports: [MODULE_COMPONENTS],
  providers: [
    MODULE_PROVIDERS,
    {
      provide: config,
      useFactory: generateWebrtcConfig
    },
    {
      provide: DEFAULT_WEBRTC_CONSTRAINTS,
      useFactory: generateWebrtcConstraints
    }
  ]
})
export class WebrtcModule {}
