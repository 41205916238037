import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { StateService } from '@breez/shared/services/state.service';
import { combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthService } from '@breez/modules/auth/services/auth.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'vks-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent implements OnInit {
  $roles = combineLatest([this.authService.currentUser$, this.authService.getRoles()]).pipe(
    filter(([user, _]) => {
      return !!user;
    })
  );

  logoSvg: string = `assets/svg/breez-home.svg`;

  constructor(
    private stateService: StateService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.stateService.setExpandedGUI(true);
  }
}
