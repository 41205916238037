import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ValidationError } from './validation-error.model';
import { UserParticipationInfo } from '@breez/modules/conference/models/user-participatrion-info.model';

const ERROR = {
  type: 'conferenceuserbusy',
  label: 'CONFERENCE_USERS_IS_BUSY',
  tooltip: 'CONFERENCE_USERS_IS_BUSY'
} as ValidationError;

export function conferenceUserBusyValidator(busyUsers$: BehaviorSubject<UserParticipationInfo[]>): ValidatorFn {
  return (_: AbstractControl): ValidationErrors | null => {
    if (busyUsers$.value.length === 0) {
      return null;
    }
    return { [ERROR.type]: ERROR } as ValidationErrors;
  };
}

export const conferenceUserBusyErrorType = ERROR;
