import { IConferenceTemplateInfo } from '@breez/models/template/conference-template-info.model';

export const ADVANCED_MODE_TEMPLATE: IConferenceTemplateInfo = {
  conferencetemplateid: 0,
  title: {
    ru: 'Экспертный режим',
    en: 'Advanced mode'
  },
  description: {
    ru: 'Создайте конференцию и настройте параметры для своего сценария.',
    en: 'Create a conference and configure options for your script.'
  },
  icon: 'new-template',
  type: 'system'
};
