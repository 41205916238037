import { createEntityAdapter } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Message } from '../../models';
import { CHAT_ID, MESSAGE_ID } from '../../types';
import * as ModuleState from '../module.state';
import { CHAT_FEATURE_KEY } from '../chat-feature-key.const';
import { EMPTY_MESSAGE } from '@breez/modules/chat';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { chatEntityById } from '@breez/modules/chat/+state/chat';

export const getFeatureState = createFeatureSelector<ModuleState.State>(CHAT_FEATURE_KEY);
export const getState = createSelector(getFeatureState, state => {
  return state.message;
});

export const { selectAll, selectEntities } = createEntityAdapter<Message>().getSelectors(getState);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getMessages = (chatId: CHAT_ID) => {
  return createSelector(selectAll, messages => {
    return messages.filter(message => {
      return message.chatId === chatId;
    });
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getMessage = (messageId: MESSAGE_ID) => {
  return createSelector(getState, state => {
    return state.entities[messageId] ?? EMPTY_MESSAGE();
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getMessagesCount = (chatId: CHAT_ID) => {
  return createSelector(getMessages(chatId), messages => {
    return messages.length ?? 0;
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getMessagesRange = (chatId: CHAT_ID, anchor: Message, count: number) => {
  return createSelector(getMessages(chatId), messages => {
    const size = messages.length;
    if (size <= count) {
      return { messages };
    }

    if (!isTruthy(anchor)) {
      return { messages: messages.slice(-count) };
    }

    const anchorIndex = messages.findIndex(message => {
      return message.id === anchor.id;
    });

    const from = anchorIndex - count <= 0 ? 0 : anchorIndex - count + 1;
    const to = from === 0 ? count : anchorIndex + 1;
    const mes = messages.slice(from, to);

    return { messages: mes };
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getLastMessage = (chatId: CHAT_ID) => {
  return createSelector(getMessages(chatId), chatEntityById(chatId), (messages, chat) => {
    return messages[messages.length - 1] ?? chat?.lastMessage ?? undefined;
  });
};
