import { Expose } from 'class-transformer';

export class WebrtcStatsEntry {
  @Expose({ name: 'b' })
  bitrate?: number;

  @Expose({ name: 'jitt' })
  jitter?: number;

  @Expose({ name: 'precv' })
  packetsReceived?: number;

  @Expose({ name: 'ploss' })
  packetsLost?: number;

  @Expose({ name: 'perct' })
  packetsLostPercentage?: number;

  constructor(base: Partial<WebrtcStatsEntry>) {
    return Object.assign(this, base);
  }
}
