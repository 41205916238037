import { Expose, Type } from 'class-transformer';
import { Chat } from './chat';
import { User } from '@breez/models';

export class FoundChat {
  @Expose()
  @Type(() => {
    return Chat;
  })
  chat: Chat;

  @Expose()
  @Type(() => {
    return User;
  })
  users: User[];
}
