import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ControlsModule } from '@breez/modules/controls/controls.module';
import { PermissionsModule } from '@breez/modules/permissions/permissions.module';
import { AvatarSmartModule } from '@breez/modules/user/modules/avatar-smart/avatar-smart.module';
import { ContextControlsComponent } from '@breez/shared/components/context-controls';
import { TableColumnsComponent } from '@breez/shared/modules/ui-controls/components/table-columns/table-columns.component';
import { PipesModule } from '@breez/shared/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';

import { UiControlsRoutingModule } from './ui-controls-routing.module';
import { TableControlComponent } from './components/table-control/table-control.component';

@NgModule({
  declarations: [TableControlComponent, TableColumnsComponent, ContextControlsComponent],
  imports: [
    CommonModule,
    UiControlsRoutingModule,
    MatTableModule,
    MatPaginatorModule,
    AvatarSmartModule,
    PipesModule,
    TranslateModule,
    ControlsModule,
    MatTooltipModule,
    MatMenuModule,
    PermissionsModule,
    MatButtonModule,
    MatSortModule,
    MatProgressSpinnerModule
  ],
  exports: [TableColumnsComponent, TableControlComponent, ContextControlsComponent]
})
export class UiControlsModule {}
