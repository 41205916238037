import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OverlayContainer } from '@breez/modules/overlay/models';
import { UserListTypesComponentResolver } from '../user-list-types-component.resolver';
import { ObjectType } from '@breez/shared/enums/object-type.enum';

@Component({
  selector: 'vks-user-list-dynamic',
  template: '<ng-container *ngxComponentOutlet="resolver | resolve:type"></ng-container>',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserListTypeDynamicComponent implements OnInit {
  @Input() conferenceId: string;
  @Output() changeOverlayContainer = new EventEmitter<OverlayContainer>();
  type: string;

  constructor(public resolver: UserListTypesComponentResolver) {}

  ngOnInit(): void {
    if (!!this.conferenceId) {
      this.type = ObjectType.CONFERENCE;
    }
  }
}
