import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { BACKDROP_CLICK } from '@breez/modules/overlay/overlay-types/overlay-entry-event.provider';
import { Observable } from 'rxjs';
import { OverlayEntry } from '@breez/modules/overlay/models';
import { OverlayService } from '@breez/modules/overlay/services/overlay.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ProcessingFileModel } from '@breez/models/shared/files/processing-file.model';
import { ImagePreviewEntry } from '@breez/modules/image-preview/models/image-preview-entry.model';
import { NotificationService } from '@breez/modules/notification';
import { NotificationType } from '@breez/models';

@UntilDestroy()
@Component({
  selector: 'vks-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImagePreviewComponent implements OnInit, OnChanges {
  @Input() entry: OverlayEntry<{ images: ImagePreviewEntry[] }>;

  // @ts-ignore
  showCopyToClipboard = !!navigator.clipboard && !!navigator.clipboard.write;

  files: ProcessingFileModel[];
  selectedFileIndex = 0;

  @HostListener('document:keydown.escape')
  closePreview(): void {
    this.close();
  }

  constructor(
    private overlayService: OverlayService,
    private notificationService: NotificationService,
    @Inject(BACKDROP_CLICK) private backdropClicked: Observable<MouseEvent>,
    @Inject('ORIGIN') public origin
  ) {}

  ngOnInit(): void {
    this.backdropClicked.pipe(untilDestroyed(this)).subscribe(() => {
      return this.close();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.entry) {
      if (!this.entry || !this.entry.data || !Array.isArray(this.entry.data.images)) {
        this.files = [];
        return;
      }
      const images = this.entry.data.images;
      this.files = images.map(image => {
        return image.imageFile;
      });
      this.selectedFileIndex = images.findIndex(image => {
        return image.selected;
      });
    }
  }

  convertToPng = (imgBlob: Blob): void => {
    const imageUrl = window.URL.createObjectURL(imgBlob);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const imageEl = this.createImage({ src: imageUrl });
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    imageEl.onload = e => {
      // @ts-ignore
      canvas.width = e.target.width;
      // @ts-ignore
      canvas.height = e.target.height;
      // @ts-ignore
      ctx.drawImage(e.target, 0, 0, e.target.width, e.target.height);
      canvas.toBlob(this.copyToClipboardPng, 'image/png', 1);
    };
  };

  createImage = (options: any): any => {
    options = options || {};
    const img = Image ? new Image() : document.createElement('img');
    if (options.src) {
      img.src = options.src;
    }
    return img;
  };

  scale: number = 1;

  copyToClipboardPng = (pngBlob: Blob): void => {
    try {
      // @ts-ignore
      navigator.clipboard.write([
        // @ts-ignore
        new ClipboardItem({
          [pngBlob.type]: pngBlob
        })
      ]);
      this.notificationService.showWithTranslate({
        type: NotificationType.Information,
        message: 'FILE_COPIED_TO_CLIPBOARD'
      });
    } catch (error) {
      console.error(error);
      this.notificationService.showWithTranslate({
        type: NotificationType.Information,
        message: 'FILE_COPY_FAILED'
      });
    }
  };

  async copyToClipboard(): Promise<void> {
    const selectedImage = this.files[this.selectedFileIndex];
    const blob = await fetch(selectedImage.previewContentUrl).then(r => {
      return r.blob();
    });
    if (selectedImage.type === 'image/jpeg') {
      this.convertToPng(blob);
    } else if (selectedImage.type === 'image/png' || selectedImage.contentUrl.indexOf('data:image/png;base64') >= 0) {
      this.copyToClipboardPng(blob);
    } else {
      console.error('Unknown file format!');
      this.notificationService.showWithTranslate({
        type: NotificationType.Information,
        message: 'FILE_COPY_FAILED'
      });
    }
  }

  next(): void {
    this.selectedFileIndex = (this.selectedFileIndex + 1) % this.files.length;
  }

  previous(): void {
    this.selectedFileIndex = this.selectedFileIndex > 0 ? this.selectedFileIndex - 1 : this.files.length - 1;
  }

  close(): void {
    this.overlayService.removeContainer(this.entry);
  }
}
