<vks-icon
  *ngIf="icon"
  [class.btn-icon-left]="iconPosition === 'left'"
  [class.btn-icon-right]="iconPosition === 'right'"
  [class]="iconClass"
  [color]="color"
  [icon]="icon"
>
</vks-icon>
<span class="label">
  {{ label }}
</span>

<ng-container *ngIf="spinnerIcon; else matSpinner">
  <div class="btn-spinner-cover">
    <vks-spinner [icon]="spinnerIcon"></vks-spinner>
  </div>
</ng-container>
<!--TODO #ShvetsDaniil BREEZ-629: Привести использования спиннеров в control-button.component к единому виду-->
<ng-template #matSpinner>
  <ng-container *ngIf="loading">
    <div class="btn-spinner-cover">
      <mat-spinner [diameter]="spinnerDiameter"></mat-spinner>
    </div>
  </ng-container>
</ng-template>
