<div [class.in-chat]="!forMessageEdit" class="container">
  <div *ngIf="messageList.length === 1 && messageList[0] as singleMessage; else multiple" class="message">
    <div class="sender">
      <span class="sender-name">{{ senderPreview$ | async }}</span>
      <span *ngIf="forMessageEdit" class="send-date weak-badge">
        {{ singleMessage.sentDate | humanizeDate: 'time-or-date' | async }}
      </span>
    </div>
    <div [class.is-out]="isOut" class="message-content">
      <span class="message-body" #messageBody></span>
      <vks-file-list
        *ngIf="singleMessage.attachments.length > 0"
        [files]="singleMessage.attachments"
        [forChat]="true"
        [forMessageEdit]="false"
        [isOut]="isOut"
      ></vks-file-list>
    </div>
  </div>
  <button
    *ngIf="forMessageEdit"
    vks-button
    (buttonClick)="cancelForward.emit()"
    class="btn-basic btn-compressed"
    icon="x-10"
    iconClass="square10"
  ></button>
</div>
<ng-template #multiple>
  <div class="message">
    <div class="sender">
      <div class="sender-name">
        {{ 'FORWARDED_MESSAGES' | translate }}
      </div>
    </div>
    <span class="message-body">{{ getMessagesCount(messageList) | async }}</span>
  </div>
</ng-template>
