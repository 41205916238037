import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Link } from '@breez/models/link.model';
import { map } from 'rxjs/operators';
import { LinksService } from '@breez/shared/services/links.service';

@Component({
  selector: 'vks-webrtc-error-message',
  templateUrl: './webrtc-error-message.component.html',
  styleUrls: ['./webrtc-error-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WebrtcErrorMessageComponent {
  @Input() headerText: string;
  @Input() contentText: string;
  @Input() actionText: string;

  @Output() action = new EventEmitter<void>();
  @Output() closeAction = new EventEmitter<void>();

  helpLink$: Observable<Link> = this.linksService.getLinks().pipe(
    map(links => {
      return links.find(link => {
        return link.name === 'Справка и поддержка';
      });
    })
  );

  constructor(private linksService: LinksService) {}

  close(): void {
    this.closeAction.emit();
  }

  reconnect(): void {
    this.action.emit();
  }

  openHelp(url: string): void {
    window.open(url);
  }
}
