<input
  *ngIf="mode === 'input'"
  vks-cinput
  [class.disabled]="disable"
  [formControl]="userInput"
  (focus)="setTouched()"
  type="text"
/>
<span *ngIf="mode === 'text'">
  <ng-container *ngIf="selectedDate$ | async; let date; else: noDate">
    {{ minDate | durationFormat: date : false | async }}
  </ng-container>
  <ng-template #noDate>{{ 'NOT_SET' | translate }}</ng-template>
</span>
<vks-popup #popup [template]="picker">
  <ng-template #picker>
    <vks-control-time-picker
      [currentHours]="userInputHours$ | async"
      [currentMinutes]="userInputMinutes$ | async"
      [minutesItemsStep]="minutesItemsStep"
      (close)="popup.close()"
      (opened)="setTouched()"
      (picked)="updateTimeFromPicker($event)"
    >
    </vks-control-time-picker>
  </ng-template>
</vks-popup>
<button
  vks-button
  (buttonClick)="!disable ? popup.open() : null"
  class="btn-anchor btn-compressed"
  icon="control-time"
  iconClass="square24"
  tabindex="-1"
  type="button"
></button>
