import { Injectable } from '@angular/core';
import { Call } from '@breez/modules/call/call';
import { CallDestinationType, CallModel, CallStatus } from '@breez/modules/call/models';
import { OverlayEntryType } from '@breez/modules/overlay/models/overlay-entry-type.enum';
import { OverlayPositionType } from '@breez/modules/overlay/models/overlay-position-type.enum';
import { OverlayService } from '@breez/modules/overlay/services/overlay.service';
import { WebsocketEvents, WebsocketService } from '@breez/modules/websocket';
import { clone, toClass, toPlain } from '@breez/shared/rxjs-operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { StateService } from '@breez/shared/services/state.service';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { ElectronService } from '@breez/modules/core/services';
import { audio } from '@breez/shared/utilities/audio';

@Injectable({
  providedIn: 'root'
})
export class CallService {
  isElectronApp: boolean = this.electronService.isElectron;

  isThisTabEventInitiator$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private readonly wsService: WebsocketService,
    private readonly overlayService: OverlayService,
    private readonly electronService: ElectronService,
    private readonly stateService: StateService
  ) {}

  private removeContainer(call: Call): void {
    this.overlayService.removeContainer({
      id: call.callId,
      type: OverlayEntryType.CALL
    });
  }

  changeCall(call: CallModel, status: CallStatus, isThisTabEventInitiator, front = false): Observable<any> {
    this.isThisTabEventInitiator$.next(isThisTabEventInitiator);
    return of(call).pipe(
      clone(),
      map((item: CallModel) => {
        item.status = status;
        delete (<any>item).parent;
        return item;
      }),
      toPlain(),
      switchMap(data => {
        return front
          ? of(call)
          : this.wsService.send(WebsocketEvents.SEND.WEBRTC.CALL, { data }).pipe(toClass(CallModel));
      }),
      catchError(() => {
        return of(null);
      })
    );
  }

  changeOverlayContainer(
    call: Call,
    callModel: CallModel,
    anotherActiveCallId?: number,
    isThisTabEventInitiator = false
  ): void {
    let position: OverlayPositionType;
    let modal;
    const bottomRightPosition = OverlayPositionType.SIDE;

    switch (callModel.status) {
      case CallStatus.RING: {
        modal = callModel.destinationType === CallDestinationType.OUTGOING;
        position =
          callModel.destinationType === CallDestinationType.OUTGOING ? OverlayPositionType.CENTER : bottomRightPosition;
        break;
      }
      case CallStatus.ACCEPT: {
        modal = true;
        position = OverlayPositionType.CENTER;
        break;
      }
      case CallStatus.TRANSFER:
        break;
      case CallStatus.HOLD: {
        modal = false;
        position = bottomRightPosition;
        break;
      }

      case CallStatus.DECLINE:
      case CallStatus.CANCEL: {
        this.removeContainer(call);
        break;
      }

      case CallStatus.HANGUP: {
        audio.play(null, 'call/' + callModel.status);
        if (callModel.isConferenceCall) {
          const conferenceId = callModel?.conferenceId;
          if (isTruthy(conferenceId) && isThisTabEventInitiator) {
            this.stateService.goToUrl(`${this.isElectronApp ? '#' : location.origin}/conference/${conferenceId}`);
          }
        }
        this.removeContainer(call);
        return;
      }
    }

    this.isThisTabEventInitiator$.next(false);

    if (!!position) {
      this.overlayService.addContainer({
        id: call.callId,
        type: OverlayEntryType.CALL,
        data: call,
        extended: !!anotherActiveCallId ? { anotherActiveCallId } : null,
        position,
        modal,
        zIndex: 1000
      });
    }
  }
}
