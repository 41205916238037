import { Inject, Injectable, Type } from '@angular/core';
import { NgxdResolver } from '@ngxd/core';

import { USERLISTTYPES_PROVIDER, UserListTypesComponentProvider } from './user-list-types-component.provider';

@Injectable()
export class UserListTypesComponentResolver extends NgxdResolver<string, Type<any>> {
  constructor(@Inject(USERLISTTYPES_PROVIDER) private providers: UserListTypesComponentProvider[]) {
    super(providers);
  }
}
