import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { Message } from '../../models';

interface State extends EntityState<Message> {}
const adapter: EntityAdapter<Message> = createEntityAdapter<Message>({
  selectId: (message: Message) => {
    return message.id;
  },
  sortComparer: (b, a) => {
    const deltaTime = b.sentDate.getTime() - a.sentDate.getTime();
    return deltaTime === 0 ? (b.id > a.id ? 1 : -1) : deltaTime;
  }
});

const initialState: State = adapter.getInitialState({});

export { State, adapter, initialState };
