import { Exclude, Expose, Transform, Type } from 'class-transformer';
import { CHAT_ID, MESSAGE_ID } from '../types';
import { Chat } from './chat';
import { ChatParticipant } from '@breez/modules/chat';
import { ProcessingFileModel } from '@breez/models/shared/files/processing-file.model';
import { Participant } from '@breez/models/shared/participant/participant.model';
import { USER_ID } from '@breez/modules/users/types/user-id.type';

//TODO MESSAGE meta.forward вынести в отдельный клас/интерфейс

export class Message {
  @Expose({ name: 'messageid' })
  id: MESSAGE_ID;

  @Expose({ name: 'createdon' })
  @Transform(
    ({ value }) => {
      if (!value) {
        return new Date();
      }
      if (value instanceof Date) {
        return value;
      }
      if (typeof value === 'number') {
        return new Date(value * 1000);
      }
      return new Date(value.replace(/ /g, 'T') + 'Z');
    },
    { toClassOnly: true }
  )
  sentDate: Date = new Date();

  @Expose({ name: 'text' })
  body: string;

  @Expose({ name: 'userid' })
  userId: number;

  /**
   * @deprecated Use sender.user.name instead
   */
  @Expose({ name: 'name' })
  userName: string;

  @Expose({ name: 'chatid' })
  chatId: CHAT_ID;

  chat: Chat;

  @Expose({ name: 'replyid' })
  replyMessageId: MESSAGE_ID;

  @Expose({ name: 'modifiedon' })
  @Transform(
    ({ value }) => {
      return value ? new Date(value * 1000) : null;
    },
    { toClassOnly: true }
  )
  modifiedOn: Date;

  @Expose()
  @Type(() => {
    return ProcessingFileModel;
  })
  @Transform(
    ({ value }) => {
      return value.map(file => {
        return file.fileId;
      });
    },
    { toPlainOnly: true }
  )
  attachments: ProcessingFileModel[];

  @Expose({ name: 'delivereduserids' })
  deliveredUserIds: USER_ID[] = [];

  @Expose({ name: 'readuserids' })
  readUserIds: USER_ID[] = [];

  @Expose({ name: 'forwardids' })
  forwardIds: number[];

  @Expose({ name: 'forwardto' })
  forwardTo: number;

  meta: {
    forward?: {
      chatid: CHAT_ID;
      created: number;
      messageid: MESSAGE_ID;
      modifiedon: number;
      userid: USER_ID;
    };
    reaction?: any;
    var?: { [key: string]: string };
  };

  isScrollTarget: boolean;
  isFirstIncomingUnread: boolean;

  replyMessage: Message;

  participant?: Participant;
  sender?: ChatParticipant;
  originalSender?: ChatParticipant;
  index: number;

  @Exclude({ toPlainOnly: true })
  isQuestionToSpeaker: boolean;

  constructor(base?: Partial<Message>) {
    this.index = undefined;
    this.isScrollTarget = undefined;
    this.isFirstIncomingUnread = undefined;
    return Object.assign(this, base);
  }
}
