import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { combineLatest, combineLatestWith, merge, Observable, of, ReplaySubject } from 'rxjs';
import {
  catchError,
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  map,
  share,
  skip,
  startWith,
  switchMap,
  take
} from 'rxjs/operators';
import { UserMediaSource } from '@breez/models/webrtc/media-source.model';
import { MediaSourceKind } from '@breez/models/webrtc/media-source-kind.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { MediaDevicesService } from '@breez/modules/webrtc/services/media-devices.service';
import { AppService } from '@breez/app.service';
import { distinctUntilChangedByJsonCompare, replayWhileSubs } from '@breez/shared/rxjs-operators';
import { fromControl } from '@breez/shared/rxjs-operators/from-control';
import { DeviceSourceType } from '@breez/models/webrtc/device-source-type.enum';
import { serialExpand } from '@breez/shared/rxjs-operators/serialExpand';
import { mediaSourceHelper } from '@breez/modules/webrtc/helpers/media-source.helper';
import { mediaSourceKindHelper } from '@breez/modules/webrtc/helpers/media-source-kind.helper';
import { MediaEffectsService } from '@breez/modules/webrtc/services/media-effects.service';
import { EmitOnChange } from '@breez/shared/utilities/decorators/emit-on-change.decorator';

interface MediaSourceProps {
  sourceId?: string;
  sourceKind?: MediaSourceKind;
  sourceType?: DeviceSourceType;
  defaultIfEmpty?: boolean;
}

@UntilDestroy()
@Component({
  selector: 'vks-media-sources-selector',
  templateUrl: './media-sources-selector-form.component.html',
  styleUrls: ['./media-sources-selector-form.component.scss', './media-sources-selector-form-max975.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaSourcesSelectorFormComponent implements OnInit, OnDestroy, OnChanges {
  protected readonly MediaSourceKind = MediaSourceKind;

  isMobile = this.appService.isMobile;
  isSafari = this.appService.isSafari;
  isMac = this.appService.isMac;
  isFireFox = this.appService.isFireFox;
  isModal = true;

  @Input() isSettingInConference = false;
  @Input() currentMedia: { audio: boolean; video: boolean; volume: boolean };

  @Input() effectsSdkSetting: { blur: boolean };

  @Input()
  @HostBinding('class.for-conference-enter')
  forConferenceEnter: boolean = false;

  @Output()
  resultSources: EventEmitter<UserMediaSource[]> = new EventEmitter<UserMediaSource[]>();

  @Output()
  effectsSdkSettingEmit: EventEmitter<{ blur: boolean }> = new EventEmitter<{ blur: boolean }>();

  @HostBinding('class.no-modal')
  get noModal(): boolean {
    return !this.isModal;
  }

  form = new FormGroup({
    videoInput: new FormControl<UserMediaSource>({
      ...mediaSourceHelper.empty,
      enabled: true
    }),
    audioInput: new FormControl<UserMediaSource>({
      ...mediaSourceHelper.empty,
      enabled: true
    }),
    audioOutput: new FormControl<UserMediaSource>({
      ...mediaSourceHelper.empty,
      enabled: true
    })
  });

  availableSourcesWarnings$: Observable<{ warning: Error; kind: MediaSourceKind }[]> =
    this.mediaDevicesService.availableSources$.pipe(
      map(sources => {
        return sources
          .map(source => {
            return isTruthy(source.warning) ? { warning: source.warning, kind: source.kind } : undefined;
          })
          .filter(isTruthy);
      }),
      startWith([]),
      distinctUntilChangedByJsonCompare(),
      replayWhileSubs()
    );

  videoInputWarning$: Observable<boolean> = this.availableSourcesWarnings$.pipe(
    map(warnings => {
      return isTruthy(
        warnings.find(warning => {
          return warning.kind === MediaSourceKind.VIDEO_INPUT;
        })
      );
    }),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  audioInputWarning$: Observable<boolean> = this.availableSourcesWarnings$.pipe(
    map(warnings => {
      return isTruthy(
        warnings.find(warning => {
          return warning.kind === MediaSourceKind.AUDIO_INPUT;
        })
      );
    }),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  audioOutputWarning$: Observable<boolean> = this.availableSourcesWarnings$.pipe(
    map(warnings => {
      return isTruthy(
        warnings.find(warning => {
          return warning.kind === MediaSourceKind.AUDIO_OUTPUT;
        })
      );
    }),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  videoInputSources$: Observable<UserMediaSource[]> = this.mediaDevicesService.availableSources$.pipe(
    map(sources => {
      return this.getMediaSources(sources, {
        sourceKind: MediaSourceKind.VIDEO_INPUT,
        sourceType: this.isMobile ? null : DeviceSourceType.HARDWARE
      });
    }),
    map(sources => {
      return isTruthy(this.currentMedia?.video)
        ? sources.map(source => {
            return source.isDefault ? { ...source, enabled: this.currentMedia?.video } : source;
          })
        : sources;
    }),
    distinctUntilChangedByJsonCompare(),
    replayWhileSubs()
  );

  videoInputPermission$: Observable<PermissionState> = mediaSourceKindHelper(MediaSourceKind.VIDEO_INPUT)
    .observePermission()
    .pipe(
      map(sourcePermission => {
        return this.isSafari || this.isMac || this.isFireFox ? 'granted' : sourcePermission;
      }),
      distinctUntilChanged(),
      replayWhileSubs()
    );

  audioInputSources$: Observable<UserMediaSource[]> = this.mediaDevicesService.availableSources$.pipe(
    map(sources => {
      return this.getMediaSources(sources, {
        sourceKind: MediaSourceKind.AUDIO_INPUT,
        sourceType: this.isMobile ? null : DeviceSourceType.HARDWARE
      });
    }),
    map(sources => {
      return isTruthy(this.currentMedia?.audio)
        ? sources.map(source => {
            return source.isDefault ? { ...source, enabled: this.currentMedia?.audio } : source;
          })
        : sources;
    }),
    distinctUntilChangedByJsonCompare(),
    replayWhileSubs()
  );

  readonly hasLicence$: Observable<boolean> = this.mediaEffectsService.hasLicence$;

  audioInputPermission$: Observable<PermissionState> = mediaSourceKindHelper(MediaSourceKind.AUDIO_INPUT)
    .observePermission()
    .pipe(
      map(sourcePermission => {
        return this.isSafari || this.isMac || this.isFireFox ? 'granted' : sourcePermission;
      }),
      distinctUntilChanged(),
      replayWhileSubs()
    );

  audioOutputSources$: Observable<UserMediaSource[]> = this.mediaDevicesService.availableSources$.pipe(
    map(sources => {
      return this.getMediaSources(sources, {
        sourceKind: MediaSourceKind.AUDIO_OUTPUT,
        sourceType: this.isMobile ? null : DeviceSourceType.HARDWARE
      });
    }),
    map(sources => {
      return isTruthy(this.currentMedia?.volume)
        ? sources.map(source => {
            return source.isDefault ? { ...source, enabled: this.currentMedia?.volume } : source;
          })
        : sources;
    }),
    distinctUntilChangedByJsonCompare(),
    replayWhileSubs()
  );

  audioOutputPermission$: Observable<PermissionState> = mediaSourceKindHelper(MediaSourceKind.AUDIO_OUTPUT)
    .observePermission()
    .pipe(
      map(() => {
        return 'granted' as PermissionState;
      }),
      startWith('granted' as PermissionState),
      catchError(() => {
        return of('granted' as PermissionState);
      }),
      distinctUntilChanged(),
      replayWhileSubs()
    );

  // @ts-ignore
  sourcesPermissions$: Observable<PermissionState | 'locked'> = this.videoInputPermission$.pipe(
    combineLatestWith(this.audioInputPermission$),
    map(permissionStates => {
      if (
        permissionStates.every(state => {
          return state === 'prompt';
        })
      ) {
        return 'prompt' as PermissionState;
      }
      if (
        permissionStates.every(state => {
          return state === 'denied';
        })
      ) {
        return 'denied' as PermissionState;
      }

      return 'granted' as PermissionState;
    }),
    combineLatestWith(this.availableSourcesWarnings$),
    map(([permissionState, warnings]) => {
      return permissionState !== 'denied' && warnings.length > 0 ? ('locked' as 'locked') : permissionState;
    }),
    map(sourcePermission => {
      return this.isSafari || this.isMac || this.isFireFox ? 'granted' : sourcePermission;
    }),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  mediaSources$: Observable<UserMediaSource[]> = fromControl(this.form, 100).pipe(
    switchMap(() => {
      let { videoInput, audioInput, audioOutput } = this.form.value;

      if (!videoInput?.enabled) {
        videoInput = mediaSourceHelper.empty;
      }
      if (!audioInput?.enabled) {
        audioInput = mediaSourceHelper.empty;
      }
      if (!audioOutput?.enabled) {
        audioOutput = mediaSourceHelper.empty;
      }

      return this.getUserMediaSources$({ videoInput, audioInput, audioOutput });
    }),
    distinctUntilChangedByJsonCompare(),
    replayWhileSubs()
  );

  isGlobalBlurEnable$: Observable<boolean> = this.mediaDevicesService.isBlurGlobalEnabled$.pipe(
    distinctUntilChanged(),
    replayWhileSubs()
  );

  @EmitOnChange<{ blur: boolean }, boolean>('effectsSdkSetting', {
    emitter: (effectsSdkSetting, subject) => {
      if (isTruthy(effectsSdkSetting?.blur)) {
        subject.next(effectsSdkSetting?.blur);
      }
    }
  })
  localBlurEnable$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  isLocalBlurEnable$: Observable<boolean> = this.localBlurEnable$.pipe(distinctUntilChanged(), replayWhileSubs());

  isBlurEnable$: Observable<boolean> = merge(
    this.isGlobalBlurEnable$,
    this.isLocalBlurEnable$.pipe(debounceTime(100))
  ).pipe(distinctUntilChanged(), replayWhileSubs());

  effectsLoading$: Observable<boolean> = combineLatest([this.mediaEffectsService.loading$, this.isBlurEnable$]).pipe(
    map(([loading, isBlurEnable]) => {
      return !!loading && !!isBlurEnable;
    }),
    switchMap(value => {
      return !!value ? of(value) : of(value).pipe(delay(750));
    }),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  mediaStream$: Observable<MediaStream> = serialExpand(
    this.mediaSources$.pipe(untilDestroyed(this)),
    (mediaStream, sources) => {
      return this.mediaDevicesService.makeMediaStream(mediaStream, sources);
    },
    new MediaStream()
  ).pipe(
    skip(1),
    switchMap(mediaStream => {
      return this.isBlurEnable$.pipe(
        switchMap(isBlurEnable => {
          return isBlurEnable ? this.mediaEffectsService.getStream$(mediaStream) : of(mediaStream);
        })
      );
    }),
    share(),
    untilDestroyed(this)
  );

  boxedMediaStream$: Observable<{ stream: MediaStream }> = this.mediaStream$.pipe(
    map(stream => {
      return { stream };
    }),
    replayWhileSubs()
  );

  constructor(
    private appService: AppService,
    private mediaDevicesService: MediaDevicesService,
    private mediaEffectsService: MediaEffectsService,
    @Optional() private dialogRef: MatDialogRef<MediaSourcesSelectorFormComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogInputData: any
  ) {}

  setBlurEnableValue(value: boolean): void {
    this.localBlurEnable$.next(value);
  }

  ngOnInit(): void {
    if (!isTruthy(this.dialogInputData)) {
      this.isModal = false;
    }

    this.hasLicence$
      .pipe(
        skip(1),
        take(1),
        filter(hasLicence => {
          return !hasLicence;
        }),
        untilDestroyed(this)
      )
      .subscribe(hasLicence => {
        return this.localBlurEnable$.next(hasLicence);
      });

    this.videoInputPermission$.pipe(untilDestroyed(this)).subscribe(state => {
      return this.changeToggleAvailability(this.form.controls.videoInput, state);
    });
    this.audioInputPermission$.pipe(untilDestroyed(this)).subscribe(state => {
      return this.changeToggleAvailability(this.form.controls.audioInput, state);
    });
    this.audioOutputPermission$.pipe(untilDestroyed(this)).subscribe(state => {
      return this.changeToggleAvailability(this.form.controls.audioOutput, state);
    });

    this.videoInputSources$.pipe(untilDestroyed(this)).subscribe(sources => {
      return this.updateForm(sources, MediaSourceKind.VIDEO_INPUT, this.form.controls.videoInput);
    });

    this.audioInputSources$.pipe(untilDestroyed(this)).subscribe(sources => {
      return this.updateForm(sources, MediaSourceKind.AUDIO_INPUT, this.form.controls.audioInput);
    });

    this.audioOutputSources$.pipe(untilDestroyed(this)).subscribe(sources => {
      return this.updateForm(sources, MediaSourceKind.AUDIO_OUTPUT, this.form.controls.audioOutput);
    });

    // valueChange
    fromControl(this.form, 100)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        return this.submit();
      });

    this.isBlurEnable$.pipe(skip(1), untilDestroyed(this)).subscribe(() => {
      return this.submit();
    });
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnChanges(_: SimpleChanges): void {}

  submit(close = false): void {
    const sources = [this.form.value.videoInput, this.form.value.audioInput, this.form.value.audioOutput].filter(
      isTruthy
    );
    if (!this.isModal || this.isSettingInConference) {
      this.resultSources.emit(sources);
      this.isBlurEnable$.pipe(take(1)).subscribe(isBlurEnable => {
        return this.effectsSdkSettingEmit.emit({ blur: isBlurEnable });
      });

      return;
    }

    if (close && isTruthy(this.dialogRef)) {
      // TODO #alekssakovsky возможно, блок не используется BREEZ-794: проверить блок кода в MediaSourcesSelectorFormComponent
      this.dialogRef.close({ sources });
    }
  }

  getUserMediaSources$(props: {
    videoInput: UserMediaSource;
    audioInput: UserMediaSource;
    audioOutput?: UserMediaSource;
    isDefault?: boolean;
  }): Observable<UserMediaSource[]> {
    return this.mediaDevicesService.availableSources$.pipe(
      map(sources => {
        return [
          // videoInput
          this.getMediaSource(sources, {
            sourceId: props.videoInput?.id,
            sourceKind: MediaSourceKind.VIDEO_INPUT,
            sourceType: DeviceSourceType.HARDWARE,
            defaultIfEmpty: !props.hasOwnProperty('isDefault') || props.isDefault
          }),
          // audioInput
          this.getMediaSource(sources, {
            sourceId: props.audioInput?.id,
            sourceKind: MediaSourceKind.AUDIO_INPUT,
            sourceType: DeviceSourceType.HARDWARE,
            defaultIfEmpty: !props.hasOwnProperty('isDefault') || props.isDefault
          }),
          // audioOutput
          this.getMediaSource(sources, {
            sourceId: props.audioOutput?.id,
            sourceKind: MediaSourceKind.AUDIO_OUTPUT,
            sourceType: DeviceSourceType.HARDWARE,
            defaultIfEmpty: !props.hasOwnProperty('isDefault') || props.isDefault
          })
        ].filter(isTruthy);
      }),
      take(1)
    );
  }

  cancel(): void {
    if (isTruthy(this.dialogRef)) {
      this.dialogRef.close(null);
    }
  }

  ngOnDestroy(): void {
    this.boxedMediaStream$.subscribe(mediaStream => {
      if (!!mediaStream?.stream) {
        this.mediaDevicesService.stopTracks({ mediaStream: mediaStream?.stream });
      }
    });
    this.mediaEffectsService.stop();
    this.submit(this.isModal);
  }

  getMediaSources(sources: UserMediaSource[] = [], props: MediaSourceProps = {}): UserMediaSource[] {
    if (!!props?.sourceId) {
      return sources
        .filter(item => {
          return item.id === props.sourceId;
        })
        .filter(item => {
          return item.kind === props.sourceKind;
        });
    }

    let items: UserMediaSource[] = sources;

    if (!!props.sourceKind) {
      items = sources.filter(item => {
        return item.kind === props.sourceKind;
      });
    }

    if (!!props.defaultIfEmpty) {
      items = items.filter(item => {
        return item.isDefault;
      });
    }

    if (!!props.sourceType) {
      items = items.filter(item => {
        return item.sourceType === props.sourceType;
      });
    }

    return items;
  }

  getMediaSource(sources: UserMediaSource[], props: MediaSourceProps = {}): UserMediaSource | null {
    return this.getMediaSources(sources, props).find(isTruthy);
  }

  changeToggleAvailability(control: FormControl<UserMediaSource>, state: PermissionState): void {
    const prevValue = control.getRawValue();
    const nextValue = state !== 'denied';

    if (nextValue) {
      if (!control.enabled) {
        control.enable();
      }
    } else {
      if (!control.disabled) {
        control.disable();
      }
    }
    if (!nextValue) {
      control.patchValue({
        ...prevValue,
        enabled: false
      });
    }
  }

  updateForm(sources: UserMediaSource[], sourceKind: MediaSourceKind, control: FormControl<UserMediaSource>): void {
    const mediaSource = this.getMediaSource(sources, { sourceKind, defaultIfEmpty: true });
    if (isTruthy(mediaSource) && mediaSource.id !== control.value?.id) {
      control.patchValue({
        ...mediaSource,
        enabled: !!control.value.id ? mediaSource.enabled : control.value.enabled
      });
    }
  }
}
