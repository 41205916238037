import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { Observable } from 'rxjs';
import { UserSettingsPopupComponent } from '@breez/modules/settings/components/user-settings-popup';
import { ElectronService } from '@breez/modules/core/services';

@Injectable({
  providedIn: 'root'
})
export class SettingsDialogService {
  isElectronApp: boolean = this.electronService.isElectron;
  constructor(
    private readonly dialog: MatDialog,
    private readonly overlay: Overlay,
    private readonly electronService: ElectronService
  ) {}

  displayUserSettings(): Observable<any> {
    return this.dialog
      .open(UserSettingsPopupComponent, {
        scrollStrategy: this.overlay.scrollStrategies.reposition(),
        width: this.isElectronApp ? '800px' : '600px',
        maxWidth: '98vw',
        height: 'fit-content',
        maxHeight: '100vh',
        autoFocus: false,
        panelClass: 'settings-popup'
      })
      .afterClosed();
  }
}
