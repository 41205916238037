import { Injectable } from '@angular/core';
import { WebsocketEvents, WebsocketService } from '@breez/modules/websocket';
import { toClass } from '@breez/shared/rxjs-operators';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ParticipationIntervalsInfoRequest,
  ParticipationIntervalsInfoResponse
} from '@breez/models/shared/participation-intervals-info';

@Injectable({
  providedIn: 'root'
})
export class ObjectService {
  constructor(private wsService: WebsocketService) {}

  getParticipationIntervalsInfo(
    data: ParticipationIntervalsInfoRequest
  ): Observable<ParticipationIntervalsInfoResponse> {
    return this.wsService.query(WebsocketEvents.SEND.OBJECT.PARTICIPATION_INTERVALS_INFO, { data }).pipe(
      toClass(ParticipationIntervalsInfoResponse),
      map(response => {
        return { duration: data.duration, ...response } as ParticipationIntervalsInfoResponse;
      })
    );
  }
}
