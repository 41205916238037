import { Expose, Type } from 'class-transformer';

export class ObjectListResponse<T> {
  @Expose({ name: 'totalcount' })
  totalCount: number;

  @Expose()
  @Type(() => {
    return Object;
  })
  protected data?: T[];
}
