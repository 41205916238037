import { ChangeDetectionStrategy, Component, DoCheck, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { combineLatest, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'vks-control-date-time',
  templateUrl: './control-date-time.component.html',
  styleUrls: ['./control-date-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => {
        return ControlDateTimeComponent;
      }),
      multi: true
    }
  ]
})
export class ControlDateTimeComponent implements OnInit, OnDestroy, ControlValueAccessor, DoCheck {
  @Input() disable: boolean;

  changedTrigger: (value: any) => void;
  touchedTrigger: () => void;

  dateControl = new UntypedFormControl(null);
  timeControl = new UntypedFormControl(null);

  summaryDate$: Observable<Date> = combineLatest([
    this.dateControl.valueChanges.pipe(startWith(null)),
    this.timeControl.valueChanges.pipe(startWith(null))
  ]).pipe(
    map(([datePart, timePart]) => {
      if (!(datePart && timePart && datePart instanceof Date && timePart instanceof Date)) {
        return null;
      }

      const summaryDate = new Date(0);
      summaryDate.setFullYear((<Date>datePart).getFullYear());
      summaryDate.setMonth((<Date>datePart).getMonth());
      summaryDate.setDate((<Date>datePart).getDate());

      summaryDate.setHours((<Date>timePart).getHours());
      summaryDate.setMinutes((<Date>timePart).getMinutes());

      return summaryDate;
    })
  );

  ngOnInit(): void {
    this.summaryDate$.pipe(untilDestroyed(this)).subscribe(value => {
      if (this.changedTrigger && typeof this.changedTrigger === 'function') {
        this.changedTrigger(value);
      }
      if (
        this.touchedTrigger &&
        typeof this.touchedTrigger === 'function' &&
        this.dateControl.touched &&
        this.timeControl.touched
      ) {
        this.touchedTrigger();
      }
    });
  }

  writeValue(date: Date): void {
    if (date && date instanceof Date) {
      this.dateControl.patchValue(date);
      this.timeControl.patchValue(date);
    }
  }

  registerOnChange(fn: (value: any) => void): void {
    if (fn && typeof fn === 'function') {
      this.changedTrigger = fn;
    }
  }

  registerOnTouched(fn: () => void): void {
    if (fn && typeof fn === 'function') {
      this.touchedTrigger = fn;
    }
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy(): void {}

  ngDoCheck(): void {
    if ((this.timeControl.touched || this.dateControl.touched) && typeof this.touchedTrigger === 'function') {
      this.touchedTrigger();
    }
  }
}
