import { createAction, props } from '@ngrx/store';
import { PropertiesActionObjectId, propsProcessing } from '@breez/helpers/+state/execution-action.helper';
import { ErrorMessageModel } from '@breez/models/+state';
import { ChatLLoadUnreadMessagesAction } from '@breez/modules/chat/interfaces/+state/chatL-load-unread-messages-action';
import { CHAT_ID, MESSAGE_ID } from '@breez/modules/chat';

export const loadUnreadMessages = createAction(
  '[Chat/API] Get count unread messages',
  propsProcessing<ChatLLoadUnreadMessagesAction & Partial<PropertiesActionObjectId>>
);

export const loadUnreadMessagesSuccess = createAction(
  '[Chat/API] Get count unread messages Success',
  (params: ChatLLoadUnreadMessagesAction) => {
    return propsProcessing<ChatLLoadUnreadMessagesAction>(params, loadUnreadMessages, 'result');
  }
);

export const loadUnreadMessagesFailure = createAction(
  '[Chat/API] Get count unread messages Failure',
  (params: ErrorMessageModel & ChatLLoadUnreadMessagesAction) => {
    return propsProcessing<ErrorMessageModel & ChatLLoadUnreadMessagesAction>(params, loadUnreadMessages);
  }
);

export const updateUnreadMessagesCount = createAction(
  '[Chat] Update unread messages model',
  props<{ chatId: CHAT_ID; unreadIds?: MESSAGE_ID[]; readIds?: MESSAGE_ID[]; baseCount?: number }>()
);
