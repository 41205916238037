import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmPopupData } from '@breez/shared/models/confrim-popup-data.model';

@Component({
  selector: 'vks-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmPopupComponent implements OnInit {
  question: string;
  action: string = 'CONFIRM';

  constructor(@Inject(MAT_DIALOG_DATA) public readonly data: ConfirmPopupData | string) {}

  ngOnInit(): void {
    if (typeof this.data === 'string') {
      this.question = this.data;
    } else {
      this.question = this.data.question;
      this.action = this.data.action;
    }
  }
}
