import { Action, createReducer, on } from '@ngrx/store';
import * as ExecutionState from './execution.state';
import * as ExecutionActions from './execution.actions';
import { ExecutionResultHelper } from '@breez/helpers';
import { EMPTY_ID } from '@breez/models';
import { ExecutionModel } from '@breez/models/+state';

const reducer = createReducer(
  ExecutionState.initialState,
  on(ExecutionActions.startProcessing, (state, { objectId, action }) => {
    return ExecutionResultHelper.generateExecution(state, objectId ?? EMPTY_ID, action);
  }),
  on(ExecutionActions.stopProcessing, (state, { objectId, action }) => {
    const { sourceAction, resultProp } = action as any;
    return ExecutionResultHelper.generateExecution(state, objectId ?? EMPTY_ID, sourceAction, action, resultProp);
  })
);
export function executionReducer(state: ExecutionModel | undefined, action: Action): ExecutionModel {
  return reducer(state, action);
}
