<vks-modal-header *ngIf="title" [title]="title" (close)="cancel()"></vks-modal-header>
<h3 *ngIf="!title" class="no-user-select">{{ 'ASSIGN_RIGHTS' | translate }}</h3>
<mat-dialog-content>
  <div class="control-row">
    <div
      *ngFor="let right of availableRights"
      vks-control-group
      [class.enabled]="hasRight(right)"
      class="inline-controls"
    >
      <mat-slide-toggle
        [checked]="hasRight(right)"
        (change)="toggleRight(right, $event.checked)"
        class="no-user-select"
      >
        {{ right.title | translate }}
      </mat-slide-toggle>
      <vks-icon [matTooltip]="right.tooltip | translate" [style.fill]="'#0F1114'" icon="help"></vks-icon>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    vks-button
    [label]="'SAVE' | translate"
    (buttonClick)="resolve()"
    buttonType="submit"
    class="btn-primary"
  ></button>
  <button vks-button [label]="'CANCEL' | translate" (buttonClick)="cancel()" class="btn-anchor"></button>
</mat-dialog-actions>
