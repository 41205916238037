<ng-container [formGroup]="form">
  <ng-container *ngIf="showToggle">
    <vks-icon *ngIf="icon" [icon]="icon" color="#0F1114" class="square16"> </vks-icon>
    <mat-slide-toggle
      class="blue"
      [matTooltip]="toggleTooltip | translate"
      matTooltipClass="shortcut"
      formControlName="enabled"
    >
    </mat-slide-toggle>
  </ng-container>
  <ng-container *ngIf="hasPermission; else noPermission">
    <mat-select
      *ngIf="sources?.length > 1; else noSources"
      #sourceSelector
      [class.warning]="hasSourcesWarning || warning"
      formControlName="sourceId"
      (focusin)="onFocusIn($event)"
      (focusout)="onFocusOut($event)"
    >
      <mat-option *ngFor="let source of sources; trackBy: trackSourceById" [value]="source.id">
        <ng-container *ngIf="!!source.title; else noSourceTitle"> {{ source.title }} </ng-container>
      </mat-option>
    </mat-select>
  </ng-container>
  <ng-template #noSourceTitle> {{ noSourceTitleMessage | translate }} </ng-template>
  <ng-template #noSources>
    <p class="stub" [translate]="noSourcesMessage"></p>
  </ng-template>
  <ng-template #noPermission>
    <p class="stub">{{ permissionWarningMessage | translate }}</p>
    <!--    <button vks-button-->
    <!--            [label]="'GET_PERMISSION_WARNING' | translate"-->
    <!--            (click)="requestPermission()"-->
    <!--            class="btn-anchor"-->
    <!--            type="button">-->
    <!--    </button>-->
  </ng-template>
</ng-container>
