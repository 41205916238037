import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: '[vks-cinput]',
  templateUrl: './control-input.component.html',
  styleUrls: ['./control-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ControlInputComponent implements OnChanges {
  @Input()
  icon: string;

  @Input()
  enterIsSubmit = false;

  @Output()
  valueSubmit = new EventEmitter<void>();

  @HostBinding('style.backgroundImage')
  backgroundImageStyle: string;

  @HostBinding('class.icon')
  iconClass = false;

  @HostBinding('class.indicate-focus-placeholder')
  indicateFocus = true;

  constructor(private ref: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.icon) {
      if (this.icon) {
        this.backgroundImageStyle = `url(assets/svg/icon-${this.icon}.svg)`;
        this.iconClass = true;
      } else {
        this.backgroundImageStyle = 'unset';
        this.iconClass = false;
      }
    }
  }

  @HostListener('document:keydown', ['$event'])
  enterKeyDown($event): void {
    if ($event?.target !== this.ref.nativeElement) {
      return;
    }

    if (!this.enterIsSubmit) {
      return;
    }

    if ($event.key !== 'Enter') {
      return;
    }

    this.valueSubmit.emit(null);
  }
}
