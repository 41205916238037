import { ChangeDetectionStrategy, Component, Input, Optional } from '@angular/core';
import { MessageListDateStub } from '@breez/modules/chat/models/message-list-date-stub.model';
import { ChatComponent } from '@breez/modules/chat/components/chat';
import { defer, EMPTY, fromEvent, Observable, of } from 'rxjs';
import { delay, distinctUntilChanged, startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'vks-chat-message-date-stub',
  templateUrl: './chat-message-date-stub.component.html',
  styleUrls: ['./chat-message-date-stub.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatMessageDateStubComponent {
  hideDelay = 3000;

  @Input() stub: MessageListDateStub;

  showStub$: Observable<boolean> = defer(() => {
    if (!this.chatComponent) {
      return EMPTY;
    }
    return fromEvent(this.chatComponent.messageListContainer.hostElement.nativeElement, 'scroll');
  }).pipe(
    switchMap(() => {
      return of(true).pipe(delay(this.hideDelay), startWith(false));
    }),
    distinctUntilChanged(),
    startWith(false)
  );

  constructor(@Optional() private chatComponent: ChatComponent) {}
}
