import { Injectable } from '@angular/core';
import { WebsocketEvents, WebsocketService } from '@breez/modules/websocket';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, filter, map, mapTo, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { Contact, ContactListResponse, UserGroup, UserGroupType } from '@breez/modules/social/models';
import { arrToClass, toClass } from '@breez/shared/rxjs-operators';
import { ObjectListRequest } from '@breez/models/shared/paging';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { ObjectType } from '@breez/shared/enums/object-type.enum';
import { uniqueArrayOfObjects } from '@breez/shared/utilities/uniqueArrayOfObjects';

@Injectable({
  providedIn: 'root'
})
export class SocialService {
  liveContactsByGroupId: { contacts: ReplaySubject<Contact[]>; groupId: string | number }[];

  constructor(private websocketService: WebsocketService) {
    this.liveContactsByGroupId = [];
  }

  addInContacts(_: number, targetUserId: number): Observable<boolean> {
    return this.websocketService.send<any>(WebsocketEvents.SEND.CONTACT.ADD, { data: targetUserId }).pipe(
      mapTo(true),
      catchError(() => {
        return of(false);
      })
    );
  }

  getContact(userId: number): Observable<Contact> {
    return this.websocketService
      .query<Contact>(WebsocketEvents.USER.GET, {
        data: {
          userid: userId
        }
      })
      .pipe(take(1), toClass(Contact));
  }

  getliveContactsData(groupId: number): any {
    return this.liveContactsByGroupId.find(contactsById => {
      return contactsById.groupId === groupId;
    });
  }

  //TODO refactor
  getliveContacts(groupId: number): Observable<Contact[]> {
    const liveContacts = this.getliveContactsData(groupId);

    if (!liveContacts) {
      this.liveContactsByGroupId.push({ groupId: groupId, contacts: new ReplaySubject<Contact[]>(1) });

      const liveContacts = this.getliveContactsData(groupId);
      liveContacts.contacts.next([]);
    }
    return this.getliveContactsData(groupId)?.contacts;
  }

  addToUserGroup(contacts: Contact[], group: UserGroup): Observable<boolean> {
    const groupType = group.type === UserGroupType.CUSTOM ? undefined : group.type;

    return this.websocketService
      .send(WebsocketEvents.SEND.CONTACTS.ADD, {
        data: {
          contactids: contacts.map(contact => {
            return contact.id;
          }),
          groupid: group.id,
          grouptype: groupType
        }
      })
      .pipe(
        mapTo(true),
        catchError(() => {
          return of(false);
        })
      )
      .pipe(
        withLatestFrom(this.getliveContacts(group.id)),
        map(([result, liveEditContacts]) => {
          const ghosted = contacts.map(contact => {
            return <Contact>{ ...contact, ...{ ghost: false } };
          });
          const liveContacts = [...liveEditContacts, ...ghosted];
          this.getliveContactsData(group.id).contacts.next(uniqueArrayOfObjects<Contact>(liveContacts, 'id'));
          return result;
        })
      );
  }

  //TODO refactor
  removeFromUserGroup(contacts: Contact[], group: UserGroup): Observable<boolean> {
    const groupType = group.type === UserGroupType.CUSTOM ? undefined : group.type;

    return this.websocketService
      .send(WebsocketEvents.SEND.CONTACTS.DELETE, {
        data: {
          contactids: contacts.map(contact => {
            return contact.id;
          }),
          groupid: group.id,
          grouptype: groupType
        }
      })
      .pipe(
        mapTo(true),
        catchError(() => {
          return of(false);
        })
      )
      .pipe(
        withLatestFrom(this.getliveContacts(group.id)),
        map(([result, liveEditContacts]) => {
          const ghosted = contacts.map(contact => {
            return <Contact>{ ...contact, ...{ ghost: true } };
          });
          const liveContacts = [...liveEditContacts, ...ghosted];
          this.getliveContactsData(group.id).contacts.next(uniqueArrayOfObjects<Contact>(liveContacts, 'id'));
          return result;
        })
      );
  }

  deleteFromContacts(_: number, targetUserId: number): Observable<boolean> {
    return this.websocketService.send<any>(WebsocketEvents.SEND.CONTACT.DELETE, { data: targetUserId }).pipe(
      mapTo(true),
      catchError(() => {
        return of(false);
      })
    );
  }

  getContacts(request: ObjectListRequest): Observable<ContactListResponse> {
    const filterObject = { ...request.filter } as Record<string, unknown>;
    const group = filterObject.group as UserGroup;
    if (!isTruthy(group)) {
      return of({ totalCount: 0, data: [] } as ContactListResponse);
    }

    if (isTruthy(group.id)) {
      filterObject.groupid = group.id;
    }
    if (group.type !== UserGroupType.CUSTOM) {
      filterObject.grouptype = group.type;
    }

    if (isTruthy(group.id) || group.type === UserGroupType.CUSTOM) {
      request = { ...request, withinvitedusers: true };
    }

    delete filterObject.group;
    return combineLatest([
      this.getliveContacts(group.id),
      this.websocketService
        .send(WebsocketEvents.SEND.CONTACTS.GET, { data: { ...request, filter: filterObject } })
        .pipe(toClass(ContactListResponse))
    ]).pipe(
      map(([liveContacts, response]) => {
        return { ...response, ...{ liveContacts } };
      })
    );
  }

  getUserGroup(userId?: number): Observable<UserGroup[]> {
    return this.websocketService
      .send(WebsocketEvents.SEND.CONTACTS.GET_USER_GROUPS, {
        data: {
          userid: userId
        }
      })
      .pipe(arrToClass(UserGroup), take(1));
  }

  contactsEvents(userId: number): Observable<Contact[]> {
    return this.websocketService
      .send(WebsocketEvents.SEND.CONTACTS.OBSERVE, {
        parent: {
          resource: ObjectType.USER,
          id: userId
        }
      })
      .pipe(
        take(1),
        switchMap(() => {
          return this.websocketService.listen<any[]>({ path: WebsocketEvents.RECEIVE.CONTACTS.OBSERVE });
        }),
        map(({ data }) => {
          return data;
        }),
        arrToClass(Contact),
        filter(data => {
          return Array.isArray(data);
        })
      );
  }
}
