import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AudioContextService {
  getAudioVolumeIndicator(mediaStream: MediaStream): Observable<number> {
    if (!mediaStream) {
      return of(0);
    }

    if (mediaStream.getAudioTracks().length === 0) {
      return of(0);
    }

    return new Observable<number>(subscriber => {
      const audioContext: AudioContext = new ((<any>window).AudioContext || (<any>window).webkitAudioContext)();
      const mediaStreamSource = audioContext.createMediaStreamSource(mediaStream);
      const meter = this.createAudioMeter(audioContext, volume => {
        return subscriber.next(volume);
      });
      mediaStreamSource.connect(meter);
    });
  }

  private createAudioMeter(audioContext, volumeCallback): ScriptProcessorNode {
    const processor = audioContext.createScriptProcessor(512);
    processor.clipping = false;
    processor.lastClip = 0;
    processor.volume = 0;
    processor.clipLevel = 0.98;
    processor.averaging = 0.95;
    processor.clipLag = 750;
    processor.onaudioprocess = function (event): void {
      const buf = event.inputBuffer.getChannelData(0);
      const rms = Math.sqrt(
        buf.reduce((sum, part) => {
          if (Math.abs(part) >= this.clipLevel) {
            this.clipping = true;
            this.lastClip = window.performance.now();
          }
          return sum + part * part;
        }, 0) / buf.length
      );
      this.volume = Math.max(rms, this.volume * this.averaging);
      volumeCallback(this.volume);
    };

    processor.connect(audioContext.destination);

    processor.checkClipping = function (): any {
      if (!this.clipping) {
        return false;
      }
      if (this.lastClip + this.clipLag < window.performance.now()) {
        this.clipping = false;
      }
      return this.clipping;
    };

    processor.shutdown = function (): void {
      this.disconnect();
      this.onaudioprocess = null;
    };

    return processor;
  }
}
