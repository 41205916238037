import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ProcessingFile } from '@breez/models/shared/files/processing-file.model';
import { ImageSize } from '@breez/models/shared/image-size.model';
import { FileService } from '@breez/modules/file/file.service';
import { ImageService } from '@breez/shared/services/image.service';
import { LanguageService } from '@breez/shared/services/language.service';
import { EmitOnChange } from '@breez/shared/utilities/decorators/emit-on-change.decorator';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import * as breezValidators from '@breez/shared/validators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, merge, Observable, of, Subject } from 'rxjs';
import { filter, map, share, shareReplay, switchMap, take } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'vks-control-file-image-selector',
  templateUrl: './control-file-image-selector.component.html',
  styleUrls: ['./control-file-image-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => {
        return ControlFileImageSelectorComponent;
      }),
      multi: true
    }
  ]
})
export class ControlFileImageSelectorComponent implements OnInit, ControlValueAccessor, OnDestroy, OnChanges {
  @Input() forceImageCrop = false;
  @Input() forceCropRatio: number = null;
  @Input() resizeImageTo: ImageSize = null;

  @Input() formats: string[] = ['JPG', 'PNG'];
  @Input() maxSizeMb = 5;

  valid = true;

  @EmitOnChange('formats')
  formats$ = new BehaviorSubject<string[]>(this.formats);

  changedTrigger: (value: any) => void;
  touchedTrigger: () => void;

  fileDropAreaControl = new UntypedFormControl(null, [
    breezValidators.fileTypeValidator(),
    breezValidators.fileSizeValidator(this.maxSizeMb)
  ]);

  fileFromArea$: Observable<File> = this.fileDropAreaControl.valueChanges.pipe(
    map(files => {
      return files[0];
    })
  );

  processingFileFromArea$: Observable<ProcessingFile> = this.fileFromArea$.pipe(
    switchMap(file => {
      return this.fileService.loadBlobFromFile(file);
    }),
    switchMap(file => {
      if (!isTruthy(file)) {
        return of(null);
      }

      if (!this.forceImageCrop) {
        return of(file);
      }

      return this.fileDropAreaControl.valid
        ? this.imageService.cropImage(file, this.forceCropRatio, this.resizeImageTo).pipe(filter(isTruthy))
        : of(file);
    }),
    share()
  );

  processingFileFromCropper$ = new Subject<ProcessingFile>();

  processingFileModel$ = merge(this.processingFileFromArea$, this.processingFileFromCropper$);

  processingFileFromAbove$ = new Subject<ProcessingFile>();

  currentProcessingFile$: Observable<ProcessingFile> = merge(
    this.processingFileFromAbove$,
    this.processingFileModel$
  ).pipe(shareReplay(1));

  supportedFileExtensions$: Observable<string[]> = this.formats$.pipe(
    map(formats => {
      return formats.map(format => {
        return '.' + format.toLocaleLowerCase();
      });
    })
  );

  supportedFormatsLabel$: Observable<string> = this.formats$.pipe(
    switchMap(formats => {
      return this.languageService.joinByCommasWithAndWord(formats);
    }),
    switchMap(formatsEnumeration => {
      return this.translateService.stream('SUPPORTED_FORMATS_SIZES', {
        formats: formatsEnumeration,
        maxSize: this.maxSizeMb
      });
    })
  );

  constructor(
    private translateService: TranslateService,
    private languageService: LanguageService,
    private imageService: ImageService,
    private fileService: FileService
  ) {}

  ngOnInit(): void {
    this.processingFileModel$.pipe(untilDestroyed(this)).subscribe(file => {
      this.touchedTrigger();
      this.changedTrigger(file);
    });
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnChanges(_: SimpleChanges): void {}

  registerOnTouched(fn: any): void {
    if (fn && typeof fn === 'function') {
      this.touchedTrigger = fn;
    }
  }

  registerOnChange(fn: (value: any) => void): void {
    if (fn && typeof fn === 'function') {
      this.changedTrigger = fn;
    }
  }

  writeValue(file: ProcessingFile): void {
    if (typeof file !== 'string') {
      this.processingFileFromAbove$.next(null);
    }

    this.processingFileFromAbove$.next(file);
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy(): void {}

  clearFile(): void {
    this.fileDropAreaControl.patchValue([]);
  }

  crop(): void {
    this.currentProcessingFile$
      .pipe(
        take(1),
        switchMap(processingFile => {
          return this.imageService.cropImage(processingFile, this.forceCropRatio, this.resizeImageTo);
        }),
        untilDestroyed(this)
      )
      .subscribe(processingFile => {
        if (!processingFile) {
          return;
        }

        this.processingFileFromCropper$.next(processingFile);
      });
  }
}
