import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ChatUnreadMessageActions from '@breez/modules/chat/+state/chatUnreadMessage/chatUnreadMessage.actions';
import { ChatService } from '@breez/modules/chat';
import { Injectable } from '@angular/core';
import { catchError, of, mergeMap } from 'rxjs';

import { map } from 'rxjs/operators';
import { isTruthy } from '@breez/shared/utilities/is-truthy';

@Injectable()
export class ChatUnreadMessageEffects {
  loadUnreadMessages$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatUnreadMessageActions.loadUnreadMessages),
      mergeMap(action => {
        return (
          isTruthy(action.conferenceId)
            ? this.chatService.getConferenceChatsUnreadMessagesCount(action.conferenceId)
            : this.chatService.fetchChatsUnreadMessageCount()
        ).pipe(
          map(result => {
            result = result ?? [];
            return ChatUnreadMessageActions.loadUnreadMessagesSuccess({ result });
          }),
          catchError(errorMessage => {
            return of(
              ChatUnreadMessageActions.loadUnreadMessagesFailure({
                ...action,
                errorMessage
              })
            );
          })
        );
      })
    );
  });

  onUnreadChats$ = createEffect(() => {
    return this.chatService.observeUnreadChats$().pipe(
      map(chatId => {
        return ChatUnreadMessageActions.updateUnreadMessagesCount({ chatId, baseCount: 1 });
      })
    );
  });

  constructor(
    private actions$: Actions,
    private readonly chatService: ChatService
  ) {}
}
