import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'vks-oops-page',
  templateUrl: './oops-page.component.html',
  styleUrls: ['./oops-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OopsPageComponent {
  svgPath = `assets/svg/oops.svg`;

  refreshPage(): void {
    window.location.reload();
  }
}
