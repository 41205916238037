import { ConferenceUserRole } from '@breez/models/conference-user-role.enum';
import { ProcessingFileModel } from '@breez/models/shared/files/processing-file.model';
import { Expose, Transform, Type } from 'class-transformer';
import { ConferenceState } from '@breez/models/conference/enums/conference-state.enum';
import { User } from '@breez/models/user.model';
import { ConferenceRegistrationStatus } from '@breez/models/conference-registration-status.model';
import { ParticipantDTO } from '@breez/models/conference/participant.dto.model';
import { fixDateDeserialization } from '@breez/shared/utilities/fixDateDeserialization';
import { Participant } from '@breez/models/shared/participant/participant.model';
import moment from 'moment';
import * as DateUtils from '@breez/shared/utilities/date-utils';
import { IFilteredEntity } from '@breez/models/shared/filtered-entity.model';
import { generateOrigin } from '@breez/shared/utilities/generate-origin';
import { ConferenceAccessType } from '@breez/models/conference/conference-access-type';
import { ConferenceTypeEnum } from '@breez/modules/conference/models/conference-types.enum';

export class Conference implements IFilteredEntity {
  @Expose({ name: 'conferenceId' })
  id: number;

  @Expose({ name: 'oldconferenceid' })
  oldId: number;

  @Expose({ name: 'plannedendedon' })
  @Transform(({ value }) => {
    return value ? new Date(fixDateDeserialization(value)) : undefined;
  })
  plannedendedon: Date;

  @Expose({ name: 'plannedstartedon' })
  @Transform(({ value }) => {
    return value ? new Date(fixDateDeserialization(value)) : undefined;
  })
  plannedstartedon: Date;

  title: string;

  subtitle: string;

  @Expose({ name: 'description' })
  descriptionText: string;

  @Expose()
  displaydescription: string;

  get isPublic(): boolean {
    return this.accessType === ConferenceAccessType.PUBLIC;
  }

  get isConstant(): boolean {
    return this.conferenceType === ConferenceTypeEnum.CONSTANT;
  }

  get isAnonymous(): boolean {
    return this.accessType === ConferenceAccessType.ANONYMOUS;
  }

  enableChat: boolean;

  @Expose({ name: 'enableqts' })
  enableQTS: boolean;

  @Expose({ name: 'enablesurvey' })
  enableSurveys: boolean;

  alias: string;

  url: string;

  @Expose()
  @Transform(({ value }) => {
    return value ? new Date(fixDateDeserialization(value)) : undefined;
  })
  startingAt: Date;

  @Expose()
  @Transform(({ value }) => {
    return value ? new Date(fixDateDeserialization(value)) : undefined;
  })
  endingAt: Date;

  @Expose({ name: 'statusId' })
  state: ConferenceState;

  registrationStatus: ConferenceRegistrationStatus;

  @Type(() => {
    return User;
  })
  creator: User;

  @Type(() => {
    return User;
  })
  operators: User[] = [];

  @Expose({ name: 'size' })
  resolution: number | string;

  @Type(() => {
    return User;
  })
  initiator?: User;

  @Expose({ name: 'hide' })
  hidden: boolean;

  @Expose()
  layoutId: number;

  @Transform(({ value }) => {
    return !!value;
  })
  autolayout: boolean;

  participants?: Participant[] | ParticipantDTO[];
  maxUsersCount: number;

  @Expose({ name: 'autostart' })
  autoStart: boolean;

  @Expose()
  recording: boolean;

  @Expose({ name: 'allowclientrecording' })
  allowClientRecording: boolean;

  @Expose({ name: 'enablerecord' })
  enableRecord: boolean;

  participantsCount: number;

  comment: string;

  @Expose({ name: 'changes' })
  changes: string;

  @Expose({ name: 'cancellation' })
  cancellation: string;

  @Expose({ name: 'cancontrol' })
  canControl: boolean;

  @Expose({ name: 'accesstype' })
  accessType: ConferenceAccessType;

  @Expose({ name: 'conferencetype' })
  conferenceType: ConferenceTypeEnum;

  @Expose()
  @Transform(({ value }) => {
    return value ? generateOrigin(value) + value : null;
  })
  recordUrl: string;

  reportUrl: string;

  recordId: number;

  @Type(() => {
    return ProcessingFileModel;
  })
  image: ProcessingFileModel;

  token?: string;

  isAvailableRecord: string;

  @Transform(({ value }) => {
    return !!value;
  })
  isMulticasting: boolean;

  @Expose({ name: 'autoinvitetoframe' })
  @Transform(({ value }) => {
    return value ? ConferenceUserRole[value.toUpperCase()] : undefined;
  })
  autoInviteToFrame: ConferenceUserRole;

  template?: any;

  @Expose({ name: 'conferenceroomid' })
  conferenceRoomId: number;

  @Expose({ name: 'hostname' })
  organizerName: string;

  @Expose({ name: 'hostmail' })
  organizerEmail: string;

  @Expose({ name: 'hostphone' })
  organizerPhone: string;

  room: number;

  ext: 'create' | 'update' | 'cancel';

  getDuration(): number {
    const endDate = this.endingAt || moment(this.startingAt).add(15, 'minutes').toDate();
    return DateUtils.getMinutesDuration(this.startingAt, endDate);
  }

  // TODO добавить на бэке в моделе это поле
  get previewContentUrl(): string {
    return this.recordUrl ? this.recordUrl.replace(/data=content/, 'data=previewcontent') : undefined;
  }

  // TODO добавить на бэке в моделе это поле
  get contentUrl(): string {
    return this.recordUrl ? this.recordUrl.replace(/data=previewcontent/, 'data=content') : undefined;
  }
}

export interface IConferenceModel {
  conferenceid: number;
  name: string;
  description: string;
  layoutid: number;
  autolayout: boolean;
  creator: number;
  created: string;
  size: string;
  public: number;
  voice_activate: number;
  recording: number;
  autoInviteToFrame: ConferenceUserRole;
}
