import { Expose, Type } from 'class-transformer';
import { LayoutEventFrame } from '@breez/models/conference/layout/layout-event-frame.model';

export class LayoutEvent {
  @Expose({ name: 'conferenceid' }) conferenceId: number;
  @Expose({ name: 'greatestcommondivisorforresolution' }) greatestCommonDivisorForResolution?: {
    height: number;
    width?: number;
  };

  @Expose() name: string;
  @Expose({ name: 'framescount' }) framesCount: number;

  @Expose()
  @Type(() => {
    return LayoutEventFrame;
  })
  frames: LayoutEventFrame[];
}
