import { HttpParams } from '@angular/common/http';

export const getParamValueFromUrl = (url: string, paramName: string): any => {
  let paramValue;
  if (url?.includes('?')) {
    try {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpParams.get(paramName);
    } catch {
      return paramValue;
    }
  }

  return paramValue;
};
