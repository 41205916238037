import { createAction, props } from '@ngrx/store';
import { CHAT_ID } from '@breez/modules/chat';
import { ChatParticipantsEvent } from '@breez/models/chat/chat-participants-event';
import { ErrorMessageModel } from '@breez/models/+state';
import { ParticipantsEntity } from '@breez/modules/chat/models/+state/participantsEntity';
import { USER_ID } from '@breez/modules/users/types/user-id.type';

export const addChatsParticipants = createAction(
  '[Chat] Store chats participants id',
  props<{
    data: ParticipantsEntity[];
  }>()
);

export const updateChatParticipants = createAction('[Chat] Update chat participants id', props<ParticipantsEntity>());

export const changeChatParticipants = createAction(
  '[Chat/API] Change chat participants',
  props<{
    chatId: CHAT_ID;
    participantsId: number[];
    status: ChatParticipantsEvent;
  }>()
);

export const changeChatParticipantsSuccess = createAction(
  '[Chat/API] Change chat participants Success',
  props<{
    chatId: CHAT_ID;
    participantsId: number[];
    status: ChatParticipantsEvent;
  }>()
);

export const changeChatParticipantsFailure = createAction(
  '[Chat/API] Change chat participants Failure',
  props<ErrorMessageModel>()
);

export const banChatParticipantsSuccess = createAction(
  '[Chat] Ban chat participants Success',
  props<{
    chatId: CHAT_ID;
    participantId: USER_ID;
    reason: string;
    expired?: Date;
  }>()
);

export const unbanChatParticipantsSuccess = createAction(
  '[Chat] Unban chat participants Success',
  props<{
    chatId: CHAT_ID;
    participantId: USER_ID;
  }>()
);
