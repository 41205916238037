import { Observable } from 'rxjs';
import { filter, mapTo } from 'rxjs/operators';

export function fromIntersectionObserver(
  element: HTMLElement,
  root?: HTMLElement
): Observable<IntersectionObserverEntry> {
  return new Observable<IntersectionObserverEntry>(observer => {
    const intersectionObserver = new IntersectionObserver(
      entries => {
        for (const entry of entries) {
          observer.next(entry);
        }
      },
      {
        root: root ? root : undefined,
        threshold: [0]
      }
    );

    intersectionObserver.observe(element);

    return () => {
      intersectionObserver.unobserve(element);
      intersectionObserver.disconnect();
    };
  });
}

export function fromElementBecomeVisible(element: HTMLElement): Observable<void> {
  return fromIntersectionObserver(element).pipe(
    filter(entry => {
      return entry.isIntersecting;
    }),
    mapTo(undefined)
  );
}
