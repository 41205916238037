import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ValidationError } from './validation-error.model';

const ERROR = {
  type: 'conferenceuserbusy',
  label: 'CONFERENCE_USERS_IS_BUSY'
} as ValidationError;

export function conferenceUserBusyAsyncValidator(busyUserIds$: Observable<number[]>): AsyncValidatorFn {
  return (_: AbstractControl): Observable<ValidationErrors | null> => {
    return busyUserIds$.pipe(
      map(busyUserIds => {
        if (busyUserIds.length === 0) {
          return null;
        }

        return { [ERROR.type]: ERROR } as ValidationErrors;
      }),
      take(1)
    );
  };
}
