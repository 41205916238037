import { NgModule } from '@angular/core';
import { NotificationProvider, NotificationType } from '@breez/models';
import { NotificationDynamicModule } from '@breez/modules/notification/notification-types/dynamic/dynamic.module';
import { NotificationInformationModule } from '@breez/modules/notification/notification-types/types/information/type.module';
import { PushNotificationService } from './push-browser.service';
import { NOTIFICATION_PROVIDER } from './notification.provider';
import { NotificationMessageModule } from '@breez/modules/notification/notification-types/types/message/type.module';

export const NOTIFICATION_PROVIDERS = [
  PushNotificationService,
  {
    provide: NOTIFICATION_PROVIDER,
    useValue: <NotificationProvider>{
      isDefault: true,
      timeOut: 3000,
      positionClass: 'toast-top-right',
      closeButton: true,
      preventDuplicates: false,
      type: NotificationType.Success,
      title: 'SUCCESS'
    },
    multi: true
  },
  {
    provide: NOTIFICATION_PROVIDER,
    useValue: <NotificationProvider>{
      isDefault: true,
      timeOut: 3000,
      positionClass: 'toast-top-right',
      closeButton: true,
      preventDuplicates: false,
      type: NotificationType.Information,
      title: 'NOTIFICATION.INFORMATION'
    },
    multi: true
  },
  {
    provide: NOTIFICATION_PROVIDER,
    useValue: <NotificationProvider>{
      isDefault: true,
      timeOut: 3000,
      positionClass: 'toast-top-right',
      closeButton: true,
      preventDuplicates: false,
      type: NotificationType.Warning,
      title: 'Внимание'
    },
    multi: true
  },
  {
    provide: NOTIFICATION_PROVIDER,
    useValue: <NotificationProvider>{
      isDefault: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      type: NotificationType.Error,
      closeButton: false,
      title: 'Ошибка',
      timeOut: 0
    },

    multi: true
  }
];

@NgModule({
  imports: [
    NotificationDynamicModule,
    NotificationInformationModule,
    NotificationMessageModule
    //    ...NOTIFICATION_TYPES_MODULES
  ],
  providers: [NOTIFICATION_PROVIDERS]
})
export class NotificationModule {}
