import { FileModificationBase } from '@breez/models/shared/files/modification/file-modification-base.model';
import { Expose, Transform } from 'class-transformer';
import { generateOrigin } from '@breez/shared/utilities/generate-origin';

export class ProcessingFileModel {
  @Expose({ name: 'fileid' })
  fileId?: string;

  @Expose()
  name?: string;

  @Expose()
  size?: string;

  @Expose()
  type?: string;

  @Expose()
  description?: string;

  @Expose({ name: 'modifiedon' })
  @Transform(({ value }) => {
    return new Date(value) || null;
  })
  modifiedOn?: Date;

  @Expose({ name: 'ownerid' })
  ownerId?: string;

  content?: string; // base64

  @Expose({ name: 'contenturl' })
  @Transform(({ value }) => {
    return value ? generateOrigin(value) + value : null;
  })
  contentUrl?: string; // preview

  @Expose({ name: 'previewcontent' })
  previewContent?: string;

  @Expose({ name: 'previewcontenturl' })
  @Transform(({ value }) => {
    return value ? generateOrigin(value) + value : null;
  })
  previewContentUrl?: string;

  constructor(base: Partial<ProcessingFileModel>) {
    return Object.assign(this, base);
  }
}

export interface ProcessingFile extends File {
  base64?: string;
  modifiedBlob?: string;
  modifications: FileModificationBase[];
  id?: string;
  localId?: string;
}

export interface Attachment extends ProcessingFileModel {
  parentObjectId: string;
}
