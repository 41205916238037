<span [class.enabled]="stroke || pulseStroke" [class.pulse]="pulseStroke" class="stroke"></span>
<div [style.fontSize]="fontSize" class="avatar placeholder">
  <span>{{ avatarPlaceholder }}</span>
  <img
    [alt]="entity?.name"
    [hidden]="!entity?.avatarUrl"
    [src]="entity?.avatarUrl ? (entity?.avatarUrl | safe: 'url') : null"
  />
  <div *ngIf="overlayIcon" class="overlay">
    <vks-icon [icon]="overlayIcon"></vks-icon>
  </div>
</div>
<span [className]="status + ' indicator'"></span>
