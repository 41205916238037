import { PropertiesActionObjectId, propsProcessing } from '@breez/helpers/+state/execution-action.helper';
import { Account } from '@breez/shared/models/account.model';
import { createAction } from '@ngrx/store';

export const loadAccount = createAction(
  '[Account/API] Load account',
  propsProcessing<Partial<PropertiesActionObjectId>>
);

export const loadAccountSuccess = createAction('[Account/API] Load account Success', params => {
  return propsProcessing<{ account: Account }>(params, loadAccount, 'result');
});
