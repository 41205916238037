import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ValidationError } from './validation-error.model';

const ERROR = {
  type: 'noninteger',
  label: 'SHOULD_BE_INTEGER'
} as ValidationError;

export function integerValidator(allowNull = false): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let controlValue = control.value;

    if (!controlValue && allowNull) {
      return null;
    }

    if (typeof controlValue !== 'number') {
      controlValue = Number(controlValue);
    }

    if (Number.isInteger(controlValue)) {
      return null;
    }

    return { [ERROR.type]: ERROR } as ValidationErrors;
  };
}
