import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as AccountState from './account.state';

export const getAccountState = createFeatureSelector<AccountState.State>('account');

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getAccount = () => {
  return createSelector(getAccountState, state => {
    return state.account;
  });
};
