import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { User } from '@breez/models';
import { AuthService } from '@breez/modules/auth/services/auth.service';
import { AppService } from '@breez/app.service';
import { LocalStorage } from '@breez/shared/modules/storage/interfaces/local-storage.interface';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BrowsersMap } from '@breez/pages/not-support-page/browsers-for-recommendations.map';
import { TranslateService } from '@ngx-translate/core';
import { SupportedBrowsers } from '@breez/shared/models/supported-browsers.enum';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';
import { LangChangeEvent } from '@ngx-translate/core/lib/translate.service';
import { replayWhileSubs } from '@breez/shared/rxjs-operators';
import { EmitOnChange } from '@breez/shared/utilities/decorators/emit-on-change.decorator';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { RestApiService } from '@breez/rest-api.service';

@UntilDestroy()
@Component({
  selector: 'vks-not-support-page',
  templateUrl: './not-support-page.component.html',
  styleUrls: ['./not-support-page.component.scss', './not-support-page.media.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotSupportPageComponent implements OnDestroy, OnChanges {
  currentUser$: Observable<User> = this.authService.currentUser$;

  helpLink$: Observable<string> = this.restApiService.info$.pipe(
    map(info => {
      return info.help;
    }),
    filter(isTruthy)
  );

  @Input()
  browserRecommendation: number;

  @EmitOnChange('browserRecommendation', { onlyTruthy: true })
  browserRecommendation$: ReplaySubject<number> = new ReplaySubject<number>(1);

  @Input()
  appAvailable: boolean;

  selectedLang$: Observable<string> = this.translateService.onLangChange.pipe(
    map((event: LangChangeEvent) => {
      return event.lang;
    }),
    startWith(this.translateService.currentLang),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  recommendationPayload$ = this.browserRecommendation$.pipe(
    filter(val => {
      return !!val;
    }),
    map(recommendationIndex => {
      const recommendationPayload = BrowsersMap[recommendationIndex];
      if (recommendationIndex === 2) {
        const currentBrowserName = this.appService.environment.browser.name.toLowerCase();
        recommendationPayload.browsersList = recommendationPayload.browsersList.filter(browser => {
          return browser.name.toLowerCase() === currentBrowserName;
        });
        if (currentBrowserName !== SupportedBrowsers.CHROME) {
          recommendationPayload.browsersList.push({
            name: 'Chrome',
            icon: 'chrome',
            link: 'https://www.google.ru/intl/ru/chrome/',
            action: 'DOWNLOAD'
          });
        }
      }
      return recommendationPayload;
    }),
    replayWhileSubs()
  );

  recommendationText$ = combineLatest([
    this.selectedLang$,
    this.browserRecommendation$,
    this.recommendationPayload$,
    this.helpLink$
  ]).pipe(
    filter(([_, val]) => {
      return !!val;
    }),
    map(([_, recommendationIndex, recommendationPayload, helpLink]) => {
      let recommendationText = this.translateService.instant(
        `BROWSER_RECOMMENDATIONS.RECOMMENDATION_${recommendationIndex}`
      );
      if (recommendationPayload.textLinks) {
        for (let link of recommendationPayload.textLinks) {
          if (!isTruthy(link) || link === '') {
            link = helpLink;
          }
          recommendationText = recommendationText.replace(/#/, `<a target="_blank" href='${link}'>`);
          recommendationText = recommendationText.replace(/#/, '</a>');
        }
        while (/\\n/.test(recommendationText)) {
          recommendationText = recommendationText.replace(/\\n/, '<br/>');
        }
      }
      return recommendationText;
    })
  );

  constructor(
    private authService: AuthService,
    private appService: AppService,
    private localStorage: LocalStorage,
    private translateService: TranslateService,
    private restApiService: RestApiService
  ) {}

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnChanges(_: SimpleChanges): void {}

  saveUserGesture(): void {
    this.localStorage.setItem('browser-checked', '1');
    this.appService.browserRecommendation$.next(0);
    this.appService.closeNotSupportPage$.next(true);
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy(): void {}
}
