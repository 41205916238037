import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NotificationDynamicModule } from '@breez/modules/notification/notification-types/dynamic/dynamic.module';
import { COMPONENTS } from './components';

import { OVERLAY_PROVIDERS } from './overlay-provider';

@NgModule({
  imports: [CommonModule, NotificationDynamicModule],
  declarations: [COMPONENTS],
  providers: [OVERLAY_PROVIDERS]
})
export class OverlayNotificationModule {}
