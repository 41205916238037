import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { LoggerService } from '@breez/shared/services/logger.service';

@Component({
  selector: 'vks-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AudioPlayerComponent implements OnChanges {
  @ViewChild('player') player: ElementRef<HTMLAudioElement>;

  @Input() source: string;
  @Input() sourceObject: MediaStream | MediaSource | Blob | null;
  @Input() outputDeviceId = 'default';

  constructor(private logger: LoggerService) {}

  play(): void {
    if (!this.player) {
      return;
    }

    this.player.nativeElement.currentTime = 0;
    this.player.nativeElement.play();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.outputDeviceId && this.player) {
      setTimeout(() => {
        return this.updatePlayerSink();
      }, 0);
    }
  }

  updatePlayerSink(): void {
    if (typeof (<any>this.player.nativeElement).setSinkId === 'function') {
      (<any>this.player.nativeElement).setSinkId(this.outputDeviceId).catch(this.logger.error);
    }
  }
}
