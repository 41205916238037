<div class="inviting-header">
  <span>{{ 'BREEZ_INVITING_TITLE' | translate }}</span>
  <button
    vks-button
    (buttonClick)="close()"
    class="fill-black btn-basic btn-compressed"
    icon="close"
    iconClass="square24"
  ></button>
</div>
<div class="inviting-form">
  <span class="inviting-info">{{ 'BREEZ_INVITING_TEXT_LINK_EMAIL' | translate }}</span>
  <div *ngIf="sendEmailSuccess$ | async | not; else sentEmailSuccess" class="inviting-form-email">
    <input
      [class.alert]="emailControl.invalid && !emailControl.pristine"
      [formControl]="emailControl"
      class="email_input"
      id="email-input"
      placeholder="email@email.ru"
      type="text"
      [enterIsSubmit]="canSendEmail()"
      (valueSubmit)="sendEmail()"
      (blur)="addValidators(emailControl, [EMAIL_VALIDATOR])"
      vks-cinput
    />
    <button
      vks-button
      vks-debounce-click
      [icon]="'inviting-email'"
      (debounceClick)="sendEmail()"
      [matTooltip]="'BREEZ_INVITING_SEND_BY_EMAIL' | translate"
      [spinnerIcon]="'loader-spin-star'"
      [storeKey]="StoreExecutionKeyEnum.EMAIL"
      [loadingClass]="'btn-loading'"
      vks-loading-condition
      iconClass="square20"
      class="btn-light"
    ></button>
  </div>
  <span *ngFor="let errorLabel of emailErrorLabels$ | async" class="inviting-info red">{{
    errorLabel | translate
  }}</span>
  <mat-divider></mat-divider>
  <span class="inviting-info">{{ 'BREEZ_INVITING_TEXT_LINK_QR' | translate }}</span>
  <div class="inviting-form-buttons" style="">
    <button
      vks-button
      vks-debounce-click
      [icon]="'inviting-link'"
      [label]="'COPY' | translate"
      (debounceClick)="copyLink()"
      [matTooltip]="'COPY_LINK' | translate"
      [spinnerIcon]="'loader-spin-star'"
      [storeKey]="StoreExecutionKeyEnum.COPY"
      [loadingClass]="'btn-loading'"
      vks-loading-condition
      iconClass="square20"
      class="btn-light"
    ></button>
    <button
      vks-button
      vks-debounce-click
      [icon]="'inviting-qr-code'"
      [label]="'QR_CODE' | translate"
      (debounceClick)="showQrCode()"
      [matTooltip]="'GENERATE_QR_CODE' | translate"
      [spinnerIcon]="'loader-spin-star'"
      [storeKey]="StoreExecutionKeyEnum.QR"
      [loadingClass]="'btn-loading'"
      vks-loading-condition
      class="btn-light"
      iconClass="square20"
    ></button>
  </div>
  <span *ngIf="generateLinkError$ | async as generateLinkError" class="inviting-info red">{{
    generateLinkError | translate
  }}</span>
  <span *ngIf="copyLinkSuccess$ | async" class="inviting-info accent">{{
    'BREEZ_INVITING_LINK_SUCCESS' | translate
  }}</span>
</div>

<ng-template #sentEmailSuccess>
  <span class="inviting-info accent center">{{ 'BREEZ_INVITING_EMAIL_SENT_SUCCESS' | translate }}</span>
</ng-template>
