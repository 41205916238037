import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmPopupData } from '@breez/shared/models/confrim-popup-data.model';
import { TranslateService } from '@ngx-translate/core';
import { Observable, switchMap, of, from, combineLatest } from 'rxjs';
import { filter, map, startWith, take, tap, withLatestFrom } from 'rxjs/operators';
import { ElectronService } from '@breez/modules/core/services';
import { StateService } from '@breez/shared/services/state.service';
import { ELECTRON_CHANNEL_LIST } from '../../../../../electron-channel-list';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { replayWhileSubs } from '@breez/shared/rxjs-operators';

@Injectable({
  providedIn: 'root'
})
export class ConfirmPopupService {
  electronWindowUid$: Observable<string> = this.stateService.electronWindowUid$.pipe(
    startWith(null),
    replayWhileSubs()
  );

  constructor(
    private readonly dialog: MatDialog,
    private translateService: TranslateService,
    private electronService: ElectronService,
    private stateService: StateService
  ) {}

  confirm(message: ConfirmPopupData | string): Observable<boolean> {
    // TODO: вернуть после редизайна диалогового окна
    // const config: MatDialogConfig<ConfirmPopupData | string> = ConfirmPopupService.configConfirmPopup(message);
    //
    // return this.dialog
    // 					 .open<ConfirmPopupComponent, ConfirmPopupData | string, boolean>(ConfirmPopupComponent, config)
    // 					 .afterClosed();
    return combineLatest([
      this.translateService.get('CONFIRM'),
      this.translateService.get('OK'),
      this.translateService.get('CANCEL'),
      this.translateService.get(
        typeof message === 'string' ? (message as string) : (message as ConfirmPopupData).question
      )
    ]).pipe(
      take(1),
      withLatestFrom(this.electronWindowUid$),
      switchMap(([[title, ok, cancel, text], uid]) => {
        return this.electronService.isElectron
          ? from(
              this.electronService.electronApi.invoke(ELECTRON_CHANNEL_LIST.DIALOG_OPEN, {
                uid,
                text,
                ok,
                cancel,
                title
              })
            ).pipe(
              map(result => {
                return result.response === 0;
              })
            )
          : of(confirm(text));
      }),
      tap(() => {
        if (this.electronService.isElectron) {
          this.electronWindowUid$.pipe(take(1), filter(isTruthy)).subscribe(uid => {
            this.electronService.electronApi.send(ELECTRON_CHANNEL_LIST.FIX_FOCUS, { uid });
          });
        }
      })
    );
  }

  private static configConfirmPopup(data: ConfirmPopupData | string): MatDialogConfig<ConfirmPopupData | string> {
    return {
      disableClose: true,
      autoFocus: false,
      panelClass: 'no-padding',
      data
    };
  }
}
