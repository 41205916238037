import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy
} from '@angular/core';
import { bufferTime, merge, Observable, OperatorFunction, ReplaySubject, Subject } from 'rxjs';
import {
  auditTime,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mapTo,
  pairwise,
  scan,
  startWith,
  take
} from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { replayWhileSubs } from '@breez/shared/rxjs-operators';

enum ScrollBoundaryStates {
  ABSOLUTE,
  SUCCESS,
  DISMISS
}

@UntilDestroy()
@Component({
  selector: 'vks-scroll-controller',
  templateUrl: './scroll-controller.component.html',
  styleUrls: ['./scroll-controller.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScrollControllerComponent implements AfterViewChecked, OnDestroy {
  @HostListener('wheel', ['$event'])
  onMouseWheel(event: WheelEvent): void {
    this.mouseWheelEventSubject$.next(event);
  }

  @HostListener('touchmove', ['$event'])
  @HostListener('touchstart', ['$event'])
  @HostListener('touchend', ['$event'])
  onTuch(event: TouchEvent): void {
    if (event.type === 'touchmove') {
      if (this.isOnTop()) {
        this.touchEventSubject$.next(event);
      }
    } else {
      this.touchEventSubject$.next(event);
    }
  }

  @Input() sideDetectAllowance = 64;

  private mouseWheelEventSubject$: ReplaySubject<WheelEvent> = new ReplaySubject<WheelEvent>(1);
  private touchEventSubject$: ReplaySubject<TouchEvent> = new ReplaySubject<TouchEvent>(1);

  private touchesDelta$: Observable<number> = this.touchEventSubject$.pipe(
    scan(
      (
        acc: { start: number; delta: number; end: boolean },
        event: TouchEvent
      ): { start: number; delta: number; end: boolean } => {
        if (event.type === 'touchstart') {
          return { start: event?.touches[0].clientY, delta: 0, end: false };
        } else if (event.type === 'touchmove') {
          const delta = event.touches[0].clientY - acc.start;
          if (delta > 0) {
            event.preventDefault();
          }
          return { start: acc.start, delta, end: false };
        }
        return { start: undefined, delta: 0, end: true };
      },
      { start: undefined, delta: 0, end: true }
    ),
    map(accumulated => {
      return accumulated.end ? 0 : accumulated.delta;
    }),
    filter(delta => {
      return delta >= 0;
    }),
    this.resetIfNotFired(0),
    distinctUntilChanged()
  );

  private mouseDelta$: Observable<number> = this.mouseWheelEventSubject$.pipe(
    filter(() => {
      return this.isOnTop();
    }),
    map(({ deltaY }) => {
      return deltaY;
    }),
    filter(delta => {
      return delta <= 0;
    }),
    this.resetIfNotFired(0),
    distinctUntilChanged()
  );

  private resetIfNotFired<T>(value: T, delay: number = 100): OperatorFunction<any, T> {
    return input$ => {
      return input$.pipe(
        bufferTime(delay),
        map(buffer => {
          return buffer?.length > 0 ? (buffer[buffer?.length - 1] ?? value) : value;
        }),
        startWith(value)
      );
    };
  }

  topDelta$: Observable<number> = merge(this.mouseDelta$, this.touchesDelta$).pipe(
    map(delta => {
      return Math.abs(delta);
    })
  );

  curtainHeight$ = this.topDelta$.pipe(
    map(height => {
      return height <= 25 ? 0 : height > 75 ? 75 : height;
    }),
    distinctUntilChanged(),
    auditTime(100),
    replayWhileSubs()
  );

  maxTopDelta = 0;

  topEnd$: Observable<boolean> = this.topDelta$.pipe(
    map(delta => {
      if (this.maxTopDelta > 5 && delta <= 5) {
        this.maxTopDelta = 0;
        return true;
      } else {
        if (delta > 5) {
          this.maxTopDelta = delta;
        }
        return false;
      }
    }),
    startWith(false),
    distinctUntilChanged()
  );

  topEndTrigger$: Observable<void> = this.topEnd$.pipe(
    filter(needLoad => {
      return needLoad === true;
    }),
    mapTo(undefined),
    replayWhileSubs()
  );

  ngAfterViewChecked$ = new Subject<void>();

  private scrollTrigger$ = new ReplaySubject<void>(1);

  constructor(public hostElement: ElementRef<HTMLElement>) {}

  @HostListener('scroll')
  onScroll(): void {
    this.scrollTrigger$.next();
  }

  marginBottom$: Observable<number> = this.scrollTrigger$.pipe(
    map(() => {
      const scrollHeight = this.hostElement.nativeElement.scrollHeight,
        scrollTop = this.hostElement.nativeElement.scrollTop,
        offsetHeight = this.hostElement.nativeElement.offsetHeight;
      return scrollHeight - (offsetHeight + scrollTop);
    }),
    replayWhileSubs()
  );

  private scrollToTopTrigger$: Observable<void> = this.scrollTrigger$.pipe(
    map(() => {
      return this.getTopBoundaryState(this.sideDetectAllowance);
    }),
    distinctUntilChanged(),
    pairwise(),
    filter(([previous, current]) => {
      return current !== ScrollBoundaryStates.ABSOLUTE && previous > current;
    }),
    mapTo(undefined)
  );

  needLoadTopTrigger$: Observable<void> = merge(this.scrollToTopTrigger$, this.topEndTrigger$.pipe(debounceTime(500)));

  getScrollToBottomTrigger(allowance: number = this.sideDetectAllowance): Observable<void> {
    return this.scrollTrigger$.pipe(
      map(() => {
        return this.isOnBottom(allowance);
      }),
      distinctUntilChanged(),
      filter(onBottom => {
        return onBottom;
      }),
      mapTo(undefined)
    );
  }

  getTopBoundaryState(allowance: number = this.sideDetectAllowance): ScrollBoundaryStates {
    const scrollTop = this.hostElement.nativeElement.scrollTop;
    return scrollTop === 0
      ? ScrollBoundaryStates.ABSOLUTE
      : scrollTop < allowance
        ? ScrollBoundaryStates.SUCCESS
        : ScrollBoundaryStates.DISMISS;
  }

  isOnTop(): boolean {
    const { offsetHeight, scrollHeight } = this.hostElement.nativeElement;
    return offsetHeight < scrollHeight && this.getTopBoundaryState() === ScrollBoundaryStates.ABSOLUTE;
  }

  isOnBottom(allowance: number = this.sideDetectAllowance): boolean {
    const scrollHeight = this.hostElement.nativeElement.scrollHeight,
      scrollTop = this.hostElement.nativeElement.scrollTop,
      offsetHeight = this.hostElement.nativeElement.offsetHeight;

    return scrollHeight - (offsetHeight + scrollTop) < allowance;
  }

  scrollToTop(): void {
    this.hostElement.nativeElement.scrollTop = 0;
    setTimeout(() => {
      return (this.hostElement.nativeElement.scrollTop = 0);
    }, 0);
  }

  scrollToBottom(): void {
    this.hostElement.nativeElement.scrollTop = this.hostElement.nativeElement.scrollHeight;
    setTimeout(() => {
      return (this.hostElement.nativeElement.scrollTop = this.hostElement.nativeElement.scrollHeight);
    }, 0);
  }

  scrollAfterChecked(top = false): void {
    this.ngAfterViewChecked$.pipe(take(1)).subscribe(() => {
      if (top) {
        this.scrollToTop();
      } else {
        this.scrollToBottom();
      }
    });
  }

  ngAfterViewChecked(): void {
    this.ngAfterViewChecked$.next();
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy(): void {}
}
