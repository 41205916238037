import { APP_INITIALIZER, LOCALE_ID, ModuleWithProviders, NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { TranslationOptions } from '@breez/shared/modules/translation/interfaces/translation-options.interface';
import {
  TRANSLATION_CONFIG,
  TranslationService
} from '@breez/shared/modules/translation/interfaces/translation-service.interface';
import { TranslationStorage } from '@breez/shared/modules/translation/interfaces/translation-storage.interface';
import {
  TRANSLATION_PREFIX,
  TRANSLATION_SUFFIX
} from '@breez/shared/modules/translation/interfaces/translation.interface';
import { BaseTranslationStorage } from '@breez/shared/modules/translation/services/base-translation-storage.service';
import { BaseTranslationService } from '@breez/shared/modules/translation/services/base-translation.service';
import {
  TRANSLATION_CONFIG_DEFAULT,
  TRANSLATION_PREFIX_DEFAULT,
  TRANSLATION_SUFFIX_DEFAULT
} from '@breez/shared/modules/translation/translation.common';
import { environment } from '@breez/environment';

export function translationLoader(translationService: TranslationService) {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return () => {
    return translationService.init().toPromise();
  };
}

@NgModule({
  imports: [TranslateModule]
})
export class TranslationModule {
  static forRoot(options: Partial<TranslationOptions> = {}): ModuleWithProviders<TranslationModule> {
    return {
      ngModule: TranslationModule,
      providers: [
        {
          provide: TRANSLATION_CONFIG,
          useValue: options.config || TRANSLATION_CONFIG_DEFAULT
        },
        {
          provide: TRANSLATION_PREFIX,
          useValue: options.prefix || TRANSLATION_PREFIX_DEFAULT
        },
        {
          provide: TRANSLATION_SUFFIX,
          useValue: options.suffix || TRANSLATION_SUFFIX_DEFAULT + '?ver=' + environment.release
        },
        {
          provide: TranslationService,
          useClass: options.service || BaseTranslationService
        },
        {
          provide: TranslationStorage,
          useClass: options.storage || BaseTranslationStorage
        },
        {
          provide: APP_INITIALIZER,
          useFactory: translationLoader,
          deps: [TranslationService],
          multi: true
        },
        {
          provide: LOCALE_ID,
          deps: [TranslationService],
          // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
          useFactory: (translationService: TranslationService) => {
            return translationService.getLanguage();
          }
        }
      ]
    };
  }
}
