import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EMPTY, Observable } from 'rxjs';
import { UserMediaSource } from '@breez/models/webrtc/media-source.model';
import { filter, map, take } from 'rxjs/operators';
import { UserDevicesSettingsPopUpComponent } from '@breez/modules/conference/components/user-devices-settings-pop-up/user-devices-settings-pop-up.component';
import { isTruthy } from '@breez/shared/utilities/is-truthy';

@Injectable({
  providedIn: 'root'
})
export class WebrtcDialogService {
  constructor(private dialog: MatDialog) {}

  requestUserPreConferenceSettings(
    destroyed$: Observable<any> = EMPTY,
    setDefaultMedia = true,
    currentMedia: { audio: boolean; video: boolean; volume: boolean; screen?: boolean } = undefined,
    effectsSdkSetting?: { blur: boolean }
  ): Observable<{
    sources: UserMediaSource[];
    mediaToggles: { video: boolean; audio: boolean; screen: boolean; volume?: boolean };
    effectsSdkSetting: { blur: boolean };
  }> {
    const settingsDialog = this.dialog.open<
      UserDevicesSettingsPopUpComponent,
      any,
      | {
          sources: UserMediaSource[];
          mediaToggles: { video: boolean; audio: boolean; screen: boolean; volume?: boolean };
          effectsSdkSetting: { blur: boolean };
          setDefaultMedia;
        }
      | undefined
    >(UserDevicesSettingsPopUpComponent, {
      data: { setDefaultMedia, currentMedia, effectsSdkSetting },
      panelClass: 'no-padding',
      autoFocus: false,
      width: '460px',
      maxWidth: '98vw',
      height: 'fit-content',
      maxHeight: '100vh',
      id: 'devices-settings-dialog'
    });

    destroyed$?.pipe(take(1))?.subscribe(() => {
      this.dialog.closeAll();
    });

    return settingsDialog.afterClosed().pipe(
      filter(isTruthy),
      map(({ sources, mediaToggles, effectsSdkSetting: sdkSetting }) => {
        if (!sources || !Array.isArray(sources)) {
          return null;
        }
        return {
          sources,
          mediaToggles: isTruthy(currentMedia.screen)
            ? { ...mediaToggles, screen: currentMedia.screen } // не меняем screen если он передан
            : mediaToggles,
          effectsSdkSetting: sdkSetting
        };
      })
    );
  }
}
