import { ConferenceChatEnum } from '../enums';
import { AvatarDisplayable } from '@breez/modules/user/modules/models/avatar-displayable.model';
import { Expose, Transform, Type } from 'class-transformer';
import { Message } from './message.model';
import { CHAT_ID } from '../types';

export class Chat implements AvatarDisplayable {
  @Expose({ name: 'chatid' })
  id: CHAT_ID;

  name: string;

  @Expose({ name: 'participantscount' })
  participantsCount: number;

  @Expose({ name: 'lastmessage' })
  @Type(() => {
    return Message;
  })
  lastMessage: Message;

  lastAction?: Date;

  conferenceChatType?: ConferenceChatEnum;

  @Expose({ name: 'activeuserids' })
  participantUserIds: number[];

  @Expose({ name: 'banneduserids' })
  bannedUserIds: number[];

  @Expose({ name: 'createdon' })
  @Transform(({ value }) => {
    return new Date(value * 1000);
  })
  createdOn: Date;

  @Expose({ name: 'conferenceid' })
  conferenceId: number;

  @Expose({ name: 'callid' })
  callId: number;

  @Expose({ name: 'pinnedorder' })
  pinnedOrder: number;

  unreadMessagesCount?: number;

  hasMessagesUpdates?: boolean;

  avatarUrl?: string;

  mute?: boolean;

  constructor(base?: Partial<Chat>) {
    return Object.assign(this, base);
  }
}
