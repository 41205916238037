<div class="header">
  <h3>{{ 'ADD_PARTICIPANTS' | translate }}</h3>
  <div class="controls">
    <button vks-button (buttonClick)="close()" class="btn-basic fill-gray" icon="close" iconClass="square24"></button>
  </div>
</div>
<mat-tab-group [class.hide-tabs]="hideTabs" [disablePagination]="true">
  <mat-tab>
    <ng-template mat-tab-label>
      {{ 'CONTACTS' | translate }}
    </ng-template>
    <div class="template-selection">
      <mat-button-toggle-group [formControl]="selectedTabControl" class="gap" name="user-group">
        <mat-button-toggle
          *ngFor="let tab of displayingTabs$ | async; let tabIndex = index"
          [disableRipple]="true"
          [value]="tabIndex"
        >
          <span
            *ngIf="tab.objectType === 'division' || (tab.type | existInArray: ['company', 'custom']); else tabTranslate"
            >{{ tab.name }}</span
          >
          <ng-template #tabTranslate>
            <span>{{ 'USER_GROUP.' + tab.type | translate }}</span>
          </ng-template>
          <span *ngIf="tab.type === 'selected'" class="inline-badge">{{ checklistSelection.selected.length }}</span>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div class="field fluid scrollable tree-control">
      <div *ngIf="multiSelect" class="controls">
        <button
          *ngIf="isExpandedAny$ | async | not"
          vks-button
          [label]="'EXPAND_ALL' | translate | uppercase"
          (buttonClick)="expandAll()"
          class="btn-basic fill-gray"
        ></button>
        <button
          *ngIf="isExpandedAny$ | async"
          vks-button
          [label]="'COLLAPSE_ALL' | translate | uppercase"
          (buttonClick)="collapseAll()"
          class="btn-basic fill-gray"
        ></button>
        <button
          *ngIf="isSelectedAll$ | async | not"
          vks-button
          [label]="'SELECT_EVERYONE' | translate | uppercase"
          (buttonClick)="selectAll()"
          class="btn-basic fill-gray"
        ></button>
        <button
          *ngIf="isSelectedAll$ | async"
          vks-button
          [label]="'DESELECT_ALL' | translate | uppercase"
          (buttonClick)="deselectAll()"
          class="btn-basic fill-gray"
        ></button>
        <button
          vks-button
          [label]="'INVERT' | translate | uppercase"
          (buttonClick)="invert()"
          class="btn-basic fill-gray"
        ></button>
        <vks-context-controls
          [actions]="extraContextControls$ | async"
          [iconsCompressed]="false"
        ></vks-context-controls>
      </div>
      <div class="list-prefix">
        <input
          #searchControl
          vks-cinput
          [formControl]="searchQuery"
          [placeholder]="'SEARCH_USER' | translate"
          aria-label="search"
          icon="search-field"
          type="text"
        />
        <button
          *ngIf="searchQuery.value"
          vks-button
          (buttonClick)="resetSearchQuery()"
          class="btn-basic btn-compressed fill-gray"
          icon="x-10"
        ></button>
      </div>
      <div class="list">
        <ng-container *ngIf="treeData$ | async; let treeData; else: loading">
          <ng-container *ngIf="treeData.users | isEmpty | not; else emptyListStub">
            <vks-user-tree
              #userTree
              [allowExpand]="true"
              [allowSelecting]="true"
              [checklistSelection]="checklistSelection"
              [colorizeNodes]="colorizeTree$ | async"
              [disabledUserIds]="disabledUserIds"
              [divisionsList]="treeData.divisions"
              [expandAll]="true"
              [disableBusyUsers]="disableBusyUsers"
              [multiSelect]="multiSelect"
              [reduceDivisions]="true"
              [userList]="treeData.users"
              [virtualScroll]="true"
              (nodeExpansionChanges)="checkExpandedAny()"
            ></vks-user-tree>
          </ng-container>
          <ng-template #emptyListStub>
            <p class="stub center">{{ 'EMPTY_LIST' | translate }}</p>
          </ng-template>
          <div [class.visible]="treeLoading$ | async" class="loading-list-stub">
            <p class="stub">{{ 'LOADING' | translate }}</p>
          </div>
        </ng-container>
        <ng-template #loading>
          <p class="stub center">{{ 'LOADING' | translate }}</p>
        </ng-template>
      </div>
    </div>
    <ng-container *ngIf="userTreeExtraContentTemplate">
      <ng-container *ngTemplateOutlet="userTreeExtraContentTemplate"></ng-container>
    </ng-container>
  </mat-tab>
  <mat-tab *ngIf="displayEmailSection">
    <ng-template mat-tab-label>
      {{ 'E_MAIL' | translate }}
    </ng-template>
    <div vks-control-group class="email-section">
      <label for="email-addresses">{{ 'LIST_EMAIL_ADDRESSES' | translate }}</label>
      <textarea
        vks-ctextarea
        [formControl]="emailListForm"
        class="min-height no-resize"
        id="email-addresses"
      ></textarea>
    </div>
  </mat-tab>
  <ng-container *ngIf="extraTabs">
    <mat-tab *ngFor="let extraTab of extraTabs">
      <ng-template mat-tab-label>
        <ng-container *ngIf="extraTab.translatingLabel | translate; else noTranslate">
          {{ extraTab.translatingLabel | translate }}
        </ng-container>
        <ng-template #noTranslate>
          <span>{{ extraTab?.label }}</span>
        </ng-template>
      </ng-template>
      <ng-container *ngTemplateOutlet="extraTab.template"></ng-container>
    </mat-tab>
  </ng-container>
</mat-tab-group>
<div *ngIf="!dialogInputData.forChat; else chatActionButtons" class="actions">
  <button vks-button [label]="'OK' | translate" (buttonClick)="resolveSelection()" class="btn-primary"></button>
</div>
<ng-template #chatActionButtons>
  <div class="actions">
    <button
      vks-button
      [disabled]="isSelectedAny$ | async | not"
      [label]="'ADD' | translate"
      (buttonClick)="resolveSelection()"
      class="btn-primary"
    ></button>
    <button vks-button [label]="'CANCEL' | translate" (buttonClick)="close()" class="btn-outline btn-danger"></button>
  </div>
</ng-template>
