import { Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { LocalStorage } from '@breez/shared/modules/storage/interfaces/local-storage.interface';
import { DRAFTS_KEY } from '../consts';
import { MessageDraft } from '../interfaces';
import { CHAT_ID } from '../types';
import { USER_ID } from '@breez/modules/users/types/user-id.type';
import { catchError, filter, map, shareReplay, startWith } from 'rxjs/operators';
import { isTruthy } from '@breez/shared/utilities/is-truthy';

@Injectable({
  providedIn: 'root'
})
export class MessageDraftService {
  constructor(private localStorage: LocalStorage) {}

  private savedDrafts$: Observable<MessageDraft[]> = fromEvent<StorageEvent>(window, 'storage').pipe(
    filter(event => {
      return event.key === DRAFTS_KEY;
    }),
    map(event => {
      return event.newValue;
    }),
    startWith(this.localStorage.getItem(DRAFTS_KEY)),
    map(plain => {
      return JSON.parse(plain);
    }),
    map(parsed => {
      return Array.isArray(parsed) ? <MessageDraft[]>parsed : [];
    }),
    catchError(() => {
      return [];
    }),
    shareReplay(1)
  );

  getMessageDraftByChatId(chatId: CHAT_ID, currentUserId: USER_ID): Observable<MessageDraft> {
    return this.savedDrafts$.pipe(
      map(draftsArray => {
        if (!Array.isArray(draftsArray) || draftsArray.length < 1) {
          return null;
        }
        const targetDraftIndex = draftsArray.findIndex(draft => {
          return draft.targetChatId === chatId && draft.userId === currentUserId;
        });
        if (targetDraftIndex === -1) {
          return null;
        }
        return draftsArray[targetDraftIndex];
      })
    );
  }

  processBodyFormControlValue(value: string, chatId: CHAT_ID, currentUserId: number): void {
    const messageDraft: MessageDraft = {
      targetChatId: chatId,
      message: value,
      userId: currentUserId
    };
    this.saveDrafts(
      isTruthy(value) && value !== '' ? this.addMessageDraft(messageDraft) : this.removeMessageDraft(messageDraft)
    );
  }

  private removeMessageDraft(draft: MessageDraft): MessageDraft[] {
    const drafts = JSON.parse(this.localStorage.getItem(DRAFTS_KEY)) ?? [];
    return drafts.filter(cDraft => {
      return cDraft.userId !== draft.userId || cDraft.targetChatId !== draft.targetChatId;
    });
  }

  private addMessageDraft(draft: MessageDraft): MessageDraft[] {
    const drafts = this.removeMessageDraft(draft);
    drafts.push(draft);
    return drafts;
  }

  private saveDrafts(drafts: MessageDraft[]): void {
    const newValue = JSON.stringify(drafts);
    const event = new StorageEvent('storage', {
      key: DRAFTS_KEY,
      newValue
    });
    window.dispatchEvent(event);
    this.localStorage.setItem(DRAFTS_KEY, newValue);
  }
}
