import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { ChatParticipantEvent, ChatService } from '@breez/modules/chat';
import { EventTypeEnum, User } from '@breez/models';
import { ChatParticipantsEvent } from '@breez/models/chat/chat-participants-event';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import * as ParticipantsAction from '@breez/modules/chat/+state/participants/participants.actions';
import { AuthService } from '@breez/modules/auth/services/auth.service';
import * as ChatActions from '@breez/modules/chat/+state/chat/chat.actions';
import { catchError, of, mergeMap } from 'rxjs';
import { Action } from '@ngrx/store';

@Injectable()
export class ParticipantsEffects {
  requestChangeChatParticipants$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ParticipantsAction.changeChatParticipants),
      mergeMap(action => {
        switch (action.status) {
          case ChatParticipantsEvent.ADDED: {
            return this.chatService.addParticipantsToChat(action.participantsId, action.chatId).pipe(
              map(result => {
                return result
                  ? ParticipantsAction.changeChatParticipantsSuccess(action)
                  : ParticipantsAction.changeChatParticipantsFailure({ errorMessage: 'unknown' });
              }),
              catchError(errorMessage => {
                return of(ParticipantsAction.changeChatParticipantsFailure({ errorMessage }));
              })
            );
          }

          case ChatParticipantsEvent.REMOVED: {
            return this.chatService.removeChatParticipants(action.participantsId, action.chatId).pipe(
              map(result => {
                return result
                  ? ParticipantsAction.changeChatParticipantsSuccess(action)
                  : ParticipantsAction.changeChatParticipantsFailure({ errorMessage: 'unknown' });
              }),
              catchError(errorMessage => {
                return of(ParticipantsAction.changeChatParticipantsFailure({ errorMessage }));
              })
            );
          }
        }
      })
    );
  });

  eventChangeChatParticipants$ = createEffect(() => {
    return this.chatService.chatParticipantChanges$.pipe(
      filter((participantChanges: ChatParticipantEvent) => {
        return [EventTypeEnum.DELETE, EventTypeEnum.INSERT].includes(participantChanges.eventType);
      }),
      withLatestFrom(this.authService.currentUser$), // TODO CurrentUser в user store
      map(([participantChanges, currentUser]: [ChatParticipantEvent, User]) => {
        const chatId = participantChanges.affectedChatId;
        const participantsId = [participantChanges.updatedUserId];

        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        const getStatus = eventType => {
          switch (eventType) {
            case EventTypeEnum.DELETE:
              return ChatParticipantsEvent.REMOVED;
            case EventTypeEnum.INSERT:
              return ChatParticipantsEvent.ADDED;
            default:
              return null;
          }
        };

        let status: ChatParticipantsEvent = getStatus(participantChanges.eventType);

        if (participantsId.includes(currentUser.id)) {
          switch (status) {
            case ChatParticipantsEvent.ADDED:
              return ChatActions.changeChatState({ chatId, needSendToBackend: false, remove: false });

            case ChatParticipantsEvent.REMOVED:
              return ChatActions.changeChatState({ chatId, needSendToBackend: false, remove: true });
          }
        } else {
          return isTruthy(status)
            ? ParticipantsAction.changeChatParticipantsSuccess({
                chatId,
                participantsId,
                status
              })
            : null;
        }
      })
    );
  });

  eventBanChatParticipants$ = createEffect(() => {
    return this.chatService.banChatParticipants$.pipe(
      map(({ banned, chatId, userId, expires, reason, conferenceId }) => {
        return banned
          ? [
              ParticipantsAction.banChatParticipantsSuccess({
                chatId,
                reason,
                participantId: userId,
                expired: expires
              })
            ]
          : [
              ParticipantsAction.unbanChatParticipantsSuccess({ chatId, participantId: userId }),
              ChatActions.loadChats({ chatsId: [chatId], forceIfExist: [chatId], conferenceId })
            ];
      }),
      mergeMap((actions: Action[]) => {
        return actions;
      })
    );
  });

  constructor(
    private actions$: Actions,
    private chatService: ChatService,
    private authService: AuthService
  ) {}
}
