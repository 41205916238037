import { Injectable } from '@angular/core';
import {
  EmailNotificationsTemplate,
  EmailNotificationTemplate
} from '@breez/models/notification/email-notification-template.model';
import { Observable, of } from 'rxjs';
import { WebsocketEvents } from '@breez/modules/websocket/websocket.events';
import { WebsocketService } from '@breez/modules/websocket/websocket.service';
import { catchError, filter, map, mapTo, pluck, switchMap, take } from 'rxjs/operators';
import { SettingsService } from '@breez/modules/settings/services/settings.service';
import { isTruthy } from '@breez/shared/utilities/is-truthy';

@Injectable({
  providedIn: 'root'
})
export class EmailNotificationsService {
  mailerEnabled$: Observable<boolean> = this.settingsService.getValue('integration', 'mailer_enabled').pipe(
    take(1),
    pluck('value'),
    map(mailer => {
      if (!mailer) {
        return undefined;
      }
      try {
        return JSON.parse(mailer);
      } catch {
        return undefined;
      }
    }),
    filter(isTruthy),
    map(mailer => {
      return !!mailer;
    })
  );

  constructor(
    private wsService: WebsocketService,
    private settingsService: SettingsService
  ) {}

  sendEmailTemplate(data: EmailNotificationTemplate | EmailNotificationsTemplate): Observable<boolean> {
    return this.mailerEnabled$.pipe(
      switchMap(mailerEnabled => {
        return mailerEnabled
          ? this.wsService.send(WebsocketEvents.SEND.NOTIFICATION.SEND_BY_TEMPLATE, { data }).pipe(
              take(1),
              mapTo(true),
              catchError(() => {
                return of(false);
              })
            )
          : of(false);
      })
    );
  }
}
