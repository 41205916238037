import { Injectable } from '@angular/core';
import { WebsocketEvents, WebsocketService } from '@breez/modules/websocket';
import { Observable } from 'rxjs';
import { Link } from '@breez/models/link.model';
import { arrToClass, replayWhileSubs } from '@breez/shared/rxjs-operators';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LinksService {
  links$: Observable<Link[]> = this.wsService
    .send(WebsocketEvents.RECEIVE.ACCOUNT.GET_LINKS)
    .pipe(arrToClass(Link), take(1), replayWhileSubs());

  constructor(private wsService: WebsocketService) {}

  getLinks(): Observable<Link[]> {
    return this.links$;
  }
}
