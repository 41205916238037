import { Injectable } from '@angular/core';
import { Contact } from '@breez/modules/social/models/contact.model';
import { Observable } from 'rxjs';
import { UserGroupFormDialogComponent } from '@breez/modules/social/components/user-group-form-dialog/user-group-form-dialog.component';
import { UserGroupEditingRequest } from '@breez/modules/social/models/user-group-editing-request.model';
import { take } from 'rxjs/operators';
import { Overlay } from '@angular/cdk/overlay';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class UserGroupDialogService {
  constructor(
    private overlay: Overlay,
    private dialog: MatDialog
  ) {}

  createUserGroupDialog$(creating = true, name = '', contacts = <Contact[]>[]): Observable<UserGroupEditingRequest> {
    const dialogRef = this.dialog.open<UserGroupFormDialogComponent, UserGroupEditingRequest, UserGroupEditingRequest>(
      UserGroupFormDialogComponent,
      {
        width: '400px',
        scrollStrategy: this.overlay.scrollStrategies.reposition(),
        data: {
          name,
          contacts,
          isNew: creating
        }
      }
    );

    return dialogRef.afterClosed().pipe(take(1));
  }
}
