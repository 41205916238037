<button
  *ngIf="canInviteGuest$ | async"
  vks-button
  [class.btn-icon-only]="isMobileDevice$ | async"
  [label]="hideableLabel && (isMobileDevice$ | async) ? '' : ('BREEZ_INVITING' | translate)"
  (buttonClick)="guestInviting()"
  class="guest-inviting btn-anchor btn-unfocused"
  icon="guest-invite"
  iconClass="square18"
></button>
