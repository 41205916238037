import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Contact, UserGroup } from '@breez/modules/social/models';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { auditTime, delay, filter, map, shareReplay, switchMap, take } from 'rxjs/operators';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { SocialService } from '@breez/modules/social/services';
import { UserStatus } from '@breez/modules/user/modules/models/user-status.enum';
import { replayWhileSubs } from '@breez/shared/rxjs-operators';
import { EmitOnChange } from '@breez/shared/utilities/decorators/emit-on-change.decorator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserCardData } from '@breez/modules/social/services/user-card.service';

@UntilDestroy()
@Component({
  selector: 'vks-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactCardComponent implements OnInit, OnChanges {
  @Input() data: UserCardData;
  @Input() displayHeader = true;

  @Output() closePanel = new EventEmitter<void>();

  private _userId$: ReplaySubject<number> = new ReplaySubject<number>();
  userId$: Observable<number> = this._userId$.pipe(filter(isTruthy), shareReplay(1));

  isMouseIn$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  @EmitOnChange('data')
  data$ = new ReplaySubject<UserCardData>(1);

  contact$: Observable<Contact> = this.userId$.pipe(
    switchMap(userId => {
      return this.socialService.getContact(userId);
    }),
    replayWhileSubs()
  );

  state$: Observable<UserStatus> = this.contact$.pipe(
    map(contact => {
      return contact.state ?? null;
    })
  );

  isOnline$: Observable<boolean> = this.contact$.pipe(
    map(contact => {
      return contact.state !== UserStatus.OFFLINE;
    })
  );

  lastAccess$: Observable<Date> = this.contact$.pipe(
    map(contact => {
      return contact.lastAccess ?? null;
    })
  );

  @HostBinding('class.mobile')
  isMobileDevices: boolean;

  private _selectedUserGroup$: ReplaySubject<UserGroup> = new ReplaySubject<UserGroup>();
  selectedUserGroup$: Observable<UserGroup> = this._selectedUserGroup$.pipe(filter(isTruthy), shareReplay(1));

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public matDialogData: { userId: number; selectedUserGroup: UserGroup; isMobileDevices: boolean },
    @Optional() private readonly dialogRef: MatDialogRef<ContactCardComponent>,
    private socialService: SocialService
  ) {
    if (matDialogData) {
      this.setData(matDialogData);
    }
  }

  @HostListener('mouseleave')
  mouseOut(): void {
    this.isMouseIn$.pipe(auditTime(1500), take(1)).subscribe(isMouseIn => {
      if (!isMouseIn) {
        this.closePanel.emit();
      }
    });
    this.isMouseIn$.next(false);
  }

  @HostListener('mouseover')
  mouseIn(): void {
    this.isMouseIn$.next(true);
  }

  @HostListener('document:click', ['$event'])
  checkClick(): void {
    if (!this.isMouseIn$.value) {
      this.closePanel.emit();
    }
  }

  setData(data: UserCardData): void {
    this._userId$.next(data.userId);
    this._selectedUserGroup$.next(data.selectedUserGroup);
    this.isMobileDevices = data.isMobileDevices;
  }

  ngOnInit(): void {
    this.data$.pipe(filter(isTruthy), untilDestroyed(this)).subscribe(data => {
      this.setData(data);
    });
    of(null)
      .pipe(delay(5000))
      .subscribe(() => {
        if (!this.isMouseIn$.value && !this.isMobileDevices) {
          this.mouseOut();
        }
      });
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnChanges(_: SimpleChanges): void {}

  close(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    } else {
      this.closePanel.emit();
    }
  }
}
