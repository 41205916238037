import { animate, state, style, transition, trigger } from '@angular/animations';

export const COMPONENT_ANIMATIONS = [
  trigger('nextRows', [
    transition('void => *', [style({ height: '*', opacity: 0 }), animate('225ms', style({ height: '*', opacity: 1 }))])
  ]),
  trigger('expandRow', [
    state('collapsed', style({ height: '0px', minHeight: '0', overflow: 'hidden' })),
    state('expanded', style({ height: '*' })),
    transition('expanded <=> collapsed', [animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')])
  ])
];
