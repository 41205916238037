import { Component, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'vks-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconButtonComponent {
  @Input() enabled = true;
  @Input() spinnerDiameter = 16;

  @HostBinding('class.clickable')
  @Input()
  enabledClick = true;

  @HostBinding('class.fetching')
  @Input()
  fetching = false;

  @HostBinding('class.disabled')
  get isEnabled(): boolean {
    return !this.enabled;
  }
}
