import { merge, Observable } from 'rxjs';
import { map, mapTo, scan, startWith } from 'rxjs/operators';

export function indicate(request: Observable<any>, response: Observable<any>): Observable<boolean> {
  return merge(request.pipe(mapTo(1)), response.pipe(mapTo(-1))).pipe(
    scan((counter, term) => {
      return Math.abs(counter + term) > 1 ? counter : counter + term;
    }, 0),
    startWith(0),
    map(counter => {
      return counter > 0;
    })
  );
}
