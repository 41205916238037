import { LocationStrategy } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { ConferenceEvent, ConferenceParticipant, LayoutEvent } from '@breez/models';
import { BanRequest } from '@breez/models/conference/ban-request.model';
import { ConferenceResolution } from '@breez/models/conference/conference-resolution.model';
import { ConferenceStatusEvent } from '@breez/models/conference/conference-status-event.model';
import { Conference } from '@breez/models/conference/conference.model';
import { ObjectListRequest } from '@breez/models/shared/paging';
import { ConferenceStatusEventType } from '@breez/models/conference/enums/conference-status-event-type.enum';
import { FilterItem, FilterItemOption } from '@breez/models/filters/filter-item';
import { Participant } from '@breez/models/shared/participant/participant.model';
import { Locale } from '@breez/models/template/conference-template-info.model';
import { AuthService } from '@breez/modules/auth/services/auth.service';
import { makeDTOFromUser } from '@breez/modules/conference/modules/planner/services/conference-planner-makeDTOFromParticipant/conference-planner-makeDTOFromParticipant';
import { Chat } from '@breez/modules/chat';
import { WebsocketEvents, WebsocketService } from '@breez/modules/websocket';
import { FilterTypes } from '@breez/shared/enums/filter-types.enum';
import { Event } from '@breez/shared/models/event.model';
import { NestedFieldsPipe } from '@breez/shared/pipes/nested-field-pipe/nested-fields.pipe';
import { arrToClass, replayWhileSubs, toClass } from '@breez/shared/rxjs-operators';
import { sort } from '@breez/shared/rxjs-operators/sort';
import { waitFor } from '@breez/shared/rxjs-operators/wait-for';
import { EmailNotificationsService } from '@breez/shared/services/email-notifications.service';
import { FiltersService } from '@breez/shared/services/filters/filters.service';
import { deleteUndefined } from '@breez/shared/utilities/delete-undefined';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { TranslateService } from '@ngx-translate/core';
import { ConferenceUserRole } from '@breez/models/conference-user-role.enum';
import { createEnumChecker, getEnumByValue } from '@breez/shared/utilities/enum';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of, ReplaySubject, Subject } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  mapTo,
  scan,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { ConferenceListResponse } from '@breez/models/conference/conference-list-response.model';
import { ObjectType } from '@breez/shared/enums/object-type.enum';
import { base64ToArrayBuffer } from '@breez/shared/utilities/base64ToBytes';
import { AppService } from '@breez/app.service';
import { ElectronService } from '@breez/modules/core/services';
import { environment } from '@breez/environment';
import { BuildType } from '@breez/shared/enums/build-type.enum';
import {
  InviteParticipantParams,
  InviteParticipantRequest,
  InviteParticipantRequestData,
  InviteParticipantsModeEnum
} from '@breez/shared/models/invite-participants.model';
import { plainToInstance } from 'class-transformer';
import { ELECTRON_CHANNEL_LIST } from '../../../../electron-channel-list';

export interface ConferenceLinks {
  reportUrl: string;
  recordUrl: string;
  editUrl: string;
  infoUrl: string;
  summaryUrl: string;
  conferenceUrl: string;
  absoluteUrl: string;
}

@Injectable({
  providedIn: 'root'
})
export class ConferencesService {
  private approvedConferencesSubject$: ReplaySubject<Array<string | number>> = new ReplaySubject<number[]>(1);

  approvedConferences$: Observable<Array<string | number>> = this.approvedConferencesSubject$.pipe(
    startWith([]),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  approveConference(conferenceId: number | string): void {
    this.approvedConferences$.pipe(take(1)).subscribe(approvedConferences => {
      if (isTruthy(conferenceId) && !approvedConferences.includes(conferenceId)) {
        approvedConferences = [...approvedConferences, conferenceId];
        this.approvedConferencesSubject$.next(approvedConferences);
      }
    });
  }

  removeApproveConference(conferenceId: number): void {
    this.approvedConferences$.pipe(take(1)).subscribe(approvedConferences => {
      if (isTruthy(conferenceId) && approvedConferences.includes(conferenceId)) {
        this.approvedConferencesSubject$.next(
          approvedConferences.filter(_conferenceId => {
            return _conferenceId !== conferenceId;
          })
        );
      }
    });
  }

  filters$: Observable<FilterItem[]> = this.filtersService.filterValues$.pipe(
    map(filterValues => {
      return filterValues.status;
    }),
    distinctUntilChanged(),
    withLatestFrom(this.filtersService.filters$),
    map(([conferencesStatus, filters]) => {
      const periodFilter = filters.find(_filter => {
        return _filter.paramName === 'period';
      });
      if (conferencesStatus === 'active') {
        periodFilter.isHidden = true;
      } else {
        periodFilter.isHidden = false;
      }
      return filters;
    }),
    sort('order', true),
    shareReplay(1)
  );

  conferenceResolutionByRole$: Observable<ConferenceResolution[]> = this.authService.roles$.pipe(
    map(roles => {
      return this.getConferenceResolutions(true).filter(resolution => {
        return (
          resolution.size.height * resolution.size.width <= 589824 || // <='576p'
          roles.some(role => {
            return role === `planner:confparam:${resolution.key}`;
          })
        );
      });
    }),
    distinctUntilChanged(),
    shareReplay(1)
  );

  filterValues$: Observable<Params> = this.filtersService.filterValues$.pipe(shareReplay(1));

  conferenceCountPerPageSubject: Subject<number> = new BehaviorSubject(10);
  readonly isFnsBuild = environment.buildType === BuildType.FNS;
  private conferenceChats$: ReplaySubject<Chat[]> = new ReplaySubject<Chat[]>(1);

  private autoLayoutSubject$ = new Subject<boolean>();

  changeAutoLayout(value: boolean): void {
    this.autoLayoutSubject$.next(value);
  }

  autoLayout$ = this.autoLayoutSubject$.asObservable().pipe(distinctUntilChanged());

  constructor(
    private appService: AppService,
    private wsService: WebsocketService,
    private authService: AuthService,
    private emailNotificationsService: EmailNotificationsService,
    private filtersService: FiltersService,
    private nestedFieldsPipe: NestedFieldsPipe,
    private translateService: TranslateService,
    private readonly locationStrategy: LocationStrategy,
    private electronService: ElectronService,
    @Inject('ORIGIN') private origin: string
  ) {}

  private static filterDataByPeriod(data: Conference[], startDate: Date, endDate: Date): Conference[] {
    return data.filter(conference => {
      let comparisonValue = true;
      if (startDate) {
        comparisonValue = conference.startingAt >= startDate;
      }
      if (endDate) {
        comparisonValue = comparisonValue && conference.startingAt <= endDate;
      }
      return comparisonValue;
    });
  }

  getConferenceResolutions(onlySelectable: boolean): ConferenceResolution[] {
    return [
      {
        value: 0,
        title: 'CIF',
        key: 'cif',
        ratio: 4 / 3,
        size: { width: 352, height: 288 },
        description: {
          ru: 'CIF',
          en: 'CIF'
        },
        selectable: false
      },
      {
        value: 1,
        title: '4CIF',
        key: '4cif',
        ratio: 4 / 3,
        size: { width: 704, height: 576 },
        description: {
          ru: '4CIF',
          en: '4CIF'
        },
        selectable: false
      },
      {
        value: 2,
        title: 'VGA',
        key: 'vga',
        ratio: 4 / 3,
        size: { width: 640, height: 480 },
        description: {
          ru: 'Низкое качество',
          en: 'Low video resolution'
        },
        selectable: false
      },
      {
        value: 3,
        title: 'SVGA',
        key: 'svga',
        ratio: 4 / 3,
        size: { width: 800, height: 600 },
        description: {
          ru: 'Низкое качество',
          en: 'Low video resolution'
        },
        selectable: false
      },
      {
        value: 4,
        title: '576p',
        key: '576p',
        ratio: 16 / 9,
        isDefault: true,
        size: { width: 1024, height: 576 },
        description: {
          ru: 'Стандартное качество',
          en: 'Regular video resolution'
        },
        selectable: false
      },
      {
        value: 5,
        title: '720p',
        key: '720p',
        ratio: 16 / 9,
        size: { width: 1280, height: 720 },
        description: {
          ru: 'Высокой четкости',
          en: 'High video resolution'
        },
        selectable: true
      },
      {
        value: 6,
        title: '1080p',
        key: '1080p',
        ratio: 16 / 9,
        size: { width: 1920, height: 1080 },
        description: {
          ru: 'FullHD',
          en: 'FullHD'
        },
        selectable: true
      },
      {
        value: 7,
        title: '360p',
        key: '360p',
        ratio: 16 / 9,
        size: { width: 640, height: 360 },
        description: {
          ru: 'Низкое качество',
          en: 'Low video resolution'
        },
        selectable: true
      },
      {
        value: 8,
        title: '480p',
        key: '480p',
        ratio: 16 / 9,
        size: { width: 854, height: 480 },
        description: {
          ru: 'Стандартное качество',
          en: 'Standard video resolution'
        },
        selectable: true
      },
      {
        value: 9,
        title: '540p',
        key: '540p',
        ratio: 16 / 9,
        size: { width: 960, height: 540 },
        description: {
          ru: 'Высокое качество',
          en: 'High video resolution'
        },
        selectable: true
      }
    ]
      .filter(resolution => {
        return !onlySelectable || resolution.selectable;
      })
      .sort((a, b) => {
        return a.size.height > b.size.height ? 1 : -1;
      });
  }

  setRecordingState(conferenceId: number, recording: boolean): Observable<any> {
    return this.wsService
      .send(WebsocketEvents.SEND.CONFERENCE.RECORD, {
        data: {
          conferenceid: conferenceId,
          enable: recording
        }
      })
      .pipe(
        catchError(err => {
          return of(err);
        })
      );
  }

  getConferenceResolutionDescriptionById(id: string): Locale {
    const resolution = this.getConferenceResolutions(false).find(res => {
      return res.value === parseInt(id, 10);
    });

    return (resolution || { description: { ru: '', en: '' } }).description;
  }

  getResolutionById(id: number): ConferenceResolution {
    if (typeof id === 'string') {
      return this.getResolutionByKey(id);
    }
    const conferenceResolution = this.getConferenceResolutions(false).find(resolution => {
      return resolution.value === id;
    });
    if (!conferenceResolution) {
      return null;
    }
    return conferenceResolution;
  }

  getResolutionIdByKey(key: string | number): number {
    const conferenceResolution =
      typeof key === 'string' ? this.getResolutionByKey(<string>key) : this.getResolutionById(<number>key);

    if (!conferenceResolution) {
      return null;
    }
    return conferenceResolution.value;
  }

  getResolutionByKey(key: string): ConferenceResolution {
    const conferenceResolution = this.getConferenceResolutions(false).find(resolution => {
      return resolution.key === key;
    });
    if (!conferenceResolution) {
      return null;
    }
    return conferenceResolution;
  }

  generateLinksForConference(conference: Conference | Partial<Conference>): ConferenceLinks {
    const conferenceId = conference.alias || conference.id;
    return {
      reportUrl: conferenceId ? `/conference/${conferenceId}` : '',
      recordUrl: conferenceId && conference.recordUrl ? conference.recordUrl : '',
      infoUrl: conferenceId ? `/conference/${conferenceId}/info` : '',
      editUrl: conferenceId ? `/conference/${conferenceId}/edit` : '',
      summaryUrl: conference.id ? `/conference/${conference.id}/summary` : '',
      conferenceUrl: conferenceId ? `/conference/${conferenceId}` : '',
      absoluteUrl: conference.url ? `${conference.url}` : ''
    };
  }

  removeLastPhraseEntry(value: string, phrase: string): string {
    const lastIndex = value.lastIndexOf(phrase);
    return value.substring(0, lastIndex);
  }

  findFilterItem(paramName: string, filterItems: FilterItem[]): FilterItem {
    const rangeStartName = this.removeLastPhraseEntry(paramName, '_start');
    const rangeEndName = this.removeLastPhraseEntry(paramName, '_end');
    return filterItems.find(item => {
      return item.paramName === paramName || item.paramName === rangeStartName || item.paramName === rangeEndName;
    });
  }

  // TODO перенести сервис туда, где ему место

  // TODO продумать архитектуру

  applyFilters(confList: Conference[], filtersValues: Params, filterItems: FilterItem[]): Conference[] {
    const allParams: string[] = Object.keys(filtersValues);
    let result: Conference[] = confList;
    allParams.forEach(paramName => {
      const filterItem = this.findFilterItem(paramName, filterItems);
      if (filterItem) {
        const selectedOption = filterItem.options.find(option => {
          return option.key === filtersValues[paramName];
        });
        switch (+FilterTypes[filterItem.type]) {
          case FilterTypes.SELECT_OPTION_FILTER:
            result = this.filtersService.applySelectOptionFilter(filterItem, selectedOption, result);
            break;
          case FilterTypes.DURATION_FILTER:
            result = this.applyDurationFilter(selectedOption, result);
            break;
          default:
            break;
        }
      }
    });
    return result;
  }

  getConferencesList(request: ObjectListRequest): Observable<ConferenceListResponse> {
    return this.wsService
      .send(WebsocketEvents.RECEIVE.CONFERENCE.LIST.ALL, {
        data: request
      })
      .pipe(
        toClass(ConferenceListResponse),
        map(response => {
          response.conferences.forEach(conference => {
            const conferenceUrls: ConferenceLinks = this.generateLinksForConference(conference);
            conference.reportUrl = conferenceUrls.reportUrl;
            conference.recordUrl = conferenceUrls.recordUrl;
          });
          return response;
        }),
        shareReplay(1)
      );
  }

  hasConference(conferenceId: number): Observable<{ activeSessions: string[]; canEnter: boolean }> {
    return this.wsService
      .send<any>(WebsocketEvents.RECEIVE.ACCOUNT.HAS_CONFERENCE, {
        data: {
          conferenceid: conferenceId
        }
      })
      .pipe(
        take(1),
        map(data => {
          return {
            activeSessions: data.activesessions,
            canEnter: data.canenter
          };
        }),
        catchError(() => {
          return of({
            activeSessions: [],
            canEnter: true
          });
        })
      );
  }

  createConference(conference: Partial<Conference>, addExt?: boolean): Observable<number> {
    if (addExt) {
      conference.ext = 'create';
    }
    return this.wsService.send(WebsocketEvents.SEND.CONFERENCE.CREATE, { data: conference }).pipe(
      catchError(() => {
        return of(null);
      }),
      map(data => {
        return data ? data.id : null;
      })
    );
  }

  checkConferenceExists(id: string | number): Observable<boolean> {
    return this.wsService.query<boolean>(WebsocketEvents.SEND.CONFERENCE.EXISTS, { id }).pipe(
      catchError(() => {
        return of(false);
      })
    );
  }

  inviteParticipants(conferenceId: number, params: InviteParticipantParams): Observable<number[]> {
    if (!isTruthy(params) || !isTruthy(params?.mode) || !isTruthy(conferenceId)) return;
    let requestParams: InviteParticipantRequestData;
    switch (params.mode) {
      case InviteParticipantsModeEnum.IDS:
        const participantIds = params?.participantIds;
        if (isTruthy(participantIds) && participantIds.length > 0) {
          requestParams = plainToInstance(InviteParticipantRequest, {
            conferenceId,
            participantIds
          });
        }
        break;
      case InviteParticipantsModeEnum.TYPES:
        const participantTypes = params?.participantTypes.filter(type => {
          return type != ConferenceUserRole.NONE;
        });
        if (isTruthy(participantTypes) && participantTypes.length > 0) {
          requestParams = plainToInstance(InviteParticipantRequest, {
            conferenceId,
            participantTypes
          });
        }
        break;
      case InviteParticipantsModeEnum.STATES:
        const participantStates = params?.participantStates;
        if (isTruthy(participantStates) && participantStates.length > 0) {
          requestParams = plainToInstance(InviteParticipantRequest, {
            conferenceId,
            participantStates
          });
        }
        break;
    }

    return this.wsService
      .send<number[]>(WebsocketEvents.SEND.CONFERENCE.INVITE_PARTICIPANTS, { data: requestParams })
      .pipe(
        take(1),
        catchError(() => {
          return of(null);
        })
      );
  }

  getAccessInfo(id: string | number): Observable<ConferenceUserRole[]> {
    return this.wsService.send(WebsocketEvents.SEND.CONFERENCE.GET_ACCESS_INFO, { id }).pipe(
      map(access => {
        const isConferenceUserRole = createEnumChecker(ConferenceUserRole);
        return access
          .filter(item => {
            return isConferenceUserRole(item);
          })
          .map(item => {
            return getEnumByValue(ConferenceUserRole, item);
          });
      }),
      catchError(() => {
        return of(<ConferenceUserRole[]>[]);
      })
    );
  }

  getConferenceId(id: string | number): Observable<number> {
    return of(id).pipe(
      map(conferenceId => {
        return conferenceId || 0;
      }),
      switchMap(conferenceId => {
        return this.getConferenceIdByAlias(<string>conferenceId);
      }),
      map(conferenceId => {
        return !!conferenceId && conferenceId > 0 ? conferenceId : null;
      })
    );
  }

  getConference(id: string | number, forEdit: boolean = false): Observable<Conference> {
    return this.getConferenceId(id).pipe(
      switchMap((conferenceId: number) => {
        return !!conferenceId ? this.getConferenceById(conferenceId, { forEdit }) : of(null);
      })
    );
  }

  observeConferenceByConferenceId(conferenceId: string | number): Observable<Conference> {
    return this.observeConferenceEventsByConferenceId(conferenceId).pipe(
      scan(
        (accumulator, event) => {
          switch (event.type) {
            case ConferenceStatusEventType.INIT:
              return event.data;
            case ConferenceStatusEventType.UPDATE:
              return Object.assign(new Conference(), accumulator, deleteUndefined(event.data));
            default:
              return accumulator;
          }
        },
        <Conference>new Conference()
      ),
      distinctUntilChanged()
    );
  }

  observeConferenceEventsByConferenceId(conferenceId: string | number): Observable<ConferenceStatusEvent> {
    return this.wsService
      .observe(
        WebsocketEvents.SEND.CONFERENCE.STATUS.OBSERVE,
        { parent: { resource: ObjectType.CONFERENCE, id: conferenceId } },
        { responseFrom: { path: WebsocketEvents.RECEIVE.CONFERENCE.STATUS } }
      )
      .pipe(
        toClass(ConferenceStatusEvent),
        finalize(() => {
          return this.wsService.query(
            WebsocketEvents.SEND.CONFERENCE.STATUS.LEAVE,
            { parent: { resource: ObjectType.CONFERENCE, id: conferenceId } },
            { sendImmediately: true }
          );
        })
      );
  }

  conferenceEvents(confId: number): Observable<Conference> {
    return this.wsService
      .send(WebsocketEvents.SEND.CONFERENCE.STATUS.OBSERVE, {
        parent: {
          resource: ObjectType.CONFERENCE,
          id: confId
        }
      })
      .pipe(
        switchMap(() => {
          return this.wsService.on<Event<Conference>>(WebsocketEvents.RECEIVE.CONFERENCE.STATUS);
        }),
        map(({ data }) => {
          return data;
        }),
        finalize(() => {
          return this.unsubscribeConferenceEvents(confId).pipe(take(1)).subscribe();
        })
      );
  }

  unsubscribeConferenceEvents(conferenceId: number): Observable<boolean> {
    return this.wsService
      .send(WebsocketEvents.SEND.CONFERENCE.STATUS.LEAVE, {
        parent: {
          resource: ObjectType.CONFERENCE,
          id: conferenceId
        }
      })
      .pipe(
        catchError(() => {
          return of(false);
        }),
        mapTo(true),
        take(1)
      );
  }

  conferenceUsersEvents(confId: number): Observable<ConferenceParticipant[]> {
    return this.wsService
      .send(WebsocketEvents.SEND.CONFERENCE.USER.OBSERVE, {
        parent: {
          resource: ObjectType.CONFERENCE,
          id: confId
        }
      })
      .pipe(
        take(1),
        switchMap(() => {
          return this.wsService.listen<any[]>({ path: WebsocketEvents.RECEIVE.CONFERENCE.USER.OBSERVE });
        }),
        map(({ data }) => {
          return data;
        }),
        arrToClass(ConferenceParticipant),
        filter(data => {
          return Array.isArray(data);
        })
      );
  }

  unsubscribeConferenceUsersEvents(conferenceId: number): Observable<boolean> {
    return this.wsService
      .send(WebsocketEvents.SEND.CONFERENCE.USER.LEAVE, {
        parent: {
          resource: ObjectType.CONFERENCE,
          id: conferenceId
        }
      })
      .pipe(
        catchError(() => {
          return of(false);
        }),
        mapTo(true),
        take(1)
      );
  }

  getParticipantsInfo(conferenceId: number): Observable<Participant[]>;
  /**
   * @deprecated Should be used __getParticipantInfo__
   */
  // tslint:disable-next-line:unified-signatures
  getParticipantsInfo(conferenceId: number, userId: number): Observable<Participant[]>;
  getParticipantsInfo(conferenceId: number, userId?: number): Observable<Participant[]> {
    return this.wsService
      .send<any>(WebsocketEvents.SEND.CONFERENCE.PARTICIPANT_INFO, { data: { conferenceId, userId } })
      .pipe(
        take(1),
        map(data => {
          return data.participants;
        }),
        arrToClass(Participant)
      );
  }

  getParticipantInfo(conferenceId: number, userId: number): Observable<Participant> {
    return this.wsService
      .send<any>(WebsocketEvents.SEND.CONFERENCE.PARTICIPANT_INFO, { data: { conferenceId, userId } })
      .pipe(
        take(1),
        map(data => {
          return data.participants || [];
        }),
        map(participants => {
          return participants[0] || null;
        }),
        toClass(Participant)
      );
  }

  getParticipantSessionInfo(clientId: string, conferenceId: number): Observable<any> {
    return this.wsService
      .send(WebsocketEvents.RECEIVE.CONFERENCE.PARTICIPANT_SESSION_INFO, {
        data: {
          clientid: clientId,
          conferenceid: conferenceId
        }
      })
      .pipe(take(1));
  }

  updateConferenceUser(conferenceId: number, userId: number, fields: any): Observable<any> {
    return this.wsService
      .send(WebsocketEvents.SEND.USER.UPDATE, {
        id: userId,
        data: {
          ...fields
        },
        parent: {
          resource: ObjectType.CONFERENCE,
          id: conferenceId
        }
      })
      .pipe(take(1));
  }

  changeConferenceLayout(conferenceId: number, layoutId: number): Observable<any> {
    return this.wsService
      .send(WebsocketEvents.SEND.CONFERENCE.LAYOUT.CHANGE, {
        data: {
          conferenceId,
          layoutId
        }
      })
      .pipe(take(1));
  }

  editConference(
    conferenceId: number,
    data: Partial<Conference>,
    addExt?: boolean
  ): Observable<{ status: number; id: number }> {
    if (addExt) {
      data.ext = 'update';
    }
    // TODO: убрать...
    if (data.hasOwnProperty('conferenceRoomId')) {
      (data as any).conferenceroomid = data.conferenceRoomId;
    }
    return this.wsService
      .send(WebsocketEvents.SEND.CONFERENCE.UPDATE, {
        id: conferenceId,
        data
      })
      .pipe(
        take(1),
        catchError(() => {
          return of(null);
        })
      );
  }

  removeParticipant(conferenceId: number, userId: number): Observable<any> {
    return this.wsService.send(WebsocketEvents.SEND.CONFERENCE.USER.DELETE, {
      data: {
        parent: {
          resource: ObjectType.CONFERENCE,
          id: conferenceId
        }
      },
      id: userId
    });
  }

  removeParticipants(conferenceId: number, usersId: number[]): Observable<any> {
    return this.wsService.send(WebsocketEvents.SEND.CONFERENCE.REMOVE_PARTICIPANTS, {
      data: {
        participantids: usersId,
        conferenceid: conferenceId
      }
    });
  }

  getConferenceSchedule(conferenceId: number): Observable<ConferenceEvent[]> {
    return this.wsService
      .send(WebsocketEvents.SEND.CONFERENCE.SCHEDULE.GET, {
        parent: {
          resource: ObjectType.CONFERENCE,
          id: conferenceId
        }
      })
      .pipe(
        take(1),
        arrToClass(ConferenceEvent),
        catchError(() => {
          return of(null);
        })
      );
  }

  registerByEmail(email: string, conferenceId: number): Observable<boolean> {
    return this.wsService
      .send<boolean>(WebsocketEvents.SEND.CONFERENCE.REGISTER_BY_EMAIL, {
        data: {
          address: email,
          conferenceId
        }
      })
      .pipe(
        take(1),
        catchError(() => {
          return of(false);
        })
      );
  }

  register(conferenceId: number, sendEmail = true): Observable<any> {
    return this.wsService.send<any>(WebsocketEvents.SEND.CONFERENCE.REGISTER, { data: { conferenceId } }).pipe(
      waitFor(() => {
        return this.authService.currentUser$.pipe(
          take(1),
          switchMap(user => {
            if (sendEmail) {
              return this.emailNotificationsService.sendEmailTemplate({
                group: 'conference-webinar',
                name: 'registration',
                data: { conferenceId },
                calendar: { conferenceId },
                address: user.email
              });
            }
            return of(EMPTY);
          })
        );
      }),
      take(1)
    );
  }

  enterConference(conferenceId: number): Observable<void> {
    return this.wsService
      .send(WebsocketEvents.SEND.CONFERENCE.ENTER, {
        data: {
          conferenceid: conferenceId
        }
      })
      .pipe(take(1));
  }

  leaveConference(conferenceId: number): Observable<void> {
    return this.wsService.send(WebsocketEvents.SEND.CONFERENCE.LEAVE, {
      data: {
        conferenceid: conferenceId
      }
    });
  }

  startConference(id: number): Observable<boolean> {
    return this.wsService.send(WebsocketEvents.SEND.CONFERENCE.START, { id }).pipe(
      catchError(() => {
        return of(false);
      })
    );
  }

  finishConference(conferenceId: number, addExt?: boolean): Observable<boolean> {
    const requestObject = addExt
      ? {
          id: conferenceId,
          data: {
            ext: 'cancel'
          }
        }
      : {
          id: conferenceId
        };
    return this.wsService.send(WebsocketEvents.SEND.CONFERENCE.DELETE, requestObject);
  }

  addParticipant(conferenceId: number, userId: number): Observable<any> {
    return this.wsService
      .send(WebsocketEvents.SEND.CONFERENCE.USER.ADD, {
        data: {
          parent: {
            resource: ObjectType.CONFERENCE,
            id: conferenceId
          },
          force: true
        },
        id: userId
      })
      .pipe(
        take(1),
        catchError(() => {
          return of(true);
        })
      );
  }

  addParticipantById(conferenceId: number, userId: number): Observable<any> {
    return this.wsService
      .send(WebsocketEvents.SEND.CONFERENCE.USER.ADD_BY_ID, {
        data: {
          userid: userId
        },
        id: conferenceId
      })
      .pipe(
        take(1),
        catchError(() => {
          return of(true);
        })
      );
  }

  getConferenceIdByAlias(alias: string, check = false): Observable<number> {
    if (!alias) {
      return of(null);
    }

    return this.wsService.send(WebsocketEvents.SEND.CONFERENCE.GET_ID_BY_ALIAS, { data: { alias } }).pipe(
      take(1),
      map(data => {
        return isTruthy(data) && isTruthy(data?.id) ? data?.id : check ? data?.id : Number(alias);
      })
    );
  }

  public fetchCalendarFile(conferenceId: number, token?: string): Observable<string> {
    return this.wsService
      .send<{ blob: string }>(WebsocketEvents.RECEIVE.CONFERENCE.CALENDAR, {
        data: {
          token,
          conferenceId
        }
      })
      .pipe(
        take(1),
        map(data => {
          return !!data && !!data.blob ? data.blob.toString() : data.toString();
        })
      );
  }

  public banUser(conferenceId: number, userId: number, reason: string): Observable<boolean> {
    return this.wsService
      .send<any>(WebsocketEvents.SEND.CONFERENCE.BAN, {
        data: {
          userid: userId,
          conferenceId: conferenceId,
          conferenceBanReason: reason
        }
      })
      .pipe(
        map(() => {
          return true;
        }),
        catchError(() => {
          return of(false);
        })
      );
  }

  public changeVoiceActivate(data: { conferenceId: number; frame: number }): Observable<boolean> {
    return this.wsService
      .send<any>(WebsocketEvents.SEND.CONFERENCE.CHANGE_VOICE_ACTIVATE, {
        data
      })
      .pipe(
        take(1),
        map(() => {
          return true;
        }),
        catchError(() => {
          return of(false);
        })
      );
  }

  public layoutEvent(conferenceId: number, event = 'change'): Observable<LayoutEvent> {
    return this.wsService.listen({ path: WebsocketEvents.RECEIVE.CONFERENCE.LAYOUT }).pipe(
      filter(isTruthy),
      map(({ data }) => {
        return data;
      }),
      filter(message => {
        return message.event === event;
      }),
      map(({ data }) => {
        return data;
      }),
      toClass(LayoutEvent),
      filter((data: any) => {
        return data.conferenceId === conferenceId;
      })
    );
  }

  public banned(conferenceId: number): Observable<BanRequest> {
    return this.wsService.on(WebsocketEvents.RECEIVE.CONFERENCE.BANNED).pipe(
      filter(isTruthy),
      filter((data: any) => {
        return data.conferenceId === conferenceId;
      }),
      map((data: any) => {
        return <BanRequest>{
          banned: data.conferenceBanned,
          conferenceId: data.conferenceId,
          reason: data.conferenceBanReason
        };
      })
    );
  }

  public leaveConferenceEvent(conferenceId: number): Observable<string> {
    return this.wsService.on(WebsocketEvents.RECEIVE.CONFERENCE.LEAVE).pipe(
      filter(isTruthy),
      filter((data: any) => {
        return data.conferenceid === conferenceId;
      }),
      map((data: any) => {
        return data.reason;
      })
    );
  }

  public requestForSpeech(conferenceId: number, clientid?: string, message?: string): Observable<boolean> {
    return this.wsService
      .send(WebsocketEvents.SEND.CONFERENCE.SPEECH.REQUEST, {
        data: {
          message,
          conferenceId,
          clientid
        }
      })
      .pipe(
        mapTo(true),
        catchError(() => {
          return of(false);
        })
      );
  }

  public cancelRequestForSpeech(conferenceId: number, clientid: string): Observable<boolean> {
    return this.wsService
      .send(WebsocketEvents.SEND.CONFERENCE.SPEECH.CANCEL, {
        data: {
          conferenceId,
          clientid
        }
      })
      .pipe(
        mapTo(true),
        catchError(() => {
          return of(false);
        })
      );
  }

  getBanReasonSuggestions(): Observable<any[]> {
    return this.translateService.get(['FLOOD', 'ADS', 'PROFANITY']).pipe(map(Object.values));
  }

  filterConferences(conferences: Conference[]): Observable<Conference[]> {
    return combineLatest([this.filters$, this.filterValues$]).pipe(
      take(1),
      map(([filters, filterValues]) => {
        if (!conferences.length) {
          return conferences;
        }
        conferences = conferences.slice();
        conferences = this.applyFilters(conferences, filterValues, filters);
        conferences = this.filtersService.filterDataByPeriod(conferences, filterValues, 'period', 'startingAt');
        return conferences;
      })
    );
  }

  getMyConferenceInfo(conferenceId: number, token: string): Observable<Participant> {
    if (token) {
      return this.authService.authenticateByToken(conferenceId, token);
    } else {
      return this.authService.currentUser$.pipe(
        switchMap(currentUser => {
          if (!currentUser) {
            return of(null);
          } else {
            return this.getParticipantInfo(conferenceId, currentUser.id);
          }
        })
      );
    }
  }

  addConferenceParticipants(conferenceId: number, participants: any[]): Observable<any> {
    return this.wsService.send(WebsocketEvents.SEND.CONFERENCE.ADD_PARTICIPANTS, {
      data: {
        participants: participants,
        conferenceid: conferenceId
      }
    });
  }

  hasAccessToConference(conferenceId: number): Observable<any> {
    return this.wsService.send(WebsocketEvents.RECEIVE.ACCOUNT.HAS_ACCESS_TO_CONFERENCE, {
      data: { conferenceid: conferenceId }
    });
  }

  FNSBlockConferenceAccess$(): Observable<boolean> {
    return !this.isFnsBuild
      ? of(false)
      : this.authService.roles$.pipe(
          map(roles => {
            return (
              !roles.includes('conferences:control:all') &&
              !roles.includes('conferences:control:own') &&
              !roles.includes('conferences:control:my')
            );
          })
        );
  }

  hasAccessToConferenceAsAdmin(conferenceId: number): Observable<boolean> {
    return this.authService.roles$.pipe(
      switchMap(roles => {
        if (roles.includes('conferences:control:all')) {
          return of(true);
        }

        if (roles.includes('conferences:control:own')) {
          return this.hasAccessToConference(conferenceId);
        }

        if (roles.includes('conferences:control:my')) {
          return combineLatest([this.authService.currentUser$, this.getConference(conferenceId)]).pipe(
            map(([currentUser, conference]) => {
              return currentUser && conference.creator && conference.creator.id === currentUser.id;
            })
          );
        }
        return of(false);
      })
    );
  }

  checkAccessToConferenceAsAdmin(conferenceId: number, insertAsOperator = false): Observable<Participant> {
    return isTruthy(conferenceId)
      ? this.hasAccessToConferenceAsAdmin(conferenceId).pipe(
          switchMap(access => {
            if (!access || !insertAsOperator) {
              return of(null);
            }

            return this.authService.currentUser$.pipe(
              map(makeDTOFromUser(false, false, true)),
              switchMap(participant => {
                return this.addConferenceParticipants(conferenceId, [participant]).pipe(
                  switchMap(() => {
                    return this.getParticipantInfo(conferenceId, participant.id);
                  })
                );
              })
            );
          })
        )
      : of(null);
  }

  currentUserAsParticipant(conferenceId): Observable<Participant> {
    return this.authService.currentUser$.pipe(
      map(makeDTOFromUser(true, false, false)),
      switchMap(participant => {
        return this.addConferenceParticipants(conferenceId, [participant]).pipe(
          switchMap(() => {
            return this.getParticipantInfo(conferenceId, participant.id);
          })
        );
      })
    );
  }

  getParticipantsFromConference(conference: Conference | Partial<Conference>): any {
    const speakers = conference.participants
      ? (<Participant[]>conference.participants)
          .filter(participant => {
            return participant.hasRole(ConferenceUserRole.SPEAKER);
          })
          .map(participant => {
            return {
              ...participant.participantReference,
              rights: participant.rights
            };
          })
      : [];
    const operators = conference.participants
      ? (<Participant[]>conference.participants)
          .filter(participant => {
            return participant.hasRole(ConferenceUserRole.OPERATOR);
          })
          .map(participant => {
            return participant.participantReference;
          })
      : [];
    const participants = conference.participants
      ? (<Participant[]>conference.participants)
          .filter(participant => {
            return (
              participant.hasRole(ConferenceUserRole.PARTICIPANT) || !participant.hasRole(ConferenceUserRole.OPERATOR)
            );
          })
          .map(participant => {
            return participant.participantReference;
          })
      : [];
    return {
      speakers,
      operators,
      participants
    };
  }

  hasGlobalOrGroupAdminAccess(conferenceId: number): Observable<boolean> {
    return this.authService.roles$.pipe(
      switchMap(roles => {
        if (roles.includes('conferences:control:all')) {
          return of(true);
        }

        if (!roles.includes('conferences:control:own')) {
          return of(false);
        }

        return this.hasAccessToConference(conferenceId).pipe(
          map(access => {
            return !!access;
          })
        );
      })
    );
  }

  saveIcsEvent(conferenceId: number): void {
    if (this.electronService.isElectron) {
      this.fetchCalendarFile(conferenceId)
        .pipe(
          map(blobCalendarFile => {
            const byteArray: Uint8Array = base64ToArrayBuffer(blobCalendarFile);
            this.electronService.electronApi.send(ELECTRON_CHANNEL_LIST.OPEN_ICS_NATIVE, byteArray);
          }),
          take(1)
        )
        .subscribe();
    } else {
      this.fetchCalendarFile(conferenceId)
        .pipe(
          map(blobCalendarFile => {
            const fileName = 'event.ics';
            const byteArray: Uint8Array = base64ToArrayBuffer(blobCalendarFile);
            return this.appService.saveFileByByteArray(byteArray, fileName);
          }),
          take(1)
        )
        .subscribe();
    }
  }

  private getConferenceById(confId: number, data?: any): Observable<Conference> {
    return this.wsService
      .send(WebsocketEvents.RECEIVE.CONFERENCE.CERTAIN, {
        id: confId,
        data
      })
      .pipe(
        take(1),
        tap((conference: Conference) => {
          this.autoLayoutSubject$.next(conference.autolayout);
        }),
        // TODO обернуть в модель результата запроса и отправлять ответы и ошибки в компонент в ней
        catchError(() => {
          return of(null);
        }),
        toClass(Conference)
      );
  }

  private applyDurationFilter(selectedOption: FilterItemOption, allValues: Conference[]): Conference[] {
    const filterValue = selectedOption.value;
    if (filterValue) {
      return allValues.filter(conference => {
        return conference.getDuration() <= filterValue;
      });
    }
  }
}
