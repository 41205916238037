import { ChangeDetectionStrategy, Component, OnDestroy, Optional } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { IAuthCredentials } from '@breez/modules/auth/auth-credentials.model';
import { AuthService } from '@breez/modules/auth/services/auth.service';
import { LoggerService } from '@breez/shared/services/logger.service';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, shareReplay, startWith, take } from 'rxjs/operators';
import { ElectronService } from '@breez/modules/core/services';
import { WebsocketService } from '@breez/modules/websocket';
import * as breezValidators from '@breez/shared/validators';
import { NotificationType } from '@breez/models';
import { NotificationService } from '@breez/modules/notification';

@Component({
  selector: 'vks-login-popup',
  templateUrl: './login-popup.component.html',
  styleUrls: ['./login-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginPopupComponent implements OnDestroy {
  readonly isElectronApp: boolean = this.electronService.isElectron;
  loginForm: UntypedFormGroup;
  isPasswordVisible = false;

  isOnline$: Observable<boolean> = this.websocketService.status$;
  isLoading$: Observable<boolean> = this.authService.loadingInProgress$.pipe(
    startWith(false),
    distinctUntilChanged(),
    shareReplay(1)
  );

  socketReconnect$: Observable<boolean> = this.websocketService.socketReconnect$.pipe(debounceTime(500));

  readonly initialUrl: string;

  constructor(
    @Optional() public dialogRef: MatDialogRef<LoginPopupComponent>,
    private authService: AuthService,
    private logger: LoggerService,
    private electronService: ElectronService,
    private websocketService: WebsocketService,
    private notificationService: NotificationService
  ) {
    this.initialUrl = websocketService.getWebsocketClearUrl();

    this.loginForm = this.isElectronApp
      ? new UntypedFormGroup({
          login: new UntypedFormControl(null, [Validators.required]),
          password: new UntypedFormControl(null, [Validators.required]),
          wsServerUrl: new UntypedFormControl(websocketService.getWebsocketClearUrl(), [
            Validators.required,
            breezValidators.serverUrlValidator()
          ])
        })
      : new UntypedFormGroup({
          login: new UntypedFormControl(null, [Validators.required]),
          password: new UntypedFormControl(null, [Validators.required])
        });

    this.websocketService.autoReconnect = false;
  }

  get passwordFormControl(): UntypedFormControl {
    return this.loginForm.get('password') as UntypedFormControl;
  }

  login(): void {
    if (this.loginForm.invalid) {
      this.loginForm.markAllAsTouched();
      return;
    }

    const credentials: IAuthCredentials = {
      login: <string>this.loginForm.controls.login.value,
      password: this.loginForm.controls.password.value
    };

    this.authService.loadingInProgress$.next(true);

    if (this.isElectronApp) {
      credentials.wsServerUrl = this.loginForm.controls.wsServerUrl.value;
      this.websocketService.updateWebsocketUrl(credentials.wsServerUrl);

      this.websocketService.reconnect();
      this.socketReconnect$
        .pipe(
          take(1),
          catchError(error => {
            this.authService.loadingInProgress$.next(false);
            return of(this.logger.error(error));
          })
        )
        .subscribe(reconnect => {
          if (reconnect) {
          } else {
            this.authService.loadingInProgress$.next(false);
            this.notificationService.showWithTranslate({
              type: NotificationType.Error,
              message: 'CONNECTION_SOCKET_FAILED'
            });
          }
        });
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  togglePasswordShow(): void {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  ngOnDestroy(): void {
    this.websocketService.autoReconnect = true;
  }
}
