import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { PipeTranslationDependency } from '@breez/shared/pipes/base/pipe-translation-dependecy.class';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'fileSize',
  pure: false
})
export class FileSizePipe extends PipeTranslationDependency implements PipeTransform {
  protected savedValue;

  constructor(
    @Inject(LOCALE_ID) locale: string,
    private translateService: TranslateService
  ) {
    super(locale, translateService);
  }

  transform(size: number, unit: 'B' | 'KB' | 'MB' | 'GB' | 'TB', forceRecalculation: boolean = false): unknown {
    if (!this.savedValue || forceRecalculation) {
      const unitsSequence = ['B', 'KB', 'MB', 'GB', 'TB'];
      let startUnitIndex = unitsSequence.findIndex(_unit => {
        return _unit === unit;
      });
      while (size >= 1024 && startUnitIndex !== unitsSequence.length) {
        size /= 1024;
        startUnitIndex++;
      }
      let label = size.toFixed(2);
      if (/\.00/.test(label)) {
        label = label.replace(/\.00/, '');
      }
      this.savedValue = `${label} ${this.translateService.instant(unitsSequence[startUnitIndex])}`;
    }
    return this.savedValue;
  }
}
