<input
  vks-cinput
  [class.disabled]="disable"
  [formControl]="control"
  [matDatepicker]="datepicker"
  (focus)="setTouched()"
  type="text"
/>
<button
  vks-button
  (buttonClick)="datepicker.open()"
  class="btn-anchor btn-compressed"
  icon="control-calendar"
  iconClass="square24"
  tabindex="-1"
  type="button"
></button>
<mat-datepicker #datepicker (opened)="setTouched()"></mat-datepicker>
