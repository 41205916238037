export function guid(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    // tslint:disable-next-line:no-bitwise
    const number = (Math.random() * 16) | 0;
    // tslint:disable-next-line:no-bitwise
    const v =
      c === 'x'
        ? number
        : // tslint:disable-next-line:no-bitwise
          (number & 0x3) | 0x8;
    return v.toString(16);
  });
}
