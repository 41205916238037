import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { CallModel, CallStatus } from '@breez/modules/call/models';
import { Call } from '@breez/modules/call/call';
import { OverlayContainer } from '@breez/modules/overlay/models';
import { generateCallStatusProviderType } from '@breez/modules/call/call-statuses/call-status-component.provider';
import { CallStatusComponentResolver } from '../call-status-component.resolver';

@Component({
  selector: 'vks-call-dynamic',
  template: '<ng-container *ngxComponentOutlet="resolver | resolve:callStatusProviderType"></ng-container>',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CallStatusDynamicComponent implements OnChanges {
  @Input() call: Call;
  @Input() callModel: CallModel;
  @Input() anotherActiveCallId: number;
  @Input() overlayContainer: OverlayContainer;

  @Output() changeCall = new EventEmitter<{ status: CallStatus; data: any }>();
  @Output() changeOverlayContainer = new EventEmitter<OverlayContainer>();

  callStatusProviderType: string;

  constructor(public resolver: CallStatusComponentResolver) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.callModel) {
      this.callStatusProviderType = generateCallStatusProviderType(this.callModel);
    }
  }
}
