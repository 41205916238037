import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';

import { getTimeOfDate } from '@breez/shared/utilities/date-utils';
import {
  Calendar,
  CalendarOptions,
  DatesSetArg,
  EventClickArg,
  EventHoveringArg,
  EventInput
} from '@fullcalendar/core';
import { EmitOnChange } from '@breez/shared/utilities/decorators/emit-on-change.decorator';
import { ReplaySubject } from 'rxjs';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { map } from 'rxjs/operators';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';

export const FULL_CALENDAR_PLUGINS = {
  plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin]
};

export interface EventObject {
  id?: string | number;
  title: string;
  allDay?: boolean;
  start?: string | Date;
  end?: string | Date;
  url?: string;
  className?: string | string[];
  editable?: boolean;
  startEditable?: boolean;
  durationEditable?: boolean;
  resourceEditable?: boolean;
  resourceId?: string;
  resourceIds?: string[];
  rendering?: '' | 'background' | 'inverse-background';
  overlap?: boolean;
  constraint?: any;
  backgroundColor?: string;
  borderColor?: string;
  popup?: boolean;
  scheduleType?: string;
  textColor?: string;
  groupId?: string;
  extendedProps?: {
    [key: string]: any;
  };
  startTime?: Object;
  endTime?: Object;
  daysOfWeek?: Array<number>;
  startRecur?: string | Date;
  endRecur?: string | Date;
}

interface RangeApi {
  start: Date;
  end: Date;
  startStr: string;
  endStr: string;
}

@Component({
  selector: 'vks-events-calendar-new',
  templateUrl: './events-calendar-new.component.html',
  styleUrls: ['./events-calendar-new.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventsCalendarNewComponent implements OnChanges, AfterViewInit {
  @Input() initRangeSet = false;

  @Input() isFetching = false;

  @Input() view: string;

  @Input() setDate: Date;

  @Input() calendarOptions: CalendarOptions;

  @EmitOnChange('calendarOptions')
  calendarOptionsInput$ = new ReplaySubject<CalendarOptions>(1);

  calendarOptions$ = this.calendarOptionsInput$.pipe(
    map(calendarOptions => {
      return { ...calendarOptions, ...FULL_CALENDAR_PLUGINS };
    })
  );

  @Input()
  events: EventInput[];

  @Output()
  datesSet = new EventEmitter<DatesSetArg | any>();

  @Output()
  onHoverElement = new EventEmitter<EventHoveringArg>();

  @Output()
  onClickElement = new EventEmitter<EventClickArg>();

  @ViewChild('calendar') calendarComponent: FullCalendarComponent;

  private api: Calendar;

  ngAfterViewInit(): void {
    this.api = this.calendarComponent.getApi();

    const emitDateSet = (dateSet: RangeApi): void => {
      this.datesSet.emit(dateSet);
      if (['timeGridWeek', 'timeGridDay'].includes(this.view)) {
        this.api.scrollToTime(getTimeOfDate(new Date()));
      }
    };

    if (this.initRangeSet) {
      const view = this.api.view;
      emitDateSet({
        start: view.activeStart,
        startStr: this.api.formatIso(view.activeStart),
        end: view.activeEnd,
        endStr: this.api.formatIso(view.activeEnd)
      });
    }

    this.api.on('datesSet', info => {
      this.api.updateSize();
      emitDateSet(info);
    });

    this.api.on('eventMouseEnter', info => {
      this.onHoverElement.next(info);
    });

    this.api.on('eventMouseLeave', _ => {
      this.onHoverElement.next(null);
    });

    this.api.on('eventClick', info => {
      this.onClickElement.next(info);
    });
  }

  toCurrentTime(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.view && this.calendarComponent) {
      this.api.changeView(changes.view.currentValue);
      if (['timeGridWeek', 'timeGridDay'].includes(changes.view.currentValue)) {
        this.api.scrollToTime(getTimeOfDate(new Date()));
      }
    }

    if (changes.setDate && this.calendarComponent) {
      this.api.gotoDate(changes.setDate.currentValue);
    }
    if (changes.events && this.calendarComponent) {
      // setTimeout(() => {
      this.api.removeAllEvents();
      if (!!this.events) {
        this.api.addEventSource(this.events);
      }
    }
  }
}
