import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ChatService } from '../../services';
import { Observable } from 'rxjs';

@Component({
  selector: 'vks-chat-warning-form',
  templateUrl: './chat-warning-form.component.html',
  styleUrls: ['./chat-warning-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatWarningFormComponent {
  suggestions$: Observable<string[]> = this.chatService.getWarningSuggestions();

  warningForm = new UntypedFormGroup({
    reason: new UntypedFormControl(null)
  });

  constructor(
    private chatService: ChatService,
    private dialogRef: MatDialogRef<ChatWarningFormComponent>
  ) {}

  submit(): void {
    this.dialogRef.close({ message: this.warningForm.value.reason });
  }

  cancel(): void {
    this.dialogRef.close(null);
  }

  setSuggestionToReasonInput(suggestion: string): void {
    this.warningForm.controls.reason.setValue(suggestion);
  }
}
