import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  OnChanges,
  OnDestroy,
  EventEmitter,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { fromControl } from '@breez/shared/rxjs-operators/from-control';
import { distinctUntilChanged } from 'rxjs/operators';
import { replayWhileSubs } from '@breez/shared/rxjs-operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { NestedFieldsPipe } from '@breez/shared/pipes/nested-field-pipe/nested-fields.pipe';

export interface SearchControlPrefix {
  icon: string;
  size?: number;
  color?: string;
  left?: number;
  top?: number;
}

export interface SearchControlAutocomplete {
  labelPath: string[];
  valuePath: string[];
}

@UntilDestroy()
@Component({
  selector: 'vks-search-control',
  templateUrl: './search-control.component.html',
  styleUrls: ['./search-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchControlComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild(MatAutocomplete) matAutocomplete: MatAutocomplete;

  @Input() placeholder: string;
  @Input() iconPrefix: SearchControlPrefix;
  @Input() debounceTime: number = 300;
  @Input() disabled: boolean = false;
  @Input() autocomplete: SearchControlAutocomplete;
  @Input() autocompleteData: any[];
  @Output() searchValue: EventEmitter<string> = new EventEmitter<string>();

  private prefixClass: string;

  formControl: UntypedFormControl = new UntypedFormControl();

  searchControlValue$: Observable<string> = fromControl(this.formControl, this.debounceTime).pipe(
    untilDestroyed(this),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  constructor(private nestedFieldsPipe: NestedFieldsPipe) {}

  ngOnInit(): void {
    this.searchControlValue$.subscribe(value => {
      return this.searchValue.emit(value);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled?.currentValue) {
      this.formControl.disable({ emitEvent: false });
    } else if (!changes.disabled?.currentValue) {
      this.formControl.enable({ emitEvent: false });
    }

    if (changes.iconPrefix?.currentValue && changes.iconPrefix.currentValue.size) {
      this.prefixClass = `square${changes.iconPrefix.currentValue.size}`;
    }
  }

  displayAutocompleteRow(entity: any): string {
    if (!this.autocomplete || !entity) {
      return;
    }

    return this.nestedFieldsPipe.transform(entity, this.autocomplete.labelPath);
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy(): void {}

  resetSearch(): void {
    this.formControl.setValue('', { emitEvent: true });
  }
}
