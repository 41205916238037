function queryParamToString(key: string, value: string | number): string {
  return `${key}=${value}`;
}

function appendAmpersandOrEmptyString(queryParamIndex: number, queryParamsKeysArrayLength: number): string {
  return queryParamIndex === queryParamsKeysArrayLength - 1 ? '' : '&';
}

export function queryParamsToString(params: { [key: string]: string | number }): string {
  if (!params) {
    return '';
  }
  const paramsKeysArray = Object.keys(params);
  const paramsKeysArrayLength = paramsKeysArray.length;

  if (paramsKeysArrayLength > 0) {
    let paramsAsString = '';

    paramsKeysArray.forEach((key, index) => {
      return (paramsAsString += `${queryParamToString(key, params[key])}${appendAmpersandOrEmptyString(index, paramsKeysArrayLength)}`);
    });
    return paramsAsString;
  }

  return '';
}
