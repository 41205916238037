<ng-container *ngIf="languages$ | async as languages">
  <mat-select
    *ngIf="languages?.length > 0"
    [panelClass]="'language-select-panel'"
    [(value)]="selected"
    (selectionChange)="switchLanguage($event.value)"
  >
    <mat-select-trigger>
      <vks-icon [icon]="languageFlagIcon$ | async"></vks-icon>
      <span class="mat-option-label">{{ languageName$ | async }}</span>
    </mat-select-trigger>
    <mat-option *ngFor="let language of languages" [value]="language.id">
      <vks-icon [icon]="language.icon"></vks-icon>
      <span class="mat-option-label">{{ language.name }}</span>

      <!--<button vks-button
							[icon]="language.icon"
							[label]="language.name"
							(click)="switchLanguage(language)"
							class="btn-anchor">
			</button>-->
    </mat-option>
  </mat-select>
</ng-container>
