import { Injectable } from '@angular/core';
import { User } from '@breez/models';
import { Division } from '@breez/models/division.model';
import { WebsocketEvents, WebsocketService } from '@breez/modules/websocket';
import { arrToClass, toClass } from '@breez/shared/rxjs-operators';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { DivisionTypeEnum } from '@breez/models/division.enum';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(
    private translateService: TranslateService,
    private wsService: WebsocketService
  ) {}

  getUsers(data: { [key: string]: any } = {}): Observable<User[]> {
    return this.wsService.send<User[]>(WebsocketEvents.SEND.USER.LIST, { data }).pipe(arrToClass(User));
  }

  getDivisions(): Observable<Division[]> {
    return this.wsService.send(WebsocketEvents.SEND.DIVISIONS.GET).pipe(
      map(divisions => {
        return divisions.map(division => {
          return {
            ...division,
            divisionname:
              !division.divisionname && division.divisiontype === DivisionTypeEnum.EXTERNAL
                ? this.translateService.instant('BREEZ_INVITING_USER_DEPARTMENT')
                : division.divisionname
          };
        });
      }),
      arrToClass(Division)
    );
  }

  getUserById(userId: number): Observable<User> {
    return this.wsService
      .send<User>(WebsocketEvents.SEND.CONFERENCE.USER.GET, { id: userId })
      .pipe(take(1), toClass(User));
  }
}
