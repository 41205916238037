import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { WEBSOCKET_CONFIG } from './websocket.config';
import { WebSocketConfig } from './websocket.interfaces';

import { WEBSOCKET_URL_STORAGE_KEY, WebsocketService } from './websocket.service';
import { environment } from '@breez/environment';

const isElectron = environment.type === 'electron';

export function generateWSConfig(): WebSocketConfig {
  let wsConfig: WebSocketConfig;
  const wsUrl = (isElectron ? localStorage.getItem(WEBSOCKET_URL_STORAGE_KEY) : null) || environment.ws;
  const wsApi = environment.wsApi;
  if (wsUrl) {
    wsConfig = { url: `${wsUrl}${wsApi}` };
  } else if (!isElectron) {
    if (window.location.protocol === 'https:') {
      wsConfig = { url: `wss://${window.location.hostname}:${window.location.port}${wsApi}` };
    } else {
      wsConfig = { url: `ws://${window.location.hostname}:${window.location.port}${wsApi}` };
    }
  } else {
    // wsConfig = {url: `wss://cloud.videoselector.ru`}; // харкод для пустого environment.ws b electron
    wsConfig = { url: `` }; // харкод для пустого environment.ws b electron
  }
  if (isElectron) {
    localStorage.setItem(WEBSOCKET_URL_STORAGE_KEY, wsUrl);
  }
  return wsConfig;
}

@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [WebsocketService]
})
export class WebsocketModule {
  static forRoot(): ModuleWithProviders<WebsocketModule> {
    return {
      ngModule: WebsocketModule,
      providers: [
        {
          provide: WEBSOCKET_CONFIG,
          useFactory: generateWSConfig
        }
      ]
    };
  }
}
