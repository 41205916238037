import { NotificationProvider, NotificationType } from './';
import { AvatarDisplayable } from '@breez/modules/user/modules/models/avatar-displayable.model';

export interface INotificationModel {
  type: NotificationType;
  message: string;
  title?: string;
  subtitle?: string;
  config?: NotificationProvider;
  afterClick?: () => any;
  timeout?: number;
  imageUrl?: string;
  iconUrl?: string;
  avatar?: AvatarDisplayable;
}
