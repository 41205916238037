<mat-dialog-content *ngIf="currentUser$ | async as currentUser" [class.is-mobile]="isMobileDevice$ | async">
  <div class="header">
    <input vks-cinput [formControl]="keyword" type="text" />
    <vks-icon
      *ngIf="isMobileDevice$ | async"
      [mat-dialog-close]="null"
      class="square14"
      color="#9daec3"
      icon="x-8"
    ></vks-icon>
  </div>
  <div *ngIf="(filteredChats$ | async)?.length > 0; else notFound" class="chats-list">
    <div *ngFor="let chat of filteredChats$ | async" [mat-dialog-close]="chat.id" class="chat">
      <vks-avatar-smart
        [entity]="chat | chatAvatarEntity | async"
        [size]="(isMobileDevice$ | async) ? 40 : 35"
      ></vks-avatar-smart>
      <span>{{ (chat | chatAvatarEntity | async).name | translate }}</span>
    </div>
  </div>
</mat-dialog-content>
<ng-template #notFound>
  <span class="stub">{{ 'NOTHING_FOUND' | translate }}</span>
</ng-template>
