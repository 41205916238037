import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ValidationError } from './validation-error.model';

const ERROR = {
  type: 'olddate',
  label: 'WRONG_DATE_OLD_DATE'
} as ValidationError;

export function currentTimeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const controlValue: Date = control.value;

    if (!(controlValue instanceof Date && controlValue?.getTime() < new Date()?.getTime() && control.touched)) {
      return null;
    }

    return { [ERROR.type]: ERROR } as ValidationErrors;
  };
}
