import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { UserStatusService } from '@breez/modules/user/modules/avatar-smart/services/user-status.service';
import { EmitOnChange } from '@breez/shared/utilities/decorators/emit-on-change.decorator';
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { UserStatus } from '@breez/modules/user/modules/models/user-status.enum';
import { auditTime, distinctUntilChanged, filter, switchMap, take } from 'rxjs/operators';
import { AvatarDisplayable } from '@breez/modules/user/modules/models/avatar-displayable.model';
import { User } from '@breez/models';
import { EntityReference } from '@breez/models/shared/entity-reference.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ObjectType } from '@breez/shared/enums/object-type.enum';
import { UserCardService } from '@breez/modules/social/services/user-card.service';
import { StateService } from '@breez/shared/services/state.service';

@UntilDestroy()
@Component({
  selector: 'vks-avatar-smart',
  templateUrl: './avatar-smart.component.html',
  styleUrls: ['./avatar-smart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarSmartComponent implements OnInit, OnChanges {
  @Input() entity: AvatarDisplayable;

  @Input() cardByHover = false;

  @HostBinding('style.width.px')
  @HostBinding('style.height.px')
  @HostBinding('style.min-width.px')
  @HostBinding('style.min-height.px')
  @Input()
  size: number = 48;

  @Input() stroke: boolean = false;
  @Input() pulseStroke: boolean = false;
  @Input() overlayIcon: string = null;
  @Input() displayIndicators: boolean = true;
  @EmitOnChange('displayIndicators')
  displayIndicators$ = new BehaviorSubject<boolean>(this.displayIndicators);

  @EmitOnChange('entity')
  entity$ = new ReplaySubject<AvatarDisplayable>(1);

  userStatus = null;
  userStatus$: Observable<UserStatus> = this.displayIndicators$.pipe(
    distinctUntilChanged(),
    switchMap(displayIndicators => {
      if (!displayIndicators) {
        return of(null);
      }
      return this.entity$.pipe(
        distinctUntilChanged((previous, current) => {
          if (previous instanceof User && current instanceof User) {
            return previous.id === current.id;
          }
          return false;
        }),
        switchMap(entity => {
          if (entity instanceof User) {
            return this.userStatusService.observeUserStatusById(entity.id);
          }
          if (entity instanceof EntityReference) {
            if (entity.type === ObjectType.USER) {
              return this.userStatusService.observeUserStatusById(entity.id);
            }
          }
          return of(null);
        })
      );
    })
  );

  isCardVisible$ = this.userCardService.isCardVisible$.pipe(auditTime(100));

  isMobileDevices$ = this.stateService.isMobileDevice$;
  mouseLeave: boolean;

  constructor(
    private readonly userStatusService: UserStatusService,
    private readonly userCardService: UserCardService,
    private readonly stateService: StateService,
    private elementRef: ElementRef,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private ngZone: NgZone
  ) {}

  ngOnInit(): void {
    this.ngZone.runOutsideAngular(() => {
      this.userStatus$.pipe(untilDestroyed(this)).subscribe(userStatus => {
        this.userStatus = userStatus;
        this.changeDetectorRef.detectChanges();
      });
    });
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnChanges(_: SimpleChanges): void {}

  @HostListener('mouseenter', ['$event'])
  @HostListener('touchend', ['$event'])
  showCard($event): void {
    if (this.cardByHover && this.entity.id) {
      this.mouseLeave = false;
      combineLatest([this.isCardVisible$, this.isMobileDevices$])
        .pipe(
          auditTime(1500),
          take(1),
          filter(([isCardVisible]) => {
            return isCardVisible === false;
          })
        )
        .subscribe(([_, isMobileDevices]) => {
          const eventFilter = !isMobileDevices || (isMobileDevices && $event?.type === 'touchend');
          if (!this.mouseLeave && eventFilter) {
            this.userCardService.createUserCardOverlay(this.entity.id, isMobileDevices, false, this.elementRef, false);
          }
        });
    }
  }

  @HostListener('mouseleave')
  hideCard(): void {
    this.mouseLeave = true;
  }
}
