import { formatDate } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from '@breez/environment';
import { NotificationType, User } from '@breez/models';
import { ConferenceUserRole } from '@breez/models/conference-user-role.enum';
import { Conference } from '@breez/models/conference/conference.model';
import { ParticipantDTO } from '@breez/models/conference/participant.dto.model';
import { Participant } from '@breez/models/shared/participant/participant.model';
import { IAbstractControl, IStepControl, ITemplate } from '@breez/models/template/template.model';
import { AuthService } from '@breez/modules/auth/services/auth.service';
import { makeDTOFromUser } from '@breez/modules/conference/modules/planner/services/conference-planner-makeDTOFromParticipant/conference-planner-makeDTOFromParticipant';
import { CREATE_CONFERENCE_DIALOG_ID } from '@breez/modules/conference/modules/planner/services/create-conference-dialog/create-conefrence-dialog-id';
import { NotificationService } from '@breez/modules/notification';
import { SettingsService } from '@breez/modules/settings/services/settings.service';
import { BuildType } from '@breez/shared/enums/build-type.enum';
import { replayWhileSubs } from '@breez/shared/rxjs-operators';
import { ConferencesService } from '@breez/shared/services/conferences.service';
import { LanguageService } from '@breez/shared/services/language.service';
import { StateService } from '@breez/shared/services/state.service';
import { EmitOnChange } from '@breez/shared/utilities/decorators/emit-on-change.decorator';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { removeTags } from '@breez/shared/utilities/removetags';
import { buildForm } from '@breez/modules/conference/modules/planner/services/conference-planner-build-form/conference-planner-build-form';
import * as uuid from 'uuid';
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, debounceTime, delay, distinctUntilChanged, filter, map, switchMap, take } from 'rxjs/operators';
import { DynamicAction } from '@breez/shared/models/dynamic-action';
import { IAbstractControlViewMode } from '@breez/models/template/control-view-mode.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfirmPopupService } from '@breez/shared/services/confirm-popup/confirm-popup.service';
import { ConferenceAccessType } from '@breez/models/conference/conference-access-type';

@UntilDestroy()
@Component({
  selector: 'vks-create-conference-popup',
  templateUrl: './create-conference-popup.component.html',
  styleUrls: ['./create-conference-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateConferencePopupComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() template: ITemplate;
  @Input() conference: Partial<Conference>;

  @EmitOnChange('conference', { onlyTruthy: true })
  conference$: ReplaySubject<Partial<Conference>> = new ReplaySubject<Partial<Conference>>();

  @Output() action: EventEmitter<DynamicAction> = new EventEmitter<DynamicAction>();

  pristine$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  @Output() pristineChange = this.pristine$;

  formGroup: UntypedFormGroup;
  currentDialog: MatDialogRef<CreateConferencePopupComponent>;
  isModal = true;

  currentUser$: Observable<User> = this.authService.currentUser$.pipe(replayWhileSubs());

  titleFormControlInitialValue$: Observable<string> = combineLatest([
    this.currentUser$.pipe(filter(isTruthy)),
    this.languageService.interfaceLanguage$.pipe(
      switchMap(language => {
        return this.languageService.getLanguageInfo(language);
      })
    )
  ]).pipe(
    map(([currentUser, languageInfo]) => {
      const transformedDate = formatDate(new Date(), languageInfo.dateFormat, languageInfo.id);
      return `${currentUser.name}, ${transformedDate}`;
    })
  );

  titleBuffer$: ReplaySubject<string> = new ReplaySubject<string>(1);

  isMobileDevice$: Observable<boolean> = this.stateService.isMobileDevice$;

  projectBuildType: BuildType;

  participantsScheme: IStepControl = {
    controls: [
      <IAbstractControl>{
        display: true,
        key: 'participants',
        keyOnDTO: 'participants',
        title: 'PARTICIPANTS',
        type: 'participants',
        view: IAbstractControlViewMode.SHORT
      },
      <IAbstractControl>{
        display: true,
        key: 'speakers',
        keyOnDTO: 'speakers',
        title: 'SPEAKERS',
        type: 'speakers'
      },
      <IAbstractControl>{
        display: true,
        key: 'operators',
        keyOnDTO: 'operators',
        title: 'OPERATORS',
        type: 'operators'
      }
    ]
  };

  participants$: Observable<Participant[]> = this.conference$.pipe(
    switchMap(conference => {
      return this.conferencesService.getParticipantsInfo(conference.id);
    })
  );

  patchedConference$: Observable<Partial<Conference>> = combineLatest([this.conference$, this.participants$]).pipe(
    map(([conference, participants]) => {
      conference.participants = participants;
      return conference;
    })
  );

  retentionTime$: Observable<number> = this.settingsService.getValue('rec', 'retention_time').pipe(
    map(setting => {
      return setting.value ? Number(setting.value) : undefined;
    })
  );

  data$: ReplaySubject<ITemplate> = new ReplaySubject();

  patchedData$ = combineLatest([this.data$, this.retentionTime$]).pipe(
    map(([data, time]) => {
      const recordControlIndex = data.controls.findIndex(control => {
        return control.key === 'enablerecord';
      });
      if (isTruthy(time) && time) {
        const dayExplanation = 'DAY';
        data.controls[recordControlIndex].warning = {
          params: { dayExplanation },
          declensionValues: [Number(time)],
          key: 'RECORD_MORATORIUM'
        };
      } else {
        delete data.controls[recordControlIndex].warning;
      }
      return data;
    })
  );

  conferenceCreating = false; // fix for VKS_Client_WEB-1476

  constructor(
    @Inject(CREATE_CONFERENCE_DIALOG_ID) private readonly dialogId: string,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: ITemplate,
    private readonly authService: AuthService,
    private readonly dialog: MatDialog,
    private readonly conferencesService: ConferencesService,
    private readonly stateService: StateService,
    private languageService: LanguageService,
    private notificationService: NotificationService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private settingsService: SettingsService,
    private confirmPopupService: ConfirmPopupService
  ) {
    this.projectBuildType = environment.buildType;
  }

  @HostBinding('class.dialog') get isDialog(): boolean {
    return this.isModal;
  }

  ngOnInit(): void {
    if (!this.data) {
      this.data = this.template;
      this.isModal = false;
      const index = this.data.controls.findIndex(control => {
        return control.key === 'size';
      });
      if (index > -1) {
        this.data.controls.splice(index, 1);
      }
      this.stateService.setHeader({ title: 'CONFERENCE' });
    }
    this.data$.next(this.data);
  }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnChanges(_: SimpleChanges): void {}

  buildForm(): void {
    this.formGroup = buildForm(this.data.controls);
    this.titleFormControlInitialValue$.subscribe(this.titleBuffer$);
    this.titleBuffer$.subscribe(title => {
      this.formGroup.get('title').patchValue(title);
    });

    const isAnonymousFormControl = this.formGroup.get('isAnonymous');
    const isPublicFormControl = this.formGroup.get('isPublic');

    this.currentDialog = this.dialog.getDialogById(this.dialogId);

    if (!this.isModal) {
      this.changeDetectorRef.detach();
      this.patchedConference$.pipe(take(1)).subscribe(conference => {
        const conferenceParticipants = this.conferencesService.getParticipantsFromConference(conference);

        this.formGroup.addControl('participants', new UntypedFormControl([]));
        this.formGroup.addControl('speakers', new UntypedFormControl([]));
        this.formGroup.addControl('operators', new UntypedFormControl([]));
        for (const control of this.data.controls) {
          if (control.key === 'enablerecord') {
            this.formGroup.get(control.key).patchValue(conference.enableRecord);
          } else if (control.key === 'size') {
            this.formGroup.get(control.key).patchValue(conference.resolution);
          } else {
            this.formGroup.get(control.key).patchValue(conference[control.key]);
          }
        }
        this.changeDetectorRef.reattach();
        this.changeDetectorRef.detectChanges();

        this.formGroup.controls.participants.patchValue(conferenceParticipants.participants);
        this.formGroup.controls.speakers.patchValue(conferenceParticipants.speakers);
        this.formGroup.controls.operators.patchValue(conferenceParticipants.operators);

        if (isTruthy(conference.accessType)) {
          if (isPublicFormControl) {
            isPublicFormControl.patchValue(conference.accessType === ConferenceAccessType.PUBLIC);
          }
          if (isAnonymousFormControl) {
            isAnonymousFormControl.patchValue(conference.accessType === ConferenceAccessType.ANONYMOUS);
          }
        }
      });
    } else {
      this.changeDetectorRef.detectChanges();
    }

    if (isTruthy(isAnonymousFormControl) && isTruthy(isPublicFormControl)) {
      isAnonymousFormControl.valueChanges
        .pipe(filter(isTruthy), debounceTime(100), distinctUntilChanged(), untilDestroyed(this))
        .subscribe(isAnonymous => {
          if (isAnonymous) {
            of(null)
              .pipe(delay(50), take(1))
              .subscribe(() => {
                isPublicFormControl.disable();
                isPublicFormControl.patchValue(false);
              });
          } else {
            isPublicFormControl.enable();
          }
        });
    }

    this.formGroup.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.pristine$.next(this.formGroup.pristine);
    });
  }

  ngAfterViewInit(): void {
    this.buildForm();
  }

  createConference(): void {
    if (!this.conferenceCreating) {
      this.conferenceCreating = true;
      const {
        displaydescription,
        allowclientrecording,
        enablerecord,
        recording,
        isMulticasting,
        isPublic,
        isAnonymous,
        title,
        size,
        enableChat
      } = this.formGroup.value;

      const defaultValues = this.data.defaultValues.reduce((acc, control) => {
        acc[control.key] = control.defaultValue;
        return acc;
      }, {});
      combineLatest([this.currentUser$, this.titleBuffer$])
        .pipe(
          take(1),
          switchMap(([currentUser, initialTitle]) => {
            const currentDate = new Date();
            const conference = <Partial<Conference>>{
              accesstype: isAnonymous
                ? ConferenceAccessType.ANONYMOUS
                : isPublic
                  ? ConferenceAccessType.PUBLIC
                  : ConferenceAccessType.PARTICIPANT,

              isMulticasting,
              size,
              allowclientrecording,
              enableChat,
              recording: !!enablerecord && !!recording,
              title: title || initialTitle,
              description: removeTags(displaydescription),
              displaydescription: displaydescription,
              enablerecord: enablerecord,
              plannedstartedon: currentDate,
              plannedendedon: currentDate,
              token: uuid.v4(),
              creator: currentUser,
              participants: [
                <ParticipantDTO>{
                  id: currentUser.id,
                  isOperator: true,
                  isParticipant: true,
                  isSpeaker: false
                }
              ],
              autostart: true,
              enableqts: false,
              template: this.data,
              enablesurvey: false,
              autoinvitetoframe: this.projectBuildType === BuildType.FNS ? null : ConferenceUserRole.PARTICIPANT,
              ...defaultValues
            };

            return this.conferencesService.createConference(conference, false);
          })
        )
        .subscribe(createdConferenceId => {
          this.currentDialog.close(createdConferenceId);
          this.conferenceCreating = false;
        });
    }
  }

  updateConference(): void {
    combineLatest([this.patchedConference$, this.currentUser$.pipe(take(1))]).subscribe(
      ([patchedConference, currentUser]) => {
        const {
          displaydescription,
          allowclientrecording,
          enablerecord,
          isMulticasting,
          isPublic,
          isAnonymous,
          title,
          size,
          enableChat
        } = this.formGroup.value;

        const payload = <Partial<Conference>>{
          isMulticasting,
          isPublic,
          size,
          allowclientrecording,
          enableChat,
          title: title,
          description: removeTags(displaydescription),
          displaydescription: displaydescription,
          enablerecord: enablerecord,
          template: this.data,
          conferencetype: patchedConference.conferenceType,
          recording: !enablerecord ? false : patchedConference.recording
        };

        let conferenceUsers: ParticipantDTO[] = [];

        let participants = (this.formGroup.controls.participants.value || []) as any[];
        const speakers = (this.formGroup.controls.speakers.value || []) as any[];
        const operators = (this.formGroup.controls.operators.value || [currentUser]) as any[];

        participants = participants.map(makeDTOFromUser(true));

        conferenceUsers = conferenceUsers.concat(participants);

        speakers.forEach(speaker => {
          let speakerParticipant = conferenceUsers.find(user => {
            return speaker.hasNoId ? user.email === speaker.email : user.id === speaker.id;
          });

          if (!speakerParticipant) {
            speakerParticipant = makeDTOFromUser()(speaker);
            conferenceUsers.push(speakerParticipant);
          }

          if (speakerParticipant) {
            speakerParticipant.isSpeaker = true;
            speakerParticipant.rights = speaker.rights;
          }
        });

        operators.forEach(speaker => {
          let speakerParticipant = conferenceUsers.find(user => {
            return speaker.hasNoId ? user.email === speaker.email : user.id === speaker.id;
          });

          if (!speakerParticipant) {
            speakerParticipant = makeDTOFromUser()(speaker);
            conferenceUsers.push(speakerParticipant);
          }

          if (speakerParticipant) {
            speakerParticipant.isOperator = true;
          }
        });

        payload.participants = conferenceUsers;
        (<any>payload).accesstype = isAnonymous
          ? ConferenceAccessType.ANONYMOUS
          : isPublic
            ? ConferenceAccessType.PUBLIC
            : ConferenceAccessType.PARTICIPANT;

        return this.conferencesService
          .editConference(this.conference.id, payload, false)
          .pipe(
            catchError(() => {
              return of(null);
            })
          )
          .subscribe((updatedConference: Partial<Conference>) => {
            if (!updatedConference) {
              this.notificationService.showWithTranslate({
                message: 'ERROR_EDITING_CONFERENCE',
                type: NotificationType.Error
              });
              return;
            }
            this.pristine$.next(true);
            this.notificationService.showWithTranslate({
              message: 'CONFERENCE_SAVED',
              type: NotificationType.Success
            });
            this.router.navigateByUrl(this.conferencesService.generateLinksForConference(updatedConference).summaryUrl);
          });
      }
    );
  }

  onAction($event: DynamicAction): void {
    this.action.emit($event);
  }

  closeModal(): void {
    this.pristine$
      .pipe(
        take(1),
        switchMap(pristine => {
          return pristine
            ? of(true)
            : this.confirmPopupService.confirm({ question: 'DATA_WILL_BE_LOST', action: 'YES' });
        })
      )
      .subscribe(close => {
        if (close) {
          this.currentDialog.close();
        }
      });
  }
}
