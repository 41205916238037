import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import * as ModuleState from '@breez/modules/chat/+state/module.state';
import * as ParticipantsSelectors from '@breez/modules/chat/+state/participants/participants.selectors';
import { map, withLatestFrom } from 'rxjs/operators';
import { Chat, getAvatarEntity } from '@breez/modules/chat';
import { AuthService } from '@breez/modules/auth/services/auth.service';
import { ChatEntity } from '@breez/modules/chat/models/+state/chatEntity';
import { Observable, of } from 'rxjs';
import { AvatarDisplayable } from '@breez/modules/user/modules/models/avatar-displayable.model';
import { User } from '@breez/models';
import { isTruthy } from '@breez/shared/utilities/is-truthy';

@Pipe({
  name: 'chatAvatarEntity'
})
@Injectable({
  providedIn: 'root'
})
export class ChatAvatarEntityPipe implements PipeTransform {
  constructor(
    private store: Store<ModuleState.State>,
    private authService: AuthService
  ) {}

  currentUser$: Observable<User> = this.authService.currentUser$;

  transform(chat: ChatEntity | Chat, ..._: unknown[]): Observable<AvatarDisplayable> {
    if (!isTruthy(chat)) {
      return of(null);
    }
    return this.store.select(ParticipantsSelectors.getParticipants(chat.id)).pipe(
      withLatestFrom(this.currentUser$),
      map(([chatParticipants, currentUser]) => {
        return getAvatarEntity(chat, chatParticipants, currentUser);
      })
    );
  }
}
