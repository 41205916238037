import { Expose, Type } from 'class-transformer';
import { Event } from '@breez/shared/models/event.model';
import { MessageStatusEventEnum } from '../enums';
import { CHAT_ID, MESSAGE_ID } from '../types';
import { USER_ID } from '@breez/modules/users/types/user-id.type';

export class MessageStatusEvent {
  @Expose({ name: 'affectedrows' })
  affectedRows: number;

  @Expose({ name: 'chatid' })
  chatId: CHAT_ID;

  @Expose({ name: 'userid' })
  userId: USER_ID;

  @Expose({ name: 'messageids' })
  messageIds: MESSAGE_ID[];

  type: MessageStatusEventEnum;
}

export class MessageStatusSubscribeEvent extends Event<MessageStatusEvent> {
  @Expose({ name: 'data' })
  @Type(() => {
    return MessageStatusEvent;
  })
  data: MessageStatusEvent;
}
