import { createAction, props } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { propsProcessing } from '@breez/helpers/+state/execution-action.helper';

export const startProcessing = createAction(
  '[Root] StartProcessing',
  props<{
    action: TypedAction<string>;
    objectId?: number | string;
    timeout?: number;
  }>()
);

export const stopProcessing = createAction(
  '[Root] StopProcessing',
  props<{
    action: TypedAction<string>;
    objectId?: number | string;
  }>()
);

export const customProcessingStart = createAction(
  '[Root] CustomProcessing',
  propsProcessing<{ objectId: number | string }>
);

export const customProcessingStop = createAction(
  '[Chat/API] CustomProcessing Stop',
  (params: { objectId: number | string }) => {
    return propsProcessing<{ objectId: number | string }>(params, customProcessingStart, 'objectId');
  }
);
