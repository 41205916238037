import { Expose, Transform } from 'class-transformer';

export class LayoutEventFrame {
  @Expose({ name: 'id' }) id: number; /* ID фрейма */

  /* UUID - web клиент, INTEGER - нативный клиент, NULL - во фрейме нет терминала */
  @Expose({ name: 'terminalid' })
  @Transform(({ value }) => {
    return value === undefined ? undefined : value;
  })
  terminalId?: string | number = undefined;

  @Expose({ name: 'fullscreen' }) fullScreen?: boolean;
  @Expose({ name: 'voiceactivate' }) voiceActivate?: boolean;
  @Expose({ name: 'canvoiceactivate' }) canVoiceActivate?: boolean;
}
