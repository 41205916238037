<vks-icon
  *ngIf="iconPrefix"
  [class]="prefixClass"
  [color]="iconPrefix.color"
  [icon]="iconPrefix.icon"
  [style.left.px]="iconPrefix.left"
  [style.top.px]="iconPrefix.top"
>
</vks-icon>
<input
  vks-cinput
  *ngIf="!matAutocomplete"
  [formControl]="formControl"
  [placeholder]="placeholder | translate"
  class="indicate-focus-placeholder"
  type="text"
/>
<ng-container *ngIf="autocomplete">
  <input
    vks-cinput
    [formControl]="formControl"
    [matAutocomplete]="matAutocomplete"
    [placeholder]="placeholder | translate"
    class="indicate-focus-placeholder"
    type="text"
  />
  <mat-autocomplete [displayWith]="displayAutocompleteRow">
    <mat-option *ngFor="let entity of autocompleteData" [value]="entity | nestedFields: autocomplete?.valuePath">
      {{ entity | nestedFields: autocomplete?.labelPath }}
    </mat-option>
  </mat-autocomplete>
</ng-container>
<button
  *ngIf="searchControlValue$ | async"
  vks-button
  (buttonClick)="resetSearch()"
  iconClass="square10"
  class="btn-basic btn-compressed fill-gray"
  icon="x-10"
></button>
