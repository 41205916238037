import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ValidationError } from './validation-error.model';

const ERROR = {
  type: 'specialsymbols',
  label: 'ALIAS_VALIDATION_ERROR'
} as ValidationError;

export function specialSymbolsValidator(): ValidatorFn {
  const illegalCharactersPattern = new RegExp('[!§±~@№#$%^&*[\\]()`+,.?"/:{}|<>=;\\s\\\\]');

  return (control: AbstractControl): ValidationErrors | null => {
    const controlValue = control.value;
    if (!controlValue) {
      return null;
    }

    if (!illegalCharactersPattern.test(controlValue)) {
      return null;
    }

    return { [ERROR.type]: ERROR } as ValidationErrors;
  };
}
