import { InjectionToken, Provider, Type } from '@angular/core';
import { NgxdProvider } from '@ngxd/core';

export interface UserListTypesComponentProvider extends NgxdProvider<string, Type<any>> {}

export const USERLISTTYPES_PROVIDER = new InjectionToken<string>('UserList type Provider');

export function provideUserListTypeComponent(type: string, component: Type<any>): Provider {
  return {
    provide: USERLISTTYPES_PROVIDER,
    useValue: <UserListTypesComponentProvider>{
      type,
      component
    },
    multi: true
  };
}
