import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[vks-debounce-click]'
})
export class DebounceClickDirective implements OnInit, OnDestroy {
  @Input()
  debounceTime: number = 500;

  @Input() disabled: boolean = false;

  @Output()
  debounceClick: EventEmitter<PointerEvent> = new EventEmitter();

  private clicks: Subject<PointerEvent> = new Subject();
  private subscription: Subscription;

  ngOnInit(): void {
    this.subscription = this.clicks.pipe(debounceTime(this.debounceTime)).subscribe($event => {
      if (!this.disabled) {
        this.debounceClick.emit($event);
      } else {
        $event.preventDefault();
        $event.stopPropagation();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostListener('click', ['$event'])
  clickEvent(event: PointerEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }

  @HostListener('dblclick', ['$event'])
  dblclickEvent(event: PointerEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }
}
