import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { FilterItem, FilterItemOption } from '@breez/models/filters/filter-item';
import { FiltersService } from '@breez/shared/services/filters/filters.service';

@Component({
  selector: 'vks-select-option-filter',
  templateUrl: './select-option-filter.component.html',
  styleUrls: ['./select-option-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectOptionFilterComponent implements OnChanges {
  @Input()
  item: FilterItem;

  @Input()
  valueFromParams: string;

  @Input() projectToPath = true;

  @Output() change: EventEmitter<FilterItemOption> = new EventEmitter<FilterItemOption>();

  constructor(private filtersService: FiltersService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.valueFromParams) {
      this.getFilterValue();
    }
  }

  selectFilterValue(option: any): void {
    const filterValue = option.value ? option.key.toString() : null;
    if (this.projectToPath) {
      this.filtersService.setFilters({ [this.item.paramName]: filterValue });
    }
    this.change.emit(option);
  }

  private getFilterValue(): void {
    if (!this.valueFromParams) {
      this.valueFromParams = this.item.defaultValue.key;
    }
  }
}
