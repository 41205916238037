import { MonoTypeOperatorFunction, pipe } from 'rxjs';
import { bufferCount, map, startWith, takeWhile } from 'rxjs/operators';

export function takeThenCloseIf<T>(condition: (value: T) => boolean, count: number = 1): MonoTypeOperatorFunction<T> {
  const NONE = {};
  const startValues: T[] = new Array(count).fill(<T>NONE);
  return pipe(
    startWith(...startValues),
    bufferCount(count + 1, 1),
    takeWhile(([firstValue]) => {
      return firstValue === NONE ? true : !condition(firstValue);
    }),
    map(buffer => {
      return buffer[count];
    })
  );
}
