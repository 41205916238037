<mat-toolbar>
  <div class="logo">
    <div [class]="selectedLang$ | async"></div>
  </div>
</mat-toolbar>
<div class="recommendation-container">
  <div class="recommendation">
    <div class="title">{{ 'PROBLEM_WITH_BROWSER' | translate }}</div>
    <div *ngIf="(recommendationPayload$ | async)?.browsersList?.length" class="recommendation-browser">
      <div class="browsers-list">
        <div *ngFor="let browser of (recommendationPayload$ | async).browsersList" class="browser">
          <vks-icon [icon]="browser.icon"></vks-icon>
          <span class="browser-name">
            {{
              browser.link
                ? browser.name
                : ('BROWSER_RECOMMENDATIONS.' + browser.action | translate) + ' ' + browser.name
            }}
          </span>
          <a
            *ngIf="browser.link"
            vks-button
            [href]="browser.link"
            [label]="browser.action | translate"
            class="btn-anchor"
            target="_blank"
          >
          </a>
        </div>
      </div>
      <div class="delimiter">
        <mat-divider vertical></mat-divider>
        <span class="inset">{{ 'OR' | translate }}</span>
        <mat-divider vertical></mat-divider>
      </div>
      <div class="device">
        <div class="device-message">
          <vks-icon icon="monitor"></vks-icon>
          <span class="hint">{{ 'LOGIN_FROM_ANOTHER_DEVICE' | translate }}</span>
        </div>
      </div>
    </div>
    <p
      *ngIf="browserRecommendation$ | async"
      [innerHTML]="recommendationText$ | async | safe: 'html'"
      class="recommendation-text"
    ></p>
    <button
      *ngIf="appAvailable"
      vks-button
      [label]="'CONTINUE' | translate | uppercase"
      (buttonClick)="saveUserGesture()"
      class="btn-primary"
    ></button>
  </div>
</div>
<footer>
  <vks-control-language-selector></vks-control-language-selector>
  <a vks-button class="btn-anchor" [label]="'COPYRIGHT' | translate" href="https://k2t.app" target="_blank"> </a>
</footer>
