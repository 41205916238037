import { Injectable } from '@angular/core';
import { FilterActions } from '@breez/shared/enums/filters/filter-actions.enum';
import { NestedFieldsPipe } from '@breez/shared/pipes/nested-field-pipe/nested-fields.pipe';
import { replayWhileSubs } from '@breez/shared/rxjs-operators';
import { Observable, of } from 'rxjs';
import { FilterItem, FilterItemOption } from '@breez/models/filters/filter-item';
import * as filtersJSON from 'assets/filters.json';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { debounceTime, shareReplay } from 'rxjs/operators';
import moment from 'moment';
import { Periods } from '@breez/shared/enums/filters/periods.enum';
import { IFilterPeriod } from '@breez/models/shared/filtered-entity.model';
import { periodGettersMap } from '@breez/modules/conference/models/period-getters-map';

@Injectable()
export class FiltersService {
  // TODO: получать фильтры с сервера, а не с assets
  filters$: Observable<FilterItem[]> = of(FiltersService.getDataFromJson()).pipe(shareReplay(1));

  filterValues$: Observable<Params> = this.activatedRoute.queryParams.pipe(debounceTime(300), replayWhileSubs());

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private static getDataFromJson() {
    return JSON.parse(JSON.stringify(filtersJSON)).default;
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private nestedFieldsPipe: NestedFieldsPipe
  ) {}

  destroy(): void {}

  setFilters(params: Params): void {
    this.router.navigate([], {
      queryParams: params,
      queryParamsHandling: 'merge'
    });
  }

  createDateRangeParams(paramName: string, startDate: string, endDate: string, option?: string): Params {
    return {
      [paramName + '_start']: startDate,
      [paramName + '_end']: endDate,
      [paramName]: option
    };
  }

  filterDataByPeriod(
    data: any[],
    filters: Params,
    filterParam: string,
    entityDateProperty: string,
    period?: IFilterPeriod
  ): any[] {
    let endValue: Date = null;
    let startValue: Date = null;
    if (filters[filterParam]) {
      if (filters[filterParam] !== 'all_conferences' && filters[filterParam] !== 'all') {
        period = period || this.convertOptionToPeriod(filters[filterParam]);
        endValue = period.end;
        startValue = period.start;
      }
    } else {
      if (filters[filterParam + '_start']) {
        startValue = moment(filters[filterParam + '_start'], 'DD-M-YYYY').toDate();
      }
      if (filters[filterParam + '_end']) {
        endValue = moment(filters[filterParam + '_end'], 'DD-M-YYYY')
          .endOf('day')
          .toDate();
      }
    }

    return data.filter(item => {
      let comparisonValue = true;
      if (startValue) {
        comparisonValue = item[entityDateProperty] >= startValue;
      }
      if (endValue) {
        comparisonValue = comparisonValue && item[entityDateProperty] <= endValue;
      }
      return comparisonValue;
    });
  }

  applySelectOptionFilter<T>(filterItem: FilterItem, selectedOption: FilterItemOption, allValues: T[]): T[] {
    if (!filterItem.isPageSizeFilter) {
      // TODO: переделать на фабрику
      if (selectedOption && selectedOption.value) {
        if (+FilterActions[selectedOption.filterAction] === FilterActions.EQUAL) {
          allValues = allValues.filter(conference => {
            return this.nestedFieldsPipe.transform(conference, filterItem.filteredField) === selectedOption.value;
          });
        }
        if (+FilterActions[selectedOption.filterAction] === FilterActions.LESS_OR_EQUAL) {
          allValues = allValues.filter(conference => {
            return this.nestedFieldsPipe.transform(conference, filterItem.filteredField) <= selectedOption.value;
          });
        }
        if (+FilterActions[selectedOption.filterAction] === FilterActions.MORE_OR_EQUAL) {
          allValues = allValues.filter(conference => {
            return this.nestedFieldsPipe.transform(conference, filterItem.filteredField) <= selectedOption.value;
          });
        }
      }
    }
    return allValues;
  }

  convertOptionToPeriod(option: string, startFromNow = false): IFilterPeriod {
    if (option) {
      const periodGetter = periodGettersMap[Periods[option.toUpperCase()]];
      return periodGetter(startFromNow);
    } else {
      return {
        start: null,
        end: null
      };
    }
  }
}
