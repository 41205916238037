import { Injectable } from '@angular/core';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { map, scan, shareReplay } from 'rxjs/operators';
import { OverlayContainer, OverlayEntry } from '@breez/modules/overlay/models';

@Injectable({
  providedIn: 'root'
})
export class OverlayService {
  private newContainers$ = new BehaviorSubject<Partial<OverlayEntry>>(null);
  private removeContainers$ = new BehaviorSubject<Partial<OverlayEntry>>(null);

  overlayEntries$: Observable<OverlayEntry[]> = merge(
    this.newContainers$.pipe(
      map(entry => {
        return {
          action: 'new',
          entry
        };
      })
    ),
    this.removeContainers$.pipe(
      map(entry => {
        return {
          action: 'remove',
          entry
        };
      })
    )
  ).pipe(
    //    switchMap(() => this.newContainers$),
    scan(
      (acc, cur: { action: 'new' | 'remove'; entry: OverlayEntry }) => {
        // entry exists ?
        if (!cur || !cur.entry) {
          return acc;
        }

        const containerIndex = acc.findIndex(entry => {
          return entry.type === cur.entry.type && entry.id === cur.entry.id;
        });
        // need remove entry ?
        if (cur.action === 'remove') {
          if (containerIndex !== -1) {
            acc.splice(containerIndex, 1);
          }

          return acc;
        }

        // need add or change entry ?
        if (containerIndex === -1) {
          if (cur.entry.data.timeout) {
            setTimeout(() => {
              this.removeContainer(cur.entry);
            }, cur.entry.data.timeout);
          }
          return [...acc, <OverlayEntry>cur.entry];
        }

        // change entry

        //      if (oldContainer.modal !== cur.entry.modal || !oldContainer.position.equals(cur.entry.position)
        //          || !compare(oldContainer.extended, cur.entry.extended)) {
        acc.splice(containerIndex, 1, cur.entry);
        //      }

        return acc;
      },
      <OverlayEntry[]>[]
    ),
    shareReplay(1)
  );

  removeContainer(entry: Partial<OverlayEntry>): void {
    this.removeContainers$.next(entry);
  }

  changeOverlayContainer(entry: OverlayEntry, data: OverlayContainer): void {
    this.newContainers$.next(<Partial<OverlayEntry>>{ ...entry, ...data });
  }

  addContainer(entry: Partial<OverlayEntry>): string {
    const container = {
      ...entry
    };
    this.newContainers$.next(container);

    return container.id;
  }
}
