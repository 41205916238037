import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { allSharedLinks } from './../../shared-buttons';
import { ImagePreviewEntry } from '@breez/modules/image-preview/models/image-preview-entry.model';
import { OverlayPositionType } from '@breez/modules/overlay/models/overlay-position-type.enum';
import { OverlayEntryType } from '@breez/modules/overlay/models/overlay-entry-type.enum';
import { OverlayService } from '@breez/modules/overlay/services/overlay.service';
import { QrCodeService } from '@breez/shared/services/qr-code.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'vks-shared-buttons',
  templateUrl: './shared-buttons.component.html',
  styleUrls: ['./shared-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SharedButtonsComponent {
  @ViewChild('shareButton') shareButton: TemplateRef<any>;

  @Input()
  url: string;

  @Input()
  modal = true;

  links = allSharedLinks;

  @Output() closeShareButtons = new EventEmitter<string>();
  @Output() saveIcsEvent = new EventEmitter<string>();

  constructor(
    private readonly dialog: MatDialog,
    private overlayService: OverlayService,
    private qrCodeService: QrCodeService
  ) {}

  shareLink(): void {
    this.dialog.open(this.shareButton, {
      panelClass: 'shared-buttons',
      width: 'auto'
    });
  }

  showQrCode(): void {
    this.qrCodeService
      .getDataUrl(this.url)
      .pipe(take(1))
      .subscribe(dataUrl => {
        const qrCode: ImagePreviewEntry = {
          selected: true,
          imageFile: {
            name: 'QR-code',
            contentUrl: dataUrl,
            previewContentUrl: dataUrl
          }
        };
        this.overlayService.addContainer({
          id: {},
          position: OverlayPositionType.CENTER,
          zIndex: 1100,
          noBackdrop: false,
          modal: true,
          type: OverlayEntryType.IMAGE_PREVIEW,
          data: { images: [qrCode] }
        });
      });
  }

  saveIcs(): void {
    this.saveIcsEvent.next(this.url);
  }
}
