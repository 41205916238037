import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserSelectModule } from '@breez/modules/user/user-select';
import { MODULE_COMPONENTS } from './components';

@NgModule({
  declarations: [MODULE_COMPONENTS],
  imports: [CommonModule, UserSelectModule],
  exports: [MODULE_COMPONENTS]
})
export class ControlUserSelectModule {}
