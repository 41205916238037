<ng-container>
  <form [formGroup]="form" class="for-conference-enter" target="_blank">
    <div class="column-flex border-block">
      <vks-media-sources-preview
        [mediaStream]="boxedMediaStream$ | async"
        [effectsLoading]="effectsLoading$ | async"
        [sources]="mediaSources$ | async"
        [sourcesPermissions]="sourcesPermissions$ | async"
      >
      </vks-media-sources-preview>
      <ng-container *ngIf="hasLicence$ | async">
        <vks-media-sdk-effects-control
          [enabled]="isBlurEnable$ | async"
          (changeEnabled)="this.setBlurEnableValue($event)"
        ></vks-media-sdk-effects-control>
      </ng-container>
    </div>
    <div class="row-flex margin-bottom" vks-control-group>
      <vks-media-source-selector
        *ngIf="videoInputSources$ | async; let videoInputSources"
        [sources]="videoInputSources"
        [sourceKind]="MediaSourceKind.VIDEO_INPUT"
        [hasPermission]="(videoInputPermission$ | async) !== 'denied'"
        [warning]="videoInputWarning$ | async"
        [enabled]="form.controls.videoInput.value?.enabled"
        [class.wide-select]="!forConferenceEnter"
        showToggle="true"
        formControlName="videoInput"
      >
      </vks-media-source-selector>
    </div>
    <div class="row-flex margin-bottom" vks-control-group>
      <vks-media-source-selector
        *ngIf="audioInputSources$ | async; let audioInputSources"
        [sources]="audioInputSources"
        [sourceKind]="MediaSourceKind.AUDIO_INPUT"
        [hasPermission]="(audioInputPermission$ | async) !== 'denied'"
        [warning]="audioInputWarning$ | async"
        [enabled]="form.controls.audioInput.value?.enabled"
        [class.wide-select]="!forConferenceEnter"
        showToggle="true"
        formControlName="audioInput"
      >
      </vks-media-source-selector>
    </div>
    <div class="row-flex margin-bottom" vks-control-group>
      <vks-media-source-selector
        *ngIf="audioOutputSources$ | async; let audioOutputSources"
        [sources]="audioOutputSources"
        [sourceKind]="MediaSourceKind.AUDIO_OUTPUT"
        [hasPermission]="(audioOutputPermission$ | async) !== 'denied'"
        [warning]="audioOutputWarning$ | async"
        [enabled]="form.controls.audioOutput.value?.enabled"
        [class.wide-select]="!forConferenceEnter"
        showToggle="true"
        formControlName="audioOutput"
      >
      </vks-media-source-selector>
    </div>
  </form>
</ng-container>

<!--<ng-container *ngIf="!forConferenceEnter">
    <h3 translate>DEVICE_SETTINGS</h3>
    <form [formGroup]="form"
          target="_blank"
    >
        <div class="control-row">
            <div class="flex-auto"
                 vks-control-group>
                <label translate>CAMERA</label>
                <vks-media-source-selector [sources]="videoInputSources$ | async"
                                           [sourceKind]="MediaSourceKind.VIDEO_INPUT"
                                           [hasPermission]="(videoInputPermission$ | async) !== 'denied'">
                </vks-media-source-selector>
            </div>
            <div *ngIf="!isMobile"
                 class="preview"
                 vks-control-group>
                <video #videoPreview
                       [hidden]="!form.controls.videoInput.value"
                       muted
                       playsinline></video>
            </div>
        </div>
        <div class="control-row">
            <div class="flex-auto"
                 vks-control-group>
                <label translate>MICROPHONE</label>
                <vks-media-source-selector [sources]="audioInputSources$ | async"
                                           [sourceKind]="MediaSourceKind.AUDIO_INPUT"
                                           [hasPermission]="(audioInputPermission$ | async) !== 'denied'"
                                           formControlName="audioInput">
                </vks-media-source-selector>
                <vks-sound-detector *ngIf="!isMobile && form.controls.audioInput.value"
                                    [mediaStream]="boxedMediaStream$ | async">
                </vks-sound-detector>
            </div>
            <div *ngIf="!isMobile"
                 class="preview"
                 vks-control-group></div>
        </div>
        <div class="control-row">
            <div class="flex-auto"
                 vks-control-group>
                <label translate>PLAYING_DEVICES</label>
                <vks-media-source-selector [sources]="audioOutputSources$ | async"
                                           [sourceKind]="MediaSourceKind.AUDIO_OUTPUT"
                                           [hasPermission]="(audioOutputPermission$ | async) !== 'denied'"
                                           formControlName="audioOutput">
                </vks-media-source-selector>
            </div>
            <div class="preview"
                 vks-control-group>
                <vks-audio-player #soundCheckPlayer
                                  *ngIf="form.controls.audioOutput.value"
                                  [outputDeviceId]="form.controls.audioOutput.value?.id"
                                  source="assets/sounds/sound-check.ogg">
                    <button (click)="soundCheckPlayer.play()"
                            [label]="'CHECK' | translate"
                            class="btn-anchor"
                            icon="sound-check"
                            type="button"
                            vks-button>
                    </button>
                </vks-audio-player>
            </div>
        </div>
        <div *ngIf="noModal"
             style="flex: 1 1 auto;"></div>
    </form>
</ng-container>-->
