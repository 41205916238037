import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UserAvatarComponent } from '@breez/modules/user/user-avatar/user-avatar.component';
import { PipesModule } from '@breez/shared/pipes/pipes.module';
import { ControlsModule } from '@breez/modules/controls/controls.module';

@NgModule({
  declarations: [UserAvatarComponent],
  imports: [CommonModule, PipesModule, ControlsModule],
  exports: [UserAvatarComponent]
})
export class UserAvatarModule {}
