import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnChanges } from '@angular/core';
import { AudioContextService } from '@breez/modules/webrtc/audio-context.service';
import { EmitOnChange } from '@breez/shared/utilities/decorators/emit-on-change.decorator';
import { Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'vks-sound-detector',
  templateUrl: './sound-detector.component.html',
  styleUrls: ['./sound-detector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SoundDetectorComponent implements OnChanges {
  @Input()
  @HostBinding('style.--bar-color')
  color: string = '#0F7A5E'; // 'greenDark'

  @Input()
  @HostBinding('class.disabled')
  disabled: boolean;

  @Input() mediaStream: { stream: MediaStream };

  @EmitOnChange<{ stream: MediaStream }, MediaStream>('mediaStream', {
    emitter: (value, subject) => {
      return subject.next(value?.stream);
    }
  })
  mediaStream$ = new ReplaySubject<MediaStream>(1);

  soundVolume$: Observable<number> = this.mediaStream$.pipe(
    switchMap(mediaStream => {
      return this.audioContextService.getAudioVolumeIndicator(mediaStream);
    })
  );

  soundVolumeCssPercentage$: Observable<string> = this.soundVolume$.pipe(
    map(volume => {
      return volume * 100 + '%';
    })
  );

  constructor(
    private audioContextService: AudioContextService,
    private cd: ChangeDetectorRef
  ) {
    this.soundVolumeCssPercentage$.pipe(untilDestroyed(this)).subscribe(() => {
      this.cd.detectChanges();
    });
  }

  ngOnChanges(): void {
    this.cd.detectChanges();
  }
}
