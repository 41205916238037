export interface IClientRecommendation {
  name: string;
  action: 'DOWNLOAD' | 'UPDATE' | 'OPEN_IN';
  link?: string;
  icon: 'chrome' | 'yandex' | 'firefox' | 'safari' | 'opera' | 'chromium' | 'edge' | 'ios';
}

export interface IRecommendationPayload {
  textLinks?: string[];
  browsersList: IClientRecommendation[];
}

export const BrowsersMap: { [key: number]: IRecommendationPayload } = {
  1: {
    textLinks: [''],
    browsersList: [
      {
        name: 'Chrome',
        action: 'DOWNLOAD',
        link: 'https://www.google.ru/intl/ru/chrome/',
        icon: 'chrome'
      }
    ]
  },
  2: {
    textLinks: [''],
    browsersList: [
      {
        name: 'Chrome',
        action: 'UPDATE',
        link: 'https://www.google.ru/intl/ru/chrome/',
        icon: 'chrome'
      },
      {
        name: 'Opera',
        action: 'UPDATE',
        link: 'https://www.opera.com/ru',
        icon: 'opera'
      },
      {
        name: 'Yandex',
        action: 'UPDATE',
        link: 'https://browser.yandex.ru/',
        icon: 'opera'
      },
      {
        name: 'Microsoft Edge',
        action: 'UPDATE',
        icon: 'edge',
        link: 'https://www.microsoft.com/ru-ru/edge'
      },
      {
        name: 'Firefox',
        action: 'UPDATE',
        icon: 'firefox',
        link: 'https://www.mozilla.org/ru/firefox/'
      },
      {
        name: 'Chromium',
        action: 'UPDATE',
        icon: 'chromium',
        link: 'https://github.com/deemru/chromium-gost/releases/latest'
      }
    ]
  },
  3: {
    textLinks: [''],
    browsersList: [
      {
        name: 'Chrome',
        action: 'DOWNLOAD',
        link: 'https://www.google.ru/intl/ru/chrome/',
        icon: 'chrome'
      }
    ]
  },
  5: {
    textLinks: [''],
    browsersList: [
      {
        name: 'Chrome',
        action: 'DOWNLOAD',
        link: 'https://www.google.ru/intl/ru/chrome/',
        icon: 'chrome'
      }
    ]
  },
  6: {
    browsersList: [
      {
        name: 'Safari',
        icon: 'safari',
        action: 'OPEN_IN'
      }
    ]
  },
  7: {
    browsersList: [
      {
        name: 'iOS',
        action: 'UPDATE',
        icon: 'ios'
      }
    ]
  }
};
