export interface Account {
  event: string;
  code: number;
  traceId: string /* UUID события, по которому можно отследить событие сервера */;
  data: {
    user: {
      userid: number /* ID пользователя */;
      name: string /* Имя пользователя */;
      email: string /* EMAIL пользователя */;
      phone?: string /* Телефон пользователя */;
      organizationid?: number /* ID организации, к которой принадлежит пользователь */;
      organizationname?: string /* Название организации, к которой принадлежит пользователь */;
      departmentid?: number /* ID подразделения, к которому принадлежит пользователь */;
      departmentname?: string /* Название подразделения, к которой принадлежит пользователь */;
      departmenttype?: 'custom' | 'external' /* Тип подрпзделения пользователя */;
      jobtitle?: string /* Должность пользователя */;
      statusid: number /* ID статуса пользователя в системе */;
      status_name?: string /* Название статуса пользователя в системе */;
      can_invited_into_conference: boolean /* Может ли пользователь быть приглашен в конференцию */;
      multilogin: number /* Количество одновременных активных сессий на сервере */;
    };
    roles: Role[];
    push: {
      publicKey: string /* VAPID key продукта */;
    };
  };
}

interface Role {
  name: string /* Название роли, которая включена для пользователя */;
}

export interface Credentials {
  subscription: {
    endpoint: string; // уникальный url, по которому отправляются push-уведомления;
    expirationTime: EpochTimeStamp | null; // время жизни;
    keys: {
      p256dh: string; // открытый ключ;
      auth: string; // закрытый ключ;
    };
  };
}

export interface NotificationSettingsItem {
  active: boolean; // включена или выключена опция, null - заблокировано или не тронуто системой
  endpoint?: string; // уникальный url, по которому отправляются push-уведомления;
  expirationTime?: EpochTimeStamp | null;
  keys?: {
    p256dh: string; // открытый ключ;
    auth: string; // закрытый ключ;
  };
  onlyAuth?: boolean;
  onlyAdmin?: boolean;
  onlyWithRoles?: string[];
  canBeWithRoles?: string[];
}

export enum NotificationsPermissionEnum {
  DENIED = 'denied', // Пользователь отказался от отображения уведомлений;
  GRANTED = 'granted', // Пользователь дал согласие на отображение уведомлений;
  DEFAULT = 'default' // выбор пользователя неизвестен, и поэтому браузер будет действовать так, как если бы значение было denied
}
