import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { GiphyService } from '@breez/modules/chat/services/giphy.service';
import { combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, startWith, take } from 'rxjs/operators';
import IGif from '@giphy/js-types/dist/gif';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { fromControl } from '@breez/shared/rxjs-operators/from-control';
import { GiphyContentTypeEnum } from '@breez/modules/chat/enums/giphy-content-type.enum';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { replayWhileSubs } from '@breez/shared/rxjs-operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IGiphyContent } from '@breez/modules/chat/components/giphy-popup/model/giphy-content';
import { StateService } from '@breez/shared/services/state.service';
import { IGiphyDialogResult } from '@breez/modules/chat/components/giphy-popup/model/giphy-dialog-result';
import { IGiphyContentTypeItem } from '@breez/modules/chat/components/giphy-popup/model/giphy-content-type';

@UntilDestroy()
@Component({
  selector: 'vks-giphy-popup',
  templateUrl: './giphy-popup.component.html',
  styleUrls: ['./giphy-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GiphyPopupComponent implements AfterViewInit {
  private readonly GIPHY_TERMS_LINK =
    'https://support.giphy.com/hc/en-us/articles/360020027752-GIPHY-User-Terms-of-Service';

  private readonly _giphyContentType$: Subject<GiphyContentTypeEnum> = new Subject<GiphyContentTypeEnum>();

  @ViewChild('gridTarget') gridTarget: ElementRef;

  keyword = new UntypedFormControl('');
  keyword$: Observable<string> = fromControl(this.keyword, 500).pipe(distinctUntilChanged());

  giphyContentType$: Observable<GiphyContentTypeEnum> = this._giphyContentType$.pipe(
    filter(isTruthy),
    startWith(GiphyContentTypeEnum.GIFS),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  isMobileDevice$: Observable<boolean> = this.stateService.isMobileDevice$;

  giphyContentTypeItems$: Observable<IGiphyContentTypeItem[]> = this.giphyService.getGiphyContentTypeItems();

  constructor(
    private readonly giphyService: GiphyService,
    private readonly dialog: MatDialog,
    private readonly stateService: StateService
  ) {}

  ngAfterViewInit(): void {
    combineLatest([this.keyword$, this.giphyContentType$])
      .pipe(untilDestroyed(this))
      .subscribe(([query, contentType]) => {
        const giphy = <IGiphyContent>{
          query,
          contentType
        };
        if (contentType === GiphyContentTypeEnum.EMOJI) {
          this.keyword.disable();
        } else {
          this.keyword.enable();
        }

        this.giphyService.renderGrid(this.gridTarget.nativeElement, this.processClosingDialog, giphy);
      });
  }

  processClosingDialog = (gif: IGif, event: MouseEvent): void => {
    event.preventDefault();
    this.giphyContentType$.pipe(take(1)).subscribe(contentType => {
      this.dialog.getDialogById('gifs').close(<IGiphyDialogResult>{
        content: gif,
        contentType
      });
    });
  };

  onToggleGroupValueChange($event: any): void {
    this._giphyContentType$.next($event);
  }
}
