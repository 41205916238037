import { MonoTypeOperatorFunction, pipe } from 'rxjs';
import { publishReplay, refCount } from 'rxjs/operators';

/**
 * Group of publishReplay & refCount operators.
 * Replays last value to new subscribers; holds a subscription to source as long as there is at least one subscriber
 */
export function replayWhileSubs<T>(): MonoTypeOperatorFunction<T> {
  return pipe(publishReplay(1), refCount());
}
