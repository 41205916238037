export enum ColumnType {
  DATE,
  STRING,
  CONFERENCE_PARAMETERS,
  DURATION,
  CLICK,
  FILE_SIZE,
  SIMPLE_DURATION,
  USER_AVATAR,
  TEMPLATE,
  CONFERENCE_STATE
}
