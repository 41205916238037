import { InjectionToken, Provider } from '@angular/core';
import { UserStatusIndividualSourceService } from './services/user-status-individual-source.service';
import { IUserStatusSource } from '../models/user-status-source.model';

export const USER_STATUS_SOURCE = new InjectionToken<IUserStatusSource>('User status source service');

export const USER_STATUS_SOURCE_PROVIDER = <Provider>{
  provide: USER_STATUS_SOURCE,
  useClass: UserStatusIndividualSourceService
};
