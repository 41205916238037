import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { SettingsItemModel } from '@breez/models/settings';
import { PlannerSettingsItem } from '@breez/modules/conference/modules/planner/services/planner-settings-item.enum';
import { BehaviorSubject } from 'rxjs';
import { ValidationError } from './validation-error.model';
import { DatePipe } from '@angular/common';

const ERROR = {
  type: 'moratorium',
  label: 'MORATORIUM_VALIDATION_FEEDBACK'
} as ValidationError;

export function moratoriumValidator(settings$: BehaviorSubject<SettingsItemModel[]>, datePipe: DatePipe): ValidatorFn {
  const error = {
    ...ERROR,
    args: { hours: null }
  };
  return (control: AbstractControl): ValidationErrors | null => {
    const controlValue = control.value as Date;
    if (!controlValue) {
      return null;
    }

    const settings = settings$.value;
    if (settings.length === 0) {
      return null;
    }

    const moratoriumLimit = parseInt(
      settings.find(setting => {
        return setting.key === PlannerSettingsItem.MORATORIUM;
      }).value,
      10
    );
    if (moratoriumLimit === 0) {
      return null;
    }

    const moratoriumEndDate = new Date();
    moratoriumEndDate.setHours(moratoriumLimit + moratoriumEndDate.getHours());

    if (moratoriumEndDate?.getTime() - controlValue?.getTime() <= 60000 /* 1 minute */) {
      return null;
    }

    error.args.hours = datePipe.transform(moratoriumEndDate, 'dd.MM, HH:mm');
    return { [error.type]: error } as ValidationErrors;
  };
}
