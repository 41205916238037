import { Injectable } from '@angular/core';
import { IShortcut } from '@breez/components/shortcuts-dialog/model/shortcut.model';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShortcutsService {
  private readonly symbols = {
    shift: '&#8679;',
    up: '&#8593;',
    down: '&#8595;',
    alt: '&#9095;',
    meta: '&#8984;'
  };

  private readonly shortcutsList$: Observable<IShortcut[]> = of([
    {
      action: 'TOGGLE_VIDEO',
      keysCombination: ['V'],
      module: 'conference'
    },
    {
      action: 'TOGGLE_MICROPHONE',
      keysCombination: ['M'],
      module: 'conference'
    },
    {
      action: 'TOGGLE_SCREENCAST',
      keysCombination: ['shift', 'S'],
      module: 'conference'
    },
    {
      action: 'TOGGLE_FULLSCREEN',
      keysCombination: ['F'],
      module: 'conference'
    },
    {
      action: 'PICTURE_IN_PICTURE_MODE',
      keysCombination: ['I'],
      module: 'conference'
    },
    {
      action: 'SELECT_LAYOUT',
      keysCombination: ['L'],
      module: 'conference'
    },
    {
      action: 'TOGGLE_RECORD',
      keysCombination: ['shift', 'R'],
      module: 'conference'
    },
    {
      action: 'TOGGLE_PARTICIPANTS_LIST',
      keysCombination: ['P'],
      module: 'conference'
    },
    {
      action: 'RELAY_MICROPHONE',
      keysCombination: ['hold space'],
      module: 'conference',
      extra: 'TURNED_OFF_MICROPHONE'
    },
    {
      action: 'CLOSE_CHAT',
      keysCombination: ['esc'],
      module: 'chat',
      extra: 'ANY_CHAT_OPENED'
    },
    {
      action: 'EDIT_LAST_MESSAGE',
      keysCombination: ['up'],
      module: 'chat'
    },
    {
      action: 'SELECT_NEXT_CHAT',
      keysCombination: ['alt', 'up'],
      module: 'chat'
    },
    {
      action: 'SELECT_PREVIOUS_CHAT',
      keysCombination: ['alt', 'down'],
      module: 'chat'
    }
  ]);

  getShortcutsList(): Observable<IShortcut[]> {
    return this.shortcutsList$;
  }

  getKeyIconByTitle(key: string): string {
    return this.symbols[key] || key;
  }
}
