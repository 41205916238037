import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { OverlayContainer, OverlayEntry } from '@breez/modules/overlay/models';

@Component({
  selector: 'vks-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationComponent {
  @Input()
  entry: OverlayEntry;

  @Output() changeOverlayContainer = new EventEmitter<OverlayContainer>();
}
