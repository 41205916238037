import { ComponentType } from 'ngx-toastr';
import { NotificationType } from './notification-type.enum';

export interface NotificationProvider {
  isDefault?: boolean;
  type?: NotificationType;
  title?: string;
  timeOut?: number;
  positionClass?: string;
  preventDuplicates?: boolean;
  closeButton?: boolean;
  enableHtml?: boolean;
  toastComponent?: ComponentType<any>;
}
