import { Overlay } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LanguageSelectionComponent } from '@breez/components/language-selection';
import { LanguageModel } from '@breez/models';
import { LanguageService } from '@breez/shared/services/language.service';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'vks-control-language-selector',
  templateUrl: './control-language-selector.component.html',
  styleUrls: ['./control-language-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ControlLanguageSelectorComponent {
  languageId$: Observable<string> = this.languageService.interfaceLanguage$;

  language$: Observable<LanguageModel> = this.languageId$.pipe(
    switchMap(languageId => {
      return this.languageService.getLanguageInfo(languageId);
    })
  );

  languageFlagIcon$: Observable<string> = this.language$.pipe(
    map(language => {
      return language.icon;
    })
  );

  constructor(
    private languageService: LanguageService,
    private dialog: MatDialog,
    private overlay: Overlay
  ) {}

  openLanguageSelectionDialog(): void {
    const dialogRef = this.dialog.open(LanguageSelectionComponent, {
      scrollStrategy: this.overlay.scrollStrategies.reposition()
    });
    dialogRef
      .afterClosed()
      .pipe(filter(isTruthy), take(1))
      .subscribe((language: LanguageModel) => {
        this.languageService.switchInterfaceLanguage(language.id);
      });
  }
}
