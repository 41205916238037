import { fromEvent, Observable, of, from } from 'rxjs';

import { catchError, map, switchMap } from 'rxjs/operators';
import { isTruthy } from '@breez/shared/utilities/is-truthy';

export function getPermission(permissionDesc: PermissionDescriptor): Observable<PermissionState> {
  if (!navigator.permissions || !permissionDesc) {
    return of(null);
  }

  return from(
    navigator.permissions
      .query(permissionDesc)
      .then(result => {
        return result.state;
      })
      .catch(() => {
        return null;
      })
  );
}
export function hasPermission(permissionDesc: PermissionDescriptor, fallback: boolean = false): Observable<boolean> {
  return getPermission(permissionDesc).pipe(
    map(state => {
      return isTruthy(state) ? state === 'granted' : fallback;
    })
  );
}

export function observePermission(permissionDesc: PermissionDescriptor): Observable<PermissionState> {
  if (!navigator.permissions || !permissionDesc) {
    return of(null);
  }

  return from(navigator.permissions.query(permissionDesc)).pipe(
    switchMap(status => {
      return fromEvent(status, 'change').pipe(
        map(() => {
          return status.state;
        })
      );
    }),
    catchError(() => {
      return of('denied' as PermissionState);
    })
  );
}

export function requestPermission(
  permissionDesc: PermissionDescriptor,
  fallback: boolean = false
): Observable<boolean> {
  if (!navigator.permissions) {
    return of(fallback);
  }

  return from((navigator.permissions as any).request(permissionDesc)) as Observable<boolean>;
}
