<div class="header" mat-dialog-title>
  <span class="title no-user-select">{{ 'SETTINGS' | translate }}</span>
  <vks-icon
    [mat-dialog-close]="null"
    [matTooltip]="'CLOSE' | translate"
    class="square14"
    color="#9daec3"
    icon="x-8"
  ></vks-icon>
</div>
<mat-dialog-content class="dialog-content">
  <div class="sections" *ngIf="(displaySections$ | async).length > 1">
    <mat-list>
      <mat-list-item
        (click)="selectSection(section)"
        *ngFor="let section of displaySections$ | async"
        [class.active]="section.id === (currentSectionId$ | async)"
      >
        <vks-icon *ngIf="section.icon" [icon]="section.icon" color="gray" hoverColor="gray"></vks-icon>
        {{ section.label | translate }}
      </mat-list-item>
    </mat-list>
  </div>
  <div class="section">
    <ng-container *ngIf="currentSection$ | async as currentSection" [ngSwitch]="currentSectionId$ | async">
      <vks-media-sources-selector
        (resultSources)="saveSources($event)"
        (effectsSdkSettingEmit)="saveEffectsSdkSettings($event)"
        *ngSwitchCase="userSettingSectionId.USER_DEVICES"
      ></vks-media-sources-selector>
      <vks-app-settings
        (close)="close()"
        *ngSwitchCase="userSettingSectionId.GENERAL_SETTINGS"
        [key]="currentSection.id"
        [label]="currentSection.label"
        [scheme]="currentSection.scheme"
      ></vks-app-settings>
    </ng-container>
  </div>
</mat-dialog-content>
