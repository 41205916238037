import { ChatParticipantRightsEnum, ChatParticipantEnum } from '../enums';
import { User } from '@breez/models';

export class ChatParticipant {
  userId: number;

  user: User;

  type?: ChatParticipantEnum;

  rights?: ChatParticipantRightsEnum[];

  constructor(base?: Partial<ChatParticipant>) {
    return Object.assign(this, base);
  }
}
