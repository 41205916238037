import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ValidationError } from './validation-error.model';

const ERROR = {
  type: 'invalidemail',
  label: 'WRONG_EMAIL_FORMAT'
} as ValidationError;

export function emailValidator(customLabel?: string): ValidatorFn {
  const reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,9})+$/;

  return (control: AbstractControl): ValidationErrors | null => {
    const controlValue = control.value;

    if (!controlValue) {
      return null;
    }

    if (reg.test(controlValue)) {
      return null;
    }

    return { [ERROR.type]: !!customLabel ? { ...ERROR, label: customLabel } : ERROR } as ValidationErrors;
  };
}

export const emailErrorType = ERROR;
