import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'is'
})
export class IsPipe implements PipeTransform {
  transform(value: any, a: any): boolean {
    return value === a;
  }
}
