export enum DeviceSourceType {
  HARDWARE = 'hardware',
  SCREEN = 'screen'
}

export enum PresentationMode {
  USER = 'user',
  ENVIRONMENT = 'environment'
}

export enum SurfaceMode {
  WINDOW = 'window',
  TAB = 'browser',
  SCREEN = 'monitor'
}
