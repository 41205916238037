/**
 * Returns index of word in array related on input number
 * <pre>
 * [ 'День', 'Дня', 'Дней' ], [ 'Минута', 'Минуты', 'Минут' ]
 * </pre>
 * *Russian*
 * @param num
 */
export function declensionNumRu(num: number): number {
  let division = num % 100;

  if (division >= 5 && division <= 20) {
    return 2;
  }

  division = num % 10;

  if (division === 1) {
    return 0;
  } else if (division > 1 && division < 5) {
    return 1;
  } else {
    return 2;
  }
}

/**
 * Returns index of word in array related on input number
 * <pre>
 * [ 'Day', 'Days ], [ 'Minute', 'Minutes' ]
 * </pre>
 * *English*
 * @param num
 */
export function declensionNumEn(num: number): number {
  if (Math.abs(num) === 1) {
    return 0;
  }

  return 1;
}

/**
 * Returns right declension word related on number
 * *Russian*
 * @param num
 * @param dic Variants of declensions, e. g. <pre>
 * [ 'День', 'Дня', 'Дней' ], [ 'Минута', 'Минуты', 'Минут' ]</pre>
 */
export function declensionRu(num: number, dic: string[]): string {
  return dic[declensionNumRu(num)];
}

/**
 * Returns right declension word related on number
 * *English*
 * @param num
 * @param dic Variants of declensions, e. g. <pre>
 * [ 'Day', 'Days ], [ 'Minute', 'Minutes' ]</pre>
 */
export function declensionEn(num: number, dic: string[]): string {
  return dic[declensionNumEn(num)];
}
