import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UserAvatarService } from '@breez/modules/user/user-avatar.service';
import { AvatarDisplayable } from '@breez/modules/user/modules/models/avatar-displayable.model';
import { UserStatus } from '@breez/modules/user/modules/models/user-status.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'vks-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserAvatarComponent implements OnChanges {
  @Input()
  entity: AvatarDisplayable;

  @Input()
  status: UserStatus;

  UserStatus = UserStatus;

  @Input()
  size = 48;

  @Input()
  stroke = false;

  @Input()
  pulseStroke = false;

  @Input()
  displayIndicators = false;

  @Input()
  overlayIcon: string = null;

  /**
   * @deprecated
   */
  @Input() isOnline = false;

  @HostBinding('style.width')
  @HostBinding('style.height')
  @HostBinding('style.min-width')
  @HostBinding('style.min-height')
  hostSize = `${this.size}px`;

  fontSize = `${this.size / 2.5}px`;

  avatarPlaceholder: string;

  constructor(
    private userAvatarService: UserAvatarService,
    private translateService: TranslateService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.entity && changes.entity.currentValue) {
      const trimmedUserName = this.entity.name?.trim() ?? this.translateService.instant('GUEST');
      this.avatarPlaceholder = this.userAvatarService.reduceName(trimmedUserName);
    }

    if (changes.size) {
      if (this.size !== undefined) {
        this.hostSize = this.size + 'px';
        this.fontSize = this.size / 2.5 + 'px';
      } else {
        this.hostSize = 'inherit';
        this.fontSize = 'inherit';
      }
    }
  }
}
