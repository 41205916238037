import { provideCallStatusComponent } from '@breez/modules/call/call-statuses/call-status-component.provider';
import { CallDestinationType, CallStatus } from '@breez/modules/call/models';
import { CallStatusAcceptComponent } from './components';

export const OVERLAY_PROVIDERS = [
  provideCallStatusComponent(
    {
      status: CallStatus.ACCEPT,
      destinationType: CallDestinationType.OUTGOING
    },
    CallStatusAcceptComponent
  ),

  provideCallStatusComponent(
    {
      status: CallStatus.ACCEPT,
      destinationType: CallDestinationType.INCOMING
    },
    CallStatusAcceptComponent
  )
];
