import { Injectable } from '@angular/core';

import { MemoryStorage } from '../interfaces/memory-storage.interface';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class BaseMemoryStorage implements MemoryStorage {
  readonly changes = new ReplaySubject<{ key: string; value: string }>(1);

  constructor() {
    this.initChanges();
  }

  /**
   * Storage data
   */
  private data: { [key: string]: string } = {};

  get length(): number {
    return Object.keys(this.data).length;
  }

  clear(): void {
    this.initChanges(true);
    this.data = {};
  }

  getItem(key: string): string | null {
    return key in this.data ? this.data[key] : null;
  }

  key(index: number): string | null {
    const keys = Object.keys(this.data);

    return index >= 0 && keys.length < index ? keys[index] : null;
  }

  removeItem(key: string): void {
    delete this.data[key];
    this.changes.next({ key, value: null });
  }

  setItem(key: string, value: string): void {
    this.data[key] = value;
    this.changes.next({ key, value });
  }

  private initChanges($needNullValue: boolean = false): void {
    for (let index = 0; index < this.length; index++) {
      const key = this.key(index);
      const value = !$needNullValue ? this.getItem(key) : null;
      this.changes.next({ key, value });
    }
  }
}
