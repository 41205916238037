import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CalendarOption } from '@fullcalendar/angular/private-types';

// import momentPlugin from '@fullcalendar/moment';

@Component({
  selector: 'vks-events-calendar',
  templateUrl: './events-calendar.component.html',
  styleUrls: ['./events-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventsCalendarComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Input()
  defaultView: 'month' | 'basicWeek' | 'basicDay';

  @Input()
  calendarOptions: any;

  @Input()
  locale: string;

  @Input()
  slotLabelFormat: string | Object;

  @Input()
  events: CalendarOption<'events'>;

  @Input()
  hoverText: string;

  @Output()
  eventCLick = new EventEmitter<number>();

  @Output()
  hoverTextClick = new EventEmitter<void>();

  @ViewChild('calendar')
  calendarComponent: any;

  private listenerFn: Array<() => void> = [];

  constructor(
    private renderer: Renderer2,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.calendarOptions = { ...this.calendarOptions };
  }

  ngAfterViewInit(): void {
    if (this.calendarComponent && this.hoverText) {
      const calendarElement: HTMLElement = this.calendarComponent.calendar.el;
      this.listenerFn.push(
        this.renderer.listen(calendarElement.querySelector('.fc-next-button'), 'click', () => {
          return this.addListenersToCalendar(calendarElement);
        })
      );
      this.listenerFn.push(
        this.renderer.listen(calendarElement.querySelector('.fc-prev-button'), 'click', () => {
          return this.addListenersToCalendar(calendarElement);
        })
      );

      this.addListenersToCalendar(calendarElement);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.events && this.calendarComponent) {
      this.calendarComponent.calendar.removeEventSources();
    }
  }

  onEventClick(event: any): void {
    this.eventCLick.emit(event.id);
  }

  ngOnDestroy(): void {
    if (this.listenerFn.length) {
      this.listenerFn.forEach(fn => {
        return fn();
      });
    }
  }

  private addListenersToCalendar(calendarElement: HTMLElement): void {
    calendarElement.querySelectorAll('.fc-day-top').forEach((el: any) => {
      if (!el.classList.contains('fc-past')) {
        this.renderer.setAttribute(el, `hover-text`, this.translateService.instant(this.hoverText));
        this.listenerFn.push(
          this.renderer.listen(el, 'click', () => {
            this.hoverTextClick.emit(el);
          })
        );
      }
    });
  }
}
