<div>
  <ng-container *ngIf="currentUser$ | async as currentUser">
    <div class="user-list">
      <div
        *ngFor="let participant of participants$ | async"
        [class.its-me]="participant.userId === currentUser.id"
        class="user"
      >
        <div (click)="openChat(participant.user.id)" class="about">
          <vks-avatar-smart [entity]="participant.user" size="35"></vks-avatar-smart>
          <span class="participant-name">{{ participant.user.name }}</span>
        </div>
        <div *ngIf="participant.userId !== currentUser.id && (participants$ | async)?.length > 2" class="actions">
          <vks-icon
            [matTooltip]="'REMOVE_PARTICIPANT' | translate"
            (click)="deleteUserFromParticipant(participant.user)"
            class="square14"
            color="#12161C"
            icon="x-8"
          ></vks-icon>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<div class="btn-add">
  <ng-container *ngxPermissionsOnly="'chat:create'">
    <mat-divider></mat-divider>
    <div class="users-actions">
      <button
        vks-button
        [label]="'ADD_PARTICIPANTS' | translate"
        (buttonClick)="openUserPicker()"
        class="btn-anchor"
      ></button>
    </div>
  </ng-container>
</div>
