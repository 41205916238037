import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { StateService } from '@breez/shared/services/state.service';

@Component({
  selector: 'vks-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotFoundPageComponent implements OnInit {
  constructor(private stateService: StateService) {}

  ngOnInit(): void {
    this.stateService.setExpandedGUI(true);
    this.stateService.setHeader(null);
  }
}
