import { BuildType } from '@breez/shared/enums/build-type.enum';
import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
  type: 'staging',
  api: 'api/v1',
  origin: null,
  tokenKey: 'vks-auth-token',
  fileTokenKey: 'vks-file-token',
  ws: null,
  wsApi: '/ws/v1',
  iceServersDomain: null,
  enableTracing: false,
  logLevel: NgxLoggerLevel.ERROR,
  serverLogLevel: NgxLoggerLevel.ERROR,
  release: '0.0.1-BREEZ-803.8',
  version: '2024-09-24T06:41:17.193Z #staging ',
  siteKey: '6LeE2L8UAAAAAOjp825kkTCjGHz_bXlwaGaBg3LE',
  googleMapKey: '',
  enableGiphy: true,
  buildType: BuildType.K2T,
  hmr: false,
  adminLink: ':8090/settings',
  helpLink: ':8090/help-doc'
};
