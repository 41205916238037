import { Expose } from 'class-transformer';
import { EventTypeEnum } from '@breez/models';
import { CHAT_ID } from '../types';

export class ChatParticipantEvent {
  @Expose({ name: 'event' })
  eventType: EventTypeEnum;

  @Expose({ name: 'userid' })
  updatedUserId: number;

  @Expose({ name: 'affectedchatid' })
  affectedChatId: CHAT_ID;

  @Expose({ name: 'conferenceid' })
  conferenceId: number;

  @Expose({ name: 'name' })
  updatedUserName: string;

  constructor(base?: Partial<ChatParticipantEvent>) {
    return Object.assign(this, base);
  }
}
