import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UserListDynamicModule } from '@breez/modules/user/user-list-types/dynamic/dynamic.module';
import { COMPONENTS } from './components';

import { OVERLAY_PROVIDERS } from './overlay-provider';

@NgModule({
  imports: [CommonModule, UserListDynamicModule],
  declarations: [COMPONENTS],
  providers: [OVERLAY_PROVIDERS]
})
export class OverlayUserListModule {}
