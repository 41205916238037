import { Injectable } from '@angular/core';
import { AppService } from '@breez/app.service';
import { ProcessingFileModel, ProcessingFile } from '@breez/models/shared/files/processing-file.model';
import { WebsocketEvents, WebsocketService } from '@breez/modules/websocket';
import { toClass } from '@breez/shared/rxjs-operators';
import { fromEvent, Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  constructor(
    private wsService: WebsocketService,
    private appService: AppService
  ) {}

  fileSizeLimit = 20;

  add(file: ProcessingFileModel): Observable<ProcessingFileModel> {
    return this.wsService.send<ProcessingFileModel>(WebsocketEvents.SEND.FILE.ADD, { data: file }).pipe(
      take(1),
      map(files => {
        return Array.isArray(files) ? files[0] : null;
      }),
      toClass(ProcessingFileModel),
      catchError(() => {
        return of(null);
      })
    );
  }

  edit(file: ProcessingFileModel): Observable<ProcessingFileModel> {
    return this.wsService.send<ProcessingFileModel>(WebsocketEvents.SEND.FILE.EDIT, { data: file }).pipe(
      take(1),
      catchError(() => {
        return of(null);
      })
    );
  }

  get(fileId: string): Observable<ProcessingFileModel> {
    return this.wsService.send<ProcessingFileModel>(WebsocketEvents.SEND.FILE.GET, { id: fileId }).pipe(
      take(1),
      catchError(() => {
        return of(null);
      })
    );
  }

  delete(fileId: string): Observable<ProcessingFileModel> {
    return this.wsService.send<ProcessingFileModel>(WebsocketEvents.SEND.FILE.DELETE, { id: fileId }).pipe(
      take(1),
      catchError(() => {
        return of(null);
      })
    );
  }

  readFile(file: File): Observable<ProcessingFileModel> {
    const reader = new FileReader();
    const exceedFileSize = this.getFileSizeInMb(file.size) > this.fileSizeLimit;

    if (!exceedFileSize) {
      reader.readAsDataURL(file);
      return fromEvent(reader, 'loadend').pipe(
        map(() => {
          return <ProcessingFileModel>{
            fileId: this.appService.newGuid(),
            name: file.name,
            size: String(file.size),
            type: file.type,
            content: <string>reader.result,
            ownerId: null
          };
        }),
        take(1)
      );
    } else {
      return of(null).pipe(
        map(() => {
          return <ProcessingFileModel>{
            fileId: this.appService.newGuid(),
            name: file.name,
            size: String(file.size),
            type: file.type,
            content: '',
            ownerId: null
          };
        }),
        take(1)
      );
    }
  }

  loadBlobFromFile(file: File): Observable<ProcessingFile> {
    if (!file) {
      return of(null);
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);

    return fromEvent(reader, 'loadend').pipe(
      map(() => {
        return <ProcessingFile>{
          base64: reader.result,
          name: file.name,
          size: file.size,
          type: file.type
        };
      })
    );
  }

  getFileSizeInMb(fileSizeInBytes: string | number): number {
    let initialSize: number;
    initialSize = typeof fileSizeInBytes === 'string' ? parseInt(fileSizeInBytes, 10) : fileSizeInBytes;

    return initialSize / (1024 * 1024);
  }

  getFileExtension(fileName: string): string {
    const pointIndex = fileName.lastIndexOf('.');

    return pointIndex === -1 ? 'folder' : fileName.slice(pointIndex + 1).toLowerCase();
  }

  get allowedExtensions(): string[] {
    return [
      '.png',
      '.jpg',
      '.pdf',
      '.doc',
      '.docx',
      '.jpeg',
      '.xls',
      '.xlsx',
      '.zip',
      '.rar',
      '.txt',
      '.rtf',
      '.djvu',
      '.fb2',
      '.ppt',
      '.pptx',
      '.mdb',
      '.accdb',
      '.mp3',
      '.avi',
      '.mpg',
      '.mpeg',
      '.gif',
      '.bmp',
      '.tiff',
      '.mov',
      '.mp4',
      '.wav',
      '.pages',
      '.numbers',
      '.keynote',
      '.psd',
      '.odt',
      '.mp3',
      '.vma',
      '.svg',
      '.mpeg',
      '.flv',
      '.3gp',
      '.avi',
      '.ogg',
      '.vob',
      '.icns'
    ];
  }

  get preventedExtensions(): string[] {
    return [
      'app',
      'ade',
      'adp',
      'asa',
      'ashx',
      'asmx',
      'asp',
      'bas',
      'bat',
      'cdx',
      'cer',
      'chm',
      'class',
      'cmd',
      'cnt',
      'com',
      'config',
      'cpl',
      'crt',
      'csh',
      'der',
      'dll',
      'exe',
      'fxp',
      'gadget',
      'grp',
      'hlp',
      'hpj',
      'hta',
      'htr',
      'htw',
      'ida',
      'idc',
      'idq',
      'ins',
      'isp',
      'its',
      'jse',
      'json',
      'ksh',
      'lnk',
      'mad',
      'maf',
      'mag',
      'mam',
      'maq',
      'mar',
      'mas',
      'mat',
      'mau',
      'mav',
      'maw',
      'mcf',
      'mda',
      'mdb',
      'mde',
      'mdt',
      'mdw',
      'mdz',
      'msc',
      'msh',
      'msh1',
      'msh1xml',
      'msh2',
      'msh2xml',
      'mshxml',
      'msi',
      'ms-one-stub',
      'msp',
      'mst',
      'ops',
      'pcd',
      'pif',
      'pl',
      'prf',
      'prg',
      'printer',
      'ps1',
      'ps1xml',
      'ps2',
      'ps2xml',
      'psc1',
      'psc2',
      'pst',
      'reg',
      'rem',
      'scf',
      'scr',
      'sct',
      'shb',
      'shs',
      'shtm',
      'shtml',
      'soap',
      'stm',
      'svc',
      'url',
      'vb',
      'vbe',
      'vbs',
      'vsix',
      'ws',
      'wsc',
      'wsf',
      'wsh',
      'xamlx',
      'folder' // костыль для того, чтобы исключить папки (this.getFileExtension будет возвращать это значение
      // если lastIndexOf('.') вернёт -1
    ];
  }

  getFilesFromClipboardEvent(event: ClipboardEvent): File[] {
    const files = event.clipboardData.files;

    return Array.from(files);
  }
}
