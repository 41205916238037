import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable, of, merge, combineLatest } from 'rxjs';
import {
  AbstractControl,
  FormControlStatus,
  UntypedFormControl,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import * as breezValidators from '@breez/shared/validators';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import { ImagePreviewEntry } from '@breez/modules/image-preview/models/image-preview-entry.model';
import { OverlayPositionType } from '@breez/modules/overlay/models/overlay-position-type.enum';
import { OverlayEntryType } from '@breez/modules/overlay/models/overlay-entry-type.enum';
import { QrCodeService } from '@breez/shared/services/qr-code.service';
import { OverlayService } from '@breez/modules/overlay/services/overlay.service';
import { InvitingService } from '@breez/modules/inviting/inviting-guests/services/inviting.service';
import { replayWhileSubs } from '@breez/shared/rxjs-operators';
import { getParamValueFromUrl } from '@breez/shared/utilities/get-param-from-url';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { ClipboardService } from 'ngx-clipboard';
import { Store } from '@ngrx/store';
import * as ModuleState from '@breez/modules/chat/+state/module.state';
import * as ExecutionAction from '@breez/+state/execution/execution.actions';
import { SqlErrorCodeEnum } from '@breez/shared/enums/sql-error-code.enum';
import { addValidators, removeValidators } from '@breez/shared/utilities/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

const MAX_EMAIL_LENGTH = 254;

enum StoreExecutionKeyEnum {
  EMAIL = 'INVITING_GUEST_EMAIL',
  COPY = 'INVITING_GUEST_COPY',
  QR = 'INVITING_GUEST_QR'
}

enum SendEmailErrorLabelEnum {
  DEFAULT = 'BREEZ_INVITING_EMAIL_NOT_SENT',
  EXIST = 'BREEZ_REGISTRATION_EMAIL_EXIST'
}

const EMAIL_VALIDATOR = breezValidators.emailValidator('BREEZ_INVITING_WRONG_EMAIL');
const REQUIRED_VALIDATOR = breezValidators.RequiredWithWhitespaceValidator('BREEZ_REGISTRATION_NOT_EMPTY_ERROR');

@UntilDestroy()
@Component({
  selector: 'vks-inviting-guest-popup',
  templateUrl: './inviting-guest-popup.component.html',
  styleUrls: ['./inviting-guest-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvitingGuestPopupComponent implements OnInit, OnDestroy {
  EMAIL_VALIDATOR: ValidatorFn = EMAIL_VALIDATOR;
  REQUIRED_VALIDATOR: ValidatorFn = REQUIRED_VALIDATOR;

  readonly StoreExecutionKeyEnum = StoreExecutionKeyEnum;
  private readonly generateLinkErrorSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  readonly generateLinkError$: Observable<string> = this.generateLinkErrorSubject.pipe(
    debounceTime(100),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  private readonly sendEmailSuccessSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly sendEmailSuccess$: Observable<boolean> = this.sendEmailSuccessSubject.pipe(
    debounceTime(100),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  private readonly copyLinkSuccessSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly copyLinkSuccess$: Observable<boolean> = this.copyLinkSuccessSubject.pipe(
    debounceTime(100),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  private readonly linkSubject$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  readonly link$: Observable<string> = this.linkSubject$.pipe(distinctUntilChanged(), shareReplay(1));

  readonly emailControl: UntypedFormControl = new UntypedFormControl(null, [
    Validators.maxLength(MAX_EMAIL_LENGTH),
    EMAIL_VALIDATOR
  ]);

  private readonly emailControlValueChanges$: Observable<FormControlStatus> = this.emailControl.valueChanges.pipe(
    distinctUntilChanged(),
    replayWhileSubs()
  );

  private readonly sendEmailErrorLabelSubject: BehaviorSubject<SendEmailErrorLabelEnum> =
    new BehaviorSubject<SendEmailErrorLabelEnum>(undefined);

  readonly sendEmailErrorLabel$: Observable<SendEmailErrorLabelEnum> = merge(
    this.emailControlValueChanges$.pipe(
      map(() => {
        return undefined;
      })
    ),
    this.sendEmailErrorLabelSubject
  ).pipe(debounceTime(50), distinctUntilChanged(), replayWhileSubs());

  private readonly emailControlStatus$: Observable<FormControlStatus> = this.emailControl.statusChanges.pipe(
    distinctUntilChanged(),
    replayWhileSubs()
  );

  readonly emailControlErrorLabels$: Observable<string[]> = this.emailControlStatus$.pipe(
    map(() => {
      if (!this.emailControl.errors) {
        return null;
      }
      const errors: ValidationErrors[] = Object.values(this.emailControl.errors);
      if (!errors || errors.length === 0) {
        return null;
      }

      return errors.map(error => {
        if (error.hasOwnProperty('requiredLength')) {
          return 'BREEZ_REGISTRATION_MAX_LENGTH_ERROR';
        }

        return error.label;
      });
    }),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  readonly emailErrorLabels$: Observable<string[]> = combineLatest([
    this.emailControlErrorLabels$,
    this.sendEmailErrorLabel$
  ]).pipe(
    map(([emailControlErrorLabels, sendEmailErrorLabel]) => {
      return (
        !isTruthy(emailControlErrorLabels) ? [sendEmailErrorLabel] : [...emailControlErrorLabels, sendEmailErrorLabel]
      ).filter(isTruthy);
    }),
    replayWhileSubs()
  );

  canSendEmail(): boolean {
    return this.emailControl.valid && isTruthy(this.emailControl.value) && this.emailControl.value !== '';
  }

  constructor(
    private dialogRef: MatDialogRef<InvitingGuestPopupComponent>,
    private qrCodeService: QrCodeService,
    private overlayService: OverlayService,
    private invitingService: InvitingService,
    private clipboardService: ClipboardService,
    private store: Store<ModuleState.State>
  ) {}

  ngOnInit(): void {
    this.emailControlValueChanges$.pipe(filter(isTruthy), untilDestroyed(this)).subscribe(() => {
      return this.removeValidators(this.emailControl, [EMAIL_VALIDATOR, REQUIRED_VALIDATOR]);
    });
  }

  getLink$(email?: string): Observable<string> {
    return this.link$.pipe(
      take(1),
      switchMap(link => {
        const token = getParamValueFromUrl(link, 'token');
        if (isTruthy(link) && !email) {
          return of(link);
        }

        return this.invitingService.requestInvitingLink(email, token).pipe(
          tap(inviteLink => {
            return this.linkSubject$.next(inviteLink);
          })
        );
      }),
      untilDestroyed(this)
    );
  }

  sendEmail(): void {
    addValidators(this.emailControl, [REQUIRED_VALIDATOR]);
    this.emailControl.markAsTouched();
    this.emailControl.updateValueAndValidity();

    if (this.emailControl.invalid) {
      return;
    }

    this.sendEmailErrorLabelSubject.next(undefined);
    this.sendEmailSuccessSubject.next(false);
    this.store.dispatch(ExecutionAction.customProcessingStart({ objectId: StoreExecutionKeyEnum.EMAIL }));
    this.getLink$(this.emailControl.value)
      .pipe(
        take(1),
        catchError(({ data }) => {
          const code = data?.error?.code;
          switch (code) {
            case SqlErrorCodeEnum.EXIST:
              this.sendEmailErrorLabelSubject.next(SendEmailErrorLabelEnum.EXIST);
              break;
            default:
              this.sendEmailErrorLabelSubject.next(SendEmailErrorLabelEnum.DEFAULT);
              break;
          }

          return of(undefined);
        })
      )
      .subscribe(link => {
        this.store.dispatch(ExecutionAction.customProcessingStop({ objectId: StoreExecutionKeyEnum.EMAIL }));
        if (isTruthy(link)) {
          this.sendEmailSuccessSubject.next(true);
        }
      });
  }

  copyLink(): void {
    this.store.dispatch(ExecutionAction.customProcessingStart({ objectId: StoreExecutionKeyEnum.COPY }));
    this.getLink$()
      .pipe(
        take(1),
        catchError(() => {
          this.generateLinkErrorSubject.next('BREEZ_INVITING_LINK_ERROR');
          return of(undefined);
        })
      )
      .subscribe(link => {
        this.store.dispatch(ExecutionAction.customProcessingStop({ objectId: StoreExecutionKeyEnum.COPY }));
        if (isTruthy(link)) {
          this.generateLinkErrorSubject.next(null);
          this.copyLinkSuccessSubject.next(true);
          this.clipboardService.copy(link);
        }
      });
  }

  showQrCode(): void {
    this.store.dispatch(ExecutionAction.customProcessingStart({ objectId: StoreExecutionKeyEnum.QR }));
    this.getLink$()
      .pipe(
        catchError(() => {
          this.generateLinkErrorSubject.next('BREEZ_INVITING_QR_ERROR');
          return of(undefined);
        }),
        switchMap(link => {
          if (isTruthy(link)) {
            this.generateLinkErrorSubject.next(null);
            return this.qrCodeService.getDataUrl(link);
          } else {
            return of(undefined);
          }
        }),
        take(1),
        tap(() => {
          return this.store.dispatch(ExecutionAction.customProcessingStop({ objectId: StoreExecutionKeyEnum.QR }));
        }),
        filter(isTruthy),
        map(dataUrl => {
          return <ImagePreviewEntry>{
            selected: true,
            imageFile: {
              name: 'QR-code',
              contentUrl: dataUrl,
              previewContentUrl: dataUrl
            }
          };
        })
      )
      .subscribe(qrCodeImageEntry => {
        this.overlayService.addContainer({
          id: {},
          position: OverlayPositionType.CENTER,
          modal: true,
          type: OverlayEntryType.IMAGE_PREVIEW,
          data: { images: [qrCodeImageEntry] }
        });
      });
  }

  close(): void {
    this.dialogRef.close();
  }

  protected readonly addValidators: (control: AbstractControl, validators: ValidatorFn | ValidatorFn[]) => void =
    addValidators;

  protected readonly removeValidators: (control: AbstractControl, validators: ValidatorFn | ValidatorFn[]) => void =
    removeValidators;

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnDestroy(): void {}
}
