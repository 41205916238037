import { CHAT_ID } from '../types';

export interface MessageDraft {
  targetChatId: CHAT_ID;
  userId: number;
  message: string;
}

export interface MessageDraftEntity {
  chatId: CHAT_ID;
  draft: string;
}
