import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

const ITEMS_PER_PAGE_LABEL = 'ITEMS_PER_PAGE';
const NEXT_PAGE_LABEL = 'NEXT_PAGE';
const PREVIOUS_PAGE_LABEL = 'PREV_PAGE';
const FIRST_PAGE_LABEL = 'FIRST_PAGE';
const LAST_PAGE_LABEL = 'LAST_PAGE';
const PAGES_COMPOSER = 'OUT_OF';

@Injectable({
  providedIn: 'root'
})
export class TablePaginatorLocaleService extends MatPaginatorIntl {
  private composer = '/';

  constructor(private readonly translateService: TranslateService) {
    super();

    this.translateService.onLangChange.subscribe(() => {
      return this.getTranslations();
    });

    this.getTranslations();
  }

  getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
      return `0 ${this.composer} ${length}`;
    }

    length = Math.max(length, 0);

    const startIndex = page * pageSize > length ? (Math.ceil(length / pageSize) - 1) * pageSize : page * pageSize;
    const endIndex = Math.min(startIndex + pageSize, length);

    return `${startIndex + 1} - ${endIndex} ${this.composer} ${length}`;
  };

  getTranslations(): void {
    this.translateService
      .get([
        ITEMS_PER_PAGE_LABEL,
        NEXT_PAGE_LABEL,
        PREVIOUS_PAGE_LABEL,
        FIRST_PAGE_LABEL,
        LAST_PAGE_LABEL,
        PAGES_COMPOSER
      ])
      .subscribe(result => {
        this.itemsPerPageLabel = result.ITEMS_PER_PAGE;
        this.nextPageLabel = result.NEXT_PAGE;
        this.previousPageLabel = result.PREV_PAGE;
        this.firstPageLabel = result.FIRST_PAGE;
        this.lastPageLabel = result.LAST_PAGE;

        this.composer = result.OUT_OF;

        this.changes.next();
      });
  }
}
