import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SortingModes } from '@breez/models/table/sorting-modes.enum';
import { ColumnType } from '@breez/shared/enums/column-type.enum';
import {
  FilterField,
  TableColumn,
  TableExpandedColumn
} from '@breez/shared/modules/ui-controls/models/table-column.interface';
import { EmptyWrapperPipe } from '@breez/shared/pipes/empty-wrapper.pipe';
import { replayWhileSubs } from '@breez/shared/rxjs-operators';
import { EmitOnChange } from '@breez/shared/utilities/decorators/emit-on-change.decorator';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { COMPONENT_ANIMATIONS } from './table-control.component.animation';

export interface ITableControlOptions {
  async: boolean;
  // определения полей, по которым фильтруются данные
  filterFields?: FilterField[];
  // callback при клике на колонку с type = CLICK
  cellClick?: Function;
  pageSize?: number;
  pagination?: boolean;
  pageSizeOptions?: number[];
  hover?: boolean;
  stickyHeader?: boolean;
  containerStyle?: { [klass: string]: any };
  style?: { [klass: string]: any };
  infoText?: {
    notFound?: TemplateRef<any>;
    noData?: TemplateRef<any>;
  };
  loader?: 'spinner' | 'text';
  showPinIcon?: boolean;
}

@UntilDestroy()
@Component({
  selector: 'vks-table-control',
  templateUrl: './table-control.component.html',
  styleUrls: ['./table-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: COMPONENT_ANIMATIONS
})
export class TableControlComponent implements OnChanges, AfterViewInit, OnInit {
  @ViewChild(MatSort) matSort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('chevronColumn', { static: false }) chevronColumn: TemplateRef<any>;
  @ViewChild('empty', { static: true }) emptyMessage: TemplateRef<any>;
  @ViewChild('loading', { static: true }) loadingMessage: TemplateRef<any>;
  @ViewChild('nothingFound', { static: true }) nothingFoundMessage: TemplateRef<any>;
  @Input() columns: Array<TableColumn> = [];
  // колонки, отображаемые в дополнительной строчке (раскрывающаяся)
  @Input() expandedColumns: TableExpandedColumn[] = [];
  // строка вхождение которой ищем по элементам таблицы
  @Input() filterValue: string;
  // функция для получения данных по заголовку (используется matSort)
  @Input() sortingDataAccessor: (data: any, sortHeaderId: string) => string | number;
  @Input() data = [];
  @Input() isFetching = false;
  @Input() isAdvancedView = false;
  @Input() paging: PageEvent;
  @Input() options: ITableControlOptions;
  @Output() sortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output() pageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output() rowClickAction: EventEmitter<any> = new EventEmitter<any>();
  @EmitOnChange('paging', { onlyTruthy: true })
  paging$: BehaviorSubject<PageEvent> = new BehaviorSubject<PageEvent>({
    length: 0,
    pageIndex: 0,
    pageSize: 0
  } as PageEvent);

  readonly dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  @EmitOnChange('columns', { onlyTruthy: true })
  columns$: ReplaySubject<TableColumn[]> = new ReplaySubject<TableColumn[]>();

  @EmitOnChange('expandedColumns')
  expandedColumns$: BehaviorSubject<TableExpandedColumn[]> = new BehaviorSubject<TableExpandedColumn[]>(
    this.expandedColumns
  );

  @EmitOnChange('isAdvancedView')
  isAdvancedView$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.isAdvancedView);

  viewInit$: ReplaySubject<void> = new ReplaySubject<void>();
  items$: ReplaySubject<any[]> = new ReplaySubject<any[]>();
  columnNames$ = combineLatest([this.columns$, this.expandedColumns$, this.isAdvancedView$]).pipe(
    map(([columns, additionalColumns, isAdvancedView]) => {
      if (additionalColumns.length) {
        columns.unshift({
          sorting: SortingModes.NONE,
          columnName: 'chevron',
          isDefault: true,
          type: ColumnType.TEMPLATE,
          template: this.chevronColumn,
          cssProp: {
            textAlign: 'center',
            padding: 0
          }
        });
      }
      return columns
        .filter(column => {
          return column.attributes && isAdvancedView ? !column.attributes.includes('hidden') : true;
        })
        .map(column => {
          return column.columnName;
        });
    }),
    replayWhileSubs()
  );

  expandedColumnsNames$ = this.expandedColumns$.pipe(
    map(columns => {
      return columns.map(column => {
        return column.columnName;
      });
    }),
    replayWhileSubs()
  );

  @EmitOnChange<ITableControlOptions, boolean>('options', {
    onlyTruthy: true,
    emitter: (value, subject) => {
      return subject.next(value.async);
    }
  })
  isAsync$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  expandedRow;
  readonly baseOptions: ITableControlOptions = {
    async: false,
    pageSize: 10,
    pagination: true,
    filterFields: [],
    pageSizeOptions: [10],
    showPinIcon: false,
    hover: false,
    stickyHeader: false,
    loader: 'text'
  };

  settingsSubject$: BehaviorSubject<ITableControlOptions> = new BehaviorSubject(this.baseOptions);
  settings$: Observable<ITableControlOptions> = this.settingsSubject$.pipe(replayWhileSubs());

  constructor(
    private emptyWrapperPipe: EmptyWrapperPipe,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.isAsync$.pipe(untilDestroyed(this)).subscribe(isAsync => {
      if (isAsync) {
        this.dataSource.paginator = null;
      } else if (!isAsync && this.settingsSubject$.value?.pagination && this.paginator) {
        this.dataSource.paginator = this.paginator;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    let settings = this.settingsSubject$.value;
    if (changes.options) {
      settings = {
        ...settings,
        ...this.options
      };
      this.settingsSubject$.next(settings);
      if (settings.infoText && settings.infoText.noData) {
        this.emptyMessage = settings.infoText.noData;
      }
      if (settings.infoText && settings.infoText.notFound) {
        this.nothingFoundMessage = settings.infoText.notFound;
      }
    }
    if (changes.data && changes.data.currentValue) {
      this.dataSource.data = this.data;
      this.items$.next(this.dataSource.filteredData);
    }
    if (changes.sortingDataAccessor && changes.sortingDataAccessor.currentValue) {
      this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
    }
    if (changes.filterValue) {
      this.dataSource.filter = this.filterValue;
    }
    if (changes.isFetching && changes.isFetching.currentValue && this.dataSource) {
      this.dataSource.data = [];
    }
    if (changes.paging && changes.paging.currentValue) {
      this.settingsSubject$.next({ ...settings, pageSize: this.paging.pageSize } as ITableControlOptions);
    }
  }

  ngAfterViewInit(): void {
    this.viewInit$.next();
    const settings = this.settingsSubject$.value;
    this.dataSource.filterPredicate = (data, filter): any => {
      if (!filter) {
        return true;
      }
      filter = filter.toLowerCase().trim();
      const fields: any = settings.filterFields.length ? settings.filterFields : Object.keys(data);
      return fields
        .map(field => {
          return this.compare(data, field, filter);
        })
        .some(isAccepted => {
          return isAccepted === true;
        });
    };
    if (settings && settings.pagination && !settings.async) {
      this.dataSource.paginator = this.paginator;
    }
  }

  compare(data: any, field: string | FilterField, filterValue: string): string | any {
    const dataKey = field instanceof Object ? field.key : field;
    const emptyLabel = field instanceof Object ? field.emptyLabel : undefined;
    let value = this.getValue(data, dataKey);
    if (isTruthy(value) && !(value[dataKey] instanceof Object)) {
      value = this.emptyWrapperPipe.getTransformedValue(value[dataKey], emptyLabel);
      return this.translateService.instant(value.toString()).toLowerCase().includes(filterValue);
    }
  }

  trackBy(_, column: TableColumn): string {
    return column.columnName;
  }

  private getValue(object: any, key: string): any {
    const keys = Object.keys(object);
    if (keys.includes(key)) {
      return { [key]: object[key] };
    } else {
      for (const objectKey of keys) {
        if (object[objectKey] instanceof Object) {
          const res = this.getValue(object, objectKey);
          if (res) {
            return res;
          }
        }
      }
    }
  }
}
