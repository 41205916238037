import { Inject, Injectable } from '@angular/core';
import { ConferenceLayoutFrameModel } from '@breez/models';
import { AppConfigModel } from '@breez/models/app-config.model';
import { WebsocketEvents, WebsocketService } from '@breez/modules/websocket';
import { arrToClass, replayWhileSubs } from '@breez/shared/rxjs-operators';
import { Observable, of, switchMap } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ConferenceLayoutModel } from '@breez/models/conference/layout/conference-layout.model';

@Injectable({
  providedIn: 'root'
})
export class ConferenceLayoutService {
  conferenceLayouts$: Observable<ConferenceLayoutModel[]> = this.conferenceGetLayouts().pipe(replayWhileSubs());
  private readonly defaultLayoutId: number = this.appConfig.defaultLayoutId;
  defaultLayout$: Observable<ConferenceLayoutModel> = this.getLayoutById(this.defaultLayoutId);

  constructor(
    @Inject('APP_CONFIG') private readonly appConfig: AppConfigModel,
    private readonly websocketService: WebsocketService
  ) {}

  getLayoutById(targetLayoutId: string | number): Observable<ConferenceLayoutModel> {
    return this.conferenceLayouts$.pipe(
      map(layouts => {
        return layouts.find(layout => {
          return typeof targetLayoutId === 'string'
            ? layout.conferenceLayoutId === targetLayoutId
            : layout.oldLayoutId === targetLayoutId;
        });
      }),
      switchMap(layout => {
        return this.getLayoutFrames(layout?.conferenceLayoutId).pipe(
          map(frames => {
            return { ...layout, ...{ frames } };
          })
        );
      })
    );
  }

  private conferenceGetLayouts(confernceid?: number, ownerid?: number): Observable<ConferenceLayoutModel[]> {
    let data = {};
    if (confernceid) {
      data = { ...data, ...{ confernceid } };
    }
    if (ownerid) {
      data = { ...data, ...{ ownerid } };
    }

    return this.websocketService
      .query(WebsocketEvents.RECEIVE.CONFERENCE.GET_LAYOUTS, { data })
      .pipe(take(1), arrToClass(ConferenceLayoutModel));
  }

  private getLayoutFrames(layoutid?: string): Observable<ConferenceLayoutFrameModel[]> {
    let data = {};
    if (layoutid) {
      data = { ...data, ...{ layoutid } };
    } else {
      return of([]);
    }

    return this.websocketService
      .query(WebsocketEvents.RECEIVE.CONFERENCE.GET_LAYOUT_FRAMES, { data })
      .pipe(take(1), arrToClass(ConferenceLayoutFrameModel));
  }
}
