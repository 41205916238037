import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserStatusService } from './services/user-status.service';
import { USER_STATUS_SOURCE_PROVIDER } from './user-status-source.provider';
import { AvatarSmartComponent } from './components/avatar-smart';
import { UserAvatarModule } from '../../user-avatar/user-avatar.module';

@NgModule({
  declarations: [AvatarSmartComponent],
  imports: [CommonModule, UserAvatarModule],
  providers: [UserStatusService, USER_STATUS_SOURCE_PROVIDER],
  exports: [AvatarSmartComponent]
})
export class AvatarSmartModule {}
