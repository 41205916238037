import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { StateService } from '@breez/shared/services/state.service';
import { Observable, timer } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { LanguageService } from '@breez/shared/services/language.service';
import { ElectronService } from '@breez/modules/core/services';

@Component({
  selector: 'vks-leaving',
  templateUrl: './leaving.component.html',
  styleUrls: ['./leaving.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeavingComponent implements OnInit {
  readonly isElectronApp: boolean = this.electronService.isElectron;

  languageLogoClass$: Observable<string> = this.languageService.interfaceLanguage$.pipe(
    switchMap(languageId => {
      return this.languageService.getLanguageInfo(languageId);
    }),
    map(language => {
      return language.logoCSSClass;
    })
  );

  constructor(
    private stateService: StateService,
    private languageService: LanguageService,
    private electronService: ElectronService
  ) {}

  ngOnInit(): void {
    this.stateService.setExpandedGUI(false);
    timer(1000, 0)
      .pipe(take(1))
      .subscribe(() => {
        window.location.href = 'breez-app://';
      });
  }
}
