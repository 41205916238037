import { isPlatformBrowser, registerLocaleData } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import {
  TranslationConfig,
  TranslationService
} from '@breez/shared/modules/translation/interfaces/translation-service.interface';
import { TranslationStorage } from '@breez/shared/modules/translation/interfaces/translation-storage.interface';
import localeEn from '@angular/common/locales/en';
import localeRu from '@angular/common/locales/ru';
import localeRuExtra from '@angular/common/locales/extra/ru';
import { LanguageModel } from '@breez/models';

export const LANGUAGES: LanguageModel[] = [
  {
    id: 'ru',
    name: 'Русский',
    icon: 'flag-ru',
    logoCSSClass: 'ru',
    dateFormat: 'dd.M.yyyy, HH:mm'
  },
  {
    id: 'en',
    name: 'English',
    icon: 'flag-en',
    logoCSSClass: 'int',
    dateFormat: 'M/dd/yyyy, HH:mm'
  }
];

@Injectable()
export class BaseTranslationService implements TranslationService {
  constructor(
    private translateService: TranslateService,
    private translationStorage: TranslationStorage,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  init(): Observable<any> {
    const config: TranslationConfig = this.getConfig();
    this.translateService.addLangs(config.languages);
    return this.setLanguage(config.language);
  }

  getConfig(): TranslationConfig {
    const languages = LANGUAGES.map(l => {
      return l.id;
    });
    let language = this.translationStorage.getLanguage();
    if (!language) {
      const cutLanguageString = (str: string): string => {
        return str.length > 2 ? str.substring(0, 2) : str;
      };
      language = isPlatformBrowser(this.platformId)
        ? this.getLanguageIdFromConfig(cutLanguageString(window.navigator.language))
        : (language = languages[0]);

      this.translationStorage.setLanguage(language);
    }

    return {
      languages,
      language
    };
  }

  getLanguage(): string {
    const language = this.translationStorage.getLanguage() || this.translateService.getBrowserLang();
    const filteredLanguage = LANGUAGES.find(configItem => {
      return language === configItem.id;
    });
    this.translateService.use(filteredLanguage?.id || 'en');

    return this.translateService.currentLang;
  }

  getLanguages(): string[] {
    return this.translateService.getLangs();
  }

  stream(key: string | Array<string>, interpolateParams?: Object): Observable<string | any> {
    return this.translateService.stream(key, interpolateParams);
  }

  setLanguage(language: string): Observable<any> {
    const locale = this.getLanguageIdFromConfig(language);
    this.translationStorage.setLanguage(locale);
    if (locale === 'ru') {
      registerLocaleData(localeRu, localeRuExtra);
    } else {
      registerLocaleData(localeEn);
    }

    this.translateService.setDefaultLang(locale);
    return this.translateService.use(locale);
  }

  private getLanguageIdFromConfig(language: string): string {
    return (
      LANGUAGES.find(configItem => {
        return language === configItem.id;
      })?.id || 'en'
    );
  }
}
