import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LanguageModel } from '@breez/models';
import { Observable } from 'rxjs';
import { LanguageService } from '@breez/shared/services/language.service';

@Component({
  selector: 'vks-language-selection',
  templateUrl: './language-selection.component.html',
  styleUrls: ['./language-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguageSelectionComponent {
  languages$: Observable<LanguageModel[]> = this.languageService.getLanguagesInfo();

  constructor(
    private dialogRef: MatDialogRef<LanguageSelectionComponent>,
    private languageService: LanguageService
  ) {}

  resolveDialog(language: LanguageModel): void {
    this.dialogRef.close(language);
  }

  close(): void {
    this.dialogRef.close();
  }
}
