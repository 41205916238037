import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { UserMediaSource } from '@breez/models/webrtc/media-source.model';
import { MediaSourceKind } from '@breez/models/webrtc/media-source-kind.enum';
import { MediaDevicesService } from '@breez/modules/webrtc/services/media-devices.service';
import { EmitOnChange } from '@breez/shared/utilities/decorators/emit-on-change.decorator';
import { interval, Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, startWith, take } from 'rxjs/operators';
import { replayWhileSubs } from '@breez/shared/rxjs-operators';

@Component({
  selector: 'vks-media-sources-preview',
  templateUrl: './media-sources-preview.component.html',
  styleUrls: ['./media-sources-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaSourcesPreviewComponent implements OnChanges, OnDestroy, OnInit {
  @Input()
  mediaStream: { stream: MediaStream } = null;

  audioCheckStream: { stream: MediaStream } = null;

  @Input() effectsLoading: boolean;

  @EmitOnChange('effectsLoading')
  effectsLoading$: ReplaySubject<boolean> = new ReplaySubject(1);

  @Input()
  sources: UserMediaSource[];

  @Input()
  sourcesPermissions: PermissionState | 'locked' = 'denied';

  @EmitOnChange('sources')
  sources$ = new ReplaySubject<UserMediaSource[]>(1);

  audioInput: UserMediaSource = null;
  audioOutput: UserMediaSource = null;

  @HostBinding('class.empty-audio')
  emptyAudio: boolean = false;

  ratioHeightSubject$: ReplaySubject<number> = new ReplaySubject<number>(1);

  ratioHeight$: Observable<number> = this.ratioHeightSubject$.pipe(
    startWith(175),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  constructor(
    private mediaDevicesService: MediaDevicesService,
    private host: ElementRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.sources?.currentValue) {
      this.audioInput = this.sources.find(item => {
        return item.kind === MediaSourceKind.AUDIO_INPUT;
      });
      this.audioOutput = this.sources.find(item => {
        return item.kind === MediaSourceKind.AUDIO_OUTPUT;
      });
    }

    if (!!changes.mediaStream && this.mediaStream?.stream.getTracks().length > 0) {
      const videoTracks = this.mediaStream?.stream.getVideoTracks() ?? [];
      const audioTracks = this.mediaStream?.stream.getAudioTracks() ?? [];
      this.mediaStream = {
        stream: videoTracks.length > 0 ? new MediaStream(videoTracks) : null
      };

      this.audioCheckStream = {
        stream: audioTracks.length > 0 ? new MediaStream(audioTracks) : null
      };
    }
  }

  ngOnDestroy(): void {
    interval(1000)
      .pipe(take(2))
      .subscribe(() => {
        if (!!this.mediaStream?.stream) {
          this.mediaDevicesService.stopTracks({ mediaStream: this.mediaStream?.stream });
          this.mediaStream = null;
        }

        if (!!this.audioCheckStream?.stream) {
          this.mediaDevicesService.stopTracks({ mediaStream: this.audioCheckStream?.stream });
          this.audioCheckStream = null;
        }
      });
  }

  ngOnInit(): void {
    const resizeObserver = new ResizeObserver(entries => {
      const width = entries[0].contentRect.width;
      const height = Math.ceil((width / 16) * 9) ?? 0;
      this.ratioHeightSubject$.next(height);
    });

    resizeObserver.observe(this.host.nativeElement);
  }
}
