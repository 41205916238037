import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostListener, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { ChatService } from '../../services';
import { Observable } from 'rxjs';

@Component({
  selector: 'vks-chat-block-form',
  templateUrl: './chat-block-form.component.html',
  styleUrls: ['./chat-block-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatBlockFormComponent {
  readonly banReasonInputId = 'conference-title';

  @HostListener('document:keydown.enter')
  onControlEnterKeyup(): void {
    const banReasonInput = this.document.getElementById(this.banReasonInputId);
    const currentActiveElement = this.document.activeElement;

    if (banReasonInput === currentActiveElement) {
      this.blockChat();
    }
  }

  banForAWhile = false;

  blockForm = new UntypedFormGroup({
    reason: new UntypedFormControl(null),
    duration: new UntypedFormControl(null)
  });

  suggestions$: Observable<string[]> = this.chatService.getBlockReasonSuggestions();

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private chatService: ChatService,
    private dialogRef: MatDialogRef<ChatBlockFormComponent>
  ) {}

  blockChat(): void {
    this.dialogRef.close(this.blockForm.value);
  }

  durationSet(event: MatRadioChange): void {
    this.banForAWhile = event.value;

    if (!this.banForAWhile) {
      this.blockForm.controls.duration.setValue(null);
    }
  }

  setSuggestionToReasonInput(suggestion: string): void {
    this.blockForm.controls.reason.setValue(suggestion);
  }

  cancel(): void {
    this.dialogRef.close(null);
  }
}
