<h3>
  {{ (callModel.destinationType === callDestinationType.OUTGOING ? callModel.answerer : callModel.caller)?.name }}
</h3>
<div class="actions">
  <vks-user-avatar
    [entity]="callModel.destinationType === callDestinationType.OUTGOING ? callModel.answerer : callModel.caller"
    size="48"
  ></vks-user-avatar>
  <vks-icon
    [hasHover]="true"
    [matTooltip]="'CALL.HANGUP' | translate"
    (click)="onChangeCall(callStatus.HANGUP)"
    class="square48"
    color="white"
    hoverColor="white"
    icon="call-cancel"
  ></vks-icon>
  <vks-icon
    [hasHover]="true"
    [matTooltip]="'CALL.RESUME' | translate"
    (click)="onChangeCall(callStatus.ACCEPT)"
    class="square48"
    color="white"
    hoverColor="white"
    icon="call-resume"
  ></vks-icon>
</div>
