<div [class.compact]="(isCompactMenu$ | async) && (isMobileDevice$ | async | not)" class="menu">
  <div class="scroll-section">
    <div *ngFor="let item of displayMenuItems$ | async" [class.expanded]="item.expanded" class="section">
      <div
        (mouseenter)="enterMenuItem(item)"
        (mouseleave)="leaveMenuItem(item)"
        [matTooltipDisabled]="isCompactMenu$ | async | not"
        [matTooltip]="item.label | translate"
        class="menu-item"
        matTooltipPosition="right"
        matTooltipTouchGestures="off"
      >
        <div (click)="toggleCompactMenu()" *ngIf="item.link || item.plainLink; else expandButton">
          <ng-container *ngIf="item.link; else routerLink">
            <a
              [queryParams]="this.getParams(item.params)"
              [routerLink]="item.link"
              class="item-content"
              [class.active]="isLinkActive(item.params, item.link, item.strongCompareRoute) | async"
            >
              <vks-icon [icon]="item.icon" class="icon"></vks-icon>
              <ng-container *ngIf="item.badgeCount$">
                <div *ngIf="item.badgeCount$ | async as badgeCount" class="badge">
                  {{ badgeCount | simplifyNumber }}
                </div>
              </ng-container>
              <span class="label">{{ item.label | translate }}</span>
            </a>
          </ng-container>
          <ng-template #routerLink>
            <a [href]="item.plainLink" class="item-content" target="_blank">
              <vks-icon [icon]="item.icon" class="icon"></vks-icon>
              <span class="label">{{ item.label | translate }}</span>
              <vks-icon class="tag square14" color="#FFFFFF" icon="open-in-new"></vks-icon>
            </a>
          </ng-template>
        </div>
        <ng-template #expandButton>
          <div
            (click)="changeItemMenuState(item)"
            [class.active]="isChildLinkActive(item) | async"
            class="expand-button item-content"
          >
            <vks-icon [icon]="item.icon" class="icon"></vks-icon>
            <ng-container *ngIf="item.labelAsIs; else translateLabel">
              <span class="label">{{ item.label | translate }}</span>
            </ng-container>
            <ng-template #translateLabel>
              <span class="label">{{ item.label | translate }}</span>
            </ng-template>
          </div>
        </ng-template>
        <ng-container *ngIf="item.children">
          <div (click)="toggleCompactMenu()" *ngFor="let subItem of item.children" class="child menu-item">
            <ng-container *ngTemplateOutlet="childLink; context: { subItem: subItem }"></ng-container>
          </div>
        </ng-container>
      </div>
    </div>
    <span *ngIf="(isCompactMenu$ | async) && (isMobileDevice$ | async | not)" class="spacer"></span>
    <div [class.expanded]="helpMenuItem.expanded" class="section">
      <div class="menu-item">
        <div
          (click)="toggleItemMenu(helpMenuItem, menuTrigger)"
          *ngIf="helpMenuItem.children; else singleItem"
          class="expand-button item-content"
        >
          <vks-icon
            #menuTrigger="matMenuTrigger"
            [icon]="helpMenuItem.icon"
            [matMenuTriggerFor]="helpMenu"
            class="icon"
          ></vks-icon>
          <span class="label">{{ helpMenuItem.label | translate }}</span>
          <mat-menu #helpMenu="matMenu" xPosition="after" yPosition="below">
            <button *ngFor="let subItem of helpMenuItem.children" class="menu-link" mat-menu-item>
              <ng-container *ngTemplateOutlet="childLink; context: { subItem: subItem }"></ng-container>
            </button>
          </mat-menu>
        </div>
        <ng-container *ngIf="helpMenuItem.children">
          <div *ngFor="let subItem of helpMenuItem.children" class="child menu-item">
            <ng-container *ngTemplateOutlet="childLink; context: { subItem: subItem }"></ng-container>
          </div>
        </ng-container>
        <ng-template #singleItem>
          <a [href]="helpMenuItem.plainLink" class="item-content" rel="opener" target="_blank">
            <vks-icon [icon]="helpMenuItem.icon" class="icon"></vks-icon>
            <span class="label">{{ helpMenuItem.label | translate }}</span>
          </a>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="menu-footer">
    <vks-control-language-selector></vks-control-language-selector>
    <a
      *ngIf="isCompactMenu$ | async | not"
      [label]="'COPYRIGHT' | translate"
      class="btn-anchor"
      href="https://k2t.app"
      target="_blank"
      vks-button
    ></a>
  </div>
</div>

<ng-template #childLink let-subItem="subItem">
  <ng-container *ngIf="subItem.plainLink; else routerLink">
    <a
      [href]="subItem.plainLink | safe: 'url'"
      class="item-content"
      rel="opener"
      target="{{ subItem.blankPage ? '_blank' : '_self' }}"
    >
      <span class="label">{{ subItem.label | translate }}</span>
    </a>
  </ng-container>
  <ng-template #routerLink>
    <a
      (click)="openLinkIfFns(subItem)"
      [class.active]="isLinkActive(subItem.params, subItem.link, false) | async"
      [queryParams]="shouldHaveRouterLink(subItem) ? subItem.params : null"
      [routerLink]="shouldHaveRouterLink(subItem) ? subItem.link : null"
      rel="opener"
      class="item-content"
      target="{{ subItem.blankPage ? '_blank' : '_self' }}"
    >
      <span class="label">{{ subItem.label | translate }}</span>
    </a>
  </ng-template>
</ng-template>
