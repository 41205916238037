import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { InvitingDialogService } from '@breez/modules/inviting/inviting-guests/services/inviting-dialog.service';
import { Observable } from 'rxjs';
import { StateService } from '@breez/shared/services/state.service';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { AuthService } from '@breez/modules/auth/services/auth.service';
import { replayWhileSubs } from '@breez/shared/rxjs-operators';

@Component({
  selector: 'vks-invite-guest-button',
  templateUrl: './invite-guest-button.component.html',
  styleUrls: ['./invite-guest-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InviteGuestButtonComponent {
  @Input()
  @HostBinding('class.bolder')
  bolder: boolean = false;

  @Input()
  hideableLabel = true;

  isMobileDevice$: Observable<boolean> = this.stateService.isMobileDevice$;

  canInviteGuest$: Observable<boolean> = this.authService.roles$.pipe(
    map(roles => {
      return roles.some(role => {
        return role === 'contacts:invite';
      });
    }),
    startWith(false),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  constructor(
    private invitingDialogService: InvitingDialogService,
    private authService: AuthService,
    private stateService: StateService
  ) {}

  guestInviting(): void {
    this.invitingDialogService.displayInvitingForm();
  }
}
