<vks-oops-page *ngIf="showOopsPage$ | async; else wrapAppMarkup"></vks-oops-page>
<pwa-install-component *ngIf="!isElectronApp && !isPwaApp"></pwa-install-component>
<ng-template #wrapAppMarkup>
  <vks-not-support-page
    [appAvailable]="appAvailable$ | async"
    [browserRecommendation]="browserRecommendation$ | async"
    *ngIf="showNotSupportServicePage$ | async; else appMarkup"
  ></vks-not-support-page>
</ng-template>
<ng-template #appMarkup>
  <ng-container *ngIf="(browserRecommendation$ | async) !== null">
    <aside [class.compact]="isMenuCompact$ | async" [class]="'sidebar ' + (menuElementClass$ | async)">
      <div class="menu">
        <!--  TODO найти для этого место получше -->
        <div class="menu-head">
          <div (click)="changeMenuState(); closeMenu()" class="menu-trigger">
            <vks-icon [className]="menuBurgerIconClass" [icon]="'breez-app-en'"></vks-icon>
          </div>
          <div [class.no-negative-background-position]="isMinimumMenuWidth$ | async" [routerLink]="['/']" class="logo">
            <div [class]="'logo-content'">{{ 'Breez' | translate }}</div>
            <span [matTooltip]="'Version: ' + version" class="app-release">{{ release }}</span>
          </div>
        </div>
        <hr />
        <vks-menu></vks-menu>
      </div>
    </aside>
    <main [style.margin-left.px]="(mainWithOffset$ | async) ? 56 : 0">
      <vks-header
        *ngIf="showGlobalHeader$ | async"
        [class.compact]="(isMenuCompact$ | async) && (isMobileDevice$ | async | not)"
      ></vks-header>
      <div [class.shifted]="expandedGUI$ | async" class="router-content">
        <router-outlet (activate)="onActivate()" (deactivate)="onDeactivate()"></router-outlet>
        <ng-container *ngIf="routerActivateSubject$ | async | not">
          <div class="logo-pre-wrapper">
            <div class="vks-logo pre" [class.en]="(selectedLang$ | async) !== 'ru'"></div>
            <span>{{ 'LOGIN_IN_PROGRESS' | translate }}</span>
            <span *ngIf="isProblemMessage$ | async"
              >{{ 'LOGIN_IN_PROGRESS_PROBLEM' | translate }}
              <a (debounceClick)="refresh()" *ngIf="isProblemMessageButton$ | async" vks-debounce-click>{{
                'LOGIN_IN_PROGRESS_PROBLEM_LINK' | translate | lowercase
              }}</a></span
            >
          </div>
        </ng-container>
      </div>
    </main>
    <div [class.show]="backdropShow$ | async" (click)="changeMenuState(); closeMenu()" class="backdrop"></div>
    <div [class.loading-blur]="blockedApp$ | async" class="backdrop">
      <div class="vks-logo pre" [class.en]="(selectedLang$ | async) !== 'ru'"></div>
      <span>{{ 'WAITING_PLS' | translate }}...</span>
    </div>
    <vks-overlay></vks-overlay>
  </ng-container>
</ng-template>
