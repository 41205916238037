import { ChatEffects, chatReducer } from './chat';
import { MessageEffects, messageReducer } from './message';
import { ParticipantsEffects } from '@breez/modules/chat/+state/participants/participants.effects';
import { participantsReducer } from '@breez/modules/chat/+state/participants/participants.reducer';
import { RangeEffects } from '@breez/modules/chat/+state/viewRange/viewRange.effects';
import { viewRangeReducer } from '@breez/modules/chat/+state/viewRange/viewRange.reducer';
import { ChatUnreadMessageEffects } from '@breez/modules/chat/+state/chatUnreadMessage/chatUnreadMessage.effects';
import { ChatDraftMessageEffects } from '@breez/modules/chat/+state/chatDraftMessage/chatDraftMessage.effects';
import { chatUnreadMessageCountReducer } from '@breez/modules/chat/+state/chatUnreadMessage/chatUnreadMessage.reducer';
import { chatDraftMessageReducer } from '@breez/modules/chat/+state/chatDraftMessage/chatDraftMessage.reducer';
export * from './chat-feature-key.const';

export const reducers = {
  chat: chatReducer,
  message: messageReducer,
  participants: participantsReducer,
  viewRange: viewRangeReducer,
  chatUnreadMessageCount: chatUnreadMessageCountReducer,
  draft: chatDraftMessageReducer
};

export const effects = [
  ChatEffects,
  MessageEffects,
  ParticipantsEffects,
  RangeEffects,
  ChatUnreadMessageEffects,
  ChatDraftMessageEffects
];
