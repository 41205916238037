<div class="content custom-padding" mat-dialog-content>
  {{ question | translate }}
</div>
<div class="actions custom-padding" mat-dialog-actions>
  <button vks-button [label]="action | translate" [mat-dialog-close]="true" class="btn-primary btn-sm"></button>
  <button
    vks-button
    [label]="'CANCEL' | translate"
    [mat-dialog-close]="false"
    class="btn-anchor btn-sm"
    tabindex="-1"
  ></button>
</div>
