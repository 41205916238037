import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

export enum HumanizedDateFormatType {
  DATE_ONLY = 'date-only',
  TIME_ONLY = 'time-only',
  TIME_OR_DATE = 'time-or-date',
  TIME_AND_DATE = 'time-and-date'
}

@Injectable({
  providedIn: 'root'
})
export class DateTimeHumanizerService {
  constructor(private translateService: TranslateService) {}

  trackHumanizeDate(date: Date, formatType: HumanizedDateFormatType): Observable<string> {
    return this.translateService.onLangChange.pipe(
      startWith({
        lang: this.translateService.currentLang,
        translations: this.translateService.translations[this.translateService.currentLang]
      }),
      map(({ lang, translations }) => {
        if (formatType === HumanizedDateFormatType.TIME_AND_DATE) {
          return (date ?? new Date()).toLocaleString(lang, {
            hour: 'numeric',
            minute: 'numeric',
            month: 'long',
            day: 'numeric',
            year: 'numeric'
          });
        }

        const m_ = moment(date).locale(lang);

        if (moment().startOf('day').diff(m_.startOf('day'), 'days') === 0) {
          // сегодня
          switch (formatType) {
            case HumanizedDateFormatType.TIME_OR_DATE:
            case HumanizedDateFormatType.TIME_ONLY:
              return date.toLocaleString(lang, {
                hour: 'numeric',
                minute: 'numeric'
              });
            case HumanizedDateFormatType.DATE_ONLY:
              return translations.TODAY;
          }
        } else if (moment().startOf('day').diff(m_.startOf('day'), 'days') === 1) {
          // вчера
          switch (formatType) {
            case HumanizedDateFormatType.TIME_ONLY:
              return date.toLocaleString(lang, {
                hour: 'numeric',
                minute: 'numeric'
              });
            case HumanizedDateFormatType.DATE_ONLY:
            case HumanizedDateFormatType.TIME_OR_DATE:
              return translations.YESTERDAY;
          }
        } else if (moment().startOf('day').diff(m_.startOf('day'), 'days') <= 7) {
          // не больше недели
          switch (formatType) {
            case HumanizedDateFormatType.TIME_ONLY:
              return date.toLocaleString(lang, {
                hour: 'numeric',
                minute: 'numeric'
              });
            case HumanizedDateFormatType.DATE_ONLY:
            case HumanizedDateFormatType.TIME_OR_DATE:
              return date.toLocaleString(lang, {
                weekday: 'long'
              });
          }
        } else if (moment().startOf('day').diff(m_.startOf('day'), 'days') <= 365) {
          // не больше года
          switch (formatType) {
            case HumanizedDateFormatType.TIME_ONLY:
              return date.toLocaleString(lang, {
                hour: 'numeric',
                minute: 'numeric'
              });
            case HumanizedDateFormatType.DATE_ONLY:
            case HumanizedDateFormatType.TIME_OR_DATE:
              return date.toLocaleString(lang, {
                month: 'long',
                day: 'numeric'
              });
          }
        } else {
          // больше года
          switch (formatType) {
            case HumanizedDateFormatType.TIME_ONLY:
              return date.toLocaleString(lang, {
                hour: 'numeric',
                minute: 'numeric'
              });
            case HumanizedDateFormatType.DATE_ONLY:
            case HumanizedDateFormatType.TIME_OR_DATE:
              return date.toLocaleString(lang, {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              });
          }
        }
      })
    );
  }
}
