import { Action, createReducer, on } from '@ngrx/store';
import * as ParticipantsState from '@breez/modules/chat/+state/participants/participants.state';
import * as ParticipantsAction from '@breez/modules/chat/+state/participants/participants.actions';
import { ChatParticipantsEvent } from '@breez/models/chat/chat-participants-event';
import { PARTICIPANT_ID } from '@breez/modules/chat/types/participant-id.type';
import { isTruthy } from '@breez/shared/utilities/is-truthy';

const reducer = createReducer(
  ParticipantsState.initialState,
  on(ParticipantsAction.addChatsParticipants, (state, { data }) => {
    return ParticipantsState.adapter.upsertMany(data, state);
  }),
  on(ParticipantsAction.updateChatParticipants, (state, { chatId, participants }) => {
    return ParticipantsState.adapter.upsertOne({ chatId, participants }, state);
  }),

  on(ParticipantsAction.changeChatParticipantsSuccess, (state, action) => {
    const updateParticipantIds = (
      status: ChatParticipantsEvent,
      participantUserIds: PARTICIPANT_ID[],
      updatedIds: PARTICIPANT_ID[]
    ): PARTICIPANT_ID[] => {
      switch (status) {
        case ChatParticipantsEvent.REMOVED:
          return participantUserIds.filter(id => {
            return !updatedIds.includes(id);
          });
        case ChatParticipantsEvent.ADDED:
          return [...new Set([...participantUserIds, ...updatedIds])];
        default:
          return participantUserIds;
      }
    };
    const oldParticipantUserIds = state.entities[action.chatId]?.participants ?? [];
    const participants = updateParticipantIds(action.status, oldParticipantUserIds, action.participantsId);

    return ParticipantsState.adapter.upsertOne(
      { ...{ chatId: action.chatId }, participants },
      {
        ...state
      }
    );
  }),
  on(ParticipantsAction.banChatParticipantsSuccess, (state, action) => {
    if (!isTruthy(action.chatId) || !isTruthy(action.participantId)) return state;
    let bannedUsers = state.entities[action.chatId]?.bannedUsers ?? [];
    bannedUsers = [
      ...bannedUsers,
      {
        id: action.participantId,
        reason: action.reason,
        expired: action.expired ?? null
      }
    ];

    // @ts-ignore
    return ParticipantsState.adapter.updateOne({ id: action.chatId, changes: { bannedUsers } }, state);
  }),
  on(ParticipantsAction.unbanChatParticipantsSuccess, (state, action) => {
    if (!isTruthy(action.chatId) || !isTruthy(action.participantId)) return state;
    const bannedUsers = (state.entities[action.chatId]?.bannedUsers ?? []).filter(bannedUser => {
      return bannedUser.id !== action.participantId;
    });
    // @ts-ignore
    return ParticipantsState.adapter.updateOne({ id: action.chatId, changes: { bannedUsers } }, state);
  })
);

export function participantsReducer(
  state: ParticipantsState.State | undefined,
  action: Action
): ParticipantsState.State {
  return reducer(state, action);
}
