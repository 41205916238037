import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, combineLatest, EMPTY, exhaustMap, of } from 'rxjs';
import { filter, map, mergeMap, take, tap } from 'rxjs/operators';
import * as ChatActions from './chat.actions';
import * as ModuleState from '../module.state';
import * as ChatSelectors from './chat.selectors';
import { CHAT_ID } from '../../types';
import { CHATS_MUTE_KEY } from '../../consts';
import { ChatService } from '../../services';
import { StateService } from '@breez/shared/services/state.service';
import { changeChatMute, ConferenceChatTitleEnum } from '@breez/modules/chat';
import * as UsersActions from '@breez/modules/users/+state/users/user.actions';
import * as ParticipantsActions from '@breez/modules/chat/+state/participants/participants.actions';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { ParticipantsEntity } from '@breez/modules/chat/models/+state/participantsEntity';

@Injectable()
export class ChatEffects {
  // noinspection JSUnusedGlobalSymbols
  loadChats$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatActions.loadChats),
      concatLatestFrom(action => {
        return [
          this.store.select(ChatSelectors.getState),
          this.store.select(ChatSelectors.chatsIsConference),
          this.store.select(ChatSelectors.getChats(action.conferenceId))
        ];
      }),
      mergeMap(([action, stateChats, chatsIsConference, getChats]) => {
        let { chatsId, forceIfExist } = action;
        if (stateChats.isLoad === true && getChats.length > 0) {
          if (!isTruthy(chatsId)) {
            return of([ChatActions.loadChatsSuccess({ ...action, chats: [] })]);
          } else {
            chatsId = chatsId.filter(chatId => {
              return (
                (isTruthy(forceIfExist) && forceIfExist?.length > 0
                  ? forceIfExist.find(id => {
                      return id === chatId;
                    })
                  : false) || !isTruthy(stateChats.entities[chatId])
              );
            });
          }
          if (chatsId.length === 0) {
            return of([ChatActions.loadChatsSuccess({ ...action, chats: [] })]);
          }
        }

        const actions: Action[] = [];
        return (
          chatsId
            ? combineLatest(
                chatsId.map(chatId => {
                  return this.chatService.getChatById(chatId);
                })
              ).pipe(
                take(1),
                map(foundChats => {
                  const chats = [];
                  let users = [];
                  foundChats.forEach(foundChat => {
                    chats.push(foundChat.chat);
                    users = [...users, ...foundChat.users];
                  });
                  return { chats, users };
                })
              )
            : this.chatService.fetchChatList(action.conferenceId).pipe(
                tap(() => {
                  actions.push(ChatActions.markAsLoaded());
                  if (isTruthy(action.conferenceId)) {
                    actions.push(ChatActions.markAsConference({ conferenceId: action.conferenceId }));
                  }
                })
              )
        ).pipe(
          take(1),
          map(chatList => {
            chatList = this.chatService.setChatsLastMessageSender(chatList);
            const chatsMute: CHAT_ID[] = this.stateService.getFromLocal<CHAT_ID[]>(CHATS_MUTE_KEY) ?? [];
            let chats = chatList.chats.map(chat => {
              return changeChatMute(chat, chatsMute.includes(chat.id));
            });
            if (isTruthy(action.conferenceId || chatsIsConference)) {
              chats = chats.map(chat => {
                return this.chatService.setDefaultConferenceChatsTitle(chat, action.conferenceId ?? chatsIsConference);
              });
            }
            actions.push(
              ChatActions.loadChatsSuccess({
                ...action,
                chats
              })
            );

            actions.push(
              UsersActions.loadUsersSuccess({
                ...action,
                users: chatList.users
              })
            );
            actions.push(
              ParticipantsActions.addChatsParticipants({
                data: chatList.chats.map(chat => {
                  return {
                    chatId: chat.id,
                    participants:
                      chat.name === ConferenceChatTitleEnum.MAIN_CHAT
                        ? chatList.users.map(user => {
                            return user.id;
                          })
                        : chat.participantUserIds
                  } as ParticipantsEntity;
                })
              })
            );
            return actions;
          }),
          catchError(errorMessage => {
            return of([
              ChatActions.markAsNotLoaded(),
              ChatActions.loadChatsFailure({
                ...action,
                errorMessage
              })
            ]);
          })
        );
      }),
      mergeMap((actions: Action[]) => {
        return actions;
      })
    );
  });

  // noinspection JSUnusedGlobalSymbols
  createChat$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatActions.createChat),
      concatLatestFrom(() => {
        return this.store.select(ChatSelectors.selectedId);
      }),
      exhaustMap(([action, _]) => {
        return this.chatService
          .createChat(action.chat?.name ?? null, action.participantIds, action.chat?.conferenceId ?? undefined)
          .pipe(
            map(chatId => {
              const actions: Action[] = [];
              let { chat } = action;
              chat = { ...chat, ...{ id: chatId } };
              actions.push(ChatActions.createChatSuccess({ ...action, chat }));
              actions.push(ChatActions.loadChats({ chatsId: [chatId], forceIfExist: [chatId] }));

              return actions;
            }),
            catchError(errorMessage => {
              return of([
                ChatActions.createChatFailure({
                  ...action,
                  errorMessage
                })
              ]);
            })
          );
      }),
      mergeMap((actions: Action[]) => {
        return actions;
      })
    );
  });

  // noinspection JSUnusedGlobalSymbols
  changeChat$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatActions.changeChat),
      exhaustMap(action => {
        const { chat, needSendToBackend } = action;
        return needSendToBackend
          ? this.chatService.updateChat(chat.name, chat.id, chat.callId).pipe(
              map(result => {
                return result
                  ? ChatActions.changeChatSuccess(action)
                  : ChatActions.changeChatFailure({ ...action, errorMessage: 'unknown' });
              }),
              catchError(errorMessage => {
                return of(ChatActions.changeChatFailure({ ...action, errorMessage }));
              })
            )
          : of(ChatActions.changeChatSuccess(action));
      })
    );
  });

  // noinspection JSUnusedGlobalSymbols
  chatChanges$ = createEffect(() => {
    return this.chatService.chatChanges$.pipe(
      map(chat => {
        const actions: Action[] = [];
        actions.push(ChatActions.changeChat({ chat, needSendToBackend: false }));
        actions.push(
          ParticipantsActions.updateChatParticipants({ chatId: chat.id, participants: chat.participantUserIds })
        );
        return actions;
      }),
      mergeMap((actions: Action[]) => {
        return actions;
      })
    );
  });

  // noinspection JSUnusedGlobalSymbols
  changeChatState$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatActions.changeChatState),
      exhaustMap(action => {
        const { needSendToBackend } = action;
        return needSendToBackend
          ? of({ result: true }).pipe(
              // todo
              map(result => {
                return result
                  ? ChatActions.changeChatStateSuccess(action)
                  : ChatActions.changeChatStateFailure({ ...action, errorMessage: 'unknown' });
              }),
              catchError(errorMessage => {
                return of(ChatActions.changeChatStateFailure({ ...action, errorMessage }));
              })
            )
          : of(ChatActions.changeChatStateSuccess(action));
      })
    );
  });

  // noinspection JSUnusedGlobalSymbols
  changeChatStateSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatActions.changeChatStateSuccess),
      filter(({ remove }) => {
        return !remove;
      }),
      map(({ chatId }) => {
        return ChatActions.loadChats({ chatsId: [chatId] });
      })
    );
  });

  // noinspection JSUnusedGlobalSymbols
  changeNotification$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatActions.changeNotification),
      concatLatestFrom(() => {
        return this.store.select(ChatSelectors.chats);
      }),
      exhaustMap(([action, _]) => {
        let changed = false;
        const chatsMute: CHAT_ID[] = this.stateService.getFromLocal(CHATS_MUTE_KEY) ?? [];
        const idIndex = chatsMute.findIndex(id => {
          return id === action.chatId;
        });

        if (action.disable) {
          if (idIndex === -1) {
            chatsMute.push(action.chatId);
            changed = true;
          }
        } else {
          if (idIndex >= 0) {
            chatsMute.splice(idIndex, 1);
            changed = true;
          }
        }
        if (changed) {
          this.stateService.saveToLocal(CHATS_MUTE_KEY, chatsMute);
          return of(
            ChatActions.changeChat({
              chat: { id: action.chatId, mute: action.disable },
              needSendToBackend: false
            })
          );
        }

        return EMPTY;
      })
    );
  });

  // noinspection JSUnusedGlobalSymbols
  changePin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ChatActions.changePin),
      exhaustMap(action => {
        if (action.pin) {
          return this.chatService.pinChat(action.chatId).pipe(
            map(result => {
              return ChatActions.changePinSuccess({ ...action, pinnedOrder: result.pinnedorder });
            }),
            catchError(errorMessage => {
              return of(
                ChatActions.changePinFailure({
                  ...action,
                  errorMessage
                })
              );
            })
          );
        }

        return this.chatService.unpinChat(action.chatId).pipe(
          map(() => {
            return ChatActions.changePinSuccess({ ...action, pinnedOrder: 0 });
          }),
          catchError(errorMessage => {
            return of(
              ChatActions.changePinFailure({
                ...action,
                errorMessage
              })
            );
          })
        );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store<ModuleState.State>,
    private readonly stateService: StateService,
    private readonly chatService: ChatService
  ) {}
}
