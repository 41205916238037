import { createEntityAdapter } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
// import {Chat} from '@breez/modules/chat/models';
import * as ModuleState from '../module.state';
import { CHAT_FEATURE_KEY } from '@breez/modules/chat/+state/chat-feature-key.const';
import { CHAT_ID, UnreadMessagesCount } from '@breez/modules/chat';

export const getFeatureState = createFeatureSelector<ModuleState.State>(CHAT_FEATURE_KEY);
export const getState = createSelector(getFeatureState, state => {
  return state?.chatUnreadMessageCount;
});

const { selectAll } = createEntityAdapter<UnreadMessagesCount>().getSelectors(getState);

export const chats = selectAll;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const chatUnreadMessagesCountModelById = (chatId: CHAT_ID) => {
  return createSelector(getState, state => {
    return state.entities[chatId] ?? new UnreadMessagesCount({ chatId, unreadIds: [], baseCount: 0 });
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getUnreadMessagesCount = (chatId: CHAT_ID) => {
  return createSelector(chatUnreadMessagesCountModelById(chatId), unreadMessageId => {
    return unreadMessageId ? (unreadMessageId.baseCount ?? 0) + (unreadMessageId.unreadIds.length ?? 0) : 0;
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getUnreadChatsCount = (chatsId?: CHAT_ID[]) => {
  return createSelector(selectAll, chatsUnreadMessagesCountModel => {
    return (
      chatsUnreadMessagesCountModel.filter(chatUnreadMessagesCountModel => {
        if (chatsId?.length > 0 && !chatsId.includes(chatUnreadMessagesCountModel.chatId)) {
          return false;
        }
        return (
          (chatUnreadMessagesCountModel.baseCount ?? 0) + (chatUnreadMessagesCountModel?.unreadIds?.length ?? 0) > 0
        );
      })?.length ?? 0
    );
  });
};
