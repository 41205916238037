import { MonoTypeOperatorFunction, pipe } from 'rxjs';
import { map } from 'rxjs/operators';

export function sort<T extends { [key: string]: any }, K extends keyof T>(
  sortableField: K,
  isAscendingSort: boolean
): MonoTypeOperatorFunction<T[]> {
  return pipe(
    map((sortableData: T[]) => {
      const coefficient = isAscendingSort ? -1 : 1;
      return sortableData.sort((a, b) => {
        const value1 = a[sortableField];
        const value2 = b[sortableField];

        if (!value1 && !value2) {
          return 0;
        }
        if (!value2) {
          return 1 * coefficient;
        }
        if (!value1) {
          return -1 * coefficient;
        }
        if (!!Date.parse(value1) && !!Date.parse(value2)) {
          const date1 = new Date(value1);
          const date2 = new Date(value2);

          return date1 < date2 ? coefficient : date1 > date2 ? coefficient * -1 : 0;
        }
        if (typeof value1 === 'string' && typeof value2 === 'string') {
          return value1.toLocaleLowerCase().localeCompare(value2.toLocaleLowerCase());
        } else {
          return value1 < value2 ? coefficient : value1 > value2 ? coefficient * -1 : 0;
        }
      });
    })
  );
}
