export enum Periods {
  DAY = 'DAY',
  THREE_DAYS = 'THREE_DAYS',
  CURRENT_WEEK = 'CURRENT_WEEK',
  CURRENT_MONTH = 'CURRENT_MONTH',
  PREVIOUS_WEEK = 'PREVIOUS_WEEK',
  NEXT_WEEK = 'NEXT_WEEK',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
  HALF_YEAR = 'HALF_YEAR',
  YEAR = 'YEAR',
  ALL_CONFERENCES = 'ALL',
  ALL = 'ALL'
}
