import { Pipe, PipeTransform } from '@angular/core';
import { DateTimeHumanizerService, HumanizedDateFormatType } from '@breez/shared/services/date-time-humanizer.service';
import { Observable } from 'rxjs';

type HumanizedDateFormatTypeString = 'date-only' | 'time-only' | 'time-or-date' | 'time-and-date';

@Pipe({
  name: 'humanizeDate'
})
export class HumanizeDatePipe implements PipeTransform {
  constructor(private dateTimeHumanizerService: DateTimeHumanizerService) {}

  transform(
    date: Date,
    type: HumanizedDateFormatTypeString = HumanizedDateFormatType.TIME_OR_DATE
  ): Observable<string> {
    const typeEnum =
      HumanizedDateFormatType[
        Object.keys(HumanizedDateFormatType).find(key => {
          return HumanizedDateFormatType[key] === type;
        })
      ];

    return this.dateTimeHumanizerService.trackHumanizeDate(date, typeEnum);
  }
}
