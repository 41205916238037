import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { EmitOnChange } from '@breez/shared/utilities/decorators/emit-on-change.decorator';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'vks-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalHeaderComponent implements OnChanges {
  @Input() title: string;
  @Input() allowClose = true;

  @HostBinding('class.no-background')
  @Input()
  noBackground = true;

  @HostBinding('class.no-margin')
  @Input()
  noMargin = false;

  @Output() close = new EventEmitter();

  @Input() icon: string;

  @EmitOnChange('icon')
  icon$ = new ReplaySubject<string>(1);

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnChanges(_: SimpleChanges): void {}
}
