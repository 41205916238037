import { Expose } from 'class-transformer';
import { Participant } from '@breez/models/shared/participant/participant.model';
import { EventTypeEnum } from '@breez/models';

export class ParticipantEvent {
  @Expose({ name: 'data' })
  affectedParticipants: Participant | Participant[];

  @Expose({ name: 'event' })
  eventType: EventTypeEnum;
}
