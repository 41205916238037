import { Injectable } from '@angular/core';
import { AppService } from '@breez/app.service';
import { WebsocketEvents, WebsocketService } from '@breez/modules/websocket';
import { combineLatest, EMPTY, Observable, of, throwError, from } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';

import { UserMediaSource } from '@breez/models/webrtc/media-source.model';
import { DeviceSourceType } from '@breez/models/webrtc/device-source-type.enum';
import { MediaSourceKind } from '@breez/models/webrtc/media-source-kind.enum';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { ResolvedUserMediaSource } from '@breez/models/webrtc/resolved-user-media-source.model';
import { UserMediaSourceBase } from '@breez/models/webrtc/media-source-base.model';
import { SdpModel } from '@breez/models/webrtc/sdp.model';
import { CallMediaSource } from '@breez/modules/call/models/call-media-source.model';
import {
  DEFAULT_USER_MEDIA_SOURCES_KEY,
  MediaDevicesService
} from '@breez/modules/webrtc/services/media-devices.service';
import { WebrtcStatsEntry } from '@breez/modules/webrtc/models/webrtc-stats-entry.model';
import { toClass, toPlain } from '@breez/shared/rxjs-operators';
import { SettingsService } from '@breez/modules/settings/services/settings.service';
import { ElectronService } from '@breez/modules/core/services';
import { LocalStorage } from '@breez/shared/modules/storage/interfaces/local-storage.interface';

export enum WebrtcMessages {
  ICE_NEGOTIATION_ERROR = 'ice_negotiation_error',
  RTP_CRITICAL_ERROR = 'rtp_critical_error'
}

@Injectable()
export class WebrtcService {
  isOnline$: Observable<boolean> = this.wsService.status$;
  isSafari: boolean = this.appService.isSafari;

  isElectronApp: boolean = this.electronService.isElectron;

  electronDesktopCapturer$: Observable<MediaStream> = this.mediaDevicesService.electronDesktopCapturer$;

  constructor(
    private appService: AppService,
    private wsService: WebsocketService,
    private mediaDevicesService: MediaDevicesService,
    private settingsService: SettingsService,
    private electronService: ElectronService,
    private localStorage: LocalStorage
  ) {}

  remoteIceCandidates(callId: number): Observable<RTCIceCandidate> {
    return this.wsService.on<{ callid: number; ice: RTCIceCandidate }>(WebsocketEvents.RECEIVE.WEBRTC.ICE).pipe(
      filter(isTruthy),
      filter(data => {
        return data.callid === callId;
      }),
      map(data => {
        return data.ice;
      })
    );
  }

  remoteWebrtcMessage(callId: number): Observable<string> {
    return this.wsService.on<{ callid: number; message: { msg: string } }>(WebsocketEvents.RECEIVE.WEBRTC.MESSAGE).pipe(
      filter(data => {
        return data.callid === callId;
      }),
      map(data => {
        const msg = data.message?.msg;
        switch (msg) {
          case WebrtcMessages.ICE_NEGOTIATION_ERROR:
            return WebrtcMessages.ICE_NEGOTIATION_ERROR;
            break;
          case WebrtcMessages.RTP_CRITICAL_ERROR:
            return WebrtcMessages.RTP_CRITICAL_ERROR;
            break;
          default:
            return null;
            break;
        }
      }),
      filter(isTruthy)
    );
  }

  remoteWebrtcStats(callId: number): Observable<WebrtcStatsEntry> {
    return this.wsService.on<{ callid: number; stat: any }>(WebsocketEvents.RECEIVE.WEBRTC.STATS).pipe(
      filter(data => {
        return data.callid === callId;
      }),
      map(data => {
        return data.stat;
      }),
      toClass(WebrtcStatsEntry)
    );
  }

  sendWebrtcStats(stats: WebrtcStatsEntry, callId: number, isConference: boolean = false): Observable<void> {
    return of(stats).pipe(
      toPlain(),
      switchMap(statsPlain => {
        return this.wsService.send<void>(WebsocketEvents.SEND.WEBRTC.STATS, {
          data: {
            stat: statsPlain,
            callid: callId,
            isconference: isConference
          }
        });
      }),
      take(1),
      catchError(() => {
        return EMPTY;
      })
    );
  }

  sendIceCandidate(candidate: RTCIceCandidate, callId: number, isConference: boolean = false): Observable<void> {
    return this.wsService
      .send<void>(WebsocketEvents.SEND.WEBRTC.ICE, {
        data: {
          ice: candidate,
          callid: callId,
          isconference: isConference
        }
      })
      .pipe(take(1));
  }

  sendSessionDescription(
    description: RTCSessionDescription,
    activeSources: CallMediaSource[],
    callId: number,
    isConference: boolean = false
  ): Observable<void> {
    return this.wsService
      .send<void>(WebsocketEvents.SEND.WEBRTC.SDP, {
        data: <SdpModel>{
          sdp: description,
          activeTracks: activeSources,
          callid: callId,
          isconference: isConference
        }
      })
      .pipe(take(1));
  }

  remoteSessionDescription(callId: number): Observable<SdpModel> {
    return this.wsService.on<SdpModel>(WebsocketEvents.RECEIVE.WEBRTC.SDP).pipe(
      filter(isTruthy),
      filter(data => {
        return data.callid === callId;
      })
    );
  }

  fetchIceServers(): Observable<RTCIceServer[]> {
    return of(null).pipe(
      switchMap(() => {
        return combineLatest([
          this.settingsService.getValue('web', 'webrtc_stun'),
          this.settingsService.getValue('web', 'webrtc_turn')
        ]);
      }),
      map(settings => {
        return settings.map(setting => {
          return setting ? setting.value : null;
        });
      }),
      map(iceServersPlain => {
        return iceServersPlain
          .map(iceServerPlain => {
            try {
              return JSON.parse(iceServerPlain);
            } catch {
              return null;
            }
          })
          .filter(isTruthy);
      })
    );
  }

  getDummyAudioTrack(): MediaStreamTrack {
    if (!((<any>window).AudioContext || (<any>window).webkitAudioContext)) {
      const dummyRTCPeer = new RTCPeerConnection();
      const audioTrack = dummyRTCPeer.addTransceiver('audio').receiver.track;
      dummyRTCPeer.close();
      return Object.assign(audioTrack, {
        isDummy: true,
        enabled: true
      });
    }

    const ctx: AudioContext = new ((<any>window).AudioContext || (<any>window).webkitAudioContext)(),
      oscillator = ctx.createOscillator(),
      gain = ctx.createGain();
    gain.gain.setValueAtTime(0, ctx.currentTime);
    const dst = oscillator.connect(gain).connect(ctx.createMediaStreamDestination());
    oscillator.start(ctx.currentTime);
    // @ts-ignore
    return Object.assign(dst.stream.getAudioTracks()[0], { isDummy: true });
  }

  // TODO очень плохой костыль, особенно setInterval; переделать
  getDummyVideoTrack(width = 960, height = 540): MediaStreamTrack {
    const canvas = Object.assign(document.createElement('canvas'), { width, height });
    canvas.getContext('2d').fillRect(0, 0, width, height);
    // тулза не принимает статическую картинку, вывешивает сообщение об отсутствии сигнала
    // поэтому холст нужно обновлять где-то десять раз в секунду

    if (!((<any>canvas).captureStream && typeof (<any>canvas).captureStream === 'function')) {
      const dummyRTCPeer = new RTCPeerConnection();
      const videoTrack = dummyRTCPeer.addTransceiver('video').receiver.track;
      dummyRTCPeer.close();
      return Object.assign(videoTrack, { isDummy: true, enabled: true });
    }

    const stream = (<any>canvas).captureStream();
    const intervalCallerId = setInterval(() => {
      canvas.getContext('2d').fillStyle = '#000';
      canvas.getContext('2d').fillRect(0, 0, width, height);
    }, 100);
    const track = stream.getVideoTracks()[0];
    const oldStopFunction = track.stop;
    track.stop = (): void => {
      clearInterval(intervalCallerId);
      oldStopFunction.call(track);
    };
    return Object.assign(track, { isDummy: true, enabled: true });
  }

  getDefaultMediaSourcesIds(): UserMediaSourceBase[] {
    const defaultUserMediaPlain = this.localStorage.getItem(DEFAULT_USER_MEDIA_SOURCES_KEY);
    try {
      const defaultUserMediaBases = JSON.parse(defaultUserMediaPlain);

      if (!Array.isArray(defaultUserMediaBases)) {
        return [];
      }

      return defaultUserMediaBases;
    } catch {
      return [];
    }
  }

  setDefaultMediaSourcesIds(sources: UserMediaSource[], withoutEnabled = false): void {
    this.mediaDevicesService.setDefaultMediaSourcesIds(sources, withoutEnabled);
  }

  enumerateAvailableMediaSources(): Observable<UserMediaSource[]> {
    if (!(navigator.mediaDevices && navigator.mediaDevices.enumerateDevices)) {
      return throwError('enumerateDevices() not implemented');
    }

    const defaultUserMediaSourceIds = this.getDefaultMediaSourcesIds();

    const hardwareMediaSources$ = from(navigator.mediaDevices.enumerateDevices()).pipe(
      map(devices => {
        return devices
          .map(device => {
            let kind: MediaSourceKind;
            switch (device.kind) {
              case 'videoinput':
                kind = MediaSourceKind.VIDEO_INPUT;
                break;
              case 'audioinput':
                kind = MediaSourceKind.AUDIO_INPUT;
                break;
              case 'audiooutput':
                kind = MediaSourceKind.AUDIO_OUTPUT;
                break;
              default:
                return null;
            }

            const isDefault = defaultUserMediaSourceIds.some(base => {
              return base.id === device.deviceId && base.kind === kind;
            });

            return <UserMediaSource>{
              id: device.deviceId,
              groupId: device.groupId,
              title: device.label,
              sourceType: DeviceSourceType.HARDWARE,
              kind,
              isDefault
            };
          })
          .filter(isTruthy);
      })
    );

    return combineLatest([hardwareMediaSources$]).pipe(
      map(([hardwareSources]) => {
        return [...hardwareSources].filter(isTruthy);
      })
    );
  }

  getDefaultMediaSources(videoInput = true, audioInput = true, audioOutput = true): Observable<UserMediaSource[]> {
    return this.enumerateAvailableMediaSources().pipe(
      map(sources => {
        let videoSource = videoInput
          ? sources.find(source => {
              return source.kind === MediaSourceKind.VIDEO_INPUT && source.isDefault;
            }) ||
            sources.find(source => {
              return source.kind === MediaSourceKind.VIDEO_INPUT && source.id === 'default';
            }) ||
            sources.find(source => {
              return source.kind === MediaSourceKind.VIDEO_INPUT;
            }) ||
            null
          : null;

        let audioSource = audioInput
          ? sources.find(source => {
              return source.kind === MediaSourceKind.AUDIO_INPUT && source.isDefault;
            }) ||
            sources.find(source => {
              return source.kind === MediaSourceKind.AUDIO_INPUT && source.id === 'default';
            }) ||
            sources.find(source => {
              return source.kind === MediaSourceKind.AUDIO_INPUT;
            }) ||
            null
          : null;

        const audioDestination = audioOutput
          ? sources.find(source => {
              return source.kind === MediaSourceKind.AUDIO_OUTPUT && source.isDefault;
            }) ||
            sources.find(source => {
              return source.kind === MediaSourceKind.AUDIO_OUTPUT && source.id === 'default';
            }) ||
            sources.find(source => {
              return source.kind === MediaSourceKind.AUDIO_OUTPUT;
            }) ||
            null
          : null;

        if (this.isSafari) {
          videoSource = videoInput
            ? <UserMediaSource>{
                kind: MediaSourceKind.VIDEO_INPUT,
                id: '',
                title: '',
                groupId: '',
                isDefault: true,
                sourceType: DeviceSourceType.HARDWARE
              }
            : null;
          audioSource = audioInput
            ? <UserMediaSource>{
                kind: MediaSourceKind.AUDIO_INPUT,
                id: '',
                title: '',
                groupId: '',
                isDefault: true,
                sourceType: DeviceSourceType.HARDWARE
              }
            : null;
        }

        return [videoSource, audioSource, audioDestination].filter(isTruthy);
      })
    );
  }

  /**
   * @deprecated Should be used __MediaDevicesService.resolveTracksBySources__
   */
  resolveTracksBySources(mediaSources: UserMediaSource[]): Observable<ResolvedUserMediaSource[]> {
    if (!Array.isArray(mediaSources) || mediaSources.filter(isTruthy).length === 0) {
      return of([]);
    }

    return combineLatest(
      mediaSources
        .map(source => {
          if (!source) {
            return null;
          }

          if (!navigator.mediaDevices || source.resolveNull) {
            return of(<ResolvedUserMediaSource>{ ...source, track: null });
          }

          if (source.sourceType === DeviceSourceType.SCREEN) {
            if (typeof (<any>navigator.mediaDevices).getDisplayMedia !== 'function') {
              return of(<ResolvedUserMediaSource>{ ...source, track: null });
            }

            if (this.isElectronApp) {
              return this.electronDesktopCapturer$.pipe(
                map((stream: MediaStream) => {
                  return <ResolvedUserMediaSource>{ ...source, track: stream.getTracks()[0] };
                }),
                map(mediaSource => {
                  (<any>mediaSource.track).displayMedia = true;
                  return mediaSource;
                }),
                catchError(error => {
                  return of(<ResolvedUserMediaSource>{ ...source, track: null, error: error });
                })
              );
            }

            return from((<any>navigator.mediaDevices).getDisplayMedia()).pipe(
              map((stream: MediaStream) => {
                return <ResolvedUserMediaSource>{ ...source, track: stream.getTracks()[0] };
              }),
              map(mediaSource => {
                (<any>mediaSource.track).displayMedia = true;
                return mediaSource;
              }),
              catchError(error => {
                return of(<ResolvedUserMediaSource>{ ...source, track: null, error: error });
              })
            );
          } else if (source.sourceType === DeviceSourceType.HARDWARE) {
            if (typeof navigator.mediaDevices.getUserMedia !== 'function') {
              return of(<ResolvedUserMediaSource>{ ...source, track: null });
            }

            const constraints: MediaStreamConstraints = {};
            let kind: 'video' | 'audio';
            switch (source.kind) {
              case MediaSourceKind.VIDEO_INPUT:
                kind = 'video';
                break;
              case MediaSourceKind.AUDIO_INPUT:
                kind = 'audio';
                break;
            }

            if (!kind) {
              return null;
            }

            constraints[kind] = (source.constraints || {}) as MediaTrackConstraints;
            if (source.id) {
              (<MediaTrackConstraints>constraints[kind]).deviceId = { exact: source.id };
            }

            return from(navigator.mediaDevices.getUserMedia(constraints)).pipe(
              map((stream: MediaStream) => {
                return <ResolvedUserMediaSource>{ ...source, track: stream.getTracks()[0] };
              }),
              catchError(error => {
                return of(<ResolvedUserMediaSource>{ ...source, track: null, error: error });
              })
            );
          }
        })
        .filter(isTruthy)
    ).pipe(take(1));
  }

  /**
   * @deprecated Should be used __MediaDevicesService.resolveTracksByExistingSources__
   */
  makeMediaStream(mediaStream: MediaStream, sources: UserMediaSource[]): Observable<MediaStream> {
    if (!mediaStream) {
      mediaStream = new MediaStream();
    }

    mediaStream
      .getTracks()
      .filter(track => {
        return track.readyState !== 'live' || (<any>track).isDummy;
      })
      .forEach(track => {
        track.stop();
        mediaStream.removeTrack(track);
      });

    let tracks = mediaStream.getTracks();

    tracks
      .filter(track => {
        return !sources
          .filter(source => {
            return !source.resolveNull;
          })
          .some(source => {
            return this.compareSources(track, source);
          });
      })
      .forEach(track => {
        track.stop();
        mediaStream.removeTrack(track);
      });

    tracks = mediaStream.getTracks();

    const newSources = sources.filter(source => {
      return !tracks.some(track => {
        return this.compareSources(track, source);
      });
    });

    return this.resolveTracksBySources(newSources).pipe(
      map(resolvedSources => {
        resolvedSources
          .filter(source => {
            return source.track;
          })
          .forEach(source => {
            return mediaStream.addTrack(source.track);
          });
        return mediaStream;
      }),
      take(1)
    );
  }

  compareSources(source1: UserMediaSource | MediaStreamTrack, source2: UserMediaSource | MediaStreamTrack): boolean {
    if (!(source1 && source2)) {
      return false;
    }

    if (source1 instanceof MediaStreamTrack) {
      source1 = this.trackToSource(source1);
    }

    if (source2 instanceof MediaStreamTrack) {
      source2 = this.trackToSource(source2);
    }

    if (this.isSafari) {
      return source1.kind === source2.kind;
    }

    return (
      source1.id === source2.id &&
      source1.kind === source2.kind &&
      source1.groupId === source2.groupId &&
      source1.sourceType === source2.sourceType
    );
  }

  trackToSource(track: MediaStreamTrack): UserMediaSource {
    const settings = track.getSettings();
    let kind: MediaSourceKind;
    switch (track.kind) {
      case 'video':
        kind = MediaSourceKind.VIDEO_INPUT;
        break;
      case 'audio':
        kind = MediaSourceKind.AUDIO_INPUT;
        break;
    }

    const screenDeviceId = new RegExp(/^(screen|window):\w+:\w+$/);
    const isDisplaySource = (settings as any)?.displaySurface || screenDeviceId.test(settings?.deviceId);
    if (isDisplaySource) {
      return this.mediaDevicesService.getDisplayMediaSource();
    }

    return <UserMediaSource>{
      id: settings.deviceId,
      groupId: settings.groupId,
      sourceType: DeviceSourceType.HARDWARE,
      kind
    };
  }
}
