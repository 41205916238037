import { Transform } from 'class-transformer';
import { CHAT_ID } from '../types';
import { fixDateDeserialization } from '@breez/shared/utilities/fixDateDeserialization';

export class ChatBanEvent {
  chatId: CHAT_ID;

  chatBanned: boolean;

  chatBanReason: string;

  @Transform(({ value }) => {
    return value ? new Date(fixDateDeserialization(value)) : null;
  })
  chatBanExpires: Date;
}
