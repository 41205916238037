import { createEntityAdapter } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
// import {Chat} from '@breez/modules/chat/models';
import * as ModuleState from '../module.state';
import { CHAT_FEATURE_KEY } from '@breez/modules/chat/+state/chat-feature-key.const';
import { Chat, CHAT_ID, MESSAGE_ID } from '@breez/modules/chat';
import { ChatEntity } from '@breez/modules/chat/models/+state/chatEntity';
import { isTruthy } from '@breez/shared/utilities/is-truthy';

export const getFeatureState = createFeatureSelector<ModuleState.State>(CHAT_FEATURE_KEY);
export const getState = createSelector(getFeatureState, state => {
  return state.chat;
});

const { selectAll } = createEntityAdapter<ChatEntity>().getSelectors(getState);

export const chats = selectAll;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const getChats = (conferenceId?: number) => {
  return createSelector(chats, chatsList => {
    return chatsList.filter(chat => {
      return isTruthy(conferenceId) ? chat.conferenceId === conferenceId : !isTruthy(chat.conferenceId);
    }) as Chat[];
  });
};

export const selectedId = createSelector(getState, state => {
  return state.selectedId;
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const chatEntityById = (chatId: CHAT_ID) => {
  return createSelector(getState, state => {
    return state.entities[chatId];
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const chatEntityByLastMessageId = (messageId: MESSAGE_ID) => {
  return createSelector(selectAll, chatsList => {
    return chatsList.find(chat => {
      return chat?.lastMessage?.id === messageId;
    });
  });
};

export const selectedChat = createSelector(getState, state => {
  return state.entities[state.selectedId];
});

export const chatsIsLoaded = createSelector(getState, state => {
  return state.isLoad === true;
});

export const chatsIsConference = createSelector(getState, state => {
  return state.isConference;
});
