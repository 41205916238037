import { UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ValidationError } from './validation-error.model';

const ERROR = {
  type: 'daterange',
  label: 'WRONG_DATE_RANGE_ERROR'
} as ValidationError;

export function dateRangeValidator(startDateKey = 'startDate', endDateKey = 'endDate'): ValidatorFn {
  return (form: UntypedFormGroup): ValidationErrors | null => {
    const controlValueStartDate: Date = form.controls[startDateKey].value;
    if (!(controlValueStartDate instanceof Date)) {
      return null;
    }

    const controlValueEndDate: Date = form.controls[endDateKey].value;
    if (!(controlValueEndDate instanceof Date)) {
      return null;
    }

    if (controlValueStartDate?.getTime() < controlValueEndDate?.getTime()) {
      return null;
    }

    if (form.controls[startDateKey].untouched && form.controls[endDateKey].untouched) {
      return null;
    }

    return { [ERROR.type]: ERROR } as ValidationErrors;
  };
}
