<vks-control-file-drop-area
  #dropArea
  [acceptFormats]="supportedFileExtensions$ | async"
  [formControl]="fileDropAreaControl"
>
  <div class="fill-parent drop-file">
    <div class="file-upload-info">
      <div>
        <vks-icon icon="cloud-upload"></vks-icon>
      </div>
      <p>
        {{ 'DROP_HERE' | translate }}
      </p>
    </div>
  </div>
  <ng-template #noFile>
    <div class="fill-parent info-holder">
      <div class="file-upload-info">
        <div>
          <vks-icon icon="cloud-upload"></vks-icon>
        </div>
        <p>
          {{ 'DROP_FILE_OR' | translate }}
          <button
            vks-button
            [label]="'OR_SELECT_FILES' | translate"
            (buttonClick)="dropArea.showFileSelectionDialog()"
            class="btn-anchor indicate-focus"
            tabindex="0"
          ></button>
        </p>
        <div class="file-status">
          {{ supportedFormatsLabel$ | async }}
        </div>
      </div>
    </div>
  </ng-template>
  <div *ngIf="currentProcessingFile$ | async; let file; else: noFile" class="fill-parent selected-file">
    <div class="file-upload-info no-events">
      <div>
        <div [style.background-image]="'url(' + file?.base64 + ')' | safe: 'style'" class="image-preview"></div>
        <div class="image-filename">
          {{ file?.name }}
        </div>
      </div>
    </div>
    <div *ngIf="fileDropAreaControl.valid; else notValid" class="file-upload-controls">
      <a vks-button [label]="'CROP' | translate" (buttonClick)="crop()" class="btn-anchor"></a>
      <a vks-button [label]="'DELETE' | translate" (buttonClick)="clearFile()" class="btn-anchor"></a>
    </div>
    <ng-template #notValid>
      <div class="file-upload-controls invalid">
        <p *ngIf="fileDropAreaControl.errors?.filemaxsize as error">
          {{ error.label | translate: error.args }}
        </p>
        <p *ngIf="fileDropAreaControl.errors?.unexpectedfiletype as error">
          {{ error.label | translate: error.args }}
        </p>
        <a vks-button [label]="'DELETE' | translate" (buttonClick)="clearFile()" class="btn-anchor"></a>
      </div>
    </ng-template>
  </div>
</vks-control-file-drop-area>
