import { Expose } from 'class-transformer';
import { EventTypeEnum } from '@breez/models';

export class Event<T> {
  @Expose({ name: 'event' })
  type: EventTypeEnum;

  @Expose({ name: 'data' })
  data: T;
}
