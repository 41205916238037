import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'round'
})
export class RoundPipe implements PipeTransform {
  transform(value: number, digits = 2): number {
    return parseFloat(value.toFixed(digits));
  }
}
