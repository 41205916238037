import { User } from '@breez/models';

export function makeDTOFromUser(isParticipant = false, isSpeaker = false, isOperator = false): (user: User) => any {
  return participant => {
    let object;
    let rights = [];
    if (participant.isGuest) {
      if (!participant.hasNoId) {
        object = { id: participant.id };
      } else {
        object = { email: participant.email };
      }
      object = {
        ...object,
        isGuest: true
      };
    } else {
      object = { id: participant.id };
    }
    if (Array.isArray((<any>participant).rights)) {
      rights = (<any>participant).rights;
    }
    object = {
      ...object,
      isParticipant,
      isSpeaker,
      isOperator,
      rights
    };
    return object;
  };
}
