import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@breez/models';
import { AuthService } from '@breez/modules/auth/services/auth.service';
import { ChatListComponent } from '../chat-list';
import { replayWhileSubs } from '@breez/shared/rxjs-operators';
import { StateService } from '@breez/shared/services/state.service';
import { isTruthy } from '@breez/shared/utilities/is-truthy';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, fromEvent, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, finalize, map, startWith, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as ModuleState from '../../+state/module.state';
import * as ChatActions from '../../+state/chat/chat.actions';
import * as ChatSelectors from '../../+state/chat/chat.selectors';
import { DomHelper } from '@breez/helpers';
import { ROOT_STORE_SELECTORS } from '@breez/+state';
import { ChatEntity } from '@breez/modules/chat/models/+state/chatEntity';

@UntilDestroy()
@Component({
  selector: 'vks-chat-page',
  templateUrl: './chat-page.component.html',
  styleUrls: ['./chat-page.component.scss', './chat-page.component.media-max520.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatPageComponent implements OnInit, AfterViewInit {
  @ViewChild('chatListComponent')
  chatListComponent: ChatListComponent;

  currentUser$: Observable<User> = this.authService.currentUser$.pipe(replayWhileSubs());

  chats$: Observable<ChatEntity[]> = this.store.select(ChatSelectors.getChats());
  chat$: Observable<ChatEntity> = this.store.select(ChatSelectors.selectedChat);

  rearrangeInterface$: Observable<boolean> = fromEvent(window, 'resize').pipe(
    untilDestroyed(this),
    startWith(() => {
      return window.innerWidth <= 700;
    }),
    map(() => {
      return window.innerWidth <= 700;
    }),
    distinctUntilChanged(),
    replayWhileSubs()
  );

  hideChatList$: Observable<boolean> = combineLatest([this.rearrangeInterface$, this.chat$]).pipe(
    map(([isTabletDevice, selectedChat]) => {
      return isTabletDevice && !!selectedChat;
    })
  );

  hideChatMessages$: Observable<boolean> = combineLatest([this.rearrangeInterface$, this.chat$]).pipe(
    map(([isTabletDevice, selectedChat]) => {
      return isTabletDevice && !selectedChat;
    })
  );

  @HostListener('document:keyup.alt.arrowup', ['$event'])
  selectPreviousChat($event: KeyboardEvent): void {
    $event.preventDefault();
    this.store.dispatch(ChatActions.selectChat({ target: 'PREVIOUS' }));
  }

  @HostListener('document:keyup.alt.arrowdown', ['$event'])
  selectNextChat($event: KeyboardEvent): void {
    $event.preventDefault();
    this.store.dispatch(ChatActions.selectChat({ target: 'NEXT' }));
  }

  executionProcessing$: Observable<boolean> = this.store
    .select(ROOT_STORE_SELECTORS.execution.executionProcessing(ChatActions.loadChats))
    .pipe(distinctUntilChanged(), replayWhileSubs());

  executionCompleted$: Observable<boolean> = this.store
    .select(ROOT_STORE_SELECTORS.execution.executionResult(ChatActions.loadChats))
    .pipe(distinctUntilChanged(), replayWhileSubs());

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private store: Store<ModuleState.State>,
    private readonly stateService: StateService,
    private readonly authService: AuthService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.store.dispatch(ChatActions.loadChats({}));

    this.activatedRoute.queryParams
      .pipe(
        map(params => {
          return params.id;
        }),
        distinctUntilChanged(),
        filter(isTruthy),
        take(1)
      )
      .subscribe(chatId => {
        return this.store.dispatch(ChatActions.selectChat({ chatId }));
      });

    this.chat$.pipe(filter(isTruthy), debounceTime(200), untilDestroyed(this)).subscribe(chat => {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { id: chat.id },
        queryParamsHandling: 'merge'
      });
    });

    this.stateService.setExpandedGUI(true);
    this.stateService.setHeader('CHATS');

    this.stateService.isMobileDevice$
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          return this.document.body.classList.remove('reduce-interface');
        })
      )
      .subscribe(isMobile => {
        if (isMobile) {
          this.document.body.classList.add('reduce-interface');
        } else {
          this.document.body.classList.remove('reduce-interface');
        }
      });
  }

  ngAfterViewInit(): void {
    this.chat$.pipe(filter(isTruthy), debounceTime(50), take(1)).subscribe(chat => {
      if (!!chat) {
        this.scrollToSelectedChat();
      }
    });
  }

  scrollToSelectedChat(): void {
    const targetChatDivElement = DomHelper.findElementByClass(this.chatListComponent?.chatDivs, 'active');
    if (isTruthy(targetChatDivElement)) {
      targetChatDivElement.scrollIntoView({ block: 'center' });
    }
  }
}
