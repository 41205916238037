import { createAction, props } from '@ngrx/store';
import { CHAT_ID } from '../../types';
import { ErrorMessageModel } from '@breez/models/+state';

import { PropertiesActionObjectId, propsProcessing } from '@breez/helpers/+state/execution-action.helper';
import { LoadChatsAction, LoadChatsActionResult } from '@breez/modules/chat/interfaces/+state/load-chats-action';
import { ChatAddAction } from '@breez/modules/chat/interfaces/+state/chat-add-action';
import { ChatChangeAction } from '@breez/modules/chat/interfaces/+state/chat-change-action';
import { SendToBack } from '@breez/models/+state/send-to-back.model';
import { ChatChangePinAction } from '@breez/modules/chat/interfaces/+state/chat-change-pin-action';
import { ChatChangeStateAction } from '@breez/modules/chat/interfaces/+state/chat-change-state-action';

/**
 * Action.loadChats
 */
export const loadChats = createAction(
  '[Chat/API] Load chats',
  propsProcessing<LoadChatsAction & Partial<PropertiesActionObjectId>>
);

export const loadChatsSuccess = createAction('[Chat/API] Load chats Success', (params: LoadChatsActionResult) => {
  return propsProcessing<LoadChatsActionResult>(params, loadChats, 'chats');
});

export const loadChatsFailure = createAction(
  '[Chat/API] Load chats Failure',
  (params: ErrorMessageModel & LoadChatsAction) => {
    return propsProcessing<ErrorMessageModel & LoadChatsAction>(params, loadChats);
  }
);

/**
 * Action.createChat
 */
export const createChat = createAction('[Chat/API] Create chat', propsProcessing<ChatAddAction>);

export const createChatSuccess = createAction('[Chat/API] Create chat Success', (params: ChatAddAction) => {
  return propsProcessing<ChatAddAction>(params, createChat, 'chat');
});

export const createChatFailure = createAction(
  '[Chat/API] Create chat Failure',
  (params: ErrorMessageModel & ChatAddAction) => {
    return propsProcessing<ErrorMessageModel & ChatAddAction>(params, createChat);
  }
);

export const changeChat = createAction('[Chat/API] Change chat', propsProcessing<ChatChangeAction & SendToBack>);

export const changeChatSuccess = createAction('[Chat/API] Change chat Success', (params: ChatChangeAction) => {
  return propsProcessing<ChatChangeAction>(params, changeChat, 'chat');
});

export const changeChatFailure = createAction(
  '[Chat/API] Change chat Failure',
  (params: ErrorMessageModel & ChatChangeAction) => {
    return propsProcessing<ErrorMessageModel & ChatChangeAction>(params, changeChat);
  }
);

export const changePin = createAction('[Chat/API] Change pin for chat', propsProcessing<ChatChangePinAction>);

export const changePinSuccess = createAction(
  '[Chat/API] Change pin for chat Success',
  (params: ChatChangePinAction) => {
    return propsProcessing<ChatChangePinAction>(params, changePin, 'pinnedOrder');
  }
);

export const changePinFailure = createAction(
  '[Chat/API] Change pin for chat Failure',
  (params: ErrorMessageModel & ChatChangePinAction) => {
    return propsProcessing<ErrorMessageModel & ChatChangePinAction>(params, changePin);
  }
);

export const changeChatState = createAction(
  '[Chat/API] Change chat state',
  propsProcessing<ChatChangeStateAction & SendToBack>
);

export const changeChatStateSuccess = createAction(
  '[Chat/API] Change chat state Success',
  (params: ChatChangeStateAction) => {
    return propsProcessing<ChatChangeStateAction>(params, changeChatState, 'chatId');
  }
);

export const changeChatStateFailure = createAction(
  '[Chat/API] Change chat state Failure',
  (params: ErrorMessageModel & ChatChangeStateAction) => {
    return propsProcessing<ErrorMessageModel & ChatChangeStateAction>(params, changeChatState);
  }
);

/*
 * SELECT & DESELECT
 * */

export const selectChat = createAction(
  '[Chat] Select chat',
  props<{
    chatId?: CHAT_ID;
    target?: 'PREVIOUS' | 'NEXT';
  }>()
);

export const markAsLoaded = createAction('[Chat] isLoad: true');

export const markAsNotLoaded = createAction('[Chat] isLoad: false');

export const markAsConference = createAction(
  '[Chat] isConference: true',
  props<{
    conferenceId?: number;
  }>()
);

export const markAsNotConference = createAction('[Chat] isConference: false');

export const deselectChat = createAction('[Chat] Deselect chat');

export const changeNotification = createAction(
  '[Chat] Change notification for chat',
  props<{
    chatId: CHAT_ID;
    disable: boolean;
  }>()
);

export const updateLastActionDate = createAction(
  '[Chat] Change last action for chat',
  props<{
    chatId: CHAT_ID;
    lastAction: Date;
  }>()
);
