import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { OverlayContainer, OverlayModule } from '@angular/cdk/overlay';
import { ErrorHandlerService } from './error-handler.service';
import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { DEFAULT_ERROR_HANDLER_CONFIG } from './constants/default-error-handler.config';
import { ERROR_HANDLER_CONFIG } from './constants/error-handler.config';

@NgModule({
  imports: [CommonModule, OverlayModule, A11yModule]
})
export class ErrorHandlerModule {
  public static forRoot(): ModuleWithProviders<ErrorHandlerModule> {
    return {
      ngModule: ErrorHandlerModule,
      providers: [
        { provide: ErrorHandler, useClass: ErrorHandlerService },
        { provide: OverlayContainer, useClass: OverlayContainer },
        { provide: ERROR_HANDLER_CONFIG, useValue: DEFAULT_ERROR_HANDLER_CONFIG }
      ]
    };
  }
}
